import zhHans from 'vuetify/es5/locale/zh-Hans';
export default {
    ...zhHans,
    setting: {
        language: "Vereenvoudigd Chinees",
        dialogDetermine: "Zeker",
        dreivatives: "Levering",
        markets: "Citaten",
        assets: "activa",
        liquidity: "Liquiditeitsmijnbouw",
        helpCentre: "Helpcentrum",
        announcementCenter: "Aankondigingscentrum",
        about: "over",
        bulletinCentre: "Aankondigingscentrum",
        serviceAgreement: "Serviceovereenkomst",
        support: "steun",
        onlineSupportCustomerService: "online dienst",
        suggestionsAndFeedback: "Suggesties en feedback",
        service: "Dienen",
        download: "downloaden",
        coinApplication: "Toepassing voor het noteren van munten",
        privacyStatement: "Privacyverklaring",
        trade: "contracthandel",
        standardConteact: "Duurzaam",
        signOut: "afmelden",
        assetWallets: "Activa portemonnee",
        financeRecords: "Accountwijzigingsrecords",
        identification: "Authenticatie",
        personalCenter: "Persoonlijk centrum",
        chooseLanguage: "Kies een taal",
        tips: "hint",
        dark: "donkere modus",
        light: "dagmodus",
        languageTitle: "taal",
        convertCurrency: "Valuta omzetten",
        cancel: "Annuleren",
        aboutUs: "over ons",
        standard: "Continuïteit van de standaard",
        share: "deel",
        introduce: "Platformintroductie",
        set: "opgericht",
        changePassword: "Wijzig het inlogwachtwoord",
        denomination: "Valuta van de offerte",
        color: "Kleurconfiguratie",
        code: "versienummer",
        color1: "Rood stijgt en groen daalt",
        color2: "Groen stijgt en rood daalt",
        setLangue: "taalinstelling",
        vip: "VIP-voordelen",
        vip2: "Huidig ​​VIP-niveau",
        safeMenu: "Beveiligings centrum",
        conventional: "conventioneel",
        other: "ander",
        quickTips: "Voeg maximaal 3 sneltoetsfunctieknoppen toe",
        quickTransaction: "Snelle transactie",
        safeConvenient: "Veilig en handig",
        timeSharing: "tijd delen",
        currentLevel: "huidig ​​niveau",
        levelBenefits: "%{level} inkomensniveau",
        teamLevel: "Teamniveau",
        levelLower: "%{level}ondergeschikt",
        rules: "Regelbeschrijving",
        bindWithdrawalAddress: "Bind intrekkingsadres",
        tipsBindWithdrawalAddress: "U heeft uw opnameadres nog niet vastgelegd. Bind dit eerst in",
        walletAddressFormatIncorrect: "Het portemonnee-adresformaat is onjuist",
        tradeRules1: "Voorspel de opkomst en ondergang in de toekomstige periode. De prijs op het moment van de bestelling wordt gebruikt als de startprijs en de prijs op de laatste seconde van de voorspellingsperiode wordt gebruikt als de afwikkelingsprijs. Als de afwikkelingsprijs hoger is dan de startprijs, betekent dit een stijging. prijs lager is dan de vanafprijs, betekent dit een daling.",
        tradeRules2: "1.wat is een contract",
        tradeRules3: "Dit platform biedt op USDT gebaseerde eeuwigdurende contracten. Gebruikers kunnen de stijging of daling beoordelen en ervoor kiezen om long- of short-contracten te kopen om winst te behalen uit de stijging/daling van de prijzen van digitale activa. Eeuwigdurende contracten hebben geen leverdatum en gebruikers kunnen ze voor altijd vasthouden.",
        tradeRules4: "2.Wat is marge",
        tradeRules5: "Op de virtuele contractmarkt hoeven gebruikers slechts een klein bedrag aan geld te betalen in een bepaalde verhouding volgens de contractprijs als financiële garantie voor de uitvoering van het contract, en vervolgens kunnen ze deelnemen aan de aan- en verkoop van het contract. Dit soort geld is de virtuele contractaanbetaling. \nDe contracten op dit platform hanteren het afzonderlijke margemodel van afzonderlijke accounts. Elke valuta komt overeen met een contractrekening. De rekeningactiva en -posities van verschillende contractvaluta's zijn onafhankelijk van elkaar, en overdrachten en transacties tussen verschillende contractrekeningen hebben geen invloed op elkaar. Nadat de contractrekening van een gebruiker is geopend, worden de risico's en voordelen van alle posities op de contractrekening samen berekend.",
        tradeRules6: "3.Hoe de marge te berekenen",
        tradeRules7: "Positiemarge = nominale contractwaarde * aantal openstaande contracten / hefboomvermenigvuldiger\nbevroren marge = de som van de marges van alle succesvol geplaatste orders en openingsorders in de huidige order\nbeschikbare marge = rekeningsaldo - positiemarge - bevroren marge",
        tradeRules8: "4.Hoe u het margepercentage kunt berekenen",
        tradeRules9: "De marge is een maatstaf voor het risico van gebruikersactiva. \nMargepercentage = (niet-gerealiseerde winst en verlies + positiemarge + beschikbare marge + bevriezingsmarge - liquidatiekosten) / positiemarge \nHoe kleiner het margepercentage, hoe hoger het risico van de rekening. Wanneer de marge lager dan of gelijk aan 0% is, zal gedwongen liquidatie plaatsvinden.",
        tradeRules10: "5.Wat is de geschatte gedwongen pariteitsprijs?",
        tradeRules11: "De geschatte prijs voor een gedwongen liquidatie, dat wil zeggen de prijs die wordt berekend door het systeem dat theoretisch tot een gedwongen liquidatie leidt, dient alleen ter referentie en kan enigszins afwijken van de werkelijke waarde.",
        tradeRules12: "6.Wat is het totale rekeningvermogen en de ongerealiseerde winst en verlies?",
        tradeRules13: "Totaal rekeningvermogen = rekeningsaldo + ongerealiseerde winst en verlies\nDe ongerealiseerde winst en verlies zijn de winst en het verlies van de positie die momenteel door de gebruiker wordt aangehouden in het valutacontract. De ongerealiseerde winst en verlies zullen veranderen op basis van de laatste wijzigingen in de transactieprijs. \nNiet-gerealiseerde winst en verlies voor longposities = (1/gemiddelde positieprijs - 1/laatste transactieprijs) * aantal contracten voor longposities * nominale contractwaarde * laatste transactieprijs \n ongerealiseerde winst en verlies voor shortposities = (1/ laatste transactieprijs - 1/ Gemiddelde positieprijs) * Aantal korte contracten * Nominale waarde contract * Laatste transactieprijs",
        text1: "accountbeveiliging",
        text2: "dag- en nachtmodus",
        text3: "Cache wissen",
        text4: "Weet u zeker dat u wilt uitloggen?",
        text5: "Weet u zeker dat u de cache wilt wissen?",
        text6: "meertalig",
        text7: "Om uw veiligheid te beschermen, raden we u aan ten minste één tweefactorauthenticatie in te schakelen"
    },
    login: {
        login: "Log in",
        register: "register",
        title1: "Handel altijd en overal en bekijk realtime marktomstandigheden",
        title2: "Handel altijd en overal en bekijk realtime marktomstandigheden",
        title3: "Het meest vertrouwde handelsplatform voor cryptocurrency",
        formTitle1: "Account login",
        formTitle2: "Welkom terug! Log in met uw e-mailadres, telefoonnummer",
        btn1: "Telefoon",
        btn2: "Mail",
        btn3: "Log in",
        btn4: "register",
        btn5: "wachtwoord vergeten?",
        field1: "Telefoonnummer",
        field2: "wachtwoord",
        field3: "Mail",
        placeholder1: "natie",
        placeholder2: "Voer uw telefoonnummer in",
        placeholder3: "Voer wachtwoord in alstublieft",
        placeholder4: "Voer uw e-mailadres in",
        content12: "Voer uw telefoonnummer in",
        content13: "Voer wachtwoord in alstublieft",
        content14: "Voer uw e-mailadres in",
        content16: "Financiële diensten voor digitale activa",
        loginSuccess: "Succesvol ingelogd ",
        chooseArea: "Selecteer het netnummer",
        placeholder5: "Selecteer de landcode",
        placeholder6: "onthoud mijn wachtwoord",
        placeholder7: "Download app",
        placeholder8: "Contacteer de Klantenservice",
        placeholder9: "Selecteer land of regio",
        placeholder10: "netnummer",
        placeholder11: "Wallet-login",
        placeholder12: "Bewerking mislukt. Schakel over naar",
        placeholder13: "portemonnee"
    },
    aboutUs: {
        aboutCoinbeadCenterTitle: "over",
        aboutCoinbeadCenterDetails: "Het heeft 's werelds toonaangevende blockchain-handelsplatform voor activa en beheert het hele ecosysteem.",
        footDetails1: "dagelijks handelsvolume",
        footDetails2: "transacties/seconde",
        footDetails3: "Helpcentrum",
        coinbeadCenterTitle: "",
        coinbeadCenterDetails: "Het is een wereldberoemde internationale website voor digitale activa, die voornamelijk munten en derivaten van digitale activa zoals Bitcoin (BTC), Litecoin (LTC) en Ethereum (ETH) aanbiedt aan wereldwijde gebruikers.",
        coinbeadCenterDetailsm: "Door gebruik te maken van geavanceerde technologieën zoals GSLB, netwerkservers, netwerktransacties, netwerken, multi-machine high-speed geheugenhandelsmotoren, cold wallets, intelligente offline hot wallets, enz., bedienen we onze klanten met meerdere terminals zoals mobiele webtelefoons en pc's Zorg voor veilige, stabiele en betrouwbare digitale tarieftransactiediensten. Tegelijkertijd verbeteren en upgraden we producten en diensten op basis van gebruikerssuggesties en moeten we elke klant een betere dienstverlening bieden, en er komt geen einde aan de verbetering van de innovatieve gebruikerservaring.",
        infrastructureCenterTitle: "infrastructuur",
        infrastructureCenterDetails: "Onze missie is het leveren van infrastructuurdiensten voor het gehele blockchain-ecosysteem",
        freeCenterTitle: "vrij",
        freeCenterDetails: "Onze visie is om geld vrijelijk over de hele wereld te laten stromen. Wij geloven dat het verspreiden van deze vrijheid de wereld een betere plek kan maken",
        futureCenterTitle: "Pionieren in de toekomst van technologie",
        futureCenterDetails: "Open de toekomst van technologie",
    },
    applicationForListing: {
        title: "Toepassing voor het noteren van munten",
        projectName: "Naam van het project",
        tokenName: "Tokennaam",
        projectDescription: "Projectbeschrijving",
        contractAddress: "Contractadres",
        officialWebsite: "officiële website",
        ContactAndEmail: "Contact email",
        ContactAndName: "Contactnaam",
        file: "Bijlage uploaden",
        pleaseEnter: "kom binnen alstublieft",
        enterFile: "Upload bestanden",
        upload1: "Momenteel beperkt tot 1 bestandsselectie,",
        upload2: "Deze keer heb ik gekozen",
        upload3: "bestanden,",
        upload4: "Totaal geselecteerd",
        upload5: "bestanden",
    },
    public: {
        submit: "indienen",
        copySuccessfully: "Gekopieerd",
        copyFailed: "Kopiëren mislukt",
        noDate: "Geen gegevens",
        submitSuccessfully: "Succesvol ingediend",
        goBack: "ga terug naar de laatste pagina",
        cancel: "Annuleren",
        home: "Home",
        loading: "bezig met laden...",
        confirm: "bevestigen",
        loadingFailed: "netwerk anomalie",
        nomore: "niet meer",
        tryAgain: "Ververs de pagina/verlaat het programma en upload opnieuw!",
        hot: "Populaire gebieden",
    },
    inviteFriends: {
        inviteFriends: "Delen met vrienden",
        freedomOfWealth: "Laat rijkdom samen vrij zijn",
        myInvitationCode: "mijn uitnodigingscode",
        clickCopy: "Klik om te kopiëren",
        myInvitationLink: "Mijn uitnodigingslink",
        clicktoSaveTheQRCode: "Klik om de QR-code op te slaan",
        saveQRCode: "QR-code uitnodigen",
        numberOfSubordinates: "Aantal ondergeschikten",
        rebateIncome: "Korting inkomen",
        people: "mensen",
        myPromotionRevenue: "Mijn promotie-inkomsten",
        numberOfDirectSubordinates: "Aantal directe ondergeschikten",
        totalNumberOfSubordinates: "Totaal aantal ondergeschikten",
        directSubordinateReturnsCommission: "Commissiekorting voor directe ondergeschikten",
        theTotalCommission: "totale korting",
        checkHistory: "Bekijk kortingsrecords",
        history: "Commissierecord",
        theLowerAccount: "Ondergeschikte rekening",
        type: "type：",
        serviceCharge: "verwerkingskosten：",
        percentageofCommissionReturned: "Kortingsverhouding：",
        commissionrebateAmount: "Kortingsbedrag：",
        time: "tijd：",
        typeName1: "Opladen en provisie",
        typeName2: "MT-orderverwerkingskosten distributie op drie niveaus",
        typeName3: "MT Winstgevende distributie op drie niveaus",
        typeName4: "Verwijzingsbeloningen",
        typeName30: "Opladen en provisie",
        typeName31: "Periodieke handelswinstkorting",
        typeName32: "Winstkorting voor liquiditeitsmijnbouw",
        typeName45: "Korting op transactiekosten",
        typeName46: "Provisiekorting op eigen administratiekosten",
        typeName50: "Korting op administratiekosten voor leveringscontracten",
        typeName51: "Korting op eeuwigdurende contractafhandelingskosten",
        typeName52: "Extra commissiekorting voor de afhandelingskosten van het leveringscontract",
        typeName53: "Extra commissiekorting voor afhandelingskosten voor eeuwigdurende contracten",
        typeName54: "Extra kortingen op afhandelingskosten voor eeuwigdurende contracten",
        shareRules1: "Uitnodigingsinstructies",
        shareRules2: "Ga naar het uitnodigingscentrum, kopieer de uitnodigingslink of code en deel deze met je vrienden. Vrienden kunnen uw ondergeschikten worden door zich te registreren met uw uitnodigingscode.",
        shareRules3: "Verdien kortingen",
        shareRules4: "Wanneer ondergeschikten transacties uitvoeren, kunt u overeenkomstige commissies ontvangen. Er worden maximaal drie niveaus van ondergeschikten ondersteund. Als u bijvoorbeeld vriend A uitnodigt, nodigt A B uit en B nodigt C uit, dan kunnen A, B en C contracten en andere transacties op het platform uitvoeren en kunnen jullie allemaal de bijbehorende commissies ontvangen.",
        shareRules5: "Teamniveau",
        shareRules6: "Hoe meer ondergeschikten op het eerste niveau u promoveert, hoe hoger het teamniveau en hoe hoger de korting waarvan u kunt genieten. Teamniveaus zijn onderverdeeld in LV1 t/m LV6. De upgraderegels worden weergegeven in de onderstaande tabel, waarbij 'N' het aantal ondergeschikten op het eerste niveau is dat is opgewaardeerd en de authenticatie met echte naam heeft voltooid.",
        shareRules7: "Wanneer ondergeschikten weddenschappen plaatsen op leveringscontracten, kunt u een commissie ontvangen in verhouding tot hun weddenschappen.",
        upgradeConditions: "Vereisen",
        recommendedNumberOfPeople: "Aanbevolen aantal personen"
    },
    suggestionsAndFeedback: {
        suggestionsAndFeedback: "Suggesties en feedback",
        text: "Als u tijdens het gebruik bugs tegenkomt of productsuggesties heeft, geef ons dan feedback.",
        email: "e-mailadres",
        enterEmail: "Voer een e-mailadres in",
        enterCorrectEmail: "Voer het juiste e-mailadres in",
        title: "titel",
        enterTitle: "Voer een vraagtitel in",
        describeTheProblem: "Beschrijf het probleem",
        enterDescribeTheProblem: "Voer het probleem in dat u beschrijft",
        submit: "indienen",
    },
    help: {
        enterSearch: "zoekopdracht",
        title: "Helpcentrum",
        search: "Voer uw vraag in",
        text1: "Begrijp uw behoeften en blijf u handigere services bieden"
    },
    announcement: {
        enterSearch: "zoekopdracht",
        title: "Aankondigingscentrum",
        search: "Voer uw vraag in"
    },
    privacy: {
        title: "Privacyverklaring",
        content1: "1. Welke persoonlijke informatie over gebruikers wordt verzameld?",
        content2: "Persoonlijke informatie wordt verzameld om producten en diensten te leveren en deze voortdurend te verbeteren.",
        content3: "Hieronder volgen de soorten persoonlijke gegevens die we verzamelen：",
        content4: "Informatie die u ons verstrekt: Wij ontvangen en bewaren alle informatie die u verstrekt in verband met het Platform. U kunt ervoor kiezen bepaalde gegevens niet te verstrekken, maar het kan zijn dat u daardoor van bepaalde ‘diensten van dit platform’ geen gebruik kunt maken. Voorbeelden van informatie die u aan ons verstrekt, zijn onder meer:",
        content5: "Naam",
        content6: "adres",
        content7: "Land van staatsburgerschap",
        content8: "ID-foto",
        content9: "Mobiel telefoonnummer/e-mailadres",
        content10: "Overige informatie waarmee wij u kunnen identificeren",
        content11: "Automatisch verzamelde informatie: We verzamelen en bewaren automatisch bepaalde soorten informatie over uw gebruik van de Platformdiensten. Zoals veel websites gebruiken we cookies en andere unieke identificatiegegevens. We verkrijgen bepaalde soorten informatie wanneer uw webbrowser of apparaat toegang krijgt tot het Platform. Voorbeelden van informatie die we verzamelen en analyseren zijn onder meer:",
        content12: "Het Internet Protocol (IP)-adres dat een pc met internet verbindt;",
        content13: "Inloggegevens, e-mailadres, wachtwoord en locatie van een persoonlijk apparaat of computer;",
        content14: "Versie- en tijdzone-instellingen;",
        content15: "Transactie Geschiedenis.",
        content16: "Informatie uit andere bronnen: We kunnen informatie over u verzamelen uit andere bronnen, zoals de kredietgeschiedenis van kredietbureaus. Wij zullen deze informatie gebruiken om fraude te voorkomen en op te sporen.",
        content17: "2. Kan het door kinderen worden gebruikt?",
        content18: 'Dit platform staat niet toe dat personen jonger dan 18 jaar gebruik maken van de "Service".',
        content19: "3. Waar worden cookies en andere identificatiegegevens voor gebruikt?",
        content20: "We gebruiken cookies en vergelijkbare tools om de gebruikerservaring te optimaliseren, onze diensten aan te bieden en te begrijpen hoe klanten onze diensten gebruiken, zodat we gerichte verbeteringen kunnen aanbrengen. Wij gebruiken cookies om ervoor te zorgen dat ons systeem uw browser of apparaat kan herkennen en u diensten kan leveren.",
        content21: 'We gebruiken operationele cookies en soortgelijke tools (gezamenlijk "Cookies") om diensten te verlenen, zoals:',
        content22: "Om u te identificeren wanneer u zich aanmeldt en onze diensten gebruikt.",
        content23: "Bied aangepaste functies en services.",
        content24: "Bescherm tegen frauduleuze activiteiten.",
        content25: "Verbeter de beveiliging.",
        content26: "Volg uw voorkeuren (zoals valuta en taal).",
        content27: "Ook gebruiken wij cookies om te begrijpen hoe gebruikers onze diensten gebruiken, zodat wij gerichte verbeteringen kunnen doorvoeren.",
        content28: "4. Worden mijn persoonlijke gegevens gedeeld?",
        content29: "Gebruikersinformatie is een belangrijk onderdeel van ons bedrijf en we zullen de persoonlijke informatie van gebruikers niet aan anderen verkopen. Dit platform deelt de persoonlijke informatie van gebruikers alleen onder de volgende omstandigheden met zijn dochterondernemingen of gelieerde ondernemingen. Deze dochterondernemingen of gelieerde ondernemingen zijn verplicht zich aan deze privacyverklaring te houden of op zijn minst praktijken te volgen die even beschermend zijn als de beschermingsmaatregelen die in deze privacyverklaring worden beschreven.",
        content30: "Externe dienstverleners: Wij schakelen andere bedrijven en personen in om namens ons taken uit te voeren. Voorbeelden van dergelijke functies zijn onder meer: ​​het analyseren van gegevens, het bieden van marketingondersteuning, het verwerken van betalingen, het verzenden van inhoud en het beoordelen en beheren van kredietrisico's. Deze externe dienstverleners hebben toegang tot persoonlijke informatie die nodig is om hun functies uit te voeren, maar mogen deze niet voor andere doeleinden gebruiken. Bovendien moeten zij persoonlijke informatie verwerken in overeenstemming met deze privacyverklaring en de toepasselijke wetgeving inzake gegevensbescherming.",
        content31: "Bedrijfsoverdrachten: Naarmate ons bedrijf blijft groeien, kunnen we andere bedrijven of diensten verkopen of kopen. Bij dergelijke transacties behoort gebruikersinformatie doorgaans tot de overgedragen bedrijfsmiddelen, maar blijft deze onderworpen aan de beloften in een reeds bestaande privacyverklaring (tenzij de gebruiker uiteraard anders instemt). Bovendien zou, als ons bedrijf of vrijwel al zijn activa zouden worden overgenomen, ook gebruikersinformatie worden overgedragen. Bescherming van ons bedrijf en anderen: Wanneer wij van mening zijn dat het vrijgeven van account- en andere persoonlijke informatie gepast is om te voldoen aan de wet of onze wettelijke verplichtingen, onze gebruiksvoorwaarden en andere overeenkomsten af ​​te dwingen of toe te passen, of de rechten, eigendommen of veiligheid van onze gebruikers of anderen Wanneer zullen we het account en andere persoonlijke informatie vrijgeven. Dit omvat het uitwisselen van informatie met andere bedrijven en organisaties om te beschermen tegen fraude en het kredietrisico te verminderen.",
        content32: "5. Internationale overdracht van persoonsgegevens",
        content33: 'Wij kunnen uw gegevens buiten de Europese Economische Ruimte ("EER") overdragen. We zullen passende waarborgen treffen om ervoor te zorgen dat dergelijke overdrachten voldoen aan de toepasselijke regels voor gegevensbescherming, tenzij de Europese Commissie heeft bevestigd dat het land waarnaar de gegevens worden overgedragen een passend beschermingsniveau biedt.',
        content34: "6. Zijn mijn persoonlijke gegevens veilig?",
        content35: "We hebben onze systemen ontworpen met uw veiligheid en privacy in gedachten. We gebruiken encryptieprotocollen en software om de veiligheid van persoonlijke informatie tijdens de verzending te beschermen.",
        content36: "We nemen altijd fysieke, elektronische en procedurele veiligheidsmaatregelen bij het verzamelen, opslaan en openbaar maken van persoonlijke informatie. Onze beveiligingsprocedures houden in dat we mogelijk uw identiteit moeten verifiëren voordat we persoonlijke gegevens aan u vrijgeven.",
        content37: "Het belangrijkste dat u kunt doen, is uw persoonlijke accountwachtwoorden beschermen tegen ongeoorloofde toegang. We raden u aan een uniek wachtwoord in te stellen voor uw account, dat verschilt van andere online accounts. En zorg ervoor dat u uitlogt nadat u een gedeelde computer hebt gebruikt.",
        content38: "7. Hoe moet ik persoonlijke informatie beschermen?",
        content39: "Als u vragen of bezwaren heeft over de manier waarop wij persoonlijke gegevens verzamelen en verwerken, kunt u contact opnemen met medewerkers van de klantenservice",
        content40: "Nadat u ermee heeft ingestemd dat wij uw persoonlijke gegevens voor een specifiek doel verwerken, kunt u uw voornemen op elk gewenst moment intrekken. Wij zullen dan stoppen met het verwerken van uw gegevens voor dat doel.",
        content41: "Daarnaast heeft u het recht om toegang tot, rectificatie en verwijdering van persoonsgegevens te verzoeken en om gegevensportabiliteit aan te vragen, met inachtneming van de toepasselijke wetgeving. U kunt ook bezwaar maken tegen onze verwerking van uw persoonsgegevens, of verzoeken dat onze verwerking van uw persoonsgegevens onder bepaalde omstandigheden wordt beperkt.",
        content42: "8. EU AVG en Britse gegevensbeschermingswet – Wettelijke basis",
        content43: "De AVG van de EU en de Britse wetgeving inzake gegevensbescherming vereisen dat we de wettelijke basis naleven bij het gebruik van persoonlijke informatie. Onze grondslag is afhankelijk van de specifieke doeleinden waarvoor de persoonsgegevens worden gebruikt. Deze bases omvatten:",
        content44: "Uitvoering van een contract – om u te voorzien van of met u te communiceren over producten of diensten, inclusief ons gebruik van uw persoonlijke gegevens om bestellingen te accepteren en te verwerken en betalingen te verwerken.",
        content45: "Onze legitieme zakelijke belangen en gebruikersbelangen - We detecteren en voorkomen fraude en misbruik om de veiligheid van onze gebruikers, onszelf of anderen te beschermen.",
        content46: "Uw toestemming – Wij hebben uw toestemming nodig om uw persoonlijke gegevens te verwerken voor de specifieke doeleinden die aan u zijn gecommuniceerd. Zodra u ons toestemming heeft gegeven om uw persoonlijke gegevens voor een specifiek doel te verwerken, kunt u uw toestemming op elk gewenst moment intrekken. Wij zullen dan stoppen met het verwerken van uw gegevens voor dat doel.",
        content47: "Voldoen aan wettelijke verplichtingen - Wij gebruiken uw persoonlijke gegevens zoals vereist door de wet. We verzamelen bijvoorbeeld bankrekeninggegevens voor identiteitsverificatiedoeleinden.",
        content48: "Bovenstaande en andere rechtsgrondslagen zijn afhankelijk van de specifieke doeleinden waarvoor wij persoonlijke informatie gebruiken.",
        content49: "9. Gebruiksvoorwaarden, mededelingen en wijzigingen",
        content50: "Op uw gebruik en elk geschil over privacy zijn deze Verklaring en onze Gebruiksvoorwaarden van toepassing. Als u zich zorgen maakt over de privacy van dit platform, neem dan contact met ons op met een gedetailleerde uitleg en wij zullen het probleem graag voor u oplossen. U heeft ook het recht om contact op te nemen met uw lokale gegevensbeschermingsautoriteit.",
        content51: "Ons bedrijf verandert voortdurend, en dat geldt ook voor onze privacyverklaring. U dient onze website regelmatig te raadplegen om de laatste wijzigingen te zien. Als u het niet eens bent met de herziene inhoud, moet u de toegang onmiddellijk stopzetten. Nadat de bijgewerkte versie van het Privacybeleid is vrijgegeven, betekent uw voortdurende toegang dat u akkoord gaat met de bijgewerkte inhoud en dat u ermee instemt zich aan het bijgewerkte Privacybeleid te houden. Tenzij anders vermeld, is de huidige Privacyverklaring van toepassing op alle informatie die wij over u en uw account bewaren.",
    },
    serviceAgreement: {
        title: "Serviceovereenkomst",
        content1: 'Deze website is een platform specifiek voor gebruikers om transacties met digitale activa uit te voeren en aanverwante diensten aan te bieden (hierna "de dienst" of "de dienst" genoemd). Voor het gemak van het beschrijven van deze overeenkomst wordt op deze website in deze overeenkomst gezamenlijk gebruik gemaakt van "wij" of andere voornaamwoorden in de eerste persoon. Zolang de natuurlijke persoon of andere persoon die inlogt op de website een gebruiker van deze website is, wordt hieronder voor het gemak van het uitdrukken van deze overeenkomst "u" of een andere tweede persoon gebruikt. Voor het gemak van het uitdrukken van deze Overeenkomst worden wij en u in deze Overeenkomst gezamenlijk de "Partijen" genoemd, en worden wij of u individueel een "Partij" genoemd.',
        content2: "belangrijke aanwijzing：",
        content3: "Wij willen u hier graag aan herinneren：",
        content4: "1 De digitale activa zelf zijn niet uitgegeven door een financiële instelling of bedrijf of deze website",
        content5: "2 De markten voor digitale activa zijn nieuw, onbevestigd en groeien mogelijk niet;",
        content6: "3 Digitale activa worden voornamelijk op grote schaal gebruikt door speculanten, met relatief weinig gebruik op de retail- en commerciële markten. Transacties met digitale activa zijn uiterst riskant. Ze worden de hele dag door continu verhandeld, zonder limiet op stijging en daling, en prijzen worden gemakkelijk beïnvloed door marktomstandigheden. makers en mondiaal overheidsbeleid fluctuatie;",
        content7: "4  Het Bedrijf behoudt zich het recht voor om uw account op elk moment op te schorten of te beëindigen als het Bedrijf, naar eigen goeddunken, vaststelt dat u deze Overeenkomst hebt geschonden, of dat de diensten die door deze website worden aangeboden of uw gebruik van de diensten die door deze website worden aangeboden, illegaal volgens de wetten van uw rechtsgebied, of uw gebruik van de diensten of transacties met digitale activa die door deze website worden aangeboden, opschorten of beëindigen. Het is iedereen uit [het vasteland van China, Taiwan, Israël, Irak, Bangladesh, Bolivia, Ecuador, Kirgizië, Sebastopol en het Verenigd Koninkrijk (retailgebruikers)] verboden gebruik te maken van de contracttransactiediensten die door deze website worden aangeboden. De bovengenoemde lijst met landen of regio's zal veranderen afhankelijk van het beleid en de producttypen van verschillende landen of regio's. Het is mogelijk dat we u op dat moment niet specifiek op de hoogte stellen, dus let tijdig op updates van deze overeenkomst.",
        content8: "Het handelen in digitale activa brengt extreem hoge risico's met zich mee en is voor de meeste mensen niet geschikt. U begrijpt en begrijpt dat deze transactie kan leiden tot gedeeltelijk of totaal verlies. U dient het bedrag van de transactie daarom te bepalen op basis van de mate van verlies die u zich kunt veroorloven. U weet en begrijpt dat digitale activa derivatenrisico's met zich meebrengen, dus als u vragen heeft, is het raadzaam eerst de hulp in te roepen van professionele adviseurs. Daarnaast zijn er naast de hierboven genoemde risico’s ook risico’s die niet te voorspellen zijn. U moet uw financiële situatie en de bovengenoemde risico's zorgvuldig overwegen en gebruiken om uw financiële situatie en de bovengenoemde risico's te beoordelen voordat u een beslissing neemt om digitale activa te kopen of verkopen, en alle daaruit voortvloeiende verliezen te dragen. Wij aanvaarden hiervoor geen enkele verantwoordelijkheid.",
        content9: "Waarschuwing voor jou：",
        content10: "1 U begrijpt dat deze website alleen wordt gebruikt als een plaats waar u informatie over digitale activa kunt verkrijgen, transactiepartijen kunt vinden, kunt onderhandelen en transacties met digitale activa kunt uitvoeren. Deze website neemt niet deel aan uw transacties, dus u moet uw eigen voorzichtige oordeel gebruiken om de relevante digitale activa en/of transacties of de authenticiteit, wettigheid en geldigheid van de informatie vast te stellen, en zal de daaruit voortvloeiende verantwoordelijkheden en verliezen op eigen kracht dragen.",
        content11: "2 Alle meningen, nieuws, discussies, analyses, prijzen, aanbevelingen en andere informatie op deze website zijn algemene marktcommentaren en vormen geen beleggingsadvies. Wij zijn niet verantwoordelijk voor eventuele verliezen die direct of indirect voortvloeien uit het vertrouwen op deze informatie, inclusief maar niet beperkt tot winstderving.",
        content12: "3 Deze website behoudt zich het recht voor om op elk moment zijn eigen beslissing te nemen en de inhoud te wijzigen of te wijzigen. We hebben redelijke maatregelen genomen om de nauwkeurigheid van de informatie op de website te garanderen, maar we kunnen de nauwkeurigheid ervan niet garanderen en we zijn niet verantwoordelijk voor verlies of schade veroorzaakt door de informatie op deze website of door het gebrek aan informatie. Directe of indirecte verliezen die voortkomen uit vertragingen of storingen bij het verbinden met internet, het verzenden of ontvangen van mededelingen en informatie.",
        content13: "4 Er zijn ook risico's verbonden aan het gebruik van op internet gebaseerde handelssystemen, inclusief maar niet beperkt tot software-, hardware- en internetverbindingsfouten. Omdat wij geen controle hebben over de betrouwbaarheid en beschikbaarheid van internet, aanvaarden wij geen verantwoordelijkheid voor vervorming, vertragingen en verbindingsstoringen.",
        content14: "5 Het is verboden deze website te gebruiken voor alle illegale handelsactiviteiten of illegale activiteiten zoals het witwassen van geld, smokkel, commerciële omkoping, enz. Als er vermoedelijke illegale transacties of illegale activiteiten worden aangetroffen, zal deze website verschillende beschikbare middelen inzetten, waaronder maar niet beperkt tot het bevriezen van rekeningen en het op de hoogte stellen van relevante partijen, autoriteiten, enz., wij aanvaarden niet alle verantwoordelijkheden die daaruit voortvloeien en behouden ons het recht voor om aansprakelijkheid te eisen van relevante personen.",
        content15: "6 Het is verboden om deze website te gebruiken voor kwaadwillige manipulatie van de markt, ongepaste transacties en andere onethische handelsactiviteiten. Als dergelijke incidenten worden ontdekt, zal deze website waarschuwen, transacties beperken en al het onethische gedrag sluiten, zoals kwaadwillige prijsmanipulatie en kwaadwillige invloed op de markt. Wij aanvaarden niet alle aansprakelijkheid die voortvloeit uit preventieve beschermingsmaatregelen zoals het opschorten van accounts en behouden ons het recht voor om aansprakelijkheid van relevante personen te vervolgen.",
        content16: "1. Algemene principes",
        content17: '1.1 "Gebruikersovereenkomst" (hierna "deze Overeenkomst" of "Deze Algemene Voorwaarden" genoemd) bestaat uit de hoofdtekst "Privacyvoorwaarden", "Ken uw klant en het antiwitwasbeleid" en diverse andere beleidsregels die zijn gepubliceerd of kunnen in de toekomst worden gepubliceerd op deze website. Klassenregels, verklaringen, beschrijvingen, etc.',
        content18: "1.2 U dient deze overeenkomst aandachtig door te lezen voordat u gebruik maakt van de diensten van deze website. Indien u iets niet begrijpt of indien het anderszins noodzakelijk is, verzoeken wij u een professionele advocaat te raadplegen. Als u op enig moment niet akkoord gaat met deze Overeenkomst en/of de wijziging ervan, stop dan onmiddellijk met het gebruik van de diensten van deze website of log niet langer in op deze website. Zodra u inlogt op deze website, gebruik maakt van de diensten van deze website of ander soortgelijk gedrag, betekent dit dat u de inhoud van deze Overeenkomst heeft begrepen en er volledig mee akkoord gaat, inclusief eventuele wijzigingen die op enig moment door deze website aan deze Overeenkomst zijn aangebracht.",
        content19: '1.3 U kunt lid worden van deze website (hierna "lid" genoemd) door de relevante gegevens in te vullen in overeenstemming met de vereisten van deze website en u succesvol te registreren na andere relevante procedures. Door tijdens de registratie op de knop "Akkoord" te klikken proces, u elektronisch ondertekent, vorm van overeenkomst met het bedrijf, of wanneer u tijdens het gebruik van deze website op een knop met de aanduiding "Akkoord" of een vergelijkbare betekenis klikt, of daadwerkelijk gebruik maakt van de diensten die door deze website worden aangeboden op andere manieren die door deze website zijn toegestaan betekent dit dat u alle voorwaarden van deze Overeenkomst volledig begrijpt, ermee instemt en accepteert. Het ontbreken van uw handgeschreven handtekening heeft geen invloed op de juridische bindende kracht van deze Overeenkomst voor u.',
        content20: "1.4 Nadat u lid bent geworden van deze website, ontvangt u een ledenaccount en bijbehorend wachtwoord, die u zelf bewaart; u bent juridisch verantwoordelijk voor alle activiteiten en evenementen die onder uw account plaatsvinden.",
        content21: "1.5 Alleen leden die lid worden van deze website kunnen het door deze website aangeboden handelsplatform voor digitale activa gebruiken voor transacties en genieten van andere diensten die door deze website worden bepaald en die alleen beschikbaar zijn voor leden; niet-leden kunnen alleen inloggen op de website, door de website bladeren en andere diensten die door deze website worden aangeboden.",
        content22: "1.6 Door u te registreren en gebruik te maken van de diensten en functies die door deze website worden aangeboden, wordt u geacht het volgende te hebben gelezen, begrepen en:",
        content23: "1.6.1 Accepteer gebonden te zijn aan alle bepalingen en voorwaarden van deze Overeenkomst.",
        content24: "1.6.2 U bevestigt dat u ouder bent dan 18 jaar of de wettelijke leeftijd heeft om contracten aan te gaan volgens verschillende toepasselijke wetten. Uw registratie op deze website, verkoop of aankoop, het publiceren van informatie, enz. en het accepteren van de diensten van deze website moeten in overeenstemming zijn met uw vereisten. Heeft jurisdictie over de relevante wet- en regelgeving van een soeverein land of regio, en heeft de volledige mogelijkheid om deze voorwaarden te accepteren, transacties aan te gaan en deze website te gebruiken om transacties met digitale activa uit te voeren.",
        content25: "1.6.3 U garandeert dat alle digitale activa die eigendom zijn van u en betrokken zijn bij de transactie legaal zijn verkregen en eigendom hebben.",
        content26: "1.6.4 U stemt ermee in dat u als enige verantwoordelijk bent voor uw eigen handels- of niet-handelsactiviteiten en voor eventuele winsten of verliezen.",
        content27: "1.6.5 U bevestigt dat de informatie die u verstrekt bij registratie waar en nauwkeurig is.",
        content28: "1.6.6 U stemt ermee in te voldoen aan de vereisten van alle relevante wetgeving, inclusief het rapporteren van eventuele handelswinsten voor belastingdoeleinden.",
        content29: "1.6.7 U stemt ermee in om op geen enkel moment deel te nemen aan gedrag of activiteiten die de belangen van deze website of het bedrijf schaden, al dan niet gerelateerd aan de diensten die door deze website worden aangeboden.",
        content30: "1.6.8 Deze overeenkomst regelt alleen de rechten en verplichtingen tussen u en ons, en heeft geen betrekking op de juridische relaties en juridische geschillen die voortvloeien uit transacties met digitale activa tussen gebruikers van deze website en andere websites en u.",
        content31: "2. Overeenkomstwijzigingen",
        content32: "Wij behouden ons het recht voor om deze Overeenkomst van tijd tot tijd te herzien en op de website aan te kondigen zonder u hiervan afzonderlijk op de hoogte te stellen. Na de herziening wordt het herzieningstijdstip gemarkeerd op de [Laatste Updatetijd] die in deze Overeenkomst wordt weergegeven, en zal het automatisch duren werking zodra deze op de website is gepubliceerd. U dient van tijd tot tijd te bladeren en aandacht te besteden aan de updatetijd en bijgewerkte inhoud van deze Overeenkomst. Als u het niet eens bent met de relevante wijzigingen, moet u onmiddellijk stoppen met het gebruik van de diensten van deze website; uw voortgezette gebruik van de diensten van deze website website betekent dat u akkoord gaat met en gebonden bent aan de herziene overeenkomst.",
        content33: "3. Registratie",
        content34: "3.1 Registratie kwalificaties",
        content35: "U bevestigt en belooft dat wanneer u het registratieproces voltooit of daadwerkelijk gebruik maakt van de diensten die door deze website worden aangeboden op andere manieren die door deze website zijn toegestaan, u de mogelijkheid moet hebben om deze overeenkomst te ondertekenen en de diensten van deze website te gebruiken zoals bepaald door de toepasselijke wetgeving. natuurlijke persoon, rechtspersoon of andere organisatie. Zodra u op de knop Akkoord met registreren klikt, betekent dit dat u of uw geautoriseerde agent akkoord is gegaan met de inhoud van de overeenkomst en dat u door zijn agent bent geregistreerd en gebruik hebt gemaakt van de diensten van deze website. Als u niet over de bovengenoemde vakkwalificaties beschikt, dragen u en uw gemachtigde agent alle daaruit voortvloeiende gevolgen, en behoudt het bedrijf zich het recht voor om uw account te annuleren of permanent te bevriezen, en u en uw gemachtigde agent verantwoordelijk te houden.",
        content36: "3.2 Registratie doel",
        content37: "U bevestigt en belooft dat uw registratie op deze website niet bedoeld is om wet- en regelgeving te overtreden of de volgorde van digitale activatransacties op deze website te verstoren.",
        content38: "3.3 Registratieproces",
        content39: "3.3.1 U stemt ermee in om een ​​geldig e-mailadres, mobiel telefoonnummer en andere informatie op te geven zoals vereist op de gebruikersregistratiepagina van deze website. U kunt het e-mailadres, mobiele telefoonnummer dat u heeft opgegeven of bevestigd of andere methoden die door deze website zijn toegestaan, gebruiken als login manier om deze website te betreden. Indien nodig moet u, in overeenstemming met de relevante wet- en regelgeving van verschillende rechtsgebieden, uw echte naam, identiteitsbewijs en andere relevante informatie opgeven die is vastgelegd in wet- en regelgeving, privacyclausules en antiwitwasclausules, en voortdurend de registratie-informatie bijwerken in op een tijdige, gedetailleerde en nauwkeurige manier. Naar alle oorspronkelijk getypte informatie wordt verwezen als registratie-informatie. U bent verantwoordelijk voor de authenticiteit, volledigheid en nauwkeurigheid van dergelijke informatie en draagt ​​alle directe of indirecte verliezen en nadelige gevolgen die daaruit voortvloeien.",
        content40: "3.3.2 Als de wetten, voorschriften, regels, besluiten en andere voorschriften van het soevereine land of de regio waar u zich bevindt vereisten inzake de echte naam voor mobiele telefoonnummers stellen, gaat u ermee akkoord dat het opgegeven geregistreerde mobiele telefoonnummer onder echte naam is geregistreerd. u het niet verstrekt in overeenstemming met de regelgeving, zullen de gevolgen die voor u worden veroorzaakt, directe of indirecte verliezen en nadelige gevolgen zijn, die voor uw rekening zijn.",
        content41: "3.3.3 Indien u de voor de registratie benodigde gegevens legaal, volledig en effectief verstrekt en deze heeft geverifieerd, heeft u recht op het verkrijgen van het accountnummer en het wachtwoord van deze website. Wanneer u het accountnummer en het wachtwoord van deze website verkrijgt, wordt uw registratie geacht te zijn succesvol en u kunt inloggen als lid op deze website.",
        content42: "3.3.4 U gaat akkoord met het ontvangen van e-mails en/of korte berichten verzonden door deze website met betrekking tot het beheer en de werking van deze website.",
        content43: "4. Dienstverlening",
        content44: "Deze website biedt alleen online handelsplatformdiensten voor uw handelsactiviteiten in digitale activa (inclusief maar niet beperkt tot de handel in digitale activa en andere diensten) via deze website.",
        content45: "4.1 Dienstinhoud",
        content46: "4.1.1 U hebt het recht om in realtime door de marktomstandigheden en transactie-informatie van verschillende digitale activaproducten op deze website te bladeren, en u hebt het recht om via deze website transactie-instructies voor digitale activa in te dienen en transacties met digitale activa te voltooien.",
        content47: "4.1.2 U heeft het recht om de informatie onder uw lidmaatschapsaccount op deze website in te zien en de functies van deze website te laten functioneren.",
        content48: "4.1.3 U heeft het recht om deel te nemen aan website-activiteiten georganiseerd door deze website in overeenstemming met de activiteitenregels die op deze website zijn gepubliceerd.",
        content49: "4.1.4 Deze website belooft u andere diensten aan te bieden.",
        content50: "4.2 Serviceregels U belooft zich te houden aan de volgende serviceregels van deze website:",
        content51: "4.2.1 U zult zich houden aan wetten, voorschriften en beleidsvereisten, de wettigheid van de bronnen van alle digitale activa in uw account garanderen en u zult zich niet inlaten met illegale of andere activiteiten die de rechten en belangen van deze website of derden op deze website schaden. of door gebruik te maken van haar diensten, inclusief maar niet beperkt tot: U bent niet beperkt tot het verzenden of ontvangen van illegale, illegale of inbreukmakende informatie over de rechten van anderen, het verzenden of ontvangen van piramidespelen of andere schadelijke informatie of opmerkingen, het gebruiken of vervalsen van e-mail headerinformatie op deze website zonder toestemming van deze website, etc.",
        content52: "4.2.2 U dient zich te houden aan wet- en regelgeving en uw rekeningnummer, inlogwachtwoord, fondswachtwoord, het mobiele telefoonnummer dat aan u is gekoppeld bij registratie en de door uw mobiele telefoon ontvangen mobiele telefoonverificatiecode op de juiste manier te gebruiken en te bewaren. U bent volledig verantwoordelijk voor alle handelingen en gevolgen van het gebruik van uw account- en inlogwachtwoord, fondswachtwoord en verificatiecode voor uw mobiele telefoon. Wanneer u merkt dat het rekeningnummer, het inlogwachtwoord of het fondswachtwoord of de verificatiecode van deze website door een derde partij worden gebruikt zonder uw toestemming, of dat er andere problemen met de accountbeveiliging zijn, dient u deze website onmiddellijk en effectief op de hoogte te stellen en deze website te verzoeken om op te schorten. de service van het account van deze website. Deze website heeft het recht om binnen een redelijke termijn actie te ondernemen op uw verzoek, maar deze website draagt ​​geen enkele verantwoordelijkheid voor de gevolgen (inclusief maar niet beperkt tot eventuele verliezen die u lijdt) die zijn opgetreden voordat er actie werd ondernomen. U mag uw account op deze website niet aan anderen doneren, lenen, leasen, overdragen of anderszins overdragen zonder toestemming van deze website.",
        content53: "4.2.3 U stemt ermee in verantwoordelijk te zijn voor alle activiteiten die plaatsvinden onder uw account en wachtwoord op deze website (inclusief maar niet beperkt tot het vrijgeven van informatie, het plaatsen van informatie, online klikken om akkoord te gaan of het indienen van verschillende regels en overeenkomsten, online verlenging van overeenkomsten of aankoop van diensten, enz.).",
        content54: "4.2.4 Bij het uitvoeren van transacties met digitale activa op deze website mag u de normale voortgang van transacties met digitale activa niet kwaadwillig verstoren of de volgorde van de transacties verstoren; u mag de normale werking van deze website niet verstoren of het gebruik van de website door andere gebruikers verstoren. diensten op welke technische of andere manier dan ook; u mag geen fictief gebruik maken. Op kwaadaardige wijze de goodwill van deze website in diskrediet brengen door middel van feiten en andere middelen.",
        content55: "4.2.5 Als u een geschil heeft met andere gebruikers als gevolg van online transacties, mag u deze website niet verzoeken om relevante informatie te verstrekken via niet-gerechtelijke of administratieve kanalen.",
        content56: "4.2.6 U oordeelt en draagt ​​uitsluitend de verschuldigde belastingen, evenals alle hardware-, software-, service- en andere kosten die u maakt tijdens uw gebruik van de diensten die door deze website worden aangeboden.",
        content57: "4.2.7 U dient zich te houden aan deze overeenkomst en andere servicevoorwaarden en bedieningsregels die van tijd tot tijd door deze website worden gepubliceerd en bijgewerkt, en u heeft het recht om het gebruik van de diensten die door deze website worden aangeboden op elk moment te beëindigen.",
        content58: "4.3 productregels",
        content59: "4.3.1 Blader door transactie-informatie",
        content60: "Wanneer u door transactie-informatie op deze website bladert, dient u zorgvuldig alle inhoud van de transactie-informatie te lezen, inclusief maar niet beperkt tot prijs, commissievolume, administratiekosten, koop- of verkooprichting, en alleen nadat u volledig akkoord bent gegaan met alle inhoud in deze website. de transactie-informatie Transacties kunnen met één klik op de knop worden uitgevoerd.",
        content61: "4.3.2 Transactiedetails bekijken",
        content62: "Via uw account kunt u de bijbehorende transactiegegevens inzien.",
        content63: "5. Rechten en plichten van deze website",
        content64: "5.1 Als u niet over de registratiekwalificaties beschikt die in deze overeenkomst zijn vastgelegd, heeft deze website het recht om uw registratie te weigeren. Voor degenen die zich al hebben geregistreerd, heeft deze website het recht om uw lidmaatschapsaccount te annuleren. Deze website behoudt zich het recht voor om u vast te houden of uw erkende agent is verantwoordelijk. Tegelijkertijd behoudt deze website zich het recht voor om te beslissen of uw registratie onder andere omstandigheden wordt geaccepteerd.",
        content65: "5.2 Op basis van het eigen oordeel van deze website hebben wij het recht om uw account en het gebruik van alle gekoppelde accounts op te schorten of te beëindigen als deze website constateert dat u of de gebruikers van uw gekoppelde account niet geschikt zijn voor beleggingen met een hoog risico.",
        content66: "5.3 Wanneer deze website ontdekt dat de accountgebruiker niet de initiële registrant van het account is, heeft zij het recht om het gebruik van het account op te schorten of te beëindigen.",
        content67: "5.4 Wanneer deze website redelijkerwijs vermoedt dat de door u verstrekte informatie onjuist, onwaar, ongeldig of onvolledig is door middel van technische tests, handmatige bemonstering en andere testmethoden, heeft zij het recht u hiervan op de hoogte te stellen om de informatie te corrigeren of bij te werken of om de verstrekking van informatie op te schorten of te beëindigen. deze website is u van dienst.",
        content68: "5.5 Deze website behoudt zich het recht voor om de op deze website weergegeven informatie te corrigeren als wordt ontdekt dat er sprake is van een duidelijke fout.",
        content69: "5.6 Deze website behoudt zich het recht voor om de diensten van deze website op elk moment te wijzigen, op te schorten of te beëindigen. Deze website hoeft u niet vooraf op de hoogte te stellen om het recht uit te oefenen om diensten te wijzigen of te beëindigen. Als deze website een of meer diensten van deze website beëindigt website, zal de beëindiging plaatsvinden vanaf deze website op de website, met ingang van de datum van publicatie van de beëindigingsaankondiging.",
        content70: "5.7 Deze website zal de nodige technische middelen en beheersmaatregelen nemen om de normale werking van deze website te garanderen, de noodzakelijke en betrouwbare handelsomgeving en handelsdiensten te bieden en de volgorde van digitale activatransacties te handhaven.",
        content71: "5.8 Als u een jaar lang niet op deze website inlogt met uw lidmaatschapsaccount en wachtwoord, heeft deze website het recht uw account te annuleren. Nadat het account is geannuleerd, heeft deze website het recht om de bijbehorende lidnaam open te stellen voor andere gebruikers voor registratie en gebruik.",
        content72: "5.9 Deze website garandeert de veiligheid van uw digitale activa door investeringen in technologie te versterken, veiligheidsmaatregelen en andere maatregelen te verbeteren, en zal u vooraf op de hoogte stellen wanneer voorzienbare veiligheidsrisico's zich voordoen in uw account.",
        content73: "5.10 Deze website heeft het recht om op elk moment allerlei inhoudsinformatie op deze website te verwijderen die niet voldoet aan de wet- en regelgeving of de voorschriften van deze website. Deze website hoeft u niet vooraf op de hoogte te stellen om dergelijke rechten uit te oefenen.",
        content74: "5.11 Deze website heeft het recht om u te verzoeken om meer informatie of materialen te verstrekken in overeenstemming met de wetten, voorschriften, regels, bevelen en andere wettelijke vereisten van uw soevereine land of regio, en om redelijke maatregelen te nemen om te voldoen aan de lokale wettelijke vereisten. zijn verplicht om mee te werken; deze website heeft het recht om het openen van sommige of alle diensten van deze website voor u op te schorten of permanent te stoppen in overeenstemming met de vereisten van de wetten, voorschriften, regels, bevelen en andere specificaties van uw soevereine land of regio.",
        content75: "5.12 Deze website behoudt zich het recht voor om uw account naar eigen goeddunken met een opzegtermijn van zeven werkdagen te sluiten. U heeft daarom zeven werkdagen de tijd om bestellingen te annuleren en posities te sluiten. Als u de bestelling niet heeft geannuleerd en de positie niet heeft gesloten wanneer de periode verstrijkt, zullen wij de bestelling met kracht annuleren en de positie sluiten, en de resterende digitale activa in uw account aan u retourneren.",
        content76: "5.13 Om de rechten en belangen van handelaars te beschermen, heeft deze website het recht om onder bijzondere omstandigheden (zoals systeemstoring, netwerkstoring, extreme marktomstandigheden etc.) aanpassingen aan online producten aan te brengen, zoals: overeenkomsten voor vroegtijdige levering en afwikkeling, contractsoorten en contracten voor vroegtijdige levering en afwikkeling. De termijn en afwikkeling en leveringsprijs zijn afhankelijk van de aankondiging op deze website.",
        content77: "6. Compensatie",
        content78: "6.1 In geen geval zal onze aansprakelijkheid jegens u voor directe schade hoger zijn dan de totale servicekosten die wij u in rekening hebben gebracht voor uw gebruik van deze website gedurende een periode van drie (3) maanden.",
        content79: "6.2 Als u deze Overeenkomst of andere wet- en regelgeving schendt, moet u ons minimaal 2 miljoen dollar compenseren en alle gemaakte kosten dragen (inclusief advocaatkosten, enz.). Als dit niet voldoende is om de daadwerkelijke verliezen te compenseren, moet u er klaar voor.",
        content80: "7. Recht om voorlopige maatregelen te zoeken",
        content81: "Wij en u erkennen beiden dat de common law-rechtsmiddelen voor uw contractbreuk of mogelijke contractbreuk mogelijk niet voldoende zijn om alle verliezen die we lijden te compenseren. in het geval van uw contractbreuk of mogelijke contractbreuk, alle andere rechtsmiddelen.",
        content82: "8. Beperking van aansprakelijkheid en disclaimer",
        content83: "8.1 U begrijpt en gaat ermee akkoord dat wij onder geen enkele omstandigheid verantwoordelijk zijn voor de volgende zaken:",
        content84: "8.1.1 verlies van inkomen;",
        content85: "8.1.2 handelswinsten of contractverliezen;",
        content86: "8.1.3 Verliezen veroorzaakt door bedrijfsonderbreking;",
        content87: "8.1.4 verlies van verwachte geldbesparingen;",
        content88: "8.1.5 Verliezen veroorzaakt door informatieproblemen;",
        content89: "8.1.6 verlies van kansen, goodwill of reputatie;",
        content90: "8.1.7 corruptie of verlies van gegevens;",
        content91: "8.1.8 de kosten voor het kopen van vervangende producten of diensten;",
        content92: "8.1.9 Enig indirect, speciaal of incidenteel verlies of schade die voortvloeit uit een onrechtmatige daad (inclusief nalatigheid), contractbreuk of enige andere reden, ongeacht of dergelijk verlies of dergelijke schade redelijkerwijs door ons te voorzien is; ongeacht of we al dan niet op de hoogte zijn gesteld van het bestaan ​​van dergelijke schade. vooraf Mogelijkheid van verlies of schade.",
        content93: "8.1.1 De artikelen bij 8.1.9 zijn onafhankelijk van elkaar.",
        content94: "8.2 U begrijpt en gaat ermee akkoord dat wij niet verantwoordelijk zijn voor enige schade die aan u wordt toegebracht als gevolg van een van de volgende omstandigheden:",
        content95: "8.2.1 Uw specifieke transacties kunnen grote schendingen van de wet of contractbreuk met zich meebrengen.",
        content96: "8.2.2 Uw gedrag op deze website kan illegaal of onethisch zijn.",
        content97: "8.2.3 Kosten en verliezen opgelopen door het kopen of verkrijgen van gegevens, informatie of het uitvoeren van transacties via de diensten van deze website of door vervangende acties.",
        content98: "8.2.4 Uw misverstand over de diensten van deze website.",
        content99: "8.2.5 Alle andere verliezen die verband houden met de diensten die door deze website worden geleverd en die niet door ons zijn veroorzaakt.",
        content100: "8.3 Wij zijn verantwoordelijk voor het onderhoud van informatienetwerkapparatuur, storingen in de verbinding van informatienetwerken, computer-, communicatie- of andere systeemstoringen, stroomstoringen, weersomstandigheden, ongevallen, stakingen, arbeidsconflicten, rellen, opstanden, rellen, onvoldoende productiviteit of productiematerialen, brand, overstromingen , stormen, explosies, oorlogen, redenen van banken of andere partners, instorting van de digitale activamarkt, overheidsacties, bevelen van gerechtelijke of administratieve instanties, andere acties die buiten onze controle liggen of die we niet kunnen controleren, of redenen van derden. aanvaardt geen enkele verantwoordelijkheid voor het onvermogen of de vertraging in de dienstverlening, of voor uw verliezen.",
        content101: "8.4 Wij kunnen niet garanderen dat alle informatie, programma's, teksten etc. op deze website volkomen veilig zijn en niet zullen worden verstoord of vernietigd door virussen, Trojaanse paarden en andere kwaadaardige programma's. Als u daarom inlogt en diensten gebruikt op deze website of het downloaden en gebruiken van gedownloade programma's, informatie, gegevens, enz. zijn allemaal uw eigen beslissingen en u draagt ​​de risico's en mogelijke verliezen op eigen risico.",
        content102: "8.5 Wij geven geen garanties of toezeggingen over de informatie, producten en diensten van websites van derden die aan deze website zijn gekoppeld, of enige andere vorm van inhoud die niet van ons is. Als u gebruik maakt van diensten, informatie en diensten die door derden worden aangeboden Websites, Producten etc. van partijen zijn allemaal uw eigen beslissingen en u draagt ​​alle daaruit voortvloeiende verantwoordelijkheden.",
        content103: "8.6 Wij geven geen expliciete of impliciete garanties voor uw gebruik van de diensten op deze website, inclusief maar niet beperkt tot de geschiktheid van de diensten die door deze website worden aangeboden, het ontbreken van fouten of weglatingen, continuïteit, nauwkeurigheid, betrouwbaarheid en geschiktheid voor een bepaalde doel. Tegelijkertijd doen wij geen enkele toezegging of garantie met betrekking tot de geldigheid, nauwkeurigheid, juistheid, betrouwbaarheid, kwaliteit, stabiliteit, volledigheid en actualiteit van de technologie en informatie die betrokken zijn bij de diensten die door deze website worden geleverd. Of u inlogt of de diensten van deze website gebruikt, is uw persoonlijke beslissing en u draagt ​​uw eigen risico's en mogelijke verliezen. We geven geen expliciete of impliciete garanties met betrekking tot de markt, waarde en prijs van digitale activa. U begrijpt en begrijpt dat de markt voor digitale activa onstabiel is. Prijzen en waarden kunnen op elk moment fluctueren of aanzienlijk instorten. Het verhandelen van digitale activa is uw persoonlijke vrijheid.Kies en beslis op eigen risico en mogelijke verliezen.",
        content104: "8.7 Onze garanties en toezeggingen in deze Overeenkomst zijn de enige garanties en toezeggingen die door ons zijn gedaan met betrekking tot deze Overeenkomst en de diensten die op deze website worden aangeboden, en vervangen alle garanties en toezeggingen die op enige andere wijze en wijze ontstaan, schriftelijk of mondeling, uitdrukkelijk van of impliciet. Al deze garanties en verklaringen vertegenwoordigen alleen onze eigen toezeggingen en garanties en garanderen niet dat een derde partij de garanties en toezeggingen in deze Overeenkomst zal naleven.",
        content105: "8.8 Wij doen geen afstand van rechten die niet in deze Overeenkomst worden vermeld om onze aansprakelijkheid voor schade te beperken, uit te sluiten of te compenseren voor zover de toepasselijke wetgeving van toepassing is.",
        content106: "8.9 Nadat u zich heeft geregistreerd, gaat u akkoord met alle handelingen die wij uitvoeren in overeenstemming met de regels die in deze overeenkomst zijn vastgelegd, en eventuele risico's zijn voor uw rekening.",
        content107: "9. Beëindiging van de Overeenkomst",
        content108: "9.1 Deze website heeft het recht om alle diensten van deze website te beëindigen in overeenstemming met deze overeenkomst. Deze overeenkomst eindigt op de datum waarop alle diensten van deze website worden beëindigd.",
        content109: "9.2 Na de beëindiging van deze overeenkomst heeft u niet het recht om van deze website te eisen dat deze diensten blijft verlenen of andere verplichtingen nakomt, inclusief maar niet beperkt tot het eisen dat deze website informatie in zijn oorspronkelijke website-account bewaart of aan u openbaar maakt. aan u of een derde partij. De derde partij stuurt alle informatie door die hij niet heeft gelezen of verzonden.",
        content110: "9.3 De beëindiging van deze overeenkomst heeft geen invloed op de andere verantwoordelijkheden van de niet-schendende partij jegens de inbreukmakende partij.",
        content111: "10. Intellectuele eigendomsrechten",
        content112: "10.1 Alle intellectuele prestaties op deze website omvatten maar zijn niet beperkt tot websitelogo's, databases, websiteontwerpen, tekst en afbeeldingen, software, foto's, video's, muziek, geluiden en combinaties daarvan, softwarecompilaties, gerelateerde broncodes en software (inclusief kleine applicaties ) De intellectuele eigendomsrechten van programma's en scripts) behoren toe aan deze website. U mag de voorgaande materialen of inhoud niet reproduceren, wijzigen, kopiëren, verzenden of gebruiken voor commerciële doeleinden.",
        content113: "10.2 Alle rechten vervat in de naam van deze website (inclusief maar niet beperkt tot goodwill en handelsmerken, logo's) behoren toe aan het bedrijf.",
        content114: "10.3 Uw aanvaarding van deze overeenkomst wordt beschouwd als uw initiatief om het auteursrecht over te dragen van elke vorm van informatie die u op deze website publiceert, inclusief maar niet beperkt tot: reproductierechten, distributierechten, verhuurrechten, tentoonstellingsrechten, uitvoeringsrechten, vertoningsrechten, uitzendrechten, informatie-internettransmissierechten, filmrechten, bewerkingsrechten, vertaalrechten, compilatierechten en andere overdraagbare rechten waarvan de houder van het auteursrecht zou moeten genieten, worden exclusief kosteloos overgedragen aan deze website. Deze website heeft het recht om een ​​onafhankelijke een rechtszaak aanspannen tegen wie dan ook wegens inbreuk en volledige schadevergoeding verkrijgen. Deze overeenkomst is geldig voor alle door het auteursrecht beschermde werkinhoud die u op deze website publiceert, ongeacht of de inhoud vóór de ondertekening van deze overeenkomst of na de ondertekening van deze overeenkomst is gevormd.",
        content115: "10.4 U mag de intellectuele eigendomsrechten van deze website of anderen niet illegaal gebruiken of erover beschikken wanneer u de diensten van deze website gebruikt. U mag de op deze website gepubliceerde informatie in welke vorm dan ook niet publiceren of andere websites (en media) machtigen om deze te gebruiken.",
        content116: "11. Informatiebescherming",
        content117: 'De bepalingen van de "Privacyvoorwaarden", afzonderlijk gepubliceerd op deze website, prevaleren.',
        content118: "12. Berekening",
        content119: "Alle transactieberekeningsresultaten zijn door ons geverifieerd en alle berekeningsmethoden zijn op de website gepubliceerd, maar we kunnen niet garanderen dat het gebruik van de website niet onderbroken of foutloos zal zijn.",
        content120: "13. Exportcontrole",
        content121: "U begrijpt en erkent dat u volgens de relevante wetgeving geen enkel materiaal (inclusief software) op deze website mag exporteren, herexporteren, importeren of overdragen, dus u garandeert dat u de inhoud ervan niet actief zult implementeren, assisteren of eraan zult deelnemen. de hierboven genoemde export of aanverwante activiteiten die in strijd zijn met wet- en regelgeving. Overdracht of andere overtredingen van toepasselijke wet- en regelgeving; als een dergelijke situatie wordt ontdekt, dient u dit actief en tijdig aan ons te melden en ons te helpen bij de afhandeling ervan.",
        content122: "14. Overdracht",
        content123: "De rechten en verplichtingen die in deze Overeenkomst zijn vastgelegd, zijn ook bindend voor de rechtverkrijgenden, erfgenamen, executeurs-testamentairen en beheerders van de partijen die van dergelijke rechten en verplichtingen profiteren. U mag deze zonder onze toestemming niet aan een derde partij overdragen, maar wij kunnen onze rechten en verplichtingen in deze Overeenkomst op elk gewenst moment aan een derde partij overdragen en u hiervan op de hoogte stellen.",
        content124: "15. Deelbaarheid",
        content125: "Als een bepaling van deze Overeenkomst door een bevoegde rechtbank als niet-afdwingbaar, ongeldig of onwettig wordt beschouwd, heeft dit geen invloed op de geldigheid van de overige bepalingen van deze Overeenkomst.",
        content126: "16. Niet-agentschaprelatie",
        content127: "Niets in deze Overeenkomst wordt geacht ons te creëren, impliceren of anderszins te vormen als uw agent, trustee of andere vertegenwoordiger, tenzij anders bepaald in deze Overeenkomst.",
        content128: "17. Onthouding",
        content129: "De afstandsverklaring door ons of u van de aansprakelijkheid van een partij voor contractbreuk of andere aansprakelijkheid die in deze Overeenkomst is vastgelegd, mag niet worden beschouwd of geïnterpreteerd als een afstandsverklaring van andere aansprakelijkheid voor contractbreuk; het niet uitoefenen van enig recht of rechtsmiddel zal in geen geval manier opgevat kan worden als afstand doen van dergelijke rechten of rechtsmiddelen.",
        content130: "18. Titel",
        content131: "Alle kopjes zijn uitsluitend bedoeld voor het gemak van de weergave van de overeenkomst en worden niet gebruikt om de inhoud of reikwijdte van de voorwaarden van de overeenkomst uit te breiden of te beperken.",
        content132: "19. Toepasselijk recht",
        content133: "19.1 Voordat u een geschil of claim indient, erkent u en stemt u ermee in eerst via e-mail contact op te nemen met deze website om het geschil informeel op te lossen. We zullen proberen uw geschil zo snel mogelijk intern op te lossen; beide partijen komen overeen het geschil op te lossen door middel van onderhandelingen te goeder trouw (besprekingen vertrouwelijk zijn en worden beschermd door toepasselijke regels en niet worden gebruikt als bewijsmateriaal in gerechtelijke procedures).",
        content134: '19.2 U erkent en gaat ermee akkoord dat in het geval van een geschil, controverse, meningsverschil of claim, inclusief het bestaan, de geldigheid, de interpretatie, de uitvoering, schending of beëindiging van deze Voorwaarden of enig geschil dat voortvloeit uit of verband houdt met deze Voorwaarden ("Geschil" ) zullen bovengenoemde partijen het geschil voorleggen aan het Internationale Hof van Arbitrage van de Internationale Kamer van Koophandel ("ICC International Court of Arbitration") in overeenstemming met de op dat moment geldende "ICC Rules of Arbitration". De plaats van arbitrage is Belize en het toepasselijke recht van de arbitrage wordt bepaald in overeenstemming met de arbitrageregels van de Internationale Kamer van Koophandel. Tenzij anders overeengekomen door de partijen, wordt er slechts één arbiter benoemd volgens het ICC-arbitragereglement. De arbiter heeft de exclusieve bevoegdheid om uitspraak te doen over zijn of haar eigen jurisdictie, met inbegrip van, maar niet beperkt tot, elk bezwaar tegen het bestaan, de reikwijdte of de geldigheid van de arbitrageovereenkomst of de arbitrabiliteit van enige claim of tegenvordering. Elke arbitrage zal in het Engels worden gevoerd. Ongeacht de wijze waarop de arbitrage wordt uitgevoerd, zal de arbiter een schriftelijk vonnis uitvaardigen dat redelijk en voldoende is om het vonnis en de eventuele essentiële conclusies waarop het vonnis is gebaseerd, toe te lichten. De arbitrale uitspraak is definitief en bindend voor de partijen en kan ten uitvoer worden gelegd bij elke bevoegde rechtbank.',
        content135: "20. Doeltreffendheid en interpretatie van de overeenkomst",
        content136: "20.1 Deze overeenkomst treedt in werking wanneer u op de registratiepagina van deze website klikt om akkoord te gaan met de registratie, het registratieproces voltooit en een account en wachtwoord voor deze website verkrijgt. De overeenkomst is bindend voor zowel deze website als u.",
        content137: "20.2 Het uiteindelijke interpretatierecht van deze overeenkomst behoort toe aan deze website.",
        content138: "Ken uw klanten- en antiwitwasbeleid",
        content139: "1. Inleiding",
        content140: '1.1 Wij beloven dat we de wet- en regelgeving met betrekking tot "Ken uw klant" en de bestrijding van het witwassen van geld zorgvuldig zullen naleven en dat we het "Ken uw klant en het antiwitwasbeleid" niet opzettelijk zullen schenden. Binnen de reikwijdte van onze redelijke controle zullen wij de nodige maatregelen en technologieën nemen om u veilige diensten te verlenen en u zoveel mogelijk te beschermen tegen verliezen veroorzaakt door het witwasgedrag van criminele verdachten.',
        content141: "1.2 Ons Ken uw klant- en antiwitwasbeleid is een alomvattend internationaal beleidssysteem dat het Ken uw klant- en antiwitwasbeleid omvat voor de verschillende rechtsgebieden waarbij u bent aangesloten.",
        content142: "2. Ken uw klant- en antiwitwasbeleid is als volgt:",
        content143: "2.1 Het uitvaardigen van een ken-uw-klant- en anti-witwasbeleid en dit van tijd tot tijd bijwerken om te voldoen aan de normen die zijn vastgelegd in de overeenkomstige wet- en regelgeving;",
        content144: "2.2 Het uitvaardigen en bijwerken van enkele leidende principes en regels voor het exploiteren van deze website, en onze medewerkers zullen diensten verlenen in overeenstemming met de richtlijnen van deze principes en regels;",
        content145: "2.3 Ontwerp en voltooi procedures voor de interne monitoring en controle van transacties, zoals het verifiëren van identiteiten met strikte middelen en het vormen van een professioneel team dat verantwoordelijk is voor de bestrijding van het witwassen van geld;",
        content146: "2.4 Risicopreventiemethoden gebruiken om due diligence en doorlopend toezicht op klanten uit te voeren;",
        content147: "2.5 Het beoordelen en regelmatig controleren van transacties die hebben plaatsgevonden;",
        content148: "2.6 Meld verdachte transacties aan de bevoegde autoriteiten;",
        content149: "2.7 Bewijs van identiteit, adres en transactiegegevens worden minimaal zes jaar bewaard en kunnen zonder verdere kennisgeving aan de regelgevende instanties worden ingediend.",
        content150: "3. Identiteitsinformatie en verificatie",
        content151: "3.1 Identiteitsinformatie",
        content152: "3.1.1 Volgens verschillende regelgeving in verschillende rechtsgebieden en verschillende soorten entiteiten kan de inhoud van de informatie die wij verzamelen inconsistent zijn. In principe wordt de volgende informatie verzameld van geregistreerde personen:",
        content153: "Basis persoonlijke informatie: uw naam, adres (en vast adres, indien verschillend), geboortedatum en nationaliteit en andere beschikbare informatie. Identiteitsverificatie moet gebaseerd zijn op documenten die zijn uitgegeven door officiële of andere soortgelijke autoriteiten, zoals paspoorten, identiteitskaarten of andere identificatiedocumenten die vereist zijn en worden geactiveerd door verschillende rechtsgebieden. Het door u opgegeven adres wordt geverifieerd met behulp van passende methoden, zoals het controleren van het personenvervoerbewijs of de rentenota of het controleren van het kiezersregister.",
        content154: "Geldige foto: Voordat u zich registreert, moet u een foto overleggen waarop u uw identiteitsbewijs tegen uw borst houdt;",
        content155: "Contactgegevens: Telefoon-/mobiel nummer en/of geldig e-mailadres.",
        content156: "3.1.2 Bent u een onderneming of andere rechtspersoon, dan verzamelen wij de volgende gegevens om u of de uiteindelijke begunstigde van uw derdenrekening te identificeren.",
        content157: "Bedrijfsregistratie en registratiecertificaat; kopieën van de statuten en het memorandum van het bedrijf; gedetailleerd certificeringsmateriaal van de aandelenstructuur van het bedrijf en de eigendomsbeschrijving, waaruit het bestuursbesluit blijkt van de bevoegde opdrachtgever die de opening en uitvoering van de rekening op deze website bepaalt; bedrijf bestuurders die indien nodig moeten worden verstrekt, identiteitsdocumenten van de grootaandeelhouder en de persoon die bevoegd is om de rekening op deze website te ondertekenen; het hoofdbedrijfsadres van het bedrijf, indien dit verschilt van het postadres van het bedrijf, geeft het postadres op. Als het lokale adres van een bedrijf niet overeenkomt met het hoofdbedrijfsadres, wordt het beschouwd als een klant met een hoger risico en moet het aanvullende aanvullende documentatie overleggen.",
        content158: "Afhankelijk van de verschillende regelgeving in verschillende rechtsgebieden en verschillende soorten entiteiten, hebben we andere certificeringen en documenten nodig die zijn uitgegeven door autoriteiten, evenals documenten die we noodzakelijk achten.",
        content159: "3.1.3 We accepteren alleen Engelse of Chinese versies van identiteitsgegevens. Zo niet, vertaal dan uw identiteitsgegevens naar het Engels en laat deze notarieel bekrachtigen.",
        content160: "3.2 Bevestig verificatie",
        content161: "3.2.1 Wij vereisen dat u de volledige pagina-inhoud van uw identificatiedocument verstrekt.",
        content162: "3.2.2 Wij vragen om een ​​foto waarop u uw identiteitsbewijs tegen uw borst houdt.",
        content163: "3.2.3 Kopieën van ondersteunende documenten moeten doorgaans worden gecontroleerd aan de hand van de originele vouchers. Een kopie is echter aanvaardbaar als een geschikte en betrouwbare certificeerder kan certificeren dat de kopie een nauwkeurige en volledige reproductie is van het originele document. Dergelijke certificeerders zijn onder meer ambassadeurs, gerechtelijke commissarissen, lokale magistraten, enz.",
        content164: "3.2.4 De vereiste om de uiteindelijke begunstigde en de controle over de rekening te identificeren, is om te bepalen welke personen uiteindelijk eigenaar zijn van of zeggenschap hebben over de directe klant, en/of om vast te stellen dat lopende transacties namens anderen worden uitgevoerd. Als het een bedrijf betreft, moet de identiteit van de grootaandeelhouders (zoals degenen die 10% of meer van de stemrechten bezitten) worden geverifieerd. Over het algemeen wordt het bezit van 25% van de aandelen beschouwd als een normaal risico en moet de identiteit van de aandeelhouder worden geverifieerd; het bezit van 10% van de aandelen of het bezit van meer stemrechten of aandelen wordt als een situatie met een hoog risico beschouwd, en de identiteit van de aandeelhouder moet worden geverifieerd.",
        content165: "4. Transacties monitoren",
        content166: "4.1 We stellen van tijd tot tijd maximale limieten voor dagelijkse transacties en geldopnames in en passen deze aan op basis van de veiligheid en feitelijke transactieomstandigheden;",
        content167: "4.2 Als transacties frequent voorkomen bij een geregistreerde gebruiker of als er sprake is van onredelijke omstandigheden, zal ons professionele team beoordelen en beslissen of deze verdacht zijn;",
        content168: "4.3 Als wij op basis van ons eigen oordeel vaststellen dat een transactie verdacht is, kunnen wij beperkende maatregelen nemen, zoals het opschorten van de transactie, het afwijzen van de transactie, of indien mogelijk zelfs de transactie zo snel mogelijk terugdraaien en dit melden aan de bevoegde autoriteit, maar zal u niet op de hoogte stellen;",
        content169: "4.4 Wij behouden ons het recht voor om registratieaanvragen af ​​te wijzen van personen uit rechtsgebieden die niet voldoen aan de internationale antiwitwasnormen of personen die als politiek prominente personen kunnen worden beschouwd. Wij behouden ons het recht voor om transacties die naar ons eigen oordeel verdacht zijn op te schorten of te beëindigen. op elk gewenst moment, maar dat doen wij niet. Het schenden van plichten of verantwoordelijkheden die u verschuldigd bent.",
    },
    register: {
        formTitle1: "creëren",
        formTitle2: "Registreer u met uw e-mailadres of mobiele telefoonnummer",
        formTitle3: "Rekening",
        field1: "Verificatie code",
        field2: "Verkrijgen",
        field3: "bevestig wachtwoord",
        field4: "Uitnodigingscode",
        field41: "（Optioneel）",
        field5: "Ik heb het gelezen en ga ermee akkoord",
        field6: "Servicevoorwaarden",
        placeholder1: "voer de verificatiecode in",
        content7: "voer de verificatiecode in",
        content12: "Voer uw telefoonnummer in",
        content13: "Voer wachtwoord in alstublieft",
        content14: "Voer uw e-mailadres in",
        content18: "Voer het wachtwoord opnieuw in",
        content19: "Ga akkoord met de servicevoorwaarden",
        content20: "Voer de juiste verificatiecode in",
        content23: "Heeft u uw verificatiecode nog steeds niet ontvangen?",
        content24: "Vul hieronder de verificatiecode in",
        registerSuccess: "registratie succes",
        content25: "Wachtwoorden zijn inconsistent",
        content26: "Voer het juiste e-mailadres in",
        content27: "Wachtwoord opnieuw instellen gelukt"
    },
    download: {
        content1: "Handel altijd en overal",
        content2: "Perfect compatibel met meerdere terminals en voldoet op elk moment aan de transactiebehoeften van verschillende scenario's",
        content3: "Controleer op elk moment de realtime marktomstandigheden",
        content4: "Koop en verkoop eenvoudig BTC, ETH, XRP en andere digitale activa",
        content5: "Ontvang vroegtijdige waarschuwingen over prijswijzigingen voor de cryptocurrencies die u belangrijk vindt",
        content6: "Bekijk Bitcoin-spotleningen, futures-leningen en optieprijzen",
        content7: "Vergelijk cryptocurrency-prijzen op verschillende beurzen",
        title: "downloaden",
        install: "gebruikshandleiding",
        one: "1. Sta het downloaden van beschrijvingsbestanden toe",
        two: "2. Het beschrijvingsbestand is gedownload",
        three: "3. Ga naar [Instellingen] op je telefoon en zoek [Algemeen]",
        four: "4. Beschrijvingsbestanden en apparaatbeheer",
        five: "5. Klik op het beschrijvingsbestand",
    },
    identity: {
        noAuthentication: {
            title: "Junior-certificering",
            pleaseSelectYourCountry: "Selecteer uw land alstublieft",
            name: "Naam",
            enterName: "Vul alstublieft uw naam in",
            city: "stad",
            enterCity: "Voer uw stad in",
            IDNumber: "ID nummer",
            enterIDNumber: "Voer uw ID-nummer in",
        },
        noAdvancedAuthentication: {
            title: "Senior certificering",
            infoCountry: "land/regio",
            infoName: "Naam",
            infoNumber: "ID nummer",
            infoAddress: "adres",
        },
        text: "Niet certificaat",
        recertification: "hercertificering",
        certification: "Certificering",
        authenticationInProgress: "geverifieerd",
        inAuthentication: "Certificering in uitvoering",
        uploadTheFrontOfTheDocument: "Upload de voorkant van het identiteitsbewijs",
        uploadTheReverseSideOfTheDocument: "Upload de achterkant van het certificaat",
        uploadAPhotoOfAHandHeldID: "Upload een foto van uw identiteitskaart bij de hand",
        pleaseUpload: "Alsjeblieft ",
        pleaseCompleteOrdinaryCertificationFirst: "Het is wachten op het succes van de primaire certificeringsbeoordeling",
        refused: "afgewezen",
        reason: "reden",
        succeeded: "geslaagd",
        uploading: "uploaden",
        newTitle: "Selecteer het land waar uw identiteitsbewijs is afgegeven",
        pleaseOrdinaryCertificationFirst: "Voer eerst de primaire certificering uit",
        text1: "Wijzig avatar",
        text2: "Bijnaam",
        text3: "rekening",
        text4: "Wijziging van de bijnaam",
        text5: "nieuwe bijnaam",
        text6: "Voer uw nieuwe bijnaam in",
        text7: "Oud Wachtwoord",
        text8: "Voer uw oude wachtwoord in",
        text9: "nieuw paswoord",
        text10: "Typ uw nieuwe wachtwoord",
        text11: "bevestig wachtwoord",

        text12: "Voer uw nieuwe wachtwoord opnieuw in",
        text13: "nieuwe brievenbus",
        text14: "Verificatie code",
        text15: "voer de verificatiecode in",
        text16: "uw mobiele telefoonnummer",
        text17: "Nieuw mobiel telefoonnummer",
        text18: "Voer uw telefoonnummer in",
        text19: "Verificatiecode voor mobiele telefoon",
        text20: "Junior-certificering",
        text21: "Controleer uw identiteitsgegevens",
        text22: "Land",
        text23: "Stad",
        text24: "Voer uw stad in",
        text25: "Vul uw naam in",
        text26: "Uw ID-nummer",
        text27: "Voer uw ID-nummer in",
        text28: "Voorfoto identiteitskaart",
        text29: "Klik om uw documenten te uploaden",
        text30: "her-upload",
        text31: "uploaden",
        text32: "Met identiteitsfoto",
        text33: "Klik om de identiteitsfoto van uw bedrijf te uploaden",
        text34: "U heeft Google Authenticator gebonden",
        text35: "sleutel",
        text36: "Voer de Google-verificatiecode in",
        text37: "versturen",
        text38: "Kan niet leeg zijn",
        text39: "Het e-mailformaat is verkeerd",
        text40: "Twee wachtwoorden zijn inconsistent",
        text41: "Gekopieerd",
        text42: "Kopiëren mislukt",
        text43: "Avatar-modificatie",
        text44: "verander wachtwoord",
        text45: "E-mailbinding",
        text46: "E-mail wijzigen",
        text47: "Mobiele telefoon binding",
        text48: "Mobiele telefoon aanpassen",
        text49: "Google-verificatie",
        text50: "Bind Google Authenticator",
        text51: "Voer de Google-verificatiecode in",
        text52: "redden ",
        text53: "Bevestig de indiening",
        text54: "Herzien",
        text55: "verbindend",
        text56: "Schakel Google-verificatie uit",
        text57: "Volgende stap",
        text58: "Finish",
        text59: "Google-verificatie is gebonden",
        text60: "Google-verificatie is ontbundeld",
        text61: "De primaire certificering is ingediend",
        text62: "Geavanceerde certificering ingediend",
        text63: "Geen afbeelding geüpload",
        text64: "Opnieuw versturen",
        text65: "Voer alstublieft een bijnaam in",
        text66: "Bijnaam succesvol opgeslagen",
        text67: "Er zijn nog geen afbeeldingen geüpload",
        text68: "Wachtwoord opnieuw instellen voltooid",
        text69: "E-mail succesvol gewijzigd",
        text70: "Avatar succesvol opgeslagen",
        text71: "Afbeelding uploaden",
        text72: "uw brievenbus",
        text73: "Voer uw nieuwe e-mailadres in",
        text74: "Uw naam",
        text75: "Ga naar authenticatie",
        text76: "Controleer de identiteitsfoto van uw bedrijf"
    },
    retrievePassword: {
        formTitle1: "wachtwoord opnieuw instellen",
        formTitle2: "Voor de veiligheid van uw bezittingen zijn transacties binnen 24 uur na het wijzigen van het wachtwoord verboden.",
        formTitle3: "Wachtwoord ophalen",
        formTitle4: "Bevestig de wijzigingen",
        formTitle5: "Voer het oude wachtwoord in",
        formTitle6: "Voer een nieuw wachtwoord in"
    },
    home: {
        title1: "Meest populaire cryptocurrency-handelsplatform",
        title3: "waarom is ",
        title4: " Het is een krachtig open source blockchain-platform dat is ontworpen om een ​​veilige, conforme en voorspelbare digitale infrastructuur te ondersteunen en te exploiteren.",
        field1: "Opgericht uitwisseling",
        field2: "Financiële diensten voor digitale activa",
        field3: "Tientallen miljoenen gebruikers",
        field4: "Richt meerdere beleggersbeschermingsfondsen op",
        field5: "Snelle handel",
        field6: "CME Gruop kan orders matchen met een ultralage latentie voor de ultieme handelservaring.",
        title5: "markt trend",
        title6: "Bekijk meer",
        table1: "Munteenheid",
        table2: "Laatste prijs",
        table3: "24 uur ups en downs",
        table4: "24H Omzet",
        table5: "24-uurs transactievolume",
        table6: "Citaten",
        title7: "over ",
        title8: " We streven ernaar om gebruikers de meest geavanceerde handelsinstrumenten, innovatieve technologie en ongeëvenaarde klantenservice te bieden.",
        title9: "Het veld van digitale assets verandert elke dag. Alleen door vooruitziend te zijn en snelle vooruitgang te boeken, kunnen we het tempo van de technologische innovatie bijhouden.",
        title901: " Het leveren van de meest geavanceerde diensten voor de handel in digitale activa aan onze gebruikers en wereldwijde liquiditeitsverschaffers.",
        title10: "Wij zijn ervan overtuigd dat elke gebruiker de beste handelservaring verdient.",
        title11: "Beveiliging waarop u kunt vertrouwen",
        title12: "Onze geavanceerde beveiligingsmaatregelen en SAFU-fondsen beschermen uw digitale activa tegen alle risico's. ",
        title13: "Beste transactietarieven",
        title14: "Voorkeurstransactietarieven, concurrerende VIP-rechten en geniet van diensten van de beste kwaliteit.",
        title15: "24*7 uur klantenserviceondersteuning",
        title16: "24/7 fulltime bedrijfsmodus, onder alle weersomstandigheden, wij zullen uw relevante vragen zo snel mogelijk beantwoorden.",
        title17: "Handelen tegen 0 kosten",
        title18: "Gebruik meerdere betaalmethoden om veilig en snel in digitale valuta te handelen zonder administratiekosten.",
        title19: "Handel altijd en overal",
        title20: "Perfect compatibel met meerdere terminals en voldoet op elk moment aan de transactiebehoeften van verschillende scenario's",
        title21: "Controleer op elk moment de realtime marktomstandigheden",
        title22: "Koop en verkoop eenvoudig BTC, ETH, XRP en andere digitale activa",
        title23: "Ontvang vroegtijdige waarschuwingen over prijswijzigingen voor de cryptocurrencies die u belangrijk vindt",
        title24: "Bekijk Bitcoin-spotleningen, futures-leningen en optieprijzen",
        title25: "Vergelijk cryptocurrency-prijzen op verschillende beurzen",
        title26: "Meld u nu aan",
        title27: "Dollar",
        title28: "24H Omzet",
        title29: "Geïntegreerde liquiditeitsverschaffer",
        title30: "gebruiker",
        title31: "Meer",
        title32: "Binance Loan voegt CTSI & KSM toe als onderpand en lanceert BUSD & USDT renteverlagingskortingen!",
        title33: "Ons voordeel",
        title34: "veilig en stabiel",
        title35: "Een technisch topteam, uitgebreide beveiligingsbescherming, onafhankelijk onderzoek en ontwikkeling van een snelle transactiesynthese-engine, kan nog steeds stabiel en betrouwbaar opereren onder massale gelijktijdige transacties.",
        title36: "Professionele naleving",
        title37: "Een professioneel operationeel team, met vele jaren ervaring in blockchain en financiën, beschikt over een conforme handelslicentie voor digitale activa en heeft een reservegarantie van 100%.",
        title38: "Gebruiker eerst",
        title39: "Geconfronteerd met de wereldmarkt, ondersteuning van meerdere talen, 24/7 actief, sterke community-ondersteuning en professionele klantenservice.",
        title40: "hoge performantie",
        title41: "300.000 transacties per seconde en een responstijd voor bestellingen van minder dan 1 milliseconde.",
        title42: "over ons",
        title43: "Handel altijd en overal",
        title44: "Perfect compatibel met meerdere terminals en voldoet op elk moment aan de transactiebehoeften van verschillende scenario's",
        title45: "（Scancode om te downloaden）",
        title46: "Digitaal valutanieuws",
        title47: "onze zakenpartner",
        title48: "Verliezerslijst",
        title49: "24 uur omzet",
        title50: "24 uur verhogen en verlagen"
    },
    quotation: {
        marketTrading: "Citaten",
        turnover: "Volume",
        optional: "Optioneel",
        contractMarket: "contract markt",
        currencyPair: "valutapaar",
        latestPrice: "Slotkoers",
        upsAndDowns: "Citaatwijziging",
        highest: "Hoogste",
        lowest: "laagste",
        transactionAmount: "Transactievolume",
        operate: "bedienen",
        buy: "handel",
        topTurnovers: "Transactielijst",
        topGainers: "Gainer-lijst",
        tradingPair: "handelspaar",
        buyOrder: "Kopen",
        sellOrder: "verkoop bestelling"
    },
    accountChange: {
        demo: "simulatie",
        realAccount: "Echt account",
        demoAccount: "Demo-account",
        accountRole: "Accountrol",
        pleaseSelect: "Gelieve te kiezen",
        AccountChangeType: "Type accountwijziging",
        AccountType: "account type",
        currencyType: "Valuta type",
        allType: "Alle typen",
        allAccount: "Alle rekeningen",
        allCoin: "Alle valuta",
        coin: "Munteenheid",
        amount: "Wijzig bedrag",
        balance: "Rekeningsaldo",
        time: "tijd",
        title: "Accountwijzigingsrecords",
        filtrar: "filter",
        check: "overschakelen naar",
        showOnly: "Geef alleen valutaposities weer",
        holdAssets: "Valutasaldo",
        accountList: "Accountlijst"
    },
    contractTransaction: {
        chart: "grafiek",
        basicEdition: "Basisversie",
        depthMap: "Diepte kaart",
        second: "Seconde",
        minute: "punt",
        day: "lucht",
        week: "week",
        month: "maan",
        open: "open",
        trade: "handel",
        buyLong: "lange koop",
        buyShort: "korte koop",
        cannotBuy: "Deze valuta ondersteunt geen leveringscontracttransacties",
        cannotBuy1: "Deze valuta ondersteunt geen eeuwigdurende handel",
        tradingAssets: "handelsactiva",
        amountAvailable: "hoeveelheid beschikbaar",
        timeStr: "tijd",
        transactionPeriod: "handelsperiode",
        quantity: "hoeveelheid",
        enterQuantity: "Voer het aantal in",
        buyIn: "Ga lang",
        latestTransaction: "laatste transactie",
        time: "tijd",
        direction: "richting",
        price: "prijs",
        sell: "kort",
        positionOrder: "Bezorgopdracht",
        historicalOrders: "Historische orden",
        product: "Product",
        realAccount: "(echte rekening)",
        demoAccount: "(Demo-account)",
        openingTime: "bestellingstijd",
        openingPrice: "Openingsprijs",
        curretPrice: "Huidige prijs",
        liquidationPrice: "Slotkoers",
        profitOrLoss: "Winst en verlies",
        endTime: "Sluitingstijd",
        areYouSureToBuy: "Weet je zeker dat je wilt kopen?",
        areYouSureToLongBuy: "Weet je zeker dat je lang wilt gaan?",
        areYouSureToShortBuy: "Weet u zeker dat u short wilt kopen?",
        successfullyBought: "Koop succesvol",
        minBuy: "Minimale buy-in",
        assets: "activa",
        withdraw: "terugtrekken",
        entrustedOrder: "Volgorde",
        total: "totaal",
        continueToPlaceOrder: "Ga door met het plaatsen van een bestelling",
        orderConfirmation: "Order bevestiging",
        buyRu: "kopen",
        sellChu: "verkopen",
        areYouSureToSell: "Weet u zeker dat u wilt verkopen?",
        operation: "bedienen",
        buyCover: "dekkingspositie",
        commitSuccess: "Transactie succesvol",
        onlyEnterIntegers: "Er kunnen alleen gehele getallen worden ingevoerd",
        trend: "Trend",
        lotteryTime: "Deadline voor bestellen",
        countdown: "Aftellen",
        period: "Probleem",
        cycle: "Tijdsperiode",
        unlimited: "Onbeperkt",
        put: "Put",
        call: "Call",
        pending: "Op commissie",
        unopened: "Nog niet getekend",
        opened: "Prijs getrokken",
        settlementPrice: "Afrekeningsprijs",
        rateOfReturn: "rendement",
        currentPositions: "Huidige positie",
        positionQuantity: "Positie hoeveelheid",
        guarantee: "marge",
        text1: "Call",
        text2: "Put",
        text3: "Wachten om te kopen",
        text4: "Bestelhoeveelheid",
        text5: "Het aantal transacties",
        text6: "Annuleren",
        text7: "Tijd om te bezorgen",
        text8: "Herinnering voor annulering",
        text9: "Weet u zeker dat u de bestelling wilt annuleren?",
        text10: "Illegale hoeveelheid",
        text11: "Bestelherinnering",
        text12: "Weet u zeker dat u bullish koopt?",
        text13: "Weet u zeker dat u de put wilt kopen?",
        text14: "Annulering succesvol"
    },
    mining: {
        title1: "'s Werelds toonaangevende blockchain-platform voor het delven van digitale activa",
        title2: "Open en transparant · veilig en stabiel",
        title3: "Selecteer valuta",
        title4: "Mijnbouw op jaarbasis rendement",
        title5: "lucht",
        title6: "Mijnbouw periode",
        title7: "Productdetails",
        btn: "De mijne nu",
        btn2: "sluiting",
        tabs1: "bezig",
        tabs2: "ingewisseld",
        table1: "productnaam",
        table2: "Mijnbouw periode",
        table3: "Mijn tijd",
        table4: "Mijnbouwbedrag",
        table5: "Vervaldatum",
        table6: "Gaat door tot volwassenheid",
        table7: "Bestelstatus",
        egularMining: "Regelmatig mijnbouw",
        inProgress: "bezig",
        redeemed: "ingewisseld",
        redemption: "aflossing",
        regularMining: "Regelmatig mijnbouw",
        earlyRedemption: "vervroegde aflossing",
        need: "behoefte",
        handlingFee: "verwerkingskosten",
        redemptionConfirmationMessage: "Bevestig of u vooraf wilt inwisselen?",
        form1: "Mijnbouw op jaarbasis rendement",
        form2: "Mijnbouw periode",
        form3: "Mijnbouwvaluta",
        form4: "Abonnementsbedrag (minimum/maximum)",
        form5: "Kosten voor vervroegde aflossing: ",
        form6: "aankoopprijs",
        form7: "Beschikbare fondsen",
        form8: "aankoopprijs",
        form9: "Beschikbare fondsen",
        form10: "Vul het aankoopbedrag in",
        form11: "alle",
        form12: "Geschatte omzet",
        form13: "Er zijn onvoldoende middelen beschikbaar",
        form14: "Vul het aankoopbedrag in",
        form15: "Mijn bestelling",
        form16: "inkomen",
        form17: "Bestel Details",
        form18: "Instructies voor vervroegde aflossing:",
        form19: "Abonnementsbedrag (minimaal)",
        form20: "Abonnementsbedrag (maximaal)",
        form21: "Hoeveelheid vastgehouden",
        form22: "Tijd kopen",
        form23: "verlossing tijd",
        form24: "over",
        form25: "Abonnement succesvol",
        form26: "Inwisseling succesvol",
        form27: "Eenvoudig financieel beheer met meer dan 25% rendement, beter presterend dan de markt, en u kunt ook beloningen ontvangen na aankoop",
        form28: "Nodig vrienden uit om een ​​beloning van 50 USDT te ontvangen, en contractvouchers kunnen ook 20% commissie retourneren."
    },
    assets: {
        content1: "Accountactiva",
        content2: "De winst van vandaag",
        content3: "lopende rekening",
        content4: "De daadwerkelijke rekening bestaat uit uw daadwerkelijke rekeningactiva, die kunnen worden gebruikt voor transacties en opnames.",
        content5: "Munten opnemen",
        content6: "Munten storten",
        content7: "aandelenbeurs",
        content8: "overdracht",
        content9: "Gelieve te kiezen",
        content10: "Demo-account",
        content11: "Echt account",
        content12: "bezittingen verwerven",
        content13: "Weet u zeker dat u wilt overstappen naar een echt account?",
        content14: "Weet u zeker dat u wilt overstappen naar een demo-account?",
        content15: "succes krijgen",
        table1: "Munteenheid",
        table2: "hoeveelheid",
        table3: "bevriezen",
        table4: "Beschikbaar",
        dialog1: "Productdetails",
        dialog2: "1. Dit item mag alleen worden gebruikt in de demo-accountmodus",
        dialog3: "2. Om misbruik te voorkomen is binnen een maand slechts één keer aflossing mogelijk.",
        dialog4: "3. Bij het ontvangen van activa wordt het ontvangen bedrag aan activa opgeteld bij het oorspronkelijke bedrag en wordt de positiestatus niet gewijzigd.",
        dialog5: "4. Als u nog vragen heeft, neem dan contact op met de klantenservice",
        dialog6: "sluiting",
        dialog7: "Ontvang nu demo-items",
        dialog8: "overdracht in",
        dialog9: "overbrengen",
        dialog10: "schakelaar",
        dialog11: "Waardering",
        dialog12: "Demo-items",
        dialog13: "mijn rekening",
        dialog14: "Totaal accountactiva",
        dialog15: "Overzicht activa",
        dialog16: "Totale activawaardering",
        dialog17: "Waardering van activa",
        dialog18: "De winst van vandaag",
        dialog19: "fondsen bevriezen",
        dialog20: "U kunt elke maand een gesimuleerd itemquotum ontvangen",
        dialog21: "ontvangen",
        dialog22: "U heeft succesvol ontvangen",
        dialog23: '30 dagen cumulatief inkomen',
        dialog24: "Financieel product",
        dialog25: "Mijn financieel beheer",
        dialog26: "Historisch financieel beheer",
        dialog27: "Historisch record",
        dialog28: "Financieringsgegevens"
    },
    validator: {
        dialog14: "validator",
        dialog15: "Bindingsvalidator",
        dialog16: "validator schakelaar",
        dialog17: "Verificatie code",
        dialog18: "Validator QR-code",
        dialog19: "valideringssleutel",
        dialog20: "redden ",
        dialog21: "Selecteer een validator",
        dialog22: "kopieer sleutel",
        dialog23: "Met succes opgeslagen",
        dialog24: "voer de verificatiecode in",
        dialog25: "Vriendelijke herinnering: Voordat u het opslaan bevestigt, opent u de bijbehorende APP en scant u de onderstaande QR-code of voegt u de sleutel handmatig toe!",
        dialog26: "Het inschakelen van uw authenticator biedt maximale beveiliging voor uw account.",
        dialog27: "Elke keer dat u inlogt of geld opneemt, moet u een unieke verificatiecode invoeren die u via uw mobiele telefoon kunt bereiken.",
        dialog28: "Validator instellen",
        dialog29: "hulp",
        dialog30: "U heeft de authenticatiecode niet gebonden. Krijg betere bescherming met een authenticator.",
        dialog31: "De validator is nog niet ingesteld. Stel deze eerst in",
        dialog32: "Ga naar Instellingen"
    },
    recharge: {
        title1: "Weet u niet zeker hoe u met digitale valuta moet kopen?",
        title2: "Slechts drie eenvoudige stappen!",
        title3: "Selecteer de digitale valuta die u wilt kopen in het vervolgkeuzemenu en voer de aankoophoeveelheid of het aankoopbedrag in.",
        title4: "Kies uw gewenste betaalmethode",
        title5: "Nadat de betaling is bevestigd, wordt de door u gekochte digitale valuta automatisch naar uw account overgezet.",
        title6: "Selecteer de valuta die u wilt opwaarderen",
        title7: "Stortingsrecord",
        table1: "Munteenheid",
        table2: "hoeveelheid",
        table3: "Kettingnaam",
        table4: "staat",
        table5: "reden",
        table6: "tijd",
        table7: "Bekijk voucher",
        dialog1: "Vul hieronder de verificatiecode in",
        dialog2: "betalingscode",
        dialog3: "kopiëren",
        dialog4: "adres",
        dialog5: "Borgbedrag",
        dialog6: "Schermafbeelding van de transactie",
        dialog7: "belangrijke mededeling",
        dialog8: "Vul het aanbetalingsbedrag in",
        dialog9: "afbeelding uploaden",
        dialog10: "Voer de inhoud in",
        dialog11: "Upload screenshots van het opladen",
        dialog12: "Niet beoordeeld",
        dialog13: "afgewezen",
        dialog14: "Gestort",
        tip1: "Succesvol ingediend",
        tip2: "Gekopieerd",
        tip3: "Kopiëren mislukt",
        tip4: "Selecteer de valuta die u wilt opwaarderen",
        tip5: "1. Het bovenstaande stortingsadres is het officiële betalingsadres van het platform. Zoek het officiële stortingsadres van het platform. U bent verantwoordelijk voor het verlies van geld als gevolg van een verkeerde storting;",
        tip6: "2. Zorg ervoor dat uw computer en browser veilig zijn om te voorkomen dat er met informatie wordt geknoeid of dat deze lekt;",
        tip7: "3. Nadat u het bovenstaande adres heeft opgewaardeerd, moet het hele netwerkknooppunt bevestigen voordat het account kan worden ontvangen;",
        tip8: "4. Selecteer het bovenstaande tokensysteem en de valuta en maak vervolgens het overeenkomstige bedrag over voor storting. Maak alstublieft geen andere niet-gerelateerde activa over, anders worden deze niet teruggehaald.",
        tip9: "QR-code opslaan",
        tip10: "bijvullen",
        tip11: "belangrijke mededeling",
        tip12: "Offsite links",
        tip13: "QR-code opladen",
        tip14: "Volgende stap",
        tip15: "Record opladen",
        tip16: "Oplaadgegevens",
        tip17: "Stortingsadres",
        tip18: "Snelle betaling",
        tip20: "Onvoldoende evenwicht",
        tip22: "Verzonden, wacht op bevestiging",
        tip23: "wachten op betaling",
        tip24: "Hoeveelheid",
        tip25: "De betaling is verzonden en wacht op blokbevestiging",
        tip26: "Betaling geweigerd",
        tip27: "Betaling mislukt",
        tip28: "Betaling gelukt",
        bankChannel: "Bankkaart kanaal",
        tip29: "Historische zoekopdrachten",
        tip30: "Valuta lijst",
        tip31: "Selecteer netwerk",
        tip32: "Wanneer u deze valuta opwaardeert, kunt u deze alleen opwaarderen via de onderstaande ondersteunde netwerken. Als u opwaardeert via andere netwerken, gaat het geld verloren.",
        tip33: "Wanneer u deze valuta opneemt, kunt u dit alleen doen via de onderstaande ondersteunde netwerken. Als u opneemt via andere netwerken, gaat het geld verloren.",
        tip34: "Snel opladen",
        tip35: "Scan de code om op te laden",
        tip36: "Handmatig opladen",
        tip37: "Netwerk opladen",

        tip38: "Oplaadadres",
        tip39: "Bedrag opwaarderen",
        tip40: "Transactiebon geüpload",
        tip41: "afbeelding uploaden",
        tip42: "bevestig de indiening",
        tip43: "bevestig betaling",
        tip44: "Let op: bevestig het adres dat je nodig hebt om op te laden. Wij zijn niet verantwoordelijk voor het verlies van geld als je het verkeerd invult.",
        tip45: "herinneren",
        tip46: "Adres succesvol gekopieerd!",
        tip47: "Adreskopie mislukt!",
        tip48: "Onvoldoende beschikbaar saldo",
        tip49: "Het opwaarderen is succesvol verzonden en wacht op bevestiging in de keten.",
        tip50: "Opladen mislukt",
        tip51: "Het opwaardeerbedrag is onjuist",
        tip52: "Verbinding mislukt",
        tip53: "Wallet niet ingelogd",
        tip54: "Verbinding geweigerd",
        tip55: "Succesvol ingediend, wachtend op beoordeling en goedkeuring van de klantenservice",
        tip56: "Upload een screenshot van de transactiebon",
        tip57: "Inzending mislukt",
        tip58: "Record opladen",
        tip59: "Opnameregistratie",
        tip60: "Valuta opwaarderen",
        tip61: "Valuta van opname",
        tip62: "Oplaadadres",
        tip63: "Screenshot toont",
        tip64: "Klik om de afbeelding te vergroten",
        tip65: "Oplaadtijd",
        tip66: "Redenen voor afwijzing",
        tip67: "Opnamebedrag",
        tip68: "Bedrag ontvangen",
        tip69: "Netwerk voor geldopname",
        tip70: "Herroepingsadres",
        tip71: "Opnametijd",
        tip72: "sluiting",
        tip73: "Schermafbeelding van de transactie",
        tip74: "Wacht beoordeling",
        tip75: "afgewezen",
        tip76: "voltooid",
        tip77: "afgewezen",
        tip78: "Gecontroleerd",
        tip79: "Uitbetaald",
        tip80: "extract",
        tip81: "overdracht netwerk",
        tip82: "Adres/Factuur",
        tip83: "Alles intrekken",
        tip84: "Neem alstublieft geen geld rechtstreeks op naar het crowdfunding- of ICO-adres, anders kunt u de tokens die zijn uitgegeven door de crowdfunding of ICO niet ontvangen.",
        tip85: "Aankomsthoeveelheid",
        tip86: "Bevestig de opname",
        tip87: "Verkeerd opnameadres",
        tip88: "Verkeerd opnamebedrag",
        tip89: "Het op te nemen bedrag is minimaal",
        tip90: "Het maximale opnamebedrag bedraagt",
        tip91: "Er is een verzoek tot intrekking ingediend",
        tip92: "adreslijst",
        tip93: "U heeft nog geen adres toegevoegd",
        tip94: "Voeg het nu toe",
        tip95: "Voeg adres toe",
        tip96: "Nieuw adres toevoegen",
        tip97: "Gelieve te kiezen",
        tip98: "adres",
        tip99: "Selecteer een overdrachtsnetwerk",
        tip100: "Voer het adres in",
        tip101: "Adres succesvol toegevoegd",
        tip102: "in transactie",
    },
    fundExchange: {
        title1: "Hoe geld wisselen?",
        title2: "Selecteer de juiste valuta die moet worden geconverteerd.",
        title3: "Selecteer de juiste valuta om te converteren.",
        title4: "Voer het in te wisselen bedrag in en klik op Verzenden om de fondsuitwisseling te voltooien.",
        title5: "van",
        title6: "Naar",
        title7: "Wissel hoeveelheid uit",
        title8: "wisselkoers",
        title9: "Beschikbaar",
        title10: "Voer het inwisselbedrag in",
        title11: "Er is geen corresponderend handelspaar voor uitwisseling.",
        title12: "Voer het in te wisselen aantal in",
        title13: "Inwisseling succesvol",
        title14: "Wissel record uit",
        table1: "Gebruik valuta",
        table2: "hoeveelheid",
        table3: "wisselkoers",
        table4: "Wisselkoers",
        table5: "Ontvangst",
        table6: "tijd",
        title15: "van",
        title16: "Er zijn momenteel geen activa beschikbaar. Selecteer een andere"
    },
    fundTransfer: {
        title1: "Hoe geld overmaken?",
        title2: "Selecteer de lopende rekening die moet worden overgedragen.",
        title3: "Selecteer het account dat u wilt overzetten.",
        title4: "Voer het overboekingsbedrag in en klik op Verzenden om de overboeking te voltooien.",
        title5: "Munteenheid",
        title6: "Hoeveelheid overbrengen",
        title7: "Vul het over te maken bedrag in",
        title8: "overdrachtsrecord",
        title9: "Selecteer het portemonneetype",
        title10: "Selecteer valuta",
        title11: "Selecteer het portemonneetype",
        title12: "Selecteer valuta",
        title13: "Vul het over te maken bedrag in",
        title14: "Succesvol ingediend",
        table1: "Rekening overboeken",
        table2: "valuta overmaken",
        table3: "Hoeveelheid overbrengen",
        table4: "rekening overmaken",
        table5: "tijd",
        table6: "overdracht",
        table7: "Geld transfer",
        table8: "Overzetten naar",
        table9: "overdrachtsrecord",
        table10: "Overdrachtsgegevens",
        table11: "Overdracht succesvol",
        table12: "Overdracht mislukt",
        table13: "Betaalrekening kan niet worden overgedragen"
    },
    withdrawal: {
        title1: "belangrijke mededeling",
        title2: "1.Om arbitrage te voorkomen, kunt u geldopname aanvragen wanneer het transactievolume het quotum bereikt.",
        title3: "2.De opnameaanvraag wordt binnen 24 uur na indiening gecrediteerd. Als de opname na de geschatte opnametijd niet is ontvangen, neem dan contact op met de online klantenservice.",
        title4: "3.Na het indienen van de opnameaanvraag zijn de gelden bevroren omdat de opname bezig is en de gelden tijdelijk door het systeem worden vastgehouden. Dit betekent niet dat u het tegoed kwijt bent of dat er een afwijking aan het tegoed is.",
        title5: "Munteenheid",
        title6: "portemonnee adres",
        title7: "Aantal opgenomen munten",
        title8: "Record voor het opnemen van munten",
        title9: "Selecteer valuta",
        title10: "Selecteer het kettingtype",
        title101: "",
        title11: "kom binnen alstublieft",
        title12: "portemonnee adres",
        title13: "Succesvol ingediend",
        title14: "wordt beoordeeld",
        title15: "Beoordeling succesvol",
        title16: "Controlefout",
        title17: "Uitbetaald",
        title18: "Voer uw portemonnee-adres in of plak het",
        title19: "Voer het opnamebedrag in",
        title20: "verwerkingskosten",
        title21: "Daadwerkelijke aankomst",
        table1: "Munteenheid",
        table2: "Aantal opgenomen munten",
        table3: "Kettingnaam",
        table4: "portemonnee adres",
        table5: "staat",
        table6: "reden",
        table7: "toepassingstijd",
        table8: "Munten opnemen",
        table81: "Munten opnemen",
        table9: "Herroepingsadres",
        table10: "Selecteer de valuta die u wilt opnemen",
        table11: "Zorg ervoor dat u binnenkomt",
        table12: 'Adres (begint meestal met "0x")',
        table13: "Opneembare munten (administratiekosten afgetrokken)：",
        table14: "Selecteer valuta",
        table15: "Problemen tegenkomen? Onmiddellijke feedback",
        table16: "evenwicht",
        table17: "Gegevens over opname",
        password: "wachtwoord",
        passwordTips: "Voer wachtwoord in alstublieft",
        passwordAg: "bevestig wachtwoord",
        passwordAgTips: "Voer het wachtwoord opnieuw in",
        oldPassword: "Oud Wachtwoord",
        oldPasswordTips: "Voer het oude wachtwoord in",
        newPassword: "nieuw paswoord",
        newPasswordTips: "Voer een nieuw wachtwoord in",
        passwordNotSame: "Wachtwoorden zijn inconsistent",
        withdrawPasswordSet: "Stel het opnamewachtwoord in",
        withdrawPasswordModify: "Wijzig het opnamewachtwoord",
        withdrawPassword: "Wachtwoord intrekken",
        withdrawPasswordTips: "Voer het opnamewachtwoord in",
        setWithdrawPasswordFirst: "Stel eerst een opnamewachtwoord in",
        withdrawPasswordSetSuccess: "Setup succesvol",
        withdrawPasswordModifySuccess: "Succesvol gewijzigd",
        submit: "indienen",
    },
    customer: {
        content1: "online dienst",
        content2: "klantenservice",
        content3: "Voer uw contactgegevens in",
        content4: "Voer de inhoud in",
        content5: "versturen",
        content6: "Verzenden mislukt, netwerkverbinding mislukt",
        content7: "afbeelding",
        content8: "bericht is leeg",
        content9: "mijn deel",
        content10: "deel link",
        content11: "sla afbeelding op",
        content12: "mijn Inkomens",
        content13: "Niveaubeschrijving",
        content14: "Team grootte",
        content15: "Uitnodigingsinkomsten",
        content16: "Regelbeschrijving",
        content17: "lidmaatschapsniveau",
        content18: "Het oplaadbedrag is groter dan",
        content19: "Teamniveau",
        content20: "Teamniveau en inkomen",
        content21: "Om aan de voorwaarden te voldoen",
        content22: "Distributie inkomen",
        content23: "niveau elftal",
        content24: "niveau, uitnodiging",
        content25: "geldige leden",
        content26: "primair inkomen",
        content27: "secundair inkomen",
        content28: "Niveau 3 inkomen",
    },
    standardContract: {
        increaseMargin: "Increase margin",
        increaseAmount: "Increase amount",
        Pleaseenterthemargin: "Please enter the margin amount to be increased",
        Pleaseenterthecorrect: "Please enter the correct deposit amount",
        Increasemarginsuccessfully: "Increase margin successfully",
        takeProfitPriceWrong: "Neem de winstprijs verkeerd",
        stoppriceerror: "Stop de prijsfout",
        thetakeprofit1: "De take-profitprijs moet hoger zijn dan de marktprijs",
        thetakeprofit2: "De stop loss-prijs moet lager zijn dan de marktprijs",
        thetakeprofit3: "De take-profitprijs moet lager zijn dan de marktprijs",
        thetakeprofit4: "De stop loss-prijs moet hoger zijn dan de marktprijs",
        thetakeprofit5: "Verkeerde hoeveelheid",
        estimatedprofitloss: "Geschatte winst en verlies",
        positionAmount: "Aantal posities",
        closeAmount: "Sluitingshoeveelheid",
        whenthelatest: "Wanneer de laatste prijs is bereikt",
        whenthelatest2: ", zal de take-profitorder op de marktprijs worden geactiveerd om de huidige positie te sluiten. Verwachte winst en verlies",
        whenthelatest3: ", wordt de marktstop-lossorder geactiveerd om de huidige positie te sluiten. Verwachte winst en verlies",
        contract: "contract",
        ordercanceledsuccessfully: "Bestelling succesvol geannuleerd",
        successfullyordered: "succesvol besteld",
        positionopeningreminder: "Herinnering positieopening",
        wrongquantity: "Verkeerde hoeveelheid",
        quantitycannot: "De hoeveelheid kan niet groter zijn dan",
        modificationstoploss: "Wijziging van stopwinst en stopverlies succesvol",
        positionclosedsuccessfully: "Positie succesvol gesloten",
        orderCancellation: "Bestellings-annulering",
        orderCancellation2: "Weet u zeker dat u de bestelling wilt annuleren?",
        tobeexecuted: "Om geëxecuteerd te worden",
        expired: "verlopen",
        failed: "mislukt",
        contractTransaction: "Eeuwigdurend contract",
        increase: "Toename",
        openPosition: "Volledig magazijn",
        isolatedPosition: "Geïsoleerde positie",
        limitOrder: "Limit",
        marketOrder: "marktprijs",
        pleaseSelectLeverage: "Selecteer hefboomwerking",
        pleaseEnterQuantity: "Voer het aantal in",
        canOpenMore: "Maximaal te openen",
        contractAmount: "Contractbedrag",
        openingFee: "verwerkingskosten",
        currentPosition: "Positie",
        historicalPositions: "Historische transacties",
        currentCommission: "Huidige order",
        historicalCommission: "historische commissie",
        buyPrice: "koopprijs",
        income: "winst",
        quantity: "hoeveelheid",
        marginRate: "marge tarief",
        initialMargin: "Initiële marge",
        estimatedPriceParity: "Gedwongen liquidatieprijs",
        operate: "bedienen",
        finishQuantity: "Transactievolume",
        averagePrice: "gemiddelde transactieprijs",
        pleaseEnterLeverage: "Voer een hefboomwerking in",
        takeProfitAndStopLoss: "TP/SL",
        takeProfit: "Neem winst",
        stopLoss: "verlies stoppen",
        openLong: "Long",
        openShort: "Short",
        liquidation: "Sluit positie",
        marketLquidation: "Marktliquidatie",
        cancel: "Annuleer bestelling",
        enterBuyPrice: "Voer de aankoopprijs in",
        areYouSureLquidation: "Weet u zeker dat u de positie wilt sluiten?",
        confirm: "Zeker",
        enterStopWinPrice: "Voer de take-profitprijs in",
        enterStopLossPrice: "Voer de stop loss-prijs in",
        commissionTime: "Commissie tijd",
        commissionPrice: "Commissie prijs",
        commissionQuantity: "Totaalbedrag aan commissies",
        volumeAlreadyTraded: "Bedrag voltooid",
        areYouSureToWinOrLoss: "Weet u zeker dat u de winst en het verlies wilt stoppen?",
        maxQuantity: "Maximale positie",
        saleQuantity: "Gesloten positie",
        salePrice: "gemiddelde slotkoers",
        saleTime: "tijd",
        succeeded: "geslaagd",
        cancelled: "Geannuleerd",
        piece: "open",
        triggerPrice: "trigger prijs",
        setWin: "Stel take-profit in",
        setLoss: "Stop-loss instellen",
        enterWinQuantity: "Voer het take-profitbedrag in",
        enterLossQuantity: "Voer het stoplossbedrag in",
        planCommission: "Plancommissie",
        enterWinTriggerPrice: "Voer de take-profit-triggerprijs in",
        enterLossTriggerPrice: "Voer de stop loss-triggerprijs in",
        insufficientQuantity: "Onvoldoende hoeveelheid",
        enterTriggerPrice: "Voer de triggerprijs in",
        tips1: "Uw orderprijs is hoger dan de huidige prijs en wordt direct uitgevoerd tegen de huidige marktprijs. Weet u zeker dat u wilt kopen?",
        tips2: "Uw orderprijs is lager dan de huidige prijs en wordt direct uitgevoerd tegen de huidige marktprijs. Weet u zeker dat u koopt?",
        type: "type",
        increaseQuantity: "Positie vergroten",
        reduceQuantity: "Posities verkleinen",
        limitCommission: "limiet volgorde",
        tips3: "De stop loss-prijs moet lager zijn dan de marktprijs",
        tips4: "De take-profitprijs moet hoger zijn dan de marktprijs",
        tips5: "De stop loss-prijs moet hoger zijn dan de marktprijs",
        tips6: "De take-profitprijs moet lager zijn dan de marktprijs",
        tips7: "De stop loss-prijs moet lager zijn dan de marktprijs en lager dan de orderprijs",
        tips8: "De take-profitprijs moet groter zijn dan de orderprijs (kan kleiner zijn dan de marktprijs)",
        tips9: "De stop loss-prijs moet groter zijn dan de marktprijs en groter dan de orderprijs",
        tips10: "De take-profitprijs moet kleiner zijn dan de orderprijs (kan groter zijn dan de marktprijs)",
        accountEquity: "Accountsaldo",
        unrealizedPL: "ongerealiseerde winst of verlies",
        tips11: "Weet je zeker dat je gelijk hebt",
        tips12: "Markt",
        tips13: "Sluit alle posities",
        tips14: "Niet opnieuw herinneren",
        profitRate: "Slotkoers van het rendement",
        openTime: "Openingstijd",
        unwindTime: "Sluitingstijd",
        dealTime: "Transactietijd",
        amountClose: "Bedrag van gesloten positie",
        long: "veel",
        short: 'nul',
        orderQuantity: "Bestelhoeveelheid",
        entrustedMargin: "toevertrouwde marge",
    },
    spotGoods: {
        spotGoods: "contant",
        sellPrice: "verkoopprijs",
        orderRecord: "Flash-inwisselingsrecord",
        transactionTime: "Transactietijd",
        maxBuy: "beschikbaar om te kopen",
        maxSell: "Beschikbaar voor verkoop",
        purchaseQuantity: "Aankoop hoeveelheid",
        slippage: "Slippen",
        convertTips: "We sluiten de transactie af tegen de gunstigste prijs met een slippage van 0-5%.",
        changePrice: "Huidige wisselkoers",
        consume: "consumeren",
        get: "krijgen",
        changeTips: "Weet je zeker dat je wilt inwisselen?",
        confirm: "Bevestig de inwisseling"
    },
    news: {
        title: "nieuws centrum",
        redMore: "Lees verder",
    },
    credit: {
        credit: "kredietwaardigheid",
        creditScore: "totale kredietscore",
        latestEntry: "laatste record",
        liveUpdate: "Live Update",
        systemEvaluation: "Systeemevaluatie",
        time: "datum",
        name: "naam",
        reason: "reden",
        scoreStory: "Kredietscorerecord"
    },
    borrowing: {
        borrowing: "lening",
        diversifiedMarket: "Gediversifieerde markten",
        totalSupply: "Totaal aanbod",
        totalBorrowings: "totale leningen",
        tips1: "Dit is het totale bedrag dat u met uw onderpand kunt lenen, tot de leenlimiet.",
        tips2: "Uw stabiele rente blijft hetzelfde gedurende de looptijd van uw lening. Aanbevolen voor levering op lange termijn en gebruikers die van voorspelbaarheid houden.",
        totalSupplys: "totaal aanbod",
        supplyAPR: "aanbod tarief",
        totalBorrowings: "Totale leningen",
        borrowAPR: "leenrente",
        check: "Rekening",
        market: "markt",
        availableLiquidity: "Beschikbare liquiditeit",
        currencyPrice: "huidige valutaprijs",
        supplyAssets: "activa leveren",
        tips3: "Let op: Het onderstaande rentepercentage is een vast rentepercentage. Nadat de aanbodperiode voorbij is, worden de hoofdsom plus de inkomsten op uw rekening teruggestort.",
        supplyCycle: "aanbodcyclus",
        minimumSupply: "minimaal aanbod",
        supply: "levering",
        borrowedAssets: "geleende activa",
        tips4: "Belangrijk om te weten: als u geld wilt lenen, moet u onderpand of bewijs overleggen,",
        tips5: "Zie de omschrijving van het hypotheekvermogen.",
        repaymentCycle: "terugbetalingscyclus",
        BorrowableAmount: "Beschikbaar leenbedrag",
        borrow: "lening",
        accountInformation: "Account Informatie",
        walletBalance: "portemonnee saldo",
        canSupply: "Beschikbaar",
        AmountCanBeBorrowed: "Totaal beschikbaar leenbedrag",
        borrowedAmount: "Geleend bedrag",
        remainingAmount: "resterende saldo",
        getBorrowednAmount: "Kredietlimiet verkrijgen",
        supplyOrder: "bestelling leveren",
        borrowedOrder: "lening bestelling",
        supplyAmount: "hoeveelheid aanbod",
        expectedProfits: "Verwachte terugkomst",
        supplyTime: "Levertijd",
        expirationTime: "Vervaldatum",
        borrowingAmount: "Leenbedrag",
        dueRepayment: "Terugbetalingsbedrag",
        borrowingTime: "Tijd vrijgeven",
        repaymentTime: "Terugbetalingstijd",
        repay: "terugbetaling",
        supplyCurrency: "valuta leveren",
        buyNow: "Koop nu",
        supplyRecords: "leveringsregistraties",
        borrowRecords: "Leninggegevens",
        completed: "voltooid",
        borrowNow: "Nu lenen",
        borrowCurrency: "Valuta lenen",
        borrowMin: "kleinste",
        borrowMax: "maximaal",
        borrowRange: "Leenbedrag (minimum/maximum)",
        tips6: "Gelieve op tijd terug te betalen. Als u te laat bent, wordt er een extra dagelijks bedrag (hoofdsom + rente) in rekening gebracht.",
        tips7: "Achterstallige vergoeding.",
        pleaseEnterBorrowAmount: "Vul het leenbedrag in",
        interest: "Interesse",
        tips8: "Weet u zeker dat u vooraf wilt afbetalen?",
        borrowChannel: "Kanaal lenen",
        tips9: "Laten we beginnen met u te leren kennen",
        name: "naam",
        lastName: "achternaam",
        birthDate: "geboortedatum",
        tips10: "Iets meer informatie, wat is uw adres?",
        familyAddress: "Woonadres (inclusief unitnummer)",
        city: "Stad",
        country: "natie",
        postalCode: "Postcode",
        pleaseEnterYourHomeAddress: "Voer uw thuisadres in",
        tips11: "Vertel ons over uw financiële situatie",
        tips12: "Mogelijk moeten we deze informatie later bevestigen, dus probeer zo nauwkeurig mogelijk te zijn",
        yourMonthlyIncome: "uw maandinkomen",
        enterYourMonthlyIncome: "Vul uw maandinkomen in",
        extraIncomeOptional: "Extra inkomen (optioneel)",
        enterExtraIncome: "Vul uw extra inkomsten in",
        monthlyRentOrMortgagePayment: "Maandelijkse huur- of hypotheekbetaling",
        enterMonthlyRent: "Vul uw maandelijkse huur- of hypotheekbetaling in",
        tip13: "Vertel ons of u een hypotheek heeft",
        tip14: "Als er geen hypotheekgarantie wordt geselecteerd, is de kans dat u de toetsing doorstaat laag.",
        isThereMortgage: "Is er een hypotheek?",
        selectIsThereMortgage: "Geef aan of er sprake is van een hypotheek",
        selectMortgageType: "Selecteer een hypotheekvorm",
        yes: "Ja",
        no: "Nee",
        uploadScreenshotOfTransaction: "Upload transactiescreenshots",
        tip15: "Laten we contact houden",
        tip16: "Op dit nummer kunnen wij contact met u opnemen als wij meer informatie nodig hebben over uw leningaanvraag",
        tip17: "Ik heb mijn toestemming gelezen en elektronisch ondertekend",
        tip18: "Gebruiksvoorwaarden en elektronische toestemming",
        tip19: ", waaronder uw toestemming voor het verzenden van informatie over onze",
        tip20: "Product- en servicevoorwaarden",
        tip21: "Wacht na het invullen van de informatie op de systeemevaluatie en -beoordeling.",
        phoneNumber: "telefoon nummer",
        enterPhoneNumber: "Voer uw telefoonnummer in",
        tip22: "Vink het vakje aan om akkoord te gaan met de gebruiksvoorwaarden en het elektronische toestemmingsformulier",
        inReview: "wordt beoordeeld",
        applicationAmount: "Aanvraagbedrag",
        reasonForRejection: "Redenen voor afwijzing",
        reapply: "Opnieuw solliciteren",
        applyStatus: "aanvraagstatus",
        currentOrder: "huidige order",
        interestRate: "rente"
    },
    yuebao: {
        filed1: "investeren",
        filed2: "afhaalmaaltijd",
        filed3: "Dagelijks inkomen",
        filed4: "teamversnelling",
        filed5: "Gestort",
        filed6: "De winst van gisteren",
        filed7: "De totale omzet",
        filed8: "Beschikbare fondsen",
        filed9: "Voer het bedrag in",
        filed10: "Liquiditeitsmijnbouw",
        filed11: "Invoering",
        field12: "Minimale aankoopprijs",
        field13: "Het maximale dat je kunt kopen",
        filed14: "Abonnement",
        filed15: 'normaal',
        filed16: 'rente op jaarbasis',
    },
    tipsPopover: {
        title: "Installeer deze applicatie",
        press: "volgens",
        and: "En",
        scrren: "Toevoegen aan startscherm",
        hasInstall: "(Indien geïnstalleerd, sluit dan het venster)",
    },
    follow: {
        title1: "Vervolgplan",
        follow_text1: "Start een vervolgbestelling",
        follow_text2: "Releasegeschiedenis",
        follow_text3: "titel",
        follow_text4: 'handelspaar',
        follow_text5: 'Aankoopduur',
        follow_text6: 'bestellingstijd',
        follow_text7: 'tijd vrijgeven',
        follow_text8: 'Aantal volgers',
        follow_text9: 'Bestelbedrag',
        follow_text10: 'bedienen',
        follow_text11: 'uitnodiging',
        follow_text12: 'Volg de bestelrichting',
        follow_text13: 'Bestelvoorwaarden instellen',
        follow_text14: 'Voorwaarden voor het volgen van bestellingen',
        follow_text15: 'vaste hoeveelheid',
        follow_text16: 'percentage',
        follow_text17: 'Uitgenodigde mensen',
        follow_text18: 'Het identiteitsbewijs van de andere partij',
        follow_text19: "Bevestig om de bestelling te volgen",
        follow_text20: "Uw orderbedrag (optioneel)",
        follow_text21: "Voer een titel in",
        follow_text22: "Selecteer een handelspaar",
        follow_text23: "Selecteer de bestelrichting",
        follow_text24: "Selecteer de aankoopduur",
        follow_text25: "Selecteer het besteltijdstip",
        follow_text26: "Selecteer het aankoopaantal",
        follow_text27: "De maximale afnamehoeveelheid kan niet kleiner zijn dan de minimale afnamehoeveelheid",
        follow_text28: "Minimale buy-in",
        follow_text29: "De meeste kopen",
        follow_text30: "Kopieeropdracht succesvol aanmaken",
        follow_text31: "Lijst met handelsparen",
        follow_text32: "Heb de bestelling al gevolgd",
        follow_text33: "Voer de gegevens van de genodigde in",
        follow_text34: "Uitgenodigd",
        follow_text35: "heeft me uitgenodigd",
        follow_text36: "Geschiedenis kopiëren",
        follow_text37: "Bereik van bestelbedrag"
    },
    followHistory: {
        title1: 'Releasegeschiedenis',
        title2: "Geschiedenis kopiëren",
        follow_h_text1: "Het totale bedrag van de bestelling",
        follow_h_text2: "Totaal aantal mensen dat bestellingen volgt",
        follow_h_text3: "Aantal releases",
        follow_h_text4: "winnende tarief",
        follow_h_text5: "Aantal keren dat bestellingen moeten worden opgevolgd",
        follow_h_text6: "Profiteer van het volgen van bestellingen",
        follow_h_text7: "richting",
        follow_h_text8: "winnen",
        follow_h_text9: "tekort",
    },
    "asset.index.1579E8B41F8D5134": "mijn bezittingen",
    "asset.index.B3F6F05Dfbe431Cd": "simulatie",
    "asset.index.B9Aeab4Ee8F33746": "Waardering van activa",
    "asset.index.583Df8Bd541Eb7E8": "winstontwikkelingen",
    "asset.index.B1254815D133Cc26": "7 dagen",
    "asset.index.2F615F6C029F01B7": "30 dagen",
    "asset.index.29439Fb58E4Bbe22": "terugtrekken",
    "asset.index.831Ab31568A78285": "bijvullen",
    "asset.index.39F7A0D48D12E8C9": "overdracht",
    "asset.index.B99063C5689Beaec": "contant",
    "asset.index.E1Dc620F2F038B2A": "Ontvang activa",
    "asset.index.972A0B4C2B4393D0": "mijn rekening",
    "asset.index.9Dfb0Fb334620733": "beschikbaar saldo",
    "asset.index.Cdc5Fbd26790Cb47": "bevroren hoeveelheid",
    "asset.index.3D8132D72Ae965Cd": "Waardering",
    "asset.receive.825503Cc430559Cb": "bezittingen verwerven",
    "asset.receive.54154A0553A535B7": "Demo-items",
    "asset.receive.F2E57B00A9C5D129": "1.Dit item mag alleen worden gebruikt in de demo-accountmodus",
    "asset.receive.Ea0D1D52989299Bd": "2.Om misbruik te voorkomen is er binnen een maand slechts één keer aflossing mogelijk.",
    "asset.receive.090E0037Aa235D3D": "3.Bij het ontvangen van activa wordt het bedrag van de ontvangen activa op de oorspronkelijke basis opgeteld en wordt de positiestatus niet gewijzigd.",
    "asset.receive.7Fd4E1Abc805C0A6": "4.Als u nog vragen heeft, kunt u contact opnemen met de klantenservice",
    "asset.receive.Df5B3A511D986575": "Ontvang nu demo-items",
    "asset.records.1Fa02E8187A39592": "laatste drie maanden",
    "asset.records.7B291C37333Fe34D": "van dichtbij tot ver",
    "asset.records.A476Cd1Ec868Fc5F": "laatste drie maanden,",
    "asset.records.9D5755A842E55Bb7": "van dichtbij tot ver,",
    "asset.records.E3Fa05E607203Cdc": "bijvullen",
    "asset.records.B4F206C6Bec08Dea": "terugtrekken",
    "asset.index.Df5B3A511D986576": "U heeft de demo-items met succes ontvangen",
    "deposit.depositSpeedy.Efe81D0E0174239B": "betalingsmiddel",
    "deposit.depositSpeedy.B9293Ee96E435F85": "Fiat-valuta",
    "deposit.depositSpeedy.16658A855F07D171": "Hoeveelheid",
    "deposit.depositSpeedy.204Ffab8A6E01870": "Zeker",
    "deposit.depositSpeedy.22E5Dfb74Ead8Aa9": "Kan niet leeg zijn",
    "deposit.depositSpeedy.B8E774D0E4E5E4C1": "Minimale oplaadbeurt",
    "deposit.depositSpeedy.F169D816F6Dd83C5": "Maximaal opladen",
    "deposit.depositWallet.F64Ec60B1Db4A6D6": "Stort USDT",
    "deposit.depositWallet.551640C76F4Ca4A1": "Valuta opwaarderen",
    "deposit.depositWallet.B404C543712B71D5": "Netwerk opladen",
    "deposit.depositWallet.A484B0F5E94B3F4F": "Stortingsadres",
    "deposit.depositWallet.D3980D54Acf242Db": "QR-code opslaan",
    "deposit.depositWallet.A06E84801D6582Be": "Bedrag opwaarderen",
    "deposit.depositWallet.Daaaeb1B7B22B126": "hint",
    "deposit.depositWallet.D599E40E1Eeec1B3": "Opladen succesvol",
    "deposit.depositWallet.4F48Abbafce532Bb": "Succesvol ingediend, wachtend op beoordeling door de klantenservice",
    "deposit.depositWallet.A88C481228811696": "Je hebt geweigerd van netwerk te wisselen",
    "deposit.depositWallet.C6Ebf27895C21932": "Open handmatig de TRON portemonnee-plug-in en log in",
    "deposit.index.B5491C8Dd09D8183": "Selecteer de valuta die u wilt opwaarderen",
    "deposit.index.2F784B2298D39987": "Snel opladen",
    "deposit.index.0Daa03Dcdcabd837": "Offsite links",
    "withdraw.bottomSheet.628A63056Ef2D2Fe": "Bevestiging van intrekking",
    "withdraw.bottomSheet.A086Fa20082C849D": "Zorg ervoor dat de onderstaande informatie correct is",
    "withdraw.bottomSheet.Bf8B184D32971501": "Opname netwerk",
    "withdraw.bottomSheet.8758Fd50C87D6C9C": "adres",
    "withdraw.bottomSheet.F9Db93B87E08763B": "hoeveelheid",
    "withdraw.bottomSheet.4Ad26475002878C0": "verwerkingskosten",
    "withdraw.index.A96454041169Af6D": "Selecteer valuta",
    "withdraw.index.2A563B7081Dd5B83": "Herroepingsadres",
    "withdraw.index.B189C2Ef713Cfff4": "Aantal opgenomen munten",
    "withdraw.index.558F35140E84B562": "Beschikbaar：",
    "withdraw.index.F4E759087E12Cb77": "Alles intrekken",
    "withdraw.index.5Cbd9A6B284D25Ae": "Wachtwoord van het fonds",
    "withdraw.index.B89Cbe4Bfc33Bb41": "verwerkingskosten:",
    "withdraw.index.C886De8D8A27026E": "belangrijke mededeling",
    "withdraw.index.Eac7C5A4095F5606": "1.Om arbitrage te voorkomen, kunt u geldopname aanvragen wanneer het transactievolume het quotum bereikt.",
    "withdraw.index.C3Cc7Fff24504Be9": "2.De opnameaanvraag wordt binnen 24 uur na indiening gecrediteerd. Als de opname na de geschatte opnametijd niet is ontvangen, neem dan contact op met de online klantenservice.",
    "withdraw.index.0B4Ad7Ccdc833B0D": "3.Na het indienen van de opnameaanvraag zijn de gelden bevroren omdat de opname bezig is en de gelden tijdelijk door het systeem worden vastgehouden. Dit betekent niet dat u het tegoed kwijt bent of dat er een afwijking aan het tegoed is.",
    "withdraw.index.4639F3A6E07C00B3": "Volgende stap",
    "withdraw.index.81Fba0D65Cc5C3Aa": "Voer uw portemonnee-adres in of plak het",
    "withdraw.index.25768141C8Bdea91": "Voer het opnamebedrag in",
    "withdraw.index.97A85734Cd1B7Bf0": "Voer het wachtwoord van het fonds in",
    "withdraw.index.39C7D74B33241C78": "Het opnamebedrag is verkeerd",
    "withdraw.index.86A5D17908197525": "Bevestiging van opname, wachten op beoordeling om munten vrij te geven)",
    "withdraw.index.48Fb603C6638C7F6": "Stel een fondswachtwoord in",
    "withdraw.index.873Ade9A3Bacc2F7": "hint",
    "bill.index.5Bbb2A6Ac68D6B2E": "Echt account",
    "bill.index.75751E0Eb8261C40": "Demo-account",
    "bill.index.Cbc853B37Ecc2E97": "Type accountwijziging",
    "bill.index.4A0Cacddeb2E38B1": "account type",
    "bill.index.F16217028E098B2F": "Munteenheid",
    "bill.index.A620Dbe6F06B78Ba": "Alle typen",
    "bill.index.2C3C148Bb94D9B3F": "Alle rekeningen",
    "c2C.index.Ab45695E4Bf1E382": "C2C-transactie",
    "c2C.index.2702Dc911Dfea762": "ik wil kopen",
    "c2C.index.D737D5B8367A53C4": "ik wil verkopen",
    "c2C.index.C0C540A3Ba9Abe01": "Volume",
    "c2C.index.D8644E8D60C8Bd8A": "eenheid prijs",
    "c2C.index.Ebb8670B203D3604": "begrenzing",
    "c2C.index.F8C6245A2Ebf1D27": "Alle bankkaarten",
    "c2C.index.F8C6245A2Ebf1D28": "valuta collectie",
    "c2C.index.F8C6245A2Ebf1D29": "bankpas",
    "c2C.index.Cc3Aa8141Da9Ec9E": "Kopen",
    "c2C.index.Cc3Aa8141Da9Ec9F": "verkopen",
    "c2C.index.67A39834582Ad99A": "Geen gegevens",
    "c2C.order.04C23810B47F77F7": "per bedrag",
    "c2C.order.6905365902E0A73D": "per hoeveelheid",
    "c2C.order.3F0Ecebbd9D2F23B": "Beschikbaar",
    "c2C.order.16853Bda54120Bf1": "alle",
    "c2C.order.Ad3335796F2C95F7": "forfaitair bedrag",
    "c2C.order.61092E27A6879318": "Om de veiligheid van bedrijfsmiddelen te beschermen, verzoeken wij u uw bewustzijn over preventie te vergroten!",
    "c2C.order.0F51881F0Fb920B2": "Handelsinformatie",
    "c2C.order.Dd74Ceca85E5Bc65": "Betalingstermijn",
    "c2C.order.De3341388A59073F": "30 minuten",
    "c2C.order.D334B597A352167F": "Bijnaam van de verkoper",
    "c2C.order.D334B597A352167G": "Bijnaam van koper",
    "c2C.order.9D5F89F99Ced890D": "middel van transactie",
    "c2C.order.8Adf755B14Bc6B7C": "Lees eerst het volgende",
    "c2C.order.686B16F454A7338B": "Maak geen opmerkingen bij een bankoverboeking, anders wordt het geld niet vrijgegeven en wordt de rekening direct geblokkeerd. Na betaling moet u na de betaling een nieuw diagram met transactiedetails verstrekken (als het P-diagram of verborgen transactiegegevens aan het platform worden gerapporteerd om het account te bevriezen)",
    "c2C.order.9Bec8Fba867B739B": "Selecteer betaalmethode",
    "c2C.order.Cf2D892C419Fea36": "minimale aankoop",
    "c2C.order.7290A6B7456A03E0": "grootste aankoop",
    "c2C.orderConfirm.F7E99B8C203E2B07": "Bestel Details",
    "c2C.orderConfirm.036D94Dc2D1Cf7D3": "Bestelling is gegenereerd",
    "c2C.orderConfirm.0F5433503F3845Ce": "Betaal de verkoper binnen de afteltijd.",
    "c2C.orderConfirm.24C3Bcacdb85A451": " Wachten op betaling van de koper ",
    "c2C.orderConfirm.8E8140D7Ab607B24": "contacteer de verkoper",
    "c2C.orderConfirm.8E8140D7Ab607B25": "contact opnemen met koper",
    "c2C.orderConfirm.Ec4D8722Ec15C813": "De digitale valuta van de verkoper is nu op de geblokkeerde rekening gestort. U kunt gerust betalen  ",
    "c2C.orderConfirm.69D1Fbbc7700C1Ab": "Echte naam van de koper：",
    "c2C.orderConfirm.E5Ed8Ec53Edcd5D3": "7x24 uur klantenserviceondersteuning ",
    "c2C.orderConfirm.Abf9F4B8C93D641E": "bestellingsnummer",
    "c2C.orderConfirm.726C51702F70C486": "creatie tijd",
    "c2C.orderConfirm.E097Bb9B1216Bfd8": "Contacteer de Klantenservice",
    "c2C.orderConfirm.103408Fbbe30287F": "annuleer bestelling",
    "c2C.orderConfirm.4E93Df1Fb521F4Ba": "Ik heb de betaling bevestigd",
    "c2C.orderConfirm.3B5418Dbea7Ebfe4": "Al betaald",
    "c2C.orderConfirm.387Ca3625B260E4E": "Uw betaalde bestelling",
    "c2C.orderConfirm.7C83D5F1Db1A08B7": "annuleer transactie",
    "c2C.orderConfirm.76C86566Cc3751C0": "Bestelling geannuleerd",
    "c2C.orderConfirm.205B349751D176B4": "betaling gelukt",
    "c2C.paymentAdd.6F2A7Dcc66E6Acba": "betalingsmiddel",
    "c2C.paymentAdd.C87E865Df6800304": "Configuratie van de betalingsmethode",
    "c2C.paymentAdd.B59Ae0F1B1C235Df": "Type betalingsmethode",
    "c2C.paymentAdd.06910Bddf002695F": "werkelijke naam",
    "c2C.paymentAdd.3E33Ae3Efa7507Dd": "Paypal account",
    "c2C.paymentAdd.3E33Ae3Efa7507De": "Bankrekeningnummer/kaartnummer",
    "c2C.paymentAdd.3E33Ae3Efa7507Df": "Valutanaam",
    "c2C.paymentAdd.595Ede91Abf6F6E0": "Betaal QR-code",
    "c2C.paymentAdd.1Ecc8Be9Ba1C940A": "Klik om te uploaden",
    "c2C.paymentAdd.6Daae340F2E9718A": "banknaam",
    "c2C.paymentAdd.347751415C3Da489": "Kettingnaam",
    "c2C.paymentAdd.15B3627Faddccb1D": "Opmerking",
    "c2C.paymentAdd.86Da96Cbd274058F": "Warme herinnering: wanneer u digitale valuta verkoopt, wordt de door u gekozen betalingsmethode aan de koper weergegeven. Bevestig dat de informatie correct is ingevuld.",
    "c2C.paymentAdd.4Ce9979Bfb6576D9": "redden ",
    "c2C.paymentAdd.90D2A3A5C07179C0": "Vul alstublieft uw echte naam in",
    "c2C.paymentAdd.A3B0Cd3Ad9Ca3475": "Voer uw",
    "c2C.paymentAdd.Ec389E6190Eedb62": "Voer uw banknaam in",
    "c2C.paymentAdd.9Be51Fb1E9025Fb2": "Voer uw kettingnaam in",
    "c2C.paymentAdd.94F034F0E01439E0": "Voer uw adres in",
    "c2C.paymentAdd.B12E035663A42B32": "Alle bankkaarten",
    "c2C.paymentAdd.C7B677F393A8F8Ac": "valuta collectie",
    "c2C.paymentAdd.Abafe11737Bab7Cd": "Upload de betalingscode",
    "c2C.paymentAdd.07C443212Ff04Bfb": "Succesvol toegevoegd",
    "c2C.paymentDetail.86Da96Cbd274058F": "Warme herinnering: wanneer u digitale valuta verkoopt, wordt de door u gekozen betalingsmethode aan de koper weergegeven. Bevestig dat de informatie correct is ingevuld.",
    "c2C.paymentDetail.C4C613Da60226De4": "Succesvol gewijzigd",
    "c2C.paymentMethod.7609F7C8337C7A17": "C2C-betaalmethode",
    "c2C.records.9E36893D5736Abb4": "Bestellijst",
    "c2C.records.4B53F9A5B13B3235": "In afwachting van betaling",
    "c2C.records.4B53F9A5B13B3236": "annuleer transactie",
    "c2C.records.4B53F9A5B13B3237": "Al betaald",
    "contract.index.9706Df877458F0Be": "Volledig magazijn",
    "contract.index.623Fc7D3D40Aa479": "Initiële marge",
    "contract.index.F92Dce1169330387": "Marktliquidatie",
    "contract.index.3598B97Dd28Dbf20": "Vul het aantal in (stuks)",
    "contract.index.6E1Fae821Dc0B797": "Duurzaam",
    "contract.index.Eb07Eb0C37C14575": "Levering",
    "contract.index.Eb6492F19B1C1Fb6": "Historische transactiedetails",
    "contract.index.873Ade9A3Bacc2F7": "hint",
    "contract.index.Ff63B0F93D39F50B": "Weet je zeker dat je gelijk hebt",
    "contract.index.Ff63B0F93D39F50D": "Ga lang",
    "contract.index.Ff63B0F93D39F50E": "kort",
    "contract.index.Ff63B0F93D39F50F": "gedrag",
    "contract.index.Ff63B0F93D39F50G": "Alle posities sluiten?",
    "customer.dialog.3D410C88E8A24Fe8": "Bevestig de bijnaam",
    "customer.dialog.4B3340F7872B411F": "Annuleren",
    "customer.dialog.549B1456Bb5F98B3": "Voer alstublieft uw bijnaam in",
    "customer.dialog.B773B3E45Ccfad00": "Bijnaam is leeg",
    "customer.index.7308C6B849D159E9": "Afbeeldingsdetails",
    "delivery.callDialog.C74F562C3Fe91Fe3": "succesvol besteld",
    "delivery.callDialog.B8C98F42Caa15Ca5": "richting",
    "delivery.callDialog.B79A581Dae543974": "lange koop",
    "delivery.callDialog.B79A581Dae543975": "korte koop",
    "delivery.callDialog.3B26F0F7E90773F3": "Openingsprijs",
    "delivery.callDialog.65Fb252Bd94E1039": "Geschatte omzet",
    "delivery.index.B483Ba4Fce633539": "Levering",
    "delivery.index.224Dd6Cb58D58249": "handelsperiode",
    "delivery.index.28158F517E902788": "Minimale buy-in",
    "delivery.index.A1232162B14Bec76": "Bezorgopdracht",
    "delivery.index.445B1D3176D9C69D": "Historische orden",
    "delivery.index.Ab6Cb86E28653C5C": "Levering",
    "delivery.index.20A7A6F14D3698C0": "Openingstijd",
    "delivery.index.Ec6821A49395Ddc2": "Sluitingstijd",
    "delivery.index.254Fdc665C391743": "Slotkoers",
    "delivery.index.2A0C5E68B44A472A": "Winst en verlies",
    "delivery.index.E05B33309F6D236A": "Voer het aantal in",
    "delivery.index.B2899E073Cb355D9": "Minimale aankoop",
    "download.description.869467A93C7Dd4A1": "gebruikshandleiding",
    "download.description.C80C1Edd6Bee12Bc": "1.Sta toe dat beschrijvingsbestanden worden gedownload",
    "download.description.2F5Bc180F92642B4": "2.Beschrijvingsbestand gedownload",
    "download.description.3C3B849F6Ccf712F": "3.Voer [Instellingen] in op uw telefoon en het gedownloade beschrijvingsbestand wordt onder de accountinformatie weergegeven.",
    "download.description.86Affd862985E045": "4.Klik om het beschrijvingsbestand te downloaden",
    "download.description.32832492Bfc1D95E": "5.Klik om te installeren",
    "download.index.245883C80F181C4A": "downloaden",
    "download.index.Ef3De8Cb2722937A": "Begin altijd en overal met handelen",
    "download.index.F4A87Eaeed691638": "Blijf op de hoogte van het laatste nieuws via onze APP",
    "download.index.Ad3Aba52D0D39D01": "Download het installatiepakket",
    "download.safariGuide.61Bd9A8Fcc2Ea788": "Vriendelijke tips",
    "download.safariGuide.2Cc68Aaadf623477": "Bezoek alstublieft in Safari",
    "download.safariGuide.C80Fff2A6Ff4E768": "U bezoekt een gedecentraliseerde website. Voer de Safari-applicatie uit",
    "download.safariGuide.06Da016F3E2F79Bd": "Kopieer link",
    "finance.index.B034B367545D5236": "Wereldwijde hoogwaardige projecten",
    "finance.index.6Ebfac2C0A9228B0": "Meer dan 1,6 miljoen actieve gebruikers, $122,4 miljard",
    "finance.index.52Bdcaf19659363D": "Liquiditeitsmijnbouw",
    "finance.index.D66924272B031Bf5": "Super hoog inkomen op jaarbasis",
    "finance.index.34789D88Afcefa64": "Bekijk artikelen",
    "finance.index.E12676B95654D21E": "krediet lening",
    "finance.index.4C924Cfd89A11Ac7": "Ontdek meer handelsmogelijkheden",
    "finance.index.C251D4B594Da2848": "AI-kwantificering",
    "finance.index.4E53Cf2E5F173Dd3": "Lease met één klik, slimme AI-transactie",
    "finance.records.Cce168301447D1Ba": "bezig",
    "finance.records.4587B6Bd7Cfa4454": "ingewisseld",
    "finance.records.18685418E3A50147": "Mijnbouw",
    "finance.records.Df9Fd438F97Cc908": "lucht",
    "finance.records.E23Cb03C5Ad359E5": "de voorwaarde",
    "finance.records.Bd87Dac6Cba7Eba8": "Aankoop hoeveelheid",
    "finance.records.2D0Fff99265E8B1A": "Winstgevend",
    "finance.records.2D0Fff99265E8B1B": "Winst op de eindvervaldag",
    "finance.records.024F5F0B851782De": "Mijn tijd：",
    "finance.records.3143A26D3F983896": "verlossing tijd：",
    "finance.records.96574Aaf8Db85Ead": "vervroegde aflossing",
    "finance.records.531E1829203181Ac": "Weet u zeker dat u eerder wilt inwisselen;",
    "finance.records.9205843B8923Dac3": "Inwisseling succesvol;",
    "loan.applyCallback.B9258Ec8746A74D8": "Kanaal lenen",
    "loan.applyForm.7B8Ed340400A6E2B": "naam",
    "loan.applyForm.549A44Bae725F4A2": "achternaam",
    "loan.applyForm.2A27E61375E7Fea8": "geboortedatum",
    "loan.applyForm.89925B019D537738": "e-mailadres",
    "loan.applyForm.Afaeb32Cb4D62E28": "Woonadres (inclusief unitnummer)",
    "loan.applyForm.E05B5D049B64B040": "Stad",
    "loan.applyForm.B166E4E8Fe9513Fa": "natie",
    "loan.applyForm.83Aaede109E395F8": "Postcode",
    "loan.applyForm.647Ed21332A4968B": "uw maandinkomen",
    "loan.applyForm.Cf0A9B845D34D4B4": "Extra inkomen (optioneel)",
    "loan.applyForm.7048F618Acc6A54C": "Maandelijkse huur- of hypotheekbetaling",
    "loan.applyForm.15C1Bba18Ae18545": "Vertel ons of u een hypotheek heeft",
    "loan.applyForm.3921E90625A84E36": "Als er geen hypotheekgarantie wordt geselecteerd, is de kans dat u de toetsing doorstaat laag.",
    "loan.applyForm.262Eec4F8906B0F6": "Is er een hypotheek?",
    "loan.applyForm.8634Eb21B6910F21": "Selecteer een hypotheekvorm",
    "loan.applyForm.A4897B3A331B5E61": "Transactiebon",
    "loan.applyForm.6623384C683De457": "Laten we contact houden",
    "loan.applyForm.A8360Bdba86834Cb": "Op dit nummer kunnen wij contact met u opnemen als wij meer informatie nodig hebben over uw leningaanvraag",
    "loan.applyForm.C1C373F385A4C7B3": "telefoon nummer",
    "loan.applyForm.1B9785Badb91191F": "Wacht na het invullen van de informatie op de systeemevaluatie en -beoordeling.",
    "loan.applyForm.8755B9C761D4080C": "Ik heb mijn toestemming gelezen en elektronisch ondertekend",
    "loan.applyForm.3E987B787468148C": "Gebruiksvoorwaarden en elektronische toestemming",
    "loan.applyForm.Bc54E76B61C50Fb9": "Lees de overeenkomst en ga ermee akkoord",
    "loan.applyForm.89E158384F634Eb8": "Keer terug naar de vorige stap",
    "loan.applyForm.Efa5A4548E2F3510": "Thuisadres",
    "loan.applyForm.4Cadf1787541B35D": "Ja,",
    "loan.applyForm.Fab408D2F4100447": "Nee,",
    "loan.applyForm.3Bad7A307480Cc56": "Het e-mailformaat is verkeerd;",
    "loan.applyForm.306322F49B36855D": "De aanvraag is ingediend, wacht op de beoordeling van de klantenservice",
    "loan.applyForm.3C6E0E858C995Ff9": "Upload de transactievoucher",
    "loan.applyForm.2Bf443476B271376": "Er kunnen maximaal 4 foto's worden geüpload",
    "loan.applyQuota.Ef1D8E23003B9A6F": "Kanaal lenen",
    "loan.applyQuota.Ef1D8E23003B9A6G": "Kredietlimiet verkrijgen",
    "loan.applyQuota.9E0780F9352C7273": "portemonnee saldo",
    "loan.applyQuota.Cbc1Eb9D639A6105": "Totaal beschikbaar leenbedrag",
    "loan.applyQuota.033A05462195B080": "Geleend bedrag",
    "loan.applyQuota.9Aa89B036Ef4821D": "resterende saldo",
    "loan.applyQuota.5656Fb52Ef17A557": "Kredietlimiet verkrijgen",
    "loan.applyQuota.6Ff6702B4C917D40": "wordt beoordeeld",
    "loan.applyQuota.6Ff6702B4C917D41": "afgewezen",
    "loan.applyQuota.6Ff6702B4C917D42": "geslaagd",
    "loan.applyQuota.0Bad0E857Ea4A952": "toepassingstijd",
    "loan.applyQuota.3E7E56E2De2Cc546": "Aanvraagbedrag",
    "loan.applyQuota.8F1D170D9E54Af5B": "Voortgang van de aanvraag",
    "loan.applyQuota.6167Ca035B93Caf3": "reden",
    "loan.applyQuota.410C7954F9A3981D": "Opnieuw solliciteren",
    "loan.bottomSheet.61Cd62F861D8F5B2": "Selecteer valuta",
    "loan.index.4C10307B22E885C3": "Ontdek meer handelsmogelijkheden en creëer rijkdom",
    "loan.index.Ae8D37Dea2B3717B": "rente",
    "loan.index.1Bb54625682E85Db": "Regel introductie",
    "loan.index.5Bd31Fab681C6E9D": "Bekijk meer",
    "loan.index.111F95F404814327": "Valuta van lening",
    "loan.index.6Bc2723B3Bec106D": "lening",
    "loan.index.3200106280E6E370": "rente",
    "loan.index.049A6Ccb6C81A635": "Leenbedrag (min/max)",
    "loan.productDetails.5037Ad86Ab4B7E02": "lening",
    "loan.productDetails.37181C74559Db182": "Volgorde ",
    "loan.productDetails.5802Aaf06A1E4755": "Liquiditeit",
    "loan.productDetails.89Aea9Cf9337C40F": "Hoeveelheid beschikbaar om te lenen",
    "loan.productDetails.9E419Ae2D06F3Ad9": "Terug te betalen hoeveelheid",
    "loan.productDetails.C65F329Ef14C4A3D": "Aantal geleende munten",
    "loan.productDetails.3A74667815Adc8Bf": "maximaal",
    "loan.productDetails.Ab851D8351573227": "lucht",
    "loan.productDetails.Ddf82D8A2C8F46A0": "terugbetalingscyclus",
    "loan.productDetails.C105E5562005Aeb1": "lucht",
    "loan.productDetails.3F346Be2137F0756": "Leenbedrag (min/max)",
    "loan.productDetails.2707F78Df864E194": "Interesse",
    "loan.productDetails.B9Dc9B81E726Bdf1": "Gelieve op tijd terug te betalen. Als u te laat bent, wordt er een extra dagelijks bedrag (hoofdsom + rente) in rekening gebracht.",
    "loan.productDetails.B9Dc9B81E726Bdf2": "% is achterstallige vergoeding",
    "loan.productDetails.4D94Faa033Be7356": "Ik heb ingestemd",
    "loan.productDetails.62429A957A22780A": "Serviceovereenkomst voor het uitlenen van munten",
    "loan.productDetails.Bc54E76B61C50Fb9": "Lees de overeenkomst en ga ermee akkoord",
    "loan.productDetails.Ec8Ba071C53A67Cb": "Nu lenen",
    "loan.productDetails.59246A0Bf081D398": "minst",
    "loan.productDetails.Ec3F1172B71Cd36C": "minimale hoeveelheid",
    "loan.productDetails.7Ccc4Cf1D81930B3": "grootste bedrag",
    "loan.productDetails.071488598990Aa68": "Lees de overeenkomst en ga ermee akkoord;",
    "loan.productDetails.579Dd216D3339119": "Lening succesvol;",
    "loan.records.4951B36Fd0D2A831": "Lening- en kredietgeschiedenis",
    "loan.records.Bb0Aaa30514B7Cba": "huidig ​​record",
    "loan.records.0F8B0A7D80769051": "Aflossingsrecord",
    "loan.records.D4B42E43D88De6F1": "Historisch record",
    "loan.records.Cb1847D6281897E6": "Leenbedrag",
    "loan.records.C7Bfcacfc2Adea58": "Terugbetalingsbedrag",
    "loan.records.8B369164D5Fea340": "Lening tijd：",
    "loan.records.Ab2402Ad3Ffd35E7": "Lening aflossen",
    "loan.records.B7A0Ca0C195D0Cd1": "Tijd vrijgeven：",
    "loan.records.42014Bdcfd9C411B": "Terugbetalingstijd：",
    "loan.records.A0Acfaee54F06458": "voltooid",
    "loan.records.67A39834582Ad99A": "Geen gegevens",
    "loan.records.6A687Ff5B8F1598F": "Weet u zeker dat u vooraf wilt afbetalen?",
    "loan.records.7C20D7008D0Eae86": "Terugbetaling succesvol",
    "mining.bottomSheet.Ba91637D32Ccba04": "Liquiditeitsmijnbouw",
    "mining.bottomSheet.7De3D636A165E008": "rendement",
    "mining.bottomSheet.F3003345B189D683": "Mijnbouw periode",
    "mining.bottomSheet.3E4C0A8C533F5848": "soort munt",
    "mining.bottomSheet.Adcba368993Ae65E": "Abonnementsbedrag (minimum/maximum)",
    "mining.bottomSheet.94743B24E4Ed4166": "Onbeperkt",
    "mining.bottomSheet.286944Fedd211257": "Beschikbare fondsen",
    "mining.bottomSheet.7B5766Dc65Abc0C3": "Geschatte omzet:",
    "mining.bottomSheet.9Bbaf80Fdb6Fd148": "De mijne nu",
    "mining.bottomSheet.Bb1493C567C114Dd": "Vriendelijke tips：",
    "mining.bottomSheet.D2315E87E34A3Bd3": "Kosten voor vervroegde aflossing",
    "mining.index.8B2Bba1422Eed6F0": "Creëer tientallen miljarden rijkdom, 100% netto-inkomen",
    "mining.index.42997E300086Ee89": "hoogste rendement",
    "mining.index.510F74F42052Ecce": "Mijnbouwproducten",
    "mining.index.B853F96626106912": "Ga mijnen",
    "mining.productList.37181C74559Db182": "Volgorde",
    "mining.productList.Aac5B810004C46C8": "normaal",
    "mining.productList.D2Fb4F78C387D888": "Je hebt met succes gemined",
    "quantify.bottomSheet.9Ea4A318F690Bc95": "Bevestig bestelling",
    "quantify.bottomSheet.6915303B698303B1": "robot",
    "quantify.bottomSheet.68045296Dd0F8Ad6": "Huurbedrag",
    "quantify.bottomSheet.Fbd931286Be387Aa": "fiets",
    "quantify.bottomSheet.9Ad84Db481807844": "voor onbepaalde tijd",
    "quantify.bottomSheet.994D7226Ca083Ea5": "Dagelijks rendement",
    "quantify.bottomSheet.0Bf82Ee4Bfe063B7": "waarde datum",
    "quantify.bottomSheet.88008603Ba7Bde64": "Aankoop succesvol",
    "quantify.bottomSheet.Ff50B3091Bbd09Ab": "Ontvang voortaan een inkomstenaandeel！",
    "quantify.bottomSheet.C778A674C4A054F9": "bestelling controleren",
    "quantify.bottomSheet.685Dad322B7029B0": "Retourtransactie",
    "quantify.buyForm.Acf8560A04334E95": "aankoopprijs",
    "quantify.buyForm.Cf9Cfbc2C554944F": "Overzicht",
    "quantify.buyForm.8788Df2B625E97B5": "robot",
    "quantify.buyForm.50Ca63B45E881D50": "Tijdstip van dividendbetaling",
    "quantify.buyForm.Fcda03Adbc5Fca97": "elke dag",
    "quantify.buyForm.Afb89504Fc399189": "Voer het aankoopaantal in",
    "quantify.buyForm.1435Afdfb4Ec0596": "Minimale aankoophoeveelheid",
    "quantify.buyForm.1B97F1730Bacd839": "Maximale aankoophoeveelheid",
    "quantify.index.32F56C9B7D0E250A": "Periode: onbepaald",
    "quantify.index.E34F028D00904630": "prijs",
    "quantify.index.E34F028D00904631": ",Dagelijks rendement",
    "quantify.productDetail.6Bbe178Df539A5Ca": "Borgbedrag",
    "quantify.productDetail.53635B53Bca31Dfa": "Geschat dagelijks inkomen",
    "quantify.productDetail.F9E46449A6269E09": "Cumulatief inkomen",
    "quantify.productDetail.98Fc46B1B8C993B2": "Hosting bestellen",
    "quantify.productDetail.93624Bb045830966": "Huur nu",
    "quantify.records.0C16A18C32B9Cf4A": "Bestelrecord",
    "quantify.records.B191F5614B0E2312": "Aankoophistorie",
    "quantify.records.6A06033B86A73F7C": "verlossingsrecord",
    "quantify.records.45E0F7E71611618E": "Cumulatief inkomen",
    "quantify.records.45E0F7E71611618F": "Winst op de eindvervaldag",
    "quantify.records.Aae1201E38C4Baaa": "Rennen",
    "flash.index.C41Fd4F2Fdfa594B": "consumeren",
    "flash.index.1Ac59Fa2218E3B00": "Beschikbaar:",
    "flash.index.Bf7233A660821549": "krijgen",
    "flash.index.548E775Dd67C7472": "Bevestig de uitwisseling",
    "flash.index.066A2449Bd616F03": "Voer het inwisselbedrag in",
    "flash.index.Fca26Fcf9C06563F": "Flash-inwisseling succesvol",
    "message.index.40031524233F4564": "Berichten centrum",
    "message.index.8E5586210Fd44D69": "Eeuwigdurende positieherinnering",
    "message.index.Bcd3F470E88C197D": "Nog geen melding",
    "message.index.23A557Fbce545879": "Permanente liquidatiekennisgeving",
    "message.index.B5E19B36B5125550": "Herroepingsherinnering",
    "message.index.716A981465138334": "Herinnering voor opladen",
    "message.index.884474Ebff2977Ba": "systeem informatie",
    "message.index.08Cc7D24752Ec42C": "online dienst",
    "message.index.1D820A6A19Ab025D": "7 X 24 uur online handmatige service",
    "more.index.29881E0B5Df58Bf7": "Meer eigenschappen",
    "more.index.C77Ee35911D10D9D": "Gemeenschappelijke functies",
    "more.index.3558F69B0260Be61": "Handelsfunctie",
    "more.index.7243Fe610Ed98Cec": "echte rekening",
    "more.index.7243Fe610Ed98Ced": "Demo-account",
    "more.index.693C7B35Bdf3E3A3": "financiën",
    "more.index.F855997Fac114554": "Helpcentrum",
    "more.index.Ae9A1252278Da742": "hulp",
    "more.index.Ace012504Bc529E0": "informatie",
    "more.index.05682Dbef5Eeb0A8": "klantenservice",
    "more.index.C7Af227A5D5C7Bb9": "U bent overgeschakeld naar een live-account",
    "more.index.D818A65592694786": "U bent overgestapt naar een demo-account",
    "notice.details.B360807Dbe727E6E": "over ons",
    "notice.details.92F2552F695E70E3": "over ons",
    "notice.details.7Aaceaf69B67F3D1": "Regel introductie",
    "notice.index.6128438Be34Bcb9F": "Eeuwigdurende kennisgeving",
    "notice.index.71627F5Eb7128261": "Kennisgeving van intrekking",
    "notice.index.2Dce570Fda841705": "Oplaadmelding",
    "notice.index.228A7C9C6448A9B1": "systeemmelding",
    "setting.dialog.8B4F5B74660Deabe": "Kleurconfiguratie",
    "setting.dialog.Bfc7D25486Ebc1A9": "Groen stijgt en rood daalt",
    "setting.dialog.796Ea0E1A82C320B": "Rood stijgt en groen daalt",
    "setting.index.8Ddc5864E1739466": "Voorkeuren",
    "setting.index.F5205801D57D6727": "taal",
    "setting.index.2E2C12Dd0Cc1B63B": "Valuta van de offerte",
    "setting.index.322D8496Aec3D918": "versienummer",
    "setting.index.Aa7E6C204C751Cfa": "afmelden",
    "share.index.75Bc38Bbf7D2E766": "nodig vrienden uit",
    "share.index.45575Eb5D42213E6": "mijn uitnodigingscode",
    "share.index.29F67F0644A78Bf3": "kopiëren",
    "share.index.19Cc65Bb6Bb98Fe7": "Mijn uitnodigingslink",
    "share.index.5372712F162Fb78B": "Aantal directe ondergeschikten",
    "share.index.C88D6B95E3Ab7D6F": "Totaal aantal ondergeschikten",
    "share.index.Bb5Fa5471Aeecc62": "Commissiekorting voor directe ondergeschikten",
    "share.index.426B6Bac9A5F3D4F": "totale korting",
    "share.index.197E4A428F0401C9": "Bekijk kortingsrecords",
    "share.records.88A6B2E2B74B13C4": "Commissierecord",
    "share.records.B671A35Ffdc93B89": "Korting",
    "transfer.index.B17617A869C03Ca5": "portemonnee",
    "transfer.index.E4116Dcc6F6C7270": "Overdracht succesvol",
    "transfer.index.48Fb603C6638C7F6": "Stel een fondswachtwoord in",
    "transfer.records.Eb0E39Eb5911D418": "krijgen",
    "transfer.records.977554Be0Aa65052": "Flash-inwisseltijd：",
    "transfer.records.9Aa33884Aa400B7C": "overdrachtstijd：",
    "form1.forget.76636Db8Fae5B124": "uw brievenbus",
    "form1.forget.35354B9Cca1Bb0E6": "E-mailverificatiecode",
    "form1.forget.604A0B11E916F387": "wachtwoord",
    "form1.forget.9Cb1C9C51E7503D6": "bevestig wachtwoord",
    "form1.forget.3A4F7E04B008453C": "Jouw telefoon nummer",
    "form1.forget.Cf11Dce5B4713B5A": "Verificatiecode voor mobiele telefoon",
    "form1.forget.3012Edba57D4F297": "Vul alstublieft uw e-mailadres in",
    "form1.forget.F1Ee1D97E869E447": "voer de verificatiecode in",
    "form1.forget.3A5C4C9Ae68Fa0F6": "Voer uw wachtwoord in",
    "form1.forget.C6Cb174Da411D5F7": "Voer uw mobiele telefoon in",
    "form1.forget.947B3B21Ee45E582": "versturen",
    "form1.forget.3E0580F1E8630Df6": "Twee wachtwoorden zijn inconsistent",
    "form1.forget.C5A61836B8Cc74C6": "Wachtwoordwijziging succesvol doorgestuurd...",
    "form1.forget.B96D072Bc8962565": "Opnieuw versturen",
    "form1.login.2B72C27Ff828Cfc4": "je wachtwoord",
    "form1.login.D7C6B872Af9Cb17F": "onthoud mijn wachtwoord",
    "form1.login.0Fff44C827A4F3B6": "Log in",
    "form1.login.1B7F2C1Ede3990Cc": "Geen account? Meld u nu aan",
    "form1.login.F657F7Be1Cc33Bd2": "vergeet het wachtwoord",
    "form1.login.2074A1Eec4B088B0": "Wallet-login",
    "form1.login.1B8938Fbcc3C9B56": "Inloggen succesvol en doorverwijzing...",
    "form1.login.07162A7Bb9310555": "Installeer de ERC20-portemonnee",
    "form1.login.766969966Ae151Dc": "Installeer de TRC20-portemonnee",
    "form1.login.B88171E1Ad4C50Bf": "Je hebt geweigerd van netwerk te wisselen",
    "form1.register.Aaef0710Cb4Db554": "Vul het wachtwoord opnieuw in",
    "form1.register.54Dfea0415C708Ff": "Uitnodigingscode",
    "form1.register.6839940Dac08C794": "register",
    "form1.register.9901A3B5550Aa58E": "Heeft u al een account? Log in",
    "form1.register.2548Ca53785252Ea": "Voer uw uitnodigingscode in",
    "form1.register.Dd71Fd65900Dd03E": "Lees de Registratieserviceovereenkomst en ga ermee akkoord,",
    "form1.register.7975613909490B77": "Registratie succesvol en doorverwijzing...",
    "form1.walletDialog.105829Acfc0Bd6Aa": "Kies portemonnee",
    "userc.certification.2Af9511640153D2E": "Junior-certificering",
    "userc.certification.177B191D8E0Fa84D": "Niet certificaat",
    "userc.certification.601218D4D572E98A": "geverifieerd",
    "userc.certification.6E37C51Ba9012Fc8": "Is niet geslaagt",
    "userc.certification.1B9B563E05C96474": "Senior certificering",
    "userc.certification.1418B83803Ab56B4": "Voltooi eerst de primaire certificering",
    "userc.creditScore.0B149112601Acc4D": "kredietwaardigheid",
    "userc.creditScore.88Ac7F84E106C5F6": "totale kredietscore",
    "userc.creditScore.3E1D6E0E7F1E6155": "Systeemevaluatie",
    "userc.index.62Eac58D04Bbca0F": "Persoonlijk centrum",
    "userc.index.F4Cac53F93Cb2Aff": "gesimuleerde handel",
    "userc.index.42F409Fdf3559773": "Beveiligings centrum",
    "userc.index.1B8E211009807Db8": "Authenticatie",
    "userc.index.Bdfcf21Eb8F2Eb70": "Accountwijzigingsrecords",
    "userc.index.6C18D67F6D19586B": "Platformintroductie",
    "userc.index.807A814Ed58A93Bc": "Cache wissen",
    "userc.index.E43Daad59759611C": "Niet certificaat",
    "userc.secureCenter.F5C70F0Db2F1Ba76": "Wijzig het inlogwachtwoord",
    "userc.secureCenter.1F37Ba427Debf24A": "Accountbinding",
    "userc.secureCenter.F9A5Ffa750664004": "Bind Google Authenticator",
    "userb.certificationForm.7685Af7D30043Cd6": "Junior-certificering",
    "userb.certificationForm.7685Af7D30043Cd7": "Senior certificering",
    "userb.certificationForm.70E37C35Abc3F462": "Land",
    "userb.certificationForm.F92C25A68E4D08C5": "Stad",
    "userb.certificationForm.0Ccc6387B38E0318": "Uw naam",
    "userb.certificationForm.94C67Bba370Fdd46": "licentienummer",
    "userb.certificationForm.B2D4C7Fffe79258E": "Mail",
    "userb.certificationForm.57554268761Fb47A": "Upload de voorkant van uw identiteitsbewijs",
    "userb.certificationForm.D79E90201F5319Bd": "Upload de achterkant van uw identiteitsbewijs",
    "userb.certificationForm.9C6Bad08Af29E20E": "Upload een foto van uw identiteitskaart",
    "userb.certificationForm.F96B62Dfa31Cde45": "Bevestig de indiening",
    "userb.certificationForm.2125D78Ea8C6D287": "Uploadvereisten",
    "userb.certificationForm.0D64864D3076A824": "Bekijk voorbeeld",
    "userb.certificationForm.35287Deb72281E87": "1.Moet de identiteitskaartinformatie duidelijk kunnen lezen.",
    "userb.certificationForm.3007F2E01Efc7B57": "2.De inhoud van de foto is waarheidsgetrouw en geldig en er zijn geen wijzigingen toegestaan.",
    "userb.certificationForm.25A90660554319F9": "3.Het ID-nummer en de naam moeten duidelijk zichtbaar zijn, ondersteunen JPG / JPEG / PNG",
    "userb.certificationForm.5A9Aefbc03C778F7": "kom binnen alstublieft",
    "userb.certificationForm.3B7A1D8Ce27C937E": "Inzending succesvol, wacht op beoordeling",
    "userb.certificationForm.744Bd07D8Abd2513": "Upload de voorkant van uw identiteitsbewijs",
    "userb.certificationForm.6De1C319130F7Fef": "Upload de achterkant van uw identiteitsbewijs",
    "userb.certificationForm.79F8E8B172E30C8A": "Upload een foto van uw identiteitskaart",
    "userb.certificationForm.46412E16D28A4753": "Foto-upload mislukt",
    "userb.changeDialog.676B0Adca1F8Eea4": "verander wachtwoord",
    "userb.modifyInfoDialog.F41De3A1Fa69135A": "telefoonnummer",
    "userb.modifyInfoDialog.3C3Aac9664A35Ef8": "validator schakelaar",
    "userb.modifyInfoDialog.62149E566814154C": "Selecteer validatie",
    "userb.modifyInfoDialog.8E69468E2435Fc89": "Open de Authenticatie-app en tik op +",
    "userb.modifyInfoDialog.7625E5Bdcbfe9963": "Selecteer 'QR-code scannen' of 'Sleutel invoeren'",
    "userb.modifyInfoDialog.93F094E2B4369472": "Scan de QR-code of voer de sleutel in",
    "userb.modifyInfoDialog.43E4F1532529Ee1E": "dicht open",
    "userb.modifyInfoDialog.Bae6358Dfd95025A": "Oud fondswachtwoord",
    "userb.modifyInfoDialog.E1133Cd4B1C2Abb7": "Nieuw fondswachtwoord",
    "userb.modifyInfoDialog.C1Ce0403A2523Ee0": "Bevestig het nieuwe fondswachtwoord",
    "userb.modifyInfoDialog.8Dd453Adc2Cd78Fb": "Bevestig het wachtwoord van het fonds",
    "userb.modifyInfoDialog.Bb0D9854C79325Bb": "Voer uw oude fondswachtwoord in",
    "userb.modifyInfoDialog.E66Cb29B00Bc54D1": "Voer uw nieuwe fondswachtwoord in",
    "userb.modifyInfoDialog.E8Ae2Ec5Df401255": "Voer uw fondswachtwoord in",
    "userb.modifyInfoDialog.258A534498Fe984F": "Bevestig uw fondswachtwoord",
    "userb.modifyInfoDialog.768Ec43Faa193F08": "Accountbinding",
    "userb.modifyInfoDialog.D34746090C25E5Db": "Wachtwoord van het fonds",
    "userb.modifyInfoDialog.E4F27D8916E5E536": "bevestigen",
    "userb.modifyInfoDialog.Bef7160Ce53773E0": "Validator is gesloten",
    "userb.modifyInfoDialog.Be8Bf47Ba6820De1": "Google-verificatie is succesvol ingeschakeld",
    "userb.modifyInfoDialog.24Dba26593F43C74": "Fondswachtwoord succesvol gewijzigd",
    "userb.modifyInfoDialog.5C369D42Dc7Dbe76": "Stel het fondswachtwoord succesvol in",
    "userb.modifyInfoDialog.667Bf8D0B6C36D4C": "Binden succesvol",
    "userb.modifyInfoDialog.1C5E05Efee2B0B9F": "Fout in verificatiecode",
    "userb.veBottomSheet.Eecc4683C725F6F2": "Voorbeeld van certificaatupload",
    "userb.veBottomSheet.6F38A0D5858633Ee": "donkere schone achtergrond",
    "userb.veBottomSheet.Af162Dbdfc4Fcae9": "Voldoende verlichting",
    "userb.veBottomSheet.0F7Baf0Cee3C8141": "Uitlijning hulpframe",
    "userb.veBottomSheet.Ac9E471Eead0Cf11": "Foto's zijn duidelijk",
    "userb.veBottomSheet.50B741382E71F103": "Mogelijke redenen voor mislukking",
    "userb.veBottomSheet.Aa56C68E8Fd4Cfb4": "De beschermhoes voor de ID-kaart is niet verwijderd",
    "userb.veBottomSheet.5D2F429C75D4C7F2": "De achtergrond wordt beïnvloed door rommel",
    "userb.veBottomSheet.90F42D3Cb7769Dbc": "De identiteitskaart is reflecterend",
    "userb.veBottomSheet.1Ee78Cee782D81B4": "Het licht is te donker/gehinderd door de schaduw van de mobiele telefoon",
    "userb.veBottomSheet.1A75D2Fc2614592B": "identiteitskaart vasthouden",
    "home.home.C3525Eb110Db58Aa": "Meer",
    "home.home.29761532Fc8Ebecc": "handel",
    "home.home.F15Ea99Bfa6E1848": "Veiligheidscertificering, koop met vertrouwen",
    "home.home.46E1Ab39Bae7182A": "Snel",
    "home.home.Fde2Eb7F6F3Dd800": "Snelle transacties en eenvoudige bediening",
    "home.home.Ea4756Bc1642E0F1": "naam",
    "home.home.B6B1127Ede1C97B4": "laatste prijs",
    "home.home.3587Ad870Eb0Ab86": "24u handel",
    "home.home.Cb1A4E14Ec2Cd2B5": "Ontdekken",
    "home.home.0F1B1Ac0E96A40D1": "Blockchain",
    "home.home.8Eaefb1107A0E210": "Forex",
    "home.home.Ad65263D034Ea051": "landbouw",
    "home.home.5Fc1474Dbf74Ac1A": "landbouw producten",
    "home.home.135318F4664F7773": "energie",
    "kline.kLine.C96Ff250Cf6B8B84": "24H Hoogste",
    "kline.kLine.6E8Be64F19D74565": "24H Omzet",
    "kline.kLine.08A4Bfbe26A0F262": "24H Laagste",
    "kline.kLine.8701Deb075066331": "24H Omzet (USDT)",
    "kline.kLine.Ea3C3052136C84Cd": "1 punt",
    "kline.kLine.B49Cca240E013124": "5 punten",
    "kline.kLine.F27A90Be97Ba3D6C": "15 punten",
    "kline.kLine.Ff3E62395B694384": "30 punten",
    "kline.kLine.C1993F9C6Db1A674": "1 uur",
    "kline.kLine.Fd2Cbbae405E284C": "1 dag",
    "kline.kLine.C9Cd71904395042C": "Januari",
    "kline.kLine.7D8127Ec18C7B8C7": "Kopen",
    "kline.kLine.E3D3Ffdb9B3E5636": "Aantal (stuks)",
    "kline.kLine.6834Fec1838D8029": "Prijs (USDT)",
    "kline.kLine.16C52Aae474Bc9Cd": "verkoop bestelling",
    "kline.kLine.B9Bea1A9A88D1419": "Kopen",
    "kline.kLine.D36Cfa96Ee2B343B": "Verkopen",
    "other.languageRateSelect.6897784800834C1B": "taalinstelling",
    "other.languageRateSelect.6897784800834C1C": "Selecteer de prijsvaluta",
    "other.navigationBar.F6E62F9642018Cbb": "Home",
    "other.navigationBar.0Ec227161A47B49D": "Citaten",
    "other.navigationBar.29761532Fc8Ebecc": "handel",
    "other.navigationBar.693C7B35Bdf3E3A3": "financiën",
    "other.navigationBar.9734C54620Eb09Af": "activa",

    "home.index.25Aa4Fc745150030": "The King of Cryptocurrency",
    "home.index.2D6Ecf8Faaa8B124": "Faster, better, stronger",
    "home.index.1Db26267D1041223": "Easily trade over 1700 cryptocurrencies",
    "home.index.F8E18C90C3E35618": "Register now",
    "home.index.29761532Fc8Ebecc": "Transaction",
    "home.index.D8A95E4506487452": "Popular Contracts",
    "home.index.5Bd31Fab681C6E9D": "View more",
    "home.index.010Ae07A09Caf221": "Trading pair",
    "home.index.F4D875118E6D6Dbb": "Latest transaction price",
    "home.index.3Dfdfb3D6599100E": "24-hour increase or decrease",
    "home.index.28Fb39Bd41E54242": "Trend Chart",
    "home.index.B47A3911Bacd94C6": "Growth List",
    "home.index.712910D574F43D95": "Stable income",
    "home.index.5507Fd830D7Bf51F": "Top strategy, easy to copy",
    "home.index.7De3D636A165E008": "yield",
    "home.index.B853F96626106912": "Go mining",
    "home.index.213B5C96Be7Cd1F8": "Days, annualized interest rate",
    "home.index.213B5C96Be7Cd1F9": "Maximum purchase",
    "home.index.Dc710Cffe6313Bb5": "View details",
    "home.index.B92Ffc837Ecaa1Dd": "News",
    "home.index.6C18D67F6D19586B": "Platform introduction",
    "home.index.6342E3C33Cb48Cd5": "Digital currency is a medium of exchange that uses cryptographic principles to ensure transaction security. Unlike fiat currencies such as the U.S. dollar, digital currencies do not have a physical form. Bitcoin (BTC), Ethereum (ETH) and Polkadot Popular digital currencies such as (DOT) use blockchain as the underlying technology and act as a decentralized digital ledger. All digital currency transactions are recorded in the blockchain and cannot be changed once confirmed and verified. Unlike traditional currencies, which are maintained by banks Unlike centralized ledgers, digital currency transactions occur on a public blockchain and can be accessed by anyone.",
    "home.index.198Efc4254683Eef": "In addition, according to the consensus mechanism, everyone can verify digital currency transactions and add them to the blockchain, thereby achieving decentralization. With Samsung, BlackRock, Morgan Stanley Leading global companies such as Alphabet and Alphabet have invested in it, and people believe that blockchain technology will completely change the global financial system. Digital currency opens the door to the world of decentralized finance for you, allowing you to explore the infinite possibilities brought by cutting-edge technology .",
    "home.index.E710339D9576292E": "Our advantages",
    "home.index.F9Ce8B2C63885B26": "Safe and stable",
    "home.index.C4D0A2C9Ff0305Ce": "Top technical team, comprehensive security protection, independent research and development of high-speed transaction synthesis engine, which can still operate stably and reliably under massive concurrent transactions.",
    "home.index.2E8708Ee3F11Cc1A": "Professional and reliable",
    "home.index.Bb12Cbcf89C56C5C": "Professional operation team, many years of experience in blockchain and financial industry, holding a compliant digital asset trading license, and 100% reserve guarantee.",
    "home.index.Aa4723Bfbf6808F4": "Service with heart",
    "home.index.1Bf0204B7D3Ee230": "Facing the global market, supporting multiple languages, running 24/7, strong community support, and professional customer service.",
    "home.index.2E7Dfd53D26F7224": "High performance",
    "home.index.1C65453103B5Fcc3": "300,000 transactions per second, order response time is less than 1 millisecond.",
    "home.index.85Ac49Ece9Dcc80B": "Trade anytime, anywhere!",
    "home.index.2799E18842E4Ebe9": "Instant position opening, supported by OKEX App and web page!",
    "home.index.2Bf52Ce75E29Fc88": "Scan the code to download now",
    "home.index.88D1422B33571066": "Android",
    "home.index.04Ee893775563F21": "Apple",
    "home.index.04Ee893775563F22": "Products and Services",
    "home.index.04Ee893775563F23": "Financial Management",
    "home.index.04Ee893775563F26": "IOS & ANDROID",
}
