import axios from "axios";
import baseUrls,{urlMode} from "@/config/baseUrls";
async function pingUrl(url) {
  try {
    await axios.head(url,{
      timeout:5000
    });//ping
    return url;  // 若返回url，表示 URL 可访问
  } catch (error) {
    return false; // 若返回 false，表示 URL 无法访问
  }
}
async function beforeInit() {
  if(process.env.NODE_ENV === 'production' && urlMode == 'list') {
    for(let index in baseUrls) {
        let url = await pingUrl(baseUrls[index])
        if(url){
            window.baseURL = url;
            break;
        }
    }
  }
}

export default beforeInit;
