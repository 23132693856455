// import service from '@/config/fetch'
const version = '/api/app'
import service from "../config/fetch"
import {getCookie} from "@/utils/cookie"
export default {
    basisSlideFetch: async (ctx, data) => {
        return service({
            url: `${version}/basis/slide`,
            method: 'post',
            data
        })
    },
    // https://api.tldrus.com/api/app/language
    //语言
    languageFetch: async (ctx, data) => {
        return service({
            url: `${version}/language`,
            method: 'post',
            data
        })
    },
    //获取文章单篇
    basisArticleFetch: async (ctx, data) => {
        return service({
            url: `${version}/basis/article`,
            method: 'post',
            data
        })
    },
    quotesFetch: async (ctx, data) => {
        return service({
            url: `${version}/quotes/sort`,
            method: 'post',
            data
        })
    },
    // 公告 有详情
    basisArticleDetailListFetch: async (ctx, data) => {
        return service({
            url: `${version}/basis/article/detail/list`,
            method: 'post',
            data
        })
    },
    // 配置
    configFetch: async (ctx, data) => {
        return service({
            url: `${version}/config`,
            method: 'post',
            data
        })
    },
    // 根据ids获取最新行情
    quotesGetNewListFetch: async (ctx, data) => {
        return service({
            url: `${version}/quotes/get/new/list`,
            method: 'post',
            data
        })
    },
    // 历史行情
    quotesHistoryFetch: async (ctx, data) => {
        return service({
            url: `${version}/quotes/history`,
            method: 'post',
            data
        })
    },
    // 汇率
    fundsUSDTLegalTenderFetch: async (ctx, data) => {
        if (ctx.state.rateList.length > 0) {
            return {
                errCode: 0,
                data: ctx.state.rateList
            }
        }
        let response = await service({
            url: `${version}/funds/usdt/legal/tender`,
            method: 'post',
            data
        })
        if (response.errCode == 0) {
            ctx.commit('setRateList', response.data)
        }
        return response
    },
    // 交易对接口
    symbolListFetch: async (ctx, data) => {
        if (ctx.state.symbolList.length > 0) {
            return {
                errCode: 0,
                data: ctx.state.symbolList
            }
        }
        let response = await service({
            url: `${version}/symbol/list`,
            method: 'post',
            data
        })
        if (response.errCode == 0) {
            ctx.commit('setSymbolList', response.data)
        }
        return response
    },
    // symbol type
    symbolTypeFetch: async (ctx, data) => {
        return service({
            url: `${version}/symbol/type`,
            method: 'get',
            params: data
        })
    },
    //根据id 获取最新行情
    quotesGetNewIdFetch: async (ctx, data) => {
        return service({
            url: `${version}/quotes/get/new/id`,
            method: 'post',
            data
        })
    },
    // https://api.tldrus.com/api/app/user/login
    userLoginFetch: async (ctx, data) => {
        return service({
            url: `${version}/user/login`,
            method: 'post',
            data
        })
    },
    //资金汇总
    fundsOverviewFetch: async (ctx, data) => {
        return service({
            url: `${version}/funds/overview`,
            method: 'post',
            data
        })
    },
    // https://api.tldrus.com/api/app/spot/varieties
    spotVarietiesFetch: async (ctx, data) => {
        return service({
            url: `${version}/spot/varieties`,
            method: 'get'
        })
    },
    // https://api.tldrus.com/api/app/spot/order
    spotOrderFetch: async (ctx, data) => {
        return service({
            url: `${version}/spot/order`,
            method: 'post',
            data
        })
    },
    // https://api.tldrus.com/api/app/spot/submit
    spotSubmitFetch: async (ctx, data) => {
        return service({
            url: `${version}/spot/submit`,
            method: 'post',
            data,
        })
    },
    // https://api.tldrus.com/api/app/funds/rec
    fundsRecFetch: async (ctx, data) => {
        return service({
            url: `${version}/funds/rec`,
            method: 'post',
            data,
            headers: {
                ...data.head
            }
        })
    },
    // https://api.tldrus.com/api/app/funds/type
    fundsTypeFetch: async (ctx, data) => {
        return service({
            url: `${version}/funds/type`,
            method: 'post',
            data
        })
    },
    // https://api.tldrus.com/api/app/funds/wallet/money
    fundsWalletMoneyFetch: async (ctx, data) => {
        return service({
            url: `${version}/funds/wallet/money`,
            method: 'post',
            data
        })
    },
    // https://api.tldrus.com/api/app/conversion/config
    conversionConfigFetch: async (ctx, data) => {
        return service({
            url: `${version}/conversion/config`,
            method: 'post',
            data
        })
    },
    // https://api.tldrus.com/api/app/funds/wallet/type
    fundsWalletTypeFetch: async (ctx, data) => {
        return service({
            url: `${version}/funds/wallet/type`,
            method: 'post',
            data
        })
    },
    // https://api.tldrus.com/api/app/conversion/submit
    conversionSubmitFetch: async (ctx, data) => {
        return service({
            url: `${version}/conversion/submit`,
            method: 'post',
            data,
            // headers: {
            //     "APP-ANALOG": getCookie('account-mode')
            // }
        })
    },
    // https://api.tldrus.com/api/app/conversion/rec
    conversionRecFetch: async (ctx, data) => {
        return service({
            url: `${version}/conversion/rec`,
            method: 'post',
            data,
            headers: {
                ...data.head
            }
        })
    },
    // https://api.tldrus.com/api/app/demo/reset/assets
    demoResetAssetsFetch: async (ctx, data) => {
        return service({
            url: `${version}/demo/reset/assets`,
            method: 'post',
            data
        })
    },
    // https://api.tldrus.com/api/app/second/product
    secondProductFetch: async (ctx, data) => {
        return service({
            url: `${version}/second/product`,
            method: 'get',
            params: data
        })
    },
    // https://api.tldrus.com/api/app/second/product/period
    secondProductPeriodFetch: async (ctx, data) => {
        return service({
            url: `${version}/second/product/period`,
            method: 'post',
            data
        })
    },
    // https://api.tldrus.com/api/app/second/now/period
    secondNowPeriodFetch: async (ctx, data) => {
        return service({
            url: `${version}/second/now/period`,
            method: 'post',
            data
        })
    },

    // secondOrderListFetch: async (ctx, data) => {
    //     return service({
    //         url: `${version}/second/orders`,
    //         method: 'post',
    //         data,
    //         headers: {
    //             "SET-LANGUAGE": getCookie("lang"),
    //             "APP-LOGIN-TOKEN": getCookie('user-cookie'),
    //             "APP-ANALOG": getCookie('account-mode')
    //         }
    //     })
    // },
    secondOrderListFetch: async (ctx, data) => {
        return service({
            url: `${version}/second/order/list`,
            method: 'post',
            data
        })
    },
    // https://api.tldrus.com/api/app/second/order/submit
    secondOrderSubmitFetch: async (ctx, data) => {
        return service({
            url: `${version}/second/order/submit`,
            method: 'post',
            data
        })
    },
    // https://api.tldrus.com/api/app/second/order/submit/by/time
    secondOrderSubmitByTimeFetch: async (ctx, data) => {
        return service({
            url: `${version}/second/order/submit/by/time`,
            method: 'post',
            data
        })
    },
    // https://api.tldrus.com/api/app/now
    nowFetch: async (ctx, data) => {
        return service({
            url: `${version}/now`,
            method: 'post',
            data
        })
    },
    // https://api.tldrus.com/api/app/second/order/cancel
    secondOrderCancelFetch: async (ctx, data) => {
        return service({
            url: `${version}/second/order/cancel`,
            method: 'post',
            data
        })
    },
    // 合约产品列表
    perpetualVarietiesFetch: async (ctx, data) => {
        return service({
            url: `${version}/perpetual/varieties`,
            method: 'get'
        })
    },
    // https://api.tldrus.com/api/app/perpetual/config
    //杠杠配置
    perpetualConfigFetch: async (ctx, data) => {
        return service({
            url: `${version}/perpetual/config`,
            method: 'get',
        })
    },
    // https://api.tldrus.com/api/app/quotes/depth
    //深度
    quotesDepthFetch: async (ctx, data) => {
        return service({
            url: `${version}/quotes/depth`,
            method: 'post',
            data,
            
        })
    },
    //用户权益
    perpetualUserInfoFetch: async (ctx, data) => {
        return service({
            url: `${version}/perpetual/user/perpetual/info`,
            method: 'get',
        })
    },
    // 永续下单
    perpetualOrderSubmitFetch: async (ctx, data) => {
        return service({
            url: `${version}/perpetual/order/submit`,
            method: 'post',
            data,
        })
    },
    //持仓记录
    perpetualPositionFetch: async (ctx, data) => {
        return service({
            url: `${version}/perpetual/position`,
            method: 'get',
        })
    },
    //委托记录
    perpetualPlanFetch: async (ctx, data) => {
        return service({
            url: `${version}/perpetual/plan`,
            method: 'post',
            data,
        })
    },
    // https://api.tldrus.com/api/app/perpetual/change/position/stop/win/lose
    perpetualChangeFetch: async (ctx, data) => {
        return service({
            url: `${version}/perpetual/change/position/stop/win/lose`,
            method: 'post',
            data,
        })
    },
    //cancel
    perpetualCancelFetch: async (ctx, data) => {
        return service({
            url: `${version}/perpetual/plan/cancel`,
            method: 'post',
            data,
        })
    },
    //历史订单
    perpetualOrderFetch: async (ctx, data) => {
        return service({
            url: `${version}/perpetual/order`,
            method: 'post',
            data,
        })
    },
    // 充值通道
    rechargePaymentFetch: async (ctx, data) => {
        return service({
            url: `${version}/recharge/payment`,
            method: 'post',
            data,
            
        })
    },
    // 充值通道对应的上分地址
    rechargePaymentInfoFetch: async (ctx, data) => {
        return service({
            url: `${version}/recharge/payment/info`,
            method: 'post',
            data,
            
        })
    },
    // 提交充值
    rechargePaymentSubmitFetch: async (ctx, data) => {
        return service({
            url: `${version}/recharge/payment/submit`,
            method: 'post',
            data,
            
        })
    },
    // https://api.tldrus.com/api/app/upload/s3/pre-signed
    //图片签名
    uploadSignedFetch: async (ctx, data) => {
        return service({
            url: `${version}/upload/s3/pre-signed`,
            method: 'post',
            data,
            
        })
    },
    // 充值记录
    rechargePaymentRecFetch: async (ctx, data) => {
        return service({
            url: `${version}/recharge/payment/rec`,
            method: 'post',
            data,
            
        })
    },
    //提现地址
    withdrawAddressCoinFetch: async (ctx, data) => {
        return service({
            url: `${version}/withdraw/address/coin`,
            method: 'post',
            data,
            
        })
    },
    //添加地址
    withdrawAddressAddFetch: async (ctx, data) => {
        return service({
            url: `${version}/withdraw/address/add`,
            method: 'post',
            data,
            
        })
    },
    // 提现
    withdrawSubmitFetch: async (ctx, data) => {
        return service({
            url: `${version}/withdraw/submit`,
            method: 'post',
            data,
            
        })
    },
    // 提现记录
    withdrawRecFetch: async (ctx, data) => {
        return service({
            url: `${version}/withdraw/rec`,
            method: 'post',
            data,
            
        })
    },
    // 文章列表
    basisArticleListFetch: async (ctx, data) => {
        return service({
            url: `${version}/basis/article/list`,
            method: 'post',
            data,
            
        })
    },
    // //文章详情
    // basisArticleDetailListFetch: async (ctx, data) => {
    //     return service({
    //         url: `${version}/basis/article/detail/list`,
    //         method: 'post',
    //         data,
    //         headers: {
    //             "SET-LANGUAGE": getCookie("lang")
    //         }
    //     })
    // },
    //根据id获取文章详情
    basisArticleDetailFetch: async (ctx, data) => {
        return service({
            url: `${version}/basis/article/detail`,
            method: 'post',
            data,
            
        })
    },
    // 区号国家
    userPhoneCountryFetch: async (ctx, data) => {
        return service({
            url: `${version}/user/phone/country`,
            method: 'post',
            data,
            
        })
    },
    // 注册
    userRegisterFetch: async (ctx, data) => {
        return service({
            url: `${version}/user/register`,
            method: 'post',
            data,
            
        })
    },
    // https://api.tldrus.com/api/app/user/send-email-code
    userEmailCodeFetch: async (ctx, data) => {
        return service({
            url: `${version}/user/send-email-code`,
            method: 'post',
            data,
            
        })
    },
    // https://api.tldrus.com/api/app/user/send-phone-code
    userPhoneCodeFetch: async (ctx, data) => {
        return service({
            url: `${version}/user/send-phone-code`,
            method: 'post',
            data,
            
        })
    },
    // https://api.tldrus.com/api/app/user/find-password
    userFindPasswordFetch: async (ctx, data) => {
        return service({
            url: `${version}/user/find-password`,
            method: 'post',
            data,
            
        })
    },
    //挖矿产品
    miningProductFetch: async (ctx, data) => {
        return service({
            url: `${version}/mining/product`,
            method: 'post',
            data,
            
        })
    },
    // 挖矿提交
    miningOrderSubmitFetch: async (ctx, data) => {
        return service({
            url: `${version}/mining/order/submit`,
            method: 'post',
            data,
            
        })
    },
    //挖矿订单
    miningOrderFetch: async (ctx, data) => {
        return service({
            url: `${version}/mining/order`,
            method: 'post',
            data,
            
        })
    },
    //提前赎回
    miningOrderRedemptionFetch: async (ctx, data) => {
        return service({
            url: `${version}/mining/order/early-redemption`,
            method: 'post',
            data,
            
        })
    },
    //挖矿收益
    miningIncomeFetch: async (ctx, data) => {
        return service({
            url: `${version}/mining/income`,
            method: 'post',
            data,
            
        })
    },
    //用户信息
    userInfoFetch: async (ctx, data) => {
        return service({
            url: `${version}/user/get/info`,
            method: 'post',
            data,
            
        })
    },
    //认证信息
    userCertificationFetch: async (ctx, data) => {
        return service({
            url: `${version}/user/certification/status`,
            method: 'post',
            data,
            
        })
    },
    //获取消息
    chatRecordFetch: async (ctx, data) => {
        return service({
            url: `${version}/chat/record`,
            method: 'post',
            data,
            
        })
    },
    //最后一条消息
    chatLatestRecordIdFetch: async (ctx, data) => {
        return service({
            url: `${version}/chat/latest/record/id`,
            method: 'post',
            data,
            
        })
    },
    //问候语
    chatWelcomeMessageFetch: async (ctx, data) => {
        return service({
            url: `${version}/chat/welcome_message`,
            method: 'post',
            data,
            
        })
    },
    //发送消息
    chatSendFetch: async (ctx, data) => {
        return service({
            url: `${version}/chat/send`,
            method: 'post',
            data,
            
        })
    },
    //修改头像
    userUpdateAvatarFetch: async (ctx, data) => {
        return service({
            url: `${version}/user/update/header/img`,
            method: 'post',
            data,
            
        })
    },
    //修改昵称
    userUpdateNickNameFetch: async (ctx, data) => {
        return service({
            url: `${version}/user/update/nick/name`,
            method: 'post',
            data,
            
        })
    },
    //修改密码
    userChangePasswordFetch: async (ctx, data) => {
        return service({
            url: `${version}/user/change-password`,
            method: 'post',
            data,
            
        })
    },
    //修改邮箱
    userChangeEmailFetch: async (ctx, data) => {
        return service({
            url: `${version}/user/change-email`,
            method: 'post',
            data,
            
        })
    },
    //修改手机
    userChangePhoneFetch: async (ctx, data) => {
        return service({
            url: `${version}/user/change-phone`,
            method: 'post',
            data,
            
        })
    },
    //初级验证
    userCertificationOrdinaryFetch: async (ctx, data) => {
        return service({
            url: `${version}/user/certification/ordinary`,
            method: 'post',
            data,
            
        })
    },
    //高级验证
    userCertificationAdvancedFetch: async (ctx, data) => {
        return service({
            url: `${version}/user/certification/advanced`,
            method: 'post',
            data,
            
        })
    },
    //谷歌验证
    userGoogleAuthFetch: async (ctx, data) => {
        return service({
            url: `${version}/user/google/auth`,
            method: 'post',
            data,
            
        })
    },
    //开启关闭谷歌
    userGoogleAuthOpenFetch: async (ctx, data) => {
        return service({
            url: `${version}/user/google/auth/open`,
            method: 'post',
            data,
            
        })
    },
    //邀请
    userShareFetch: async (ctx, data) => {
        return service({
            url: `${version}/user/share`,
            method: 'post',
            data,
            
        })
    },
    //用户等级
    rankUserFetch: async (ctx, data) => {
        return service({
            url: `${version}/rank/user`,
            method: 'get',
            
        })
    },
    //用户等级
    rankTeamFetch: async (ctx, data) => {
        return service({
            url: `${version}/rank/team`,
            method: 'get',
            
        })
    },
    //发起跟单
    secondShareAddFetch: async (ctx, data) => {
        return service({
            url: `${version}/second/share/add`,
            method: 'post',
            data,
            
        })
    },
    //邀请跟单
    secondShareUserAddFetch: async (ctx, data) => {
        return service({
            url: `${version}/second/share/user/add`,
            method: 'post',
            data,
            
        })
    },
    //受邀记录
    secondShareUserListFetch: async (ctx, data) => {
        return service({
            url: `${version}/second/share/user/list`,
            method: 'post',
            data,
            
        })
    },
    //确认跟单
    secondShareUserFollowFetch: async (ctx, data) => {
        return service({
            url: `${version}/second/share/user/follow`,
            method: 'post',
            data,
            
        })
    },
    //跟单记录
    secondShareListFetch: async (ctx, data) => {
        return service({
            url: `${version}/second/share/list`,
            method: 'post',
            data,
            
        })
    },
    //增加保证金
    guaranteeAddFetch: async (ctx, data) => {
        return service({
            url: `${version}/perpetual/change/position/add/guarantee`,
            method: 'post',
            data,
            
        })
    },
    //
    secondNowPeriodProfitFetch: async (ctx, data) => {
        return service({
            url: `${version}/second/now/period/profit`,
            method: 'post',
            data,
            
        })
    },

    //资金密码状态
    userWithdrawalPasswordStatusFetch: async (ctx, data) => {
        return service({
            url: `${version}/user/withdrawal/password/status`,
            method: 'post',
            data
        })
    },
    //修改资金密码
    userUpdateWithdrawalPasswordFetch: async (ctx, data) => {
        return service({
            url: `${version}/user/update/withdrawal/password`,
            method: 'post',
            data
        })
    },
    //设置资金密码
    userSetWithdrawalPasswordFetch: async (ctx, data) => {
        return service({
            url: `${version}/user/set/withdrawal/password`,
            method: 'post',
            data
        })
    },
    //验证验证码
    checkCodeFetch: async (ctx, data) => {
        return service({
            url: `${version}/user/check-code`,
            method: 'post',
            data
        })
    },
    //图片验证码
    sendImageCodeFetch: async (ctx, data) => {
        return service({
            url: `${version}/user/send-image-code`,
            method: 'post',
            data
        })
    }
}