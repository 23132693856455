export default {
    getIsLogin: (state) => state.isLogin,
    getConfigData: (state) => state.configData,
    getBaseQuotes: (state) => state.baseQuotes,
    getUpdateQuotes: (state) => state.updateQuotes,
    getInnerHeight: (state) => state.innerHeight,
    getSymbolList: (state) => state.symbolList,
    getIsShowBalance: (state) => state.showBalance,
    getInvestData: (state) => state.investData,
    getUserInfo: (state) => state.userInfo,
    getLanguage: (state) => state.language,
    getLanguageList: (state) => state.languageList,
    getRateList: (state) => state.rateList,
    getCurrentRate: (state) => state.currentRate,
    getAuthInfo: (state) => state.authInfo,
    getSelectSymbolQuote: (state) => state.selectSymbolQuote,
    getSelectSymbol: (state) => state.selectSymbol,
    getAllSymbolQuote: (state) => state.allSymbolQuote,
    getLastPrice: (state) => state.lastPrice
}