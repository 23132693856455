
const urlMode = 'list'; // list 或 default
export { urlMode };
export default [
  'https://api.yepbit.pro',
  'https://api.yepbit.com',
  'https://api.yepbit.lol',
  'https://api.yepbit.me',
  'https://api.yepbit.net',
  'https://yep.fppitcfs.com',
]
