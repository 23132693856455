import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import zhHans from '@/langs/zhHans';
import en from '@/langs/en';
import ar from '@/langs/ar';
import de from "@/langs/de"
import zhHant from '@/langs/zhHant';
import es from '@/langs/es';
import fr from '@/langs/fr';
import id from "@/langs/id"
import it from '@/langs/it';
import ja from '@/langs/ja';
import ko from '@/langs/ko';
import nl from '@/langs/nl';
import pl from '@/langs/pl';
import pt from '@/langs/pt';
import ru from '@/langs/ru';
import sv from '@/langs/sv';
import th from '@/langs/th';
import vi from '@/langs/vi';
import bg from '@/langs/bg';
import tr from '@/langs/tr';
import lt from '@/langs/lt';
import hu from '@/langs/hu';
Vue.use(Vuetify);

Vue.component('my-component', {
    methods: {
        changeLocale() {
            // this.$vuetify.lang.current = 'zhHans'
        },
    },
})
export default new Vuetify({
    lang: {
        locales: { zhHans, en, zhHant, ar, de, es, fr, id, it, ja, ko, nl, pl, pt, ru, sv, th, vi,bg,tr,lt,hu },
        current: 'en',
    },
});
