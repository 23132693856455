<template>
  <v-dialog v-model="dialog" persistent max-width="650">
    <div class="card" v-if="type == 'logout' || type == 'catch'">
      <div class="d-flex justify-end close">
        <v-btn icon @click="close"
          ><v-icon x-large color="#404851">mdi-close</v-icon></v-btn
        >
      </div>
      <p class="font-primary-27-sub mb-0 text-center mt-8">{{ title }}</p>
      <p class="font-primary-21 mb-0 text-center mt-10">{{ sub }}</p>
      <div class="actios d-flex justify-space-between mt-10">
        <v-btn
          :height="60"
          color="#0A66FF"
          class="mr-5 white--text rounded-pill"
          @click="confirmClick"
          >{{ $vuetify.lang.t("$vuetify.setting.dialogDetermine") }}</v-btn
        >
        <v-btn :height="60" color="#C0C4CA" class="font-primary-20 rounded-pill" @click="close">{{
          $vuetify.lang.t("$vuetify.setting.cancel")
        }}</v-btn>
      </div>
    </div>
    <div class="card2" v-else-if="type == 'lang' || type == 'rate'">
      <div class="d-flex justify-space-between align-center item pt-0">
        <p class="font-primary-27-sub mb-0 text-center">{{ title }}</p>
        <v-btn icon @click="close"
          ><v-icon x-large color="#404851">mdi-close</v-icon></v-btn
        >
      </div>
      <template v-if="type == 'lang'">
        <div class="list-box">
          <div
            class="d-flex justify-space-between align-center item"
            @click="langClick(item)"
            v-for="item in list"
            :key="item.key"
            :class="item.key == getLanguage && 'active'"
          >
            <p class="font-secondary-19 mb-0">
              {{ languageObj[item.key] || item.name }}
            </p>
            <div v-if="item.key == getLanguage">
              <v-icon color="#888F99">mdi-check</v-icon>
            </div>
          </div>
        </div>
      </template>
      <template v-if="type == 'rate'">
        <div class="list-box">
          <div
            class="d-flex justify-space-between align-center item"
            @click="rateClick(item)"
            v-for="item in list"
            :key="item.key"
            :class="item.key == getLanguage && 'active'"
          >
            <div>
              <p class="font-secondary-19 mb-0">
                {{ item.code }}
              </p>
              <p class="font-secondary-14 mb-0">{{ item.name }}</p>
            </div>
            <div v-if="item.code == getCurrentRate.code">
              <v-icon color="#888F99">mdi-check</v-icon>
            </div>
          </div>
        </div>
      </template>
    </div>
    <div class="card3" v-else>
      <div class="d-flex justify-space-between align-center">
        <p class="font-primary-27">{{ title }}</p>
        <v-btn icon @click="close"
          ><v-icon x-large color="#404851">mdi-close</v-icon></v-btn
        >
      </div>
      <template v-if="type == 'nickName'">
        <v-form ref="form1">
          <p class="font-secondary-14 mb-2 mt-10">
            {{ $vuetify.lang.t("$vuetify.identity.text5") }}
          </p>
          <v-text-field
            class="rounded-pill"
            :height="60"
            v-model="form.nickName"
            :rules="[rules.required]"
            :placeholder="$vuetify.lang.t('$vuetify.identity.text6')"
            solo
          >
          </v-text-field>
        </v-form>
        <v-btn
          height="60"
          block
          color="#0A66FF"
          class="mt-10 rounded-pill white--text"
          @click="updateNickName"
        >
          <span class="font-primary-20 white--text">{{
            $vuetify.lang.t("$vuetify.validator.dialog20")
          }}</span>
        </v-btn>
      </template>
      <!-- <template v-if="type == 'avatar'">
        <v-avatar class="avatar-img" color="#f3f3f3" size="144">
          <img
            v-if="avatar && avatar.type == 1"
            :src="fileimgURL || (avatar && avatar.content)"
            alt=""
          />
          <span v-else>{{ avatar && avatar.content }}</span>
        </v-avatar>
        <v-btn
          height="70"
          block
          color="#0A66FF"
          class="mt-10"
          @click="updateAvatar"
        >
          <span class="font-primary-21-dark">保存</span>
        </v-btn>
      </template> -->
    </div>
  </v-dialog>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { setCookie } from "@/utils/cookie";
export default {
  data() {
    return {
      dialog: false,
      languageObj: {
        SIMPLIFIED_CHINESE: "简体中文",
        ENGLISH: "ENGLISH",
        TRADITIONAL_CHINESE: "繁體中文",
      },
      form: {
        nickName: "",
      },
      rules: {
        required: (value) =>
          !!value || this.$vuetify.lang.t("$vuetify.identity.text38"),
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return (
            pattern.test(value) ||
            this.$vuetify.lang.t("$vuetify.identity.text39")
          );
        },
      },
    };
  },
  props: {
    title: {
      type: String,
      default: () => "",
    },
    sub: {
      type: String,
      default: () => "",
    },
    type: {
      type: String,
      default: () => "",
    },
    list: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    ...mapGetters(["getCurrentRate", "getLanguage", "getUserInfo"]),
    avatar() {
      if (Object.keys(this.getUserInfo).length == 0) {
        return;
      }
      let nickName =
        this.getUserInfo.nickName ||
        this.getUserInfo.email ||
        this.getUserInfo.phone ||
        this.getUserInfo.address;
      let userName =
        this.getUserInfo.email ||
        this.getUserInfo.phone ||
        this.getUserInfo.address;
      if (this.getUserInfo.headerImg) {
        return {
          type: 1,
          content: this.getUserInfo.headerImg,
          userName,
        };
      }

      return {
        type: 2,
        content: nickName.slice(0, 1),
        userName,
      };
    },
  },
  watch: {
    value(v) {
      this.dialog = v;
    },
  },
  methods: {
    ...mapMutations([
      "setLanguage",
      "setCurrentRate",
      "userUpdateNickNameFetch",
    ]),
    close() {
      this.$emit("update:value", false);
    },
    updateNickName() {
      let isOk = this.$refs.form1.validate();
      if (!isOk) {
        return;
      }
      this.$emit("confirm-click", {
        type: "nickName",
        ...this.form,
      });
    },
    updateAvatar() {
      this.$emit("confirm-click", {
        type: "avatar",
        ...this.form,
      });
    },
    langClick(data) {
      localStorage.setItem("lang", data.key);
      this.setLanguage(data.key);
      setCookie("lang", data.key);
      this.reloadLangs();
      location.reload();
    },
    confirmClick() {
      this.$emit("confirm-click");
    },
    rateClick(data) {
      localStorage.setItem("rate", JSON.stringify(data));
      this.setCurrentRate(data);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-text-field .v-input__slot {
  box-shadow: none !important;
  background: transparent !important;

  border: 1px solid #D3D7DD !important;
}
::v-deep .v-dialog {
  box-shadow: none !important;
  border-radius: 40px 40px 40px 40px;
}
@media (max-width:768px) {
  .card {
    padding: 20px;
  }
  .card2 {
    padding: 20px;
    .item {
      padding: 14px 0;
    }
  }
}
@media (min-width:768px) {
  .card {
    padding: 40px 80px 60px;
  }
  .card2 {
    padding: 30px;
    .item {
      padding: 28px 0;
    }
  }
}
.card {
  background: #fff;
  border-radius: 21px 21px 21px 21px;
}
.card2 {
  background: #fff;
  border-radius: 50px;
  .item {
    border-bottom: 1px solid rgba(42, 45, 51, 0.2);
    cursor: pointer;
    &.active {
      border-bottom: 1px solid #0A66FF;
    }
  }
  .list-box {
    max-height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
    // &::-webkit-scrollbar {
    //   /*滚动条整体样式*/
    //   width: 5px; /*高宽分别对应横竖滚动条的尺寸*/
    //   height: 1px;
    // }
    // &::-webkit-scrollbar-thumb {
    //   /*滚动条里面小方块*/
    //   border-radius: 10px;
    //   box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    //   background: #535353;
    // }
    // &::-webkit-scrollbar-track {
    //   /*滚动条里面轨道*/
    //   box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    //   border-radius: 10px;
    //   background: #ededed;
    // }
  }
}
.card3 {
  background: #fff;
  padding: 30px;

  border-radius: 21px 21px 21px 21px;
}
.actios {
  .v-btn {
    flex: 1;
  }
}
</style>