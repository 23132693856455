import zhHans from 'vuetify/es5/locale/zh-Hans';
export default {
    ...zhHans,
    setting: {
        language: "Basitleştirilmiş Çince",
        dialogDetermine: "Elbette",
        dreivatives: "Teslimat sözleşmesi",
        markets: "Alıntılar",
        assets: "varlıklar",
        liquidity: "Likidite madenciliği",
        helpCentre: "Yardım Merkezi",
        announcementCenter: "Duyuru Merkezi",
        about: "hakkında",
        bulletinCentre: "Duyuru Merkezi",
        serviceAgreement: "Hizmet sözleşmesi",
        support: "Destek",
        onlineSupportCustomerService: "çevrimiçi servis",
        suggestionsAndFeedback: "Öneriler ve geri bildirimler",
        service: "Sert",
        download: "indirmek",
        coinApplication: "Madeni para listeleme uygulaması",
        privacyStatement: "Gizlilik bildirimi",
        trade: "sözleşme ticareti",
        standardConteact: "Sürdürülebilir",
        signOut: "oturumu Kapat",
        assetWallets: "Varlık cüzdanı",
        financeRecords: "Hesap değişiklik kayıtları",
        identification: "Kimlik doğrulama",
        personalCenter: "Kişisel Merkez",
        chooseLanguage: "Bir dil seçin",
        tips: "ipucu",
        dark: "karanlık mod",
        light: "gündüz modu",
        languageTitle: "dil",
        convertCurrency: "Para birimini dönüştür",
        cancel: "İptal etmek",
        aboutUs: "Hakkımızda",
        standard: "Standardın sürekliliği",
        share: "paylaşmak",
        introduce: "Platforma Giriş",
        set: "kurmak",
        changePassword: "Giriş şifresini değiştir",
        denomination: "Teklif para birimi",
        color: "Renk yapılandırması",
        code: "versiyon numarası",
        color1: "Kırmızı yükselişler ve yeşil düşüşler",
        color2: "Yeşil yükselir ve kırmızı düşer",
        setLangue: "dil ayarı",
        vip: "VIP hakları",
        vip2: "Mevcut VIP seviyesi",
        safeMenu: "Güvenlik Merkezi",
        conventional: "geleneksel",
        other: "diğer",
        quickTips: "En fazla 3 kısayol işlev düğmesi ekleyin",
        quickTransaction: "Hızlı işlem",
        safeConvenient: "Güvenli ve kullanışlı",
        timeSharing: "zaman paylaşımı",
        currentLevel: "mevcut seviye",
        levelBenefits: "%{level} seviye gelir",
        teamLevel: "Takım seviyesi",
        levelLower: "%{level}ast",
        rules: "Kural açıklaması",
        bindWithdrawalAddress: "Para çekme adresini bağla",
        tipsBindWithdrawalAddress: "Para çekme adresinizi henüz bağlamadınız, lütfen önce onu bağlayın",
        walletAddressFormatIncorrect: "Cüzdan adresi biçimi yanlış",
        tradeRules1: "Gelecek dönemdeki yükseliş ve düşüşleri tahmin edin. Başlangıç ​​fiyatı olarak emir anındaki fiyat, uzlaşma fiyatı olarak ise tahmin döneminin son saniyesindeki fiyat kullanılır. Uzlaşma fiyatının başlangıç ​​fiyatından yüksek olması uzlaşmanın artması anlamına gelir. fiyatın başlangıç ​​fiyatından düşük olması düşüş anlamına gelir.",
        tradeRules2: "1.Sözleşme nedir?",
        tradeRules3: "Bu platform, USDT tabanlı kalıcı sözleşmeler sağlar. Kullanıcılar yükselişi veya düşüşü değerlendirebilir ve dijital varlık fiyatlarının yükselişinden/düşüşünden kar elde etmek için uzun sözleşmeler satın almayı veya kısa sözleşmeler satmayı seçebilir. Kalıcı sözleşmelerin teslim tarihi yoktur ve kullanıcılar bunları sonsuza kadar elinde tutabilir.",
        tradeRules4: "2. Marj nedir",
        tradeRules5: "Sanal sözleşme pazarında kullanıcıların, sözleşmenin ifasının mali garantisi olarak sözleşme bedeline göre belirli bir oranda küçük bir miktar fon ödemesi yeterlidir ve daha sonra sözleşmenin alım satımına katılabilirler. Bu tür fonlar sanal sözleşme mevduatıdır. \nBu platformdaki sözleşmeler, ayrı hesapların ayrı marjin modelini benimser. Her para birimi bir sözleşme hesabına karşılık gelir. Farklı sözleşme para birimlerinin hesap varlıkları ve pozisyonları birbirinden bağımsızdır ve farklı sözleşme hesapları arasındaki transferler ve işlemler birbirini etkilemez. Kullanıcının sözleşme hesabı açıldıktan sonra, sözleşme hesabında tutulan tüm pozisyonların riskleri ve faydaları birlikte hesaplanacaktır.",
        tradeRules6: "3. Marj nasıl hesaplanır",
        tradeRules7: "Pozisyon marjı = sözleşme nominal değeri * açık sözleşme sayısı / kaldıraç çarpanı\ndondurulmuş marj = mevcut emirdeki başarıyla verilen tüm emirlerin ve açılış emirlerinin marjlarının toplamı\nmevcut marj = hesap bakiyesi - pozisyon marjı - dondurulmuş marj",
        tradeRules8: "4. Marj oranı nasıl hesaplanır",
        tradeRules9: "Marj oranı, kullanıcı varlık riskinin bir ölçüsüdür. \nMarj oranı = (Gerçekleşmemiş kar ve zarar + Pozisyon marjı + Kullanılabilir marj + Dondurulmuş marjin - Tasfiye ücreti) / Pozisyon marjı \nMarj oranı ne kadar küçükse, hesabın riski de o kadar yüksek olur. Teminat oranı %0'a eşit veya daha az olduğunda zorunlu tasfiye tetiklenecektir.",
        tradeRules10: "5. Tahmini zorunlu tasfiye fiyatı nedir?",
        tradeRules11: "Tahmini zorunlu tasfiye fiyatı, yani teorik olarak zorunlu tasfiyeyi tetikleyen sistem tarafından hesaplanan fiyat, yalnızca referans amaçlıdır ve gerçek değerden bir miktar sapma gösterebilir.",
        tradeRules12: "6. Toplam hesap özsermayesi ve gerçekleşmemiş kar ve zarar nedir?",
        tradeRules13: "Toplam hesap özsermayesi = hesap bakiyesi + gerçekleşmemiş kar ve zarar\nGerçekleşmemiş kar ve zarar, kullanıcının döviz sözleşmesinde halihazırda tuttuğu pozisyonun kar ve zararıdır. Gerçekleşmemiş kar ve zarar, en son işlem fiyatı değişiklikleriyle değişecektir. \nUzun pozisyonlar için gerçekleşmemiş kar ve zarar = (1/ortalama pozisyon fiyatı - 1/son işlem fiyatı) * uzun pozisyonlar için sözleşme sayısı * sözleşme nominal değeri * son işlem fiyatı \n kısa pozisyonlar için gerçekleşmemiş kar ve zarar = (1/ son işlem fiyatı - 1/ Ortalama pozisyon fiyatı) * Kısa sözleşme sayısı * Sözleşme nominal değeri * Son işlem fiyatı",
        text1: "hesap Güvenliği",
        text2: "gündüz ve gece modu",
        text3: "önbelleği temizle",
        text4: "Oturumu kapatmak istediğinizden emin misiniz?",
        text5: "Önbelleği temizlemek istediğinizden emin misiniz?",
        text6: "çoklu dil",
        text7: "Güvenliğinizi korumak için en az bir adet iki faktörlü kimlik doğrulamayı açmanızı öneririz"
    },
    login: {
        login: "Giriş yapmak",
        register: "kayıt olmak",
        title1: "İstediğiniz zaman, istediğiniz yerde işlem yapın ve gerçek zamanlı piyasa koşullarını görüntüleyin",
        title2: "İstediğiniz zaman, istediğiniz yerde işlem yapın ve gerçek zamanlı piyasa koşullarını görüntüleyin",
        title3: "En Güvenilir Kripto Para Ticaret Platformu",
        formTitle1: "Hesap girişi",
        formTitle2: "Tekrar hoşgeldiniz! E-postanız, telefon numaranız ile giriş yapın",
        btn1: "cep telefonu",
        btn2: "Posta",
        btn3: "Giriş yapmak",
        btn4: "kayıt olmak",
        btn5: "şifreyi mi unuttun?",
        field1: "Telefon numarası",
        field2: "şifre",
        field3: "Posta",
        placeholder1: "ulus",
        placeholder2: "Lütfen telefon numarasını girin",
        placeholder3: "Lütfen şifre giriniz",
        placeholder4: "lütfen e-posta adresinizi girin",
        content12: "Lütfen telefon numarasını girin",
        content13: "Lütfen şifre giriniz",
        content14: "lütfen e-posta adresinizi girin",
        content16: "Dijital varlık finansal hizmetleri",
        loginSuccess: "giriş başarılı ",
        chooseArea: "Lütfen alan kodunu seçin",
        placeholder5: "Lütfen ülke kodunu seçin",
        placeholder6: "Şifremi Hatırla",
        placeholder7: "İndir uygulaması",
        placeholder8: "Müşteri hizmetlerine başvurun",
        placeholder9: "Ülke veya bölge seçin",
        placeholder10: "alan kodu",
        placeholder11: "Cüzdan girişi",
        placeholder12: "İşlem başarısız oldu, lütfen geçiş yapın",
        placeholder13: "cüzdan"
    },
    aboutUs: {
        aboutCoinbeadCenterTitle: "hakkında",
        aboutCoinbeadCenterDetails: "Dünyanın önde gelen blockchain varlık ticaret platformuna sahiptir ve tüm ekosistemi işletmektedir.",
        footDetails1: "günlük işlem hacmi",
        footDetails2: "işlemler/saniye",
        footDetails3: "Yardım Merkezi",
        coinbeadCenterTitle: "",
        coinbeadCenterDetails: "Dijital varlıklara yönelik dünyaca ünlü uluslararası bir web sitesidir ve temel olarak küresel kullanıcılara Bitcoin (BTC), Litecoin (LTC) ve Ethereum (ETH) gibi dijital varlıkların madeni paralarını ve türevlerini sağlar.",
        coinbeadCenterDetailsm: "GSLB, ağ sunucuları, ağ işlemleri, ağlar, çok makineli yüksek hızlı bellek ticaret motorları, soğuk cüzdanlar, akıllı çevrimdışı sıcak cüzdanlar vb. gibi ileri teknolojileri benimseyerek müşterilerimize Web cep telefonları ve PC'ler gibi birden fazla terminal sunuyoruz. Güvenli, istikrarlı ve güvenilir dijital tarife işlem hizmetleri sağlayın. Aynı zamanda, her müşteriye daha iyi hizmet sunmak için kullanıcı önerileri ve ihtiyaçlarına göre ürün ve hizmetleri geliştiriyor ve yükseltiyoruz ve yenilikçi kullanıcı deneyiminin iyileştirilmesinin sonu yok.",
        infrastructureCenterTitle: "altyapı",
        infrastructureCenterDetails: "Misyonumuz tüm blockchain ekosistemi için altyapı hizmetleri sağlamaktır.",
        freeCenterTitle: "özgür",
        freeCenterDetails: "Vizyonumuz paranın dünya çapında serbestçe akmasını sağlamaktır. Bu özgürlüğü yaymanın dünyayı daha iyi bir yer haline getirebileceğine inanıyoruz",
        futureCenterTitle: "Teknolojinin geleceğine öncülük etmek",
        futureCenterDetails: "Teknolojinin geleceğini açın",
    },
    applicationForListing: {
        title: "Madeni para listeleme uygulaması",
        projectName: "proje Adı",
        tokenName: "Belirteç adı",
        projectDescription: "Proje Açıklaması",
        contractAddress: "Sözleşme adresi",
        officialWebsite: "resmi internet sitesi",
        ContactAndEmail: "İletişim E-posta",
        ContactAndName: "Kişi adı",
        file: "Eki Yükle",
        pleaseEnter: "girin lütfen",
        enterFile: "Lütfen dosyaları yükleyin",
        upload1: "Şu anda 1 dosya seçimiyle sınırlıdır,",
        upload2: "Bu sefer ben seçtim",
        upload3: "Dosyalar,",
        upload4: "Toplam seçili",
        upload5: "Dosyalar",
    },
    public: {
        submit: "göndermek",
        copySuccessfully: "Başarıyla kopyalandı",
        copyFailed: "Kopyalama başarısız oldu",
        noDate: "Veri yok",
        submitSuccessfully: "Başarıyla gönderildi",
        goBack: "son sayfaya geri dön",
        cancel: "İptal etmek",
        home: "ön Sayfa",
        loading: "Yükleniyor...",
        confirm: "onaylamak",
        loadingFailed: "ağ anormalliği",
        nomore: "daha fazla yok",
        tryAgain: "Lütfen sayfayı yenileyin/programdan çıkın ve tekrar yükleyin!",
        hot: "Popüler alanlar",
    },
    inviteFriends: {
        inviteFriends: "Arkadaşlarınızla paylaşın",
        freedomOfWealth: " Kazan-kazan işbirliği",
        myInvitationCode: "davetiye kodum",
        clickCopy: "Kopyalamak için tıklayın",
        myInvitationLink: "Davetiye bağlantım",
        clicktoSaveTheQRCode: "QR kodunu kaydetmek için tıklayın",
        saveQRCode: "QR kodunu davet et",
        numberOfSubordinates: "Ast sayısı",
        rebateIncome: "İndirim geliri",
        people: "insanlar",
        myPromotionRevenue: "Promosyon gelirim",
        numberOfDirectSubordinates: "Doğrudan astların sayısı",
        totalNumberOfSubordinates: "Toplam ast sayısı",
        directSubordinateReturnsCommission: "Doğrudan astlar için komisyon indirimi",
        theTotalCommission: "toplam indirim",
        checkHistory: "İndirim kayıtlarını görüntüle",
        history: "Komisyon kaydı",
        theLowerAccount: "Alt hesap",
        type: "tip:",
        serviceCharge: "Hizmet bedeli:",
        percentageofCommissionReturned: "İndirim oranı:",
        commissionrebateAmount: "İndirim tutarı:",
        time: "zaman:",
        typeName1: "Şarj etme ve devreye alma",
        typeName2: "MT sipariş işleme ücreti üç seviyeli dağıtım",
        typeName3: "MT sipariş işleme ücreti üç seviyeli dağıtım",
        typeName4: "Yönlendirme ödülleri",
        typeName30: "Şarj etme ve devreye alma",
        typeName31: "Periyodik ticari kar indirimi",
        typeName32: "Likidite madenciliği kar iadesi",
        typeName45: "İşlem ücreti iadesi",
        typeName46: "Kendi işlem ücreti üzerinden komisyon indirimi",
        typeName50: "Teslimat sözleşmesi işlem ücreti iadesi",
        typeName51: "Sürekli Sözleşme İşleme Ücreti İndirimi",
        typeName52: "Teslimat sözleşmesi taşıma ücretleri için ek komisyon iadesi",
        typeName53: "Kalıcı sözleşme işlem ücretleri için ek komisyon iadesi",
        typeName54: "Kalıcı sözleşme işlem ücretlerinde ek indirimler",
        shareRules1: "Davet talimatları",
        shareRules2: "Davet merkezine girin, davet bağlantısını veya kodunu kopyalayın ve arkadaşlarınızla paylaşın. Davet kodunuzla kaydolarak arkadaşlarınız astınız olabilir.",
        shareRules3: "İndirim kazanın",
        shareRules4: "Astlarınız işlem yaptığında, ilgili komisyonları alabilirsiniz ve üç seviyeye kadar astınız desteklenir. Örneğin, A arkadaşınızı, A B'yi ve B C'yi davet ederseniz, A, B ve C platformda sözleşmeler ve diğer işlemleri gerçekleştirebilir ve hepiniz ilgili komisyonları alabilirsiniz.",
        shareRules5: "Takım seviyesi",
        shareRules6: "Ne kadar çok birinci seviye astınızı terfi ettirirseniz, takım seviyesi o kadar yüksek olur ve yararlanabileceğiniz indirim de o kadar yüksek olur. Takım seviyeleri LV1-LV6 olarak bölünmüştür. Yükseltme kuralları aşağıdaki tabloda gösterilmektedir; burada 'N', yeniden şarj olan ve gerçek ad kimlik doğrulamasını tamamlayan birinci seviye astların sayısıdır.",
        shareRules7: "Astlarınız teslimat sözleşmelerine bahis koyduğunda, onların bahisleriyle orantılı olarak komisyon alabilirsiniz.",
        upgradeConditions: "Gerekmek",
        recommendedNumberOfPeople: "Önerilen kişi sayısı"
    },
    suggestionsAndFeedback: {
        suggestionsAndFeedback: "Öneriler ve geri bildirimler",
        text: "Kullanım sırasında hatalarla karşılaşırsanız veya ürün önerileriniz varsa lütfen bize geri bildirimde bulunun.",
        email: "e-posta adresi",
        enterEmail: "Lütfen e-posta adresini girin",
        enterCorrectEmail: "Lütfen doğru e-posta adresini girin",
        title: "başlık",
        enterTitle: "Lütfen bir soru başlığı girin",
        describeTheProblem: "Sorunu açıklayın",
        enterDescribeTheProblem: "Lütfen tanımladığınız sorunu girin",
        submit: "göndermek",
    },
    help: {
        enterSearch: "aramak",
        title: "Yardım Merkezi",
        search: "Lütfen sorunuzu girin",
        text1: "İhtiyaçlarınızı anlayın ve size daha uygun hizmetler sunmaya devam edin"
    },
    announcement: {
        enterSearch: "aramak",
        title: "Duyuru Merkezi",
        search: "Lütfen sorunuzu girin"
    },
    privacy: {
        title: "Gizlilik bildirimi",
        content1: "1. Kullanıcıların hangi kişisel bilgileri toplanıyor?",
        content2: "Kişisel bilgiler, ürün ve hizmetlerin sağlanması ve sürekli iyileştirilmesi amacıyla toplanmaktadır.",
        content3: "Topladığımız kişisel bilgi türleri şunlardır:",
        content4: "Bize sağladığınız bilgiler: Platformla bağlantılı olarak sağladığınız her türlü bilgiyi alıyor ve saklıyoruz. Belirli bilgileri sağlamamayı seçebilirsiniz ancak bunun sonucunda belirli 'bu platform hizmetlerini' kullanamayabilirsiniz. Bize sağladığınız bilgilere örnek olarak şunlar verilebilir:",
        content5: "İsim",
        content6: "adres",
        content7: "Uyruğu Olunan Ülke",
        content8: "Kimlik fotoğrafı",
        content9: "Cep telefonu numarası/e-posta numarası",
        content10: "Sizi tanımlamamıza yardımcı olacak diğer bilgiler",
        content11: "Otomatik olarak toplanan bilgiler: Platform Hizmetlerini kullanımınıza ilişkin belirli türdeki bilgileri otomatik olarak topluyor ve saklıyoruz. Birçok web sitesi gibi biz de çerezler ve diğer benzersiz tanımlayıcılar kullanıyoruz. Web tarayıcınız veya cihazınız Platforma eriştiğinde belirli türde bilgiler elde ederiz. Topladığımız ve analiz ettiğimiz bilgilere örnek olarak şunlar verilebilir:",
        content12: "Kişisel bilgisayarı İnternet'e bağlayan İnternet Protokolü (IP) adresi;",
        content13: "Kişisel bir cihazın veya bilgisayarın oturum açma bilgileri, e-posta adresi, şifresi ve konumu;",
        content14: "Sürüm ve saat dilimi ayarları;",
        content15: "İşlem geçmişi.",
        content16: "Diğer Kaynaklardan Gelen Bilgiler: Kredi bürolarının kredi geçmişi gibi diğer kaynaklardan sizin hakkınızda bilgi toplayabiliriz. Bu bilgileri dolandırıcılığı önlemek ve tespit etmek için kullanacağız.",
        content17: "2. Çocuklar kullanabilir mi?",
        content18: "Bu platformda 18 yaşından küçük bireylerin 'Hizmet'i kullanmasına izin verilmemektedir.",
        content19: "3. Çerezler ve diğer tanımlayıcılar ne için kullanılır?",
        content20: "Hedeflenen iyileştirmeler yapabilmek amacıyla kullanıcı deneyimini optimize etmek, hizmetlerimizi sunmak ve müşterilerin hizmetlerimizi nasıl kullandığını anlamak için çerezler ve benzer araçlar kullanırız. Sistemimizin tarayıcınızı veya cihazınızı tanıyabilmesini ve size hizmet sunabilmesini sağlamak için çerezleri kullanırız.",
        content21: "Aşağıdakiler gibi hizmetleri sağlamak için operasyonel çerezleri ve benzer araçları (toplu olarak 'Çerezler') kullanırız:",
        content22: "Oturum açtığınızda ve hizmetlerimizi kullandığınızda sizi tanımlamak için.",
        content23: "Özelleştirilmiş özellikler ve hizmetler sağlayın.",
        content24: "Dolandırıcılık faaliyetlerine karşı koruma sağlayın.",
        content25: "Güvenliği artırın.",
        content26: "Tercihlerinizi (para birimi ve dil gibi) takip edin.",
        content27: "Hedeflenen iyileştirmeler yapabilmek amacıyla kullanıcıların hizmetlerimizi nasıl kullandıklarını anlamak için de çerezleri kullanırız.",
        content28: "4. Kişisel bilgilerim paylaşılacak mı?",
        content29: "Kullanıcı bilgileri işimizin önemli bir parçasıdır ve kullanıcıların kişisel bilgilerini başkalarına satmayacağız. Bu platform, kullanıcıların kişisel bilgilerini yan kuruluşları veya bağlı kuruluşlarıyla yalnızca aşağıdaki durumlarda paylaşacaktır. Bu bağlı kuruluşların veya bağlı kuruluşların bu gizlilik bildirimine uyması veya en azından bu gizlilik bildiriminde açıklanan koruma önlemleri kadar koruyucu uygulamaları izlemesi gerekmektedir.",
        content30: "Üçüncü Taraf Hizmet Sağlayıcıları: Bizim adımıza işlevleri yerine getirmeleri için başka şirket ve bireyleri görevlendiriyoruz. Bu tür işlevlere örnek olarak şunlar verilebilir: verilerin analiz edilmesi, pazarlama yardımı sağlanması, ödemelerin işlenmesi, içerik iletilmesi ve kredi riskinin değerlendirilmesi ve yönetilmesi. Bu üçüncü taraf hizmet sağlayıcıları, işlevlerini yerine getirmek için gereken kişisel bilgilere erişebilir ancak bunları başka amaçlarla kullanamazlar. Ayrıca kişisel bilgileri bu gizlilik bildirimine ve geçerli veri koruma yasalarına uygun şekilde işlemelidirler.",
        content31: "İş Transferleri: İşimiz büyümeye devam ettikçe diğer işletmeleri veya hizmetleri satabilir veya satın alabiliriz. Bu tür işlemlerde, kullanıcı bilgileri genellikle devredilen iş varlıkları arasındadır ancak önceden var olan herhangi bir gizlilik beyanındaki vaatlere tabi olmaya devam eder (tabii ki kullanıcı aksini kabul etmedikçe). Ayrıca Şirketimizin veya varlıklarının tamamına yakınının satın alınması durumunda kullanıcı bilgileri de devredilecektir. Şirketimizin ve Başkalarının Korunması: Hesap vermenin ve diğer kişisel bilgilerin yasalara veya düzenleyici yükümlülüklerimize uymak için uygun olduğuna inandığımızda, Kullanım Koşullarımızı ve diğer sözleşmeleri ya da şirketimizin haklarını, mülkiyetini veya güvenliğini uygulamak veya uygulamak kullanıcılar veya diğerleri Ne zaman hesabı ve diğer kişisel bilgileri açıklayacağız. Buna dolandırıcılığa karşı koruma sağlamak ve kredi riskini azaltmak için diğer şirket ve kuruluşlarla bilgi alışverişi de dahildir.",
        content32: "5. Kişisel verilerin uluslararası aktarımı",
        content33: "Verilerinizi Avrupa Ekonomik Alanı ('AEA') dışına aktarabiliriz. Avrupa Komisyonu, verilerin aktarıldığı ülkenin yeterli düzeyde koruma sağladığını doğrulamadığı sürece, bu tür aktarımların geçerli veri koruma kurallarına uygun olmasını sağlamak için uygun önlemleri uygulayacağız.",
        content34: "6. Kişisel bilgilerim güvende mi?",
        content35: "Sistemlerimizi güvenliğinizi ve gizliliğinizi göz önünde bulundurarak tasarladık. Aktarım sırasında kişisel bilgilerin güvenliğini korumaya çalışmak için şifreleme protokolleri ve yazılımları kullanıyoruz.",
        content36: "Kişisel bilgileri toplarken, saklarken ve ifşa ederken her zaman fiziksel, elektronik ve prosedürle ilgili önlemleri alırız. Güvenlik prosedürlerimiz, kişisel bilgilerinizi size açıklamadan önce kimliğinizi doğrulamamız gerekebileceği anlamına gelir.",
        content37: "Yapabileceğiniz en önemli şey, kişisel hesap şifrelerinizi yetkisiz erişime karşı korumaktır. Hesabınız için diğer çevrimiçi hesaplardan farklı, benzersiz bir şifre belirlemenizi öneririz. Paylaşılan bir bilgisayarı kullandıktan sonra oturumu kapattığınızdan emin olun.",
        content38: "7. Kişisel bilgilerimi nasıl korumalıyım?",
        content39: "Kişisel bilgileri toplama ve işleme şeklimizle ilgili herhangi bir sorunuz veya itirazınız varsa lütfen müşteri hizmetleri personeliyle iletişime geçin.",
        content40: "Kişisel bilgilerinizi belirli bir amaç için işlememizi kabul ettikten sonra, niyetinizi istediğiniz zaman geri çekebilirsiniz ve biz de verilerinizi bu amaç için işlemeyi durdururuz.",
        content41: "Ayrıca, yürürlükteki yasalara tabi olarak, kişisel verilere erişim, bunların düzeltilmesini ve silinmesini talep etme ve veri taşınabilirliğini talep etme hakkına da sahipsiniz. Ayrıca kişisel verilerinizi işlememize itiraz edebilir veya belirli durumlarda kişisel verilerinizi işlememizin kısıtlanmasını talep edebilirsiniz.",
        content42: "8. AB GDPR ve Birleşik Krallık Veri Koruma Kanunu – Yasal Dayanak",
        content43: "AB GDPR ve Birleşik Krallık veri koruma kanunu, kişisel bilgileri kullanırken yasal dayanaklara uymamızı gerektirmektedir. Temelimiz, kişisel bilgilerin kullanıldığı belirli amaçlara bağlıdır. Bu üsler şunları içerir:",
        content44: "Bir sözleşmenin yerine getirilmesi - siparişleri kabul etmek ve işleme koymak ve ödemeleri işleme koymak için kişisel bilgilerinizi kullanmamız da dahil olmak üzere, ürünler veya hizmetler sağlamak veya sizinle bunlar hakkında iletişim kurmak.",
        content45: "Meşru ticari çıkarlarımız ve kullanıcı çıkarlarımız - Kullanıcılarımızın, kendimizin veya başkalarının güvenliğini korumak için dolandırıcılığı ve kötüye kullanımı tespit edip önleriz.",
        content46: "İzniniz – Kişisel bilgilerinizi size iletilen belirli amaçlar doğrultusunda işlemek için izninize ihtiyacımız var. Kişisel bilgilerinizin belirli bir amaç için işlenmesine ilişkin onayınızı bize verdikten sonra, onayınızı istediğiniz zaman geri çekebilirsiniz ve verilerinizi bu amaç için işlemeyi durduracağız.",
        content47: "Yasal yükümlülüklere uyun - Kişisel bilgilerinizi yasaların gerektirdiği şekilde kullanırız. Örneğin, kimlik doğrulama amacıyla banka hesabı bilgilerini topluyoruz.",
        content48: "Yukarıdaki ve diğer yasal dayanaklar, kişisel bilgileri kullandığımız belirli amaçlara bağlıdır.",
        content49: "9. Kullanım Koşulları, Bildirimler ve Revizyonlar",
        content50: "Kullanımınız ve gizlilikle ilgili herhangi bir anlaşmazlık bu Bildirime ve Kullanım Koşullarımıza tabidir. Bu platformun gizliliğiyle ilgili herhangi bir endişeniz varsa lütfen ayrıntılı bir açıklama ile bizimle iletişime geçin; sorununuzu çözmekten memnuniyet duyarız. Ayrıca yerel veri koruma yetkilinizle iletişime geçme hakkına da sahipsiniz.",
        content51: "İşimiz sürekli değişiyor ve gizlilik bildirimimiz de değişecek. En son değişiklikleri görmek için web sitemizi sık sık kontrol etmelisiniz. Revize edilen içeriği kabul etmiyorsanız erişimi derhal durdurmalısınız. Gizlilik Politikasının güncellenmiş sürümü yayınlandıktan sonra erişiminizin devam etmesi, güncellenen içeriği kabul ettiğiniz ve güncellenen Gizlilik Politikasına uymayı kabul ettiğiniz anlamına gelir. Aksi belirtilmediği sürece mevcut Gizlilik Bildirimi, siz ve hesabınız hakkında tuttuğumuz tüm bilgiler için geçerlidir.",
    },
    serviceAgreement: {
        title: "Hizmet sözleşmesi",
        content1: "Bu web sitesi, özellikle kullanıcıların dijital varlık işlemleri yapmasına ve ilgili hizmetleri (bundan sonra 'hizmet' veya 'hizmet' olarak anılacaktır) sağlamasına yönelik bir platformdur. Bu sözleşmeyi açıklamanın kolaylığı açısından, bu web sitesi toplu olarak bu sözleşmede 'biz' veya diğer birinci şahıs zamirlerini kullanır. Siteye giriş yapan gerçek kişi veya diğer kişi bu sitenin kullanıcısı olduğu sürece, işbu sözleşmenin ifade edilmesinde kolaylık sağlamak amacıyla aşağıda 'siz' veya diğer ikinci kişi kullanılacaktır. Bu Sözleşmeyi ifade etmenin kolaylığı açısından, biz ve siz bu Sözleşmede topluca 'Taraflar' olarak anılırız ve biz veya siz ayrı ayrı 'Taraf' olarak anılırız.",
        content2: "önemli ipucu:",
        content3: "Sizlere özellikle şunu hatırlatıyoruz:",
        content4: "1 Dijital varlıkların kendisi herhangi bir finansal kurum veya şirket ya da bu web sitesi tarafından verilmemektedir",
        content5: "2 Dijital varlık piyasası yenidir, doğrulanmamıştır ve büyümeyebilir;",
        content6: "3 Dijital varlıklar esas olarak spekülatörler tarafından kullanılır, perakende ve ticari piyasalarda nispeten az kullanılır. Dijital varlık işlemleri son derece risklidir ve gün boyunca herhangi bir yükseliş ve düşüş sınırı olmadan işlem görür ve fiyatlar piyasa yapıcılardan kolaylıkla etkilenir. ve küresel hükümet politikaları;",
        content7: "4 Şirket, tamamen kendi takdirine bağlı olarak bu Sözleşmeyi ihlal ettiğinizi veya bu web sitesi tarafından sağlanan hizmetlerin veya bu web sitesi tarafından sağlanan hizmetleri kullanımınızın, yargı bölgenizdeki yasalara göre yasa dışı olduğunu tespit ederse, Şirket, sözleşmeyi askıya alma hakkına sahiptir. veya hesabınızı istediğiniz zaman feshedebilir veya bu web sitesi tarafından sağlanan hizmetleri veya dijital varlık işlemlerini kullanımınızı askıya alabilir veya sonlandırabilirsiniz. [Çin Anakarası, Tayvan, İsrail, Irak, Bangladeş, Bolivya, Ekvador, Kırgızistan, Sevastopol ve Birleşik Krallık'tan (perakende kullanıcılar)] herkesin bu web sitesi tarafından sağlanan sözleşme işlem hizmetlerini kullanması yasaktır. Yukarıda belirtilen ülke veya bölge listesi, farklı ülke veya bölgelerin politikalarına ve ürün türlerine bağlı olarak değişecektir. Sizi o anda özel olarak bilgilendiremeyebiliriz; bu nedenle, lütfen bu sözleşmedeki güncellemeleri zamanında yapmaya dikkat edin.",
        content8: "Dijital varlık ticareti son derece yüksek riskler taşır ve çoğu insan için uygun değildir. Bu işlemin kısmen veya tamamen zararla sonuçlanabileceğini anlıyor ve anlıyorsunuz, bu nedenle işlemin tutarını karşılayabileceğiniz zararın derecesine göre belirlemelisiniz. Dijital varlıkların türev riskler oluşturacağını biliyor ve anlıyorsunuz; dolayısıyla herhangi bir sorunuz varsa öncelikle profesyonel danışmanların yardımına başvurmanız önerilir. Ayrıca yukarıda sayılan risklerin yanı sıra öngörülemeyen riskler de bulunacaktır. Dijital varlıkları satın alma veya satma kararı vermeden önce mali durumunuzu ve yukarıda belirtilen riskleri dikkatlice değerlendirmeli ve net bir muhakeme kullanmalısınız ve bunun sonucunda ortaya çıkan tüm zararları üstlenmiyoruz.",
        content9: "Lütfen aklınızda bulundurun:",
        content10: "1 Bu web sitesinin yalnızca dijital varlık bilgilerini edinebileceğiniz, işlem taraflarını bulabileceğiniz, pazarlık yapabileceğiniz ve dijital varlıklar üzerinde işlem gerçekleştirebileceğiniz bir yer olarak kullanıldığını anlıyorsunuz, bu nedenle ihtiyatlı davranmalısınız. ilgili dijital varlıkların ve/veya bilgilerin gerçekliğini, hukuka uygunluğunu ve geçerliliğini tespit etmeye karar verecek ve bundan kaynaklanan sorumlulukları ve kayıpları tek başına üstlenecektir.",
        content11: "2 Bu sitede yer alan her türlü görüş, haber, tartışma, analiz, fiyat, öneri ve diğer bilgiler genel piyasa yorumları olup, yatırım tavsiyesi niteliğinde değildir. Kâr kaybı dahil ancak bununla sınırlı olmamak üzere, doğrudan veya dolaylı olarak bu bilgilere güvenilmesinden kaynaklanan hiçbir kayıptan sorumlu değiliz.",
        content12: "3 Bu web sitesi, herhangi bir zamanda kendi kararını verme, içeriği değiştirme veya değiştirme hakkını saklı tutar. Web sitesi bilgilerinin doğruluğunu sağlamak için makul önlemler aldık, ancak doğruluğunu garanti edemeyiz ve bunlardan sorumlu olmayacağız. Bu web sitesinde yer alan bilgilerden veya herhangi bir sebepten kaynaklanan kayıp veya hasarlar, doğrudan veya dolaylı olarak internete bağlanmadaki veya herhangi bir bildirim ve bilginin iletilmesi veya alınmasındaki gecikmelerden veya başarısızlıklardan kaynaklanan kayıplar.",
        content13: "4 İnternet tabanlı ticaret sistemlerini kullanmanın, yazılım, donanım ve İnternet bağlantısı arızaları dahil ancak bunlarla sınırlı olmamak üzere riskleri de vardır. İnternetin güvenilirliği ve kullanılabilirliği üzerinde hiçbir kontrolümüz olmadığından, bozulma, gecikmeler ve bağlantı arızalarından dolayı hiçbir sorumluluk kabul etmiyoruz.",
        content14: "5 Bu web sitesinin tüm yasa dışı ticari faaliyetlerde veya kara para aklama, kaçakçılık, ticari rüşvet vb. gibi yasa dışı faaliyetlerde bulunmak amacıyla kullanılması yasaktır. Herhangi bir yasa dışı işlem veya yasa dışı faaliyetten şüphelenilmesi durumunda, bu web sitesi aşağıdakiler de dahil olmak üzere çeşitli mevcut araçları kullanacaktır: hesapların dondurulması, ilgili makamlara bildirimde bulunulması vb. ile sınırlı olmamak üzere, bundan doğacak tüm sorumlulukları üstlenmiyor ve ilgili kişilerden mesuliyet talep etme hakkını saklı tutuyoruz.",
        content15: "6 Bu web sitesinin kötü niyetli piyasa manipülasyonu, adil olmayan işlemler ve diğer etik olmayan ticari faaliyetlerde bulunmak amacıyla kullanılması yasaktır. Bu tür olayların tespit edilmesi durumunda bu web sitesi uyarıda bulunur, işlemleri kısıtlar, hesap gibi önleyici koruma tedbirlerinden kaynaklanan hiçbir sorumluluğu kabul etmiyoruz. kapatma ve ilgili kişilerden sorumluluk talebinde bulunma hakkını saklı tutar.",
        content16: "1. Genel ilkeler",
        content17: '1.1 "Kullanıcı Sözleşmesi" (bundan böyle "bu Sözleşme" veya "Bu Hüküm ve Koşullar" olarak anılacaktır), ana metin olan "Gizlilik Koşulları", "Müşterinizi Tanıyın ve Kara Para Aklamayı Önleme Politikası" ve bu sözleşmeyi düzenleyen diğer politikalardan oluşur. Bu web sitesinde yayınlanmış veya gelecekte yayınlanabilecek çeşitli kurallar, beyanlar, talimatlar vb. içerir.',
        content18: "1.2 Bu web sitesi tarafından sağlanan hizmetleri kullanmadan önce bu sözleşmeyi dikkatlice okumalısınız. Herhangi bir şeyi anlamadıysanız veya başka bir şekilde gerekliyse lütfen profesyonel bir avukata danışın. Bu Sözleşmeyi ve/veya değiştirilmesini herhangi bir zamanda kabul etmiyorsanız, lütfen bu web sitesi tarafından sağlanan hizmetleri kullanmayı derhal bırakın veya bu web sitesinde artık oturum açmayın. Bu web sitesinde oturum açtığınızda, bu web sitesindeki herhangi bir hizmeti kullandığınızda veya diğer benzer davranışlarda bulunduğunuzda, bu, bu web sitesi tarafından bu Sözleşmede herhangi bir zamanda yapılan değişiklikler de dahil olmak üzere, bu Sözleşmenin içeriğini anladığınız ve tamamen kabul ettiğiniz anlamına gelir.",
        content19: '1.3 Kayıt işlemi sırasında "Kabul Ediyorum" butonuna tıklayarak, bu sitenin gereklerine uygun olarak ilgili bilgileri doldurarak ve ilgili diğer prosedürlerin ardından başarılı bir şekilde kayıt yaptırarak bu siteye üye (bundan böyle "Üye" olarak anılacaktır) olabilirsiniz. bu web sitesinin kullanımı sırasında şirket ile elektronik olarak bir sözleşme imzaladığınız veya "Kabul Ediyorum" işaretli veya benzer anlamlara sahip herhangi bir düğmeye tıkladığınızda veya bu web sitesi tarafından sağlanan hizmetleri bu web sitesi tarafından izin verilen diğer şekillerde fiilen kullandığınız anlamına gelir; Bu Sözleşmenin tüm koşullarını tam olarak anladığınız, kabul ettiğiniz ve kabul ettiğiniz anlamına gelir. El yazısıyla imzanızın olmaması, bu Sözleşmenin sizin için yasal bağlayıcılığını etkilemeyecektir.',
        content20: "1.4 Bu siteye üye olduktan sonra size bir üye hesabı ve buna ait şifre verilecektir. Üye hesabı ve şifresinin saklanması sizin sorumluluğunuzdadır; hesabınız altında gerçekleştirilen tüm faaliyet ve etkinliklerden yasal olarak siz sorumlusunuz.",
        content21: "1.5 Yalnızca bu web sitesine üye olan üyeler, bu web sitesi tarafından sağlanan dijital varlık alım satım platformunu işlemler için kullanabilir ve bu web sitesinde belirtildiği üzere yalnızca üyelerin kullanımına sunulan diğer hizmetlerden yararlanabilir; üye olmayanlar yalnızca web sitesine giriş yapabilir, göz atabilir; Web sitesi ve bu web sitesi tarafından sağlanan diğer hizmetler.",
        content22: "1.6 Bu web sitesi tarafından sağlanan herhangi bir hizmet ve işleve kaydolarak ve bunları kullanarak, aşağıdakileri okumuş, anlamış ve:",
        content23: "1.6.1 Bu Sözleşmenin tüm hüküm ve koşullarını kabul edin.",
        content24: "1.6.2 18 yaşın üzerinde olduğunuzu veya geçerli farklı yasalara göre sözleşme yapmak için yasal yaşta olduğunuzu, bu web sitesine kaydolduğunuzu, satış veya satın alma yaptığınızı, bilgi yayınlamanızı vb. ve bu web sitesinin hizmetlerini kabul ettiğinizi onaylıyorsunuz. Üzerinizde yargı yetkisine sahip olan egemen ülke veya bölgenin ilgili yasa ve düzenlemelerine uyacak ve bu koşulları kabul etme, işlem yapma ve dijital varlık işlemlerini yürütmek için bu web sitesini kullanma konusunda tam yetkiye sahip olacaksınız.",
        content25: "1.6.3 İşleme dahil olan size ait tüm dijital varlıkların yasal olarak elde edildiğini ve mülkiyetine sahip olduğunu garanti edersiniz.",
        content26: "1.6.4 Kendi ticari veya ticari olmayan faaliyetlerinizden ve her türlü kazanç veya kayıptan yalnızca kendinizin sorumlu olduğunu kabul edersiniz.",
        content27: "1.6.5 Kayıt olurken verdiğiniz bilgilerin doğru ve doğru olduğunu onaylıyorsunuz.",
        content28: "1.6.6 Ticari karların raporlanması da dahil olmak üzere, vergi açısından ilgili kanunların gerekliliklerine uymayı kabul edersiniz.",
        content29: "1.6.7 Bu web sitesi tarafından sağlanan hizmetlerle ilgili olsun veya olmasın, hiçbir zaman bu web sitesinin veya şirketin çıkarlarına zarar veren davranış veya faaliyetlerde bulunmamayı veya bunlara katılmamayı kabul edersiniz.",
        content30: "1.6.8 Bu sözleşme yalnızca sizinle bizim aramızdaki hak ve yükümlülükleri düzenler ve bu web sitesinin ve diğer web sitelerinin kullanıcıları ile sizin aranızda dijital varlık işlemlerinden kaynaklanan hukuki ilişkileri ve hukuki anlaşmazlıkları kapsamaz.",
        content31: "2. Anlaşma değişiklikleri",
        content32: "Bu Sözleşmeyi zaman zaman revize etme ve size ayrıca bildirimde bulunmaksızın web sitesinde duyurma hakkını saklı tutuyoruz. Revizyondan sonra, revizyon zamanı bu Sözleşmede görüntülenen [Son Güncelleme Zamanı] üzerinde işaretlenecek ve otomatik olarak gerçekleşecektir. web sitesinde yayınlandıktan sonra yürürlüğe girer. Zaman zaman bu Sözleşmenin güncelleme zamanına ve güncellenen içeriğine göz atmalı ve bunlara dikkat etmelisiniz. İlgili değişiklikleri kabul etmiyorsanız, bu web sitesinin hizmetlerini kullanmaya devam ettiğinizi derhal bırakmalısınız; web sitesi, revize edilen sözleşmeyi kabul ettiğiniz ve bunlara bağlı kalmayı kabul ettiğiniz anlamına gelir.",
        content33: "3. Kayıt",
        content34: "3.1 Kayıt nitelikleri",
        content35: "Kayıt işlemini tamamladığınızda veya bu web sitesi tarafından sağlanan hizmetleri bu web sitesi tarafından izin verilen diğer şekillerde fiilen kullandığınızda, bu sözleşmeyi imzalama ve bu web sitesinin hizmetlerini yürürlükteki yasaların öngördüğü şekilde kullanma olanağına sahip olmanız gerektiğini onaylıyor ve taahhüt ediyorsunuz. gerçek kişi, tüzel kişi veya başka bir kuruluş. Kaydolmayı Kabul Et düğmesini tıklattığınızda, bu sizin veya yetkili temsilcinizin sözleşmenin içeriğini kabul ettiğiniz ve bu web sitesinin hizmetlerini temsilcisi tarafından kaydettirdiğiniz ve kullandığınız anlamına gelir. Yukarıda belirtilen niteliklere sahip değilseniz, bundan doğacak tüm sonuçlara siz ve yetkili acenteniz katlanacak olup, şirket hesabınızı iptal etme veya kalıcı olarak dondurma ve sizi ve yetkili acentenizi sorumlu tutma hakkını saklı tutar.",
        content36: "3.2 Kaydın amacı",
        content37: "Bu web sitesine kaydınızın yasa ve yönetmelikleri ihlal etme veya bu web sitesindeki dijital varlık işlemlerinin düzenini bozma amacı taşımadığını onaylıyor ve taahhüt ediyorsunuz.",
        content38: "3.3 Kayıt süreci",
        content39: "3.3.1 Bu web sitesinin kullanıcı kayıt sayfasında gerekli olduğu şekilde geçerli bir e-posta adresi, cep telefonu numarası ve diğer bilgileri sağlamayı kabul edersiniz. Sağladığınız veya onayladığınız e-posta adresini, cep telefonu numarasını veya bu web sitesi tarafından izin verilen diğer yöntemleri kullanabilirsiniz. Bu web sitesine girmek için oturum açma yöntemi olarak. Gerekirse, farklı yargı bölgelerinin ilgili yasa ve yönetmelikleri uyarınca, gerçek adınızı, kimlik belgenizi ve yasa ve yönetmeliklerde, gizlilik hükümlerinde ve kara para aklamayı önleme hükümlerinde öngörülen diğer ilgili bilgileri sağlamalı ve kayıt bilgilerinizi sürekli olarak güncellemelisiniz. zamanında, ayrıntılı ve doğru bir şekilde yapılmasını gerektirir. Orijinal olarak yazılan tüm bilgilere kayıt bilgileri olarak atıfta bulunulacaktır. Bu tür bilgilerin gerçekliğinden, eksiksizliğinden ve doğruluğundan siz sorumlusunuz ve bundan kaynaklanan doğrudan veya dolaylı tüm zararları ve olumsuz sonuçları üstleneceksiniz.",
        content40: "3.3.2 Bulunduğunuz egemen ülke veya bölgenin kanunları, düzenlemeleri, kuralları, emirleri ve diğer düzenlemeleri cep telefonu numaraları için gerçek ad gerekliliklerini içeriyorsa, sağladığınız kayıtlı cep telefonu numarasının gerçek ad altında kayıtlı olduğunu kabul edersiniz. Adınızı mevzuata uygun olarak vermemeniz durumunda, doğrudan veya dolaylı olarak oluşacak her türlü zarar ve olumsuz sonuçlar tarafınıza ait olacaktır.",
        content41: "3.3.3 Kayıt için gerekli bilgileri yasal, eksiksiz ve etkili bir şekilde vermeniz ve doğrulamış olmanız halinde, bu web sitesinin hesap numarası ve şifresini almaya hak kazanmış olursunuz. Başarılı bir şekilde kaydolmuş olmanız ve bu web sitesine üye olarak giriş yapabilmeniz gerekmektedir.",
        content42: "3.3.4 Bu web sitesinin yönetimi ve işletilmesiyle ilgili olarak bu web sitesi tarafından gönderilen e-postaları ve/veya kısa mesajları almayı kabul edersiniz.",
        content43: "4. Hizmet",
        content44: "Bu web sitesi, yalnızca bu web sitesi aracılığıyla dijital varlık ticareti faaliyetleriniz için (dijital varlık ticareti ve diğer hizmetler dahil ancak bunlarla sınırlı olmamak üzere) çevrimiçi ticaret platformu hizmetleri sağlar.",
        content45: "4.1 Hizmet içeriği",
        content46: "4.1.1 Bu web sitesinde çeşitli dijital varlık ürünlerinin gerçek zamanlı piyasa koşullarına ve işlem bilgilerine göz atma hakkına sahip olduğunuz gibi, bu web sitesi aracılığıyla dijital varlık işlem talimatlarını gönderme ve dijital varlık işlemlerini tamamlama hakkına da sahipsiniz.",
        content47: "4.1.2 Bu web sitesinde üyelik hesabınızda yer alan bilgileri görüntüleme ve bu web sitesinin sağladığı fonksiyonları çalıştırmak için uygulama hakkına sahipsiniz.",
        content48: "4.1.3 Bu sitede yayınlanan etkinlik kurallarına uygun olarak bu site tarafından düzenlenen site etkinliklerine katılma hakkına sahipsiniz.",
        content49: "4.1.4 Bu web sitesinin size sağlamayı vaat ettiği diğer hizmetler.",
        content50: "4.2 Hizmet Kuralları Bu web sitesinin aşağıdaki hizmet kurallarına uyacağıma söz veriyorsunuz:",
        content51: "4.2.1 Yasalara, düzenlemelere ve politika gerekliliklerine uyacak, hesabınızdaki tüm dijital varlıkların kaynaklarının yasallığını sağlayacak ve bu web sitesinin veya üçüncü tarafların hak ve çıkarlarına zarar veren yasa dışı veya diğer faaliyetlerde bulunmayacaksınız. Bu web sitesinde veya hizmetlerini kullanarak, bunlarla sınırlı olmamak üzere, yasa dışı, yasa dışı veya başkalarının haklarını ihlal eden bilgiler göndermek veya almak, piramit şemaları veya diğer zararlı bilgi veya açıklamalar göndermek veya almak, e-posta başlığını kullanmak veya taklit etmek. Bu web sitesinin bilgileri, bu web sitesinin izni olmadan vb.",
        content52: "4.2.2 Yasa ve yönetmeliklere uymalı, hesap numaranızı, giriş şifrenizi, fon şifrenizi, kayıt olurken size bağlı olan cep telefonu numaranızı ve cep telefonunuza gelen cep telefonu doğrulama kodunu doğru kullanmanız ve saklamanız gerekmektedir. Hesabınızın ve giriş şifrenizin, fon şifrenizin ve cep telefonu doğrulama kodunuzun kullanımına ilişkin her türlü işlem ve sonuçların sorumluluğu tamamen size aittir. Bu web sitesinin hesap numarasının, giriş şifresinin veya fon şifresinin veya doğrulama kodunun üçüncü bir tarafça izniniz olmadan kullanıldığını veya başka hesap güvenliği sorunları olduğunu tespit ettiğinizde, bu web sitesini derhal ve etkili bir şekilde bilgilendirmeli ve bu web sitesinin askıya alınmasını talep etmelisiniz. Bu web sitesinin hesabının hizmeti. Bu web sitesi, talebiniz üzerine makul bir süre içerisinde işlem yapma hakkına sahiptir, ancak bu web sitesi, işlem yapmadan önce meydana gelen sonuçlardan (karşılaştığınız kayıplar dahil ancak bunlarla sınırlı olmamak üzere) herhangi bir sorumluluk kabul etmez. Bu web sitesindeki hesabınızı, bu web sitesinin izni olmadan başkalarına bağışlayamaz, ödünç alamaz, kiralayamaz, devredemez veya başka bir şekilde elden çıkaramazsınız.",
        content53: "4.2.3 Bu web sitesinde hesabınız ve şifreniz altında gerçekleşen tüm faaliyetlerden (bilgi ifşası, bilgi yayınlama, çevrimiçi tıklama onayı veya çeşitli kural ve sözleşmelerin sunulması, sözleşmelerin çevrimiçi olarak yenilenmesi veya satın alma dahil ancak bunlarla sınırlı olmamak üzere) sorumlu olduğunuzu kabul edersiniz. hizmetler vb.)",
        content54: "4.2.4 Bu web sitesinde dijital varlık işlemleri gerçekleştirirken, dijital varlık işlemlerinin normal ilerlemesine kötü niyetle müdahale etmemeli veya işlemlerin sırasını bozmamalı, bunun normal işleyişine müdahale edecek herhangi bir teknik araç veya başka araçlar kullanmamalısınız; Web sitesi veya diğer kullanıcıların bu web sitesinin hizmetlerini kullanmasına müdahale etmeyin; Sahte gerçekler veya başka yollarla bu web sitesinin iyi niyetini kötü niyetle karalamayın;",
        content55: "4.2.5 Çevrimiçi işlemlerden dolayı diğer kullanıcılarla bir anlaşmazlığa düşerseniz, bu web sitesinden ilgili bilgilerin adli veya idari olmayan kanallar aracılığıyla sağlanmasını talep edemezsiniz.",
        content56: "4.2.6 Bu web sitesi tarafından sağlanan hizmetleri kullanımınız sırasında ödenecek her türlü verginin yanı sıra tüm donanım, yazılım, hizmet ve diğer masrafları yalnızca siz yargılayacak ve üstleneceksiniz.",
        content57: "4.2.7 Bu Sözleşmeye ve bu web sitesi tarafından zaman zaman yayınlanan ve güncellenen diğer hizmet şartlarına ve işletim kurallarına uyacaksınız ve bu web sitesi tarafından sağlanan hizmetlerin kullanımını istediğiniz zaman sonlandırma hakkına sahip olacaksınız.",
        content58: "4.3 Ürün Kuralları",
        content59: "4.3.1 İşlem bilgilerine göz atın",
        content60: "Bu web sitesindeki işlem bilgilerine göz attığınızda, fiyat, komisyon hacmi, işlem ücreti, alım veya satım talimatı dahil ancak bunlarla sınırlı olmamak üzere işlem bilgilerinde yer alan tüm içeriği dikkatlice okumalı ve ancak burada yer alan tüm içeriği tam olarak kabul ettikten sonra okumalısınız. işlem bilgileri İşlemler tek tuşla yapılabilir.",
        content61: "4.3.2 İşlem ayrıntılarını görüntüleme",
        content62: "İlgili işlem kayıtlarını hesabınız üzerinden görüntüleyebilirsiniz.",
        content63: "5. Bu web sitesinin hakları ve yükümlülükleri",
        content64: "5.1 Bu sözleşmede belirtilen kayıt niteliklerine sahip değilseniz, bu web sitesi kaydınızı reddetme hakkına sahiptir. Halihazırda kayıt yaptırmış olanlar için bu web sitesi üyelik hesabınızı iptal etme hakkına sahiptir. veya yetkili acentenizin sorumluluk hakları. Aynı zamanda bu web sitesi, başka koşullar altında kaydınızı kabul edip etmemeye karar verme hakkını saklı tutar.",
        content65: "5.2 Bu web sitesinin kendi takdirine göre, bu web sitesi sizin veya ilgili hesap kullanıcılarınızın yüksek riskli yatırımlar için uygun olmadığını tespit ederse, hesabınızı ve ilgili tüm hesapların kullanımını askıya alma veya sonlandırma hakkına sahiptir.",
        content66: "5.3 Bu web sitesi, hesap kullanıcısının, hesabı ilk kaydeden kişi olmadığını tespit ederse, hesabın kullanımını askıya alma veya sonlandırma hakkına sahiptir.",
        content67: "5.4 Bu web sitesi, teknik testler, manuel örnekleme ve diğer test yöntemleri yoluyla sağladığınız bilgilerin yanlış, gerçek dışı, geçersiz veya eksik olduğundan makul ölçüde şüphelendiğinde, bilgileri düzeltmeniz veya güncellemeniz veya hükmü askıya almanız veya feshetmeniz için size bildirimde bulunma hakkına sahiptir. Bu web sitesinin size sunduğu hizmetler.",
        content68: "5.5 Bu web sitesi, bu web sitesinde görüntülenen herhangi bir bilgiyi bariz hataların tespit edilmesi durumunda düzeltme hakkına sahiptir.",
        content69: "5.6 Bu web sitesi, bu web sitesinin hizmetlerini herhangi bir zamanda değiştirme, askıya alma veya sonlandırma hakkını saklı tutar. Bu web sitesinin, hizmetleri değiştirme veya sonlandırma hakkını kullanmak için size önceden bildirimde bulunmasına gerek yoktur. Bu web sitesinden fesih, bu web sitesinden fesih duyurusunun yayınlandığı tarihte geçerli olacaktır.",
        content70: "5.7 Bu web sitesi, bu web sitesinin normal işleyişini sağlamak, gerekli ve güvenilir ticaret ortamını ve alım satım hizmetlerini sağlamak ve dijital varlık işlemlerinin düzenini korumak için gerekli teknik araçları ve yönetim önlemlerini alacaktır.",
        content71: "5.8 Üyelik hesabınızı ve şifrenizi kullanarak bu siteye bir yıl üst üste giriş yapmazsanız, bu site hesabınızı iptal etme hakkına sahiptir. Hesap iptal edildikten sonra bu site, ilgili üye adını kayıt ve kullanım için diğer kullanıcılara açma hakkına sahiptir.",
        content72: "5.9 Bu web sitesi, teknoloji yatırımını güçlendirerek, güvenlik önlemlerini ve diğer önlemleri iyileştirerek dijital varlıklarınızın güvenliğini sağlar ve hesabınızda öngörülebilir güvenlik riskleri oluştuğunda sizi önceden bilgilendirir.",
        content73: "5.10 Bu web sitesi, bu web sitesinde yer alan, yasa ve düzenlemelere veya bu web sitesinin düzenlemelerine uymayan her türlü içerik bilgisini herhangi bir zamanda silme hakkına sahiptir. Bu web sitesinin bu hakları kullanmak için size önceden bildirimde bulunmasına gerek yoktur.",
        content74: "5.11 Bu web sitesi, egemen ülkenizin veya bölgenizin yasalarına, düzenlemelerine, kurallarına, emirlerine ve diğer düzenleyici gerekliliklerine uygun olarak daha fazla bilgi veya materyal sağlamanızı talep etme ve yerel düzenleyici gerekliliklere uymak için makul önlemleri alma hakkına sahiptir. işbirliği yapmakla yükümlüsünüz; bu web sitesi, egemen ülkenizin veya bölgenizin kanunları, düzenlemeleri, kuralları, emirleri ve diğer spesifikasyonlarına uygun olarak bu web sitesinin hizmetlerinin bir kısmını veya tamamını size açmayı askıya alma veya kalıcı olarak durdurma hakkına sahiptir.",
        content75: "5.12 Bu web sitesi, tamamen kendi takdirine bağlı olarak, yedi iş günü önceden bildirimde bulunarak hesabınızı kapatma hakkını saklı tutar. Bu nedenle emirleri iptal etmek ve pozisyonları kapatmak için yedi iş gününüz var. Süre dolduğunda emri iptal etmediyseniz ve pozisyonu kapatmadıysanız, emri zorla iptal edip pozisyonu kapatacağız ve hesabınızda kalan dijital varlıkları size iade edeceğiz.",
        content76: "5.13 Tüccarların haklarını ve çıkarlarını korumak amacıyla, bu web sitesi özel koşullar altında (sistem arızası, ağ arızası, aşırı piyasa koşulları vb. gibi) çevrimiçi ürünlerde ayarlamalar yapma hakkına sahiptir, örneğin: erken teslimat sözleşmeleri ve uzlaşma, erken teslim ve uzlaşmaya ilişkin sözleşme türleri, Sözleşme süresi ile uzlaşma ve teslim bedeli bu sitede duyurulmaya tabidir.",
        content77: "6. Tazminat",
        content78: "6.1 Hiçbir durumda, doğrudan zararlara ilişkin olarak size karşı sorumluluğumuz, üç (3) aylık bir süre boyunca bu web sitesini kullanmanız karşılığında sizden tahsil ettiğimiz toplam hizmet ücretlerini aşmayacaktır.",
        content79: "6.2 Bu Sözleşmeyi veya diğer yasa ve düzenlemeleri ihlal etmeniz durumunda, bize en az 2 milyon ABD Doları tutarında tazminat ödemeniz ve oluşan tüm masrafları (avukatlık ücretleri vb. dahil) karşılamanız gerekir. bunu telafi etmeli.",
        content80: "7. İhtiyati tedbir isteme hakkı",
        content81: "Biz ve siz, sözleşme ihlalinize veya olası sözleşme ihlaline ilişkin genel hukuk çözümlerinin, uğradığımız tüm kayıpları telafi etmek için yeterli olmayabileceğini kabul ediyoruz; bu nedenle, genel hukuk veya hakkaniyetin izin verdiği ölçüde, Sözleşmenin ihlali veya sözleşmenin olası ihlali durumunda diğer tüm telafiler.",
        content82: "8. Sorumluluğun Sınırlandırılması ve Sorumluluk Reddi",
        content83: "8.1 Hiçbir koşulda aşağıdaki konulardan sorumlu olmayacağımızı anlıyor ve kabul ediyorsunuz:",
        content84: "8.1.1 Gelir kaybı;",
        content85: "8.1.2 Ticari karlar veya sözleşme zararları;",
        content86: "8.1.3 İş kesintisinden kaynaklanan kayıplar;",
        content87: "8.1.4 Beklenen parasal tasarruf kaybı;",
        content88: "8.1.5 Bilgi sorunlarından kaynaklanan kayıplar;",
        content89: "8.1.6 Fırsat, iyi niyet veya itibar kaybı;",
        content90: "8.1.7 Verilerin hasar görmesi veya kaybı;",
        content91: "8.1.8 İkame ürün veya hizmet satın alma maliyeti;",
        content92: "8.1.9 Haksız fiil (ihmal dahil), sözleşmenin ihlali veya başka herhangi bir nedenden kaynaklanan her türlü dolaylı, özel veya tesadüfi kayıp veya hasar, bu tür kayıp veya hasarlar tarafımızca makul bir şekilde öngörülebilir olsun veya olmasın; avans Böyle bir kayıp veya hasar olasılığı mevcuttur.",
        content93: "8.1.1'den 8.1.9'a kadar olan maddeler birbirinden bağımsızdır.",
        content94: "8.2 Aşağıdaki durumlardan herhangi biri nedeniyle size verilen zararlardan sorumlu olmadığımızı anlıyor ve kabul ediyorsunuz:",
        content95: "8.2.1 Belirli işlemlerinizde büyük yasa ihlalleri veya sözleşme ihlalleri olabilir.",
        content96: "8.2.2 Bu web sitesindeki davranışınızın yasa dışı veya ahlaka aykırı olduğundan şüpheleniliyor.",
        content97: "8.2.3 Bu web sitesinin hizmetleri aracılığıyla herhangi bir veri, bilgi satın alınması veya elde edilmesi veya işlem yapılmasından veya bunların yerine geçen eylemlerden kaynaklanan masraflar ve kayıplar.",
        content98: "8.2.4 Bu web sitesinin hizmetlerini yanlış anlamanız.",
        content99: "8.2.5 Bu web sitesi tarafından sağlanan hizmetlerle ilgili bizden kaynaklanmayan diğer kayıplar.",
        content100: "8.3 Bilgi ağı ekipmanı bakımı, bilgi ağı bağlantı arızası, bilgisayar, iletişim veya diğer sistem arızaları, elektrik kesintisi, hava koşulları, kazalar, grevler, iş anlaşmazlıkları, ayaklanmalar, ayaklanmalar, ayaklanmalar, yetersiz üretkenlik veya üretimden kaynaklanan her türlü hasardan biz sorumluyuz. Malzemeler, yangınlar, sel baskınları, fırtınalar, patlamalar, savaşlar, banka veya diğer ortak nedenleri, dijital varlık piyasasının çöküşü, hükümet eylemleri, adli veya idari kurumların emirleri, kontrolümüz dışında olan veya kontrol etme yeteneğimizin olmadığı diğer eylemler veya üçüncü taraf nedenleri Hizmetin sağlanamaması veya gecikmesi veya kayıplarınız konusunda herhangi bir sorumluluk kabul etmiyoruz.",
        content101: "8.4 Bu web sitesinde yer alan tüm bilgilerin, programların, metinlerin vb. tamamen güvenli olduğunu ve herhangi bir virüs, Truva atı ve diğer kötü amaçlı programların müdahalesinden ve tahribatından arınmış olduğunu garanti edemeyiz. Bu nedenle, oturum açabilir ve bu sitedeki tüm hizmetleri kullanabilirsiniz. Web sitesi veya indirilen programları, bilgileri, verileri vb. indirmek ve kullanmak tamamen kişisel kararlarınızdır ve riskleri ve olası kayıpları kendi sorumluluğunuzdadır.",
        content102: "8.5 Bu web sitesine bağlantı verilen herhangi bir üçüncü taraf web sitesine ait herhangi bir bilgi, ürün ve hizmet veya üçüncü kişiler tarafından sağlanan herhangi bir hizmet ve bilgiyi kullanmanız durumunda bize ait olmayan herhangi bir içerik türü hakkında herhangi bir garanti veya taahhütte bulunmuyoruz. taraf web siteleri ve ürünleri sizin kişisel kararlarınızdır ve bundan doğacak tüm sorumluluk size aittir.",
        content103: "8.6 Bu web sitesi tarafından sağlanan hizmetlerin uygunluğu, hata veya eksiklik olmaması, sürekliliği, doğruluğu, güvenilirliği ve belirli bir amaca uygunluğu dahil ancak bunlarla sınırlı olmamak üzere, bu web sitesindeki hizmetleri kullanımınıza ilişkin açık veya zımni hiçbir garanti vermiyoruz. belirli amaç. Aynı zamanda bu web sitesi tarafından sağlanan hizmetlerde yer alan teknoloji ve bilgilerin geçerliliği, doğruluğu, doğruluğu, güvenilirliği, kalitesi, istikrarı, eksiksizliği ve güncelliği konusunda herhangi bir taahhüt veya garanti vermiyoruz. Giriş yapmak veya bu web sitesi tarafından sağlanan hizmetleri kullanmak sizin kişisel kararınızdır ve kendi risklerinizi ve olası kayıplarınızı üstlenirsiniz. Dijital varlıkların piyasası, değeri ve fiyatı ile ilgili herhangi bir açık veya zımni garanti vermiyoruz. Dijital varlık piyasasının istikrarsız olduğunu anlıyor ve anlıyorsunuz. Fiyatlar ve değerler herhangi bir zamanda önemli ölçüde dalgalanabilir veya çökebilir. kişisel özgürlüğünüz. Riski ve olası kayıpları kendiniz seçer ve karar verirsiniz.",
        content104: "8.7 Bu Sözleşmede belirtilen garantilerimiz ve taahhütlerimiz, bu Sözleşme ve bu web sitesi tarafından sağlanan hizmetlerle ilgili olarak tarafımızdan verilen tek garanti ve beyanlardır ve yazılı veya sözlü olarak başka herhangi bir şekilde ve şekilde ortaya çıkan her türlü garanti ve taahhütlerin yerine geçer. , açık veya zımni. Tüm bu garantiler ve beyanlar yalnızca bizim kendi taahhütlerimizi ve garantilerimizi temsil eder ve herhangi bir üçüncü tarafın bu Sözleşmedeki garanti ve taahhütlere uyacağını garanti etmez.",
        content105: "8.8 Bu Sözleşmede belirtilmeyen, zararlara ilişkin sorumluluğumuzu yürürlükteki kanunların izin verdiği ölçüde sınırlamak, hariç tutmak veya dengelemek için herhangi bir haktan feragat etmiyoruz.",
        content106: "c8.8 Bu Sözleşmede belirtilmeyen, zararlara ilişkin sorumluluğumuz yürürlükteki kanunların izin verme ölçüsü sınırlamak, çalışma veya bağlantı için herhangi bir haktan feragat etmiyoruz.",
        content107: "9. Sözleşmenin Feshi",
        content108: "9.1 Bu web sitesi, bu sözleşmeye uygun olarak bu web sitesinin tüm hizmetlerini sona erdirme hakkına sahiptir. Bu sözleşme, bu web sitesinin tüm hizmetlerinin sona erdirildiği tarihte sona erecektir.",
        content109: "9.2 Bu Sözleşmenin feshinden sonra, bu web sitesinin kendisine herhangi bir hizmet sağlamaya devam etmesini veya bu web sitesinin orijinal web sitesindeki herhangi bir bilgiyi saklamasını veya size ifşa etmesini zorunlu kılmak dahil ancak bunlarla sınırlı olmamak üzere başka herhangi bir yükümlülüğü yerine getirmesini talep etme hakkınız yoktur. Hesabın size veya üçüncü tarafa okumadığı veya göndermediği herhangi bir bilgiyi iletmesi.",
        content110: "9.3 Bu anlaşmanın feshi, gözlemci tarafın ihlal eden tarafa karşı diğer sorumlulukları üstlenme gerekliliğini etkilemeyecektir.",
        content111: "10. Fikri mülkiyet hakları",
        content112: "10.1 Bu web sitesinde yer alan tüm fikri başarılar, web sitesi logolarını, veritabanlarını, web sitesi tasarımlarını, metin ve grafikleri, yazılımları, fotoğrafları, videoları, müzikleri, sesleri ve bunların kombinasyonlarını, yazılım derlemelerini, ilgili kaynak kodlarını ve yazılımları (küçük Dosyaların) ve uygulamaların ve komut dosyalarının fikri mülkiyet hakları bu web sitesine aittir. Yukarıdaki materyalleri veya içerikleri ticari amaçlarla çoğaltamaz, değiştiremez, kopyalayamaz, gönderemez veya kullanamazsınız.",
        content113: "10.2 Bu web sitesinin adının içerdiği tüm haklar (iyi niyet ve ticari markalar, logolar dahil ancak bunlarla sınırlı olmamak üzere) şirkete aittir.",
        content114: "10.3 Bu sözleşmeyi kabul etmeniz, bu web sitesinde yayınladığınız her türlü bilginin telif hakkını, bunlarla sınırlı olmamak üzere, aktarma girişiminiz olarak kabul edilecektir: çoğaltma hakları, dağıtım hakları, kiralama hakları, sergileme hakları, performans hakları, gösterim hakları Telif hakkı sahibinin sahip olması gereken yayın hakları, Bilgi ağı yayma hakları, filme alma hakları, uyarlama hakları, çeviri hakları, derleme hakları ve diğer devredilebilir haklar münhasıran bu web sitesine ücretsiz olarak devredilmektedir. Herhangi bir ihlale karşı bağımsız dava açabilir ve tam tazminat alabilirsiniz. Bu sözleşme, içerik ister bu sözleşmenin imzalanmasından önce ister bu sözleşmenin imzalanmasından sonra oluşturulmuş olsun, bu web sitesinde yayınladığınız telif hakkı yasasıyla korunan her türlü çalışma içeriği için geçerlidir.",
        content115: "10.4 Bu web sitesinin hizmetlerini kullanırken bu web sitesinin veya başkalarının fikri mülkiyet haklarını yasa dışı olarak kullanmayacak veya elden çıkarmayacaksınız. Bu web sitesinde yayınlanan bilgileri hiçbir şekilde başka web sitelerine (ve medyaya) yayınlayamaz veya kullanmaları için yetki veremezsiniz.",
        content116: "11. Bilgi koruması",
        content117: 'Bu sitede ayrıca yayınlanan "Gizlilik Koşulları" hükümleri geçerli olacaktır.',
        content118: "12. Hesaplama",
        content119: "Tüm işlem hesaplama sonuçları tarafımızca doğrulanmış ve tüm hesaplama yöntemleri sitede yayınlanmıştır ancak site kullanımının kesintisiz veya hatasız olmayacağını garanti edemeyiz.",
        content120: "13. İhracat Kontrolü",
        content121: "İlgili yasalara göre, bu web sitesindeki herhangi bir materyali (yazılım dahil) ihraç etmenize, yeniden ihraç etmenize, ithal etmenize veya aktarmanıza izin verilmediğini anlıyor ve kabul ediyorsunuz; dolayısıyla, bunlardan herhangi birini aktif olarak uygulamayacağınızı, yardımcı olmayacağınızı veya katılmayacağınızı garanti ediyorsunuz. Yukarıda belirtilen yasa ve yönetmelikleri ihlal eden ihracatlar veya ilgili faaliyetler. Transfer veya yürürlükteki yasa ve düzenlemelerin diğer ihlalleri; böyle bir durum tespit edilirse, bunu bize aktif olarak zamanında bildirmeli ve bu durumu ele almamıza yardımcı olmalısınız.",
        content122: "14. Transfer",
        content123: "İşbu Sözleşmede öngörülen hak ve yükümlülükler aynı zamanda bu hak ve yükümlülüklerden yararlanan tarafların devralanlarını, mirasçılarını, vasilerini ve idarecilerini de bağlar. Bunu bizim iznimiz olmadan herhangi bir üçüncü tarafa devredemezsiniz, ancak bu Sözleşmedeki haklarımızı ve yükümlülüklerimizi herhangi bir zamanda herhangi bir üçüncü tarafa devredebilir ve size bildirimde bulunabiliriz.",
        content124: "15. Bölünebilme",
        content125: "Bu Sözleşmenin herhangi bir hükmünün yetkili herhangi bir mahkeme tarafından uygulanamaz, geçersiz veya yasa dışı olduğuna karar verilmesi, bu Sözleşmenin geri kalan hükümlerinin geçerliliğini etkilemeyecektir.",
        content126: "16. Acentelik dışı ilişki",
        content127: "Bu Sözleşmedeki hiçbir şey, bu Sözleşmede aksi belirtilmediği sürece, bizi sizin temsilciniz, yediemininiz veya başka bir temsilciniz olarak yarattığı, ima ettiği veya başka bir şekilde teşkil ettiği kabul edilmeyecektir.",
        content128: "17. Çekimserlik",
        content129: "Herhangi bir tarafın sözleşmenin ihlaline ilişkin sorumluluğundan veya bu Sözleşmede belirtilen diğer yükümlülüklerden tarafımızdan veya tarafınızdan feragat edilmesi, sözleşmenin ihlaline ilişkin diğer yükümlülüklerden feragat olarak kabul edilmeyecek veya yorumlanmayacaktır; bu haklardan feragat veya vazgeçme çareleri olarak yorumlanabilir.",
        content130: "18. Başlık",
        content131: "Tüm başlıklar yalnızca sözleşmenin ifadesine kolaylık sağlamak içindir ve sözleşme koşullarının içeriğini veya kapsamını genişletmek veya sınırlamak için kullanılmaz.",
        content132: "19. Geçerli Kanun",
        content133: "19.1 Bir anlaşmazlığı dile getirmeden veya hak talebinde bulunmadan önce, anlaşmazlığı gayri resmi olarak çözmek için bu web sitesiyle e-posta yoluyla iletişime geçmeyi kabul ve beyan edersiniz. Anlaşmazlığınızı mümkün olan en kısa sürede dahili olarak çözmeye çalışacağız; her iki taraf da anlaşmazlığı iyi niyetli müzakereler yoluyla çözmeyi kabul eder; Tartışmanın içeriği gizli olacak ve herhangi bir yasal işlemde delil olarak kullanılmaktan geçerli kurallarla korunacaktır).",
        content134: '19.2 Bu Koşulların varlığı, geçerliliği, yorumlanması, uygulanması, ihlali veya feshi de dahil olmak üzere herhangi bir anlaşmazlık, ihtilaf, anlaşmazlık veya iddia durumunda ya da bu Koşullardan kaynaklanan veya bunlarla bağlantılı herhangi bir anlaşmazlığın ("Anlaşmazlık) "), yukarıda belirtilen taraflar, anlaşmazlığı, o sırada yürürlükte olan "ICC Tahkim Kuralları" uyarınca Uluslararası Ticaret Odası Uluslararası Tahkim Divanı na ("ICC Uluslararası Tahkim Divanı") sunacaklardır. Tahkim yeri Belize olacak ve tahkime uygulanacak hukuk Uluslararası Ticaret Odası Tahkim Kurallarına göre belirlenecektir. Taraflarca aksi kararlaştırılmadıkça, ICC Tahkim Kurallarına göre yalnızca bir hakem atanacaktır. Hakem, tahkim anlaşmasının varlığına, kapsamına veya geçerliliğine itiraz veya herhangi bir iddia veya karşı iddianın tahkime elverişliliği dahil ancak bunlarla sınırlı olmamak üzere, kendi yargı yetkisine göre karar verme konusunda münhasır yetkiye sahip olacaktır. Her türlü tahkim İngilizce olarak yürütülecektir. Tahkimin nasıl yürütüldüğüne bakılmaksızın hakem, kararı ve varsa kararın dayandığı temel sonuçları açıklamaya makul ve yeterli olan yazılı bir karar verecektir. Tahkim kararı taraflar için nihai ve bağlayıcı olacak ve yetkili herhangi bir mahkemede icra edilebilecektir.',
        content135: "20. Anlaşmanın yürürlüğe girmesi ve yorumlanması",
        content136: "20.1 Bu sözleşme, kaydolmayı kabul etmek, kayıt işlemini tamamlamak ve bu web sitesi için bir hesap ve şifre almak üzere bu web sitesinin kayıt sayfasına tıkladığınızda yürürlüğe girer ve hem bu web sitesi hem de sizin için bağlayıcıdır.",
        content137: "20.2 Bu sözleşmenin nihai yorum hakkı bu web sitesine aittir.",
        content138: "Müşterinizi ve kara para aklamayı önleme politikanızı tanıyın",
        content139: "1. Giriş",
        content140: '1.1 "Müşterinizi Tanıyın" ve kara para aklamanın önlenmesi ile ilgili yasa ve düzenlemelere titizlikle uyacağımıza ve "Müşterinizi Tanıyın ve Kara Para Aklamayı Önleme Politikası"nı kasıtlı olarak ihlal etmeyeceğimize söz veriyoruz. Makul kontrolümüz kapsamında, size güvenli hizmetler sunmak ve suç şüphelilerinin kara para aklama davranışlarından kaynaklanan kayıplardan sizi mümkün olduğunca korumak için gerekli önlemleri ve teknolojileri alacağız.',
        content141: "1.2 Müşterinizi Tanıyın ve Kara Para Aklamayı Önleme Politikamız, bağlı olduğunuz farklı yasal yetki alanlarının Müşterinizi Tanıyın ve Kara Para Aklamayı Önleme politikalarını içeren kapsamlı bir uluslararası politika sistemidir.",
        content142: "2. Müşterinizi tanıyın ve kara para aklamayı önleme politikaları aşağıdaki gibidir:",
        content143: "2.1 Müşterini tanı ve kara para aklamayı önleme politikalarını yürürlüğe koy ve bunları ilgili yasa ve yönetmeliklerde öngörülen standartları karşılayacak şekilde zaman zaman güncelle;",
        content144: "2.2 Bu web sitesinin işletilmesine ilişkin bazı yol gösterici ilke ve kuralların yayımlanması ve güncellenmesi, çalışanlarımızın bu ilke ve kuralların rehberliğinde hizmet sunması;",
        content145: "2.3 Kimliğin kesin yöntemlerle doğrulanması ve kara para aklamanın önlenmesi çalışmalarından sorumlu profesyonel bir ekip oluşturulmasının düzenlenmesi gibi işlemlerin dahili izlenmesi ve kontrolüne yönelik prosedürler tasarlayın ve tamamlayın;",
        content146: "2.4 Müşterilerin durum tespitini ve sürekli denetimini gerçekleştirmek için risk önleme yöntemlerini kullanın;",
        content147: "2.5 Gerçekleşen işlemleri gözden geçirin ve düzenli olarak kontrol edin;",
        content148: "2.6 Şüpheli işlemleri yetkili makamlara bildirin;",
        content149: "2.7 Kimlik belgeleri, adres kanıt belgeleri ve işlem kayıtları en az altı yıl süreyle saklanacak ve size herhangi bir bildirimde bulunulmaksızın düzenleyici makamlara sunulacaktır.",
        content150: "3. Kimlik bilgileri ve doğrulama",
        content151: "3.1 Kimlik bilgileri",
        content152: "3.1.1 Farklı yetki alanlarındaki ve farklı kuruluş türlerindeki farklı düzenlemelere göre, topladığımız bilgilerin içeriği tutarsız olabilir. Prensip olarak, kayıtlı kişilerden aşağıdaki bilgiler toplanacaktır:",
        content153: "Temel kişisel bilgiler: adınız, adresiniz (ve farklıysa daimi adresiniz), doğum tarihiniz ve uyruğunuz ve mevcut diğer bilgiler. Kimlik doğrulaması, pasaport, kimlik kartı veya farklı yargı mercileri tarafından gerekli görülen ve tetiklenen diğer kimlik belgeleri gibi resmi veya benzeri makamlar tarafından verilen belgelere dayanmalıdır. Verdiğiniz adres, yolcu taşıma biletinin veya faiz oranı notunun kontrol edilmesi veya seçmen kütüğünün kontrol edilmesi gibi uygun yöntemler kullanılarak doğrulanacaktır.",
        content154: "Geçerli Fotoğraf: Kayıt olmadan önce kimliğinizi göğsünüze dayadığınız bir fotoğrafınızı sunmalısınız;",
        content155: "İletişim bilgileri: Telefon/cep telefonu numarası ve/veya geçerli e-posta adresi.",
        content156: "3.1.2 Bir şirket veya başka bir tüzel kişilik iseniz, sizi veya emanet hesabının nihai yararlanıcısını tanımlamak için aşağıdaki bilgileri toplayacağız.",
        content157: "Şirket tescil ve tescil belgesi; şirketin ana sözleşmesinin ve protokolünün kopyaları; şirketin bu web sitesindeki hesabın açılmasını ve yürütülmesini belirleyen, şirketin özsermaye yapısı ve mülkiyet tanımına ilişkin ayrıntılı sertifika materyalleri; Gerektiğinde sağlanması gereken yöneticiler, ana hissedarın ve bu sitedeki hesabı imzalamaya yetkili kişinin kimlik belgeleri; şirketin ana iş adresi, şirketin posta adresinden farklı ise posta adresini belirtiniz. Bir şirketin yerel adresi ana işletme adresiyle tutarsızsa, bu şirket daha yüksek riskli bir müşteri olarak kabul edilir ve ek ek belgeler sunması gerekir.",
        content158: "Farklı yetki alanlarındaki ve farklı kuruluş türlerindeki farklı düzenlemelere bağlı olarak, gerekli gördüğümüz belgelerin yanı sıra yetkililer tarafından verilen başka sertifikalar ve belgeler de talep ediyoruz.",
        content159: "3.1.3 Kimlik bilgilerinin yalnızca İngilizce veya Çince versiyonlarını kabul ediyoruz. Aksi takdirde, lütfen kimlik bilgilerinizi İngilizceye tercüme edip notere tasdik ettirin.",
        content160: "3.2 Onaylama ve doğrulama",
        content161: "3.2.1 Kimlik belgenizin tüm sayfa içeriğini sağlamanızı talep ediyoruz.",
        content162: "3.2.2 Kimlik belgenizi göğsünüze yaslamış bir fotoğrafınızı istiyoruz.",
        content163: "3.2.3 Destekleyici belgelerin kopyaları genellikle orijinal makbuzlarla karşılaştırılarak kontrol edilmelidir. Bununla birlikte, uygun ve güvenilir bir onaylayıcının, kopyanın orijinal belgenin doğru ve eksiksiz bir kopyası olduğunu tasdik etmesi halinde, kopya kabul edilebilir. Bu tür onaylayıcılar arasında büyükelçiler, adli komiserler, yerel hakimler vb. yer alır.",
        content164: "3.2.4 Nihai yararlanıcının belirlenmesi ve hesap kontrolüne ilişkin gereksinimler, doğrudan müşterinin nihai olarak hangi kişilerin sahibi olduğunu veya kontrol ettiğini belirlemek ve/veya devam eden işlemlerin başkaları adına yürütüldüğünü tespit etmektir. Bir işletme ise, büyük hissedarların (örneğin %10 veya daha fazla oy hakkına sahip olanlar) kimlikleri doğrulanmalıdır. Genel olarak %25 oranında hisseye sahip olmak normal riskli kabul edilecek olup, hissedarın kimliğinin doğrulanması gerekmektedir; %10 oranında hisseye sahip olmak veya daha fazla oy hakkına veya hisseye sahip olmak yüksek riskli durum olarak değerlendirilmektedir, ve hissedarın kimliğinin doğrulanması gerekmektedir.",
        content165: "4. İşlemleri izleyin",
        content166: "4.1 Maksimum günlük işlem ve para çekme limitlerini zaman zaman güvenlik ve fiili işlem koşullarına göre belirliyor ve ayarlıyoruz;",
        content167: "4.2 Kayıtlı bir kullanıcıda işlemlerin sık sık gerçekleşmesi veya makul olmayan durumların mevcut olması durumunda, profesyonel ekibimiz bunların şüpheli olup olmadığına değerlendirecek ve karar verecektir;",
        content168: "4.3 Kendi kanaatimize dayanarak bir işlemin şüpheli olduğunu tespit ettiğimiz takdirde, işlemin askıya alınması, işlemin reddedilmesi gibi kısıtlayıcı tedbirler alabilir, hatta mümkün olan en kısa sürede işlemi tersine çevirerek yetkili makama bildirebiliriz, ama size haber vermeyeceğim;",
        content169: "4.4 Uluslararası kara para aklamayla mücadele standartlarına uymayan yargı bölgelerindeki kişilerin veya siyasi nüfuz sahibi kişiler olarak kabul edilebilecek kişilerin kayıt başvurularını reddetme hakkımızı saklı tutuyoruz. herhangi bir zamanda karar verebiliriz, ancak bunu size karşı herhangi bir yükümlülüğü veya sorumluluğu ihlal etmeden yaparız.",
    },
    register: {
        formTitle1: "yaratmak",
        formTitle2: "E-postanızı veya cep telefonu numaranızı kullanarak kaydolun",
        formTitle3: "Hesap",
        field1: "Doğrulama kodu",
        field2: "Elde etmek",
        field3: "Şifreyi Onayla",
        field4: "Davet Kodu",
        field41: "(isteğe bağlı)",
        field5: "okudum ve kabul ediyorum",
        field6: "Kullanım Şartları",
        placeholder1: "lütfen doğrulama kodunu girin",
        content7: "lütfen doğrulama kodunu girin",
        content12: "Lütfen telefon numarasını girin",
        content13: "Lütfen şifre giriniz",
        content14: "lütfen e-posta adresinizi girin",
        content18: "Lütfen şifreyi tekrar girin",
        content19: "Lütfen hizmet koşullarını kabul ediniz",
        content20: "Lütfen doğru doğrulama kodunu girin",
        content23: "Doğrulama kodunu hâlâ almadınız？",
        content24: "Lütfen doğrulama kodunu aşağıya girin",
        registerSuccess: "başarılı Kayıt",
        content25: "Şifreler tutarsız",
        content26: "Lütfen doğru e-posta adresini girin",
        content27: "Şifre sıfırlama başarılı"
    },
    download: {
        content1: "İstediğiniz zaman, istediğiniz yerde ticaret yapın",
        content2: "Birden fazla terminalle mükemmel uyumlu olup, çeşitli senaryoların işlem ihtiyaçlarını her zaman karşılar",
        content3: "İstediğiniz zaman gerçek zamanlı piyasa koşullarını kontrol edin",
        content4: "BTC, ETH, XRP ve diğer dijital varlıkları kolayca satın alın ve satın",
        content5: "Önemsediğiniz kripto para birimleri için fiyat değişikliklerine ilişkin erken uyarı alın",
        content6: "Bitcoin spot kredisini, vadeli işlem kredisini ve opsiyon fiyatlarını görüntüleyin",
        content7: "Çeşitli borsalardaki kripto para birimi fiyatlarını karşılaştırın",
        title: "indirmek",
        install: "yükleme notları",
        one: "1. Açıklama dosyalarının indirilmesine izin ver",
        two: "2. Açıklama dosyası indirildi",
        three: "3. Telefonunuzda [Ayarlar]'a girin ve [Genel]'i bulun",
        four: "4. Açıklama dosyaları ve cihaz yöneticisi",
        five: "5. Açıklama dosyasına tıklayın",
    },
    identity: {
        noAuthentication: {
            title: "Genç Sertifikası",
            pleaseSelectYourCountry: "Lütfen ülkenizi seçiniz",
            name: "İsim",
            enterName: "Lütfen adınızı giriniz",
            city: "şehir",
            enterCity: "Lütfen şehrinizi girin",
            IDNumber: "kimlik Numarası",
            enterIDNumber: "Lütfen kimlik numaranızı giriniz",
        },
        noAdvancedAuthentication: {
            title: "Gelişmiş sertifikasyon",
            infoCountry: "ülke / bölge",
            infoName: "İsim",
            infoNumber: "kimlik Numarası",
            infoAddress: "adres",
        },
        text: "sertifikalı değil",
        recertification: "yeniden sertifikalandırma",
        certification: "Sertifikasyon",
        authenticationInProgress: "doğrulandı",
        inAuthentication: "Sertifikasyon devam ediyor",
        uploadTheFrontOfTheDocument: "Kimliğin ön yüzünü yükleyin",
        uploadTheReverseSideOfTheDocument: "Sertifikanın arka tarafını yükleyin",
        uploadAPhotoOfAHandHeldID: "Kimlik kartınızın elinizdeki fotoğrafını yükleyin",
        pleaseUpload: "Lütfen ",
        pleaseCompleteOrdinaryCertificationFirst: "Birincil sertifika incelemesinin başarısını beklemeniz gerekiyor",
        refused: "Reddedilmiş",
        reason: "sebep",
        succeeded: "Başarılı",
        uploading: "yükleniyor",
        newTitle: "Kimliğinizin verildiği ülkeyi seçin",
        pleaseOrdinaryCertificationFirst: "Lütfen önce birincil sertifikasyon işlemini gerçekleştirin",
        text1: "Avatarı değiştir",
        text2: "Takma ad",
        text3: "hesap",
        text4: "Takma ad değişikliği",
        text5: "yeni takma ad",
        text6: "Yeni takma adınızı girin",
        text7: "eski Şifre",
        text8: "Eski şifrenizi girin",
        text9: "Yeni Şifre",
        text10: "Yeni şifrenizi giriniz",
        text11: "Şifreyi Onayla",

        text12: "Yeni şifrenizi tekrar girin",
        text13: "yeni posta kutusu",
        text14: "Doğrulama kodu",
        text15: "lütfen doğrulama kodunu girin",
        text16: "Senin cep telefonu numaran",
        text17: "Yeni cep telefonu numarası",
        text18: "lütfen telefon numaranızı girin",
        text19: "Cep telefonu doğrulama kodu",
        text20: "Genç Sertifikası",
        text21: "Lütfen kimlik bilgilerinizi doğrulayın",
        text22: "Ülke",
        text23: "Şehir",
        text24: "Şehrinizi girin",
        text25: "Adınızı giriniz",
        text26: "Kimlik numaranız",
        text27: "Kimlik numaranızı girin",
        text28: "Kimlik kartı ön fotoğrafı",
        text29: "Belgelerinizi yüklemek için tıklayın",
        text30: "yeniden yükle",
        text31: "yüklemek",
        text32: "Kimlik fotoğrafı tutma",
        text33: "Kimlik fotoğrafınızı yüklemek için tıklayın",
        text34: "Google Authenticator'ı bağladınız",
        text35: "anahtar",
        text36: "Lütfen Google doğrulama kodunu girin",
        text37: "Göndermek",
        text38: "Boş olamaz",
        text39: "E-posta formatı yanlış",
        text40: "İki şifre tutarsız",
        text41: "Başarıyla kopyalandı",
        text42: "Kopyalama başarısız oldu",
        text43: "Avatar değişikliği",
        text44: "şifre değiştir",
        text45: "E-posta bağlama",
        text46: "E-postayı değiştir",
        text47: "Cep telefonu bağlama",
        text48: "Cep telefonunu değiştir",
        text49: "Google doğrulaması",
        text50: "Google Authenticator'ı bağlayın",
        text51: "Google doğrulama kodunu girin",
        text52: "kaydetmek",
        text53: "Gönderimi Onayla",
        text54: "Tekrar düzeltme yapmak",
        text55: "bağlayıcı",
        text56: "Google doğrulamasını kapat",
        text57: "Sonraki adım",
        text58: "Sona ermek",
        text59: "Google doğrulaması bağlı",
        text60: "Google doğrulaması ayrıştırıldı",
        text61: "Birincil sertifika gönderildi",
        text62: "Gelişmiş Sertifika Gönderildi",
        text63: "Resim yüklenmedi",
        text64: "Yeniden gönder",
        text65: "Lütfen bir takma isim giriniz",
        text66: "Takma ad başarıyla kaydedildi",
        text67: "Henüz resim yüklenmedi",
        text68: "Şifre sıfırlama tamamlandı",
        text69: "E-posta başarıyla değiştirildi",
        text70: "Avatar başarıyla kaydedildi",
        text71: "Fotoğraf yükleniyor",
        text72: "posta kutunuz",
        text73: "Yeni e-postanızı girin",
        text74: "Adınız",
        text75: "Kimlik doğrulamaya git",
        text76: "Lütfen kimlik fotoğrafınızı doğrulayın"
    },
    retrievePassword: {
        formTitle1: "şifreyi yenile",
        formTitle2: "Varlıklarınızın güvenliği açısından şifre değişiminden sonraki 24 saat içerisinde işlem yapılması yasaktır.",
        formTitle3: "Parola Al",
        formTitle4: "Değişiklikleri onaylayın",
        formTitle5: "Lütfen eski şifreyi girin",
        formTitle6: "Lütfen yeni bir şifre girin"
    },
    home: {
        title1: "En Popüler Kripto Para Ticaret Platformu",
        title3: "neden ki ",
        title4: " Güvenli, uyumlu ve öngörülebilir dijital altyapıyı desteklemek ve işletmek için tasarlanmış, yüksek performanslı, açık kaynaklı bir blockchain platformudur.",
        field1: "Kurulu borsa",
        field2: "Dijital varlık finansal hizmetleri",
        field3: "On milyonlarca kullanıcı",
        field4: "Birden fazla yatırımcı koruma fonu oluşturun",
        field5: "Hızlı Ticaret",
        field6: "CME Grup, en üst düzey ticaret deneyimi için emirleri ultra düşük gecikmeyle eşleştirebiliyor.",
        title5: "piyasa eğilimi",
        title6: "daha fazla gör",
        table1: "Para birimi",
        table2: "Son fiyat",
        table3: "24 saat iniş ve çıkışlar",
        table4: "24 saatlik işlem hacmi",
        table5: "24 saatlik işlem hacmi",
        table6: "Alıntılar",
        title7: "hakkında ",
        title8: " Kullanıcılara en gelişmiş ticaret araçlarını, yenilikçi teknolojiyi ve benzersiz kalitede müşteri hizmetini sunmaya kararlıyız.",
        title9: "Dijital varlıkların alanı her geçen gün değişiyor ancak ileriyi görerek ve hızlı ilerleme kaydederek teknolojik yeniliğin hızına ayak uydurabiliriz.",
        title901: " Kullanıcılarımıza ve küresel likidite sağlayıcılarımıza en gelişmiş dijital varlık alım satım hizmetlerini sağlıyoruz.",
        title10: "Her kullanıcının en iyi ticaret deneyimini hak ettiğine inanıyoruz.",
        title11: "Güvenebileceğiniz güvenlik",
        title12: "Gelişmiş güvenlik önlemlerimiz ve SAFU fonlarımız dijital varlıklarınızı tüm risklerden korur.",
        title13: "En iyi işlem oranları",
        title14: "Tercihli işlem oranları, rekabetçi VIP hakları ve en kaliteli hizmetlerin keyfini çıkarın.",
        title15: "24*7 saat müşteri hizmetleri desteği",
        title16: "7/24 her türlü hava koşulunda, tam zamanlı çalışma modunda, ilgili sorularınızı en kısa sürede yanıtlayacağız.",
        title17: "0 ücretle ticaret",
        title18: "Sıfır işlem ücretiyle, güvenli ve hızlı bir şekilde dijital para birimleriyle işlem yapmak için birden fazla ödeme yöntemi kullanın.",
        title19: "İstediğiniz zaman, istediğiniz yerde ticaret yapın",
        title20: "Birden fazla terminalle mükemmel uyumlu olup, çeşitli senaryoların işlem ihtiyaçlarını her zaman karşılar",
        title21: "İstediğiniz zaman gerçek zamanlı piyasa koşullarını kontrol edin",
        title22: "BTC, ETH, XRP ve diğer dijital varlıkları kolayca satın alın ve satın",
        title23: "Önemsediğiniz kripto para birimleri için fiyat değişikliklerine ilişkin erken uyarı alın",
        title24: "Bitcoin spot kredisini, vadeli işlem kredisini ve opsiyon fiyatlarını görüntüleyin",
        title25: "Çeşitli borsalardaki kripto para birimi fiyatlarını karşılaştırın",
        title26: "Şimdi kayıt ol",
        title27: "Dolar",
        title28: "24 saatlik işlem hacmi",
        title29: "Entegre Likidite Sağlayıcısı",
        title30: "kullanıcı",
        title31: "Daha",
        title32: "Binance Kredi, CTSI ve KSM'yi teminat varlıkları olarak ekliyor ve BUSD ve USDT faiz oranı indirim indirimlerini başlatıyor!",
        title33: "Bizim avantajımız",
        title34: "güvenli ve istikrarlı",
        title35: "Üst düzey teknik ekip, kapsamlı güvenlik koruması, yüksek hızlı işlem sentezi motorunun bağımsız araştırması ve geliştirilmesi, büyük eşzamanlı işlemler altında hala istikrarlı ve güvenilir bir şekilde çalışabilir.",
        title36: "Profesyonel Uyumluluk",
        title37: "Blockchain ve finans alanında uzun yıllara dayanan deneyime sahip profesyonel operasyon ekibi, uyumlu bir dijital varlık alım satım lisansına ve %100 rezerv garantisine sahiptir.",
        title38: "Önce kullanıcı",
        title39: "Küresel pazara dönük, birden fazla dili destekleyen, 7/24 çalışan, güçlü topluluk desteği ve profesyonel müşteri hizmetleri.",
        title40: "yüksek performans",
        title41: "Saniyede 300.000 işlem ve 1 milisaniyeden kısa sipariş yanıt süresi.",
        title42: "Hakkımızda",
        title43: "İstediğiniz zaman, istediğiniz yerde ticaret yapın",
        title44: "Birden fazla terminalle mükemmel uyumlu olup, çeşitli senaryoların işlem ihtiyaçlarını her zaman karşılar",
        title45: "（İndirmek için kodu tarayın）",
        title46: "Dijital Para Haberleri",
        title47: "iş ortağımız",
        title48: "Kaybeden listesi",
        title49: "24 saatlik ciro",
        title50: "24 saatlik artış ve azalma"
    },
    quotation: {
        marketTrading: "Alıntılar",
        turnover: "Hacim",
        optional: "İsteğe bağlı",
        contractMarket: "sözleşme piyasası",
        currencyPair: "döviz çifti",
        latestPrice: "Kapanış fiyatı",
        upsAndDowns: "Teklif değişikliği",
        highest: "En yüksek",
        lowest: "en düşük",
        transactionAmount: "İşlem hacmi",
        operate: "işletmek",
        buy: "ticaret",
        topTurnovers: "İşlem listesi",
        topGainers: "Kazanan listesi",
        tradingPair: "ticaret çifti",
        buyOrder: "Satın almak",
        sellOrder: "satış emri"
    },
    accountChange: {
        demo: "simülasyon",
        realAccount: "gerçek hesap",
        demoAccount: "Demo hesabı",
        accountRole: "Hesap rolü",
        pleaseSelect: "lütfen seç",
        AccountChangeType: "Hesap değişikliği türü",
        AccountType: "hesap tipi",
        currencyType: "Para birimi türü",
        allType: "Her türlü",
        allAccount: "Bütün hesaplar",
        allCoin: "Tüm para birimleri",
        coin: "Para birimi",
        amount: "Tutarı değiştir",
        balance: "Hesap bakiyesi",
        time: "zaman",
        title: "Hesap değişikliği kayıtları",
        filtrar: "filtre",
        check: "çevirmek",
        showOnly: "Yalnızca döviz varlıklarını göster",
        holdAssets: "Döviz bakiyesi",
        accountList: "Hesap listesi"
    },
    contractTransaction: {
        chart: "çizelge",
        basicEdition: "Temel sürüm",
        depthMap: "Derinlik haritası",
        second: "Saniye",
        minute: "nokta",
        day: "gökyüzü",
        week: "hafta",
        month: "ay",
        open: "açık",
        trade: "ticaret",
        buyLong: "uzun satın alma",
        buyShort: "kısa satın alma",
        cannotBuy: "Bu para birimi teslimat sözleşmesi işlemlerini desteklemiyor",
        cannotBuy1: "Bu para birimi sürekli ticareti desteklemiyor",
        tradingAssets: "ticari varlıklar",
        amountAvailable: "Mevcut miktar",
        timeStr: "zaman",
        transactionPeriod: "ticaret dönemi",
        quantity: "miktar",
        enterQuantity: "Lütfen miktarı girin",
        buyIn: "Uzun git",
        latestTransaction: "son işlem",
        time: "zaman",
        direction: "yön",
        price: "fiyat",
        sell: "kısa",
        positionOrder: "Teslimat siparişi",
        historicalOrders: "Tarihsel siparişler",
        product: "ürün",
        realAccount: "(gerçek hesap)",
        demoAccount: "(Demo hesabı)",
        openingTime: "sipariş zamanı",
        openingPrice: "Açılış fiyatı",
        curretPrice: "Mevcut fiyat",
        liquidationPrice: "Kapanış fiyatı",
        profitOrLoss: "Kar ve zarar",
        endTime: "Kapanış saati",
        areYouSureToBuy: "Satın almak istediğinizden emin misiniz?",
        areYouSureToLongBuy: "Uzun süre gitmek istediğinden emin misin?",
        areYouSureToShortBuy: "Kısa alım yapmak istediğinizden emin misiniz?",
        successfullyBought: "Başarıyla satın alın",
        minBuy: "Minimum katılım",
        assets: "varlıklar",
        withdraw: "geri çekilmek",
        entrustedOrder: "Emir",
        total: "Toplam",
        continueToPlaceOrder: "Sipariş vermeye devam edin",
        orderConfirmation: "Sipariş onayı",
        buyRu: "satın almak",
        sellChu: "satmak",
        areYouSureToSell: "Satmak istediğinizden emin misiniz?",
        operation: "işletmek",
        buyCover: "kapak konumu",
        commitSuccess: "İşlem başarılı",
        onlyEnterIntegers: "Yalnızca tam sayılar girilebilir",
        trend: "Akım",
        lotteryTime: "Sipariş için son tarih",
        countdown: "geri sayım",
        period: "Sorun",
        cycle: "Zaman dilimi",
        unlimited: "Sınırsız",
        put: "Put",
        call: "Call",
        pending: "Komisyonda",
        unopened: "Henüz çizilmedi",
        opened: "Ödül çekildi",
        settlementPrice: "Uzlaşma Fiyatı",
        rateOfReturn: "getiri oranı",
        currentPositions: "Şu anki pozisyon",
        positionQuantity: "Pozisyon miktarı",
        guarantee: "marj",
        text1: "Call",
        text2: "Put",
        text3: "Satın almayı bekliyorum",
        text4: "Sipariş Miktarı",
        text5: "İşlem sayısı",
        text6: "İptal etmek",
        text7: "teslimat süresi",
        text8: "İptal hatırlatıcısı",
        text9: "Siparişi iptal etmek istediğinizden emin misiniz?",
        text10: "Yasa dışı miktar",
        text11: "Sipariş hatırlatıcısı",
        text12: "Yükseliş satın aldığınızdan emin misiniz?",
        text13: "Putu satın almak istediğinden emin misin?",
        text14: "İptal başarılı"
    },
    mining: {
        title1: "Dünyanın önde gelen blockchain dijital varlık madenciliği platformu",
        title2: "Açık ve şeffaf · güvenli ve istikrarlı",
        title3: "Lütfen para birimini seçin",
        title4: "Madencilik Yıllık Getirisi",
        title5: "gökyüzü",
        title6: "Madencilik dönemi",
        title7: "Ürün Detayları",
        btn: "Şimdi benim",
        btn2: "kapanış",
        tabs1: "devam etmekte",
        tabs2: "kurtarılmış",
        table1: "Ürün adı",
        table2: "Madencilik dönemi",
        table3: "Madencilik zamanı",
        table4: "Madencilik miktarı",
        table5: "Son kullanma tarihi",
        table6: "Vadeye kadar ilerler",
        table7: "Sipariş durumu",
        egularMining: "Düzenli olarak madencilik",
        inProgress: "devam etmekte",
        redeemed: "kurtarılmış",
        redemption: "kefaret",
        regularMining: "Düzenli olarak madencilik",
        earlyRedemption: "erken ödeme",
        need: "ihtiyaç",
        handlingFee: "Hizmet bedeli",
        redemptionConfirmationMessage: "Lütfen önceden kullanıp kullanmayacağınızı onaylayın？",
        form1: "Madencilik Yıllık Getirisi",
        form2: "Madencilik dönemi",
        form3: "Madencilik para birimi",
        form4: "Abonelik tutarı (minimum/maksimum)",
        form5: "Erken kullanım ücreti: ",
        form6: "satın alma bedeli",
        form7: "Mevcut fonlar",
        form8: "satın alma bedeli",
        form9: "Mevcut fonlar",
        form10: "Lütfen satın alma tutarını girin",
        form11: "Tümü",
        form12: "Tahmini gelir",
        form13: "Yetersiz fon mevcut",
        form14: "Lütfen satın alma tutarını girin",
        form15: "Benim siparişim",
        form16: "gelir",
        form17: "sipariş detayları",
        form18: "Erken ödeme talimatları:",
        form19: "Abonelik tutarı (minimum)",
        form20: "Abonelik tutarı (maksimum)",
        form21: "Tutulan miktar",
        form22: "Zaman satın alma",
        form23: "itfa zamanı",
        form24: "üzerinde",
        form25: "Abonelik başarılı",
        form26: "Ödeme başarılı",
        form27: "Kolay finansal yönetim, %25'in üzerinde getiri, piyasadan daha iyi performans ve satın alma sonrasında ödüller de alabilirsiniz",
        form28: "Arkadaşlarınızı 50 USDT tutarında bir ödül almaya davet edin; sözleşme kuponları da %20 komisyon getirebilir."
    },
    assets: {
        content1: "Hesap varlıkları",
        content2: "Bugünkü kazanç",
        content3: "mevcut hesap",
        content4: "Gerçek hesap, işlemler ve para çekme işlemleri için kullanılabilen gerçek hesap varlıklarınızdır.",
        content5: "Madeni para çekme",
        content6: "Para yatırma",
        content7: "değişme",
        content8: "Aktar",
        content9: "lütfen seç",
        content10: "Demo hesabı",
        content11: "gerçek hesap",
        content12: "varlık edinmek",
        content13: "Gerçek bir hesaba geçmek istediğinizden emin misiniz?？",
        content14: "Demo hesaba geçmek istediğinizden emin misiniz?？",
        content15: "başarı elde etmek",
        table1: "Para birimi",
        table2: "miktar",
        table3: "donmak",
        table4: "Mevcut",
        dialog1: "Ürün Detayları",
        dialog2: "1. Bu varlığın yalnızca demo hesap modunda kullanılmasına izin verilir",
        dialog3: "2. Kötüye kullanımın önlenmesi amacıyla, bir ay içinde yalnızca bir kez geri ödemeye izin verilmektedir.",
        dialog4: "3. Varlık alınırken alınan varlık tutarı asıl tutara eklenecek ve pozisyon durumu değişmeyecektir.",
        dialog5: "4. Başka sorularınız varsa lütfen müşteri hizmetlerine başvurun.",
        dialog6: "kapanış",
        dialog7: "Demo varlıklarını hemen alın",
        dialog8: "içeriye transfer et",
        dialog9: "dışarı aktarma",
        dialog10: "anahtar",
        dialog11: "Değerleme",
        dialog12: "Demo varlıkları",
        dialog13: "hesabım",
        dialog14: "Toplam hesap varlıkları",
        dialog15: "Varlığa genel bakış",
        dialog16: "Toplam varlık değerlemesi",
        dialog17: "Varlık değerlendirmesi",
        dialog18: "Bugünkü kazanç",
        dialog19: "fonları dondur",
        dialog20: "Her ay simüle edilmiş varlık kotası alabilirsiniz",
        dialog21: "almak",
        dialog22: "Başarıyla aldınız",
        dialog23: '30 günlük kümülatif gelir',
        dialog24: "Finansal ürün",
        dialog25: "Finansal yönetimim",
        dialog26: "Tarihsel finans yönetimi",
        dialog27: "geçmiş kaydı",
        dialog28: "Finansman kayıtları"
    },
    validator: {
        dialog14: "doğrulayıcı",
        dialog15: "Bağlama doğrulayıcı",
        dialog16: "doğrulayıcı anahtarı",
        dialog17: "Doğrulama kodu",
        dialog18: "Doğrulayıcı QR kodu",
        dialog19: "doğrulayıcı anahtar",
        dialog20: "kaydetmek",
        dialog21: "Lütfen bir doğrulayıcı seçin",
        dialog22: "anahtarı kopyala",
        dialog23: "Başarıyla kaydedildi",
        dialog24: "lütfen doğrulama kodunu girin",
        dialog25: "Dostça bir hatırlatma: Kaydetmeyi onaylamadan önce lütfen ilgili uygulamayı açın ve aşağıdaki QR kodunu tarayın veya anahtarı manuel olarak ekleyin!",
        dialog26: "Kimlik doğrulayıcınızı etkinleştirmek, hesabınız için maksimum güvenlik sağlar.",
        dialog27: "Her giriş yaptığınızda veya para çektiğinizde, cep telefonunuz aracılığıyla erişilen benzersiz bir doğrulama kodunu girmeniz gerekecektir.",
        dialog28: "Doğrulayıcıyı ayarla",
        dialog29: "yardım",
        dialog30: "Kimlik doğrulama kodunu bağlamadınız. Kimlik doğrulayıcıyla daha güçlü koruma elde edin.",
        dialog31: "Doğrulayıcı henüz kurulmadı, lütfen önce onu kurun",
        dialog32: "Ayarlara git"
    },
    recharge: {
        title1: "Dijital para birimini kullanarak nasıl satın alacağınızdan emin değil misiniz?",
        title2: "Sadece üç kolay adım!",
        title3: "Açılır menüden satın almak istediğiniz dijital para birimini seçin ve satın alma miktarını veya tutarını girin.",
        title4: "Tercih ettiğiniz ödeme yöntemini seçin",
        title5: "Ödeme onaylandıktan sonra satın aldığınız dijital para birimi otomatik olarak hesabınıza aktarılacaktır.",
        title6: "Lütfen yüklemek istediğiniz para birimini seçin",
        title7: "Mevduat kaydı",
        table1: "Para birimi",
        table2: "miktar",
        table3: "Zincir adı",
        table4: "durum",
        table5: "sebep",
        table6: "zaman",
        table7: "Kuponu görüntüle",
        dialog1: "Lütfen doğrulama kodunu aşağıya girin",
        dialog2: "ödeme kodu",
        dialog3: "kopyala",
        dialog4: "adres",
        dialog5: "Mevduat tutarı",
        dialog6: "İşlem Ekran",
        dialog7: "önemli Uyarı",
        dialog8: "Lütfen depozito tutarını girin",
        dialog9: "fotoğraf yükleniyor",
        dialog10: "Lütfen içeriği girin",
        dialog11: "Lütfen şarj işleminin ekran görüntülerini yükleyin",
        dialog12: "İncelenmedi",
        dialog13: "Reddedilmiş",
        dialog14: "Yatırıldı",
        tip1: "Başarıyla gönderildi",
        tip2: "Başarıyla kopyalandı",
        tip3: "Kopyalama başarısız oldu",
        tip4: "Lütfen yüklemek istediğiniz para birimini seçin",
        tip5: "1. Yukarıdaki para yatırma adresi, platformun resmi ödeme adresidir. Lütfen platformun resmi para yatırma adresini arayın. Yanlış para yatırma işleminden kaynaklanan para kaybından siz sorumlu olacaksınız;",
        tip6: "2. Bilgilerin tahrif edilmesini veya sızdırılmasını önlemek için lütfen bilgisayarınızın ve tarayıcınızın güvenli olduğundan emin olun;",
        tip7: "3. Yukarıdaki adresi yeniden doldurduktan sonra, hesabın alınabilmesi için tüm ağ düğümünün onaylaması gerekir;",
        tip8: "4. Lütfen yukarıdaki jeton sistemini ve para birimini seçin ve ardından ilgili tutarı para yatırmak için aktarın. Lütfen ilgisiz başka varlıkları transfer etmeyin, aksi takdirde geri alınmayacaktır.",
        tip9: "QR kodunu kaydet",
        tip10: "tamamlamak",
        tip11: "önemli Uyarı",
        tip12: "Site dışı bağlantılar",
        tip13: "QR kodunu şarj edin",
        tip14: "Sonraki adım",
        tip15: "Şarj kaydı",
        tip16: "Şarj ayrıntıları",
        tip17: "Para yatırma adresi",
        tip18: "Hızlı ödeme",
        tip20: "Yetersiz bakiye",
        tip22: "Gönderildi, onay bekleniyor",
        tip23: "Ödeme için bekleniyor",
        tip24: "Miktar",
        tip25: "Ödeme gönderildi, blok onayı bekleniyor",
        tip26: "Ödeme Reddedildi",
        tip27: "Ödeme Başarısız",
        tip28: "Ödeme başarılı",
        bankChannel: "Banka kartı kanalı",
        tip29: "Geçmiş aramalar",
        tip30: "Para birimi listesi",
        tip31: "Ağ seçin",
        tip32: "Bu para birimini yeniden doldurduğunuzda lütfen yalnızca aşağıdaki desteklenen ağlar üzerinden yükleme yapın. Diğer ağlar üzerinden yükleme yaparsanız paranız kaybolur.",
        tip33: "Bu para birimini çekerken lütfen yalnızca aşağıdaki desteklenen ağlar üzerinden çekim yapın. Diğer ağlar üzerinden çekim yaparsanız paranız kaybolur.",
        tip34: "Hızlı şarj",
        tip35: "Yeniden şarj etmek için kodu tarayın",
        tip36: "Manuel şarj",
        tip37: "Ağı yeniden şarj edin",

        tip38: "Şarj adresi",
        tip39: "Yeniden yükleme miktarı",
        tip40: "İşlem makbuzu yükleme",
        tip41: "fotoğraf yükleniyor",
        tip42: "gönderimi onayla",
        tip43: "ödemeyi Onayla",
        tip44: "Not: Lütfen şarj etmeniz gereken adresi doğrulayın. Yanlış doldurmanız durumunda oluşacak para kaybından sorumlu değiliz.",
        tip45: "hatırlatmak",
        tip46: "Adres başarıyla kopyalandı!",
        tip47: "Adres kopyalama başarısız oldu!",
        tip48: "Yetersiz kullanılabilir bakiye",
        tip49: "Yeniden yükleme başarıyla gönderildi ve zincirde onay bekleniyor.",
        tip50: "Yeniden şarj başarısız oldu",
        tip51: "Yükleme miktarı yanlış",
        tip52: "Bağlantı başarısız oldu",
        tip53: "Cüzdana giriş yapılmadı",
        tip54: "bağlantı reddedildi",
        tip55: "Başarıyla gönderildi, müşteri hizmetlerinin incelemesi ve onayı bekleniyor",
        tip56: "Lütfen işlem makbuzunun ekran görüntüsünü yükleyin",
        tip57: "Gönderim Başarısız",
        tip58: "Şarj kaydı",
        tip59: "Para çekme kaydı",
        tip60: "Para birimini yeniden yükle",
        tip61: "Para çekme para birimi",
        tip62: "Şarj adresi",
        tip63: "Ekran görüntüsü gösterileri",
        tip64: "Resmi büyütmek için tıklayın",
        tip65: "Şarj süresi",
        tip66: "Reddedilme nedeni",
        tip67: "Para Çekme Tutarı",
        tip68: "Alınan tutar",
        tip69: "Nakit çekme ağı",
        tip70: "Para çekme adresi",
        tip71: "Para çekme süresi",
        tip72: "kapanış",
        tip73: "İşlem Ekran",
        tip74: "Bekleyen yorum",
        tip75: "Reddedilmiş",
        tip76: "tamamlanmış",
        tip77: "Reddedilmiş",
        tip78: "Denetlendi",
        tip79: "Ödenen",
        tip80: "çıkarmak",
        tip81: "aktarım ağı",
        tip82: "Adres/Fatura",
        tip83: "Hepsini geri çek",
        tip84: "Lütfen doğrudan kitle fonlaması veya ICO adresine para çekmeyin, aksi takdirde kitle fonlaması veya ICO tarafından verilen tokenları alamayacaksınız.",
        tip85: "Varış miktarı",
        tip86: "Para çekme işlemini onaylayın",
        tip87: "Yanlış para çekme adresi",
        tip88: "Yanlış para çekme tutarı",
        tip89: "Çekilecek miktar en az",
        tip90: "Maksimum para çekme tutarı",
        tip91: "Para çekme talebi gönderildi",
        tip92: "adres listesi",
        tip93: "Henüz bir adres eklemediniz",
        tip94: "Şimdi ekle",
        tip95: "Adres Ekle",
        tip96: "yeni adres ekleyin",
        tip97: "lütfen seç",
        tip98: "adres",
        tip99: "Lütfen bir aktarım ağı seçin",
        tip100: "Lütfen adresi girin",
        tip101: "Adres başarıyla eklendi",
        tip102: "işlemde",
    },
    fundExchange: {
        title1: "Para değişimi nasıl yapılır?",
        title2: "Dönüştürülecek uygun para birimini seçin.",
        title3: "Dönüştürülecek uygun para birimini seçin.",
        title4: "Değiştirilecek tutarı girin ve fon değişimini tamamlamak için Gönder'e tıklayın.",
        title5: "itibaren",
        title6: "varmak",
        title7: "Değişim miktarı",
        title8: "Döviz kuru",
        title9: "Mevcut",
        title10: "Lütfen ödeme miktarını girin",
        title11: "Değişim için karşılık gelen bir işlem çifti yok.",
        title12: "Lütfen kullanılacak miktarı girin",
        title13: "Ödeme başarılı",
        title14: "Değişim kaydı",
        table1: "Para birimini kullan",
        table2: "miktar",
        table3: "Döviz kuru",
        table4: "Döviz kuru",
        table5: "Fiş",
        table6: "zaman",
        title15: "itibaren",
        title16: "Şu anda kullanılabilir varlık yok, lütfen başka bir tane seçin"
    },
    fundTransfer: {
        title1: "Para transferi nasıl yapılır?",
        title2: "Aktarılması gereken cari hesabı seçin.",
        title3: "Aktarmak istediğiniz hesabı seçin.",
        title4: "Transfer tutarını girin ve transferi tamamlamak için Gönder'e tıklayın.",
        title5: "Para birimi",
        title6: "Transfer miktarı",
        title7: "Lütfen transfer edilecek tutarı giriniz",
        title8: "aktarım kaydı",
        title9: "Lütfen cüzdan türünü seçin",
        title10: "Lütfen para birimini seçin",
        title11: "Lütfen cüzdan türünü seçin",
        title12: "Lütfen para birimini seçin",
        title13: "Lütfen transfer edilecek tutarı giriniz",
        title14: "Başarıyla gönderildi",
        table1: "Hesabı aktar",
        table2: "para birimi transferi",
        table3: "Transfer miktarı",
        table4: "hesap transferi",
        table5: "zaman",
        table6: "Aktar",
        table7: "Fon Transferi",
        table8: "Transfer",
        table9: "aktarım kaydı",
        table10: "Aktarım ayrıntıları",
        table11: "Aktarım başarılı",
        table12: "Aktarım başarısız oldu",
        table13: "Cari hesap devredilemez"
    },
    withdrawal: {
        title1: "önemli Uyarı",
        title2: "1. Arbitrajı önlemek için işlem hacmi limite ulaştığında para çekme başvurusunda bulunabilirsiniz.",
        title3: "2. Para çekme başvurusu, gönderildikten sonraki 24 saat içinde hesaba geçecektir. Eğer para çekme işlemi beklenen para çekme süresinden sonra gerçekleşmezse, lütfen çevrimiçi müşteri hizmetlerine danışın.",
        title4: "3. Para çekme başvurusu yapıldıktan sonra para çekme işlemi devam ettiği için fonların dondurulması ve fonların geçici olarak sistem tarafından tutulması, varlığı kaybettiğiniz veya varlıkta bir anormallik olduğu anlamına gelmez.",
        title5: "Para birimi",
        title6: "cüzdan adresi",
        title7: "Çekilen coin miktarı",
        title8: "Para çekme kaydı",
        title9: "Lütfen para birimini seçin",
        title10: "Lütfen zincir tipini seçin",
        title101: "",
        title11: "girin lütfen",
        title12: "cüzdan adresi",
        title13: "Başarıyla gönderildi",
        title14: "inceleme altında",
        title15: "İnceleme başarılı",
        title16: "Denetim hatası",
        title17: "Ödenen",
        title18: "Lütfen cüzdan adresinizi girin veya yapıştırın",
        title19: "Lütfen çekim tutarını giriniz",
        title20: "Hizmet bedeli",
        title21: "Gerçek varış",
        table1: "Para birimi",
        table2: "Çekilen coin miktarı",
        table3: "Zincir adı",
        table4: "cüzdan adresi",
        table5: "durum",
        table6: "sebep",
        table7: "başvuru zamanı",
        table8: "Madeni para çekme",
        table81: "Madeni para çekme",
        table9: "Para çekme adresi",
        table10: "Lütfen çekmek istediğiniz para birimini seçiniz",
        table11: "Lütfen girdiğinizden emin olun",
        table12: 'Adres (genellikle "0x" ile başlar)',
        table13: "Çekilebilir paralar (işlem ücreti düşülür)：",
        table14: "Para birimini seçin",
        table15: "Sorunlarla mı karşılaştınız? Anında geri bildirim",
        table16: "Denge",
        table17: "Para çekme ayrıntıları",
        password: "şifre",
        passwordTips: "Lütfen şifre giriniz",
        passwordAg: "Şifreyi Onayla",
        passwordAgTips: "Lütfen şifreyi tekrar girin",
        oldPassword: "eski Şifre",
        oldPasswordTips: "Lütfen eski şifreyi girin",
        newPassword: "Yeni Şifre",
        newPasswordTips: "Lütfen yeni bir şifre girin",
        passwordNotSame: "Şifreler tutarsız",
        withdrawPasswordSet: "Para çekme şifresini ayarla",
        withdrawPasswordModify: "Para çekme şifresini değiştir",
        withdrawPassword: "Şifreyi geri çek",
        withdrawPasswordTips: "Lütfen para çekme şifresini girin",
        setWithdrawPasswordFirst: "Lütfen önce bir para çekme şifresi belirleyin",
        withdrawPasswordSetSuccess: "Kurulum başarılı",
        withdrawPasswordModifySuccess: "Başarıyla değiştirildi",
        submit: "göndermek",
    },
    customer: {
        content1: "çevrimiçi servis",
        content2: "müşteri servisi",
        content3: "Lütfen iletişim bilgilerinizi girin",
        content4: "Lütfen içeriği girin",
        content5: "Göndermek",
        content6: "Gönderim başarısız oldu, ağ bağlantısı başarısız oldu",
        content7: "resim",
        content8: "mesaj boş",
        content9: "benim payım",
        content10: "linki paylaş",
        content11: "resmi Kaydet",
        content12: "kazancım",
        content13: "Seviye açıklaması",
        content14: "Takım boyu",
        content15: "Davetiye geliri",
        content16: "Kural açıklaması",
        content17: "üyelik seviyesi",
        content18: "Şarj miktarı şundan daha fazla:",
        content19: "Takım seviyesi",
        content20: "Takım Seviyesi ve Geliri",
        content21: "Koşulları karşılamak için",
        content22: "Dağıtım geliri",
        content23: "seviye ekibi",
        content24: "seviye, davet",
        content25: "geçerli üyeler",
        content26: "birincil gelir",
        content27: "ikincil gelir",
        content28: "Seviye 3 geliri",
    },
    standardContract: {
        increaseMargin: "marjı artır",
        increaseAmount: "miktarı artır",
        Pleaseenterthemargin: "Lütfen artırılacak teminat tutarını giriniz",
        Pleaseenterthecorrect: "Lütfen doğru para yatırma tutarını girin",
        Increasemarginsuccessfully: "Marjı başarıyla artırın",
        takeProfitPriceWrong: "Kar Fiyatını Yanlış Almak",
        stoppriceerror: "Fiyat hatasını durdur",
        thetakeprofit1: "Kâr alma fiyatı piyasa fiyatından yüksek olmalıdır",
        thetakeprofit2: "Zararı durdurma fiyatı piyasa fiyatından düşük olmalıdır",
        thetakeprofit3: "Kâr alma fiyatı piyasa fiyatından düşük olmalıdır",
        thetakeprofit4: "Zararı durdurma fiyatı piyasa fiyatından yüksek olmalıdır",
        thetakeprofit5: "Yanlış miktar",
        estimatedprofitloss: "Tahmini kar ve zarar",
        positionAmount: "Pozisyon sayısı",
        closeAmount: "Kapanış miktarı",
        whenthelatest: "Son fiyata ulaşıldığında",
        whenthelatest2: ", mevcut pozisyonu kapatmak için piyasa fiyatından kar alma emri tetiklenecektir. Beklenen kar ve zarar",
        whenthelatest3: "mevcut pozisyonu kapatmak için piyasa zararı durdurma emri tetiklenecektir. Beklenen kar ve zarar",
        contract: "sözleşme",
        ordercanceledsuccessfully: "Sipariş başarıyla iptal edildi",
        successfullyordered: "başarıyla sipariş edildi",
        positionopeningreminder: "Pozisyon açılış hatırlatıcısı",
        wrongquantity: "Yanlış miktar",
        quantitycannot: "Miktar şu miktardan büyük olamaz:",
        modificationstoploss: "Kar durdurma ve zarar durdurma değişiklikleri başarılı",
        positionclosedsuccessfully: "Pozisyon başarıyla kapatıldı",
        orderCancellation: "Sipariş iptali",
        orderCancellation2: "Siparişi iptal etmek istediğinizden emin misiniz?",
        tobeexecuted: "İdam edilecek",
        expired: "günü geçmiş",
        failed: "arızalı",
        contractTransaction: "Sürekli sözleşme",
        increase: "Arttırmak",
        openPosition: "Tam depo",
        isolatedPosition: "İzole konum",
        limitOrder: "fiyat sınırı",
        marketOrder: "Market fiyatı",
        pleaseSelectLeverage: "Lütfen kaldıracı seçin",
        pleaseEnterQuantity: "Lütfen miktarı girin",
        canOpenMore: "Açılabilecek maksimum konum",
        contractAmount: "Kontrat miktarı",
        openingFee: "Hizmet bedeli",
        currentPosition: "Konum",
        historicalPositions: "Geçmiş işlemler",
        currentCommission: "Şuan ki sipariş",
        historicalCommission: "tarihi komisyon",
        buyPrice: "Alış fiyatı",
        income: "kâr",
        quantity: "miktar",
        marginRate: "marj oranı",
        initialMargin: "Başlangıç ​​marjı",
        estimatedPriceParity: "Zorunlu tasfiye fiyatı",
        operate: "işletmek",
        finishQuantity: "İşlem hacmi",
        averagePrice: "ortalama işlem fiyatı",
        pleaseEnterLeverage: "Lütfen kaldıracı girin",
        takeProfitAndStopLoss: "Kâr alın ve zararı durdurun",
        takeProfit: "Kar almak",
        stopLoss: "kaybı durdurmak",
        openLong: "Long",
        openShort: "Short",
        liquidation: "Konumu kapat",
        marketLquidation: "Piyasa fiyatı sabit",
        cancel: "Siparişi iptal et",
        enterBuyPrice: "Lütfen alış fiyatını giriniz",
        areYouSureLquidation: "Pozisyonu kapatacağınızdan emin misiniz??",
        confirm: "Elbette",
        enterStopWinPrice: "Lütfen kar alma fiyatını girin",
        enterStopLossPrice: "Lütfen zararı durdurma fiyatını girin",
        commissionTime: "Komisyon süresi",
        commissionPrice: "Komisyon fiyatı",
        commissionQuantity: "Toplam komisyon tutarı",
        volumeAlreadyTraded: "Tamamlanan tutar",
        areYouSureToWinOrLoss: "Kârı durdurmak ve zararı durdurmak istediğinizden emin misiniz?",
        maxQuantity: "Maksimum konum",
        saleQuantity: "Pozisyon kapalı",
        salePrice: "ortalama kapanış fiyatı",
        saleTime: "zaman",
        succeeded: "Başarılı",
        cancelled: "İptal edildi",
        piece: "açık",
        triggerPrice: "tetik fiyatı",
        setWin: "Kâr almayı ayarla",
        setLoss: "Zararı durdurmayı ayarla",
        enterWinQuantity: "Lütfen kar alma tutarını girin",
        enterLossQuantity: "Lütfen zararı durdurma tutarını girin",
        planCommission: "Komisyon planı",
        enterWinTriggerPrice: "Lütfen kar alma tetik fiyatını girin",
        enterLossTriggerPrice: "Lütfen zararı durdurma tetikleme fiyatını girin",
        insufficientQuantity: "Yetersiz miktar",
        enterTriggerPrice: "Lütfen tetik fiyatını girin",
        tips1: "Emir fiyatınız güncel fiyattan yüksektir ve doğrudan güncel piyasa fiyatı üzerinden gerçekleştirilecektir. Almak istediğinizden emin misiniz?",
        tips2: "Emir fiyatınız güncel fiyattan daha düşük ve direkt olarak güncel piyasa fiyatı üzerinden gerçekleştirilecek. Alış yaptığınızdan emin misiniz?",
        type: "tip",
        increaseQuantity: "Konumu artır",
        reduceQuantity: "Pozisyonları azaltın",
        limitCommission: "limit emri",
        tips3: "Zararı durdurma fiyatı piyasa fiyatından düşük olmalıdır",
        tips4: "Kâr alma fiyatı piyasa fiyatından yüksek olmalıdır",
        tips5: "Zararı durdurma fiyatı piyasa fiyatından yüksek olmalıdır",
        tips6: "Kâr alma fiyatı piyasa fiyatından düşük olmalıdır",
        tips7: "Zararı durdurma fiyatı piyasa fiyatından ve emir fiyatından düşük olmalıdır",
        tips8: "Kâr alma fiyatı emir fiyatından yüksek olmalıdır (piyasa fiyatından daha küçük olabilir)",
        tips9: "Zararı durdurma fiyatı piyasa fiyatından ve emir fiyatından yüksek olmalıdır",
        tips10: "Kâr alma fiyatı emir fiyatından küçük olmalıdır (piyasa fiyatından büyük olabilir)",
        accountEquity: "Hesap bakiyesi",
        unrealizedPL: "gerçekleşmemiş kar veya zarar",
        tips11: "Haklı olduğundan emin misin",
        tips12: "Market fiyatı",
        tips13: "Tüm pozisyonları kapat",
        tips14: "Bir daha hatırlatma",
        profitRate: "Kapanış getiri oranı",
        openTime: "Açılış zamanı",
        unwindTime: "Kapanış saati",
        dealTime: "İşlem süresi",
        amountClose: "Kapalı pozisyon miktarı",
        long: "birçok",
        short: 'hükümsüz',
        orderQuantity: "Sipariş Miktarı",
        entrustedMargin: "emanet edilen marj",
    },
    spotGoods: {
        spotGoods: "Flaş değişimi",
        sellPrice: "satış fiyatı",
        orderRecord: "Flaş kullanım kaydı",
        transactionTime: "İşlem süresi",
        maxBuy: "satınalınabilir",
        maxSell: "Satılık",
        purchaseQuantity: "Satın alma miktarı",
        slippage: "Kayma",
        convertTips: "%0-5 kayma ile en uygun fiyattan işlemi kapatacağız.",
        changePrice: "Güncel döviz fiyatı",
        consume: "tüketmek",
        consumeAmount: "Tüketilen miktar",
        get: "elde etmek",
        getAmount: "Miktarı al",
        changeTips: "Kullanmak istediğinizden emin misiniz?",
        confirm: "Değişimi onayla"
    },
    news: {
        title: "haber Merkezi",
        redMore: "devamını oku",
    },
    credit: {
        credit: "kredi notu",
        creditScore: "toplam kredi puanı",
        latestEntry: "son kayıt",
        liveUpdate: "Canlı güncelleştirme",
        systemEvaluation: "Sistem değerlendirmesi",
        time: "tarih",
        name: "isim",
        reason: "sebep",
        scoreStory: "Kredi notu kaydı"
    },
    borrowing: {
        borrowing: "borç",
        diversifiedMarket: "Çeşitlendirilmiş pazarlar",
        totalSupply: "Toplam arz",
        totalBorrowings: "toplam borçlanmalar",
        tips1: "Teminatınız karşılığında borçlanma limitine kadar ödünç alabileceğiniz toplam tutardır.",
        tips2: "İstikrarlı faiz oranınız kredinizin ömrü boyunca aynı kalacaktır. Uzun vadeli tedarik ve öngörülebilirliği seven kullanıcılar için önerilir.",
        totalSupplys: "toplam arz",
        supplyAPR: "arz oranı",
        totalBorrowings: "Toplam borçlanma",
        borrowAPR: "borçlanma oranı",
        check: "Kontrol etmek",
        market: "pazar",
        availableLiquidity: "Mevcut likidite",
        currencyPrice: "güncel döviz fiyatı",
        supplyAssets: "tedarik varlıkları",
        tips3: "Not: Aşağıdaki faiz oranı sabit faiz oranı olup, arz süresi bittikten sonra anapara artı kazanç hesabınıza iade edilecektir.",
        supplyCycle: "tedarik döngüsü",
        minimumSupply: "minimum arz",
        supply: "tedarik",
        borrowedAssets: "ödünç alınan varlıklar",
        tips4: "Bilmeniz gereken önemli: Borç almak için herhangi bir teminat varlığı veya kanıt sunmanız gerekecektir.",
        tips5: "Lütfen ipotek varlık açıklamasına bakın.",
        repaymentCycle: "geri ödeme döngüsü",
        BorrowableAmount: "Mevcut kredi tutarı",
        borrow: "borç",
        accountInformation: "Hesap Bilgileri",
        walletBalance: "cüzdan bakiyesi",
        canSupply: "Mevcut",
        AmountCanBeBorrowed: "Mevcut toplam kredi tutarı",
        borrowedAmount: "Ödünç alınan miktar",
        remainingAmount: "kalan bakiye",
        getBorrowednAmount: "Kredi limiti al",
        supplyOrder: "Tedarik emri",
        borrowedOrder: "kredi emri",
        supplyAmount: "tedarik miktarı",
        expectedProfits: "Beklenen getiri",
        supplyTime: "Tedarik süresi",
        expirationTime: "Son kullanma tarihi",
        borrowingAmount: "Kredi miktarı",
        dueRepayment: "Geri Ödeme Tutarı",
        borrowingTime: "Yayınlanma zamanı",
        repaymentTime: "Geri ödeme süresi",
        repay: "geri ödeme",
        supplyCurrency: "para birimi sağlamak",
        buyNow: "Şimdi al",
        supplyRecords: "tedarik kayıtları",
        borrowRecords: "Kredi kayıtları",
        completed: "tamamlanmış",
        borrowNow: "Şimdi ödünç al",
        borrowCurrency: "Borçlanma para birimi",
        borrowMin: "en küçük",
        borrowMax: "maksimum",
        borrowRange: "Borçlanma tutarı (minimum/maksimum)",
        tips6: "Lütfen zamanında ödeyiniz, eğer vadesi geçmişse, günlük ilave tutar (anapara+faiz) tahsil edilecektir.",
        tips7: "Gecikmiş ücret.",
        pleaseEnterBorrowAmount: "Lütfen kredi tutarını giriniz",
        interest: "Faiz",
        tips8: "Peşin ödeme yapmak istediğinizden emin misiniz?",
        borrowChannel: "Borçlanma kanalı",
        tips9: "Seni tanıyarak başlayalım",
        name: "isim",
        lastName: "soyadı",
        birthDate: "doğum tarihi",
        tips10: "Biraz daha bilgi, adresiniz nedir?",
        familyAddress: "Ev adresi (ünite numarası dahil)",
        city: "Şehir",
        country: "ulus",
        postalCode: "Posta Kodu",
        pleaseEnterYourHomeAddress: "Lütfen ev adresinizi girin",
        tips11: "Bize mali durumunuzdan bahsedin",
        tips12: "Bu bilgiyi daha sonra doğrulamamız gerekebilir, bu nedenle lütfen mümkün olduğunca doğru olmaya çalışın.",
        yourMonthlyIncome: "aylık geliriniz",
        enterYourMonthlyIncome: "Lütfen aylık gelirinizi giriniz",
        extraIncomeOptional: "Ek gelir (isteğe bağlı)",
        enterExtraIncome: "Lütfen ek gelirinizi girin",
        monthlyRentOrMortgagePayment: "Aylık kira veya ipotek ödemesi",
        enterMonthlyRent: "Lütfen aylık kira veya ipotek ödemenizi girin",
        tip13: "İpotekiniz varsa bize bildirin",
        tip14: "İpotek garantisi seçilmediği takdirde incelemeyi geçme olasılığı düşüktür.",
        isThereMortgage: "İpotek var mı?",
        selectIsThereMortgage: "Lütfen ipotek olup olmadığını seçin",
        selectMortgageType: "Lütfen ipotek türünü seçin",
        yes: "Evet",
        no: "HAYIR",
        uploadScreenshotOfTransaction: "Lütfen işlem ekran görüntülerini yükleyin",
        tip15: "İrtibatta kalalım",
        tip16: "Kredi başvurunuzla ilgili daha fazla bilgiye ihtiyaç duymamız halinde sizinle bu numaradan iletişime geçebiliriz.",
        tip17: "Onayımı okudum ve elektronik olarak imzaladım",
        tip18: "Kullanım Koşulları ve Elektronik Onay",
        tip19: "size ürünlerimizle ilgili bilgi göndermemiz için verdiğiniz izni içerir.",
        tip20: "Ürün ve Hizmet Kullanım Koşulları",
        tip21: "Bilgileri doldurduktan sonra lütfen sistem değerlendirmesi ve incelemesini bekleyin.",
        phoneNumber: "telefon numarası",
        enterPhoneNumber: "Lütfen telefon numaranızı girin",
        tip22: "Kullanım koşullarını ve elektronik onay formunu kabul etmek için lütfen kutuyu işaretleyin",
        inReview: "inceleme altında",
        applicationAmount: "Başvuru miktarı",
        reasonForRejection: "Reddedilme nedeni",
        reapply: "Yeniden başvur",
        applyStatus: "uygulama durumu",
        currentOrder: "şuan ki sipariş",
        interestRate: "faiz oranı"
    },
    yuebao: {
        filed1: "yatırım",
        filed2: "Çıkarmak",
        filed3: "Günlük gelir",
        filed4: "takım hızlandırma",
        filed5: "Yatırıldı",
        filed6: "Dünkü kazanç",
        filed7: "Toplam gelir",
        filed8: "Mevcut fonlar",
        filed9: "Lütfen tutarı girin",
        filed10: "Likidite madenciliği",
        filed11: "giriiş",
        field12: "Minimum satın alma fiyatı",
        field13: "Satın alabileceğiniz en fazla",
        filed14: "Abonelik",
        filed15: 'düzenli',
        filed16: 'yıllık faiz oranı',
    },
    tipsPopover: {
        title: "Bu uygulamayı yükle",
        press: "buna göre",
        and: "Ve",
        scrren: "Ana ekrana ekle",
        hasInstall: "(Yüklüyse lütfen pencereyi kapatın)",
    },
    follow: {
        title1: "Takip planı",
        follow_text1: "Bir takip emri başlatın",
        follow_text2: "Sürüm geçmişi",
        follow_text3: "başlık",
        follow_text4: 'ticaret çifti',
        follow_text5: 'Satın alma süresi',
        follow_text6: 'sipariş zamanı',
        follow_text7: 'yayınlanma zamanı',
        follow_text8: 'Takipçi sayısı',
        follow_text9: 'Sipariş miktarı',
        follow_text10: 'işletmek',
        follow_text11: 'davet etmek',
        follow_text12: 'Sipariş yönünü takip edin',
        follow_text13: 'Sipariş koşullarını ayarlayın',
        follow_text14: 'Siparişleri takip etme koşulları',
        follow_text15: 'sabit miktar',
        follow_text16: 'yüzde',
        follow_text17: 'Davet edilen kişiler',
        follow_text18: 'Karşı tarafın kimliği',
        follow_text19: "Siparişi takip etmeyi onaylayın",
        follow_text20: "Sipariş tutarınız (isteğe bağlı)",
        follow_text21: "Lütfen bir başlık girin",
        follow_text22: "Lütfen bir işlem çifti seçin",
        follow_text23: "Lütfen sipariş yönünü seçin",
        follow_text24: "Lütfen satın alma süresini seçin",
        follow_text25: "Lütfen sipariş zamanını seçin",
        follow_text26: "Lütfen satın alma miktarını seçin",
        follow_text27: "Maksimum satın alma miktarı, minimum satın alma miktarından az olamaz",
        follow_text28: "Minimum katılım",
        follow_text29: "En çok satın alınanlar",
        follow_text30: "Kopyalama sırasını başarıyla oluştur",
        follow_text31: "Ticaret çifti listesi",
        follow_text32: "Siparişi zaten takip ettim",
        follow_text33: "Lütfen davetli bilgilerini girin",
        follow_text34: "Davet edildi",
        follow_text35: "beni davet etti",
        follow_text36: "Geçmiş kopyalanıyor",
        follow_text37: "Sipariş miktarı aralığı"
    },
    followHistory: {
        title1: 'Sürüm geçmişi',
        title2: "Geçmiş kopyalanıyor",
        follow_h_text1: "Siparişin toplam tutarı",
        follow_h_text2: "Emirleri takip eden toplam kişi sayısı",
        follow_h_text3: "Sürüm sayısı",
        follow_h_text4: "kazanma oranı",
        follow_h_text5: "Emirlerin takip edilme sayısı",
        follow_h_text6: "Takip eden siparişlerden kar elde edin",
        follow_h_text7: "yön",
        follow_h_text8: "kazanç",
        follow_h_text9: "açık",
    },
    "asset.index.1579E8B41F8D5134": "varlıklarım",
    "asset.index.B3F6F05Dfbe431Cd": "simülasyon",
    "asset.index.B9Aeab4Ee8F33746": "Varlık değerlendirmesi",
    "asset.index.583Df8Bd541Eb7E8": "kazanç eğilimleri",
    "asset.index.B1254815D133Cc26": "7 gün",
    "asset.index.2F615F6C029F01B7": "30 gün",
    "asset.index.29439Fb58E4Bbe22": "geri çekilmek",
    "asset.index.831Ab31568A78285": "tamamlamak",
    "asset.index.39F7A0D48D12E8C9": "Aktar",
    "asset.index.B99063C5689Beaec": "Flaş değişimi",
    "asset.index.E1Dc620F2F038B2A": "Varlıkları al",
    "asset.index.972A0B4C2B4393D0": "hesabım",
    "asset.index.9Dfb0Fb334620733": "Kalan bakiye",
    "asset.index.Cdc5Fbd26790Cb47": "dondurulmuş miktar",
    "asset.index.3D8132D72Ae965Cd": "Değerleme",
    "asset.receive.825503Cc430559Cb": "varlık edinmek",
    "asset.receive.54154A0553A535B7": "Demo varlıkları",
    "asset.receive.F2E57B00A9C5D129": "1. Bu varlığın yalnızca demo hesap modunda kullanılmasına izin verilir",
    "asset.receive.Ea0D1D52989299Bd": "2. Kötüye kullanımın önlenmesi amacıyla, bir ay içinde yalnızca bir kez geri ödemeye izin verilmektedir.",
    "asset.receive.090E0037Aa235D3D": "3. Varlık alınırken alınan varlık tutarı asıl tutara eklenecek ve pozisyon durumu değişmeyecektir.",
    "asset.receive.7Fd4E1Abc805C0A6": "4. Başka sorularınız varsa lütfen müşteri hizmetlerine başvurun.",
    "asset.receive.Df5B3A511D986575": "Demo varlıklarını hemen alın",
    "asset.records.1Fa02E8187A39592": "son üç ay",
    "asset.records.7B291C37333Fe34D": "yakından uzağa",
    "asset.records.A476Cd1Ec868Fc5F": "son üç ay,",
    "asset.records.9D5755A842E55Bb7": "yakından uzağa,",
    "asset.records.E3Fa05E607203Cdc": "tamamlamak",
    "asset.records.B4F206C6Bec08Dea": "geri çekilmek",
    "asset.index.Df5B3A511D986576": "Demo varlıklarını başarıyla aldınız",
    "deposit.depositSpeedy.Efe81D0E0174239B": "ödeme yöntemi",
    "deposit.depositSpeedy.B9293Ee96E435F85": "Fiat para birimi",
    "deposit.depositSpeedy.16658A855F07D171": "Miktar",
    "deposit.depositSpeedy.204Ffab8A6E01870": "Elbette",
    "deposit.depositSpeedy.22E5Dfb74Ead8Aa9": "Boş olamaz",
    "deposit.depositSpeedy.B8E774D0E4E5E4C1": "Minimum şarj",
    "deposit.depositSpeedy.F169D816F6Dd83C5": "Maksimum şarj",
    "deposit.depositWallet.F64Ec60B1Db4A6D6": "USDT yatırın",
    "deposit.depositWallet.551640C76F4Ca4A1": "Para birimini yeniden yükle",
    "deposit.depositWallet.B404C543712B71D5": "Ağı yeniden şarj edin",
    "deposit.depositWallet.A484B0F5E94B3F4F": "Para yatırma adresi",
    "deposit.depositWallet.D3980D54Acf242Db": "QR kodunu kaydet",
    "deposit.depositWallet.A06E84801D6582Be": "Yeniden yükleme miktarı",
    "deposit.depositWallet.Daaaeb1B7B22B126": "ipucu",
    "deposit.depositWallet.D599E40E1Eeec1B3": "Yeniden yükleme başarılı",
    "deposit.depositWallet.4F48Abbafce532Bb": "Başarıyla gönderildi, müşteri hizmetlerinin incelemesi bekleniyor",
    "deposit.depositWallet.A88C481228811696": "Ağ değiştirmeyi reddettiniz",
    "deposit.depositWallet.C6Ebf27895C21932": "Lütfen TRON cüzdan eklentisini manuel olarak açın ve oturum açın",
    "deposit.index.B5491C8Dd09D8183": "Yüklemek istediğiniz para birimini seçin",
    "deposit.index.2F784B2298D39987": "Hızlı şarj",
    "deposit.index.0Daa03Dcdcabd837": "Site dışı bağlantılar",
    "withdraw.bottomSheet.628A63056Ef2D2Fe": "Para çekme onayı",
    "withdraw.bottomSheet.A086Fa20082C849D": "Lütfen aşağıdaki bilgilerin doğru olduğundan emin olun",
    "withdraw.bottomSheet.Bf8B184D32971501": "Para çekme ağı",
    "withdraw.bottomSheet.8758Fd50C87D6C9C": "adres",
    "withdraw.bottomSheet.F9Db93B87E08763B": "miktar",
    "withdraw.bottomSheet.4Ad26475002878C0": "Hizmet bedeli",
    "withdraw.index.A96454041169Af6D": "Para birimini seçin",
    "withdraw.index.2A563B7081Dd5B83": "Para çekme adresi",
    "withdraw.index.B189C2Ef713Cfff4": "Çekilen coin miktarı",
    "withdraw.index.558F35140E84B562": "Mevcut：",
    "withdraw.index.F4E759087E12Cb77": "Hepsini geri çek",
    "withdraw.index.5Cbd9A6B284D25Ae": "Fon şifresi",
    "withdraw.index.B89Cbe4Bfc33Bb41": "Hizmet bedeli:",
    "withdraw.index.C886De8D8A27026E": "önemli Uyarı",
    "withdraw.index.Eac7C5A4095F5606": "1. Arbitrajı önlemek için işlem hacmi limite ulaştığında para çekme başvurusunda bulunabilirsiniz.",
    "withdraw.index.C3Cc7Fff24504Be9": "2. Para çekme başvurusu, gönderildikten sonraki 24 saat içinde hesaba geçecektir. Eğer para çekme işlemi beklenen para çekme süresinden sonra gerçekleşmezse, lütfen çevrimiçi müşteri hizmetlerine danışın.",
    "withdraw.index.0B4Ad7Ccdc833B0D": "3. Para çekme başvurusu yapıldıktan sonra para çekme işlemi devam ettiği için fonların dondurulması ve fonların geçici olarak sistem tarafından tutulması, varlığı kaybettiğiniz veya varlıkta bir anormallik olduğu anlamına gelmez.",
    "withdraw.index.4639F3A6E07C00B3": "Sonraki adım",
    "withdraw.index.81Fba0D65Cc5C3Aa": "Lütfen cüzdan adresinizi girin veya yapıştırın",
    "withdraw.index.25768141C8Bdea91": "Lütfen çekim tutarını giriniz",
    "withdraw.index.97A85734Cd1B7Bf0": "Lütfen fon şifresini giriniz",
    "withdraw.index.39C7D74B33241C78": "Para çekme tutarı yanlış",
    "withdraw.index.86A5D17908197525": "Para çekme onayı, madeni paraların serbest bırakılması için inceleme bekleniyor",
    "withdraw.index.48Fb603C6638C7F6": "Lütfen bir fon şifresi belirleyin",
    "withdraw.index.873Ade9A3Bacc2F7": "ipucu",
    "bill.index.5Bbb2A6Ac68D6B2E": "gerçek hesap",
    "bill.index.75751E0Eb8261C40": "Demo hesabı",
    "bill.index.Cbc853B37Ecc2E97": "Hesap değişikliği türü",
    "bill.index.4A0Cacddeb2E38B1": "hesap tipi",
    "bill.index.F16217028E098B2F": "Para birimi",
    "bill.index.A620Dbe6F06B78Ba": "Her türlü",
    "bill.index.2C3C148Bb94D9B3F": "Bütün hesaplar",
    "c2C.index.Ab45695E4Bf1E382": "C2C işlemi",
    "c2C.index.2702Dc911Dfea762": "satın almak istiyorum",
    "c2C.index.D737D5B8367A53C4": "satmak istiyorum",
    "c2C.index.C0C540A3Ba9Abe01": "Hacim",
    "c2C.index.D8644E8D60C8Bd8A": "birim fiyat",
    "c2C.index.Ebb8670B203D3604": "sınır",
    "c2C.index.F8C6245A2Ebf1D27": "Tüm banka kartları",
    "c2C.index.F8C6245A2Ebf1D28": "para tahsilatı",
    "c2C.index.F8C6245A2Ebf1D29": "banka kartı",
    "c2C.index.Cc3Aa8141Da9Ec9E": "Satın almak",
    "c2C.index.Cc3Aa8141Da9Ec9F": "satmak",
    "c2C.index.67A39834582Ad99A": "Veri yok",
    "c2C.order.04C23810B47F77F7": "miktara göre",
    "c2C.order.6905365902E0A73D": "miktara göre",
    "c2C.order.3F0Ecebbd9D2F23B": "Mevcut",
    "c2C.order.16853Bda54120Bf1": "Tümü",
    "c2C.order.Ad3335796F2C95F7": "Toptan",
    "c2C.order.61092E27A6879318": "Varlıkların güvenliğini korumak için lütfen önleme farkındalığınızı artırın!",
    "c2C.order.0F51881F0Fb920B2": "Ticaret Bilgileri",
    "c2C.order.Dd74Ceca85E5Bc65": "Ödeme süresi sınırı",
    "c2C.order.De3341388A59073F": "30 dakika",
    "c2C.order.D334B597A352167F": "Satıcı takma adı",
    "c2C.order.D334B597A352167G": "Alıcı takma adı",
    "c2C.order.9D5F89F99Ced890D": "işlem aracı",
    "c2C.order.8Adf755B14Bc6B7C": "Lütfen önce aşağıdakileri okuyun",
    "c2C.order.686B16F454A7338B": "Banka kartı transferi yaparken herhangi bir açıklama yapmayınız, aksi takdirde para aktarılmayacak ve hesap doğrudan bloke edilecektir. Ödeme yapıldıktan sonra yeni bir işlem ayrıntıları şeması sağlamanız gerekir (hesabı dondurmak için P şeması veya gizli işlem ayrıntıları platforma bildirilirse)",
    "c2C.order.9Bec8Fba867B739B": "Ödeme türünü seçin",
    "c2C.order.Cf2D892C419Fea36": "minimum satın alma",
    "c2C.order.7290A6B7456A03E0": "en büyük satın alma",
    "c2C.orderConfirm.F7E99B8C203E2B07": "sipariş detayları",
    "c2C.orderConfirm.036D94Dc2D1Cf7D3": "Sipariş oluşturuldu",
    "c2C.orderConfirm.0F5433503F3845Ce": "Lütfen geri sayım süresi içinde satıcıya ödeme yapın.",
    "c2C.orderConfirm.24C3Bcacdb85A451": " Alıcıların ödemesini bekliyorum ",
    "c2C.orderConfirm.8E8140D7Ab607B24": "satıcıyla iletişime geç",
    "c2C.orderConfirm.8E8140D7Ab607B25": "alıcıyla iletişime geç",
    "c2C.orderConfirm.Ec4D8722Ec15C813": "Satıcının dijital para birimi artık emanet hesabına yatırıldı, lütfen ödeme yapmaktan çekinmeyin ",
    "c2C.orderConfirm.69D1Fbbc7700C1Ab": "Alıcının gerçek adı:",
    "c2C.orderConfirm.E5Ed8Ec53Edcd5D3": "7x24 saat müşteri hizmetleri desteği ",
    "c2C.orderConfirm.Abf9F4B8C93D641E": "sipariş numarası",
    "c2C.orderConfirm.726C51702F70C486": "yaratılış zamanı",
    "c2C.orderConfirm.E097Bb9B1216Bfd8": "Müşteri Hizmetleriyle İletişime Geçin",
    "c2C.orderConfirm.103408Fbbe30287F": "siparişi iptal et",
    "c2C.orderConfirm.4E93Df1Fb521F4Ba": "Ödemeyi onayladım",
    "c2C.orderConfirm.3B5418Dbea7Ebfe4": "Zaten ödendi",
    "c2C.orderConfirm.387Ca3625B260E4E": "Ücretli siparişiniz",
    "c2C.orderConfirm.7C83D5F1Db1A08B7": "İşlemi iptal et",
    "c2C.orderConfirm.76C86566Cc3751C0": "Sipariş iptal edildi",
    "c2C.orderConfirm.205B349751D176B4": "Ödeme başarılı",
    "c2C.paymentAdd.6F2A7Dcc66E6Acba": "ödeme yöntemi",
    "c2C.paymentAdd.C87E865Df6800304": "Ödeme yöntemi yapılandırması",
    "c2C.paymentAdd.B59Ae0F1B1C235Df": "Ödeme yöntemi türü",
    "c2C.paymentAdd.06910Bddf002695F": "gerçek isim",
    "c2C.paymentAdd.3E33Ae3Efa7507Dd": "Paypal hesabı",
    "c2C.paymentAdd.3E33Ae3Efa7507De": "Banka hesap numarası/kart numarası",
    "c2C.paymentAdd.3E33Ae3Efa7507Df": "Para birimi adı",
    "c2C.paymentAdd.595Ede91Abf6F6E0": "QR kodunu öde",
    "c2C.paymentAdd.1Ecc8Be9Ba1C940A": "Yüklemek için tıklayın",
    "c2C.paymentAdd.6Daae340F2E9718A": "banka adı",
    "c2C.paymentAdd.347751415C3Da489": "Zincir adı",
    "c2C.paymentAdd.15B3627Faddccb1D": "Açıklama",
    "c2C.paymentAdd.86Da96Cbd274058F": "Sıcak hatırlatma: Dijital para birimi sattığınızda, seçtiğiniz ödeme yöntemi alıcıya gösterilecektir. Lütfen bilgilerin doğru bir şekilde doldurulduğunu onaylayın.",
    "c2C.paymentAdd.4Ce9979Bfb6576D9": "kaydetmek",
    "c2C.paymentAdd.90D2A3A5C07179C0": "lütfen gerçek adınızı girin",
    "c2C.paymentAdd.A3B0Cd3Ad9Ca3475": "Lütfen giriniz",
    "c2C.paymentAdd.Ec389E6190Eedb62": "Lütfen banka adınızı girin",
    "c2C.paymentAdd.9Be51Fb1E9025Fb2": "Lütfen zincir adınızı girin",
    "c2C.paymentAdd.94F034F0E01439E0": "Lütfen adresinizi girin",
    "c2C.paymentAdd.B12E035663A42B32": "Tüm banka kartları",
    "c2C.paymentAdd.C7B677F393A8F8Ac": "para tahsilatı",
    "c2C.paymentAdd.Abafe11737Bab7Cd": "Lütfen ödeme kodunu yükleyin",
    "c2C.paymentAdd.07C443212Ff04Bfb": "Başarıyla eklendi",
    "c2C.paymentDetail.86Da96Cbd274058F": "Sıcak hatırlatma: Dijital para birimi sattığınızda, seçtiğiniz ödeme yöntemi alıcıya gösterilecektir. Lütfen bilgilerin doğru bir şekilde doldurulduğunu onaylayın.",
    "c2C.paymentDetail.C4C613Da60226De4": "Başarıyla değiştirildi",
    "c2C.paymentMethod.7609F7C8337C7A17": "C2C ödeme yöntemi",
    "c2C.records.9E36893D5736Abb4": "Sipariş listesi",
    "c2C.records.4B53F9A5B13B3235": "Bekleyen ödeme",
    "c2C.records.4B53F9A5B13B3236": "İşlemi iptal et",
    "c2C.records.4B53F9A5B13B3237": "Zaten ödendi",
    "contract.index.9706Df877458F0Be": "Tam depo",
    "contract.index.623Fc7D3D40Aa479": "Başlangıç ​​marjı",
    "contract.index.F92Dce1169330387": "Piyasa fiyatı sabit",
    "contract.index.3598B97Dd28Dbf20": "Lütfen miktarı giriniz (adet)",
    "contract.index.6E1Fae821Dc0B797": "Sürdürülebilir",
    "contract.index.Eb07Eb0C37C14575": "Teslimat",
    "contract.index.Eb6492F19B1C1Fb6": "Geçmiş işlem ayrıntıları",
    "contract.index.873Ade9A3Bacc2F7": "ipucu",
    "contract.index.Ff63B0F93D39F50B": "Haklı olduğundan emin misin",
    "contract.index.Ff63B0F93D39F50D": "Uzun git",
    "contract.index.Ff63B0F93D39F50E": "kısa",
    "contract.index.Ff63B0F93D39F50F": "yönetmek",
    "contract.index.Ff63B0F93D39F50G": "Tüm pozisyonlar kapatılsın mı?",
    "customer.dialog.3D410C88E8A24Fe8": "Takma adı onaylayın",
    "customer.dialog.4B3340F7872B411F": "İptal etmek",
    "customer.dialog.549B1456Bb5F98B3": "lütfen takma adınızı giriniz",
    "customer.dialog.B773B3E45Ccfad00": "Takma ad boş",
    "customer.index.7308C6B849D159E9": "Resim ayrıntıları",
    "delivery.callDialog.C74F562C3Fe91Fe3": "başarıyla sipariş edildi",
    "delivery.callDialog.B8C98F42Caa15Ca5": "yön",
    "delivery.callDialog.B79A581Dae543974": "uzun satın alma",
    "delivery.callDialog.B79A581Dae543975": "kısa satın alma",
    "delivery.callDialog.3B26F0F7E90773F3": "Açılış fiyatı",
    "delivery.callDialog.65Fb252Bd94E1039": "Tahmini gelir",
    "delivery.index.B483Ba4Fce633539": "Teslimat",
    "delivery.index.224Dd6Cb58D58249": "ticaret dönemi",
    "delivery.index.28158F517E902788": "Minimum katılım",
    "delivery.index.A1232162B14Bec76": "Teslimat siparişi",
    "delivery.index.445B1D3176D9C69D": "Tarihsel siparişler",
    "delivery.index.Ab6Cb86E28653C5C": "Teslimat",
    "delivery.index.20A7A6F14D3698C0": "Açılış zamanı",
    "delivery.index.Ec6821A49395Ddc2": "Kapanış saati",
    "delivery.index.254Fdc665C391743": "Kapanış fiyatı",
    "delivery.index.2A0C5E68B44A472A": "Kar ve zarar",
    "delivery.index.E05B33309F6D236A": "Lütfen miktarı girin",
    "delivery.index.B2899E073Cb355D9": "Minimum satın alma",
    "download.description.869467A93C7Dd4A1": "yükleme notları",
    "download.description.C80C1Edd6Bee12Bc": "1. Açıklama dosyalarının indirilmesine izin ver",
    "download.description.2F5Bc180F92642B4": "2. Açıklama dosyası indirildi",
    "download.description.3C3B849F6Ccf712F": "3. Cep telefonuna [Ayarlar] girin; indirilen açıklama dosyası hesap bilgilerinin altında görüntülenecektir.",
    "download.description.86Affd862985E045": "4. İndirilen açıklama dosyasına tıklayın",
    "download.description.32832492Bfc1D95E": "5. Yüklemek için tıklayın",
    "download.index.245883C80F181C4A": "indirmek",
    "download.index.Ef3De8Cb2722937A": "İstediğiniz zaman, istediğiniz yerde ticarete başlayın",
    "download.index.F4A87Eaeed691638": "Bizim APP aracılığıyla en son haberleri takip edin",
    "download.index.Ad3Aba52D0D39D01": "Kurulum paketini indirin",
    "download.safariGuide.61Bd9A8Fcc2Ea788": "Nazik ipuçları",
    "download.safariGuide.2Cc68Aaadf623477": "Lütfen Safari'de ziyaret edin",
    "download.safariGuide.C80Fff2A6Ff4E768": "Merkezi olmayan bir web sitesini ziyaret ediyorsunuz, lütfen safari uygulamasını çalıştırın",
    "download.safariGuide.06Da016F3E2F79Bd": "Bağlantıyı kopyala",
    "finance.index.B034B367545D5236": "Küresel yüksek kaliteli projeler",
    "finance.index.6Ebfac2C0A9228B0": "1,6 milyondan fazla aktif kullanıcı, 122,4 milyar dolar",
    "finance.index.52Bdcaf19659363D": "Likidite madenciliği",
    "finance.index.D66924272B031Bf5": "Süper yüksek yıllık gelir",
    "finance.index.34789D88Afcefa64": "Öğeleri görüntüle",
    "finance.index.E12676B95654D21E": "kredi kredisi",
    "finance.index.4C924Cfd89A11Ac7": "Daha fazla ticaret fırsatını keşfedin",
    "finance.index.C251D4B594Da2848": "Yapay zeka ölçümü",
    "finance.index.4E53Cf2E5F173Dd3": "Tek tıklamayla kiralama, AI akıllı işlem",
    "finance.records.Cce168301447D1Ba": "devam etmekte",
    "finance.records.4587B6Bd7Cfa4454": "kurtarılmış",
    "finance.records.18685418E3A50147": "madencilik",
    "finance.records.Df9Fd438F97Cc908": "gökyüzü",
    "finance.records.E23Cb03C5Ad359E5": "dönem",
    "finance.records.Bd87Dac6Cba7Eba8": "Satın alma miktarı",
    "finance.records.2D0Fff99265E8B1A": "karlı",
    "finance.records.2D0Fff99265E8B1B": "Vade sonunda kar",
    "finance.records.024F5F0B851782De": "Madencilik zamanı：",
    "finance.records.3143A26D3F983896": "itfa zamanı：",
    "finance.records.96574Aaf8Db85Ead": "erken ödeme",
    "finance.records.531E1829203181Ac": "Teklifi erken kullanmak istediğinizden emin misiniz?",
    "finance.records.9205843B8923Dac3": "Ödeme başarılı;",
    "loan.applyCallback.B9258Ec8746A74D8": "Borçlanma kanalı",
    "loan.applyForm.7B8Ed340400A6E2B": "isim",
    "loan.applyForm.549A44Bae725F4A2": "soyadı",
    "loan.applyForm.2A27E61375E7Fea8": "doğum tarihi",
    "loan.applyForm.89925B019D537738": "e-posta adresi",
    "loan.applyForm.Afaeb32Cb4D62E28": "Ev adresi (ünite numarası dahil)",
    "loan.applyForm.E05B5D049B64B040": "Şehir",
    "loan.applyForm.B166E4E8Fe9513Fa": "ulus",
    "loan.applyForm.83Aaede109E395F8": "Posta Kodu",
    "loan.applyForm.647Ed21332A4968B": "aylık geliriniz",
    "loan.applyForm.Cf0A9B845D34D4B4": "Ek gelir (isteğe bağlı)",
    "loan.applyForm.7048F618Acc6A54C": "Aylık kira veya ipotek ödemesi",
    "loan.applyForm.15C1Bba18Ae18545": "İpotekiniz varsa bize bildirin",
    "loan.applyForm.3921E90625A84E36": "İpotek garantisi seçilmediği takdirde incelemeyi geçme olasılığı düşüktür.",
    "loan.applyForm.262Eec4F8906B0F6": "İpotek var mı?",
    "loan.applyForm.8634Eb21B6910F21": "Lütfen ipotek türünü seçin",
    "loan.applyForm.A4897B3A331B5E61": "İşlem kuponu",
    "loan.applyForm.6623384C683De457": "İrtibatta kalalım",
    "loan.applyForm.A8360Bdba86834Cb": "Kredi başvurunuzla ilgili daha fazla bilgiye ihtiyaç duymamız halinde sizinle bu numaradan iletişime geçebiliriz.",
    "loan.applyForm.C1C373F385A4C7B3": "telefon numarası",
    "loan.applyForm.1B9785Badb91191F": "Bilgileri doldurduktan sonra lütfen sistem değerlendirmesi ve incelemesini bekleyin.",
    "loan.applyForm.8755B9C761D4080C": "Onayımı okudum ve elektronik olarak imzaladım",
    "loan.applyForm.3E987B787468148C": "Kullanım Koşulları ve Elektronik Onay",
    "loan.applyForm.Bc54E76B61C50Fb9": "Lütfen sözleşmeyi okuyup kabul edin",
    "loan.applyForm.89E158384F634Eb8": "Önceki adıma dön",
    "loan.applyForm.Efa5A4548E2F3510": "Ev Adresi",
    "loan.applyForm.4Cadf1787541B35D": "Evet,",
    "loan.applyForm.Fab408D2F4100447": "HAYIR,",
    "loan.applyForm.3Bad7A307480Cc56": "E-posta formatı yanlış;",
    "loan.applyForm.306322F49B36855D": "Başvuru gönderildi, lütfen müşteri hizmetlerinin incelemesini bekleyin",
    "loan.applyForm.3C6E0E858C995Ff9": "Lütfen işlem kuponunu yükleyin",
    "loan.applyForm.2Bf443476B271376": "En fazla 4 fotoğraf yüklenebilir",
    "loan.applyQuota.Ef1D8E23003B9A6F": "Borçlanma kanalı",
    "loan.applyQuota.Ef1D8E23003B9A6G": "Kredi limiti al",
    "loan.applyQuota.9E0780F9352C7273": "cüzdan bakiyesi",
    "loan.applyQuota.Cbc1Eb9D639A6105": "Mevcut toplam kredi tutarı",
    "loan.applyQuota.033A05462195B080": "Ödünç alınan miktar",
    "loan.applyQuota.9Aa89B036Ef4821D": "kalan bakiye",
    "loan.applyQuota.5656Fb52Ef17A557": "Kredi limiti al",
    "loan.applyQuota.6Ff6702B4C917D40": "inceleme altında",
    "loan.applyQuota.6Ff6702B4C917D41": "Reddedilmiş",
    "loan.applyQuota.6Ff6702B4C917D42": "Başarılı",
    "loan.applyQuota.0Bad0E857Ea4A952": "başvuru zamanı",
    "loan.applyQuota.3E7E56E2De2Cc546": "Başvuru miktarı",
    "loan.applyQuota.8F1D170D9E54Af5B": "Uygulama İlerlemesi",
    "loan.applyQuota.6167Ca035B93Caf3": "sebep",
    "loan.applyQuota.410C7954F9A3981D": "Yeniden başvur",
    "loan.bottomSheet.61Cd62F861D8F5B2": "Lütfen para birimini seçin",
    "loan.index.4C10307B22E885C3": "Daha fazla ticaret fırsatını keşfedin ve zenginlik yaratın",
    "loan.index.Ae8D37Dea2B3717B": "faiz oranı",
    "loan.index.1Bb54625682E85Db": "Kural tanıtımı",
    "loan.index.5Bd31Fab681C6E9D": "daha fazla gör",
    "loan.index.111F95F404814327": "Kredi para birimi",
    "loan.index.6Bc2723B3Bec106D": "borç",
    "loan.index.3200106280E6E370": "faiz oranı",
    "loan.index.049A6Ccb6C81A635": "Kredi tutarı (min/maks)",
    "loan.productDetails.5037Ad86Ab4B7E02": "borç",
    "loan.productDetails.37181C74559Db182": "Emir ",
    "loan.productDetails.5802Aaf06A1E4755": "Likidite",
    "loan.productDetails.89Aea9Cf9337C40F": "Ödünç alınabilecek miktar",
    "loan.productDetails.9E419Ae2D06F3Ad9": "Geri ödenecek miktar",
    "loan.productDetails.C65F329Ef14C4A3D": "Ödünç alınan madeni para miktarı",
    "loan.productDetails.3A74667815Adc8Bf": "maksimum",
    "loan.productDetails.Ab851D8351573227": "gökyüzü",
    "loan.productDetails.Ddf82D8A2C8F46A0": "geri ödeme döngüsü",
    "loan.productDetails.C105E5562005Aeb1": "gökyüzü",
    "loan.productDetails.3F346Be2137F0756": "Kredi tutarı (min/maks)",
    "loan.productDetails.2707F78Df864E194": "Faiz",
    "loan.productDetails.B9Dc9B81E726Bdf1": "Lütfen zamanında ödeyiniz, eğer vadesi geçmişse, günlük ilave tutar (anapara+faiz) tahsil edilecektir.",
    "loan.productDetails.B9Dc9B81E726Bdf2": "% gecikmiş ücrettir",
    "loan.productDetails.4D94Faa033Be7356": "kabul ettim",
    "loan.productDetails.62429A957A22780A": "Coin Ödünç Verme Hizmet Sözleşmesi",
    "loan.productDetails.Bc54E76B61C50Fb9": "Lütfen sözleşmeyi okuyup kabul edin",
    "loan.productDetails.Ec8Ba071C53A67Cb": "Şimdi ödünç al",
    "loan.productDetails.59246A0Bf081D398": "en az",
    "loan.productDetails.Ec3F1172B71Cd36C": "minimum miktar",
    "loan.productDetails.7Ccc4Cf1D81930B3": "en büyük miktar",
    "loan.productDetails.071488598990Aa68": "Lütfen sözleşmeyi okuyup kabul edin;",
    "loan.productDetails.579Dd216D3339119": "Kredi başarılı oldu;",
    "loan.records.4951B36Fd0D2A831": "Kredi ve kredi geçmişi",
    "loan.records.Bb0Aaa30514B7Cba": "şuan ki kayıt",
    "loan.records.0F8B0A7D80769051": "Geri ödeme geçmişi",
    "loan.records.D4B42E43D88De6F1": "geçmiş kaydı",
    "loan.records.Cb1847D6281897E6": "Kredi miktarı",
    "loan.records.C7Bfcacfc2Adea58": "Geri Ödeme Tutarı",
    "loan.records.8B369164D5Fea340": "Kredi süresi:",
    "loan.records.Ab2402Ad3Ffd35E7": "Krediyi erken ödeyin",
    "loan.records.B7A0Ca0C195D0Cd1": "Yayınlanma zamanı:",
    "loan.records.42014Bdcfd9C411B": "Geri ödeme süresi:",
    "loan.records.A0Acfaee54F06458": "tamamlanmış",
    "loan.records.67A39834582Ad99A": "Veri yok",
    "loan.records.6A687Ff5B8F1598F": "Peşin ödeme yapmak istediğinizden emin misiniz?",
    "loan.records.7C20D7008D0Eae86": "Geri ödeme başarılı",
    "mining.bottomSheet.Ba91637D32Ccba04": "Likidite madenciliği",
    "mining.bottomSheet.7De3D636A165E008": "getiri oranı",
    "mining.bottomSheet.F3003345B189D683": "Madencilik dönemi",
    "mining.bottomSheet.3E4C0A8C533F5848": "madeni para türü",
    "mining.bottomSheet.Adcba368993Ae65E": "Abonelik tutarı (minimum/maksimum)",
    "mining.bottomSheet.94743B24E4Ed4166": "Sınırsız",
    "mining.bottomSheet.286944Fedd211257": "Mevcut fonlar",
    "mining.bottomSheet.7B5766Dc65Abc0C3": "Tahmini gelir:",
    "mining.bottomSheet.9Bbaf80Fdb6Fd148": "Şimdi benim",
    "mining.bottomSheet.Bb1493C567C114Dd": "Tür ipuçları:",
    "mining.bottomSheet.D2315E87E34A3Bd3": "Erken kullanım ücreti",
    "mining.index.8B2Bba1422Eed6F0": "On milyarlarca zenginlik ve %100 net gelir yaratın",
    "mining.index.42997E300086Ee89": "en yüksek getiri",
    "mining.index.510F74F42052Ecce": "Madencilik ürünleri",
    "mining.index.B853F96626106912": "Madenciliğe git",
    "mining.productList.37181C74559Db182": "Emir",
    "mining.productList.Aac5B810004C46C8": "düzenli",
    "mining.productList.D2Fb4F78C387D888": "Başarılı bir şekilde madencilik yaptınız",
    "quantify.bottomSheet.9Ea4A318F690Bc95": "Sipariş onaylamak",
    "quantify.bottomSheet.6915303B698303B1": "robot",
    "quantify.bottomSheet.68045296Dd0F8Ad6": "Kira tutarı",
    "quantify.bottomSheet.Fbd931286Be387Aa": "döngü",
    "quantify.bottomSheet.9Ad84Db481807844": "süresiz olarak",
    "quantify.bottomSheet.994D7226Ca083Ea5": "Günlük getiri oranı",
    "quantify.bottomSheet.0Bf82Ee4Bfe063B7": "valör tarihi",
    "quantify.bottomSheet.88008603Ba7Bde64": "Satın alma başarılı",
    "quantify.bottomSheet.Ff50B3091Bbd09Ab": "Gelir paylaşımınızı bugünden itibaren alın!",
    "quantify.bottomSheet.C778A674C4A054F9": "sipariş kontrolü",
    "quantify.bottomSheet.685Dad322B7029B0": "İade işlemi",
    "quantify.buyForm.Acf8560A04334E95": "satın alma bedeli",
    "quantify.buyForm.Cf9Cfbc2C554944F": "Genel Bakış",
    "quantify.buyForm.8788Df2B625E97B5": "robot",
    "quantify.buyForm.50Ca63B45E881D50": "Temettü ödeme zamanı",
    "quantify.buyForm.Fcda03Adbc5Fca97": "Her gün",
    "quantify.buyForm.Afb89504Fc399189": "Lütfen satın alma miktarını girin",
    "quantify.buyForm.1435Afdfb4Ec0596": "Minimum satın alma miktarı",
    "quantify.buyForm.1B97F1730Bacd839": "Maksimum satın alma miktarı",
    "quantify.index.32F56C9B7D0E250A": "Süre: belirsiz",
    "quantify.index.E34F028D00904630": "fiyat",
    "quantify.index.E34F028D00904631": ",Günlük getiri oranı",
    "quantify.productDetail.6Bbe178Df539A5Ca": "Emanet tutarı",
    "quantify.productDetail.53635B53Bca31Dfa": "Tahmini günlük gelir",
    "quantify.productDetail.F9E46449A6269E09": "Kümülatif gelir",
    "quantify.productDetail.98Fc46B1B8C993B2": "Barındırma siparişi verin",
    "quantify.productDetail.93624Bb045830966": "Şimdi kirala",
    "quantify.records.0C16A18C32B9Cf4A": "Sipariş kaydı",
    "quantify.records.B191F5614B0E2312": "Satın alım geçmişi",
    "quantify.records.6A06033B86A73F7C": "geri ödeme kaydı",
    "quantify.records.45E0F7E71611618E": "Kümülatif gelir",
    "quantify.records.45E0F7E71611618F": "Vade sonunda kar",
    "quantify.records.Aae1201E38C4Baaa": "Koşma",
    "flash.index.C41Fd4F2Fdfa594B": "tüketmek",
    "flash.index.1Ac59Fa2218E3B00": "Mevcut:",
    "flash.index.Bf7233A660821549": "elde etmek",
    "flash.index.548E775Dd67C7472": "Değişimi onayla",
    "flash.index.066A2449Bd616F03": "Lütfen ödeme miktarını girin",
    "flash.index.Fca26Fcf9C06563F": "Flash kullanımı başarılı",
    "message.index.40031524233F4564": "Mesaj merkezi",
    "message.index.8E5586210Fd44D69": "Sürekli konum hatırlatıcısı",
    "message.index.Bcd3F470E88C197D": "Henüz bildirim yok",
    "message.index.23A557Fbce545879": "Kalıcı Tasfiye Bildirimi",
    "message.index.B5E19B36B5125550": "Para çekme hatırlatıcısı",
    "message.index.716A981465138334": "Şarj hatırlatıcısı",
    "message.index.884474Ebff2977Ba": "sistem bilgisi",
    "message.index.08Cc7D24752Ec42C": "çevrimiçi servis",
    "message.index.1D820A6A19Ab025D": "7X24 saat çevrimiçi manuel servis",
    "more.index.29881E0B5Df58Bf7": "Daha fazla özellik",
    "more.index.C77Ee35911D10D9D": "Ortak İşlevler",
    "more.index.3558F69B0260Be61": "Ticaret fonksiyonu",
    "more.index.7243Fe610Ed98Cec": "gerçek hesap",
    "more.index.7243Fe610Ed98Ced": "Demo hesabı",
    "more.index.693C7B35Bdf3E3A3": "finans",
    "more.index.F855997Fac114554": "Yardım Merkezi",
    "more.index.Ae9A1252278Da742": "yardım",
    "more.index.Ace012504Bc529E0": "bilgi",
    "more.index.05682Dbef5Eeb0A8": "müşteri servisi",
    "more.index.C7Af227A5D5C7Bb9": "Gerçek bir hesaba geçtiniz",
    "more.index.D818A65592694786": "Demo hesabına geçtiniz",
    "notice.details.B360807Dbe727E6E": "Hakkımızda",
    "notice.details.92F2552F695E70E3": "Hakkımızda",
    "notice.details.7Aaceaf69B67F3D1": "Kural tanıtımı",
    "notice.index.6128438Be34Bcb9F": "Sürekli bildirim",
    "notice.index.71627F5Eb7128261": "Para çekme bildirimi",
    "notice.index.2Dce570Fda841705": "Yeniden yükleme bildirimi",
    "notice.index.228A7C9C6448A9B1": "sistem bildirimi",
    "setting.dialog.8B4F5B74660Deabe": "Renk yapılandırması",
    "setting.dialog.Bfc7D25486Ebc1A9": "Yeşil yükselir ve kırmızı düşer",
    "setting.dialog.796Ea0E1A82C320B": "Kırmızı yükselişler ve yeşil düşüşler",
    "setting.index.8Ddc5864E1739466": "Tercihler",
    "setting.index.F5205801D57D6727": "dil",
    "setting.index.2E2C12Dd0Cc1B63B": "Teklif para birimi",
    "setting.index.322D8496Aec3D918": "versiyon numarası",
    "setting.index.Aa7E6C204C751Cfa": "oturumu Kapat",
    "share.index.75Bc38Bbf7D2E766": "arkadaşları davet etmek",
    "share.index.45575Eb5D42213E6": "davetiye kodum",
    "share.index.29F67F0644A78Bf3": "kopyala",
    "share.index.19Cc65Bb6Bb98Fe7": "Davetiye bağlantım",
    "share.index.5372712F162Fb78B": "Doğrudan astların sayısı",
    "share.index.C88D6B95E3Ab7D6F": "Toplam ast sayısı",
    "share.index.Bb5Fa5471Aeecc62": "Doğrudan astlar için komisyon indirimi",
    "share.index.426B6Bac9A5F3D4F": "toplam indirim",
    "share.index.197E4A428F0401C9": "İndirim kayıtlarını görüntüle",
    "share.records.88A6B2E2B74B13C4": "Komisyon kaydı",
    "share.records.B671A35Ffdc93B89": "İndirim",
    "transfer.index.B17617A869C03Ca5": "cüzdan",
    "transfer.index.E4116Dcc6F6C7270": "Aktarım başarılı",
    "transfer.index.48Fb603C6638C7F6": "Lütfen bir fon şifresi belirleyin",
    "transfer.records.Eb0E39Eb5911D418": "elde etmek",
    "transfer.records.977554Be0Aa65052": "Flaş kullanım süresi：",
    "transfer.records.9Aa33884Aa400B7C": "transfer zamanı：",
    "form1.forget.76636Db8Fae5B124": "posta kutunuz",
    "form1.forget.35354B9Cca1Bb0E6": "E-posta doğrulama kodu",
    "form1.forget.604A0B11E916F387": "Sifre",
    "form1.forget.9Cb1C9C51E7503D6": "Şifreyi Onayla",
    "form1.forget.3A4F7E04B008453C": "Telefon numaran",
    "form1.forget.Cf11Dce5B4713B5A": "Cep telefonu doğrulama kodu",
    "form1.forget.3012Edba57D4F297": "Lütfen E-postanızı girin",
    "form1.forget.F1Ee1D97E869E447": "lütfen doğrulama kodunu girin",
    "form1.forget.3A5C4C9Ae68Fa0F6": "Şifrenizi giriniz Lütfen",
    "form1.forget.C6Cb174Da411D5F7": "Lütfen cep telefonunuzu giriniz",
    "form1.forget.947B3B21Ee45E582": "Göndermek",
    "form1.forget.3E0580F1E8630Df6": "İki şifre tutarsız",
    "form1.forget.C5A61836B8Cc74C6": "Şifre değişikliği başarıyla yönlendirildi...",
    "form1.forget.B96D072Bc8962565": "Yeniden gönder",
    "form1.login.2B72C27Ff828Cfc4": "şifreniz",
    "form1.login.D7C6B872Af9Cb17F": "Şifremi Hatırla",
    "form1.login.0Fff44C827A4F3B6": "Giriş yapmak",
    "form1.login.1B7F2C1Ede3990Cc": "Henüz bir hesabınız yok mu? Şimdi kayıt ol",
    "form1.login.F657F7Be1Cc33Bd2": "şifreyi unut",
    "form1.login.2074A1Eec4B088B0": "Cüzdan girişi",
    "form1.login.1B8938Fbcc3C9B56": "Giriş başarılı ve yönlendiriliyor...",
    "form1.login.07162A7Bb9310555": "Lütfen ERC20 cüzdanını yükleyin",
    "form1.login.766969966Ae151Dc": "Lütfen TRC20 cüzdanını yükleyin",
    "form1.login.B88171E1Ad4C50Bf": "Ağ değiştirmeyi reddettiniz",
    "form1.register.Aaef0710Cb4Db554": "Şifreyi tekrar girin",
    "form1.register.54Dfea0415C708Ff": "Davet Kodu",
    "form1.register.6839940Dac08C794": "kayıt olmak",
    "form1.register.9901A3B5550Aa58E": "Zaten hesabınız var mı? Giriş yapmak",
    "form1.register.2548Ca53785252Ea": "Lütfen davetiye kodunuzu giriniz",
    "form1.register.Dd71Fd65900Dd03E": "Lütfen Kayıt Hizmeti Sözleşmesini okuyun ve kabul edin",
    "form1.register.7975613909490B77": "Kayıt başarılı ve yönlendiriliyor...",
    "form1.walletDialog.105829Acfc0Bd6Aa": "Cüzdan seç",
    "userc.certification.2Af9511640153D2E": "Genç Sertifikası",
    "userc.certification.177B191D8E0Fa84D": "sertifikalı değil",
    "userc.certification.601218D4D572E98A": "doğrulandı",
    "userc.certification.6E37C51Ba9012Fc8": "Geçmedi",
    "userc.certification.1B9B563E05C96474": "Gelişmiş sertifikasyon",
    "userc.certification.1418B83803Ab56B4": "Lütfen önce birincil sertifikayı tamamlayın",
    "userc.creditScore.0B149112601Acc4D": "kredi notu",
    "userc.creditScore.88Ac7F84E106C5F6": "toplam kredi puanı",
    "userc.creditScore.3E1D6E0E7F1E6155": "Sistem değerlendirmesi",
    "userc.index.62Eac58D04Bbca0F": "Kişisel merkez",
    "userc.index.F4Cac53F93Cb2Aff": "simüle edilmiş ticaret",
    "userc.index.42F409Fdf3559773": "Güvenlik Merkezi",
    "userc.index.1B8E211009807Db8": "Kimlik doğrulama",
    "userc.index.Bdfcf21Eb8F2Eb70": "Hesap değişikliği kayıtları",
    "userc.index.6C18D67F6D19586B": "Platforma Giriş",
    "userc.index.807A814Ed58A93Bc": "önbelleği temizle",
    "userc.index.E43Daad59759611C": "sertifikalı değil",
    "userc.secureCenter.F5C70F0Db2F1Ba76": "Giriş şifresini değiştir",
    "userc.secureCenter.1F37Ba427Debf24A": "Hesap bağlama",
    "userc.secureCenter.F9A5Ffa750664004": "Google Authenticator'ı bağlayın",
    "userb.certificationForm.7685Af7D30043Cd6": "Genç Sertifikası",
    "userb.certificationForm.7685Af7D30043Cd7": "Gelişmiş sertifikasyon",
    "userb.certificationForm.70E37C35Abc3F462": "Ülke",
    "userb.certificationForm.F92C25A68E4D08C5": "Şehir",
    "userb.certificationForm.0Ccc6387B38E0318": "Adınız",
    "userb.certificationForm.94C67Bba370Fdd46": "Lisans numarası",
    "userb.certificationForm.B2D4C7Fffe79258E": "Posta",
    "userb.certificationForm.57554268761Fb47A": "Kimliğinizin ön yüzünü yükleyin",
    "userb.certificationForm.D79E90201F5319Bd": "Kimliğinizin arka yüzünü yükleyin",
    "userb.certificationForm.9C6Bad08Af29E20E": "Kimlik kartınızın resmini yükleyin",
    "userb.certificationForm.F96B62Dfa31Cde45": "Gönderimi Onayla",
    "userb.certificationForm.2125D78Ea8C6D287": "Yükleme gereksinimleri",
    "userb.certificationForm.0D64864D3076A824": "Örneği görüntüle",
    "userb.certificationForm.35287Deb72281E87": "1. Kimlik kartı bilgilerini net bir şekilde okuyabilmelidir.",
    "userb.certificationForm.3007F2E01Efc7B57": "2. Fotoğrafın içeriği doğru ve geçerlidir, hiçbir değişikliğe izin verilmez.",
    "userb.certificationForm.25A90660554319F9": "3. Kimlik numarası ve adı açıkça görülebilmeli, JPG/JPEG/PNG'yi desteklemelidir.",
    "userb.certificationForm.5A9Aefbc03C778F7": "girin lütfen",
    "userb.certificationForm.3B7A1D8Ce27C937E": "Gönderim başarılı, lütfen incelemeyi bekleyin",
    "userb.certificationForm.744Bd07D8Abd2513": "Lütfen kimliğinizin ön yüzünü yükleyiniz",
    "userb.certificationForm.6De1C319130F7Fef": "Lütfen kimliğinizin arka yüzünü yükleyiniz",
    "userb.certificationForm.79F8E8B172E30C8A": "Lütfen kimlik kartınızın fotoğrafını yükleyin",
    "userb.certificationForm.46412E16D28A4753": "Fotoğraf yükleme başarısız oldu",
    "userb.changeDialog.676B0Adca1F8Eea4": "şifre değiştir",
    "userb.modifyInfoDialog.F41De3A1Fa69135A": "telefon numarası",
    "userb.modifyInfoDialog.3C3Aac9664A35Ef8": "doğrulayıcı anahtarı",
    "userb.modifyInfoDialog.62149E566814154C": "Doğrulayıcıyı seçin",
    "userb.modifyInfoDialog.8E69468E2435Fc89": "Kimlik Doğrulama uygulamasını açın ve +'ya dokunun.",
    "userb.modifyInfoDialog.7625E5Bdcbfe9963": "'QR kodunu tara'yı veya 'Anahtarı girin'i seçin'",
    "userb.modifyInfoDialog.93F094E2B4369472": "QR kodunu tarayın veya anahtarı girin",
    "userb.modifyInfoDialog.43E4F1532529Ee1E": "kapalı açık",
    "userb.modifyInfoDialog.Bae6358Dfd95025A": "Eski fon şifresi",
    "userb.modifyInfoDialog.E1133Cd4B1C2Abb7": "Yeni fon şifresi",
    "userb.modifyInfoDialog.C1Ce0403A2523Ee0": "Yeni fon şifresini onaylayın",
    "userb.modifyInfoDialog.8Dd453Adc2Cd78Fb": "Fon şifresini doğrulayın",
    "userb.modifyInfoDialog.Bb0D9854C79325Bb": "Lütfen eski fon şifrenizi giriniz",
    "userb.modifyInfoDialog.E66Cb29B00Bc54D1": "Lütfen yeni fon şifrenizi giriniz",
    "userb.modifyInfoDialog.E8Ae2Ec5Df401255": "Lütfen fon şifrenizi giriniz",
    "userb.modifyInfoDialog.258A534498Fe984F": "Lütfen fon şifrenizi doğrulayın",
    "userb.modifyInfoDialog.768Ec43Faa193F08": "Hesap bağlama",
    "userb.modifyInfoDialog.D34746090C25E5Db": "Fon şifresi",
    "userb.modifyInfoDialog.E4F27D8916E5E536": "onaylamak",
    "userb.modifyInfoDialog.Bef7160Ce53773E0": "Doğrulayıcı kapalı",
    "userb.modifyInfoDialog.Be8Bf47Ba6820De1": "Google doğrulaması başarıyla açıldı",
    "userb.modifyInfoDialog.24Dba26593F43C74": "Fon şifresi başarıyla değiştirildi",
    "userb.modifyInfoDialog.5C369D42Dc7Dbe76": "Fon şifresini başarıyla belirleyin",
    "userb.modifyInfoDialog.667Bf8D0B6C36D4C": "Bağlama başarılı",
    "userb.modifyInfoDialog.1C5E05Efee2B0B9F": "Doğrulama kodu hatası",
    "userb.veBottomSheet.Eecc4683C725F6F2": "Sertifika yükleme örneği",
    "userb.veBottomSheet.6F38A0D5858633Ee": "koyu temiz arka plan",
    "userb.veBottomSheet.Af162Dbdfc4Fcae9": "Yeterli aydınlatma",
    "userb.veBottomSheet.0F7Baf0Cee3C8141": "Hizalama yardımcı çerçevesi",
    "userb.veBottomSheet.Ac9E471Eead0Cf11": "Fotoğraflar net",
    "userb.veBottomSheet.50B741382E71F103": "Başarısızlığın olası nedenleri",
    "userb.veBottomSheet.Aa56C68E8Fd4Cfb4": "Kimlik kartı koruyucu kapağı çıkarılmamış",
    "userb.veBottomSheet.5D2F429C75D4C7F2": "Arka plan dağınıklıktan etkileniyor",
    "userb.veBottomSheet.90F42D3Cb7769Dbc": "Kimlik kartı yansıtıcıdır",
    "userb.veBottomSheet.1Ee78Cee782D81B4": "Işık çok karanlık/cep telefonunun gölgesi nedeniyle engelleniyor",
    "userb.veBottomSheet.1A75D2Fc2614592B": "kimlik kartı tutmak",
    "home.home.C3525Eb110Db58Aa": "Daha",
    "home.home.29761532Fc8Ebecc": "ticaret",
    "home.home.F15Ea99Bfa6E1848": "Güvenlik sertifikası, güvenle satın alın",
    "home.home.46E1Ab39Bae7182A": "Hızlı",
    "home.home.Fde2Eb7F6F3Dd800": "Hızlı işlemler ve basit operasyon",
    "home.home.Ea4756Bc1642E0F1": "isim",
    "home.home.B6B1127Ede1C97B4": "son fiyat",
    "home.home.3587Ad870Eb0Ab86": "24 saat artış ve azalma",
    "home.home.Cb1A4E14Ec2Cd2B5": "Keşfetmek",
    "home.home.0F1B1Ac0E96A40D1": "Blockchain",
    "home.home.8Eaefb1107A0E210": "Forex",
    "home.home.Ad65263D034Ea051": "Özel Mesajlar",
    "home.home.5Fc1474Dbf74Ac1A": "Tarım ürünleri",
    "home.home.135318F4664F7773": "enerji",
    "kline.kLine.C96Ff250Cf6B8B84": "24 saatlik en yüksek fiyat",
    "kline.kLine.6E8Be64F19D74565": "24 saatlik işlem hacmi",
    "kline.kLine.08A4Bfbe26A0F262": "24 saat en düşük fiyat",
    "kline.kLine.8701Deb075066331": "24 saatlik işlem hacmi (USDT)",
    "kline.kLine.Ea3C3052136C84Cd": "1 puan",
    "kline.kLine.B49Cca240E013124": "5 puan",
    "kline.kLine.F27A90Be97Ba3D6C": "15 puan",
    "kline.kLine.Ff3E62395B694384": "30 puan",
    "kline.kLine.C1993F9C6Db1A674": "1 saat",
    "kline.kLine.Fd2Cbbae405E284C": "1 gün",
    "kline.kLine.C9Cd71904395042C": "Ocak",
    "kline.kLine.7D8127Ec18C7B8C7": "Satın almak",
    "kline.kLine.E3D3Ffdb9B3E5636": "Miktar (adet)",
    "kline.kLine.6834Fec1838D8029": "Fiyat (USDT)",
    "kline.kLine.16C52Aae474Bc9Cd": "satış emri",
    "kline.kLine.B9Bea1A9A88D1419": "Uzun süre aç",
    "kline.kLine.D36Cfa96Ee2B343B": "Kısa aç",
    "other.navigationBar.F6E62F9642018Cbb": "ön Sayfa",
    "other.navigationBar.0Ec227161A47B49D": "Alıntılar",
    "other.navigationBar.29761532Fc8Ebecc": "ticaret",
    "other.navigationBar.693C7B35Bdf3E3A3": "finans",
    "other.navigationBar.9734C54620Eb09Af": "varlıklar",
    "home.index.25Aa4Fc745150030": "kripto paraların kralı",
    "home.index.2D6Ecf8Faaa8B124": "Daha hızlı, daha iyi, daha güçlü",
    "home.index.1Db26267D1041223": "1.700'den fazla kripto para biriminde kolayca işlem yapın",
    "home.index.F8E18C90C3E35618": "Şimdi kayıt ol",
    "home.index.29761532Fc8Ebecc": "ticaret",
    "home.index.D8A95E4506487452": "Popüler sözleşmeler",
    "home.index.5Bd31Fab681C6E9D": "daha fazla gör",
    "home.index.010Ae07A09Caf221": "ticaret çifti",
    "home.index.F4D875118E6D6Dbb": "Son işlem fiyatı",
    "home.index.3Dfdfb3D6599100E": "24 saatlik fiyat artışı ve düşüşü",
    "home.index.28Fb39Bd41E54242": "akım",
    "home.index.B47A3911Bacd94C6": "Kazanan listesi",
    "home.index.712910D574F43D95": "Sabit gelir",
    "home.index.5507Fd830D7Bf51F": "En iyi stratejiler, kopyalanması kolay",
    "home.index.7De3D636A165E008": "getiri oranı",
    "home.index.B853F96626106912": "Madenciliğe git",
    "home.index.213B5C96Be7Cd1F8": "gün sayısı, yıllık faiz oranı",
    "home.index.213B5C96Be7Cd1F9": "Maksimum satın alınabilir",
    "home.index.Dc710Cffe6313Bb5": "ayrıntıları kontrol et",
    "home.index.B92Ffc837Ecaa1Dd": "haberler",
    "home.index.6C18D67F6D19586B": "Platforma Giriş",
    "home.index.6342E3C33Cb48Cd5": "Dijital para birimi, işlem güvenliğini sağlamak için kriptografik ilkeleri kullanan bir değişim aracıdır. ABD doları gibi fiat para birimlerinin aksine, dijital para birimlerinin fiziksel bir biçimi yoktur. Bitcoin (BTC), Ethereum (ETH) ve Polkadot (DOT) gibi popüler dijital para birimleri, merkezi olmayan bir dijital defter görevi görmek için temel teknoloji olarak blockchain'i kullanıyor. Tüm dijital para işlemleri blok zincirine kaydedilir ve onaylanıp doğrulandıktan sonra değiştirilemez. Bankaların merkezi defterleri tuttuğu geleneksel para birimlerinin aksine, dijital para işlemleri halka açık bir blok zincirinde gerçekleşir ve herkes tarafından erişilebilir.",
    "home.index.198Efc4254683Eef": "Ayrıca mutabakat mekanizmasına göre herkes dijital para işlemlerini doğrulayıp bunları blok zincirine ekleyebilir, böylece merkeziyetsizlik sağlanmış olur. Samsung, BlackRock, Morgan Stanley ve Alphabet gibi önde gelen küresel şirketlerin yatırımları nedeniyle insanlar blockchain teknolojisinin küresel finansal sistemi tamamen değiştireceğine inanıyor. Dijital para birimi, merkezi olmayan finans dünyasının kapısını sizin için açarak, en son teknolojinin getirdiği sonsuz olanakları keşfetmenize olanak tanır.",
    "home.index.E710339D9576292E": "Bizim avantajımız",
    "home.index.F9Ce8B2C63885B26": "güvenli ve istikrarlı",
    "home.index.C4D0A2C9Ff0305Ce": "Üst düzey teknik ekip, kapsamlı güvenlik koruması, yüksek hızlı işlem sentezi motorunun bağımsız araştırması ve geliştirilmesi, büyük eşzamanlı işlemler altında hala istikrarlı ve güvenilir bir şekilde çalışabilir.",
    "home.index.2E8708Ee3F11Cc1A": "Profesyonel ve güvenilir",
    "home.index.Bb12Cbcf89C56C5C": "Blockchain ve finans alanında uzun yıllara dayanan deneyime sahip profesyonel operasyon ekibi, uyumlu bir dijital varlık alım satım lisansına ve %100 rezerv garantisine sahiptir.",
    "home.index.Aa4723Bfbf6808F4": "Gönülden hizmet edin",
    "home.index.1Bf0204B7D3Ee230": "Küresel pazara dönük, birden fazla dili destekleyen, 7/24 çalışan, güçlü topluluk desteği ve profesyonel müşteri hizmetleri.",
    "home.index.2E7Dfd53D26F7224": "yüksek performans",
    "home.index.1C65453103B5Fcc3": "Saniyede 300.000 işlem ve 1 milisaniyeden kısa sipariş yanıt süresi.",
    "home.index.85Ac49Ece9Dcc80B": "İstediğiniz zaman, istediğiniz yerde ticaret yapın!",
    "home.index.2799E18842E4Ebe9": "Hem OKEX Uygulaması hem de web sayfası tarafından desteklenen, anında pozisyon açın!",
    "home.index.2Bf52Ce75E29Fc88": "Şimdi indirmek için kodu tarayın",
    "home.index.88D1422B33571066": "Android",
    "home.index.04Ee893775563F21": "Apple sürümü",
    "home.index.04Ee893775563F22": "ürün hizmeti",
    "home.index.04Ee893775563F23": "finansal Yönetim",
    "home.index.04Ee893775563F24": "geri dönmek",
    "home.index.04Ee893775563F25": "Piyango çekilişini bekliyorum",
    "home.index.04Ee893775563F26": "IOS & ANDROID",
    "homepage.text1": "Bitcoin, Ethereum ve diğer",
	"homepage.text1_2": "1000'den fazla kripto para birimi satın alın",
	"homepage.text2": "Kripto para deneyiminiz için yolculuk",
	"homepage.text4": "Yüksek faizli finansal yönetim",
	"homepage.text5": "Dengeli finansal yönetim, yüksek yıllık getiri",
	"homepage.text6": "Ödül paylaşımı",
	"homepage.text7": "Hızlı işlemler, basit işlemler",
	"homepage.text8": "Daha fazlasını görüntüle",
	"homepage.text9": "Anlık döviz",
	"homepage.text10": "Güvenli sertifikasyon, güvenle satın alma",
	"homepage.text11": "Önde gelen teknoloji ekibi, kapsamlı güvenlik",
	"homepage.text12": "Koruma, bağımsızlık",
	"homepage.text13": "Profesyonel ve güvenilir",
	"homepage.text14": "Bloğ zinciri ve finansal tecrübesi olan uzman işletme ekibi",
	"homepage.text15": "İçten hizmet",
	"homepage.text16": "Küresel pazar odaklı, çoklu dil desteği, 7/24 işletme",
	"homepage.text17": "Yüksek performans",
	"homepage.text18": "Saniyede 300.000 işlem işleme, sipariş yanıt süresi 1 milisaniyeden az",
}
