import Vue from 'vue'
import VueRouter from 'vue-router'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}


Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import('@/views/home/Index.vue'),
    },
    {
        path: '/market',
        name: 'Market',
        component: () => import('@/views/Market.vue'),
    },
    {
        path: '/contract',
        name: 'Contract',
        component: () => import('@/views/contract/Index.vue'),
    }, {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/user/Login.vue'),
    }, {
        path: '/register',
        name: 'Register',
        component: () => import('@/views/user/Register.vue'),
    }, {
        path: '/forget',
        name: 'Forget',
        component: () => import('@/views/user/Forget.vue'),
    },
    {
        path: '/delivery',
        name: 'Login',
        component: () => import('@/views/delivery/Index.vue'),
    },
    {
        path: '/assets',
        name: 'Assets',
        component: () => import('@/views/assets/Index.vue'),
    },
    {
        path: '/assets/details',
        name: 'AssetsDetails',
        component: () => import('@/views/assets/AssetsDetails.vue'),
    }, {
        path: '/assets/records',
        name: 'AssetsRecords',
        component: () => import('@/views/assets/AssetsRecords.vue'),
    }, {
        path: '/coin/details',
        name: 'CoinDetails',
        component: () => import('@/views/assets/CoinDetails.vue'),
    },
    {
        path: '/withdraw',
        name: 'Withdraw',
        component: () => import('@/views/dw/Withdraw.vue'),
    }, {
        path: '/dw/records',
        name: 'DwRecords',
        component: () => import('@/views/dw/Records.vue'),
    }, {
        path: '/invest',
        name: 'Invest',
        component: () => import('@/views/invest/Index.vue'),
    },
    {
        path: '/invest/list',
        name: 'InvestList',
        component: () => import('@/views/invest/ProductList.vue'),
    }, {
        path: '/flash',
        name: 'Flash',
        component: () => import('@/views/flash/Index.vue'),
    }, {
        path: '/flash/records',
        name: 'FlashRecords',
        component: () => import('@/views/flash/Records.vue'),
    }, {
        path: '/transfer',
        name: 'Transfer',
        component: () => import('@/views/transfer/Index.vue'),
    }, {
        path: '/transfer/records',
        name: 'TransferRecords',
        component: () => import('@/views/transfer/Records.vue'),
    }, {
        path: '/notice',
        name: 'Notice',
        component: () => import('@/views/notice/Index.vue'),
    }, {
        path: '/news',
        name: 'News',
        component: () => import('@/views/notice/Index.vue'),
    }, {
        path: '/notice/details',
        name: 'NoticeDetails',
        component: () => import('@/views/notice/Details.vue'),
    }, {
        path: '/about',
        name: 'About',
        component: () => import('@/views/notice/Details.vue'),
    }, {
        path: '/agreement',
        name: 'Agreement',
        component: () => import('@/views/notice/Details.vue'),
    }, {
        path: '/privacy',
        name: 'Privacy',
        component: () => import('@/views/notice/Details.vue'),
    }, {
        path: '/news/details',
        name: 'NewsDetails',
        component: () => import('@/views/notice/Details.vue'),
    }, {
        path: '/support',
        name: 'Support',
        component: () => import('@/views/notice/Index.vue'),
    }, {
        path: '/support/details',
        name: 'SupportDetails',
        component: () => import('@/views/notice/Details.vue'),
    }, {
        path: '/user',
        name: 'User',
        component: () => import('@/views/personal/Index.vue'),
    }, {
        path: '/security',
        name: 'Security',
        component: () => import('@/views/personal/Security.vue'),
    }, {
        path: '/security/:id',
        name: 'Verify',
        component: () => import('@/views/personal/Verify.vue'),
    }, {
        path: '/help',
        name: 'Help',
        component: () => import('@/views/personal/Help.vue'),
    },
    {
        path: '/userinfo',
        name: 'UserInfo',
        component: () => import('@/views/personal/Info.vue'),
    }, {
        path: '/share',
        name: 'Share',
        component: () => import('@/views/personal/Share.vue'),
    }, {
        path: '/deposit',
        name: 'Deposit',
        component: () => import('@/views/dw/Deposit.vue'),
    }, {
        path: '/follow/records',
        name: 'FollowRecords',
        component: () => import('@/views/delivery/FollowRecords.vue'),
    },{
        path: '/download',
        name: 'Download',
        component: () => import('@/views/download/Index.vue'),
    }

]


const router = new VueRouter({
    mode: 'hash',
    // base: "/pc/",
    routes,
    scrollBehavior(to, from, savedPosition) {
        //路由跳转后回到顶部
        return { x: 0, y: 0 }
    }
})

export default router;