export default {
    isLogin: false,
    configData: {},
    baseQuotes: {},
    updateQuotes: {},
    innerHeight: null,
    symbolList: [],
    showBalance: true,
    investData: [],
    userInfo: {},
    language: "",
    languageList: [],
    rateList: [],
    currentRate: {},
    authInfo: {},
    selectSymbolQuote: {},
    selectSymbol: {},
    allSymbolQuote: {},
    lastPrice: {}
}