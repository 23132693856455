export default {
    setIsLogin: (state, v) => state.isLogin = v,
    setConfigData: (state, v) => state.configData = v,
    setBaseQuotes: (state, v) => state.baseQuotes = v,
    setUpdateQuotes: (state, v) => state.updateQuotes = v,
    setInnerHeight: (state, v) => state.innerHeight = v,
    setSymbolList: (state, v) => state.symbolList = v,
    setIsShowBalance: (state, v) => state.showBalance = v,
    setInvestData: (state, v) => state.investData = v,
    setUserInfo: (state, v) => state.userInfo = v,
    setLanguage: (state, v) => state.language = v,
    setLanguageList: (state, v) => state.languageList = v,
    setRateList: (state, v) => state.rateList = v,
    setCurrentRate: (state, v) => state.currentRate = v,
    setAuthInfo: (state, v) => state.authInfo = v,
    setSelectSymbolQuote: (state, v) => state.selectSymbolQuote = v,
    setSelectSymbol: (state, v) => state.selectSymbol = v,
    setAllSymbolQuote: (state, v) => state.allSymbolQuote = v,
    setLastPrice: (state, v) => state.lastPrice = v
}