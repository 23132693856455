import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import Message from './plugins/toast/index'
import router from './router'
import store from './store'
import "./assets/scss/style.scss";
import './mixins'
import beforeInit from './utils/before'
Vue.prototype.$message = Message
Vue.config.productionTip = false

import utils from '@/utils/utils.js';
Vue.prototype._utils = utils;

(async() => {
    await beforeInit();
    new Vue({
        router,
        vuetify,
        store,
        render: h => h(App),
    }).$mount('#app')
})();
