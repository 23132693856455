import zhHans from 'vuetify/es5/locale/zh-Hans';
export default {
    ...zhHans,
    setting: {
        language: "Förenklad kinesiska",
        dialogDetermine: "Säker",
        dreivatives: "Leverans",
        markets: "Citat",
        assets: "tillgångar",
        liquidity: "Likviditetsbrytning",
        helpCentre: "Hjälpcenter",
        announcementCenter: "Annonseringscenter",
        about: "handla om",
        bulletinCentre: "Annonseringscenter",
        serviceAgreement: "Serviceavtal",
        support: "Stöd",
        onlineSupportCustomerService: "onlinetjänst",
        suggestionsAndFeedback: "Förslag och feedback",
        service: "Tjäna",
        download: "ladda ner",
        coinApplication: "Myntnoteringsapplikation",
        privacyStatement: "Sekretesspolicy",
        trade: "kontraktshandel",
        standardConteact: "Hållbar",
        signOut: "logga ut",
        assetWallets: "Tillgångsplånbok",
        financeRecords: "Kontoändringsposter",
        identification: "Autentisering",
        personalCenter: "Personligt center",
        chooseLanguage: "Välj ett språk",
        tips: "antydan",
        dark: "mörkt läge",
        light: "dagsläge",
        languageTitle: "språk",
        convertCurrency: "Konvertera valuta",
        cancel: "Annullera",
        aboutUs: "om oss",
        standard: "Standardens kontinuitet",
        share: "dela med sig",
        introduce: "Plattformsintroduktion",
        set: "uppstart",
        changePassword: "Ändra inloggningslösenord",
        denomination: "Offertvaluta",
        color: "Färgkonfiguration",
        code: "versionsnummer",
        color1: "Röda stiger och gröna fall",
        color2: "Gröna stiger och röda fall",
        setLangue: "språk inställning",
        vip: "VIP-förmåner",
        vip2: "Aktuell VIP-nivå",
        safeMenu: "Säkerhetscenter",
        conventional: "konventionell",
        other: "Övrig",
        quickTips: "Lägg till upp till 3 genvägsfunktionsknappar",
        quickTransaction: "Snabb transaktion",
        safeConvenient: "Säkert och bekvämt",
        timeSharing: "tidsdelning",
        currentLevel: "nuvarande nivå",
        levelBenefits: "%{level}-nivåinkomst",
        teamLevel: "Lagnivå",
        levelLower: "%{level}underordnad",
        rules: "Regelbeskrivning",
        bindWithdrawalAddress: "Bind uttagsadress",
        tipsBindWithdrawalAddress: "Du har inte bundit din uttagsadress ännu, vänligen bind den först",
        walletAddressFormatIncorrect: "Plånbokens adressformat är felaktigt",
        tradeRules1: "Förutsäg uppgång och nedgång i den framtida perioden. Priset vid beställningstidpunkten används som utgångspris, och priset vid sista sekunden av prognosperioden används som avräkningspris. Om likvidpriset är större än utgångspriset innebär det en ökning, och om avräkningspriset är lägre än utgångspriset innebär det en minskning.",
        tradeRules2: "1.vad är ett kontrakt",
        tradeRules3: "Den här plattformen tillhandahåller USDT-baserade eviga kontrakt. Användare kan bedöma ökningen eller minskningen och välja att köpa långa eller sälja korta kontrakt för att få vinster från stigande/fall av digitala tillgångspriser. Eviga kontrakt har inget leveransdatum och användarna kan behålla dem för alltid.",
        tradeRules4: "2.Vad är marginal",
        tradeRules5: "På den virtuella kontraktsmarknaden behöver användarna bara betala en liten mängd medel i en viss andel enligt kontraktspriset som en finansiell garanti för att fullgöra kontraktet, och sedan kan de delta i köpet och försäljningen av kontraktet. Denna typ av medel är den virtuella kontraktsinsättningen. \nKontrakten på den här plattformen använder den separata marginalmodellen för separata konton. Varje valuta motsvarar ett kontraktskonto Kontotillgångarna och positionerna för olika kontraktsvalutor är oberoende av varandra och överföringar och transaktioner mellan olika kontraktskonton påverkar inte varandra. Efter att en användares kontraktskonto har öppnats kommer riskerna och fördelarna med alla positioner som innehas på kontraktskontot att beräknas tillsammans.",
        tradeRules6: "3.Hur man beräknar marginal",
        tradeRules7: "Positionsmarginal = kontrakts nominella värde * antal öppna kontrakt / hävstångsmultiplikator\nfryst marginal = summan av marginalerna för alla framgångsrikt placerade beställningar och öppningsorder i den aktuella beställningen\tillgänglig marginal = kontosaldo - positionsmarginal - fryst marginal",
        tradeRules8: "4.Hur man beräknar marginalgrad",
        tradeRules9: "Marginalgraden är ett mått på risken för användartillgångar. \nMarginalsats = (Orealiserad vinst och förlust + Positionsmarginal + Tillgänglig marginal + Frysmarginal - Likvidationsavgift) / Positionsmarginal \nJu mindre marginalräntan är, desto högre är risken för kontot. När marginalen är mindre än eller lika med 0 % kommer tvångslikvidation att utlösas.",
        tradeRules10: "5.Vad är det uppskattade tvångsparitetspriset?",
        tradeRules11: "Det uppskattade tvångslikvidationspriset, det vill säga det pris som beräknas av systemet som teoretiskt utlöser tvångslikvidation, är endast för referens och kan avvika något från det faktiska värdet.",
        tradeRules12: "6.Vad är totalt eget kapital på kontot och orealiserad vinst och förlust?",
        tradeRules13: "Totalt eget kapital på konto = kontosaldo + orealiserad vinst och förlust\nOrealiserad vinst och förlust är vinsten och förlusten av den position som användaren för närvarande innehar i valutakontraktet. Orealiserad vinst och förlust kommer att förändras med de senaste förändringarna i transaktionspriset. \nOrealiserad vinst och förlust för långa positioner = (1/genomsnittligt positionspris - 1/senaste transaktionspris) * antal kontrakt för långa positioner * kontrakts nominella värde * senaste transaktionspris \n orealiserad vinst och förlust för korta positioner = (1/ senaste transaktionspris - 1/ Genomsnittligt positionspris) * Antal korta kontrakt * Kontrakt nominellt värde * Senaste transaktionspris",
        text1: "konto säkerhet",
        text2: "dag- och nattläge",
        text3: "rensa cache",
        text4: "Är du säker på att du vill logga ut?",
        text5: "Är du säker på att du vill rensa cacheminnet?",
        text6: "flerspråkig",
        text7: "För att skydda din säkerhet rekommenderar vi att du aktiverar minst en tvåfaktorsautentisering"
    },
    login: {
        login: "Logga in",
        register: "Registrera",
        title1: "Handla när som helst, var som helst och se marknadsförhållanden i realtid",
        title2: "Handla när som helst, var som helst och se marknadsförhållanden i realtid",
        title3: "Den mest pålitliga handelsplattformen för kryptovaluta",
        formTitle1: "Kontoinloggning",
        formTitle2: "Välkommen tillbaka! Logga in med din e-post, telefonnummer",
        btn1: "Telefon",
        btn2: "Post",
        btn3: "Logga in",
        btn4: "Registrera",
        btn5: "glömt lösenordet?",
        field1: "Telefonnummer",
        field2: "Lösenord",
        field3: "Post",
        placeholder1: "nation",
        placeholder2: "Vänligen ange telefonnummer",
        placeholder3: "Ange lösenord Tack",
        placeholder4: "vänligen ange din e-post",
        content12: "Vänligen ange telefonnummer",
        content13: "Ange lösenord Tack",
        content14: "vänligen ange din e-post",
        content16: "Finansiella tjänster för digitala tillgångar",
        loginSuccess: "inloggningen lyckades ",
        chooseArea: "Vänligen välj riktnummer",
        placeholder5: "Välj landskod",
        placeholder6: "kom ihåg mitt lösenord",
        placeholder7: "Ladda ner APP",
        placeholder8: "Kontakta Kundservice",
        placeholder9: "Välj land eller region",
        placeholder10: "riktnummer",
        placeholder11: "Plånboksinloggning",
        placeholder12: "Åtgärden misslyckades, byt till",
        placeholder13: "plånbok"
    },
    aboutUs: {
        aboutCoinbeadCenterTitle: "handla om",
        aboutCoinbeadCenterDetails: "Den har världens ledande plattform för handel med blockchaintillgångar och driver hela ekosystemet.",
        footDetails1: "daglig handelsvolym",
        footDetails2: "transaktioner/sekund",
        footDetails3: "Hjälpcenter",
        coinbeadCenterTitle: "",
        coinbeadCenterDetails: "Det är en världsberömd internationell webbplats för digitala tillgångar, som främst tillhandahåller mynt och derivat av digitala tillgångar som Bitcoin (BTC), Litecoin (LTC) och Ethereum (ETH) till globala användare.",
        coinbeadCenterDetailsm: "Genom att anta avancerad teknologi som GSLB, nätverksservrar, nätverkstransaktioner, nätverk, multi-maskin höghastighets minneshandelsmotorer, kalla plånböcker, intelligenta offline hot wallets, etc., ser vi våra kunder med flera terminaler som webbmobiltelefoner och datorer Tillhandahålla säkra, stabila och pålitliga digitala tarifftransaktionstjänster. Samtidigt förbättrar och uppgraderar vi produkter och tjänster baserat på användarförslag och behov för att tillhandahålla bättre tjänster till varje kund, och det finns ingen ände på förbättringen av innovativ användarupplevelse.",
        infrastructureCenterTitle: "infrastruktur",
        infrastructureCenterDetails: "Vårt uppdrag är att tillhandahålla infrastrukturtjänster för hela blockchain-ekosystemet",
        freeCenterTitle: "fri",
        freeCenterDetails: "Vår vision är att få pengar att flöda fritt runt om i världen. Vi tror att spridning av denna frihet kan göra världen till en bättre plats",
        futureCenterTitle: "Banbrytande teknikens framtid",
        futureCenterDetails: "Öppna upp teknikens framtid",
    },
    applicationForListing: {
        title: "Myntnoteringsapplikation",
        projectName: "Projektnamn",
        tokenName: "Tokennamn",
        projectDescription: "projekt beskrivning",
        contractAddress: "Avtalsadress",
        officialWebsite: "officiell hemsida",
        ContactAndEmail: "Kontakt e-post",
        ContactAndName: "Kontaktnamn",
        file: "Ladda upp bilaga",
        pleaseEnter: "Stig på",
        enterFile: "Ladda upp filer",
        upload1: "För närvarande begränsat till 1 filval,",
        upload2: "Den här gången valde jag",
        upload3: "filer,",
        upload4: "Totalt valt",
        upload5: "filer",
    },
    public: {
        submit: "Skicka in",
        copySuccessfully: "Kopierades framgångsrikt",
        copyFailed: "Kopieringen misslyckades",
        noDate: "Inga data",
        submitSuccessfully: "Framgångsrikt inskickat",
        goBack: "gå tillbaka till sista sidan",
        cancel: "Annullera",
        home: "framsida",
        loading: "läser in...",
        confirm: "bekräfta",
        loadingFailed: "nätverksanomali",
        nomore: "inte mer",
        tryAgain: "Uppdatera sidan/stäng programmet och ladda upp igen!",
        hot: "Populära områden",
    },
    inviteFriends: {
        inviteFriends: "Dela med vänner",
        freedomOfWealth: "Låt rikedomen vara fri tillsammans",
        myInvitationCode: "min inbjudningskod",
        clickCopy: "Klicka för att kopiera",
        myInvitationLink: "Min inbjudningslänk",
        clicktoSaveTheQRCode: "Klicka för att spara QR-koden",
        saveQRCode: "Bjud in QR-kod",
        numberOfSubordinates: "Antal underordnade",
        rebateIncome: "Rabattinkomst",
        people: "människor",
        myPromotionRevenue: "Min kampanjinkomst",
        numberOfDirectSubordinates: "Antal direkt underordnade",
        totalNumberOfSubordinates: "Totalt antal underordnade",
        directSubordinateReturnsCommission: "Kommissionsrabatt till direkt underordnade",
        theTotalCommission: "total rabatt",
        checkHistory: "Visa rabattposter",
        history: "kommissionens rekord",
        theLowerAccount: "Underordnat konto",
        type: "typ：",
        serviceCharge: "expeditionsavgift：",
        percentageofCommissionReturned: "Rabattkvot：",
        commissionrebateAmount: "Rabattbelopp：",
        time: "tid：",
        typeName1: "Laddning och provision",
        typeName2: "MT orderhanteringsavgift trenivåfördelning",
        typeName3: "MT Lönsam distribution i tre nivåer",
        typeName4: "Remissbelöningar",
        typeName30: "Laddning och provision",
        typeName31: "Periodisk handelsvinsrabatt",
        typeName32: "Likviditetsutvinning vinst rabatt",
        typeName45: "Rabatt på transaktionsavgift",
        typeName46: "Provisionsrabatt på egen hanteringsavgift",
        typeName50: "Leveransavtal hanteringsavgift rabatt",
        typeName51: "Evig avtalshanteringsavgiftsrabatt",
        typeName52: "Ytterligare provisionsrabatt för hanteringsavgifter för leveransavtal",
        typeName53: "Ytterligare provisionsrabatt för eviga avtalshanteringsavgifter",
        typeName54: "Ytterligare rabatter på eviga avtalshanteringsavgifter",
        shareRules1: "Inbjudan instruktioner",
        shareRules2: "Gå in i inbjudningscentret, kopiera inbjudningslänken eller koden och dela den med dina vänner. Vänner kan bli dina underordnade genom att registrera sig med din inbjudningskod.",
        shareRules3: "Få rabatter",
        shareRules4: "När underordnade genomför transaktioner kan du få motsvarande provisioner, upp till tre nivåer av underordnade stöds. Till exempel, om du bjuder in vän A, A bjuder in B och B bjuder in C, då genomför A, B och C kontrakt och andra transaktioner på plattformen, och ni kan alla få motsvarande provisioner.",
        shareRules5: "Lagnivå",
        shareRules6: "Ju fler underordnade på första nivån du främjar, desto högre lagnivå, och desto högre rabatt kan du få. Lagnivåerna är uppdelade i LV1-LV6. Uppgraderingsreglerna visas i tabellen nedan, där 'N' är antalet underordnade på första nivån som har laddats om och slutfört autentisering med riktiga namn.",
        shareRules7: "När underordnade satsar på leveranskontrakt kan du få provision i proportion till deras insatser.",
        upgradeConditions: "Behöva",
        recommendedNumberOfPeople: "Rekommenderat antal personer"
    },
    suggestionsAndFeedback: {
        suggestionsAndFeedback: "Förslag och feedback",
        text: "Om du stöter på buggar eller har produktförslag under användning, vänligen ge oss feedback.",
        email: "e-postadress",
        enterEmail: "Vänligen ange e-postadress",
        enterCorrectEmail: "Vänligen ange rätt e-postadress",
        title: "titel",
        enterTitle: "Vänligen ange en frågas titel",
        describeTheProblem: "Beskriv problemet",
        enterDescribeTheProblem: "Ange problemet du beskriver",
        submit: "Skicka in",
    },
    help: {
        enterSearch: "Sök",
        title: "Hjälpcenter",
        search: "Ange din fråga",
        text1: "Förstå dina behov och fortsätt att ge dig mer bekväma tjänster"
    },
    announcement: {
        enterSearch: "Sök",
        title: "Annonseringscenter",
        search: "Ange din fråga"
    },
    privacy: {
        title: "Sekretesspolicy",
        content1: "1. Vilken personlig information om användare samlas in?",
        content2: "Personuppgifter samlas in för att tillhandahålla produkter och tjänster och för att kontinuerligt förbättra dem.",
        content3: "Följande är de typer av personlig information vi samlar in：",
        content4: 'Information du lämnar till oss: Vi tar emot och lagrar all information du lämnar i samband med Plattformen. Du kan välja att inte tillhandahålla viss information, men du kanske inte kan använda vissa "denna plattformstjänster" som ett resultat. Exempel på information du lämnar till oss inkluderar：',
        content5: "namn",
        content6: "adress",
        content7: "medborgarskap",
        content8: "ID-foto",
        content9: "Mobilnummer/e-postnummer",
        content10: "Annan information som hjälper oss att identifiera dig",
        content11: "Automatiskt insamlad information: Vi samlar in och lagrar automatiskt vissa typer av information om din användning av plattformstjänsterna. Liksom många webbplatser använder vi cookies och andra unika identifierare. Vi får vissa typer av information när din webbläsare eller enhet får åtkomst till Plattformen. Exempel på information vi samlar in och analyserar inkluderar：",
        content12: "IP-adressen (Internet Protocol) som ansluter en persondator till Internet；",
        content13: "Inloggningsinformation för personlig enhet eller dator, e-postadress, lösenord och plats；",
        content14: "Inställningar för version och tidszon；",
        content15: "Transaktionshistorik.",
        content16: "Information från andra källor: Vi kan samla in information om dig från andra källor, till exempel kredithistorik från kreditupplysningsföretag. Vi kommer att använda denna information för att förhindra och upptäcka bedrägerier.",
        content17: "2. Kan den användas av barn?",
        content18: 'Denna plattform tillåter inte någon person under 18 år att använda "Tjänsten".',
        content19: "3. Vad används cookies och andra identifierare till?",
        content20: "Vi använder cookies och liknande verktyg för att optimera användarupplevelsen, tillhandahålla våra tjänster och förstå hur kunder använder våra tjänster så att vi kan göra riktade förbättringar. Vi använder cookies för att säkerställa att vårt system kan känna igen din webbläsare eller enhet och förse dig med tjänster.",
        content21: 'Vi använder operativa cookies och liknande verktyg (tillsammans "cookies") för att tillhandahålla tjänster, såsom:',
        content22: "För att identifiera dig när du loggar in och använder våra tjänster.",
        content23: "Tillhandahålla anpassade funktioner och tjänster.",
        content24: "Skydda mot bedräglig aktivitet.",
        content25: "Förbättra säkerheten.",
        content26: "Följ dina preferenser (t.ex. valuta och språk).",
        content27: "Vi använder också cookies för att förstå hur användare använder våra tjänster så att vi kan göra riktade förbättringar.",
        content28: "4. Kommer min personliga information att delas?",
        content29: "Användarinformation är en viktig del av vår verksamhet, och vi kommer inte att sälja användarnas personuppgifter till andra. Den här plattformen kommer endast att dela användarnas personliga information med sina dotterbolag eller dotterbolag under följande omständigheter. Dessa dotterbolag eller dotterbolag är skyldiga att följa denna sekretesspolicy eller åtminstone följa praxis som är lika skyddande som de skyddsåtgärder som beskrivs i denna sekretesspolicy.",
        content30: "Tredjepartstjänsteleverantörer: Vi anställer andra företag och individer för att utföra funktioner för vår räkning. Exempel på sådana funktioner inkluderar: analysera data, tillhandahålla marknadsföringshjälp, behandla betalningar, överföra innehåll och bedöma och hantera kreditrisk. Dessa tredjepartstjänsteleverantörer har tillgång till personlig information som behövs för att utföra sina funktioner men får inte använda den för några andra ändamål. Dessutom måste de behandla personlig information i enlighet med denna sekretesspolicy och tillämpliga dataskyddslagar.",
        content31: "Företagsöverföringar: När vår verksamhet fortsätter att växa kan vi sälja eller köpa andra företag eller tjänster. I sådana transaktioner är användarinformation i allmänhet bland de överförda affärstillgångarna men förblir föremål för löftena i eventuella redan existerande sekretessmeddelanden (såvida inte användaren givetvis samtycker till annat). Dessutom, om vårt företag eller i stort sett alla dess tillgångar förvärvades, skulle användarinformation också överföras. Skydd av vårt företag och andra: När vi anser att frigivning av konto och annan personlig information är lämplig för att följa lagen eller våra regulatoriska skyldigheter, upprätthålla eller tillämpa våra användarvillkor och andra avtal, eller våra rättigheter, egendom eller säkerhet användare eller andra När kommer vi att släppa kontot och annan personlig information. Detta inkluderar utbyte av information med andra företag och organisationer för att skydda mot bedrägerier och minska kreditrisken.",
        content32: "5. Internationella överföringar av personuppgifter",
        content33: 'Vi kan komma att överföra dina uppgifter utanför Europeiska ekonomiska samarbetsområdet ("EES"). Vi kommer att införa lämpliga skyddsåtgärder för att säkerställa att sådana överföringar följer tillämpliga dataskyddsregler, såvida inte Europeiska kommissionen har bekräftat att det land till vilket uppgifterna överförs tillhandahåller en adekvat skyddsnivå.',
        content34: "6. Är min personliga information säker?",
        content35: "Vi designade våra system med din säkerhet och integritet i åtanke. Vi använder krypteringsprotokoll och programvara för att sträva efter att skydda säkerheten för personlig information under överföring.",
        content36: "Vi använder alltid fysiska, elektroniska och processuella skyddsåtgärder när vi samlar in, lagrar och avslöjar personlig information. Våra säkerhetsrutiner innebär att vi kan behöva verifiera din identitet innan vi lämnar ut personlig information till dig.",
        content37: "Det viktigaste du kan göra är att skydda dina personliga kontolösenord från obehörig åtkomst. Vi rekommenderar att du ställer in ett unikt lösenord för ditt konto som skiljer sig från andra onlinekonton. Och se till att logga ut efter att du har använt en delad dator.",
        content38: "7. Hur ska jag skydda personlig information?",
        content39: "Om du har några frågor eller invändningar om hur vi samlar in och behandlar personuppgifter, vänligen kontakta kundtjänstpersonalen",
        content40: "Efter att du samtyckt till att vi behandlar dina personuppgifter för ett specifikt ändamål kan du när som helst dra tillbaka din avsikt och vi kommer att sluta behandla dina uppgifter för det ändamålet.",
        content41: "Dessutom har du rätt att begära tillgång till, rättelse och radering av personuppgifter och att begära dataportabilitet, i enlighet med gällande lagar. Du kan också invända mot vår behandling av dina personuppgifter, eller begära att vår behandling av dina personuppgifter begränsas under vissa omständigheter.",
        content42: "8. EU GDPR och brittisk dataskyddslag – Rättslig grund",
        content43: "EU GDPR och brittisk dataskyddslagstiftning kräver att vi följer den rättsliga grunden när vi använder personlig information. Vår grund beror på de specifika syften för vilka personuppgifterna används. Dessa baser inkluderar:",
        content44: "Utförande av ett kontrakt - för att tillhandahålla eller kommunicera med dig om produkter eller tjänster, inklusive vår användning av din personliga information för att acceptera och behandla beställningar och behandla betalningar.",
        content45: "Våra legitima affärsintressen och användarintressen - Vi upptäcker och förhindrar bedrägerier och missbruk för att skydda våra användares, oss själva eller andras säkerhet.",
        content46: "Ditt samtycke – Vi kräver ditt samtycke för att behandla dina personuppgifter för de specifika syften som kommuniceras till dig. När du har gett oss ditt samtycke till att behandla dina personuppgifter för ett specifikt ändamål kan du när som helst återkalla ditt samtycke och vi kommer att sluta behandla dina uppgifter för det ändamålet.",
        content47: "Följ juridiska skyldigheter - Vi använder dina personuppgifter enligt lag. Till exempel samlar vi in ​​bankkontoinformation för identitetsverifiering.",
        content48: "Ovanstående och andra rättsliga grunder beror på de specifika syften för vilka vi använder personuppgifter.",
        content49: "9. Användarvillkor, meddelanden och ändringar",
        content50: "Din användning och eventuella tvister om integritet är föremål för detta uttalande och våra användarvillkor. Om du har några frågor om integriteten för denna plattform, vänligen kontakta oss med en detaljerad förklaring så löser vi gärna problemet åt dig. Du har också rätt att kontakta din lokala dataskyddsmyndighet.",
        content51: "Vår verksamhet förändras ständigt, och det kommer även vår sekretesspolicy. Du bör besöka vår webbplats ofta för att se de senaste ändringarna. Om du inte godkänner det reviderade innehållet bör du sluta komma åt det omedelbart. Efter att den uppdaterade versionen av sekretesspolicyn har släppts innebär din fortsatta åtkomst att du godkänner det uppdaterade innehållet och samtycker till att följa den uppdaterade integritetspolicyn. Om inget annat anges gäller den aktuella integritetspolicyn för all information vi har om dig och ditt konto.",
    },
    serviceAgreement: {
        title: "Serviceavtal",
        content1: 'Denna webbplats är en plattform specifikt för användare att utföra digitala tillgångstransaktioner och tillhandahålla relaterade tjänster (hädanefter kallad "tjänsten" eller "tjänsten"). För att underlätta beskrivningen av detta avtal använder denna webbplats gemensamt "vi" eller andra förstapersonspronomen i detta avtal. Så länge som den fysiska personen eller annan subjekt som loggar in på webbplatsen är en användare av denna webbplats, för bekvämligheten att uttrycka detta avtal, kommer "du" eller annan andra person att användas nedan. För att underlätta uttrycket av detta avtal kallas vi och du kollektivt för "Parterna" i detta Avtal, och vi eller du hänvisas individuellt till som en "Part".',
        content2: "viktigt tips：",
        content3: "Vi vill påminna dig här：",
        content4: "1 De digitala tillgångarna i sig är inte utfärdade av någon finansiell institution eller företag eller denna webbplats",
        content5: "2 Digitala tillgångsmarknader är nya, obekräftade och kanske inte växer;",
        content6: "3 Digitala tillgångar används huvudsakligen i stor utsträckning av spekulanter, med relativt liten användning på detaljhandels- och kommersiella marknader. Digitala tillgångstransaktioner är extremt riskabla. De handlas kontinuerligt under hela dagen, utan begränsningar för uppgång och fall, och priserna påverkas lätt av marknaden. beslutsfattare och global regeringspolitik fluktuation;",
        content7: "4 Om företaget efter eget gottfinnande fastställer att du har brutit mot detta avtal, eller att tjänsterna som tillhandahålls av denna webbplats eller din användning av tjänsterna som tillhandahålls av denna webbplats är olagliga enligt lagarna i din jurisdiktion, har företaget rätt att avbryta eller avsluta dina rättigheter när som helst konto, eller avbryta eller avsluta din användning av tjänsterna eller transaktioner med digitala tillgångar som tillhandahålls av denna webbplats. Alla från [Kina, Taiwan, Israel, Irak, Bangladesh, Bolivia, Ecuador, Kirgizistan, Sevastopol och Storbritannien (detaljhandelsanvändare)] är förbjuden att använda kontraktstransaktionstjänsterna som tillhandahålls av denna webbplats. Ovannämnda lista över länder eller regioner kommer att ändras beroende på policyer och produkttyper för olika länder eller regioner. Vi kanske inte meddelar dig specifikt vid den tidpunkten, så var uppmärksam på uppdateringar av detta avtal i god tid.",
        content8: "Handel med digitala tillgångar innebär extremt höga risker och är inte lämpligt för de flesta. Du förstår och förstår att denna transaktion kan resultera i partiell eller total förlust, så du bör bestämma transaktionsbeloppet baserat på graden av förlust du har råd med. Du vet och förstår att digitala tillgångar kommer att generera derivatrisker, så om du har några frågor rekommenderas det att först ta hjälp av professionella rådgivare. Utöver de ovan nämnda riskerna finns det dessutom risker som inte går att förutse. Du bör noggrant överväga och använda ett tydligt omdöme för att utvärdera din ekonomiska situation och de ovan nämnda riskerna innan du fattar något beslut att köpa eller sälja digitala tillgångar, och bära alla förluster som uppstår. Vi tar inget ansvar för detta.",
        content9: "Vänligen notera:",
        content10: "1 Du förstår att denna webbplats endast används som en plats för dig att få information om digital tillgång, hitta transaktionsparter, förhandla och genomföra transaktioner på digitala tillgångar. Denna webbplats deltar inte i några av dina transaktioner, så du bör använda ditt eget försiktiga omdöme för att fastställa relevanta digitala tillgångar och/eller transaktioner eller informationens äkthet, laglighet och giltighet, och ska bära det ansvar och förluster som uppstår på egen hand.",
        content11: "2 Eventuella åsikter, nyheter, diskussioner, analyser, priser, rekommendationer och annan information på denna webbplats är allmänna marknadskommentarer och utgör inte investeringsråd. Vi är inte ansvariga för några förluster som uppstår direkt eller indirekt från att vi litar på denna information, inklusive men inte begränsat till eventuell förlust av vinst.",
        content12: "3 Denna webbplats förbehåller sig rätten att fatta egna beslut, modifiera eller ändra innehållet när som helst. Vi har vidtagit rimliga åtgärder för att säkerställa riktigheten av informationen på webbplatsen, men vi kan inte garantera dess riktighet, och vi kommer inte att ansvara för förlust eller skada som orsakas av informationen på denna webbplats eller bristen på information. Direkta eller indirekta förluster som uppstår på grund av förseningar eller misslyckanden i att länka till Internet, sända eller ta emot meddelanden och information.",
        content13: "4 Det finns också risker med att använda internetbaserade handelssystem, inklusive men inte begränsat till mjukvara, hårdvara och Internetanslutningsfel. Eftersom vi inte har någon kontroll över tillförlitligheten och tillgängligheten för Internet, tar vi inget ansvar för förvrängningar, förseningar och anslutningsfel.",
        content14: "5 Det är förbjudet att använda denna webbplats för att ägna sig åt all olaglig handelsverksamhet eller olaglig verksamhet såsom penningtvätt, smuggling, kommersiella mutor, etc. Om några misstänkta olagliga transaktioner eller olagliga aktiviteter upptäcks kommer denna webbplats att använda olika tillgängliga medel, inklusive men inte begränsat till att frysa konton och meddela relevanta parter. Myndigheter etc. tar vi inte på oss allt ansvar som uppstår därav och förbehåller oss rätten att utöva ansvar från relevanta personer.",
        content15: "6 Det är förbjudet att använda den här webbplatsen för att uppsåtligt manipulera marknaden, olämpliga transaktioner och andra oetiska handelsaktiviteter. Om sådana incidenter upptäcks kommer denna webbplats att varna, begränsa transaktioner och stänga alla oetiska beteenden såsom skadlig prismanipulation och skadlig påverkan på Vi tar inte på oss allt ansvar som härrör från förebyggande skyddsåtgärder såsom kontoavstängning och förbehåller oss rätten att utöva ansvar från relevanta personer.",
        content16: "1. Allmänna principer",
        content17: '1.1 "Användaravtal" (hädanefter kallat "detta avtal" eller "dessa villkor"), består av huvudtexten, "Sekretessvillkor", "Känn din kund och policy mot penningtvätt" och andra policyer som har publicerats eller kan komma att publiceras i framtiden på denna webbplats. Den består av olika regler, uttalanden, instruktioner m.m.',
        content18: "1.2 Du bör läsa detta avtal noggrant innan du använder tjänsterna som tillhandahålls av denna webbplats. Om du inte förstår någonting eller om det på annat sätt är nödvändigt, kontakta en professionell advokat. Om du inte godkänner detta avtal och/eller dess ändring när som helst, vänligen sluta omedelbart använda tjänsterna som tillhandahålls av denna webbplats eller inte längre logga in på denna webbplats. När du väl loggar in på denna webbplats, använder någon av tjänsterna på denna webbplats eller något annat liknande beteende, betyder det att du har förstått och fullt samtyckt till innehållet i detta avtal, inklusive alla ändringar som gjorts av denna webbplats i detta avtal när som helst.",
        content19: '1.3 Du kan bli medlem på denna webbplats (hädanefter kallad "Medlem") genom att fylla i relevant information i enlighet med kraven på denna webbplats och framgångsrikt registrera dig efter andra relevanta procedurer. Klicka på knappen "Godkänn" under registreringsprocessen betyder att du elektroniskt har undertecknat ett avtal med företaget; eller när du klickar på någon knapp märkt "Godkänn" eller liknande betydelse under användningen av denna webbplats eller faktiskt använder tjänsterna som tillhandahålls av denna webbplats på andra sätt som denna webbplats tillåter, det betyder att du till fullo förstår, accepterar och accepterar alla villkor i detta avtal. Frånvaron av din handskrivna signatur kommer inte att påverka den juridiska bindande kraften av detta avtal för dig.',
        content20: '1.3 Du kan bli medlem på denna webbplats (hädanefter kallad "Medlem") genom att fylla i relevant information i enlighet med kraven på denna webbplats och lyckas registrera dig efter andra relevanta förfaranden. Klicka på knappen "Godkänn" under registreringsprocessen betyder att du elektronis kthar underteckna ett avtal med företaget, eller när du klickar på någon knapp märkt "Godkänn" eller liknande betydelse under användningen av denna webbplats eller använder tjänster som tillhandahålls av denna webbplats på andra sätt som denna webbplat s tillåter, det betyder att du till fullo förstår , accepterar och accepterar alla villkor i detta avtal.',
        content21: "1.5 Endast medlemmar som blir medlemmar på denna webbplats kan använda den digitala tillgångshandelsplattformen som tillhandahålls av denna webbplats för transaktioner och njuta av andra tjänster som föreskrivs av denna webbplats som endast är tillgängliga för medlemmar; icke-medlemmar kan bara logga in på webbplatsen, surfa på webbplatsen och andra tjänster som anges av denna webbplats.",
        content22: "1.6 Genom att registrera och använda alla tjänster och funktioner som tillhandahålls av denna webbplats kommer du att anses ha läst, förstått och:",
        content23: "1.6.1 Acceptera att vara bunden av alla villkor i detta avtal.",
        content24: "1.6.2 Du bekräftar att du är över 18 år gammal eller har laglig ålder för att ingå kontrakt enligt olika tillämpliga lagar. Din registrering på denna webbplats, försäljning eller köp, publicering av information etc. och accepterande av tjänsterna på denna webbplats bör vara i överensstämmelse med Har jurisdiktion över relevanta lagar och regler i ett suveränt land eller en region och har full förmåga att acceptera dessa villkor, ingå transaktioner och använda denna webbplats för att utföra transaktioner med digitala tillgångar.",
        content25: "1.6.3 Du garanterar att alla digitala tillgångar som tillhör dig involverade i transaktionen är lagligt erhållna och har äganderätt.",
        content26: "1.6.4 Du samtycker till att du är ensam ansvarig för din egen handel eller icke-handelsverksamhet och för eventuella vinster eller förluster.",
        content27: "1.6.5 Du bekräftar att informationen du lämnar vid registreringen är sann och korrekt.",
        content28: "1.6.6 Du samtycker till att följa kraven i relevant lag, inklusive rapportering av eventuella handelsvinster för skatteändamål.",
        content29: "1.6.7 Du samtycker till att inte delta i eller delta i beteenden eller aktiviteter som skadar denna webbplats eller företagets intressen när som helst, oavsett om de är relaterade till tjänsterna som tillhandahålls av denna webbplats.",
        content30: "1.6.8 Detta avtal reglerar endast rättigheterna och skyldigheterna mellan dig och oss, och involverar inte rättsförhållanden och juridiska tvister som uppstår från transaktioner med digitala tillgångar mellan användare av denna webbplats och andra webbplatser och dig.",
        content31: "2. Avtalsändringar",
        content32: "Vi förbehåller oss rätten att revidera detta avtal då och då och tillkännage det på webbplatsen utan att meddela dig separat. Efter revideringen kommer revisionstiden att markeras på [Sista uppdateringstid] som visas i detta avtal, och det tar automatiskt effekt när den publiceras på webbplatsen. Du bör bläddra och vara uppmärksam på uppdateringstiden och det uppdaterade innehållet i detta avtal från tid till annan. Om du inte samtycker till de relevanta ändringarna bör du omedelbart sluta använda tjänsterna på denna webbplats; din fortsatta användning av tjänsterna på denna webbplats webbplats betyder att du accepterar och accepterar att vara bunden av det reviderade avtalet. .",
        content33: "3. Registrering",
        content34: "3.1 Registreringskvalifikationer",
        content35: "Du bekräftar och lovar att när du slutför registreringsprocessen eller faktiskt använder tjänsterna som tillhandahålls av denna webbplats på andra sätt som tillåts av denna webbplats, bör du ha möjlighet att underteckna detta avtal och använda tjänsterna på denna webbplats enligt gällande lagar. fysisk person, juridisk person eller annan organisation. När du klickar på knappen Godkänn att registrera, betyder det att du eller din auktoriserade agent har godkänt innehållet i avtalet och har registrerats och använt tjänsterna på denna webbplats av hans agent. Om du inte har de ovan nämnda ämneskvalifikationerna ska du och din auktoriserade agent bära alla konsekvenser som blir följden därav, och företaget förbehåller sig rätten att avsluta eller permanent frysa ditt konto och hålla dig och din auktoriserade agent ansvariga. .",
        content36: "3.2 Registreringssyfte",
        content37: "Du bekräftar och lovar att din registrering på denna webbplats inte är i syfte att bryta mot lagar och förordningar eller störa ordningen för digitala tillgångstransaktioner på denna webbplats.",
        content38: "3.3 Registreringsprocess",
        content39: "3.3.1 Du samtycker till att tillhandahålla en giltig e-postadress, mobiltelefonnummer och annan information som krävs på användarregistreringssidan på denna webbplats. Du kan använda e-postadressen, mobiltelefonnumret du angett eller bekräftat eller andra metoder som denna webbplats tillåter som inloggning metod för att komma in på den här webbplatsen. Vid behov, i enlighet med relevanta lagar och förordningar i olika jurisdiktioner, måste du tillhandahålla ditt riktiga namn, identitetshandling och annan relevant information som anges i lagar och förordningar, integritetsklausuler och anti-penningtvättsklausuler, och ständigt uppdatera registreringsinformationen i ett snabbt, detaljerat och korrekt sätt. All ursprungligt inskriven information kommer att hänvisas till som registreringsinformation. Du är ansvarig för äktheten, fullständigheten och riktigheten av sådan information och ska bära alla direkta eller indirekta förluster och negativa konsekvenser som uppstår därav.",
        content40: "3.3.2 Om lagar, förordningar, regler, order och andra föreskrifter i det suveräna landet eller den region där du befinner dig har krav på riktiga namn för mobiltelefonnummer, samtycker du till att det registrerade mobiltelefonnumret som tillhandahålls har registrerats under riktiga namn. du inte tillhandahåller det i enlighet med bestämmelserna, alla konsekvenser som orsakas för dig kommer att vara direkta eller indirekta förluster och negativa konsekvenser ska bäras av dig.",
        content41: "3.3.3 Om du tillhandahåller den information som krävs för registrering lagligt, fullständigt och effektivt och har verifierat den, har du rätt att få kontonumret och lösenordet för denna webbplats. När du får kontonumret och lösenordet för denna webbplats anses din registrering vara lyckats och du kan logga in som medlem på denna webbplats.",
        content42: "3.3.4 Du samtycker till att ta emot e-postmeddelanden och/eller korta meddelanden som skickas av denna webbplats relaterade till hantering och drift av denna webbplats.",
        content43: "4. Service",
        content44: "Den här webbplatsen tillhandahåller endast onlinehandelsplattformstjänster för dina aktiviteter för handel med digitala tillgångar (inklusive men inte begränsat till handel med digitala tillgångar och andra tjänster) via denna webbplats.",
        content45: "4.1 Tjänstens innehåll",
        content46: "4.1.1 Du har rätt att bläddra i realtidsmarknadsförhållandena och transaktionsinformation för olika digitala tillgångsprodukter på denna webbplats, och du har rätt att skicka instruktioner för digitala tillgångstransaktioner och slutföra digitala tillgångstransaktioner via denna webbplats.",
        content47: "4.1.2 Du har rätt att se informationen under ditt medlemskonto på denna webbplats och att använda de funktioner som tillhandahålls av denna webbplats för att fungera.",
        content48: "4.1.3 Du har rätt att delta i webbplatsaktiviteter som organiseras av denna webbplats i enlighet med aktivitetsreglerna som publiceras på denna webbplats.",
        content49: "4.1.4 Denna webbplats lovar att förse dig med andra tjänster.",
        content50: "4.2 Serviceregler Du lovar att följa följande serviceregler på denna webbplats:",
        content51: "4.2.1 Du ska följa lagar, förordningar och policykrav, säkerställa lagligheten av källorna till alla digitala tillgångar på ditt konto och ska inte delta i olagliga eller andra aktiviteter som skadar rättigheterna och intressena för denna webbplats eller tredje parter på denna webbplats eller genom att använda dess tjänster, inklusive men inte begränsat till: Du är inte begränsad till att skicka eller ta emot olaglig, olaglig eller kränkande information om andras rättigheter, skicka eller ta emot pyramidspel eller annan skadlig information eller anmärkningar, använda eller förfalska e-posthuvudinformation för denna webbplats utan tillstånd från denna webbplats, etc.",
        content52: "4.2.2 Du bör följa lagar och förordningar och korrekt använda och behålla ditt kontonummer, inloggningslösenord, fondlösenord, mobilnumret bundet till dig när du registrerar dig och mobiltelefonens verifieringskod som du fått av din mobiltelefon. Du är fullt ansvarig för alla åtgärder och konsekvenser av att använda ditt konto och inloggningslösenord, fondlösenord och mobiltelefonverifieringskod. När du upptäcker att den här webbplatsens kontonummer, inloggningslösenord eller fondlösenord eller verifieringskod används av en tredje part utan ditt tillstånd, eller det finns andra kontosäkerhetsproblem, bör du omedelbart och effektivt meddela denna webbplats och begära att webbplatsen stängs av tjänsten för denna webbplats konto. . Denna webbplats har rätt att vidta åtgärder på din begäran inom rimlig tid, men denna webbplats bär inget ansvar för konsekvenserna (inklusive men inte begränsat till eventuella förluster som du lider) som har inträffat innan åtgärder vidtas. Du får inte donera, låna, leasa, överföra eller på annat sätt förfoga över ditt konto på denna webbplats till andra utan samtycke från denna webbplats.",
        content53: "4.2.3 Du samtycker till att vara ansvarig för alla aktiviteter som sker under ditt konto och lösenord på denna webbplats (inklusive men inte begränsat till informationsutlämnande, publicering av information, onlineklick för att godkänna eller inlämnande av olika regler och avtal, onlineförnyelse av avtal eller köp av tjänster etc.).",
        content54: "4.2.4 När du utför transaktioner med digitala tillgångar på denna webbplats får du inte med uppsåt störa den normala utvecklingen av transaktioner med digitala tillgångar eller störa transaktionsordningen; du får inte störa den normala driften av denna webbplats eller störa andra användares användning av denna webbplats. tjänster på något tekniskt sätt eller på andra sätt; du får inte använda fiktiva Med skadlig uppsåt förtala denna webbplatss goodwill genom fakta och andra medel.",
        content55: "4.2.5 Om du har en tvist med andra användare på grund av onlinetransaktioner får du inte begära att denna webbplats tillhandahåller relevant information via icke-rättsliga eller administrativa kanaler.",
        content56: "4.2.6 Eventuell skatt som ska betalas, såväl som alla utgifter för hårdvara, mjukvara, tjänster och andra som uppstår under din användning av tjänsterna som tillhandahålls av denna webbplats ska enbart bedömas och bäras av dig.",
        content57: "4.2.7 Du ska följa detta avtal och andra tjänstevillkor och driftregler som publiceras och uppdateras av denna webbplats från tid till annan och har rätt att när som helst avsluta användningen av tjänsterna som tillhandahålls av denna webbplats.",
        content58: "4.3 produktregler",
        content59: "4.3.1 Bläddra i transaktionsinformation",
        content60: "När du bläddrar i transaktionsinformation på denna webbplats bör du noggrant läsa allt innehåll i transaktionsinformationen, inklusive men inte begränsat till pris, provisionsvolym, hanteringsavgift, köp- eller försäljningsriktning, och först efter att du helt accepterat allt innehåll i transaktionsinformationen Avslut kan göras med ett knapptryck.",
        content61: "4.3.2 Visa transaktionsdetaljer",
        content62: "Du kan se motsvarande transaktionsposter via ditt konto.",
        content63: "5. Rättigheter och skyldigheter för denna webbplats",
        content64: "5.1 Om du inte har de registreringskvalifikationer som anges i detta avtal har denna webbplats rätt att vägra din registrering. För dem som redan har registrerat sig har denna webbplats rätt att avsluta ditt medlemskonto. Denna webbplats förbehåller sig rätten att hålla dig eller din auktoriserade agent ansvarig. Samtidigt förbehåller sig denna webbplats rätten att besluta om din registrering ska accepteras under några andra omständigheter.",
        content65: "5.2 Baserat på denna webbplatss egen bedömning, om denna webbplats finner att du eller dina associerade kontoanvändare inte är lämpliga för högriskinvesteringar, har vi rätt att stänga av eller avsluta ditt konto och användningen av alla associerade konton.",
        content66: "5.3 När denna webbplats upptäcker att kontoanvändaren inte är den första registranten av kontot, har den rätt att stänga av eller avsluta användningen av kontot.",
        content67: "5.4 När denna webbplats rimligen misstänker att informationen du lämnat är felaktig, osann, ogiltig eller ofullständig genom teknisk testning, manuell provtagning och andra testmetoder, har den rätt att meddela dig att korrigera eller uppdatera informationen eller att avbryta eller avsluta tillhandahållandet av denna webbplats tjänster till dig.",
        content68: "5.5 Denna webbplats förbehåller sig rätten att korrigera all information som visas på denna webbplats om det upptäcks att det finns ett uppenbart fel.",
        content69: "5.6 Denna webbplats förbehåller sig rätten att när som helst ändra, stänga av eller avsluta tjänsterna på denna webbplats. Denna webbplats behöver inte meddela dig i förväg för att utöva rätten att ändra eller avsluta tjänster. Om denna webbplats avslutar en eller flera tjänster av denna webbplats, kommer uppsägningen att ske från denna webbplats på webbplatsen. Gäller från dagen för publiceringen av meddelandet om uppsägning.",
        content70: "5.7 Denna webbplats kommer att vidta nödvändiga tekniska medel och förvaltningsåtgärder för att säkerställa den normala driften av denna webbplats, tillhandahålla nödvändig och tillförlitlig handelsmiljö och handelstjänster och upprätthålla ordningen för digitala tillgångstransaktioner.",
        content71: "5.8 Om du inte loggar in på denna webbplats med ditt medlemskonto och lösenord under ett år i följd, har denna webbplats rätt att avsluta ditt konto. Efter att kontot har avslutats har denna webbplats rätt att öppna motsvarande medlemsnamn för andra användare för registrering och användning.",
        content72: "5.9 Denna webbplats säkerställer säkerheten för dina digitala tillgångar genom att stärka teknikinvesteringar, förbättra säkerhetsåtgärder och andra åtgärder, och kommer att meddela dig i förväg när förutsebara säkerhetsrisker uppstår på ditt konto.",
        content73: "5.10 Denna webbplats har rätt att när som helst radera all slags innehållsinformation på denna webbplats som inte följer lagar och förordningar eller reglerna på denna webbplats. Denna webbplats behöver inte meddela dig i förväg för att utöva sådana rättigheter.",
        content74: "5.11 Denna webbplats har rätt att begära att du tillhandahåller mer information eller material i enlighet med lagar, förordningar, regler, order och andra regulatoriska krav i ditt suveräna land eller din region, och att vidta rimliga åtgärder för att följa lokala regulatoriska krav. är skyldiga att samarbeta; denna webbplats har rätt att stänga av eller permanent sluta öppna vissa eller alla av denna webbplatss tjänster för dig i enlighet med kraven i lagar, förordningar, regler, order och andra specifikationer i ditt suveräna land eller din region.",
        content75: "5.12 Denna webbplats förbehåller sig rätten att stänga ditt konto med sju arbetsdagars varsel efter eget gottfinnande. Därför har du sju arbetsdagar på dig att avbryta beställningar och stänga positioner. Om du inte har avbrutit beställningen och stängt positionen när perioden löper ut, kommer vi att tvångsavbryta beställningen och stänga positionen och returnera de återstående digitala tillgångarna på ditt konto till dig.",
        content76: "5.13 För att skydda näringsidkares rättigheter och intressen har denna webbplats rätt att göra justeringar av onlineprodukter under speciella omständigheter (såsom systemfel, nätverksfel, extrema marknadsförhållanden, etc.), såsom: kontrakt för tidig leverans och avräkning, avtalstyper och avtal för tidig leverans och avveckling Perioden och avräknings- och leveranspriset är föremål för tillkännagivandet på denna webbplats.",
        content77: "6. Ersättning",
        content78: "6.1 Under inga omständigheter kommer vårt ansvar gentemot dig för direkta skador att överstiga de totala serviceavgifterna som vi debiterade dig för din användning av denna webbplats under en period av tre (3) månader.",
        content79: "6.2 Om du bryter mot detta avtal eller andra lagar och förordningar måste du kompensera oss för minst 2 miljoner USD och stå för alla kostnader (inklusive advokatkostnader etc.) Om det inte räcker för att kompensera för de faktiska förlusterna måste du göra är med.",
        content80: "7. Rätt att begära förbudsföreläggande",
        content81: "Vi och du är båda medvetna om att sedvanliga rättsmedel för ditt avtalsbrott eller eventuella avtalsbrott kanske inte är tillräckliga för att kompensera för alla förluster vi lider, så vi har rätt att begära föreläggande som tillåts enligt sedvanelag eller rättvisa i i händelse av ditt avtalsbrott eller eventuellt avtalsbrott, alla andra åtgärder.",
        content82: "8. Ansvarsbegränsning och ansvarsfriskrivning",
        content83: "8.1 Du förstår och samtycker till att vi under några omständigheter inte kommer att vara ansvariga för följande frågor:",
        content84: "8.1.1 förlust av inkomst;",
        content85: "8.1.2 handelsvinster eller kontraktsförluster;",
        content86: "8.1.3 Förluster orsakade av affärsavbrott;",
        content87: "8.1.4 förlust av förväntade monetära besparingar;",
        content88: "8.1.5 Förluster orsakade av informationsproblem;",
        content89: "8.1.6 förlust av möjligheter, goodwill eller rykte;",
        content90: "8.1.7 korruption eller förlust av data;",
        content91: "8.1.8 kostnaden för att köpa ersättningsprodukter eller tjänster;",
        content92: "8.1.9 Alla indirekta, speciella eller tillfälliga förluster eller skador som härrör från skadestånd (inklusive vårdslöshet), avtalsbrott eller någon annan orsak, oavsett om sådan förlust eller skada rimligen är förutsägbar av oss eller inte; oavsett om vi har blivit informerade om förekomsten av sådan eller inte. i förväg risk för förlust eller skada.",
        content93: "8.1.1 Artiklarna till 8.1.9 är oberoende av varandra.",
        content94: "8.2 Du förstår och samtycker till att vi inte är ansvariga för några skador som orsakats dig på grund av någon av följande omständigheter:",
        content95: "8.2.1 Dina specifika transaktioner kan innebära stora brott mot lagar eller avtalsbrott.",
        content96: "8.2.2 Ditt beteende på denna webbplats kan vara olagligt eller oetiskt.",
        content97: "8.2.3 Kostnader och förluster som uppstår genom att köpa eller erhålla data, information eller genomföra transaktioner genom tjänsterna på denna webbplats eller genom ersättningsåtgärder.",
        content98: "8.2.4 Ditt missförstånd om tjänsterna på denna webbplats.",
        content99: "8.2.5 Alla andra förluster relaterade till tjänsterna som tillhandahålls av denna webbplats som inte orsakas av oss.",
        content100: "8.3 Vi är ansvariga för underhåll av informationsnätverksutrustning, fel på informationsnätverksanslutningen, dator-, kommunikations- eller annat systemfel, strömavbrott, väderförhållanden, olyckor, strejker, arbetskonflikter, upplopp, uppror, upplopp, otillräcklig produktivitet eller produktionsmaterial, brand, översvämningar , stormar, explosioner, krig, bank- eller andra partnerskäl, kollaps på marknaden för digitala tillgångar, statliga åtgärder, order från rättsliga eller administrativa myndigheter, andra åtgärder som ligger utanför vår kontroll eller som vi inte har någon förmåga att kontrollera, eller skäl från tredje part. ta inget ansvar för oförmågan eller förseningen i service, eller dina förluster.",
        content101: "8.4 Vi kan inte garantera att all information, program, texter etc. som finns på denna webbplats är helt säkra och inte kommer att störas och förstöras av några virus, trojanska hästar och andra skadliga program. Därför, om du loggar in och använder någon tjänst på denna webbplats eller ladda ner och använda eventuella nedladdade program, information, data, etc. är alla dina egna beslut och du bär riskerna och eventuella förluster på egen risk.",
        content102: "8.5 Vi lämnar inga garantier eller åtaganden om någon information, produkter och tjänster från tredje parts webbplatser som är länkade till denna webbplats eller någon annan form av innehåll som inte tillhör oss. Om du använder tjänster, information och tjänster som tillhandahålls av tredje part. -partswebbplatser, produkter etc. är alla dina egna beslut och du bär allt ansvar som följer därav.",
        content103: "8.6 Vi lämnar inga uttryckliga eller underförstådda garantier för din användning av tjänsterna på denna webbplats, inklusive men inte begränsat till lämpligheten av tjänsterna som tillhandahålls av denna webbplats, inga fel eller utelämnanden, kontinuitet, noggrannhet, tillförlitlighet och lämplighet för en viss syfte. Samtidigt gör vi inga åtaganden eller garantier för giltigheten, noggrannheten, riktigheten, tillförlitligheten, kvaliteten, stabiliteten, fullständigheten och aktualiteten för den teknik och information som ingår i tjänsterna som tillhandahålls av denna webbplats. Om du ska logga in eller använda tjänsterna som tillhandahålls av denna webbplats är ditt personliga beslut och du bär dina egna risker och eventuella förluster. Vi lämnar inga uttryckliga eller underförstådda garantier angående marknaden, värde och pris på digitala tillgångar. Du förstår och förstår att den digitala tillgångsmarknaden är instabil. Priser och värden kan fluktuera eller kollapsa avsevärt när som helst. Handel med digitala tillgångar är din personliga frihet Du väljer och bestämmer på egen risk och eventuella förluster.",
        content104: "8.7 Våra garantier och åtaganden som anges i detta avtal är de enda garantier och utfästelser som görs av oss i relation till detta avtal och tjänsterna som tillhandahålls av denna webbplats, och ersätter alla garantier och åtaganden som uppstår på något annat sätt och sätt, vare sig det är skriftligt eller muntligt , uttryckliga eller underförstådda. Alla dessa garantier och representationer representerar endast våra egna åtaganden och garantier och garanterar inte att någon tredje part kommer att följa garantierna och åtagandena i detta avtal.",
        content105: "8.8 Vi avsäger oss inte några rättigheter som inte nämns i detta avtal för att begränsa, utesluta eller kompensera vårt skadeståndsansvar i största möjliga utsträckning tillämplig lag.",
        content106: "8.9 Efter att du registrerat dig samtycker du till alla operationer vi utför i enlighet med reglerna i detta avtal, och alla risker som uppstår kommer att bäras av dig.",
        content107: "9. Uppsägning av avtal",
        content108: "9.1 Denna webbplats har rätt att säga upp alla tjänster på denna webbplats i enlighet med detta avtal. Detta avtal kommer att sägas upp det datum då alla tjänster på denna webbplats avslutas.",
        content109: "9.2 Efter uppsägningen av detta avtal har du ingen rätt att kräva att denna webbplats fortsätter att tillhandahålla den med några tjänster eller utföra några andra skyldigheter, inklusive men inte begränsat till att kräva att denna webbplats ska behålla eller avslöja någon information på dess ursprungliga webbplats. konto till dig eller den tredje parten vidarebefordrar all information som den inte har läst eller skickat.",
        content110: "9.3 Uppsägningen av detta avtal kommer inte att påverka den observerande partens krav på att ta på sig annat ansvar från den överträdande parten.",
        content111: "10. Immateriella rättigheter",
        content112: "10.1 Alla intellektuella prestationer som finns på denna webbplats inkluderar men är inte begränsade till webbplatslogotyper, databaser, webbdesigner, text och grafik, programvara, foton, videor, musik, ljud och kombinationer därav, programvarukompilationer, relaterade källkoder och programvara (inklusive små De immateriella rättigheterna för applikationer och skript) tillhör denna webbplats. Du får inte reproducera, ändra, kopiera, skicka eller använda något av ovanstående material eller innehåll för kommersiella ändamål.",
        content113: "10.2 Alla rättigheter som ingår i namnet på denna webbplats (inklusive men inte begränsat till goodwill och varumärken, logotyper) tillhör företaget.",
        content114: "10.3 Ditt godkännande av detta avtal ska anses som ditt initiativ att överföra upphovsrätten till all form av information som du publicerar på denna webbplats, inklusive men inte begränsat till: reproduktionsrättigheter, distributionsrättigheter, hyresrätter, utställningsrättigheter, framföranderättigheter, visningsrättigheter, sändningsrättigheter, informationsrättigheter för Internetöverföring, filmrättigheter, anpassningsrättigheter, översättningsrättigheter, sammanställningsrättigheter och andra överlåtbara rättigheter som upphovsrättsinnehavaren bör åtnjuta överförs exklusivt till denna webbplats utan kostnad. Denna webbplats har rätt att lämna in en oberoende stämning mot alla föremål för intrång och få full ersättning. Detta avtal gäller för allt innehåll som skyddas av upphovsrättslagen som du publicerar på denna webbplats, oavsett om innehållet bildas före undertecknandet av detta avtal eller efter undertecknandet av detta avtal.",
        content115: "10.4 Du får inte olagligt använda eller förfoga över de immateriella rättigheterna för denna webbplats eller andra när du använder tjänsterna på denna webbplats. Du får inte publicera eller tillåta andra webbplatser (och media) att använda informationen som publiceras på denna webbplats i någon form.",
        content116: "11. Informationsskydd",
        content117: 'Bestämmelserna i "Sekretessvillkoren" som publiceras separat på denna webbplats ska ha företräde.',
        content118: "12. Beräkning",
        content119: "Alla transaktionsberäkningsresultat har verifierats av oss, och alla beräkningsmetoder har publicerats på webbplatsen, men vi kan inte garantera att användningen av webbplatsen inte kommer att avbrytas eller felfritt.",
        content120: "13. Exportkontroll",
        content121: "Du förstår och erkänner att du enligt relevanta lagar inte får exportera, återexportera, importera eller överföra något material (inklusive programvara) på denna webbplats, så du garanterar att du inte aktivt kommer att implementera eller hjälpa till eller delta i något av ovan nämnda export eller relaterade aktiviteter som bryter mot lagar och regler Överföring eller andra brott mot tillämpliga lagar och förordningar, om en sådan situation upptäcks bör du aktivt rapportera den till oss i tid och hjälpa oss att hantera den.",
        content122: "14. Överföring",
        content123: "Rättigheterna och skyldigheterna som anges i detta avtal ska också binda överlåtare, arvingar, exekutorer och administratörer till de parter som drar nytta av sådana rättigheter och skyldigheter. Du får inte överföra det till någon tredje part utan vårt samtycke, men vi kan överföra våra rättigheter och skyldigheter i detta avtal till någon tredje part när som helst och meddela dig.",
        content124: "15. Delbarhet",
        content125: "Om någon bestämmelse i detta avtal anses vara omöjlig att verkställa, ogiltig eller olaglig av någon domstol med behörig jurisdiktion, kommer det inte att påverka giltigheten av de återstående bestämmelserna i detta avtal.",
        content126: "16. Icke-agenturförhållande",
        content127: "Ingenting i detta avtal ska anses skapa, antyda eller på annat sätt utgöra oss som din ombud, förvaltare eller annan representant, förutom om annat anges i detta avtal.",
        content128: "17. Nedlagd röst",
        content129: "Att vi eller du avstår från någon parts ansvar för avtalsbrott eller annat ansvar som anges i detta avtal ska inte anses eller tolkas som ett avstående från annat ansvar för avtalsbrott; underlåtenhet att utöva någon rättighet eller gottgörelse ska inte i någon tolkas som ett avstående från sådana rättigheter eller möjligheter att ge upp.",
        content130: "18. Titel",
        content131: "Alla rubriker är endast för att underlätta uttrycket för avtalet och används inte för att utöka eller begränsa innehållet eller omfattningen av villkoren i avtalet.",
        content132: "19. Tillämplig lag",
        content133: "19.1 Innan du tar upp en tvist eller ett anspråk, bekräftar och samtycker du till att först kontakta denna webbplats via e-post för att lösa tvisten informellt. Vi kommer att försöka lösa din tvist internt så snart som möjligt; båda parter är överens om att lösa tvisten genom förhandlingar i god tro (diskussioner) ska vara konfidentiell och skyddad av tillämpliga regler och inte användas som bevis i några rättsliga förfaranden).",
        content134: '19.2 Du erkänner och samtycker till att i händelse av tvist, kontrovers, oenighet eller anspråk, inklusive existensen, giltigheten, tolkningen, utförandet, brottet eller uppsägningen av dessa villkor eller någon tvist som uppstår ur eller i samband med dessa villkor ("tvist" ) , ska ovannämnda parter överlämna tvisten till Internationella Handelskammarens internationella skiljedomstol ("ICC International Court of Arbitration") i enlighet med de då gällande "ICC Rules of Arbitration". Platsen för skiljeförfarandet ska vara Belize och tillämplig lag för skiljeförfarandet ska fastställas i enlighet med Internationella handelskammarens skiljedomsregler. Om inte annat avtalats av parterna kommer endast en skiljeman att utses enligt ICC:s regler för skiljeförfarande. Skiljemannen ska ha exklusiv behörighet att besluta om sin egen jurisdiktion, inklusive, utan begränsning, alla invändningar mot existensen, omfattningen eller giltigheten av skiljeavtalet eller skiljemöjligheten för något anspråk eller motkrav. Eventuell skiljedom kommer att genomföras på engelska. Oavsett hur skiljeförfarandet bedrivs, ska skiljemannen utfärda en skriftlig dom som är rimlig och tillräcklig för att förklara domen och de väsentliga slutsatser, om några, som domen baseras på. Skiljedomen ska vara slutgiltig och bindande för parterna och kan verkställas i vilken domstol som helst med behörig jurisdiktion.',
        content135: "20. Effektivitet och tolkning av avtalet",
        content136: "20.1 Detta avtal träder i kraft när du klickar på registreringssidan för denna webbplats för att godkänna registrering, slutföra registreringsprocessen och skaffa ett konto och lösenord för denna webbplats. Det är bindande för både denna webbplats och dig.",
        content137: "20.2 Den slutliga tolkningsrätten av detta avtal tillhör denna webbplats.",
        content138: "Känna till din policy för kunder och mot penningtvätt",
        content139: "1. Introduktion",
        content140: '1.1 Vi lovar att noggrant följa lagar och förordningar relaterade till "Känn din kund" och bekämpning av penningtvätt och ska inte avsiktligt bryta mot "Känn din kund och policy mot penningtvätt". Inom ramen för vår rimliga kontroll kommer vi att vidta nödvändiga åtgärder och teknologier för att förse dig med säkra tjänster och skydda dig från förluster orsakade av kriminella misstänktes penningtvättsbeteende så mycket som möjligt.',
        content141: "1.2 Vår Know Your Customer och Anti-penningtvättspolicy är ett omfattande internationellt policysystem som inkluderar Know Your Customer och Anti-penningtvättspolicyer för de olika juridiska jurisdiktionerna som du är ansluten till.",
        content142: "2. Vet att din policy för kunder och mot penningtvätt är följande:",
        content143: '2.1 Promulgera "känn din kund"- och anti-penningtvättspolicyer och uppdatera dem då och då för att uppfylla de standarder som ställs upp av motsvarande lagar och förordningar;',
        content144: "2.2 Promulgera och uppdatera vissa vägledande principer och regler för driften av denna webbplats, och våra anställda kommer att tillhandahålla tjänster i enlighet med riktlinjerna för dessa principer och regler;",
        content145: "2.3 Utforma och slutföra rutiner för intern övervakning och kontroll av transaktioner, såsom att verifiera identiteter med strikta medel och arrangera ett professionellt team som ansvarar för arbetet mot penningtvätt;",
        content146: "2.4 Använd riskförebyggande metoder för att genomföra due diligence och löpande övervakning av kunder;",
        content147: "2.5 Granska och kontrollera regelbundet transaktioner som har inträffat;",
        content148: "2.6 Rapportera misstänkta transaktioner till de behöriga myndigheterna;",
        content149: "2.7 Bevis på identitet, adress och transaktionsregister kommer att bevaras i minst sex år och kan skickas till tillsynsmyndigheter utan ytterligare meddelande till dig.",
        content150: "3. Identitetsinformation och verifiering",
        content151: "3.1 Identitetsinformation",
        content152: "3.1.1 Enligt olika regler i olika jurisdiktioner och olika enhetstyper kan innehållet i din information vi samlar in vara inkonsekvent. I princip kommer följande information att samlas in från registrerade individer:",
        content153: "Grundläggande personlig information: ditt namn, adress (och permanent adress, om annan), födelsedatum och nationalitet och annan tillgänglig information. Identitetsverifiering bör baseras på dokument utfärdade av officiella eller andra liknande myndigheter, såsom pass, ID-kort eller andra identifieringshandlingar som krävs och utlöses av olika jurisdiktioner. Adressen du anger kommer att verifieras med lämpliga metoder, som att kontrollera passagerarbiljetten eller räntelappen eller kontrollera röstlängden.",
        content154: "Giltigt foto: Innan du registrerar dig måste du tillhandahålla ett foto på dig själv med ditt ID mot bröstet;",
        content155: "Kontaktuppgifter: Telefon/mobilnummer och/eller giltig e-postadress.",
        content156: "3.1.2 Om du är ett företag eller annan juridisk person kommer vi att samla in följande information för att identifiera dig eller den slutliga förmånstagaren av ditt förtroendekonto.",
        content157: "Företagsregistrering och registreringsbevis; kopior av bolagets bolagsordning och promemoria; detaljerat certifieringsmaterial av bolagets aktiestruktur och ägarbeskrivning, som styrker styrelsens beslut av den auktoriserade huvudmannen som bestämmer öppnandet och verkställandet av kontot på denna webbplats; bolaget styrelseledamöter som behöver tillhandahållas vid behov , identitetshandlingar för den större aktieägaren och den person som har behörighet att underteckna kontot på denna webbplats; företagets huvudsakliga affärsadress, om den skiljer sig från företagets postadress, uppge postadressen. Om ett företags lokala adress inte överensstämmer med dess huvudsakliga affärsadress, anses det vara en kund med högre risk och kommer att behöva skicka in ytterligare ytterligare dokumentation.",
        content158: "Beroende på de olika reglerna i olika jurisdiktioner och olika typer av enheter kräver vi andra certifieringar och dokument utfärdade av myndigheter samt dokument som vi anser nödvändiga.",
        content159: "3.1.3 Vi accepterar endast engelska eller kinesiska versioner av identitetsinformation. Om inte, översätt din identitetsinformation till engelska och få den attesterad.",
        content160: "3.2 Bekräfta verifiering",
        content161: "3.2.1 Vi kräver att du tillhandahåller hela sidans innehåll i din identifieringshandling.",
        content162: "3.2.2 Vi ber om ett foto där du håller din identitetshandling mot bröstet.",
        content163: "3.2.3 Kopior av styrkande handlingar bör i allmänhet kontrolleras mot originalkupongerna. En kopia är dock acceptabel om en lämplig och pålitlig intygsgivare kan intyga att kopian är en korrekt och fullständig återgivning av originaldokumentet. Sådana intygsgivare inkluderar ambassadörer, rättskommissionärer, lokala domare, etc.",
        content164: "3.2.4 Kravet för att identifiera den slutliga förmånstagaren och kontrollen av kontot är att fastställa vilka individer som i slutändan äger eller kontrollerar den direkta kunden, och/eller att fastställa att pågående transaktioner utförs för andras räkning. Om det är ett företag bör identiteten på de större aktieägarna (som de som innehar 10 % eller mer av rösterna) verifieras. Generellt sett kommer innehav av 25 % av aktierna att anses ligga inom normal risk, och aktieägarens identitet måste verifieras; innehav av 10 % av aktierna eller innehav av fler rösträtter eller aktier anses vara en högrisksituation, och identiteten av aktieägaren måste verifieras.",
        content165: "4. Övervaka transaktioner",
        content166: "4.1 Vi ställer in och justerar maxgränser för dagliga transaktioner och valutauttag från tid till annan baserat på säkerhet och faktiska transaktionsförhållanden;",
        content167: "4.2 Om transaktioner sker ofta hos en registrerad användare eller det finns orimliga omständigheter, kommer vårt professionella team att utvärdera och besluta om de är misstänkta;",
        content168: "4.3 Om vi ​​avgör att en transaktion är misstänkt baserat på vår egen bedömning, kan vi vidta restriktiva åtgärder som att avbryta transaktionen, avvisa transaktionen eller till och med återställa transaktionen så snart som möjligt om möjligt, och rapportera det till den behöriga myndigheten, men kommer inte att meddela dig;",
        content169: "4.4 Vi förbehåller oss rätten att avslå registreringsansökningar från personer från jurisdiktioner som inte följer internationella standarder för bekämpning av penningtvätt eller personer som kan anses vara politiskt utsatta personer. Vi förbehåller oss rätten att avbryta eller avsluta transaktioner som är misstänkta enligt vår enda bedömning på när som helst, men vi gör det inte. Bryter mot några skyldigheter eller ansvar som du är skyldig.",
    },
    register: {
        formTitle1: "skapa",
        formTitle2: "Registrera dig med din e-post eller mobilnummer",
        formTitle3: "konto",
        field1: "Verifierings kod",
        field2: "Erhålla",
        field3: "Bekräfta lösenord",
        field4: "Inbjudningskod",
        field41: "（Frivillig）",
        field5: "Jag har läst och håller med",
        field6: "Användarvillkor",
        placeholder1: "vänligen ange verifieringskoden",
        content7: "vänligen ange verifieringskoden",
        content12: "Vänligen ange telefonnummer",
        content13: "Ange lösenord Tack",
        content14: "vänligen ange din e-post",
        content18: "Vänligen ange lösenordet igen",
        content19: "Vänligen godkänn användarvillkoren",
        content20: "Vänligen ange korrekt verifieringskod",
        content23: "Har du fortfarande inte fått din verifieringskod?",
        content24: "Vänligen ange verifieringskoden nedan",
        registerSuccess: "registrering framgång",
        content25: "Lösenord är inkonsekventa",
        content26: "Vänligen ange rätt e-postadress",
        content27: "Lösenordsåterställningen lyckades"
    },
    download: {
        content1: "Handla när som helst, var som helst",
        content2: "Perfekt kompatibel med flera terminaler, och möter transaktionsbehoven för olika scenarier när som helst",
        content3: "Kontrollera marknadsförhållandena i realtid när som helst",
        content4: "Köp och sälj enkelt BTC, ETH, XRP och andra digitala tillgångar",
        content5: "Få tidiga varningar om prisförändringar för de kryptovalutor du bryr dig om",
        content6: "Se Bitcoin spotlån, terminslån och optionspriser",
        content7: "Jämför priser på kryptovaluta på olika börser",
        title: "ladda ner",
        install: "Installationsanmärkningar",
        one: "1. Tillåt nedladdning av beskrivningsfiler",
        two: "2. Beskrivningsfilen har laddats ner",
        three: "3. Ange [Settings] på din telefon och hitta [Allmänt]",
        four: "4. Beskrivningsfiler och enhetshanterare",
        five: "5. Klicka på beskrivningsfilen",
    },
    identity: {
        noAuthentication: {
            title: "Juniorcertifiering",
            pleaseSelectYourCountry: "Välj land",
            name: "namn",
            enterName: "Vänligen fyll i ditt namn",
            city: "stad",
            enterCity: "Ange din stad",
            IDNumber: "id nummer",
            enterIDNumber: "Vänligen ange ditt ID-nummer",
        },
        noAdvancedAuthentication: {
            title: "Avancerad certifiering",
            infoCountry: "land/region",
            infoName: "namn",
            infoNumber: "id nummer",
            infoAddress: "adress",
        },
        text: "inte certifierad",
        recertification: "omcertifiering",
        certification: "Certifiering",
        authenticationInProgress: "verifierad",
        inAuthentication: "Certifiering pågår",
        uploadTheFrontOfTheDocument: "Ladda upp framsidan av ID:t",
        uploadTheReverseSideOfTheDocument: "Ladda upp baksidan av certifikatet",
        uploadAPhotoOfAHandHeldID: "Ladda upp ett foto av ditt ID-kort i handen",
        pleaseUpload: "snälla du ",
        pleaseCompleteOrdinaryCertificationFirst: "Måste vänta på framgången för den primära certifieringsgranskningen",
        refused: "avvisade",
        reason: "anledning",
        succeeded: "lyckades",
        uploading: "laddar upp",
        newTitle: "Välj det land där ditt ID utfärdades",
        pleaseOrdinaryCertificationFirst: "Vänligen utför primär certifiering först",
        text1: "Ändra avatar",
        text2: "Smeknamn",
        text3: "konto",
        text4: "Smeknamnsändring",
        text5: "nytt smeknamn",
        text6: "Ange ditt nya smeknamn",
        text7: "Gammalt lösenord",
        text8: "Ange ditt gamla lösenord",
        text9: "nytt lösenord",
        text10: "Ange ditt nya lösenord",
        text11: "Bekräfta lösenord",

        text12: "Ange ditt nya lösenord igen",
        text13: "ny brevlåda",
        text14: "Verifierings kod",
        text15: "vänligen ange verifieringskoden",
        text16: "ditt mobiltelefonnummer",
        text17: "Nytt mobilnummer",
        text18: "Vänligen skriv in ditt telefonnummer",
        text19: "Mobiltelefonens verifieringskod",
        text20: "Juniorcertifiering",
        text21: "Vänligen verifiera din identitetsinformation",
        text22: "Land",
        text23: "Stad",
        text24: "Ange din stad",
        text25: "Ange ditt namn",
        text26: "Ditt ID-nummer",
        text27: "Ange ditt ID-nummer",
        text28: "ID-kort framsida foto",
        text29: "Klicka för att ladda upp dina dokument",
        text30: "ladda upp igen",
        text31: "ladda upp",
        text32: "Håller ID-foto",
        text33: "Klicka för att ladda upp ditt innehavs-ID-foto",
        text34: "Du har bundit Google Authenticator",
        text35: "nyckel",
        text36: "Ange Googles verifieringskod",
        text37: "skicka",
        text38: "Kan inte vara tom",
        text39: "E-postformatet är fel",
        text40: "Två lösenord är inkonsekventa",
        text41: "Kopierades framgångsrikt",
        text42: "Kopieringen misslyckades",
        text43: "Avatar modifiering",
        text44: "ändra lösenord",
        text45: "E-postbindning",
        text46: "Ändra e-post",
        text47: "Mobiltelefon bindning",
        text48: "Ändra mobiltelefon",
        text49: "Google-verifiering",
        text50: "Bind Google Authenticator",
        text51: "Ange Googles verifieringskod",
        text52: "spara",
        text53: "Bekräfta inlämning",
        text54: "Revidera",
        text55: "bindande",
        text56: "Stäng av Google-verifiering",
        text57: "Nästa steg",
        text58: "Avsluta",
        text59: "Google-verifiering är bunden",
        text60: "Google-verifiering har tagits bort",
        text61: "Primär certifiering har lämnats in",
        text62: "Avancerad certifiering inlämnad",
        text63: "Ingen bild laddad upp",
        text64: "Skicka igen",
        text65: "Vänligen ange ett smeknamn",
        text66: "Smeknamnet har sparats",
        text67: "Inga bilder uppladdade än",
        text68: "Lösenordsåterställning slutförd",
        text69: "E-posten har ändrats",
        text70: "Avataren har sparats",
        text71: "Ladda upp bild",
        text72: "din brevlåda",
        text73: "Ange din nya e-postadress",
        text74: "Ditt namn",
        text75: "Gå till autentisering",
        text76: "Vänligen verifiera ditt innehavs-ID-foto"
    },
    retrievePassword: {
        formTitle1: "Återställ lösenord",
        formTitle2: "För säkerheten för dina tillgångar är transaktioner förbjudna inom 24 timmar efter att du har ändrat lösenordet.",
        formTitle3: "Hämta lösenord",
        formTitle4: "Bekräfta ändringarna",
        formTitle5: "Vänligen ange det gamla lösenordet",
        formTitle6: "Vänligen ange ett nytt lösenord"
    },
    home: {
        title1: "Mest populära handelsplattformen för kryptovaluta",
        title3: "varför är ",
        title4: " Det är en högpresterande blockchain-plattform med öppen källkod designad för att stödja och driva säker, kompatibel och förutsägbar digital infrastruktur.",
        field1: "Etablerad utbyte",
        field2: "Finansiella tjänster för digitala tillgångar",
        field3: "Tiotals miljoner användare",
        field4: "Etablera flera investerarskyddsfonder",
        field5: "Snabb handel",
        field6: "CME Gruop kan matcha order med ultralåg latens för den ultimata handelsupplevelsen.",
        title5: "marknadstrend",
        title6: "se mer",
        table1: "Valuta",
        table2: "Senaste priset",
        table3: "24h upp- och nedgångar",
        table4: "24H handelsvolym",
        table5: "24H transaktionsvolym",
        table6: "Citat",
        title7: "handla om ",
        title8: " Vi är fast beslutna att ge användarna de mest avancerade handelsverktygen, innovativ teknik och oöverträffad kundservice av hög kvalitet.",
        title9: "Området för digitala tillgångar förändras för varje dag som går. Bara genom att vara framsynta och göra snabba framsteg kan vi hänga med i takten i den tekniska innovationen.",
        title901: " Tillhandahåller de mest avancerade tjänsterna för handel med digitala tillgångar till våra användare och globala likviditetsleverantörer.",
        title10: "Vi är övertygade om att varje användare förtjänar den bästa handelsupplevelsen.",
        title11: "Säkerhet du kan lita på",
        title12: "Våra sofistikerade säkerhetsåtgärder och SAFU-medel skyddar dina digitala tillgångar från alla risker. ",
        title13: "Bästa transaktionspriser",
        title14: "Förmånliga transaktionspriser, konkurrenskraftiga VIP-rättigheter och njut av tjänster av högsta kvalitet.",
        title15: "24*7 timmar kundservicesupport",
        title16: "24/7 i alla väder, heltidsläge, vi kommer att svara på dina relevanta förfrågningar så snart som möjligt.",
        title17: "0 avgiftshandel",
        title18: "Använd flera betalningsmetoder för att handla digitala valutor utan hanteringsavgifter, säkert och snabbt.",
        title19: "Handla när som helst, var som helst",
        title20: "Perfekt kompatibel med flera terminaler, och möter transaktionsbehoven för olika scenarier när som helst",
        title21: "Kontrollera marknadsförhållandena i realtid när som helst",
        title22: "Köp och sälj enkelt BTC, ETH, XRP och andra digitala tillgångar",
        title23: "Få tidiga varningar om prisförändringar för de kryptovalutor du bryr dig om",
        title24: "Se Bitcoin spotlån, terminslån och optionspriser",
        title25: "Jämför priser på kryptovaluta på olika börser",
        title26: "Anmäl dig nu",
        title27: "Dollar",
        title28: "24 timmars handelsvolym",
        title29: "Integrerad likviditetsleverantör",
        title30: "användare",
        title31: "Mer",
        title32: "Binance Loan lägger till CTSI & KSM som säkerhetstillgångar och lanserar BUSD & USDT räntesänkningsrabatter!",
        title33: "Vår fördel",
        title34: "säker och stabil",
        title35: "säker och stabil",
        title36: "Professionell efterlevnad",
        title37: "Professionellt operativt team, med många års erfarenhet av blockchain och finans, innehar en kompatibel digital tillgångshandelslicens och har en 100% reservgaranti.",
        title38: "Användaren först",
        title39: "Att möta den globala marknaden, stödja flera språk, köra 24/7, stark community support och professionell kundservice.",
        title40: "hög prestanda",
        title41: "300 000 transaktioner per sekund och ordersvarstid på mindre än 1 millisekund.",
        title42: "om oss",
        title43: "Handla när som helst, var som helst",
        title44: "Perfekt kompatibel med flera terminaler, och möter transaktionsbehoven för olika scenarier när som helst",
        title45: "（Skanna koden för att ladda ner）",
        title46: "Digital valutanyheter",
        title47: "vår affärspartner",
        title48: "Förlorarlista",
        title49: "24h omsättning",
        title50: "24h öka och minska"
    },
    quotation: {
        marketTrading: "Citat",
        turnover: "Volym",
        optional: "Frivillig",
        contractMarket: "kontraktsmarknaden",
        currencyPair: "valutapar",
        latestPrice: "Slutkurs",
        upsAndDowns: "Citat förändring",
        highest: "Högsta",
        lowest: "lägst",
        transactionAmount: "Transaktionsvolym",
        operate: "fungera",
        buy: "handel",
        topTurnovers: "Transaktionslista",
        topGainers: "Vinstlista",
        tradingPair: "handelspar",
        buyOrder: "köpa",
        sellOrder: "säljorder"
    },
    accountChange: {
        demo: "simulering",
        realAccount: "faktiska kontot",
        demoAccount: "Demokonto",
        accountRole: "Kontoroll",
        pleaseSelect: "Vänligen välj",
        AccountChangeType: "Kontoändringstyp",
        AccountType: "kontotyp",
        currencyType: "Valutatyp",
        allType: "Alla typer",
        allAccount: "Alla konton",
        allCoin: "Alla valutor",
        coin: "Valuta",
        amount: "Ändra belopp",
        balance: "Kontobalans",
        time: "tid",
        title: "Kontoändringsposter",
        filtrar: "filtrera",
        check: "byta till",
        showOnly: "Visa endast valutainnehav",
        holdAssets: "Valutabalans",
        accountList: "Kontolista"
    },
    contractTransaction: {
        chart: "Diagram",
        basicEdition: "Grundversion",
        depthMap: "Djupkarta",
        second: "Andra",
        minute: "punkt",
        day: "himmel",
        week: "vecka",
        month: "måne",
        open: "öppen",
        trade: "handel",
        buyLong: "långt köp",
        buyShort: "kort köp",
        cannotBuy: "Denna valuta stöder inte leveranskontraktstransaktioner",
        cannotBuy1: "Denna valuta stöder inte evig handel",
        tradingAssets: "handel med tillgångar",
        amountAvailable: "Tillgänglig kvantitet",
        timeStr: "tid",
        transactionPeriod: "handelsperiod",
        quantity: "kvantitet",
        enterQuantity: "Vänligen ange antal",
        buyIn: "Gå långt",
        latestTransaction: "senaste transaktionen",
        time: "tid",
        direction: "riktning",
        price: "pris",
        sell: "kort",
        positionOrder: "Följesedel",
        historicalOrders: "Historiska beställningar",
        product: "produkt",
        realAccount: "(verkligt konto)",
        demoAccount: "(Demokonto)",
        openingTime: "beställningstid",
        openingPrice: "Öppningspris",
        curretPrice: "Nuvarande pris",
        liquidationPrice: "Slutkurs",
        profitOrLoss: "Vinst och förlust",
        endTime: "Stängningstid",
        areYouSureToBuy: "Är du säker på att du vill köpa?",
        areYouSureToLongBuy: "Är du säker på att du vill åka långt?",
        areYouSureToShortBuy: "Är du säker på att du vill köpa kort?",
        successfullyBought: "Köp framgångsrikt",
        minBuy: "Köp framgångsrikt",
        assets: "tillgångar",
        withdraw: "dra tillbaka",
        entrustedOrder: "Beställa",
        total: "total",
        continueToPlaceOrder: "Fortsätt att lägga en beställning",
        orderConfirmation: "Orderbekräftelse",
        buyRu: "köpa",
        sellChu: "sälja",
        areYouSureToSell: "Är du säker på att du vill sälja?",
        operation: "fungera",
        buyCover: "täckläge",
        commitSuccess: "Transaktionen lyckades",
        onlyEnterIntegers: "Endast heltal kan anges",
        trend: "Trend",
        lotteryTime: "Sista datum för beställning",
        countdown: "Nedräkning",
        period: "Problem",
        cycle: "Tidsperiod",
        unlimited: "Obegränsat",
        put: "Put",
        call: "Call",
        pending: "På kommission",
        unopened: "Inte ritat än",
        opened: "Vinst dragits",
        settlementPrice: "Avräkningspris",
        rateOfReturn: "avkastning",
        currentPositions: "Nuvarande position",
        positionQuantity: "Positionsmängd",
        guarantee: "marginal",
        text1: "Uppåt",
        text2: "Nedåt",
        text3: "Väntar på att köpa",
        text4: "Order kvantitet",
        text5: "Antalet transaktioner",
        text6: "Annullera",
        text7: "leveranstid",
        text8: "Påminnelse om avbokning",
        text9: "Är du säker på att du vill avbryta beställningen?",
        text10: "Olaglig mängd",
        text11: "Beställningspåminnelse",
        text12: "Är du säker på att du köper hausse?",
        text13: "Är du säker på att köpa putten?",
        text14: "Avbokningen lyckades"
    },
    mining: {
        title1: "Världens ledande blockchain-plattform för utvinning av digitala tillgångar",
        title2: "Öppen och transparent · säker och stabil",
        title3: "Vänligen välj valuta",
        title4: "Mining Annualized Yield",
        title5: "himmel",
        title6: "Gruvperiod",
        title7: "Produktinformation",
        btn: "Min nu",
        btn2: "stängning",
        tabs1: "pågående",
        tabs2: "inlösta",
        table1: "produktnamn",
        table2: "Gruvperiod",
        table3: "Gruvtid",
        table4: "Gruvbelopp",
        table5: "Utgångsdatum",
        table6: "Fortsätter till mognad",
        table7: "Orderstatus",
        egularMining: "Gruvdrift regelbundet",
        inProgress: "pågående",
        redeemed: "inlösta",
        redemption: "upprättelse",
        regularMining: "Gruvdrift regelbundet",
        earlyRedemption: "förtida inlösen",
        need: "behöver",
        handlingFee: "expeditionsavgift",
        redemptionConfirmationMessage: "Vänligen bekräfta om du ska lösa in i förväg?",
        form1: "Mining Annualized Yield",
        form2: "Gruvperiod",
        form3: "Gruvvaluta",
        form4: "Prenumerationsbelopp (minsta/högsta)",
        form5: "Förtida inlösenavgift: ",
        form6: "inköpspris",
        form7: "Tillgängliga medel",
        form8: "inköpspris",
        form9: "Tillgängliga medel",
        form10: "Ange köpesumman",
        form11: "Allt",
        form12: "Beräknad intäkt",
        form13: "Otillräckliga medel tillgängliga",
        form14: "Ange köpesumman",
        form15: "Min order",
        form16: "inkomst",
        form17: "Orderdetaljer",
        form18: "Instruktioner för förtida inlösen:",
        form19: "Prenumerationsbelopp (minimum)",
        form20: "Prenumerationsbelopp (max)",
        form21: "Kvantitet som hålls",
        form22: "Köper tid",
        form23: "inlösentid",
        form24: "över",
        form25: "Prenumerationen lyckades",
        form26: "Inlösen lyckades",
        form27: "Enkel ekonomisk hantering med över 25 % avkastning, bättre än marknaden och du kan också få belöningar efter köp",
        form28: "Bjud in vänner att få en belöning på 50 USDT, och kontraktskuponger kan också returnera 20 % i provision."
    },
    assets: {
        content1: "Kontotillgångar",
        content2: "Dagens förtjänst",
        content3: "nuvarande konto",
        content4: "Det faktiska kontot är dina faktiska kontotillgångar, som kan användas för transaktioner och uttag.",
        content5: "Ta ut mynt",
        content6: "Sätt in mynt",
        content7: "utbyta",
        content8: "överföra",
        content9: "Vänligen välj",
        content10: "Demokonto",
        content11: "faktiska kontot",
        content12: "förvärva tillgångar",
        content13: "Är du säker på att du vill byta till ett riktigt konto?",
        content14: "Är du säker på att du vill byta till ett demokonto?",
        content15: "få framgång",
        table1: "Valuta",
        table2: "kvantitet",
        table3: "frysa",
        table4: "Tillgängliga",
        dialog1: "Produktinformation",
        dialog2: "1、Denna tillgång är endast tillåten att användas i demokontoläge",
        dialog3: "2、För att förhindra missbruk tillåts endast en inlösen inom en månad.",
        dialog4: "3、Att ta emot tillgångar kommer att lägga till mängden tillgångar som mottagits på den ursprungliga grunden och kommer inte att ändra positionsstatus.",
        dialog5: "4、Om du har några andra frågor, vänligen kontakta kundtjänst",
        dialog6: "stängning",
        dialog7: "Skaffa demotillgångar nu",
        dialog8: "överföra in",
        dialog9: "överföra ut",
        dialog10: "växla",
        dialog11: "Värdering",
        dialog12: "Demotillgångar",
        dialog13: "mitt konto",
        dialog14: "Totala kontotillgångar",
        dialog15: "Tillgångsöversikt",
        dialog16: "Total tillgångsvärdering",
        dialog17: "Tillgångsvärdering",
        dialog18: "Dagens förtjänst",
        dialog19: "frysa medel",
        dialog20: "Du kan få simulerad tillgångskvot varje månad",
        dialog21: "motta",
        dialog22: "Du har tagit emot framgångsrikt",
        dialog23: '30 dagars ackumulerad inkomst',
        dialog24: "Finansiell produkt",
        dialog25: "Min ekonomistyrning",
        dialog26: "Historisk ekonomisk förvaltning",
        dialog27: "historia rekord",
        dialog28: "Finansieringsrekord"
    },
    validator: {
        dialog14: "validator",
        dialog15: "Bindvalidator",
        dialog16: "valideringsbrytare",
        dialog17: "Verifierings kod",
        dialog18: "Validator QR-kod",
        dialog19: "valideringsnyckel",
        dialog20: "spara",
        dialog21: "Välj en validator",
        dialog22: "kopiera nyckel",
        dialog23: "sparad",
        dialog24: "vänligen ange verifieringskoden",
        dialog25: "Vänlig påminnelse: Innan du bekräftar lagringen, öppna motsvarande APP och skanna QR-koden nedan eller lägg till nyckeln manuellt!",
        dialog26: "Att aktivera din autentisering ger maximal säkerhet för ditt konto.",
        dialog27: "Du måste ange en unik verifieringskod som nås via din mobiltelefon varje gång du loggar in eller tar ut pengar.",
        dialog28: "Konfigurera validator",
        dialog29: "hjälp",
        dialog30: "Du har inte bundit autentiseringskoden. Få starkare skydd med en autentisering.",
        dialog31: "Validatorn har inte konfigurerats än, vänligen ställ in den först",
        dialog32: "Gå till Inställningar"
    },
    recharge: {
        title1: "Osäker på hur man köper med digital valuta?",
        title2: "Bara tre enkla steg!",
        title3: "Välj den digitala valutan du vill köpa från rullgardinsmenyn och ange inköpskvantitet eller belopp.",
        title4: "Välj din föredragna betalningsmetod",
        title5: "Efter att betalningen har bekräftats kommer den digitala valutan du köpt att automatiskt överföras till ditt konto.",
        title6: "Välj den valuta du vill ladda om",
        title7: "Insättningsrekord",
        table1: "Valuta",
        table2: "kvantitet",
        table3: "Kedjenamn",
        table4: "stat",
        table5: "anledning",
        table6: "tid",
        table7: "Visa kupong",
        dialog1: "Vänligen ange verifieringskoden nedan",
        dialog2: "betalningskod",
        dialog3: "kopiera",
        dialog4: "adress",
        dialog5: "Insättningsbelopp",
        dialog6: "Transaktionsskärmdump",
        dialog7: "viktigt meddelande",
        dialog8: "Vänligen fyll i insättningsbeloppet",
        dialog9: "ladda upp bild",
        dialog10: "Vänligen ange innehåll",
        dialog11: "Ladda upp skärmdumpar av uppladdning",
        dialog12: "Ej granskad",
        dialog13: "avvisade",
        dialog14: "Deponerad",
        tip1: "Framgångsrikt inskickat",
        tip2: "Kopierades framgångsrikt",
        tip3: "Kopieringen misslyckades",
        tip4: "Välj den valuta du vill ladda om",
        tip5: "1.Ovanstående insättningsadress är plattformens officiella betalningsadress. Leta efter plattformens officiella insättningsadress. Du kommer att vara ansvarig för förlust av pengar som orsakas av felaktig insättning;",
        tip6: "2.Var noga med att bekräfta säkerheten för din dator och webbläsare för att förhindra att information manipuleras eller läcker;",
        tip7: "3.När du har laddat om ovanstående adress måste hela nätverksnoden bekräftas innan kontot kan krediteras;",
        tip8: "4.Vänligen välj ovanstående tokensystem och valuta och överför motsvarande belopp för insättning. Vänligen överför inte några andra orelaterade tillgångar, annars kommer de inte att hämtas.",
        tip9: "Spara QR-koden",
        tip10: "fylla på",
        tip11: "viktigt meddelande",
        tip12: "Länkar utanför webbplatsen",
        tip13: "Ladda QR-koden",
        tip14: "Nästa steg",
        tip15: "Ladda rekord",
        tip16: "Ladda detaljer",
        tip17: "Insättningsadress",
        tip18: "Snabb betalning",
        tip20: "Otillräcklig balans",
        tip22: "Skickat, väntar på bekräftelse",
        tip23: "Väntar på betalning",
        tip24: "Belopp",
        tip25: "Betalning har skickats, väntar på blockeringsbekräftelse",
        tip26: "Betalning Nekad",
        tip27: "Betalning misslyckas",
        tip28: "Betalning genomförd",
        bankChannel: "Bankkortskanal",
        tip29: "Historiska sökningar",
        tip30: "Valutalista",
        tip31: "Välj nätverk",
        tip32: "När du laddar den här valutan ska du bara ladda om via de nätverk som stöds nedan. Om du laddar via andra nätverk går pengar förlorade.",
        tip33: "När du tar ut denna valuta, vänligen ta bara ut via de nätverk som stöds nedan. Om du tar ut via andra nätverk kommer pengar att gå förlorade.",
        tip34: "Snabb uppladdning",
        tip35: "Skanna koden för att ladda",
        tip36: "Manuell uppladdning",
        tip37: "Ladda nätverk",

        tip38: "Ladda adress",
        tip39: "Ladda belopp",
        tip40: "Uppladdning av transaktionskvitto",
        tip41: "ladda upp bild",
        tip42: "bekräfta inlämningen",
        tip43: "bekräfta betalningen",
        tip44: "备注:请确认您需要充值的地址,若填错导致金额损失我方不负责",
        tip45: "påminna",
        tip46: "Adressen har kopierats!",
        tip47: "Adresskopiering misslyckades!",
        tip48: "Otillräckligt tillgängligt saldo",
        tip49: "Omladdningen har skickats in och väntar på bekräftelse på kedjan.",
        tip50: "Uppladdningen misslyckades",
        tip51: "Omladdningsbeloppet är fel",
        tip52: "Anslutningen misslyckades",
        tip53: "Plånbok inte inloggad",
        tip54: "Anslutningen vägrades",
        tip55: "Inskickad framgångsrikt, väntar på kundtjänstgranskning och godkännande",
        tip56: "Ladda upp en skärmdump av transaktionskvittot",
        tip57: "Inlämning misslyckades",
        tip58: "Ladda rekord",
        tip59: "Uttagsrekord",
        tip60: "Ladda valuta",
        tip61: "Uttagsvaluta",
        tip62: "Ladda adress",
        tip63: "Skärmdump visar",
        tip64: "Klicka för att förstora bilden",
        tip65: "Uppladdningstid",
        tip66: "Orsak till avslag",
        tip67: "Uttagsbelopp",
        tip68: "Mottaget belopp",
        tip69: "Nätverk för kontantuttag",
        tip70: "Uttagsadress",
        tip71: "Uttagstid",
        tip72: "stängning",
        tip73: "Transaktionsskärmdump",
        tip74: "Väntar på granskning",
        tip75: "avvisade",
        tip76: "avslutad",
        tip77: "avvisade",
        tip78: "Reviderad",
        tip79: "Utbetalas",
        tip80: "extrahera",
        tip81: "överföringsnätverk",
        tip82: "Adress/Faktura",
        tip83: "Dra tillbaka alla",
        tip84: "Ta inte ut pengar direkt till crowdfunding- eller ICO-adressen, annars kommer du inte att kunna ta emot tokens som utfärdats av crowdfunding eller ICO.",
        tip85: "Ankomstmängd",
        tip86: "Bekräfta uttag",
        tip87: "Fel uttagsadress",
        tip88: "Fel uttagsbelopp",
        tip89: "Beloppet att ta ut är minst",
        tip90: "Det maximala uttagsbeloppet är",
        tip91: "Uttagsbegäran har skickats in",
        tip92: "adresslista",
        tip93: "Du har inte lagt till en adress än",
        tip94: "Lägg till det nu",
        tip95: "Lägg till adress",
        tip96: "Lägg till ny adress",
        tip97: "Vänligen välj",
        tip98: "adress",
        tip99: "Välj ett överföringsnätverk",
        tip100: "Vänligen ange adress",
        tip101: "Adressen har lagts till",
        tip102: "i transaktion",
    },
    fundExchange: {
        title1: "Hur byter man pengar?",
        title2: "Välj lämplig valuta som ska konverteras.",
        title3: "Välj lämplig valuta att konvertera.",
        title4: "Ange beloppet som ska växlas och klicka på Skicka för att slutföra fondbytet.",
        title5: "från",
        title6: "anlända",
        title7: "Byt kvantitet",
        title8: "växlingskurs",
        title9: "Tillgängliga",
        title10: "Vänligen ange inlösenkvantiteten",
        title11: "Det finns inget motsvarande handelspar för utbyte.",
        title12: "Vänligen ange den kvantitet som ska lösas in",
        title13: "Inlösen lyckades",
        title14: "Utbytesrekord",
        table1: "Använd valuta",
        table2: "kvantitet",
        table3: "växlingskurs",
        table4: "Växla valuta",
        table5: "Mottagande",
        table6: "tid",
        title15: "från",
        title16: "Det finns för närvarande inga tillgångar tillgängliga, välj en annan"
    },
    fundTransfer: {
        title1: "Hur överför man pengar?",
        title2: "Välj det aktuella kontot som behöver överföras.",
        title3: "Välj det konto du vill överföra.",
        title4: "Ange överföringsbeloppet och klicka på Skicka för att slutföra överföringen.",
        title5: "Valuta",
        title6: "Överföringsmängd",
        title7: "Ange beloppet som ska överföras",
        title8: "överföringsrekord",
        title9: "Välj plånbokstyp",
        title10: "Vänligen välj valuta",
        title11: "Välj plånbokstyp",
        title12: "Vänligen välj valuta",
        title13: "Ange beloppet som ska överföras",
        title14: "Framgångsrikt inskickat",
        table1: "Överför ut konto",
        table2: "överföra valuta",
        table3: "Överföringsmängd",
        table4: "överföra konto",
        table5: "tid",
        table6: "överföra",
        table7: "Fondöverföring",
        table8: "Överföra till",
        table9: "överföringsrekord",
        table10: "Överföringsinformation",
        table11: "Överföringen lyckades",
        table12: "Överföringen misslyckades",
        table13: "Aktuellt konto kan inte överföras"
    },
    withdrawal: {
        title1: "viktigt meddelande",
        title2: "1.För att förhindra arbitrage kan du ansöka om valutauttag när transaktionsvolymen når kvoten.",
        title3: "2.Uttagsansökan kommer att krediteras inom 24 timmar efter inlämnandet. Om uttaget inte tas emot efter den beräknade uttagstiden, kontakta kundtjänst online.",
        title4: "3.Efter att ha skickat in uttagsansökan är medlen i fryst tillstånd eftersom uttaget pågår och medlen tillfälligt innehas av systemet.Det betyder inte att du har förlorat tillgången eller att det finns en avvikelse med tillgången.",
        title5: "Valuta",
        title6: "plånboksadress",
        title7: "Antal uttagna mynt",
        title8: "Rekord för myntuttag",
        title9: "Vänligen välj valuta",
        title10: "Vänligen välj kedjetyp",
        title101: "",
        title11: "Stig på",
        title12: "plånboksadress",
        title13: "Framgångsrikt inskickat",
        title14: "under granskning",
        title15: "Recensionen lyckades",
        title16: "Granskningsfel",
        title17: "Utbetalas",
        title18: "Ange eller klistra in din plånboksadress",
        title19: "Ange uttagsbeloppet",
        title20: "expeditionsavgift",
        title21: "Faktisk ankomst",
        table1: "Valuta",
        table2: "Antal uttagna mynt",
        table3: "Kedjenamn",
        table4: "plånboksadress",
        table5: "stat",
        table6: "anledning",
        table7: "ansökningstid",
        table8: "Ta ut mynt",
        table81: "Ta ut mynt",
        table9: "Uttagsadress",
        table10: "Vänligen välj den valuta du vill ta ut",
        table11: "Se till att du går in",
        table12: 'Adress (börjar vanligtvis med "0x")',
        table13: 'Adress (börjar allmänt med "0x")：',
        table14: "Välj valuta",
        table15: "Stöter på problem? Omedelbar feedback",
        table16: "balans",
        table17: "Uttagsdetaljer",
        password: "Lösenord",
        passwordTips: "Ange lösenord Tack",
        passwordAg: "Bekräfta lösenord",
        passwordAgTips: "Vänligen ange lösenordet igen",
        oldPassword: "Gammalt lösenord",
        oldPasswordTips: "Vänligen ange det gamla lösenordet",
        newPassword: "nytt lösenord",
        newPasswordTips: "Vänligen ange ett nytt lösenord",
        passwordNotSame: "Lösenord är inkonsekventa",
        withdrawPasswordSet: "Ställ in ett uttagslösenord",
        withdrawPasswordModify: "Ändra uttagslösenord",
        withdrawPassword: "Dra tillbaka lösenordet",
        withdrawPasswordTips: "Vänligen ange lösenordet för uttag",
        setWithdrawPasswordFirst: "Vänligen ange ett uttagslösenord först",
        withdrawPasswordSetSuccess: "Installationen lyckades",
        withdrawPasswordModifySuccess: "Har modifierats",
        submit: "Skicka in",
    },
    customer: {
        content1: "onlinetjänst",
        content2: "kundservice",
        content3: "Ange dina kontaktuppgifter",
        content4: "Vänligen ange innehåll",
        content5: "skicka",
        content6: "Det gick inte att skicka, nätverksanslutningen misslyckades",
        content7: "bild",
        content8: "meddelandet är tomt",
        content9: "min andel",
        content10: "dela länk",
        content11: "spara bild",
        content12: "Mina vinster",
        content13: "Nivåbeskrivning",
        content14: "Lagstorlek",
        content15: "Inbjudan inkomst",
        content16: "Regelbeskrivning",
        content17: "medlemsnivå",
        content18: "Laddningsmängden är större än",
        content19: "Lagnivå",
        content20: "Lagnivå & inkomst",
        content21: "För att uppfylla villkoren",
        content22: "Distributionsintäkter",
        content23: "nivå team",
        content24: "nivå, inbjudan",
        content25: "giltiga medlemmar",
        content26: "primär inkomst",
        content27: "sekundär inkomst",
        content28: "Nivå 3 inkomst",
    },
    standardContract: {
        increaseMargin: "Increase margin",
        increaseAmount: "Increase amount",
        Pleaseenterthemargin: "Please enter the margin amount to be increased",
        Pleaseenterthecorrect: "Please enter the correct deposit amount",
        Increasemarginsuccessfully: "Increase margin successfully",
        takeProfitPriceWrong: "Tar vinstpriset fel",
        stoppriceerror: "Stoppa prisfel",
        thetakeprofit1: "Take profit-priset måste vara högre än marknadspriset",
        thetakeprofit2: "Stop loss-priset måste vara lägre än marknadspriset",
        thetakeprofit3: "Take profit-priset måste vara lägre än marknadspriset",
        thetakeprofit4: "Stop loss-priset måste vara högre än marknadspriset",
        thetakeprofit5: "Fel kvantitet",
        estimatedprofitloss: "Beräknad vinst och förlust",
        positionAmount: "Antal befattningar",
        closeAmount: "Utgående kvantitet",
        whenthelatest: "När det senaste priset nås",
        whenthelatest2: ", kommer marknadspriset vinstbeställning att utlösas för att stänga den nuvarande positionen. Förväntad vinst och förlust",
        whenthelatest3: ", kommer marknadens stop loss-order att utlösas för att stänga den aktuella positionen. Förväntad vinst och förlust",
        contract: "avtal",
        ordercanceledsuccessfully: "Beställningen avbröts",
        successfullyordered: "framgångsrikt beställd",
        positionopeningreminder: "Position öppningspåminnelse",
        wrongquantity: "Fel kvantitet",
        quantitycannot: "Kvantiteten kan inte vara större än",
        modificationstoploss: "Modifiering av stoppvinst och stoppförlust framgångsrik",
        positionclosedsuccessfully: "Positionen stängdes",
        orderCancellation: "Annullering av beställning",
        orderCancellation2: "Är du säker på att du vill avbryta beställningen?",
        tobeexecuted: "Att avrättas",
        expired: "utgånget",
        failed: "misslyckades",
        contractTransaction: "Evigt kontrakt",
        increase: "Öka",
        openPosition: "Fullt lager",
        isolatedPosition: "Isolerad position",
        limitOrder: "prisgräns",
        marketOrder: "marknadspris",
        pleaseSelectLeverage: "Vänligen välj hävstång",
        pleaseEnterQuantity: "Vänligen ange antal",
        canOpenMore: "Maximalt läge som kan öppnas",
        contractAmount: "Kontraktsbelopp",
        openingFee: "expeditionsavgift",
        currentPosition: "Placera",
        historicalPositions: "Historiska transaktioner",
        currentCommission: "Aktuell beställning",
        historicalCommission: "historiska uppdrag",
        buyPrice: "inköpspris",
        income: "vinst",
        quantity: "kvantitet",
        marginRate: "marginalhastighet",
        initialMargin: "Initial marginal",
        estimatedPriceParity: "Tvångslikvidationspris",
        operate: "fungera",
        finishQuantity: "Transaktionsvolym",
        averagePrice: "genomsnittligt transaktionspris",
        pleaseEnterLeverage: "Vänligen ange hävstång",
        takeProfitAndStopLoss: "TP&SL",
        takeProfit: "Göra vinst",
        stopLoss: "stoppa förlusten",
        openLong: "Long",
        openShort: "Short",
        liquidation: "Stängt läge",
        marketLquidation: "Marknadspris allround",
        cancel: "Avbryt beställning",
        enterBuyPrice: "Vänligen ange inköpspriset",
        areYouSureLquidation: "Är du säker på att stänga positionen?",
        confirm: "Säker",
        enterStopWinPrice: "Vänligen ange vinstpriset",
        enterStopLossPrice: "Vänligen ange vinstpriset",
        commissionTime: "kommissionstid",
        commissionPrice: "Provisionspris",
        commissionQuantity: "Totalt provisionsbelopp",
        volumeAlreadyTraded: "Belopp färdigt",
        areYouSureToWinOrLoss: "Är du säker på att du vill stoppa vinsten och stoppa förlusten?",
        maxQuantity: "Maximal position",
        saleQuantity: "Position stängd",
        salePrice: "genomsnittliga stängningskursen",
        saleTime: "tid",
        succeeded: "lyckades",
        cancelled: "Inställt",
        piece: "öppen",
        triggerPrice: "utlösande pris",
        setWin: "Ställ ta vinst",
        setLoss: "Ställ in stop loss",
        enterWinQuantity: "Ange vinstbeloppet",
        enterLossQuantity: "Vänligen ange stop loss-beloppet",
        planCommission: "Plankommission",
        enterWinTriggerPrice: "Vänligen ange utlösande pris för vinst",
        enterLossTriggerPrice: "Ange stop loss triggerpriset",
        insufficientQuantity: "Otillräcklig mängd",
        enterTriggerPrice: "Ange utlösande pris",
        tips1: "Ditt orderpris är högre än det aktuella priset och det kommer att utföras direkt till det aktuella marknadspriset. Är du säker på att du vill köpa?",
        tips2: "Ditt orderpris är lägre än det aktuella priset och det kommer att utföras direkt till det aktuella marknadspriset. Är du säker på att köpa?",
        type: "typ",
        increaseQuantity: "Öka positionen",
        reduceQuantity: "Minska positioner",
        limitCommission: "gränsordning",
        tips3: "Stop loss-priset måste vara lägre än marknadspriset",
        tips4: "Take profit-priset måste vara högre än marknadspriset",
        tips5: "Stop loss-priset måste vara högre än marknadspriset",
        tips6: "Take profit-priset måste vara lägre än marknadspriset",
        tips7: "Stop loss-priset bör vara lägre än marknadspriset och lägre än orderpriset",
        tips8: "Tavinstpriset bör vara högre än orderpriset (kan vara mindre än marknadspriset)",
        tips9: "Stop loss-priset bör vara högre än marknadspriset och högre än orderpriset",
        tips10: "Tarvningspriset bör vara mindre än orderpriset (kan vara större än marknadspriset)",
        accountEquity: "Kontobalans",
        unrealizedPL: "orealiserad vinst eller förlust",
        tips11: "Är du säker på att du har rätt",
        tips12: "Marknadspris",
        tips13: "Stäng alla positioner",
        tips14: "Påminn inte igen",
        profitRate: "Stängande avkastning",
        openTime: "Öppnings tid",
        unwindTime: "Stängningstid",
        dealTime: "Transaktionstid",
        amountClose: "Mängd stängt läge",
        long: "många",
        short: 'null',
        orderQuantity: "Order kvantitet",
        entrustedMargin: "anförtrodd marginal",
    },
    spotGoods: {
        spotGoods: "Flash utbyte",
        sellPrice: "Säljande pris",
        orderRecord: "Flash inlösen rekord",
        transactionTime: "Transaktionstid",
        maxBuy: "finns att köpa",
        maxSell: "Till salu",
        purchaseQuantity: "Köpmängd",
        slippage: "Glidning",
        convertTips: "Vi kommer att avsluta transaktionen till det mest fördelaktiga priset med en glidning på 0-5%.",
        changePrice: "Aktuellt bytespris",
        consume: "konsumera",
        get: "skaffa sig",
        changeTips: "Är du säker på att du vill lösa in?",
        confirm: "Bekräfta inlösen"
    },
    news: {
        title: "Nyhetscenter",
        redMore: "Läs mer",
    },
    credit: {
        credit: "kreditvärdighet",
        creditScore: "total kreditpoäng",
        latestEntry: "senaste rekordet",
        liveUpdate: "Direktuppdatering",
        systemEvaluation: "Systemutvärdering",
        time: "datum",
        name: "namn",
        reason: "anledning",
        scoreStory: "Kreditpoängrekord"
    },
    borrowing: {
        borrowing: "lån",
        diversifiedMarket: "Diversifierade marknader",
        totalSupply: "Totalt utbud",
        totalBorrowings: "totala upplåningen",
        tips1: "totala upplåningen",
        tips2: "Din stabila ränta förblir densamma under hela ditt låns löptid. Rekommenderas för långsiktig leverans och användare som gillar förutsägbarhet.",
        totalSupplys: "totalt utbud",
        supplyAPR: "utbudshastighet",
        totalBorrowings: "Total upplåning",
        borrowAPR: "låneränta",
        check: "Kolla upp",
        market: "marknadsföra",
        availableLiquidity: "Tillgänglig likviditet",
        currencyPrice: "aktuellt valutapris",
        supplyAssets: "leveranstillgångar",
        tips3: "Obs: Räntan nedan är en fast ränta. När leveransperioden är över kommer kapitalbeloppet plus vinsten att återföras till ditt konto.",
        supplyCycle: "tillförselcykel",
        minimumSupply: "minsta utbud",
        supply: "tillförsel",
        borrowedAssets: "lånade tillgångar",
        tips4: "Viktigt att veta: För att låna pengar måste du tillhandahålla eventuella säkerhetstillgångar eller bevis,",
        tips5: "Se beskrivningen av inteckningstillgången.",
        repaymentCycle: "återbetalningscykel",
        BorrowableAmount: "Tillgängligt lånebelopp",
        borrow: "lån",
        accountInformation: "Kontoinformation",
        walletBalance: "saldo i plånboken",
        canSupply: "Tillgängliga",
        AmountCanBeBorrowed: "Totalt tillgängligt lånebelopp",
        borrowedAmount: "Lånat belopp",
        remainingAmount: "återstående balans",
        getBorrowednAmount: "Få lånegräns",
        supplyOrder: "leveransorder",
        borrowedOrder: "låneorder",
        supplyAmount: "leveransbelopp",
        expectedProfits: "Förväntad retur",
        supplyTime: "Tillförseltid",
        expirationTime: "Utgångsdatum",
        borrowingAmount: "Lånebelopp",
        dueRepayment: "Återbetalningsbelopp",
        borrowingTime: "Släpptid",
        repaymentTime: "Släpptid",
        repay: "återbetalning",
        supplyCurrency: "tillhandahålla valuta",
        buyNow: "Köp nu",
        supplyRecords: "leveransposter",
        borrowRecords: "Låneregister",
        completed: "avslutad",
        borrowNow: "Låna nu",
        borrowCurrency: "Låna valuta",
        borrowMin: "minsta",
        borrowMax: "maximal",
        borrowRange: "Lånebelopp (minsta/högsta)",
        tips6: "Vänligen återbetala i tid Om det är försenat debiteras ett extra dagligt belopp på (kapital + ränta).",
        tips7: "Förfallen avgift.",
        pleaseEnterBorrowAmount: "Ange lånebeloppet",
        interest: "Intressera",
        tips8: "Är du säker på att du vill betala tillbaka i förskott?",
        borrowChannel: "Lånekanal",
        tips9: "Låt oss börja med att lära känna dig",
        name: "namn",
        lastName: "efternamn",
        birthDate: "födelsedatum",
        tips10: "Lite mer information, vad är din adress?",
        familyAddress: "Hemadress (inklusive enhetsnummer)",
        city: "Stad",
        country: "nation",
        postalCode: "postnummer",
        pleaseEnterYourHomeAddress: "Vänligen ange din hemadress",
        tips11: "Berätta om din ekonomiska situation",
        tips12: "Vi kan behöva bekräfta denna information senare, så försök att vara så korrekta som möjligt",
        yourMonthlyIncome: "din månadsinkomst",
        enterYourMonthlyIncome: "Ange din månadsinkomst",
        extraIncomeOptional: "Ytterligare inkomst (valfritt)",
        enterExtraIncome: "Ange din extra inkomst",
        monthlyRentOrMortgagePayment: "Månadshyra eller betalning av bolån",
        enterMonthlyRent: "Vänligen ange din månadshyra eller betalning av bolån",
        tip13: "Berätta om du har ett bolån",
        tip14: "Om ingen bolånegaranti väljs är sannolikheten låg att klara granskningen.",
        isThereMortgage: "Finns det ett bolån?",
        selectIsThereMortgage: "Välj om det finns ett bolån",
        selectMortgageType: "Välj typ av inteckning",
        yes: "ja",
        no: "Nej",
        uploadScreenshotOfTransaction: "Ladda upp transaktionsskärmdumpar",
        tip15: "Låt oss hålla kontakten",
        tip16: "Vi kan kontakta dig på detta nummer om vi behöver ytterligare information om din låneansökan",
        tip17: "Jag har läst och elektroniskt undertecknat mitt samtycke",
        tip18: "Användarvillkor och elektroniskt samtycke",
        tip19: ", vilket inkluderar ditt samtycke till att vi skickar dig information om vår",
        tip20: "Användarvillkor för produkter och tjänster",
        tip21: "Efter att ha fyllt i informationen, vänta på systemutvärdering och granskning.",
        phoneNumber: "telefonnummer",
        enterPhoneNumber: "Vänligen skriv in ditt telefonnummer",
        tip22: "Vänligen markera rutan för att godkänna användarvillkoren och elektroniskt samtyckesformulär",
        inReview: "under granskning",
        applicationAmount: "Ansökningsbelopp",
        reasonForRejection: "Orsak till avslag",
        reapply: "Ansök igen",
        applyStatus: "ansökningsstatus",
        currentOrder: "nuvarande ordning",
        interestRate: "ränta"
    },
    yuebao: {
        filed1: "investera",
        filed2: "ta ut",
        filed3: "Dagsinkomst",
        filed4: "teamacceleration",
        filed5: "Deponerad",
        filed6: "Gårdagens intäkter",
        filed7: "Totala intäkter",
        filed8: "Tillgängliga medel",
        filed9: "Vänligen ange beloppet",
        filed10: "Likviditetsbrytning",
        filed11: "Introduktion",
        field12: "Lägsta inköpspris",
        field13: "Det mesta du kan köpa",
        filed14: "Prenumeration",
        filed15: 'regelbunden',
        filed16: 'årlig ränta',
    },
    tipsPopover: {
        title: "Installera denna applikation",
        press: "enligt",
        and: "och",
        scrren: "Lägg till på hemskärmen",
        hasInstall: "(Stäng fönstret om det är installerat)",
    },
    follow: {
        title1: "Uppföljningsplan",
        follow_text1: "Initiera en uppföljningsorder",
        follow_text2: "Releasehistorik",
        follow_text3: "titel",
        follow_text4: 'handelspar',
        follow_text5: 'Köptiden',
        follow_text6: 'beställningstid',
        follow_text7: 'släpptid',
        follow_text8: 'Antal följare',
        follow_text9: 'Beställningssumma',
        follow_text10: 'fungera',
        follow_text11: 'bjuda',
        follow_text12: 'Följ orderriktningen',
        follow_text13: 'Ställ in ordervillkor',
        follow_text14: 'Villkor för att följa beställningar',
        follow_text15: 'bestämd mängd',
        follow_text16: 'procentsats',
        follow_text17: 'Inbjudna människor',
        follow_text18: 'Den andra partens id',
        follow_text19: "Bekräfta att följa ordern",
        follow_text20: "Ditt orderbelopp (valfritt)",
        follow_text21: "Vänligen ange en titel",
        follow_text22: "Välj ett handelspar",
        follow_text23: "Välj beställningsriktning",
        follow_text24: "Vänligen välj köptiden",
        follow_text25: "Vänligen välj beställningstid",
        follow_text26: "Vänligen välj inköpskvantitet",
        follow_text27: "Den maximala inköpskvantiteten får inte vara mindre än den minsta inköpskvantiteten",
        follow_text28: "Minsta inköp",
        follow_text29: "De flesta köper",
        follow_text30: "Skapa kopieringsorder framgångsrikt",
        follow_text31: "Trading par lista",
        follow_text32: "Har redan följt ordern",
        follow_text33: "Ange information om den inbjudna",
        follow_text34: "Inbjudna",
        follow_text35: "bjöd in mig",
        follow_text36: "Kopiera historik",
        follow_text37: "Beställningsbeloppsintervall"
    },
    followHistory: {
        title1: 'Releasehistorik',
        title2: "Kopiera historik",
        follow_h_text1: "Det totala beloppet för beställningen",
        follow_h_text2: "Totalt antal personer som följer order",
        follow_h_text3: "Antal releaser",
        follow_h_text4: "vinstgrad",
        follow_h_text5: "Antal gånger att följa order",
        follow_h_text6: "Dra nytta av följande order",
        follow_h_text7: "riktning",
        follow_h_text8: "vinna",
        follow_h_text9: "underskott",
    },
    "asset.index.1579E8B41F8D5134": "mina tillgångar",
    "asset.index.B3F6F05Dfbe431Cd": "simulering",
    "asset.index.B9Aeab4Ee8F33746": "Tillgångsvärdering",
    "asset.index.583Df8Bd541Eb7E8": "resultattrender",
    "asset.index.B1254815D133Cc26": "7 dagar",
    "asset.index.2F615F6C029F01B7": "30 dagar",
    "asset.index.29439Fb58E4Bbe22": "dra tillbaka",
    "asset.index.831Ab31568A78285": "fylla på",
    "asset.index.39F7A0D48D12E8C9": "överföra",
    "asset.index.B99063C5689Beaec": "Flash utbyte",
    "asset.index.E1Dc620F2F038B2A": "Ta emot tillgångar",
    "asset.index.972A0B4C2B4393D0": "mitt konto",
    "asset.index.9Dfb0Fb334620733": "Tillgängligt Saldo",
    "asset.index.Cdc5Fbd26790Cb47": "frusen mängd",
    "asset.index.3D8132D72Ae965Cd": "Värdering",
    "asset.receive.825503Cc430559Cb": "förvärva tillgångar",
    "asset.receive.54154A0553A535B7": "Demotillgångar",
    "asset.receive.F2E57B00A9C5D129": "1.Denna tillgång är endast tillåten att användas i demokontoläge",
    "asset.receive.Ea0D1D52989299Bd": "2.För att förhindra missbruk tillåts endast en inlösen inom en månad.",
    "asset.receive.090E0037Aa235D3D": "3.Att ta emot tillgångar kommer att lägga till mängden tillgångar som mottagits på den ursprungliga grunden och kommer inte att ändra positionsstatus.",
    "asset.receive.7Fd4E1Abc805C0A6": "4.Om du har några andra frågor, vänligen kontakta kundtjänst",
    "asset.receive.Df5B3A511D986575": "Skaffa demotillgångar nu",
    "asset.records.1Fa02E8187A39592": "senaste tre månaderna",
    "asset.records.7B291C37333Fe34D": "från nära till långt",
    "asset.records.A476Cd1Ec868Fc5F": "senaste tre månaderna,",
    "asset.records.9D5755A842E55Bb7": "från nära till långt,",
    "asset.records.E3Fa05E607203Cdc": "fylla på",
    "asset.records.B4F206C6Bec08Dea": "dra tillbaka",
    "asset.index.Df5B3A511D986576": "Du har framgångsrikt tagit emot demotillgångarna",
    "deposit.depositSpeedy.Efe81D0E0174239B": "betalningsmetod",
    "deposit.depositSpeedy.B9293Ee96E435F85": "Fiat valuta",
    "deposit.depositSpeedy.16658A855F07D171": "Belopp",
    "deposit.depositSpeedy.204Ffab8A6E01870": "Säker",
    "deposit.depositSpeedy.22E5Dfb74Ead8Aa9": "Kan inte vara tom",
    "deposit.depositSpeedy.B8E774D0E4E5E4C1": "Minsta laddning",
    "deposit.depositSpeedy.F169D816F6Dd83C5": "Maximal laddning",
    "deposit.depositWallet.F64Ec60B1Db4A6D6": "Sätt in USDT",
    "deposit.depositWallet.551640C76F4Ca4A1": "Ladda valuta",
    "deposit.depositWallet.B404C543712B71D5": "Ladda nätverk",
    "deposit.depositWallet.A484B0F5E94B3F4F": "Insättningsadress",
    "deposit.depositWallet.D3980D54Acf242Db": "Spara QR-koden",
    "deposit.depositWallet.A06E84801D6582Be": "Ladda belopp",
    "deposit.depositWallet.Daaaeb1B7B22B126": "antydan",
    "deposit.depositWallet.D599E40E1Eeec1B3": "Uppladdningen lyckades",
    "deposit.depositWallet.4F48Abbafce532Bb": "Skickat framgångsrikt, väntar på kundtjänstgranskning",
    "deposit.depositWallet.A88C481228811696": "Du avböjde att byta nätverk",
    "deposit.depositWallet.C6Ebf27895C21932": "Öppna TRON-plånbokens plug-in manuellt och logga in",
    "deposit.index.B5491C8Dd09D8183": "Välj den valuta du vill fylla på",
    "deposit.index.2F784B2298D39987": "Snabb uppladdning",
    "deposit.index.0Daa03Dcdcabd837": "Länkar utanför webbplatsen",
    "withdraw.bottomSheet.628A63056Ef2D2Fe": "Uttagsbekräftelse",
    "withdraw.bottomSheet.A086Fa20082C849D": "Se till att informationen nedan är korrekt",
    "withdraw.bottomSheet.Bf8B184D32971501": "Uttagsnätverk",
    "withdraw.bottomSheet.8758Fd50C87D6C9C": "adress",
    "withdraw.bottomSheet.F9Db93B87E08763B": "kvantitet",
    "withdraw.bottomSheet.4Ad26475002878C0": "expeditionsavgift",
    "withdraw.index.A96454041169Af6D": "Välj valuta",
    "withdraw.index.2A563B7081Dd5B83": "Uttagsadress",
    "withdraw.index.B189C2Ef713Cfff4": "Antal uttagna mynt",
    "withdraw.index.558F35140E84B562": "Tillgängliga：",
    "withdraw.index.F4E759087E12Cb77": "Dra tillbaka alla",
    "withdraw.index.5Cbd9A6B284D25Ae": "Fondlösenord",
    "withdraw.index.B89Cbe4Bfc33Bb41": "expeditionsavgift:",
    "withdraw.index.C886De8D8A27026E": "viktigt meddelande",
    "withdraw.index.Eac7C5A4095F5606": "1.För att förhindra arbitrage kan du ansöka om valutauttag när transaktionsvolymen når kvoten.",
    "withdraw.index.C3Cc7Fff24504Be9": "2.Uttagsansökan kommer att krediteras inom 24 timmar efter inlämnandet. Om uttaget inte tas emot efter den beräknade uttagstiden, kontakta kundtjänst online.",
    "withdraw.index.0B4Ad7Ccdc833B0D": "3.Efter att ha skickat in uttagsansökan är medlen i fryst tillstånd eftersom uttaget pågår och medlen tillfälligt innehas av systemet.Det betyder inte att du har förlorat tillgången eller att det finns en avvikelse med tillgången.",
    "withdraw.index.4639F3A6E07C00B3": "Nästa steg",
    "withdraw.index.81Fba0D65Cc5C3Aa": "Ange eller klistra in din plånboksadress",
    "withdraw.index.25768141C8Bdea91": "Ange uttagsbeloppet",
    "withdraw.index.97A85734Cd1B7Bf0": "Vänligen ange fondens lösenord",
    "withdraw.index.39C7D74B33241C78": "Uttagsbeloppet är fel",
    "withdraw.index.86A5D17908197525": "Uttagsbekräftelse, väntar på granskning för att släppa mynt)",
    "withdraw.index.48Fb603C6638C7F6": "Ange ett fondlösenord",
    "withdraw.index.873Ade9A3Bacc2F7": "antydan",
    "bill.index.5Bbb2A6Ac68D6B2E": "faktiska kontot",
    "bill.index.75751E0Eb8261C40": "Demokonto",
    "bill.index.Cbc853B37Ecc2E97": "Kontoändringstyp",
    "bill.index.4A0Cacddeb2E38B1": "kontotyp",
    "bill.index.F16217028E098B2F": "Valuta",
    "bill.index.A620Dbe6F06B78Ba": "Alla typer",
    "bill.index.2C3C148Bb94D9B3F": "Alla konton",
    "c2C.index.Ab45695E4Bf1E382": "C2C transaktion",
    "c2C.index.2702Dc911Dfea762": "jag vill köpa",
    "c2C.index.D737D5B8367A53C4": "jag vill sälja",
    "c2C.index.C0C540A3Ba9Abe01": "Volym",
    "c2C.index.D8644E8D60C8Bd8A": "enhetspris",
    "c2C.index.Ebb8670B203D3604": "begränsa",
    "c2C.index.F8C6245A2Ebf1D27": "Alla bankkort",
    "c2C.index.F8C6245A2Ebf1D28": "valutainsamling",
    "c2C.index.F8C6245A2Ebf1D29": "bankkort",
    "c2C.index.Cc3Aa8141Da9Ec9E": "köpa",
    "c2C.index.Cc3Aa8141Da9Ec9F": "sälja",
    "c2C.index.67A39834582Ad99A": "Inga data",
    "c2C.order.04C23810B47F77F7": "efter belopp",
    "c2C.order.6905365902E0A73D": "efter kvantitet",
    "c2C.order.3F0Ecebbd9D2F23B": "Tillgängliga",
    "c2C.order.16853Bda54120Bf1": "Allt",
    "c2C.order.Ad3335796F2C95F7": "klumpsumma",
    "c2C.order.61092E27A6879318": "För att skydda tillgångarnas säkerhet, vänligen öka din medvetenhet om förebyggande!",
    "c2C.order.0F51881F0Fb920B2": "Handelsinformation",
    "c2C.order.Dd74Ceca85E5Bc65": "Tidsgräns för betalning",
    "c2C.order.De3341388A59073F": "30 minuter",
    "c2C.order.D334B597A352167F": "Säljarens smeknamn",
    "c2C.order.D334B597A352167G": "Köparens smeknamn",
    "c2C.order.9D5F89F99Ced890D": "transaktionsmedel",
    "c2C.order.8Adf755B14Bc6B7C": "Vänligen läs följande först",
    "c2C.order.686B16F454A7338B": "Gör inga anmärkningar när du gör bankkortsöverföringar, annars frigörs inte pengarna och kontot spärras direkt. Efter betalning måste du ange ett nytt diagram för transaktionsdetaljer efter betalningen (om P-diagrammet eller dolda transaktionsdetaljer rapporteras till plattformen för att frysa kontot)",
    "c2C.order.9Bec8Fba867B739B": "Välj betalningsmetod",
    "c2C.order.Cf2D892C419Fea36": "minimiköp",
    "c2C.order.7290A6B7456A03E0": "största köpet",
    "c2C.orderConfirm.F7E99B8C203E2B07": "Orderdetaljer",
    "c2C.orderConfirm.036D94Dc2D1Cf7D3": "Beställning har genererats",
    "c2C.orderConfirm.0F5433503F3845Ce": "Vänligen betala säljaren inom nedräkningstiden.",
    "c2C.orderConfirm.24C3Bcacdb85A451": " Väntar på köparens betalning ",
    "c2C.orderConfirm.8E8140D7Ab607B24": "kontakta säljaren",
    "c2C.orderConfirm.8E8140D7Ab607B25": "kontakta köparen",
    "c2C.orderConfirm.Ec4D8722Ec15C813": "Säljarens digitala valuta är nu insatt på depositionskontot, betala gärna  ",
    "c2C.orderConfirm.69D1Fbbc7700C1Ab": "Köparens riktiga namn：",
    "c2C.orderConfirm.E5Ed8Ec53Edcd5D3": "7x24 timmars kundservicesupport ",
    "c2C.orderConfirm.Abf9F4B8C93D641E": "ordernummer",
    "c2C.orderConfirm.726C51702F70C486": "skapande tid",
    "c2C.orderConfirm.E097Bb9B1216Bfd8": "Kontakta Kundservice",
    "c2C.orderConfirm.103408Fbbe30287F": "avbryta beställningen",
    "c2C.orderConfirm.4E93Df1Fb521F4Ba": "Jag har bekräftat betalningen",
    "c2C.orderConfirm.3B5418Dbea7Ebfe4": "Redan betalat",
    "c2C.orderConfirm.387Ca3625B260E4E": "Din betalda beställning",
    "c2C.orderConfirm.7C83D5F1Db1A08B7": "avbryta transaktionen",
    "c2C.orderConfirm.76C86566Cc3751C0": "Beställning annullerad",
    "c2C.orderConfirm.205B349751D176B4": "Betalning genomförd",
    "c2C.paymentAdd.6F2A7Dcc66E6Acba": "betalningsmetod",
    "c2C.paymentAdd.C87E865Df6800304": "Konfiguration av betalningsmetod",
    "c2C.paymentAdd.B59Ae0F1B1C235Df": "Typ av betalningsmetod",
    "c2C.paymentAdd.06910Bddf002695F": "verkliga namnet",
    "c2C.paymentAdd.3E33Ae3Efa7507Dd": "Paypal konto",
    "c2C.paymentAdd.3E33Ae3Efa7507De": "Bankkontonummer/kortnummer",
    "c2C.paymentAdd.3E33Ae3Efa7507Df": "Valutans namn",
    "c2C.paymentAdd.595Ede91Abf6F6E0": "Betala QR-kod",
    "c2C.paymentAdd.1Ecc8Be9Ba1C940A": "Klicka för att ladda upp",
    "c2C.paymentAdd.6Daae340F2E9718A": "Bank namn",
    "c2C.paymentAdd.347751415C3Da489": "Kedjenamn",
    "c2C.paymentAdd.15B3627Faddccb1D": "Anmärkning",
    "c2C.paymentAdd.86Da96Cbd274058F": "Varm påminnelse: När du säljer digital valuta kommer den betalningsmetod du väljer att visas för köparen. Bekräfta att informationen är korrekt ifylld.",
    "c2C.paymentAdd.4Ce9979Bfb6576D9": "spara",
    "c2C.paymentAdd.90D2A3A5C07179C0": "vänligen ange ditt riktiga namn",
    "c2C.paymentAdd.A3B0Cd3Ad9Ca3475": "Vänligen ange din",
    "c2C.paymentAdd.Ec389E6190Eedb62": "Ange ditt banknamn",
    "c2C.paymentAdd.9Be51Fb1E9025Fb2": "Vänligen ange ditt kedjanamn",
    "c2C.paymentAdd.94F034F0E01439E0": "Vänligen ange din adress",
    "c2C.paymentAdd.B12E035663A42B32": "Alla bankkort",
    "c2C.paymentAdd.C7B677F393A8F8Ac": "valutainsamling",
    "c2C.paymentAdd.Abafe11737Bab7Cd": "Ladda upp betalningskoden",
    "c2C.paymentAdd.07C443212Ff04Bfb": "Tillagt framgångsrikt",
    "c2C.paymentDetail.86Da96Cbd274058F": "Varm påminnelse: När du säljer digital valuta kommer den betalningsmetod du väljer att visas för köparen. Bekräfta att informationen är korrekt ifylld.",
    "c2C.paymentDetail.C4C613Da60226De4": "Har modifierats",
    "c2C.paymentMethod.7609F7C8337C7A17": "C2C betalningsmetod",
    "c2C.records.9E36893D5736Abb4": "Orderlista",
    "c2C.records.4B53F9A5B13B3235": "Väntar på betalning",
    "c2C.records.4B53F9A5B13B3236": "avbryta transaktionen",
    "c2C.records.4B53F9A5B13B3237": "Redan betalat",
    "contract.index.9706Df877458F0Be": "Fullt lager",
    "contract.index.623Fc7D3D40Aa479": "Initial marginal",
    "contract.index.F92Dce1169330387": "Marknadspriset är oförändrat",
    "contract.index.3598B97Dd28Dbf20": "Vänligen ange antal (styck)",
    "contract.index.6E1Fae821Dc0B797": "Hållbar",
    "contract.index.Eb07Eb0C37C14575": "Leverans",
    "contract.index.Eb6492F19B1C1Fb6": "Historisk transaktionsinformation",
    "contract.index.873Ade9A3Bacc2F7": "antydan",
    "contract.index.Ff63B0F93D39F50B": "antydan",
    "contract.index.Ff63B0F93D39F50D": "Gå långt",
    "contract.index.Ff63B0F93D39F50E": "kort",
    "contract.index.Ff63B0F93D39F50F": "kort",
    "contract.index.Ff63B0F93D39F50G": "Stänga alla positioner?",
    "customer.dialog.3D410C88E8A24Fe8": "Bekräfta smeknamn",
    "customer.dialog.4B3340F7872B411F": "Annullera",
    "customer.dialog.549B1456Bb5F98B3": "Vänligen ange ditt smeknamn",
    "customer.dialog.B773B3E45Ccfad00": "Smeknamnet är tomt",
    "customer.index.7308C6B849D159E9": "Bilddetaljer",
    "delivery.callDialog.C74F562C3Fe91Fe3": "framgångsrikt beställd",
    "delivery.callDialog.B8C98F42Caa15Ca5": "riktning",
    "delivery.callDialog.B79A581Dae543974": "långt köp",
    "delivery.callDialog.B79A581Dae543975": "kort köp",
    "delivery.callDialog.3B26F0F7E90773F3": "Öppningspris",
    "delivery.callDialog.65Fb252Bd94E1039": "Beräknad intäkt",
    "delivery.index.B483Ba4Fce633539": "Leverans",
    "delivery.index.224Dd6Cb58D58249": "handelsperiod",
    "delivery.index.28158F517E902788": "Minsta inköp",
    "delivery.index.A1232162B14Bec76": "Följesedel",
    "delivery.index.445B1D3176D9C69D": "Historiska beställningar",
    "delivery.index.Ab6Cb86E28653C5C": "Leverans",
    "delivery.index.20A7A6F14D3698C0": "Öppnings tid",
    "delivery.index.Ec6821A49395Ddc2": "Stängningstid",
    "delivery.index.254Fdc665C391743": "Slutkurs",
    "delivery.index.2A0C5E68B44A472A": "Vinst och förlust",
    "delivery.index.E05B33309F6D236A": "Vänligen ange antal",
    "delivery.index.B2899E073Cb355D9": "Minsta köp",
    "download.description.869467A93C7Dd4A1": "Installationsanmärkningar",
    "download.description.C80C1Edd6Bee12Bc": "1.Tillåt beskrivningsfiler att laddas ner",
    "download.description.2F5Bc180F92642B4": "2.Beskrivningsfil har laddats ner",
    "download.description.3C3B849F6Ccf712F": "3.Ange [Settings] på din telefon och den nedladdade beskrivningsfilen kommer att visas under kontoinformationen.",
    "download.description.86Affd862985E045": "4.Klicka för att ladda ner beskrivningsfilen",
    "download.description.32832492Bfc1D95E": "5.Klicka för att installera",
    "download.index.245883C80F181C4A": "ladda ner",
    "download.index.Ef3De8Cb2722937A": "Börja handla när som helst, var som helst",
    "download.index.F4A87Eaeed691638": "Håll dig uppdaterad med de senaste nyheterna via vår APP",
    "download.index.Ad3Aba52D0D39D01": "Ladda ner installationspaketet",
    "download.safariGuide.61Bd9A8Fcc2Ea788": "Snälla tips",
    "download.safariGuide.2Cc68Aaadf623477": "Besök gärna i Safari",
    "download.safariGuide.C80Fff2A6Ff4E768": "Du besöker en decentraliserad webbplats, vänligen kör i safariapplikationen",
    "download.safariGuide.06Da016F3E2F79Bd": "kopiera länk",
    "finance.index.B034B367545D5236": "Globala högkvalitativa projekt",
    "finance.index.6Ebfac2C0A9228B0": "1,6 miljoner+ aktiva användare, 122,4 miljarder dollar",
    "finance.index.52Bdcaf19659363D": "Likviditetsbrytning",
    "finance.index.D66924272B031Bf5": "Superhög årlig inkomst",
    "finance.index.34789D88Afcefa64": "Visa objekt",
    "finance.index.E12676B95654D21E": "kreditlån",
    "finance.index.4C924Cfd89A11Ac7": "Upptäck fler handelsmöjligheter",
    "finance.index.C251D4B594Da2848": "AI kvantifiering",
    "finance.index.4E53Cf2E5F173Dd3": "Ett klick leasing, AI smart transaktion",
    "finance.records.Cce168301447D1Ba": "pågående",
    "finance.records.4587B6Bd7Cfa4454": "inlösta",
    "finance.records.18685418E3A50147": "Brytning",
    "finance.records.Df9Fd438F97Cc908": "himmel",
    "finance.records.E23Cb03C5Ad359E5": "termen",
    "finance.records.Bd87Dac6Cba7Eba8": "Köpmängd",
    "finance.records.2D0Fff99265E8B1A": "Lönsam",
    "finance.records.2D0Fff99265E8B1B": "Vinst vid förfall",
    "finance.records.024F5F0B851782De": "Gruvtid：",
    "finance.records.3143A26D3F983896": "inlösentid：",
    "finance.records.96574Aaf8Db85Ead": "förtida inlösen",
    "finance.records.531E1829203181Ac": "Är du säker på att du vill lösa in tidigt;",
    "finance.records.9205843B8923Dac3": "Inlösen lyckades;",
    "loan.applyCallback.B9258Ec8746A74D8": "Lånekanal",
    "loan.applyForm.7B8Ed340400A6E2B": "namn",
    "loan.applyForm.549A44Bae725F4A2": "efternamn",
    "loan.applyForm.2A27E61375E7Fea8": "födelsedatum",
    "loan.applyForm.89925B019D537738": "e-postadress",
    "loan.applyForm.Afaeb32Cb4D62E28": "Hemadress (inklusive enhetsnummer)",
    "loan.applyForm.E05B5D049B64B040": "Stad",
    "loan.applyForm.B166E4E8Fe9513Fa": "nation",
    "loan.applyForm.83Aaede109E395F8": "postnummer",
    "loan.applyForm.647Ed21332A4968B": "din månadsinkomst",
    "loan.applyForm.Cf0A9B845D34D4B4": "Ytterligare inkomst (valfritt)",
    "loan.applyForm.7048F618Acc6A54C": "Månadshyra eller betalning av bolån",
    "loan.applyForm.15C1Bba18Ae18545": "Berätta om du har ett bolån",
    "loan.applyForm.3921E90625A84E36": "Om ingen bolånegaranti väljs är sannolikheten låg att klara granskningen.",
    "loan.applyForm.262Eec4F8906B0F6": "Finns det ett bolån?",
    "loan.applyForm.8634Eb21B6910F21": "Välj typ av inteckning",
    "loan.applyForm.A4897B3A331B5E61": "Transaktionskuponger",
    "loan.applyForm.6623384C683De457": "Låt oss hålla kontakten",
    "loan.applyForm.A8360Bdba86834Cb": "Vi kan kontakta dig på detta nummer om vi behöver ytterligare information om din låneansökan",
    "loan.applyForm.C1C373F385A4C7B3": "telefonnummer",
    "loan.applyForm.1B9785Badb91191F": "Efter att ha fyllt i informationen, vänta på systemutvärdering och granskning.",
    "loan.applyForm.8755B9C761D4080C": "Jag har läst och elektroniskt undertecknat mitt samtycke",
    "loan.applyForm.3E987B787468148C": "Användarvillkor och elektroniskt samtycke",
    "loan.applyForm.Bc54E76B61C50Fb9": "Vänligen läs och godkänn avtalet",
    "loan.applyForm.89E158384F634Eb8": "Återgå till föregående steg",
    "loan.applyForm.Efa5A4548E2F3510": "Hemadress",
    "loan.applyForm.4Cadf1787541B35D": "ja,",
    "loan.applyForm.Fab408D2F4100447": "Nej,",
    "loan.applyForm.3Bad7A307480Cc56": "E-postformatet är fel;",
    "loan.applyForm.306322F49B36855D": "Ansökan har skickats in, vänta på kundtjänstgranskning",
    "loan.applyForm.3C6E0E858C995Ff9": "Ladda upp transaktionskupong",
    "loan.applyForm.2Bf443476B271376": "Upp till 4 bilder kan laddas upp",
    "loan.applyQuota.Ef1D8E23003B9A6F": "Lånekanal",
    "loan.applyQuota.Ef1D8E23003B9A6G": "Få lånegräns",
    "loan.applyQuota.9E0780F9352C7273": "saldo i plånboken",
    "loan.applyQuota.Cbc1Eb9D639A6105": "Totalt tillgängligt lånebelopp",
    "loan.applyQuota.033A05462195B080": "Lånat belopp",
    "loan.applyQuota.9Aa89B036Ef4821D": "återstående balans",
    "loan.applyQuota.5656Fb52Ef17A557": "Få lånegräns",
    "loan.applyQuota.6Ff6702B4C917D40": "under granskning",
    "loan.applyQuota.6Ff6702B4C917D41": "avvisade",
    "loan.applyQuota.6Ff6702B4C917D42": "lyckades",
    "loan.applyQuota.0Bad0E857Ea4A952": "ansökningstid",
    "loan.applyQuota.3E7E56E2De2Cc546": "Ansökningsbelopp",
    "loan.applyQuota.8F1D170D9E54Af5B": "Ansökan framsteg",
    "loan.applyQuota.6167Ca035B93Caf3": "anledning",
    "loan.applyQuota.410C7954F9A3981D": "Ansök igen",
    "loan.bottomSheet.61Cd62F861D8F5B2": "Vänligen välj valuta",
    "loan.index.4C10307B22E885C3": "Upptäck fler handelsmöjligheter och skapa välstånd",
    "loan.index.Ae8D37Dea2B3717B": "ränta",
    "loan.index.1Bb54625682E85Db": "Regelintroduktion",
    "loan.index.5Bd31Fab681C6E9D": "se mer",
    "loan.index.111F95F404814327": "Lånevaluta",
    "loan.index.6Bc2723B3Bec106D": "lån",
    "loan.index.3200106280E6E370": "ränta",
    "loan.index.049A6Ccb6C81A635": "Lånebelopp (min/max)",
    "loan.productDetails.5037Ad86Ab4B7E02": "lån",
    "loan.productDetails.37181C74559Db182": "Beställa ",
    "loan.productDetails.5802Aaf06A1E4755": "Likviditet",
    "loan.productDetails.89Aea9Cf9337C40F": "Finns tillgänglig att låna",
    "loan.productDetails.9E419Ae2D06F3Ad9": "Kvantitet som ska återbetalas",
    "loan.productDetails.C65F329Ef14C4A3D": "Antal lånade mynt",
    "loan.productDetails.3A74667815Adc8Bf": "maximal",
    "loan.productDetails.Ab851D8351573227": "himmel",
    "loan.productDetails.Ddf82D8A2C8F46A0": "återbetalningscykel",
    "loan.productDetails.C105E5562005Aeb1": "himmel",
    "loan.productDetails.3F346Be2137F0756": "Lånebelopp (min/max)",
    "loan.productDetails.2707F78Df864E194": "Intressera",
    "loan.productDetails.B9Dc9B81E726Bdf1": "Vänligen återbetala i tid Om det är försenat debiteras ett extra dagligt belopp på (kapital + ränta).",
    "loan.productDetails.B9Dc9B81E726Bdf2": "% är förfallen avgift",
    "loan.productDetails.4D94Faa033Be7356": "Jag har gått med på det",
    "loan.productDetails.62429A957A22780A": "Serviceavtal för myntutlåning",
    "loan.productDetails.Bc54E76B61C50Fb9": "Vänligen läs och godkänn avtalet",
    "loan.productDetails.Ec8Ba071C53A67Cb": "Låna nu",
    "loan.productDetails.59246A0Bf081D398": "minst",
    "loan.productDetails.Ec3F1172B71Cd36C": "minimibelopp",
    "loan.productDetails.7Ccc4Cf1D81930B3": "största mängden",
    "loan.productDetails.071488598990Aa68": "Vänligen läs och godkänn avtalet;",
    "loan.productDetails.579Dd216D3339119": "Lånet lyckades;",
    "loan.records.4951B36Fd0D2A831": "Låne- och kredithistorik",
    "loan.records.Bb0Aaa30514B7Cba": "nuvarande rekord",
    "loan.records.0F8B0A7D80769051": "Återbetalningshistorik",
    "loan.records.D4B42E43D88De6F1": "historia rekord",
    "loan.records.Cb1847D6281897E6": "Lånebelopp",
    "loan.records.C7Bfcacfc2Adea58": "Återbetalningsbelopp",
    "loan.records.8B369164D5Fea340": "Lånetid：",
    "loan.records.Ab2402Ad3Ffd35E7": "Betala av lånet i förtid",
    "loan.records.B7A0Ca0C195D0Cd1": "Släpptid：",
    "loan.records.42014Bdcfd9C411B": "Återbetalningstid：",
    "loan.records.A0Acfaee54F06458": "avslutad",
    "loan.records.67A39834582Ad99A": "Inga data",
    "loan.records.6A687Ff5B8F1598F": "Är du säker på att du vill betala tillbaka i förskott?",
    "loan.records.7C20D7008D0Eae86": "Återbetalningen lyckades",
    "mining.bottomSheet.Ba91637D32Ccba04": "Likviditetsbrytning",
    "mining.bottomSheet.7De3D636A165E008": "avkastning",
    "mining.bottomSheet.F3003345B189D683": "Gruvperiod",
    "mining.bottomSheet.3E4C0A8C533F5848": "mynttyp",
    "mining.bottomSheet.Adcba368993Ae65E": "Prenumerationsbelopp (minsta/högsta)",
    "mining.bottomSheet.94743B24E4Ed4166": "Obegränsat",
    "mining.bottomSheet.286944Fedd211257": "Tillgängliga medel",
    "mining.bottomSheet.7B5766Dc65Abc0C3": "Beräknad intäkt:",
    "mining.bottomSheet.9Bbaf80Fdb6Fd148": "Min nu",
    "mining.bottomSheet.Bb1493C567C114Dd": "Snälla tips：",
    "mining.bottomSheet.D2315E87E34A3Bd3": "Förtida inlösenavgift",
    "mining.index.8B2Bba1422Eed6F0": "Skapa tiotals miljarder av välstånd, 100% nettoinkomst",
    "mining.index.42997E300086Ee89": "högsta avkastning",
    "mining.index.510F74F42052Ecce": "Gruvprodukter",
    "mining.index.B853F96626106912": "Gå till gruvdrift",
    "mining.productList.37181C74559Db182": "Beställa",
    "mining.productList.Aac5B810004C46C8": "regelbunden",
    "mining.productList.D2Fb4F78C387D888": "Du har framgångsrikt minerat",
    "quantify.bottomSheet.9Ea4A318F690Bc95": "Bekräfta order",
    "quantify.bottomSheet.6915303B698303B1": "robot",
    "quantify.bottomSheet.68045296Dd0F8Ad6": "Hyresbelopp",
    "quantify.bottomSheet.Fbd931286Be387Aa": "cykel",
    "quantify.bottomSheet.9Ad84Db481807844": "obegränsat",
    "quantify.bottomSheet.994D7226Ca083Ea5": "Daglig avkastning",
    "quantify.bottomSheet.0Bf82Ee4Bfe063B7": "valuteringsdag",
    "quantify.bottomSheet.88008603Ba7Bde64": "Köpet lyckades",
    "quantify.bottomSheet.Ff50B3091Bbd09Ab": "Få inkomstdelning från och med nu！",
    "quantify.bottomSheet.C778A674C4A054F9": "kontrollera ordning",
    "quantify.bottomSheet.685Dad322B7029B0": "Returtransaktion",
    "quantify.buyForm.Acf8560A04334E95": "inköpspris",
    "quantify.buyForm.Cf9Cfbc2C554944F": "Översikt",
    "quantify.buyForm.8788Df2B625E97B5": "robot",
    "quantify.buyForm.50Ca63B45E881D50": "Utdelningstid",
    "quantify.buyForm.Fcda03Adbc5Fca97": "varje dag",
    "quantify.buyForm.Afb89504Fc399189": "Vänligen ange inköpskvantiteten",
    "quantify.buyForm.1435Afdfb4Ec0596": "Minsta inköpskvantitet",
    "quantify.buyForm.1B97F1730Bacd839": "Maximal inköpskvantitet",
    "quantify.index.32F56C9B7D0E250A": "Period: obestämd",
    "quantify.index.E34F028D00904630": "pris",
    "quantify.index.E34F028D00904631": ",Daglig avkastning",
    "quantify.productDetail.6Bbe178Df539A5Ca": "Spärrat belopp",
    "quantify.productDetail.53635B53Bca31Dfa": "Beräknad daglig inkomst",
    "quantify.productDetail.F9E46449A6269E09": "Kumulativ inkomst",
    "quantify.productDetail.98Fc46B1B8C993B2": "Beställ hosting",
    "quantify.productDetail.93624Bb045830966": "Hyr nu",
    "quantify.records.0C16A18C32B9Cf4A": "Orderrekord",
    "quantify.records.B191F5614B0E2312": "Köphistorik",
    "quantify.records.6A06033B86A73F7C": "inlösenrekord",
    "quantify.records.45E0F7E71611618E": "Kumulativ inkomst",
    "quantify.records.45E0F7E71611618F": "Vinst vid förfall",
    "quantify.records.Aae1201E38C4Baaa": "Löpning",
    "flash.index.C41Fd4F2Fdfa594B": "konsumera",
    "flash.index.1Ac59Fa2218E3B00": "Tillgängliga:",
    "flash.index.Bf7233A660821549": "skaffa sig",
    "flash.index.548E775Dd67C7472": "Bekräfta utbyte",
    "flash.index.066A2449Bd616F03": "Vänligen ange inlösenkvantiteten",
    "flash.index.Fca26Fcf9C06563F": "Flash-inlösen lyckades",
    "message.index.40031524233F4564": "Meddelandecenter",
    "message.index.8E5586210Fd44D69": "Evig positionspåminnelse",
    "message.index.Bcd3F470E88C197D": "Ingen anmälan ännu",
    "message.index.23A557Fbce545879": "Permanent likvidationsmeddelande",
    "message.index.B5E19B36B5125550": "Påminnelse om uttag",
    "message.index.716A981465138334": "Ladda påminnelse",
    "message.index.884474Ebff2977Ba": "systeminformation",
    "message.index.08Cc7D24752Ec42C": "onlinetjänst",
    "message.index.1D820A6A19Ab025D": "7 X 24 timmar online manuell service",
    "more.index.29881E0B5Df58Bf7": "Flera funktioner",
    "more.index.C77Ee35911D10D9D": "Vanliga funktioner",
    "more.index.3558F69B0260Be61": "Handelsfunktion",
    "more.index.7243Fe610Ed98Cec": "verkligt konto",
    "more.index.7243Fe610Ed98Ced": "Demokonto",
    "more.index.693C7B35Bdf3E3A3": "finansiera",
    "more.index.F855997Fac114554": "Hjälpcenter",
    "more.index.Ae9A1252278Da742": "hjälp",
    "more.index.Ace012504Bc529E0": "information",
    "more.index.05682Dbef5Eeb0A8": "kundservice",
    "more.index.C7Af227A5D5C7Bb9": "Du har bytt till ett livekonto",
    "more.index.D818A65592694786": "Du har bytt till ett demokonto",
    "notice.details.B360807Dbe727E6E": "om oss",
    "notice.details.92F2552F695E70E3": "om oss",
    "notice.details.7Aaceaf69B67F3D1": "Regelintroduktion",
    "notice.index.6128438Be34Bcb9F": "Evig varsel",
    "notice.index.71627F5Eb7128261": "Uttagsbesked",
    "notice.index.2Dce570Fda841705": "Avisering om laddning",
    "notice.index.228A7C9C6448A9B1": "systemavisering",
    "setting.dialog.8B4F5B74660Deabe": "Färgkonfiguration",
    "setting.dialog.Bfc7D25486Ebc1A9": "Gröna stiger och röda fall",
    "setting.dialog.796Ea0E1A82C320B": "Röda stiger och gröna fall",
    "setting.index.8Ddc5864E1739466": "Inställningar",
    "setting.index.F5205801D57D6727": "språk",
    "setting.index.2E2C12Dd0Cc1B63B": "Offertvaluta",
    "setting.index.322D8496Aec3D918": "versionsnummer",
    "setting.index.Aa7E6C204C751Cfa": "logga ut",
    "share.index.75Bc38Bbf7D2E766": "bjuda in vänner",
    "share.index.45575Eb5D42213E6": "min inbjudningskod",
    "share.index.29F67F0644A78Bf3": "kopiera",
    "share.index.19Cc65Bb6Bb98Fe7": "Min inbjudningslänk",
    "share.index.5372712F162Fb78B": "Antal direkt underordnade",
    "share.index.C88D6B95E3Ab7D6F": "Totalt antal underordnade",
    "share.index.Bb5Fa5471Aeecc62": "Kommissionsrabatt till direkt underordnade",
    "share.index.426B6Bac9A5F3D4F": "total rabatt",
    "share.index.197E4A428F0401C9": "Visa rabattposter",
    "share.records.88A6B2E2B74B13C4": "kommissionens rekord",
    "share.records.B671A35Ffdc93B89": "Rabatt",
    "transfer.index.B17617A869C03Ca5": "plånbok",
    "transfer.index.E4116Dcc6F6C7270": "Överföringen lyckades",
    "transfer.index.48Fb603C6638C7F6": "Ange ett fondlösenord",
    "transfer.records.Eb0E39Eb5911D418": "skaffa sig",
    "transfer.records.977554Be0Aa65052": "Flash-inlösentid：",
    "transfer.records.9Aa33884Aa400B7C": "överföringstid：",
    "form1.forget.76636Db8Fae5B124": "din brevlåda",
    "form1.forget.35354B9Cca1Bb0E6": "E-postverifieringskod",
    "form1.forget.604A0B11E916F387": "Lösenord",
    "form1.forget.9Cb1C9C51E7503D6": "Bekräfta lösenord",
    "form1.forget.3A4F7E04B008453C": "Ditt telefonnummer",
    "form1.forget.Cf11Dce5B4713B5A": "Mobiltelefonens verifieringskod",
    "form1.forget.3012Edba57D4F297": "Vänligen ange din e-postadress",
    "form1.forget.F1Ee1D97E869E447": "vänligen ange verifieringskoden",
    "form1.forget.3A5C4C9Ae68Fa0F6": "Vänligen ange ditt lösenord",
    "form1.forget.C6Cb174Da411D5F7": "Ange din mobiltelefon",
    "form1.forget.947B3B21Ee45E582": "skicka",
    "form1.forget.3E0580F1E8630Df6": "Två lösenord är inkonsekventa",
    "form1.forget.C5A61836B8Cc74C6": "Lösenordsändringen har omdirigerats...",
    "form1.forget.B96D072Bc8962565": "Skicka igen",
    "form1.login.2B72C27Ff828Cfc4": "ditt lösenord",
    "form1.login.D7C6B872Af9Cb17F": "kom ihåg mitt lösenord",
    "form1.login.0Fff44C827A4F3B6": "Logga in",
    "form1.login.1B7F2C1Ede3990Cc": "Inget konto? Anmäl dig nu",
    "form1.login.F657F7Be1Cc33Bd2": "glöm lösenordet",
    "form1.login.2074A1Eec4B088B0": "Plånboksinloggning",
    "form1.login.1B8938Fbcc3C9B56": "Inloggningen lyckades och omdirigering...",
    "form1.login.07162A7Bb9310555": "Installera ERC20-plånboken",
    "form1.login.766969966Ae151Dc": "Installera TRC20-plånboken",
    "form1.login.B88171E1Ad4C50Bf": "Du avböjde att byta nätverk",
    "form1.register.Aaef0710Cb4Db554": "Ange lösenordet igen",
    "form1.register.54Dfea0415C708Ff": "Inbjudningskod",
    "form1.register.6839940Dac08C794": "Registrera",
    "form1.register.9901A3B5550Aa58E": "Har du redan ett konto? Logga in",
    "form1.register.2548Ca53785252Ea": "Vänligen ange din inbjudningskod",
    "form1.register.Dd71Fd65900Dd03E": "Vänligen läs registreringstjänstavtalet och godkänn,",
    "form1.register.7975613909490B77": "Registrering lyckad och omdirigering...",
    "form1.walletDialog.105829Acfc0Bd6Aa": "Välj plånbok",
    "userc.certification.2Af9511640153D2E": "Juniorcertifiering",
    "userc.certification.177B191D8E0Fa84D": "inte certifierad",
    "userc.certification.601218D4D572E98A": "verifierad",
    "userc.certification.6E37C51Ba9012Fc8": "Gick inte godkänt",
    "userc.certification.1B9B563E05C96474": "Avancerad certifiering",
    "userc.certification.1418B83803Ab56B4": "Vänligen slutför den primära certifieringen först",
    "userc.creditScore.0B149112601Acc4D": "kreditvärdighet",
    "userc.creditScore.88Ac7F84E106C5F6": "total kreditpoäng",
    "userc.creditScore.3E1D6E0E7F1E6155": "Systemutvärdering",
    "userc.index.62Eac58D04Bbca0F": "Personligt center",
    "userc.index.F4Cac53F93Cb2Aff": "simulerad handel",
    "userc.index.42F409Fdf3559773": "Säkerhetscenter",
    "userc.index.1B8E211009807Db8": "Autentisering",
    "userc.index.Bdfcf21Eb8F2Eb70": "Kontoändringsposter",
    "userc.index.6C18D67F6D19586B": "Plattformsintroduktion",
    "userc.index.807A814Ed58A93Bc": "rensa cache",
    "userc.index.E43Daad59759611C": "inte certifierad",
    "userc.secureCenter.F5C70F0Db2F1Ba76": "Ändra inloggningslösenord",
    "userc.secureCenter.1F37Ba427Debf24A": "Kontobindande",
    "userc.secureCenter.F9A5Ffa750664004": "Bind Google Authenticator",
    "userb.certificationForm.7685Af7D30043Cd6": "Juniorcertifiering",
    "userb.certificationForm.7685Af7D30043Cd7": "Avancerad certifiering",
    "userb.certificationForm.70E37C35Abc3F462": "Land",
    "userb.certificationForm.F92C25A68E4D08C5": "Stad",
    "userb.certificationForm.0Ccc6387B38E0318": "Ditt namn",
    "userb.certificationForm.94C67Bba370Fdd46": "licensnummer",
    "userb.certificationForm.B2D4C7Fffe79258E": "Post",
    "userb.certificationForm.57554268761Fb47A": "Ladda upp framsidan av ditt ID",
    "userb.certificationForm.D79E90201F5319Bd": "Ladda upp baksidan av ditt ID",
    "userb.certificationForm.9C6Bad08Af29E20E": "Ladda upp en bild av ditt ID-kort",
    "userb.certificationForm.F96B62Dfa31Cde45": "Bekräfta inlämning",
    "userb.certificationForm.2125D78Ea8C6D287": "Uppladdningskrav",
    "userb.certificationForm.0D64864D3076A824": "Se exempel",
    "userb.certificationForm.35287Deb72281E87": "1.Måste kunna läsa ID-kortsinformation tydligt.",
    "userb.certificationForm.3007F2E01Efc7B57": "2.Innehållet i bilden är sant och giltigt och inga ändringar är tillåtna.",
    "userb.certificationForm.25A90660554319F9": "3.ID-numret och namnet måste vara väl synligt, stödja JPG/JPEG/PNG",
    "userb.certificationForm.5A9Aefbc03C778F7": "Stig på",
    "userb.certificationForm.3B7A1D8Ce27C937E": "Inlämningen lyckades, vänta på granskning",
    "userb.certificationForm.744Bd07D8Abd2513": "Ladda upp framsidan av ditt ID",
    "userb.certificationForm.6De1C319130F7Fef": "Ladda upp baksidan av ditt ID",
    "userb.certificationForm.79F8E8B172E30C8A": "Ladda upp ett foto av ditt ID-kort",
    "userb.certificationForm.46412E16D28A4753": "Fotouppladdning misslyckades",
    "userb.changeDialog.676B0Adca1F8Eea4": "ändra lösenord",
    "userb.modifyInfoDialog.F41De3A1Fa69135A": "telefonnummer",
    "userb.modifyInfoDialog.3C3Aac9664A35Ef8": "valideringsbrytare",
    "userb.modifyInfoDialog.62149E566814154C": "Välj validator",
    "userb.modifyInfoDialog.8E69468E2435Fc89": "Öppna appen Autentisering och tryck på +",
    "userb.modifyInfoDialog.7625E5Bdcbfe9963": 'Välj "Skanna QR-kod" eller "Enter-nyckel".',
    "userb.modifyInfoDialog.93F094E2B4369472": "Skanna QR-koden eller skriv in nyckeln",
    "userb.modifyInfoDialog.43E4F1532529Ee1E": "stäng/öppna",
    "userb.modifyInfoDialog.Bae6358Dfd95025A": "Bekräfta det nya fondlösenordet",
    "userb.modifyInfoDialog.E1133Cd4B1C2Abb7": "Nytt fondlösenord",
    "userb.modifyInfoDialog.C1Ce0403A2523Ee0": "Bekräfta det nya fondlösenordet",
    "userb.modifyInfoDialog.8Dd453Adc2Cd78Fb": "Bekräfta fondlösenord",
    "userb.modifyInfoDialog.Bb0D9854C79325Bb": "Ange ditt gamla fondlösenord",
    "userb.modifyInfoDialog.E66Cb29B00Bc54D1": "Ange ditt nya fondlösenord",
    "userb.modifyInfoDialog.E8Ae2Ec5Df401255": "Ange ditt fondlösenord",
    "userb.modifyInfoDialog.258A534498Fe984F": "Vänligen bekräfta ditt fondlösenord",
    "userb.modifyInfoDialog.768Ec43Faa193F08": "Kontobindande",
    "userb.modifyInfoDialog.D34746090C25E5Db": "Fondens lösenord",
    "userb.modifyInfoDialog.E4F27D8916E5E536": "bekräfta",
    "userb.modifyInfoDialog.Bef7160Ce53773E0": "Validatorn är stängd",
    "userb.modifyInfoDialog.Be8Bf47Ba6820De1": "Google-verifiering har aktiverats",
    "userb.modifyInfoDialog.24Dba26593F43C74": "Fondens lösenord har ändrats",
    "userb.modifyInfoDialog.5C369D42Dc7Dbe76": "Ange fondlösenordet framgångsrikt",
    "userb.modifyInfoDialog.667Bf8D0B6C36D4C": "Bindningen lyckades",
    "userb.modifyInfoDialog.1C5E05Efee2B0B9F": "Verifieringskodfel",
    "userb.veBottomSheet.Eecc4683C725F6F2": "Exempel på uppladdning av certifikat",
    "userb.veBottomSheet.6F38A0D5858633Ee": "mörk ren bakgrund",
    "userb.veBottomSheet.Af162Dbdfc4Fcae9": "Tillräcklig belysning",
    "userb.veBottomSheet.0F7Baf0Cee3C8141": "Inriktningshjälpram",
    "userb.veBottomSheet.Ac9E471Eead0Cf11": "Foton är tydliga",
    "userb.veBottomSheet.50B741382E71F103": "Möjliga orsaker till misslyckande",
    "userb.veBottomSheet.Aa56C68E8Fd4Cfb4": "ID-kortskyddet har inte tagits bort",
    "userb.veBottomSheet.5D2F429C75D4C7F2": "Bakgrunden påverkas av skräp",
    "userb.veBottomSheet.90F42D3Cb7769Dbc": "ID-kortet är reflekterande",
    "userb.veBottomSheet.1Ee78Cee782D81B4": "Ljuset är för mörkt/hindrat av mobiltelefonens skugga",
    "userb.veBottomSheet.1A75D2Fc2614592B": "håller ID-kort",
    "home.home.C3525Eb110Db58Aa": "Mer",
    "home.home.29761532Fc8Ebecc": "handel",
    "home.home.F15Ea99Bfa6E1848": "Säkerhetscertifiering, köp med förtroende",
    "home.home.46E1Ab39Bae7182A": "Snabbt",
    "home.home.Fde2Eb7F6F3Dd800": "Snabba transaktioner och enkel hantering",
    "home.home.Ea4756Bc1642E0F1": "namn",
    "home.home.B6B1127Ede1C97B4": "senaste priset",
    "home.home.3587Ad870Eb0Ab86": "24H ökning och minskning",
    "home.home.Cb1A4E14Ec2Cd2B5": "Upptäck",
    "home.home.0F1B1Ac0E96A40D1": "Blockchain",
    "home.home.8Eaefb1107A0E210": "Forex",
    "home.home.Ad65263D034Ea051": "ädelmetaller",
    "home.home.5Fc1474Dbf74Ac1A": "jordbruksprodukter",
    "home.home.135318F4664F7773": "energi",
    "kline.kLine.C96Ff250Cf6B8B84": "24h högsta pris",
    "kline.kLine.6E8Be64F19D74565": "24h handelsvolym",
    "kline.kLine.08A4Bfbe26A0F262": "24h lägsta pris",
    "kline.kLine.8701Deb075066331": "24h handelsvolym (USDT)",
    "kline.kLine.Ea3C3052136C84Cd": "1 poäng",
    "kline.kLine.B49Cca240E013124": "5 poäng",
    "kline.kLine.F27A90Be97Ba3D6C": "15 mark",
    "kline.kLine.Ff3E62395B694384": "30 poäng",
    "kline.kLine.C1993F9C6Db1A674": "1 timme",
    "kline.kLine.Fd2Cbbae405E284C": "1 dag",
    "kline.kLine.C9Cd71904395042C": "januari",
    "kline.kLine.7D8127Ec18C7B8C7": "köpa",
    "kline.kLine.E3D3Ffdb9B3E5636": "Kvantitet (styck)",
    "kline.kLine.6834Fec1838D8029": "Pris (USDT)",
    "kline.kLine.16C52Aae474Bc9Cd": "säljorder",
    "kline.kLine.B9Bea1A9A88D1419": "Öppet länge",
    "kline.kLine.D36Cfa96Ee2B343B": "Öppna kort",
    "other.languageRateSelect.6897784800834C1B": "språk inställning",
    "other.languageRateSelect.6897784800834C1C": "Vänligen välj prissättningsvaluta",
    "other.navigationBar.F6E62F9642018Cbb": "framsida",
    "other.navigationBar.0Ec227161A47B49D": "Citat",
    "other.navigationBar.29761532Fc8Ebecc": "handel",
    "other.navigationBar.693C7B35Bdf3E3A3": "finansiera",
    "other.navigationBar.9734C54620Eb09Af": "tillgångar",

    "home.index.25Aa4Fc745150030": "The King of Cryptocurrency",
    "home.index.2D6Ecf8Faaa8B124": "Faster, better, stronger",
    "home.index.1Db26267D1041223": "Easily trade over 1700 cryptocurrencies",
    "home.index.F8E18C90C3E35618": "Register now",
    "home.index.29761532Fc8Ebecc": "Transaction",
    "home.index.D8A95E4506487452": "Popular Contracts",
    "home.index.5Bd31Fab681C6E9D": "View more",
    "home.index.010Ae07A09Caf221": "Trading pair",
    "home.index.F4D875118E6D6Dbb": "Latest transaction price",
    "home.index.3Dfdfb3D6599100E": "24-hour increase or decrease",
    "home.index.28Fb39Bd41E54242": "Trend Chart",
    "home.index.B47A3911Bacd94C6": "Growth List",
    "home.index.712910D574F43D95": "Stable income",
    "home.index.5507Fd830D7Bf51F": "Top strategy, easy to copy",
    "home.index.7De3D636A165E008": "yield",
    "home.index.B853F96626106912": "Go mining",
    "home.index.213B5C96Be7Cd1F8": "Days, annualized interest rate",
    "home.index.213B5C96Be7Cd1F9": "Maximum purchase",
    "home.index.Dc710Cffe6313Bb5": "View details",
    "home.index.B92Ffc837Ecaa1Dd": "News",
    "home.index.6C18D67F6D19586B": "Platform introduction",
    "home.index.6342E3C33Cb48Cd5": "Digital currency is a medium of exchange that uses cryptographic principles to ensure transaction security. Unlike fiat currencies such as the U.S. dollar, digital currencies do not have a physical form. Bitcoin (BTC), Ethereum (ETH) and Polkadot Popular digital currencies such as (DOT) use blockchain as the underlying technology and act as a decentralized digital ledger. All digital currency transactions are recorded in the blockchain and cannot be changed once confirmed and verified. Unlike traditional currencies, which are maintained by banks Unlike centralized ledgers, digital currency transactions occur on a public blockchain and can be accessed by anyone.",
    "home.index.198Efc4254683Eef": "In addition, according to the consensus mechanism, everyone can verify digital currency transactions and add them to the blockchain, thereby achieving decentralization. With Samsung, BlackRock, Morgan Stanley Leading global companies such as Alphabet and Alphabet have invested in it, and people believe that blockchain technology will completely change the global financial system. Digital currency opens the door to the world of decentralized finance for you, allowing you to explore the infinite possibilities brought by cutting-edge technology .",
    "home.index.E710339D9576292E": "Our advantages",
    "home.index.F9Ce8B2C63885B26": "Safe and stable",
    "home.index.C4D0A2C9Ff0305Ce": "Top technical team, comprehensive security protection, independent research and development of high-speed transaction synthesis engine, which can still operate stably and reliably under massive concurrent transactions.",
    "home.index.2E8708Ee3F11Cc1A": "Professional and reliable",
    "home.index.Bb12Cbcf89C56C5C": "Professional operation team, many years of experience in blockchain and financial industry, holding a compliant digital asset trading license, and 100% reserve guarantee.",
    "home.index.Aa4723Bfbf6808F4": "Service with heart",
    "home.index.1Bf0204B7D3Ee230": "Facing the global market, supporting multiple languages, running 24/7, strong community support, and professional customer service.",
    "home.index.2E7Dfd53D26F7224": "High performance",
    "home.index.1C65453103B5Fcc3": "300,000 transactions per second, order response time is less than 1 millisecond.",
    "home.index.85Ac49Ece9Dcc80B": "Trade anytime, anywhere!",
    "home.index.2799E18842E4Ebe9": "Instant position opening, supported by OKEX App and web page!",
    "home.index.2Bf52Ce75E29Fc88": "Scan the code to download now",
    "home.index.88D1422B33571066": "Android",
    "home.index.04Ee893775563F21": "Apple",
    "home.index.04Ee893775563F22": "Products and Services",
    "home.index.04Ee893775563F23": "Financial Management",
    "home.index.04Ee893775563F26": "IOS & ANDROID",
}
