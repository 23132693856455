import zhHans from 'vuetify/es5/locale/zh-Hans';
export default {
    ...zhHans,
    setting: {
        language: "Tiếng Việt",
        dialogDetermine: "Chắc chắn",
        dreivatives: "xe đạp",
        markets: "Báo giá",
        assets: "tài sản",
        liquidity: "Khai thác thanh khoản",
        helpCentre: "Trung tâm trợ giúp",
        announcementCenter: "Trung tâm thông báo",
        about: "Về",
        bulletinCentre: "Trung tâm thông báo",
        serviceAgreement: "Thỏa thuận dịch vụ",
        support: "ủng hộ",
        onlineSupportCustomerService: "dịch vụ trực tuyến",
        suggestionsAndFeedback: "Đề xuất và phản hồi",
        service: "Phục vụ",
        download: "Tải xuống",
        coinApplication: "Ứng dụng niêm yết tiền xu",
        privacyStatement: "Cam kết bảo mật",
        trade: "giao dịch hợp đồng",
        standardConteact: "Bền vững",
        signOut: "đăng xuất",
        assetWallets: "Ví tài sản",
        financeRecords: "Hồ sơ thay đổi tài khoản",
        identification: "Xác thực danh tính",
        personalCenter: "Trung tâm cá nhân",
        chooseLanguage: "Chọn một ngôn ngữ",
        tips: "gợi ý",
        dark: "chế độ tối",
        light: "chế độ ngày",
        languageTitle: "ngôn ngữ",
        convertCurrency: "Chuyển tiền tệ",
        cancel: "Hủy bỏ",
        aboutUs: "về chúng tôi",
        standard: "Tính liên tục của tiêu chuẩn",
        share: "chia sẻ",
        introduce: "Giới thiệu nền tảng",
        set: "cài đặt",
        changePassword: "Đổi mật khẩu đăng nhập",
        denomination: "Đồng tiền báo giá",
        color: "Cấu hình màu",
        code: "số phiên bản",
        color1: "Tăng đỏ và giảm xanh",
        color2: "Màu xanh tăng và màu đỏ rơi",
        setLangue: "cài đặt ngôn ngữ",
        vip: "Quyền lợi VIP",
        vip2: "Cấp độ VIP hiện tại",
        safeMenu: "Trung tâm bảo vệ",
        conventional: "thông thường",
        other: "khác",
        quickTips: "Thêm tối đa 3 nút chức năng phím tắt",
        quickTransaction: "Giao dịch nhanh chóng",
        safeConvenient: "An toàn và tiện lợi",
        timeSharing: "chia sẻ thời gian",
        currentLevel: "mức hiện tại",
        levelBenefits: "thu nhập cấp %{level}",
        teamLevel: "Cấp độ đội",
        levelLower: "%{level}cấp dưới",
        rules: "Mô tả quy tắc",
        bindWithdrawalAddress: "Ràng buộc địa chỉ rút tiền",
        tipsBindWithdrawalAddress: "Bạn chưa ràng buộc địa chỉ rút tiền của mình, vui lòng ràng buộc nó trước",
        walletAddressFormatIncorrect: "Định dạng địa chỉ ví không chính xác",
        tradeRules1: "Dự đoán sự tăng giảm trong thời gian tới. Giá tại thời điểm đặt lệnh được sử dụng làm giá khởi điểm và giá tại giây cuối cùng của khoảng thời gian dự đoán được sử dụng làm giá thanh toán. Nếu giá thanh toán lớn hơn giá khởi điểm, điều đó có nghĩa là tăng và nếu giá thanh toán giá thanh toán thấp hơn giá khởi điểm tức là giá giảm.",
        tradeRules2: "1.hợp đồng là gì",
        tradeRules3: "Nền tảng này cung cấp các hợp đồng vĩnh viễn dựa trên USDT. Người dùng có thể đánh giá mức tăng hoặc giảm và chọn mua hoặc bán hợp đồng ngắn hạn để thu được lợi nhuận từ sự tăng/giảm của giá tài sản kỹ thuật số. Hợp đồng vĩnh viễn không có ngày giao hàng và người dùng có thể giữ chúng mãi mãi.",
        tradeRules4: "2.Ký quỹ là gì",
        tradeRules5: "Trong thị trường hợp đồng ảo, người dùng chỉ cần trả một số tiền nhỏ theo một tỷ lệ nhất định theo giá hợp đồng như một sự đảm bảo tài chính cho việc thực hiện hợp đồng và sau đó họ có thể tham gia mua bán hợp đồng. Loại tiền này là tiền gửi hợp đồng ảo. \nCác hợp đồng trên nền tảng này áp dụng mô hình ký quỹ riêng của các tài khoản riêng biệt. Mỗi loại tiền tệ tương ứng với một tài khoản hợp đồng. Tài sản tài khoản và vị thế của các loại tiền tệ hợp đồng khác nhau độc lập với nhau và việc chuyển khoản và giao dịch giữa các tài khoản hợp đồng khác nhau không ảnh hưởng lẫn nhau. Sau khi tài khoản hợp đồng của người dùng được mở, rủi ro và lợi ích của tất cả các vị thế nắm giữ trong tài khoản hợp đồng sẽ được tính toán cùng nhau.",
        tradeRules6: "3.Cách tính ký quỹ",
        tradeRules7: "Ký quỹ vị thế = mệnh giá hợp đồng * số lượng hợp đồng mở / hệ số đòn bẩy\n ký quỹ cố định = tổng ký quỹ của tất cả các lệnh đặt thành công và lệnh mở trong lệnh hiện tại\ký quỹ khả dụng = số dư tài khoản - ký quỹ vị thế - ký quỹ cố định",
        tradeRules8: "4.Cách tính tỷ lệ ký quỹ",
        tradeRules9: "Tỷ lệ ký quỹ là thước đo rủi ro tài sản của người dùng. \nTỷ lệ ký quỹ = (Lãi và lỗ chưa thực hiện + Ký quỹ vị thế + Ký quỹ khả dụng + Ký quỹ đóng băng - Phí thanh lý) / Ký quỹ vị thế \nTỷ lệ ký quỹ càng nhỏ thì rủi ro của tài khoản càng cao. Khi tỷ lệ ký quỹ nhỏ hơn hoặc bằng 0%, việc thanh lý bắt buộc sẽ được kích hoạt.",
        tradeRules10: "5.Giá ngang bằng bắt buộc ước tính là bao nhiêu?",
        tradeRules11: "Giá thanh lý bắt buộc ước tính, tức là giá được tính toán bởi hệ thống kích hoạt thanh lý bắt buộc về mặt lý thuyết, chỉ mang tính tham khảo và có thể sai lệch đôi chút so với giá trị thực tế.",
        tradeRules12: "6.Tổng vốn chủ sở hữu tài khoản và lãi lỗ chưa thực hiện là gì?",
        tradeRules13: "Tổng vốn chủ sở hữu tài khoản = số dư tài khoản + lãi và lỗ chưa thực hiện\nLãi và lỗ chưa thực hiện là lãi và lỗ của vị thế hiện do người dùng nắm giữ trong hợp đồng tiền tệ. Lãi và lỗ chưa thực hiện sẽ thay đổi theo những thay đổi về giá giao dịch mới nhất. \nLãi và lỗ chưa thực hiện đối với các vị thế mua = (1/giá vị thế trung bình - 1/giá giao dịch mới nhất) * số lượng hợp đồng cho các vị thế mua * mệnh giá hợp đồng * giá giao dịch mới nhất \n lãi và lỗ chưa thực hiện đối với các vị thế bán = (1/ giá giao dịch mới nhất - 1/ Giá vị thế trung bình) * Số lượng hợp đồng bán * Mệnh giá hợp đồng * Giá giao dịch mới nhất",
        text1: "bảo mật tài khoản",
        text2: "chế độ ngày và đêm",
        text3: "xóa bộ nhớ đệm",
        text4: "Bạn có chắc chắn bạn muốn thoát?",
        text5: "Bạn có chắc chắn muốn xóa bộ nhớ đệm không?",
        text6: "đa ngôn ngữ",
        text7: "Để bảo vệ tính bảo mật của bạn, chúng tôi khuyên bạn nên bật ít nhất một xác thực hai yếu tố"
    },
    login: {
        login: "Đăng nhập",
        register: "đăng ký",
        title1: "Giao dịch mọi lúc, mọi nơi và xem điều kiện thị trường theo thời gian thực",
        title2: "Giao dịch mọi lúc, mọi nơi và xem điều kiện thị trường theo thời gian thực",
        title3: "Nền tảng giao dịch tiền điện tử đáng tin cậy nhất",
        formTitle1: "Đăng nhập tài khoản",
        formTitle2: "Chào mừng trở lại! Đăng nhập bằng email, số điện thoại của bạn",
        btn1: "điện thoại",
        btn2: "Thư",
        btn3: "Đăng nhập",
        btn4: "đăng ký",
        btn5: "quên mật khẩu?",
        field1: "Số điện thoại",
        field2: "mật khẩu",
        field3: "Thư",
        placeholder1: "Quốc gia",
        placeholder2: "Vui lòng nhập số điện thoại",
        placeholder3: "Xin vui lòng nhập mật khẩu",
        placeholder4: "vui lòng nhập email của bạn",
        content12: "Vui lòng nhập số điện thoại",
        content13: "Xin vui lòng nhập mật khẩu",
        content14: "vui lòng nhập email của bạn",
        content16: "Dịch vụ tài chính tài sản kỹ thuật số",
        loginSuccess: "đăng nhập thành công ",
        chooseArea: "Vui lòng chọn mã vùng",
        placeholder5: "Vui lòng chọn mã quốc gia",
        placeholder6: "nhớ mật khẩu của tôi",
        placeholder7: "Tải ứng dụng",
        placeholder8: "Liên hệ với dịch vụ khách hàng",
        placeholder9: "Chọn quốc gia hoặc khu vực",
        placeholder10: "mã vùng",
        placeholder11: "Đăng nhập ví",
        placeholder12: "Thao tác không thành công, vui lòng chuyển sang",
        placeholder13: "cái ví"
    },
    aboutUs: {
        aboutCoinbeadCenterTitle: "Về",
        aboutCoinbeadCenterDetails: "Nó có nền tảng giao dịch tài sản blockchain hàng đầu thế giới và vận hành toàn bộ hệ sinh thái.",
        footDetails1: "khối lượng giao dịch hàng ngày",
        footDetails2: "giao dịch/giây",
        footDetails3: "Trung tâm trợ giúp",
        coinbeadCenterTitle: "",
        coinbeadCenterDetails: "Đây là trang web quốc tế nổi tiếng thế giới về tài sản kỹ thuật số, chủ yếu cung cấp tiền xu và các dẫn xuất của tài sản kỹ thuật số như Bitcoin (BTC), Litecoin (LTC) và Ethereum (ETH) cho người dùng toàn cầu.",
        coinbeadCenterDetailsm: "Áp dụng các công nghệ tiên tiến như GSLB, máy chủ mạng, giao dịch mạng, mạng, công cụ giao dịch bộ nhớ tốc độ cao nhiều máy, ví lạnh, ví nóng ngoại tuyến thông minh, v.v., chúng tôi phục vụ khách hàng của mình bằng nhiều thiết bị đầu cuối như điện thoại di động Web và PC .Cung cấp dịch vụ giao dịch thuế quan kỹ thuật số an toàn, ổn định và đáng tin cậy. Đồng thời, chúng tôi cải tiến và nâng cấp các sản phẩm và dịch vụ dựa trên đề xuất và nhu cầu của người dùng để cung cấp dịch vụ tốt hơn cho mọi khách hàng và không ngừng cải thiện trải nghiệm người dùng đổi mới.",
        infrastructureCenterTitle: "cơ sở hạ tầng",
        infrastructureCenterDetails: "Sứ mệnh của chúng tôi là cung cấp dịch vụ cơ sở hạ tầng cho toàn bộ hệ sinh thái blockchain",
        freeCenterTitle: "miễn phí",
        freeCenterDetails: "Tầm nhìn của chúng tôi là làm cho tiền được lưu chuyển tự do trên khắp thế giới. Chúng tôi tin rằng việc lan tỏa sự tự do này có thể khiến thế giới trở thành một nơi tốt đẹp hơn",
        futureCenterTitle: "Tiên phong cho tương lai của công nghệ",
        futureCenterDetails: "Mở ra tương lai của công nghệ",
    },
    applicationForListing: {
        title: "Ứng dụng niêm yết coin",
        projectName: "Tên dự án",
        tokenName: "Tên mã thông báo",
        projectDescription: "mô tả dự án",
        contractAddress: "Địa chỉ hợp đồng",
        officialWebsite: "Trang web chính thức",
        ContactAndEmail: "Địa chỉ email liên lạc",
        ContactAndName: "Tên Liên lạc",
        file: "Tải Tệp đính kèm",
        pleaseEnter: "vui lòng nhập",
        enterFile: "Vui lòng tải tập tin lên",
        upload1: "Hiện tại bị giới hạn ở 1 lựa chọn tệp，",
        upload2: "Lần này tôi chọn",
        upload3: "các tập tin,",
        upload4: "Tổng số đã chọn",
        upload5: "các tập tin",
    },
    public: {
        submit: "nộp",
        copySuccessfully: "Đã sao chép thành công",
        copyFailed: "Sao chép không thành công",
        noDate: "Không có dữ liệu",
        submitSuccessfully: "gửi thành công",
        goBack: "quay lại trang cuối cùng",
        cancel: "Hủy bỏ",
        home: "trang đầu",
        loading: "đang tải...",
        confirm: "xác nhận",
        loadingFailed: "xác nhận",
        nomore: "không còn nữa",
        tryAgain: "Vui lòng làm mới trang/thoát khỏi chương trình và tải lên lại!",
        hot: "Khu vực phổ biến",
    },
    inviteFriends: {
        inviteFriends: "Chia sẻ với bạn bè",
        freedomOfWealth: "Hãy để sự giàu có được tự do cùng nhau",
        myInvitationCode: "mã mời của tôi",
        clickCopy: "Bấm để sao chép",
        myInvitationLink: "Liên kết lời mời của tôi",
        clicktoSaveTheQRCode: "Bấm để lưu mã QR",
        saveQRCode: "Mời mã QR",
        numberOfSubordinates: "Số lượng các cấp dưới",
        rebateIncome: "Thu nhập chiết khấu",
        people: "mọi người",
        myPromotionRevenue: "Thu nhập khuyến mãi của tôi",
        numberOfDirectSubordinates: "Số lượng cấp dưới trực tiếp",
        totalNumberOfSubordinates: "Tổng số cấp dưới",
        directSubordinateReturnsCommission: "Chiết khấu hoa hồng cho cấp dưới trực tiếp",
        theTotalCommission: "tổng giảm giá",
        checkHistory: "Xem hồ sơ giảm giá",
        history: "Hồ sơ hoa hồng",
        theLowerAccount: "Tài khoản cấp dưới",
        type: "kiểu:",
        serviceCharge: "Phí xử lý:",
        percentageofCommissionReturned: "Tỷ lệ chiết khấu:",
        commissionrebateAmount: "Số tiền giảm giá:",
        time: "thời gian:",
        typeName1: "Nạp tiền và hoa hồng",
        typeName2: "Phân phối phí xử lý đơn hàng MT ba cấp",
        typeName3: "MT phân phối ba cấp có lợi nhuận",
        typeName4: "Phần thưởng giới thiệu",
        typeName30: "Nạp tiền và hoa hồng",
        typeName31: "Chiết khấu lợi nhuận giao dịch định kỳ",
        typeName32: "Chiết khấu lợi nhuận khai thác thanh khoản",
        typeName45: "Giảm phí giao dịch",
        typeName46: "Giảm phí hoa hồng cho phí xử lý riêng",
        typeName50: "Giảm phí xử lý Giao hàng",
        typeName51: "Giảm phí xử lý hợp đồng vĩnh viễn",
        typeName52: "Giảm phí hoa hồng bổ sung cho phí xử lý Giao hàng",
        typeName53: "Giảm phí hoa hồng bổ sung cho phí xử lý hợp đồng vĩnh viễn",
        typeName54: "Giảm giá bổ sung cho phí xử lý hợp đồng vĩnh viễn",
        shareRules1: "Hướng dẫn mời",
        shareRules2: "Nhập trung tâm lời mời, sao chép liên kết hoặc mã lời mời và chia sẻ nó với bạn bè của bạn. Bạn bè có thể trở thành cấp dưới của bạn bằng cách đăng ký bằng mã mời của bạn.",
        shareRules3: "Kiếm tiền giảm giá",
        shareRules4: "Khi cấp dưới thực hiện giao dịch, bạn có thể nhận được hoa hồng tương ứng, hỗ trợ tối đa ba cấp độ cấp dưới. Ví dụ: nếu bạn mời bạn A, A mời B và B mời C thì A, B và C sẽ thực hiện các hợp đồng và các giao dịch khác trên nền tảng và tất cả các bạn đều có thể nhận được hoa hồng tương ứng.",
        shareRules5: "Cấp độ đội",
        shareRules6: "Bạn thăng chức càng nhiều cấp dưới cấp một thì cấp độ nhóm càng cao và mức giảm giá bạn có thể được hưởng càng cao. Cấp độ đội được chia thành LV1-LV6, quy tắc nâng cấp được thể hiện trong bảng bên dưới, trong đó 'N' là số lượng cấp dưới cấp một đã nạp tiền và hoàn thành xác thực tên thật.",
        shareRules7: "Khi cấp dưới đặt cược vào Giao hàng, bạn có thể nhận được hoa hồng tương ứng với số tiền đặt cược của họ.",
        upgradeConditions: "Yêu cầu",
        recommendedNumberOfPeople: "Số người được đề xuất"
    },
    suggestionsAndFeedback: {
        suggestionsAndFeedback: "Đề xuất và phản hồi",
        text: "Nếu bạn gặp lỗi hoặc có góp ý về sản phẩm trong quá trình sử dụng, vui lòng phản hồi cho chúng tôi.",
        email: "địa chỉ email",
        enterEmail: "Vui lòng nhập địa chỉ email",
        enterCorrectEmail: "Vui lòng nhập đúng địa chỉ email",
        title: "tiêu đề",
        enterTitle: "Vui lòng nhập tiêu đề câu hỏi",
        describeTheProblem: "Mô tả vấn đề",
        enterDescribeTheProblem: "Vui lòng nhập vấn đề bạn mô tả",
        submit: "nộp",
    },
    help: {
        enterSearch: "tìm kiếm",
        title: "Trung tâm trợ giúp",
        search: "Vui lòng nhập câu hỏi của bạn",
        text1: "Hiểu nhu cầu của bạn và tiếp tục cung cấp cho bạn các dịch vụ thuận tiện hơn"
    },
    announcement: {
        enterSearch: "tìm kiếm",
        title: "Trung tâm thông báo",
        search: "Vui lòng nhập câu hỏi của bạn"
    },
    privacy: {
        title: "Cam kết bảo mật",
        content1: "1. Thông tin cá nhân nào về người dùng được thu thập?",
        content2: "Thông tin cá nhân được thu thập để cung cấp sản phẩm và dịch vụ và liên tục cải thiện chúng.",
        content3: "Sau đây là các loại thông tin cá nhân chúng tôi thu thập:",
        content4: 'Thông tin bạn cung cấp cho chúng tôi: Chúng tôi nhận và lưu trữ bất kỳ thông tin nào bạn cung cấp liên quan đến Nền tảng. Bạn có thể chọn không cung cấp một số thông tin nhất định, nhưng kết quả là bạn không thể sử dụng một số "dịch vụ nền tảng này". Ví dụ về thông tin bạn cung cấp cho chúng tôi bao gồm:',
        content5: "Tên",
        content6: "Địa chỉ",
        content7: "Quốc tịch",
        content8: "ảnh giấy tờ tùy thân",
        content9: "Số điện thoại di động/số email",
        content10: "Thông tin khác giúp chúng tôi nhận dạng bạn",
        content11: "Thông tin được thu thập tự động: Chúng tôi tự động thu thập và lưu trữ một số loại thông tin nhất định về việc bạn sử dụng Dịch vụ nền tảng. Giống như nhiều trang web, chúng tôi sử dụng cookie và các thông tin nhận dạng duy nhất khác. Chúng tôi thu được một số loại thông tin nhất định khi trình duyệt web hoặc thiết bị của bạn truy cập Nền tảng. Ví dụ về thông tin chúng tôi thu thập và phân tích bao gồm:",
        content12: "Địa chỉ Giao thức Internet (IP) kết nối máy tính cá nhân với Internet;",
        content13: "Thông tin đăng nhập, địa chỉ email, mật khẩu và vị trí của thiết bị cá nhân hoặc máy tính;",
        content14: "Cài đặt phiên bản và múi giờ;",
        content15: "Lịch sử giao dịch.",
        content16: "Thông tin từ các nguồn khác: Chúng tôi có thể thu thập thông tin về bạn từ các nguồn khác, chẳng hạn như lịch sử tín dụng từ các văn phòng tín dụng. Chúng tôi sẽ sử dụng thông tin này để ngăn chặn và phát hiện gian lận.",
        content17: "2. Trẻ em có thể sử dụng nó được không?",
        content18: 'Nền tảng này không cho phép bất kỳ cá nhân nào dưới 18 tuổi sử dụng "Dịch vụ".',
        content19: "3. Cookie và các thông tin nhận dạng khác được dùng để làm gì?",
        content20: "Chúng tôi sử dụng cookie và các công cụ tương tự để tối ưu hóa trải nghiệm người dùng, cung cấp dịch vụ của mình và hiểu cách khách hàng sử dụng dịch vụ của chúng tôi để chúng tôi có thể thực hiện các cải tiến có mục tiêu. Chúng tôi sử dụng cookie để đảm bảo rằng hệ thống của chúng tôi có thể nhận ra trình duyệt hoặc thiết bị của bạn và cung cấp cho bạn các dịch vụ.",
        content21: 'Chúng tôi sử dụng cookie hoạt động và các công cụ tương tự (gọi chung là "Cookie") để cung cấp các dịch vụ, chẳng hạn như:',
        content22: "Để nhận dạng bạn khi bạn đăng nhập và sử dụng dịch vụ của chúng tôi.",
        content23: "Cung cấp các tính năng và dịch vụ tùy chỉnh.",
        content24: "Bảo vệ chống lại hoạt động gian lận.",
        content25: "Cải thiện an ninh.",
        content26: "Theo dõi tùy chọn của bạn (chẳng hạn như tiền tệ và ngôn ngữ).",
        content27: "Chúng tôi cũng sử dụng cookie để hiểu cách người dùng sử dụng dịch vụ của chúng tôi để có thể thực hiện các cải tiến có mục tiêu.",
        content28: "4. Thông tin cá nhân của tôi có được chia sẻ không?",
        content29: "Thông tin người dùng là một phần quan trọng trong hoạt động kinh doanh của chúng tôi và chúng tôi sẽ không bán thông tin cá nhân của người dùng cho người khác. Nền tảng này sẽ chỉ chia sẻ thông tin cá nhân của người dùng với các công ty con hoặc chi nhánh trong các trường hợp sau. Các công ty con hoặc chi nhánh này phải tuân thủ tuyên bố về quyền riêng tư này hoặc ít nhất tuân theo các biện pháp bảo vệ như các biện pháp bảo vệ được mô tả trong tuyên bố về quyền riêng tư này.",
        content30: "Nhà cung cấp dịch vụ bên thứ ba: Chúng tôi thuê các công ty và cá nhân khác để thay mặt chúng tôi thực hiện các chức năng. Ví dụ về các chức năng như vậy bao gồm: phân tích dữ liệu, cung cấp hỗ trợ tiếp thị, xử lý thanh toán, truyền nội dung cũng như đánh giá và quản lý rủi ro tín dụng. Các nhà cung cấp dịch vụ bên thứ ba này có quyền truy cập vào thông tin cá nhân cần thiết để thực hiện các chức năng của họ nhưng không được sử dụng thông tin đó cho bất kỳ mục đích nào khác. Ngoài ra, họ phải xử lý thông tin cá nhân tuân thủ tuyên bố về quyền riêng tư này và luật bảo vệ dữ liệu hiện hành.",
        content31: "Chuyển giao doanh nghiệp: Khi hoạt động kinh doanh của chúng tôi tiếp tục phát triển, chúng tôi có thể bán hoặc mua các hoạt động kinh doanh hoặc dịch vụ khác. Trong các giao dịch như vậy, thông tin người dùng thường nằm trong số tài sản kinh doanh được chuyển giao nhưng vẫn phải tuân theo những lời hứa trong bất kỳ tuyên bố về quyền riêng tư nào có sẵn (tất nhiên trừ khi người dùng đồng ý khác). Ngoài ra, nếu công ty của chúng tôi hoặc về cơ bản toàn bộ tài sản của công ty bị mua lại, thông tin người dùng cũng sẽ được chuyển giao. Bảo vệ Công ty của chúng tôi và những người khác: Khi chúng tôi tin rằng việc tiết lộ tài khoản và thông tin cá nhân khác là phù hợp để tuân thủ luật pháp hoặc nghĩa vụ pháp lý của chúng tôi, hãy thực thi hoặc áp dụng Điều khoản sử dụng và các thỏa thuận khác hoặc quyền, tài sản hoặc sự an toàn của người dùng của chúng tôi hoặc những người khác Khi nào, chúng tôi sẽ tiết lộ tài khoản và thông tin cá nhân khác. Điều này bao gồm việc trao đổi thông tin với các công ty và tổ chức khác để bảo vệ chống gian lận và giảm rủi ro tín dụng.",
        content32: "5. Chuyển dữ liệu cá nhân quốc tế",
        content33: 'Chúng tôi có thể chuyển dữ liệu của bạn ra ngoài Khu vực Kinh tế Châu Âu ("EEA"). Chúng tôi sẽ áp dụng các biện pháp bảo vệ thích hợp để đảm bảo rằng việc chuyển dữ liệu đó tuân thủ các quy tắc bảo vệ dữ liệu hiện hành, trừ khi Ủy ban Châu Âu xác nhận rằng quốc gia nơi dữ liệu được chuyển đến cung cấp mức độ bảo vệ thích hợp.',
        content34: "6. Thông tin cá nhân của tôi có an toàn không?",
        content35: "Chúng tôi đã thiết kế hệ thống của mình chú trọng đến vấn đề bảo mật và quyền riêng tư của bạn. Chúng tôi sử dụng các giao thức và phần mềm mã hóa để cố gắng bảo vệ tính bảo mật của thông tin cá nhân trong quá trình truyền tải.",
        content36: "Chúng tôi luôn áp dụng các biện pháp bảo vệ vật lý, điện tử và thủ tục khi thu thập, lưu trữ và tiết lộ thông tin cá nhân. Quy trình bảo mật của chúng tôi có nghĩa là chúng tôi có thể cần xác minh danh tính của bạn trước khi tiết lộ thông tin cá nhân cho bạn.",
        content37: "Điều quan trọng nhất bạn có thể làm là bảo vệ mật khẩu tài khoản cá nhân của mình khỏi bị truy cập trái phép. Chúng tôi khuyên bạn nên đặt một mật khẩu duy nhất cho tài khoản của mình, khác biệt với các tài khoản trực tuyến khác. Và hãy nhớ đăng xuất sau khi sử dụng máy tính dùng chung.",
        content38: "7. Tôi nên bảo vệ thông tin cá nhân như thế nào?",
        content39: "Nếu bạn có bất kỳ câu hỏi hoặc phản đối nào về cách chúng tôi thu thập và xử lý thông tin cá nhân, vui lòng liên hệ với nhân viên dịch vụ khách hàng",
        content40: "Sau khi bạn đồng ý cho chúng tôi xử lý thông tin cá nhân của bạn cho một mục đích cụ thể, bạn có thể rút lại ý định của mình bất kỳ lúc nào và chúng tôi sẽ ngừng xử lý dữ liệu của bạn cho mục đích đó.",
        content41: "Ngoài ra, bạn có quyền yêu cầu quyền truy cập, chỉnh sửa và xóa dữ liệu cá nhân cũng như yêu cầu di chuyển dữ liệu, tuân theo luật hiện hành. Bạn cũng có thể phản đối việc chúng tôi xử lý dữ liệu cá nhân của bạn hoặc yêu cầu hạn chế việc xử lý dữ liệu cá nhân của chúng tôi trong một số trường hợp nhất định.",
        content42: "8. GDPR của EU và Luật bảo vệ dữ liệu của Vương quốc Anh - Cơ sở pháp lý",
        content43: "GDPR của EU và luật bảo vệ dữ liệu của Vương quốc Anh yêu cầu chúng tôi tuân thủ cơ sở pháp lý khi sử dụng thông tin cá nhân. Cơ sở của chúng tôi phụ thuộc vào mục đích cụ thể mà thông tin cá nhân được sử dụng. Những căn cứ này bao gồm:",
        content44: "Thực hiện hợp đồng - để cung cấp hoặc liên lạc với bạn về các sản phẩm hoặc dịch vụ, bao gồm cả việc chúng tôi sử dụng thông tin cá nhân của bạn để chấp nhận và xử lý đơn đặt hàng cũng như xử lý thanh toán.",
        content45: "Lợi ích kinh doanh hợp pháp và lợi ích người dùng của chúng tôi - Chúng tôi phát hiện và ngăn chặn gian lận và lạm dụng để bảo vệ sự an toàn của người dùng, chính chúng tôi hoặc những người khác.",
        content46: "Sự đồng ý của bạn – ​​Chúng tôi cần có sự đồng ý của bạn để xử lý thông tin cá nhân của bạn cho các mục đích cụ thể được thông báo cho bạn. Khi bạn đã đồng ý cho chúng tôi xử lý thông tin cá nhân của bạn cho một mục đích cụ thể, bạn có thể rút lại sự đồng ý của mình bất cứ lúc nào và chúng tôi sẽ ngừng xử lý dữ liệu của bạn cho mục đích đó.",
        content47: "Tuân thủ nghĩa vụ pháp lý - Chúng tôi sử dụng thông tin cá nhân của bạn theo yêu cầu của pháp luật. Ví dụ: chúng tôi thu thập thông tin tài khoản ngân hàng cho mục đích xác minh danh tính.",
        content48: "Các cơ sở pháp lý trên và các cơ sở pháp lý khác phụ thuộc vào mục đích cụ thể mà chúng tôi sử dụng thông tin cá nhân.",
        content49: "9. Điều kiện sử dụng, thông báo và sửa đổi",
        content50: "Việc bạn sử dụng và mọi tranh chấp về quyền riêng tư đều phải tuân theo Tuyên bố này và Điều khoản sử dụng của chúng tôi. Nếu bạn có bất kỳ lo ngại nào về quyền riêng tư của nền tảng này, vui lòng liên hệ với chúng tôi kèm theo lời giải thích chi tiết và chúng tôi sẽ sẵn lòng giải quyết vấn đề cho bạn. Bạn cũng có quyền liên hệ với cơ quan bảo vệ dữ liệu địa phương của bạn.",
        content51: "Hoạt động kinh doanh của chúng tôi liên tục thay đổi và tuyên bố về quyền riêng tư của chúng tôi cũng vậy. Bạn nên kiểm tra trang web của chúng tôi thường xuyên để xem những thay đổi mới nhất. Nếu bạn không đồng ý với nội dung sửa đổi, bạn nên ngừng truy cập ngay lập tức. Sau khi phiên bản cập nhật của Chính sách quyền riêng tư được phát hành, việc bạn tiếp tục truy cập có nghĩa là bạn đồng ý với nội dung cập nhật và đồng ý tuân theo Chính sách quyền riêng tư đã cập nhật. Trừ khi có quy định khác, Tuyên bố về quyền riêng tư hiện tại áp dụng cho tất cả thông tin chúng tôi lưu giữ về bạn và tài khoản của bạn.",
    },
    serviceAgreement: {
        title: "Thỏa thuận dịch vụ",
        content1: 'Trang web này là một nền tảng dành riêng cho người dùng thực hiện các giao dịch tài sản kỹ thuật số và cung cấp các dịch vụ liên quan (sau đây gọi là “dịch vụ” hoặc “dịch vụ”). Để thuận tiện cho việc mô tả thỏa thuận này, trang web này sử dụng chung "chúng tôi" hoặc các đại từ nhân xưng ngôi thứ nhất khác trong thỏa thuận này. Miễn là cá nhân hoặc chủ thể khác đăng nhập vào trang web là người dùng của trang web này, để thuận tiện cho việc thể hiện thỏa thuận này, "bạn" hoặc người thứ hai khác sẽ được sử dụng bên dưới. Để thuận tiện cho việc thể hiện Thỏa thuận này, chúng tôi và bạn được gọi chung là "Các bên" trong Thỏa thuận này và chúng tôi hoặc bạn được gọi riêng là "Bên".',
        content2: "gợi ý quan trọng:",
        content3: "Chúng tôi đặc biệt nhắc nhở bạn:",
        content4: "1 Bản thân tài sản kỹ thuật số không được phát hành bởi bất kỳ tổ chức tài chính, công ty hoặc trang web này",
        content5: "2 Thị trường tài sản kỹ thuật số còn mới, chưa được xác nhận và có thể không phát triển;",
        content6: "3 Tài sản kỹ thuật số chủ yếu được sử dụng rộng rãi bởi các nhà đầu cơ, tương đối ít được sử dụng trong thị trường bán lẻ và thương mại. Giao dịch tài sản kỹ thuật số cực kỳ rủi ro, được giao dịch liên tục trong ngày, không có giới hạn tăng giảm và giá cả dễ bị ảnh hưởng bởi thị trường. các nhà sản xuất và chính sách của chính phủ toàn cầu.",
        content7: "4  Công ty có quyền đình chỉ hoặc chấm dứt tài khoản của bạn bất cứ lúc nào nếu Công ty xác định, theo quyết định riêng của mình, rằng bạn đã vi phạm Thỏa thuận này hoặc các dịch vụ do trang web này cung cấp hoặc việc bạn sử dụng các dịch vụ do trang web này cung cấp là bất hợp pháp theo luật pháp tại khu vực pháp lý của bạn. , hoặc đình chỉ hoặc chấm dứt việc bạn sử dụng các dịch vụ hoặc giao dịch tài sản kỹ thuật số do trang web này cung cấp. Bất kỳ ai từ [Trung Quốc đại lục, Đài Loan, Israel, Iraq, Bangladesh, Bolivia, Ecuador, Kyrgyzstan, Sevastopol và Vương quốc Anh (người dùng bán lẻ)] đều bị cấm sử dụng các dịch vụ giao dịch hợp đồng do trang web này cung cấp. Danh sách quốc gia hoặc khu vực nói trên sẽ thay đổi tùy theo chính sách và loại sản phẩm của các quốc gia hoặc khu vực khác nhau, chúng tôi có thể không thông báo cụ thể cho bạn vào thời điểm đó, vì vậy vui lòng chú ý cập nhật thỏa thuận này một cách kịp thời.",
        content8: "Giao dịch tài sản kỹ thuật số tiềm ẩn rủi ro cực kỳ cao và không phù hợp với hầu hết mọi người. Bạn hiểu và hiểu rằng giao dịch này có thể dẫn đến thua lỗ một phần hoặc toàn bộ, vì vậy bạn nên xác định số tiền của giao dịch dựa trên mức độ thua lỗ mà bạn có thể chấp nhận được. Bạn biết và hiểu rằng tài sản kỹ thuật số sẽ tạo ra rủi ro phái sinh, vì vậy nếu có bất kỳ câu hỏi nào, trước tiên bạn nên tìm kiếm sự hỗ trợ của các cố vấn chuyên nghiệp. Ngoài ra, ngoài những rủi ro nêu trên còn có những rủi ro không thể lường trước được. Bạn nên cân nhắc cẩn thận và đưa ra phán đoán rõ ràng để đánh giá tình hình tài chính của mình cũng như các rủi ro nêu trên trước khi đưa ra bất kỳ quyết định mua hoặc bán tài sản kỹ thuật số nào và chịu mọi tổn thất phát sinh. Chúng tôi không chịu bất kỳ trách nhiệm nào về việc này.",
        content9: "Xin lưu ý:",
        content10: "1 Bạn hiểu rằng trang web này chỉ được sử dụng làm nơi để bạn lấy thông tin tài sản kỹ thuật số, tìm các bên giao dịch, đàm phán và thực hiện các giao dịch về tài sản kỹ thuật số. Trang web này không tham gia vào bất kỳ giao dịch nào của bạn, vì vậy bạn nên sử dụng phán đoán thận trọng của riêng mình để xác định các tài sản và/hoặc giao dịch kỹ thuật số có liên quan hoặc tính xác thực, hợp pháp và hợp lệ của thông tin và sẽ tự chịu trách nhiệm cũng như tổn thất phát sinh.",
        content11: "2 Bất kỳ ý kiến, tin tức, thảo luận, phân tích, giá cả, khuyến nghị và thông tin nào khác trên trang web này đều là nhận xét chung về thị trường và không phải là lời khuyên đầu tư. Chúng tôi không chịu trách nhiệm về bất kỳ tổn thất nào phát sinh trực tiếp hoặc gián tiếp do dựa vào thông tin này, bao gồm nhưng không giới hạn ở bất kỳ tổn thất lợi nhuận nào.",
        content12: "3 Trang web này có quyền tự đưa ra quyết định, sửa đổi hoặc thay đổi nội dung bất cứ lúc nào. Chúng tôi đã thực hiện các biện pháp hợp lý để đảm bảo tính chính xác của thông tin trên trang web, nhưng chúng tôi không thể đảm bảo tính chính xác của nó và chúng tôi sẽ không chịu trách nhiệm về bất kỳ tổn thất hoặc thiệt hại nào do thông tin trên trang web này gây ra hoặc do thiếu thông tin.Tổn thất trực tiếp hoặc gián tiếp phát sinh từ sự chậm trễ hoặc thất bại trong việc kết nối Internet, truyền hoặc nhận bất kỳ thông báo và thông tin nào.",
        content13: "4 Cũng có những rủi ro khi sử dụng hệ thống giao dịch dựa trên Internet, bao gồm nhưng không giới hạn ở lỗi phần mềm, phần cứng và kết nối Internet. Vì chúng tôi không kiểm soát được độ tin cậy và tính khả dụng của Internet nên chúng tôi không chịu trách nhiệm về sự biến dạng, độ trễ và lỗi kết nối.",
        content14: "5 Nghiêm cấm sử dụng trang web này để tham gia vào tất cả các hoạt động giao dịch bất hợp pháp hoặc các hoạt động bất hợp pháp như rửa tiền, buôn lậu, hối lộ thương mại, v.v. Nếu phát hiện bất kỳ giao dịch hoặc hoạt động bất hợp pháp nào bị nghi ngờ, trang web này sẽ sử dụng nhiều phương tiện sẵn có, bao gồm nhưng không giới hạn việc đóng băng tài khoản và thông báo cho các bên liên quan.. Cơ quan chức năng, v.v., chúng tôi không chịu mọi trách nhiệm phát sinh từ đó và có quyền truy đòi trách nhiệm pháp lý từ những người có liên quan.",
        content15: "6 Nghiêm cấm sử dụng website này để thao túng thị trường với mục đích ác ý, giao dịch không đúng quy định và các hoạt động giao dịch trái đạo đức khác, nếu phát hiện những sự việc đó, website này sẽ cảnh báo, hạn chế giao dịch và đóng mọi hành vi phi đạo đức như thao túng giá ác ý, gây ảnh hưởng xấu đến thị trường. Chúng tôi không chịu mọi trách nhiệm pháp lý phát sinh từ các biện pháp bảo vệ phòng ngừa như đình chỉ tài khoản và có quyền truy đòi trách nhiệm pháp lý từ những người có liên quan.",
        content16: "1. Nguyên tắc chung",
        content17: '1.1 "Thỏa thuận người dùng" (sau đây gọi là "Thỏa thuận này" hoặc "Các điều khoản và điều kiện này"), bao gồm nội dung chính, "Điều khoản về quyền riêng tư", "Chính sách nhận biết khách hàng và chống rửa tiền" và nhiều chính sách khác có đã được xuất bản hoặc có thể được xuất bản trong tương lai trên trang web này. Nội quy lớp học, tuyên bố, mô tả, v.v.',
        content18: "1.2 Bạn nên đọc kỹ thỏa thuận này trước khi sử dụng các dịch vụ do trang web này cung cấp, nếu bạn không hiểu điều gì hoặc nếu cần thiết, vui lòng tham khảo ý kiến ​​​​luật sư chuyên nghiệp. Nếu bạn không đồng ý với Thỏa thuận này và/hoặc sửa đổi của nó bất cứ lúc nào, vui lòng ngừng sử dụng ngay các dịch vụ do trang web này cung cấp hoặc không đăng nhập vào trang web này nữa. Khi bạn đăng nhập vào trang web này, sử dụng bất kỳ dịch vụ nào của trang web này hoặc bất kỳ hành vi tương tự nào khác, điều đó có nghĩa là bạn đã hiểu và hoàn toàn đồng ý với nội dung của Thỏa thuận này, bao gồm mọi sửa đổi mà trang web này thực hiện đối với Thỏa thuận này vào bất kỳ lúc nào.",
        content19: '1.3 Bạn có thể trở thành thành viên của trang web này (sau đây gọi là "thành viên") bằng cách điền các thông tin liên quan theo yêu cầu của trang web này và đăng ký thành công sau các thủ tục liên quan khác. Bằng cách nhấp vào nút "Đồng ý" trong quá trình đăng ký hoặc khi bạn nhấp vào bất kỳ nút nào được đánh dấu "Đồng ý" hoặc có ý nghĩa tương tự trong quá trình sử dụng trang web này hoặc thực sự sử dụng các dịch vụ do trang web này cung cấp theo những cách khác được trang web này cho phép , điều đó có nghĩa là bạn hoàn toàn hiểu, đồng ý và chấp nhận tất cả các điều khoản trong Thỏa thuận này. Việc không có chữ ký viết tay của bạn sẽ không ảnh hưởng đến hiệu lực ràng buộc pháp lý của Thỏa thuận này đối với bạn.',
        content20: "1.4 Sau khi trở thành thành viên của trang web này, bạn sẽ nhận được tài khoản thành viên và mật khẩu tương ứng do bạn lưu giữ, bạn phải chịu trách nhiệm trước pháp luật về mọi hoạt động, sự kiện được thực hiện bằng tài khoản của mình.",
        content21: "1.5 Chỉ những thành viên trở thành thành viên của trang web này mới có thể sử dụng nền tảng giao dịch tài sản kỹ thuật số do trang web này cung cấp để giao dịch và tận hưởng các dịch vụ khác do trang web này quy định chỉ dành cho thành viên; những người không phải là thành viên chỉ có thể đăng nhập vào trang web, duyệt trang web và các dịch vụ khác do trang web này quy định.",
        content22: "1.6 Bằng cách đăng ký và sử dụng bất kỳ dịch vụ và chức năng nào do trang web này cung cấp, bạn sẽ được coi là đã đọc, hiểu và:",
        content23: "1.6.1 Chấp nhận bị ràng buộc bởi tất cả các điều khoản và điều kiện của Thỏa thuận này.",
        content24: "1.6.2 Bạn xác nhận rằng bạn trên 18 tuổi hoặc đủ độ tuổi hợp pháp để ký kết hợp đồng theo các luật hiện hành khác nhau. Việc đăng ký của bạn trên trang web này, việc mua bán, xuất bản thông tin, v.v. và việc chấp nhận các dịch vụ của trang web này phải tuân thủ với yêu cầu của bạn. Có thẩm quyền đối với các luật và quy định liên quan của một quốc gia hoặc khu vực có chủ quyền và có toàn quyền chấp nhận các điều khoản này, tham gia giao dịch và sử dụng trang web này để thực hiện các giao dịch tài sản kỹ thuật số.",
        content25: "1.6.3 Bạn đảm bảo rằng tất cả tài sản kỹ thuật số thuộc về bạn tham gia vào giao dịch đều có nguồn gốc hợp pháp và có quyền sở hữu.",
        content26: "1.6.4 Bạn đồng ý rằng bạn hoàn toàn chịu trách nhiệm về các hoạt động giao dịch hoặc phi giao dịch của riêng mình và về bất kỳ khoản lãi hoặc lỗ nào.",
        content27: "1.6.5 Bạn xác nhận rằng thông tin bạn cung cấp khi đăng ký là đúng và chính xác.",
        content28: "1.6.6 Bạn đồng ý tuân thủ các yêu cầu của bất kỳ luật liên quan nào, bao gồm cả việc báo cáo mọi lợi nhuận giao dịch vì mục đích thuế.",
        content29: "1.6.7 Bạn đồng ý không tham gia vào các hành vi hoặc hoạt động gây tổn hại đến lợi ích của trang web này hoặc công ty vào bất kỳ lúc nào, dù có liên quan đến các dịch vụ do trang web này cung cấp hay không.",
        content30: "1.6.8 Thỏa thuận này chỉ điều chỉnh các quyền và nghĩa vụ giữa bạn và chúng tôi và không liên quan đến các mối quan hệ pháp lý và tranh chấp pháp lý phát sinh từ giao dịch tài sản kỹ thuật số giữa người dùng trang web này với các trang web khác và bạn.",
        content31: "2. Sửa đổi thỏa thuận",
        content32: "Chúng tôi có quyền sửa đổi Thỏa thuận này tùy từng thời điểm và thông báo trên trang web mà không cần thông báo riêng cho bạn. Sau khi sửa đổi, thời gian sửa đổi sẽ được đánh dấu trên [Thời gian cập nhật lần cuối] hiển thị trong Thỏa thuận này và nó sẽ tự động được thực hiện. có hiệu lực sau khi nó được xuất bản trên trang web. Thỉnh thoảng bạn nên duyệt qua và chú ý đến thời gian cập nhật cũng như nội dung cập nhật của Thỏa thuận này. Nếu bạn không đồng ý với những thay đổi có liên quan, bạn nên ngừng ngay việc sử dụng các dịch vụ của trang web này và tiếp tục sử dụng các dịch vụ của trang web này. trang web có nghĩa là bạn chấp nhận và đồng ý bị ràng buộc bởi thỏa thuận sửa đổi. .",
        content33: "3. Đăng ký",
        content34: "3.1 Trình độ đăng ký",
        content35: "Bạn xác nhận và hứa rằng khi bạn hoàn tất quá trình đăng ký hoặc thực sự sử dụng các dịch vụ do trang web này cung cấp theo những cách khác được trang web này cho phép, bạn sẽ có khả năng ký thỏa thuận này và sử dụng các dịch vụ của trang web này theo quy định của pháp luật hiện hành. thể nhân, pháp nhân hoặc tổ chức khác. Khi bạn nhấp vào nút Đồng ý đăng ký, điều đó có nghĩa là bạn hoặc đại lý được ủy quyền của bạn đã đồng ý với nội dung của thỏa thuận và đã được đại lý của mình đăng ký và sử dụng các dịch vụ của trang web này. Nếu bạn không có đủ các tiêu chuẩn chuyên môn nói trên, bạn và người đại diện được ủy quyền của bạn sẽ phải chịu mọi hậu quả phát sinh từ đó và công ty có quyền hủy hoặc đóng băng vĩnh viễn tài khoản của bạn, đồng thời buộc bạn và người đại diện được ủy quyền của bạn phải chịu trách nhiệm. .",
        content36: "3.2 Mục đích đăng ký",
        content37: "Bạn xác nhận và cam kết rằng việc đăng ký của bạn trên trang web này không nhằm mục đích vi phạm luật pháp và quy định hoặc làm gián đoạn trật tự giao dịch tài sản kỹ thuật số trên trang web này.",
        content38: "3.3quá trình đăng ký",
        content39: "3.3.1 Bạn đồng ý cung cấp địa chỉ email, số điện thoại di động hợp lệ và các thông tin khác theo yêu cầu trên trang đăng ký người dùng của trang web này. Bạn có thể sử dụng địa chỉ email, số điện thoại di động bạn đã cung cấp hoặc xác nhận hoặc các phương thức khác được trang web này cho phép làm thông tin đăng nhập phương pháp để vào trang web này. Nếu cần, theo luật và quy định liên quan của các khu vực pháp lý khác nhau, bạn phải cung cấp tên thật, giấy tờ tùy thân và các thông tin liên quan khác được quy định trong luật và quy định, điều khoản về quyền riêng tư và điều khoản chống rửa tiền, đồng thời liên tục cập nhật thông tin đăng ký trong một cách kịp thời, chi tiết và chính xác. Tất cả thông tin gõ ban đầu sẽ được tham chiếu như thông tin đăng ký. Bạn chịu trách nhiệm về tính xác thực, đầy đủ và chính xác của thông tin đó và phải chịu mọi tổn thất trực tiếp hoặc gián tiếp cũng như hậu quả bất lợi phát sinh từ đó.",
        content40: "3.3.2 Nếu luật pháp, quy định, quy tắc, mệnh lệnh và các quy định khác của quốc gia hoặc khu vực có chủ quyền nơi bạn sinh sống có yêu cầu về tên thật đối với số điện thoại di động, thì bạn đồng ý rằng số điện thoại di động đã đăng ký được cung cấp đã được đăng ký bằng tên thật. bạn không cung cấp theo đúng quy định, mọi hậu quả gây ra cho bạn sẽ là tổn thất Trực tiếp hoặc gián tiếp và hậu quả bất lợi sẽ do bạn gánh chịu.",
        content41: "3.3.3 Nếu bạn cung cấp thông tin cần thiết để đăng ký một cách hợp pháp, đầy đủ và hiệu quả và đã xác minh thông tin đó, bạn có quyền lấy số tài khoản và mật khẩu của trang web này. Khi bạn có được số tài khoản và mật khẩu của trang web này, việc đăng ký của bạn được coi là thành công và bạn có thể đăng nhập với tư cách thành viên trên trang web này.",
        content42: "3.3.4 Bạn đồng ý nhận email và/hoặc tin nhắn ngắn do trang web này gửi liên quan đến việc quản lý và vận hành trang web này.",
        content43: "4. Dịch vụ",
        content44: "Trang web này chỉ cung cấp dịch vụ nền tảng giao dịch trực tuyến cho các hoạt động giao dịch tài sản kỹ thuật số của bạn (bao gồm nhưng không giới hạn ở giao dịch tài sản kỹ thuật số và các dịch vụ khác) thông qua trang web này.",
        content45: "4.1 Nội dung dịch vụ",
        content46: "4.1.1 Bạn có quyền duyệt các điều kiện thị trường theo thời gian thực và thông tin giao dịch của các sản phẩm tài sản kỹ thuật số khác nhau trên trang web này và bạn có quyền gửi hướng dẫn giao dịch tài sản kỹ thuật số và hoàn tất giao dịch tài sản kỹ thuật số thông qua trang web này.",
        content47: "4.1.2 Bạn có quyền xem thông tin trong tài khoản thành viên của mình trên trang web này và sử dụng các chức năng do trang web này cung cấp để hoạt động.",
        content48: "4.1.3 Bạn có quyền tham gia vào các hoạt động của trang web do trang web này tổ chức theo các quy định hoạt động được công bố trên trang web này.",
        content49: "4.1.4 Trang web này hứa hẹn sẽ cung cấp cho bạn các dịch vụ khác.",
        content50: "4.2 Quy tắc dịch vụ Bạn cam kết tuân thủ các quy tắc dịch vụ sau của trang web này:",
        content51: "4.2.1 Bạn phải tuân thủ luật pháp, quy định và yêu cầu chính sách, đảm bảo tính hợp pháp của nguồn của tất cả tài sản kỹ thuật số trong tài khoản của bạn và không được tham gia vào các hoạt động bất hợp pháp hoặc các hoạt động khác gây tổn hại đến quyền và lợi ích của trang web này hoặc của bên thứ ba trên trang web này hoặc bằng cách sử dụng các dịch vụ của nó, bao gồm nhưng không giới hạn ở: Bạn không bị giới hạn gửi hoặc nhận bất kỳ thông tin bất hợp pháp, bất hợp pháp hoặc vi phạm quyền của người khác, gửi hoặc nhận các âm mưu kim tự tháp hoặc thông tin hoặc nhận xét có hại khác, sử dụng hoặc giả mạo email thông tin tiêu đề trên trang web này mà không có sự cho phép của trang web này, v.v.",
        content52: "4.2.2 Bạn nên tuân thủ pháp luật và các quy định, đồng thời sử dụng và lưu giữ đúng cách số tài khoản, mật khẩu đăng nhập, mật khẩu quỹ, số điện thoại di động được ràng buộc với bạn khi đăng ký và mã xác minh điện thoại di động mà điện thoại di động của bạn nhận được. Bạn hoàn toàn chịu trách nhiệm về mọi hoạt động và hậu quả của việc sử dụng tài khoản và mật khẩu đăng nhập, mật khẩu quỹ và mã xác minh điện thoại di động của mình. Khi bạn phát hiện số tài khoản, mật khẩu đăng nhập hoặc mật khẩu quỹ hoặc mã xác minh của trang web này bị bên thứ ba sử dụng mà không có sự cho phép của bạn hoặc có các vấn đề bảo mật tài khoản khác, bạn nên thông báo ngay lập tức và hiệu quả cho trang web này và yêu cầu trang web này tạm dừng. dịch vụ của tài khoản trang web này. . Trang web này có quyền thực hiện hành động theo yêu cầu của bạn trong thời gian hợp lý, nhưng trang web này không chịu bất kỳ trách nhiệm nào về hậu quả (bao gồm nhưng không giới hạn ở bất kỳ tổn thất nào bạn phải gánh chịu) đã xảy ra trước khi hành động. Bạn không được tặng, mượn, cho thuê, chuyển nhượng hoặc chuyển nhượng tài khoản của mình trên trang web này cho người khác mà không có sự đồng ý của trang web này.",
        content53: "4.2.3 Bạn đồng ý chịu trách nhiệm về mọi hoạt động diễn ra bằng tài khoản và mật khẩu của bạn trên trang web này (bao gồm nhưng không giới hạn ở việc tiết lộ thông tin, đăng thông tin, nhấp chuột trực tuyến để đồng ý hoặc gửi các quy tắc và thỏa thuận khác nhau, gia hạn thỏa thuận trực tuyến hoặc mua hàng dịch vụ, v.v.).",
        content54: "4.2.4 Khi thực hiện các giao dịch tài sản kỹ thuật số trên trang web này, bạn không được can thiệp một cách ác ý vào tiến trình bình thường của giao dịch tài sản kỹ thuật số hoặc làm gián đoạn trật tự giao dịch; bạn không được can thiệp vào hoạt động bình thường của trang web này hoặc can thiệp vào việc người dùng khác sử dụng trang web này. dịch vụ bằng bất kỳ phương tiện kỹ thuật hoặc phương tiện nào khác; bạn không được sử dụng hư cấu. Nói xấu một cách ác ý thiện chí của trang web này thông qua các sự kiện và các phương tiện khác.",
        content55: "4.2.5 Nếu bạn có tranh chấp với những người dùng khác do giao dịch trực tuyến, bạn không được yêu cầu trang web này cung cấp thông tin liên quan thông qua các kênh hành chính hoặc phi tư pháp.",
        content56: "4.2.6 Mọi khoản thuế phải nộp cũng như tất cả phần cứng, phần mềm, dịch vụ và các chi phí khác phát sinh trong quá trình bạn sử dụng các dịch vụ do trang web này cung cấp sẽ do bạn tự quyết định và chịu.",
        content57: "4.2.7 Bạn nên tuân thủ thỏa thuận này cũng như các điều khoản dịch vụ và quy tắc hoạt động khác do trang web này công bố và cập nhật tùy từng thời điểm, đồng thời có quyền chấm dứt việc sử dụng các dịch vụ do trang web này cung cấp bất kỳ lúc nào.",
        content58: "4.3 quy tắc sản phẩm",
        content59: "4.3.1 Duyệt thông tin giao dịch",
        content60: "Khi duyệt thông tin giao dịch trên trang web này, bạn nên đọc kỹ tất cả nội dung có trong thông tin giao dịch, bao gồm nhưng không giới hạn ở giá cả, khối lượng hoa hồng, phí xử lý, hướng mua hoặc bán và chỉ sau khi bạn chấp nhận hoàn toàn tất cả nội dung có trong thông tin giao dịch Giao dịch có thể được thực hiện chỉ bằng một nút bấm.",
        content61: "4.3.2 Xem chi tiết giao dịch",
        content62: "Bạn có thể xem hồ sơ giao dịch tương ứng thông qua tài khoản của mình.",
        content63: "5. Quyền và nghĩa vụ của trang web này",
        content64: "5.1 Nếu bạn không có đủ điều kiện đăng ký được quy định trong thỏa thuận này, trang web này có quyền từ chối đăng ký của bạn. Đối với những người đã đăng ký, trang web này có quyền hủy tài khoản thành viên của bạn. Trang web này có quyền giữ bạn hoặc đại lý được ủy quyền của bạn chịu trách nhiệm. Đúng vậy. Đồng thời, trang web này có quyền quyết định có chấp nhận đăng ký của bạn trong bất kỳ trường hợp nào khác hay không.",
        content65: "5.2 Dựa trên đánh giá riêng của trang web này, nếu trang web này nhận thấy rằng bạn hoặc người dùng tài khoản được liên kết của bạn không phù hợp với các khoản đầu tư có rủi ro cao, chúng tôi có quyền đình chỉ hoặc chấm dứt tài khoản của bạn và việc sử dụng tất cả các tài khoản được liên kết.",
        content66: "5.3 Khi trang web này phát hiện ra rằng người dùng tài khoản không phải là người đăng ký tài khoản ban đầu, nó có quyền đình chỉ hoặc chấm dứt việc sử dụng tài khoản.",
        content67: "5.4 Khi trang web này có lý do hợp lý để nghi ngờ rằng thông tin bạn cung cấp là sai, không đúng sự thật, không hợp lệ hoặc không đầy đủ thông qua kiểm tra kỹ thuật, lấy mẫu thủ công và các phương pháp kiểm tra khác, trang web này có quyền thông báo cho bạn để sửa hoặc cập nhật thông tin hoặc đình chỉ hoặc chấm dứt việc cung cấp thông tin đó. trang web này cung cấp dịch vụ cho bạn.",
        content68: "5.5 Trang web này có quyền sửa bất kỳ thông tin nào hiển thị trên trang web này nếu phát hiện ra rằng có lỗi rõ ràng.",
        content69: "5.6 Trang web này có quyền sửa đổi, đình chỉ hoặc chấm dứt dịch vụ của trang web này bất cứ lúc nào. Trang web này không cần thông báo trước cho bạn để thực hiện quyền sửa đổi hoặc chấm dứt dịch vụ. Nếu trang web này chấm dứt một hoặc nhiều dịch vụ này trang web, việc chấm dứt sẽ được thực hiện từ trang web này trên trang web. Có hiệu lực kể từ ngày công bố thông báo chấm dứt.",
        content70: "5.7 Trang web này sẽ thực hiện các biện pháp quản lý và phương tiện kỹ thuật cần thiết để đảm bảo hoạt động bình thường của trang web này, cung cấp môi trường giao dịch và dịch vụ giao dịch cần thiết và đáng tin cậy, đồng thời duy trì trật tự giao dịch tài sản kỹ thuật số.",
        content71: "5.8 Nếu bạn không đăng nhập vào trang web này bằng tài khoản thành viên và mật khẩu của mình trong một năm liên tiếp, trang web này có quyền hủy tài khoản của bạn. Sau khi tài khoản bị hủy, website này có quyền mở tên thành viên tương ứng cho người dùng khác đăng ký và sử dụng.",
        content72: "5.9 Trang web này đảm bảo tính bảo mật cho tài sản kỹ thuật số của bạn bằng cách tăng cường đầu tư công nghệ, cải thiện các biện pháp phòng ngừa bảo mật và các biện pháp khác, đồng thời sẽ thông báo trước cho bạn khi xảy ra rủi ro bảo mật có thể thấy trước trong tài khoản của bạn.",
        content73: "5.10 Trang web này có quyền xóa tất cả các loại thông tin nội dung trên trang web này không tuân thủ luật pháp và quy định hoặc các quy định của trang web này bất cứ lúc nào và trang web này không cần phải thông báo trước cho bạn để thực hiện các quyền đó.",
        content74: "5.11 Trang web này có quyền yêu cầu bạn cung cấp thêm thông tin hoặc tài liệu theo luật pháp, quy định, quy tắc, lệnh và các yêu cầu pháp lý khác của quốc gia hoặc khu vực có chủ quyền của bạn và thực hiện các biện pháp hợp lý để tuân thủ các yêu cầu quy định của địa phương. có nghĩa vụ hợp tác; trang web này có quyền đình chỉ hoặc ngừng vĩnh viễn việc mở một số hoặc tất cả các dịch vụ của trang web này cho bạn theo các yêu cầu của luật pháp, quy định, quy tắc, lệnh và các thông số kỹ thuật khác của quốc gia hoặc khu vực có chủ quyền của bạn.",
        content75: "5.12 Trang web này có quyền đóng tài khoản của bạn sau bảy ngày làm việc theo quyết định riêng của mình. Do đó, bạn có bảy ngày làm việc để hủy lệnh và đóng vị thế. Nếu bạn chưa hủy đơn hàng và đóng vị thế khi hết thời hạn, chúng tôi sẽ buộc hủy đơn hàng và đóng vị thế, đồng thời trả lại tài sản kỹ thuật số còn lại trong tài khoản của bạn cho bạn.",
        content76: "5.13 Để bảo vệ quyền và lợi ích của nhà giao dịch, trang web này có quyền điều chỉnh các sản phẩm trực tuyến trong các trường hợp đặc biệt (chẳng hạn như lỗi hệ thống, lỗi mạng, điều kiện thị trường khắc nghiệt, v.v.), chẳng hạn như: hợp đồng giao hàng sớm và quyết toán, loại hợp đồng, hợp đồng giao hàng và thanh toán sớm.Thời hạn, quyết toán và giá giao hàng thực hiện theo thông báo trên website này.",
        content77: "6. Bồi thường",
        content78: "6.1 Trong mọi trường hợp, trách nhiệm pháp lý của chúng tôi đối với bạn đối với các thiệt hại trực tiếp sẽ không vượt quá tổng phí dịch vụ mà chúng tôi tính cho bạn khi bạn sử dụng trang web này trong thời gian ba (3) tháng.",
        content79: "6.2 Nếu bạn vi phạm Thỏa thuận này hoặc các luật và quy định khác, bạn phải bồi thường cho chúng tôi ít nhất 2 triệu đô la Mỹ và chịu mọi chi phí phát sinh (bao gồm phí luật sư, v.v.). Nếu không đủ để bù đắp cho những tổn thất thực tế, bạn phải thực hiện sẵn sàng cho nó.",
        content80: "7. Quyền yêu cầu biện pháp khẩn cấp theo lệnh",
        content81: "Cả chúng tôi và bạn đều thừa nhận rằng các biện pháp khắc phục theo luật thông thường đối với việc bạn vi phạm hợp đồng hoặc có thể vi phạm hợp đồng có thể không đủ để bù đắp cho tất cả những tổn thất mà chúng tôi phải gánh chịu, vì vậy chúng tôi có quyền yêu cầu biện pháp khẩn cấp theo lệnh mà luật thông thường hoặc luật công bằng cho phép trong trong trường hợp bạn vi phạm hợp đồng hoặc có thể vi phạm hợp đồng.",
        content82: "8. Giới hạn trách nhiệm pháp lý và miễn trừ trách nhiệm",
        content83: "8.1 Bạn hiểu và đồng ý rằng, trong mọi trường hợp, chúng tôi sẽ không chịu trách nhiệm về các vấn đề sau:",
        content84: "8.1.1 mất thu nhập;",
        content85: "8.1.2 lợi nhuận giao dịch hoặc lỗ hợp đồng;",
        content86: "8.1.3 Thiệt hại do gián đoạn kinh doanh;",
        content87: "8.1.4 mất tiền tiết kiệm dự kiến;",
        content88: "8.1.5 Tổn thất do vấn đề thông tin;",
        content89: "8.1.6 mất cơ hội, thiện chí hoặc danh tiếng;",
        content90: "8.1.7 hỏng hoặc mất dữ liệu;",
        content91: "8.1.8 chi phí mua sản phẩm hoặc dịch vụ thay thế;",
        content92: "8.1.9 Bất kỳ tổn thất hoặc thiệt hại gián tiếp, đặc biệt hoặc ngẫu nhiên phát sinh từ hành vi vi phạm hợp đồng (kể cả sơ suất), vi phạm hợp đồng hoặc bất kỳ lý do nào khác, cho dù tổn thất hoặc thiệt hại đó có thể dự đoán trước một cách hợp lý hay không; cho dù chúng tôi có được thông báo về sự tồn tại của những điều đó trong trước Khả năng mất mát hoặc thiệt hại.",
        content93: "Các điều 8.1.1 đến 8.1.9 độc lập với nhau.",
        content94: "8.2 Bạn hiểu và đồng ý rằng chúng tôi không chịu trách nhiệm về bất kỳ thiệt hại nào gây ra cho bạn do bất kỳ trường hợp nào sau đây:",
        content95: "8.2.1 Các giao dịch cụ thể của bạn có thể liên quan đến những vi phạm nghiêm trọng về luật pháp hoặc vi phạm hợp đồng.",
        content96: "8.2.2 Hành vi của bạn trên trang web này có thể là bất hợp pháp hoặc phi đạo đức.",
        content97: "8.2.3 Chi phí và tổn thất phát sinh do mua hoặc thu thập bất kỳ dữ liệu, thông tin hoặc thực hiện giao dịch nào thông qua các dịch vụ của trang web này hoặc bằng các hành động thay thế.",
        content98: "8.2.4 Sự hiểu lầm của bạn về các dịch vụ của trang web này.",
        content99: "8.2.5 Bất kỳ tổn thất nào khác liên quan đến các dịch vụ được cung cấp bởi trang web này không phải do chúng tôi gây ra.",
        content100: "8.3 Chúng tôi chịu trách nhiệm bảo trì thiết bị mạng thông tin, lỗi kết nối mạng thông tin, lỗi máy tính, liên lạc hoặc hệ thống khác, mất điện, điều kiện thời tiết, tai nạn, đình công, tranh chấp lao động, bạo loạn, nổi dậy, bạo loạn, không đủ năng suất hoặc nguyên liệu sản xuất, hỏa hoạn, lũ lụt , bão, vụ nổ, chiến tranh, ngân hàng hoặc lý do đối tác khác, sự sụp đổ của thị trường tài sản kỹ thuật số, hành động của chính phủ, lệnh của cơ quan tư pháp hoặc hành chính, các hành động khác nằm ngoài tầm kiểm soát của chúng tôi hoặc chúng tôi không có khả năng kiểm soát hoặc lý do của bên thứ ba Chúng tôi không chịu bất kỳ trách nhiệm nào về việc không thể cung cấp dịch vụ hoặc chậm trễ hoặc tổn thất của bạn.",
        content101: "8.4 Chúng tôi không thể đảm bảo rằng tất cả thông tin, chương trình, văn bản, v.v. có trên trang web này là hoàn toàn an toàn và không bị can thiệp, hư hại bởi bất kỳ loại virus, Trojan và các chương trình độc hại nào khác.Vì vậy, nếu bạn đăng nhập và sử dụng bất kỳ dịch vụ nào trên trang web này hoặc tải xuống và sử dụng bất kỳ chương trình, Thông tin, dữ liệu, v.v. đã tải xuống đều là quyết định của riêng bạn và bạn tự chịu rủi ro cũng như tổn thất có thể xảy ra.",
        content102: "8.5 Chúng tôi không đưa ra bất kỳ đảm bảo hay cam kết nào về bất kỳ thông tin, sản phẩm và dịch vụ nào của bất kỳ trang web bên thứ ba nào được liên kết với trang web này hoặc bất kỳ dạng nội dung nào khác không thuộc sở hữu của chúng tôi. -các trang web của bên, Sản phẩm, v.v. đều là quyết định của riêng bạn và bạn chịu mọi trách nhiệm phát sinh từ đó.",
        content103: "8.6 Chúng tôi không đưa ra bất kỳ đảm bảo rõ ràng hay ngụ ý nào cho việc bạn sử dụng các dịch vụ trên trang web này, bao gồm nhưng không giới hạn ở sự phù hợp của các dịch vụ do trang web này cung cấp, không có lỗi hoặc thiếu sót, tính liên tục, độ chính xác, độ tin cậy và sự phù hợp cho một mục đích cụ thể. mục đích. Đồng thời, chúng tôi không đưa ra bất kỳ cam kết hay đảm bảo nào về tính hợp lệ, chính xác, đúng đắn, tin cậy, chất lượng, tính ổn định, đầy đủ và kịp thời của công nghệ và thông tin liên quan đến các dịch vụ do trang web này cung cấp. Việc đăng nhập hay sử dụng các dịch vụ do trang web này cung cấp là quyết định cá nhân của bạn và bạn tự chịu rủi ro cũng như tổn thất có thể xảy ra. Chúng tôi không đưa ra bất kỳ đảm bảo rõ ràng hay ngụ ý nào về thị trường, giá trị và giá của tài sản kỹ thuật số. Bạn hiểu và hiểu rằng thị trường tài sản kỹ thuật số không ổn định. Giá cả và giá trị có thể dao động hoặc sụp đổ đáng kể bất cứ lúc nào. Giao dịch tài sản kỹ thuật số là quyền tự do cá nhân của bạn Lựa chọn và quyết định rủi ro của riêng bạn và những tổn thất có thể xảy ra.",
        content104: "8.7 Các bảo đảm và cam kết của chúng tôi được nêu trong Thỏa thuận này là những bảo đảm và tuyên bố duy nhất do chúng tôi đưa ra liên quan đến Thỏa thuận này và các dịch vụ được cung cấp trên trang web này và thay thế mọi bảo đảm và cam kết phát sinh dưới bất kỳ hình thức và cách thức nào khác, dù bằng văn bản hay bằng miệng, rõ ràng. của hoặc ngụ ý. Tất cả những bảo đảm và tuyên bố này chỉ thể hiện những cam kết và bảo đảm của chính chúng tôi và không đảm bảo rằng bất kỳ bên thứ ba nào sẽ tuân thủ những bảo đảm và cam kết trong Thỏa thuận này.",
        content105: "8.8 Chúng tôi không từ bỏ bất kỳ quyền nào không được đề cập trong Thỏa thuận này nhằm hạn chế, loại trừ hoặc bù đắp trách nhiệm pháp lý của chúng tôi đối với các thiệt hại trong phạm vi tối đa theo luật hiện hành.",
        content106: "8.9 Sau khi đăng ký, bạn đồng ý với mọi hoạt động chúng tôi thực hiện theo các quy tắc được quy định trong thỏa thuận này và mọi rủi ro phát sinh sẽ do bạn chịu.",
        content107: "9. Chấm dứt Thỏa thuận",
        content108: "9.1 Trang web này có quyền chấm dứt tất cả các dịch vụ của trang web này theo thỏa thuận này. Thỏa thuận này sẽ chấm dứt vào ngày tất cả các dịch vụ của trang web này bị chấm dứt.",
        content109: "9.2 Sau khi chấm dứt thỏa thuận này, bạn không có quyền yêu cầu trang web này tiếp tục cung cấp bất kỳ dịch vụ nào cho nó hoặc thực hiện bất kỳ nghĩa vụ nào khác, bao gồm nhưng không giới hạn ở việc yêu cầu trang web này lưu giữ hoặc tiết lộ cho bạn bất kỳ thông tin nào trong tài khoản trang web ban đầu của nó. cho bạn hoặc bên thứ ba. Bên thứ ba chuyển tiếp bất kỳ thông tin nào mà họ chưa đọc hoặc gửi.",
        content110: "9.3 Việc chấm dứt thỏa thuận này sẽ không ảnh hưởng đến các trách nhiệm khác của bên không vi phạm đối với bên vi phạm.",
        content111: "10. Quyền sở hữu trí tuệ",
        content112: "10.1 Tất cả các thành tựu trí tuệ có trong trang web này bao gồm nhưng không giới hạn ở logo trang web, cơ sở dữ liệu, thiết kế trang web, văn bản và đồ họa, phần mềm, ảnh, video, âm nhạc, âm thanh và sự kết hợp của chúng, phần tổng hợp phần mềm, mã nguồn và phần mềm liên quan (bao gồm cả các ứng dụng nhỏ). ) Quyền sở hữu trí tuệ đối với chương trình, kịch bản) thuộc về website này. Bạn không được sao chép, thay đổi, sao chép, gửi hoặc sử dụng bất kỳ tài liệu hoặc nội dung nào nêu trên cho mục đích thương mại.",
        content113: "10.2 Tất cả các quyền có trong tên của trang web này (bao gồm nhưng không giới hạn ở thiện chí và nhãn hiệu, logo) đều thuộc về công ty.",
        content114: "10.3 Việc bạn chấp nhận thỏa thuận này sẽ được coi là sáng kiến ​​của bạn trong việc chuyển giao bản quyền của bất kỳ dạng thông tin nào bạn xuất bản trên trang web này, bao gồm nhưng không giới hạn ở: quyền sao chép, quyền phân phối, quyền cho thuê, quyền triển lãm, quyền biểu diễn, quyền sàng lọc, quyền phát sóng, quyền truyền tải thông tin trên Internet, quyền quay phim, quyền chuyển thể, quyền dịch thuật, quyền biên soạn và các quyền có thể chuyển nhượng khác mà người giữ bản quyền phải được hưởng sẽ được chuyển giao độc quyền miễn phí cho trang web này. khởi kiện bất kỳ đối tượng nào vi phạm và được bồi thường đầy đủ. Thỏa thuận này có hiệu lực đối với mọi nội dung tác phẩm được bảo vệ bởi luật bản quyền mà bạn xuất bản trên trang web này, cho dù nội dung đó được hình thành trước khi ký thỏa thuận này hay sau khi ký thỏa thuận này.",
        content115: "10.4 Bạn không được sử dụng hoặc hủy bỏ trái phép quyền sở hữu trí tuệ của trang web này hoặc của người khác khi sử dụng các dịch vụ của trang web này. Bạn không được công bố hoặc ủy quyền cho các trang web (và phương tiện truyền thông) khác sử dụng thông tin được công bố trên trang web này dưới mọi hình thức.",
        content116: "11. Bảo vệ thông tin",
        content117: 'Các quy định của "Điều khoản về quyền riêng tư" được công bố riêng trên trang web này sẽ được ưu tiên áp dụng.',
        content118: "12. Tính toán",
        content119: "Tất cả kết quả tính toán giao dịch đã được chúng tôi xác minh và tất cả các phương pháp tính toán đã được công bố trên trang web, nhưng chúng tôi không thể đảm bảo rằng việc sử dụng trang web sẽ không bị gián đoạn hoặc không có lỗi.",
        content120: "13. Kiểm soát xuất khẩu",
        content121: "Bạn hiểu và thừa nhận rằng theo pháp luật liên quan, bạn không được phép xuất khẩu, tái xuất khẩu, nhập khẩu hoặc chuyển giao bất kỳ tài liệu nào (bao gồm cả phần mềm) trên trang web này, vì vậy bạn đảm bảo rằng bạn sẽ không tích cực thực hiện hoặc hỗ trợ hoặc tham gia vào bất kỳ hoạt động xuất khẩu nêu trên hoặc các hoạt động liên quan vi phạm pháp luật, chuyển giao hoặc các hành vi vi phạm pháp luật và quy định hiện hành khác, nếu phát hiện trường hợp như vậy, bạn nên chủ động báo cáo cho chúng tôi kịp thời và hỗ trợ chúng tôi xử lý.",
        content122: "14. Chuyển nhượng",
        content123: "Các quyền và nghĩa vụ được quy định trong Thỏa thuận này cũng sẽ ràng buộc người được chuyển nhượng, người thừa kế, người thực hiện và người quản lý của các bên được hưởng lợi từ các quyền và nghĩa vụ đó. Bạn không được chuyển giao nó cho bất kỳ bên thứ ba nào mà không có sự đồng ý của chúng tôi, nhưng chúng tôi có thể chuyển giao các quyền và nghĩa vụ của mình trong Thỏa thuận này cho bất kỳ bên thứ ba nào vào bất kỳ lúc nào và thông báo cho bạn.",
        content124: "15. Tính chia hết",
        content125: "Nếu bất kỳ điều khoản nào của Thỏa thuận này bị bất kỳ tòa án có thẩm quyền nào cho là không thể thi hành, không hợp lệ hoặc bất hợp pháp thì điều khoản đó sẽ không ảnh hưởng đến hiệu lực của các điều khoản còn lại của Thỏa thuận này.",
        content126: "16. Mối quan hệ không đại diện",
        content127: "Không có nội dung nào trong Thỏa thuận này được coi là tạo ra, ngụ ý hoặc cấu thành chúng tôi với tư cách là đại lý, người được ủy thác hoặc đại diện khác của bạn, trừ khi có quy định khác trong Thỏa thuận này.",
        content128: "17. Vắng mặt",
        content129: "Việc chúng tôi hoặc bạn từ bỏ trách nhiệm pháp lý của bất kỳ bên nào đối với việc vi phạm hợp đồng hoặc trách nhiệm pháp lý khác được quy định trong Thỏa thuận này sẽ không được coi là hoặc được giải thích là sự từ bỏ trách nhiệm pháp lý khác do vi phạm hợp đồng; việc không thực hiện bất kỳ quyền hoặc biện pháp khắc phục nào sẽ không theo bất kỳ hình thức nào. được hiểu là sự từ bỏ các quyền hoặc biện pháp khắc phục đó.",
        content130: "18. Tiêu đề",
        content131: "Tất cả các tiêu đề chỉ nhằm mục đích thuận tiện cho việc diễn đạt thỏa thuận và không được sử dụng để mở rộng hoặc giới hạn nội dung hoặc phạm vi của các điều khoản của thỏa thuận.",
        content132: "19. Luật áp dụng",
        content133: "19.1 Trước khi đưa ra tranh chấp hoặc khiếu nại, bạn thừa nhận và đồng ý liên hệ trước với trang web này qua email để giải quyết tranh chấp một cách không chính thức. Chúng tôi sẽ cố gắng giải quyết tranh chấp của bạn trong nội bộ càng sớm càng tốt; cả hai bên đồng ý giải quyết tranh chấp thông qua đàm phán thiện chí (thảo luận) sẽ được giữ bí mật và được bảo vệ bởi các quy tắc hiện hành và không được sử dụng làm bằng chứng trong bất kỳ thủ tục tố tụng nào).",
        content134: '19.2Bạn thừa nhận và đồng ý rằng trong trường hợp có bất kỳ tranh chấp, tranh cãi, bất đồng hoặc khiếu nại nào, bao gồm sự tồn tại, hiệu lực, giải thích, thực hiện, vi phạm hoặc chấm dứt các Điều khoản này hoặc bất kỳ tranh chấp nào phát sinh từ hoặc liên quan đến các Điều khoản này ("Tranh chấp" ), các bên nêu trên sẽ đưa tranh chấp ra Tòa án Trọng tài Quốc tế của Phòng Thương mại Quốc tế ("Tòa án Trọng tài Quốc tế ICC") theo "Quy tắc Trọng tài ICC" có hiệu lực khi đó. Địa điểm phân xử trọng tài sẽ là Belize và luật áp dụng cho trọng tài sẽ được xác định theo Quy tắc trọng tài của Phòng Thương mại Quốc tế. Trừ khi các bên có thỏa thuận khác, chỉ có một trọng tài viên sẽ được chỉ định theo Quy tắc trọng tài của ICC. Trọng tài viên sẽ có thẩm quyền độc quyền để đưa ra quyết định về thẩm quyền tài phán của chính mình, bao gồm nhưng không giới hạn ở bất kỳ sự phản đối nào đối với sự tồn tại, phạm vi hoặc hiệu lực của thỏa thuận trọng tài hoặc khả năng trọng tài của bất kỳ khiếu nại hoặc yêu cầu phản tố nào. Mọi hoạt động trọng tài sẽ được tiến hành bằng tiếng Anh. Bất kể quá trình phân xử trọng tài được tiến hành như thế nào, trọng tài sẽ đưa ra phán quyết bằng văn bản hợp lý và đầy đủ để giải thích phán quyết và các kết luận quan trọng, nếu có, làm cơ sở cho phán quyết. Phán quyết trọng tài sẽ là quyết định cuối cùng và ràng buộc đối với các bên và có thể được thi hành tại bất kỳ tòa án có thẩm quyền nào. ',
        content135: "20. Hiệu lực và giải thích thỏa thuận",
        content136: "20.1 Thỏa thuận này có hiệu lực khi bạn nhấp vào trang đăng ký của trang web này để đồng ý đăng ký, hoàn tất quy trình đăng ký và lấy tài khoản và mật khẩu cho trang web này. Nó ràng buộc cả trang web này và bạn.",
        content137: "20.2 Quyền giải thích cuối cùng của thỏa thuận này thuộc về trang web này.",
        content138: "Biết khách hàng của bạn và chính sách chống rửa tiền",
        content139: "1. Giới thiệu",
        content140: '1.1 Chúng tôi hứa sẽ tuân thủ cẩn thận các luật và quy định liên quan đến "Biết khách hàng của bạn" và chống rửa tiền và sẽ không cố ý vi phạm "Chính sách hiểu biết khách hàng và chống rửa tiền". Trong phạm vi kiểm soát hợp lý của chúng tôi, chúng tôi sẽ thực hiện các biện pháp và công nghệ cần thiết để cung cấp cho bạn các dịch vụ an toàn và bảo vệ bạn khỏi những tổn thất do hành vi rửa tiền của nghi phạm hình sự gây ra ở mức tối đa có thể.',
        content141: "1.2 Chính sách Biết khách hàng và Chống rửa tiền của chúng tôi là một hệ thống chính sách quốc tế toàn diện bao gồm các chính sách Biết khách hàng và Chống rửa tiền cho các khu vực pháp lý khác nhau mà bạn có liên kết.",
        content142: "2. Biết khách hàng và chính sách chống rửa tiền như sau:",
        content143: "2.1 Ban hành các chính sách nhận biết khách hàng và chống rửa tiền, đồng thời cập nhật chúng thường xuyên để đáp ứng các tiêu chuẩn do luật pháp và quy định tương ứng đặt ra;",
        content144: "2.2 Ban hành và cập nhật một số nguyên tắc và quy tắc hướng dẫn vận hành trang web này và nhân viên của chúng tôi sẽ cung cấp dịch vụ theo hướng dẫn của các nguyên tắc và quy tắc đó;",
        content145: "2.3 Thiết kế và hoàn thiện các quy trình giám sát và kiểm soát nội bộ các giao dịch như xác minh danh tính thông qua các biện pháp nghiêm ngặt và bố trí thành lập đội ngũ chuyên nghiệp chịu trách nhiệm về công tác chống rửa tiền;",
        content146: "2.4 Sử dụng các phương pháp phòng ngừa rủi ro để tiến hành thẩm định và giám sát liên tục khách hàng;",
        content147: "2.5 Xem xét và thường xuyên kiểm tra các giao dịch đã phát sinh;",
        content148: "2.6 Báo cáo các giao dịch đáng ngờ cho cơ quan có thẩm quyền;",
        content149: "2.7 Bằng chứng về danh tính, địa chỉ và hồ sơ giao dịch sẽ được lưu giữ ít nhất sáu năm và có thể được nộp cho cơ quan quản lý mà không cần thông báo thêm cho bạn.",
        content150: "3. Thông tin nhận dạng và xác minh",
        content151: "3.1 Thông tin nhận dạng",
        content152: "3.1.1 Theo các quy định khác nhau ở các khu vực pháp lý khác nhau và các loại thực thể khác nhau, nội dung thông tin của bạn mà chúng tôi thu thập có thể không nhất quán. Về nguyên tắc, các thông tin sau sẽ được thu thập từ các cá nhân đã đăng ký:",
        content153: "Thông tin cá nhân cơ bản: tên, địa chỉ của bạn (và địa chỉ thường trú, nếu khác), ngày sinh, quốc tịch và các thông tin khác có sẵn. Việc xác minh danh tính phải dựa trên các tài liệu do chính quyền hoặc các cơ quan tương tự khác cấp, chẳng hạn như hộ chiếu, chứng minh thư hoặc các tài liệu nhận dạng khác được yêu cầu và kích hoạt bởi các khu vực pháp lý khác nhau. Địa chỉ bạn cung cấp sẽ được xác minh bằng các phương pháp thích hợp, chẳng hạn như kiểm tra vé vận chuyển hành khách hoặc phiếu lãi suất hoặc kiểm tra sổ đăng ký bầu cử.",
        content154: "Ảnh hợp lệ: Trước khi đăng ký, bạn phải cung cấp ảnh của chính bạn đang ôm CMND vào ngực;",
        content155: "Chi tiết liên hệ: Số điện thoại/số điện thoại di động và/hoặc địa chỉ email hợp lệ.",
        content156: "3.1.2 Nếu bạn là một công ty hoặc pháp nhân khác, chúng tôi sẽ thu thập thông tin sau để nhận dạng bạn hoặc người thụ hưởng cuối cùng trong tài khoản ủy thác của bạn.",
        content157: "Giấy chứng nhận đăng ký và đăng ký công ty; bản sao các điều khoản liên kết và bản ghi nhớ của công ty; tài liệu chứng nhận chi tiết về cơ cấu vốn chủ sở hữu và mô tả quyền sở hữu của công ty, chứng minh nghị quyết của hội đồng quản trị của người ủy quyền quyết định việc mở và thực hiện tài khoản trên trang web này; công ty các giám đốc cần được cung cấp theo yêu cầu, giấy tờ tùy thân của cổ đông lớn và người được ủy quyền ký tài khoản trên trang web này; địa chỉ kinh doanh chính của công ty, nếu khác với địa chỉ gửi thư của công ty, hãy cung cấp địa chỉ gửi thư. Nếu địa chỉ địa phương của công ty không nhất quán với địa chỉ kinh doanh chính thì công ty đó được coi là khách hàng có rủi ro cao hơn và sẽ cần gửi thêm tài liệu bổ sung.",
        content158: "Tùy thuộc vào các quy định khác nhau ở các khu vực pháp lý khác nhau và các loại tổ chức khác nhau, chúng tôi yêu cầu các chứng nhận và tài liệu khác do cơ quan có thẩm quyền cấp cũng như các tài liệu mà chúng tôi cho là cần thiết.",
        content159: "3.1.3 Chúng tôi chỉ chấp nhận phiên bản thông tin nhận dạng bằng tiếng Anh hoặc tiếng Trung. Nếu không, vui lòng dịch thông tin nhận dạng của bạn sang tiếng Anh và công chứng.",
        content160: "3.2 Xác nhận xác minh",
        content161: "3.2.1 Chúng tôi yêu cầu bạn cung cấp nội dung trang đầy đủ của tài liệu nhận dạng của bạn.",
        content162: "3.2.2 Chúng tôi yêu cầu một bức ảnh chụp bạn đang ôm giấy tờ tùy thân trước ngực.",
        content163: "3.2.3 Bản sao của các tài liệu hỗ trợ thường phải được đối chiếu với các chứng từ gốc. Tuy nhiên, bản sao có thể được chấp nhận nếu người chứng nhận phù hợp và đáng tin cậy có thể xác nhận rằng bản sao đó là bản sao chính xác và đầy đủ của tài liệu gốc. Những người chứng nhận như vậy bao gồm các đại sứ, ủy viên tư pháp, thẩm phán địa phương, v.v.",
        content164: "3.2.4 Yêu cầu xác định người thụ hưởng cuối cùng và quyền kiểm soát tài khoản là xác định cá nhân nào cuối cùng sở hữu hoặc kiểm soát khách hàng trực tiếp và/hoặc để chứng minh rằng các giao dịch đang diễn ra đang được thực hiện thay mặt cho người khác. Nếu là doanh nghiệp, danh tính của các cổ đông lớn (chẳng hạn như những người nắm giữ 10% quyền biểu quyết trở lên) cần được xác minh. Nói chung, việc nắm giữ 25% cổ phần sẽ được coi là có rủi ro thông thường và danh tính của cổ đông phải được xác minh; nắm giữ 10% cổ phần hoặc nắm giữ nhiều quyền biểu quyết hoặc cổ phiếu được coi là tình huống rủi ro cao và danh tính của cổ đông phải được xác minh.",
        content165: "4. Giám sát giao dịch",
        content166: "4.1 Đôi khi, chúng tôi đặt và điều chỉnh các giới hạn tối đa về giao dịch và rút tiền hàng ngày dựa trên các điều kiện giao dịch thực tế và bảo mật.;",
        content167: "4.2 Nếu giao dịch xảy ra thường xuyên ở người dùng đã đăng ký hoặc có những trường hợp không hợp lý, đội ngũ chuyên môn của chúng tôi sẽ đánh giá và quyết định xem chúng có đáng ngờ hay không.;",
        content168: "4.3 Nếu chúng tôi xác định rằng một giao dịch là đáng ngờ dựa trên nhận định của chính chúng tôi, chúng tôi có thể thực hiện các biện pháp hạn chế như đình chỉ giao dịch, từ chối giao dịch hoặc thậm chí hủy bỏ giao dịch đó càng sớm càng tốt nếu có thể và báo cáo cho cơ quan có thẩm quyền, nhưng sẽ không thông báo cho bạn.;",
        content169: "4.4 Chúng tôi có quyền từ chối đơn đăng ký từ những người từ các khu vực pháp lý không tuân thủ các tiêu chuẩn chống rửa tiền quốc tế hoặc những người có thể được coi là những người có liên quan đến chính trị. Chúng tôi có quyền đình chỉ hoặc chấm dứt các giao dịch đáng ngờ theo phán quyết duy nhất của chúng tôi tại bất cứ lúc nào, nhưng chúng tôi không làm như vậy Vi phạm bất kỳ nghĩa vụ hoặc trách nhiệm nào đối với bạn.",
    },
    register: {
        formTitle1: "tạo nên",
        formTitle2: "Đăng ký bằng email hoặc số điện thoại di động của bạn",
        formTitle3: "Tài khoản",
        field1: "Mã xác nhận",
        field2: "Đạt được",
        field3: "Xác nhận mật khẩu",
        field4: "Mã mời",
        field41: "(không bắt buộc)",
        field5: "tôi đã đọc và đồng ý",
        field6: "Điều khoản dịch vụ",
        placeholder1: "vui lòng nhập mã xác nhận",
        content7: "vui lòng nhập mã xác nhận",
        content12: "Vui lòng nhập số điện thoại",
        content13: "Xin vui lòng nhập mật khẩu",
        content14: "vui lòng nhập email của bạn",
        content18: "Vui lòng nhập lại mật khẩu",
        content19: "Vui lòng đồng ý với các điều khoản dịch vụ",
        content20: "Vui lòng nhập mã xác minh chính xác",
        content23: "Vẫn chưa nhận được mã xác minh của bạn?",
        content24: "Vui lòng nhập mã xác minh bên dưới",
        registerSuccess: "đăng ký thành công",
        content25: "Mật khẩu không nhất quán",
        content26: "Vui lòng nhập đúng địa chỉ email",
        content27: "Đặt lại mật khẩu thành công"
    },
    download: {
        content1: "Giao dịch mọi lúc, mọi nơi",
        content2: "Tương thích hoàn hảo với nhiều thiết bị đầu cuối, đáp ứng nhu cầu giao dịch của nhiều tình huống khác nhau bất cứ lúc nào",
        content3: "Kiểm tra điều kiện thị trường theo thời gian thực bất cứ lúc nào",
        content4: "Dễ dàng mua và bán BTC, ETH, XRP và các tài sản kỹ thuật số khác",
        content5: "Nhận cảnh báo sớm về những thay đổi giá đối với loại tiền điện tử mà bạn quan tâm",
        content6: "Xem khoản vay giao ngay Bitcoin, khoản vay tương lai và giá quyền chọn",
        content7: "So sánh giá tiền điện tử trên các sàn giao dịch khác nhau",
        title: "Tải xuống",
        install: "Cài đặt phần ghi chú",
        one: "1. Cho phép tải file mô tả",
        two: "2. Tệp mô tả đã được tải xuống",
        three: "3. Nhập [Cài đặt] trên điện thoại của bạn và tìm [Chung]",
        four: "4. Tệp mô tả và trình quản lý thiết bị",
        five: "5. Bấm vào file mô tả",
    },
    identity: {
        noAuthentication: {
            title: "Chứng nhận cơ sở",
            pleaseSelectYourCountry: "vui lòng chọn đất nước của bạn",
            name: "Tên",
            enterName: "xin hãy nhập tên của bạn",
            city: "thành phố",
            enterCity: "Làm ơn nhập tên thành phố bạn đang sinh sống",
            IDNumber: "số CMND",
            enterIDNumber: "Vui lòng nhập số ID của bạn",
        },
        noAdvancedAuthentication: {
            title: "Chứng nhận nâng cao",
            infoCountry: "quốc gia/khu vực",
            infoName: "Tên",
            infoNumber: "số CMND",
            infoAddress: "Địa chỉ",
        },
        text: "không chứng nhận",
        recertification: "chứng nhận lại",
        certification: "Chứng nhận",
        authenticationInProgress: "đã xác minh",
        inAuthentication: "Đang tiến hành chứng nhận",
        uploadTheFrontOfTheDocument: "Tải lên mặt trước của ID",
        uploadTheReverseSideOfTheDocument: "Tải lên mặt sau của chứng chỉ",
        uploadAPhotoOfAHandHeldID: "Upload ảnh CMND cầm trên tay",
        pleaseUpload: "vui lòng ",
        pleaseCompleteOrdinaryCertificationFirst: "Cần chờ sự thành công của đợt xét duyệt chứng nhận sơ bộ",
        refused: "từ chối",
        reason: "lý do",
        succeeded: "đã thành công",
        uploading: "đang tải lên",
        newTitle: "Chọn quốc gia nơi ID của bạn được cấp",
        pleaseOrdinaryCertificationFirst: "Vui lòng thực hiện chứng nhận chính trước",
        text1: "Sửa đổi hình đại diện",
        text2: "tên nick",
        text3: "tài khoản",
        text4: "Sửa đổi biệt hiệu",
        text5: "biệt danh mới",
        text6: "Nhập biệt hiệu mới của bạn",
        text7: "Mật khẩu cũ",
        text8: "Điền mật khẩu cũ",
        text9: "mật khẩu mới",
        text10: "Nhập mật khẩu mới của bạn",
        text11: "Xác nhận mật khẩu",

        text12: "Nhập lại mật khẩu mới của bạn",
        text13: "hộp thư mới",
        text14: "Mã xác nhận",
        text15: "vui lòng nhập mã xác nhận",
        text16: "số điện thoại di động của bạn",
        text17: "Số điện thoại di động mới",
        text18: "xin vui lòng điền số điện thoại của bạn",
        text19: "Mã xác minh điện thoại di động",
        text20: "Chứng nhận cơ sở",
        text21: "Vui lòng xác minh thông tin nhận dạng của bạn",
        text22: "Quốc gia",
        text23: "Thành phố",
        text24: "Nhập thành phố của bạn",
        text25: "Nhập tên của bạn",
        text26: "Số ID của bạn",
        text27: "Nhập số ID của bạn",
        text28: "Ảnh mặt trước CMND",
        text29: "Bấm để tải lên tài liệu của bạn",
        text30: "tải lên lại",
        text31: "tải lên",
        text32: "Giữ ảnh CMND",
        text33: "Nhấp để tải lên ảnh ID đang giữ của bạn",
        text34: "Bạn đã ràng buộc Google Authenticator",
        text35: "chìa khóa",
        text36: "Vui lòng nhập mã xác minh Google",
        text37: "gửi",
        text38: "Không thể trống",
        text39: "Định dạng email sai",
        text40: "Hai mật khẩu không nhất quán",
        text41: "Đã sao chép thành công",
        text42: "Sao chép không thành công",
        text43: "Sửa đổi hình đại diện",
        text44: "đổi mật khẩu",
        text45: "Ràng buộc email",
        text46: "Sửa đổi email",
        text47: "Ràng buộc điện thoại di động",
        text48: "Sửa đổi điện thoại di động",
        text49: "Xác minh của Google",
        text50: "Liên kết Google Authenticator",
        text51: "Nhập mã xác minh của Google",
        text52: "cứu",
        text53: "Xác nhận gửi",
        text54: "Ôn lại",
        text55: "ràng buộc",
        text56: "Tắt xác minh Google",
        text57: "Bước tiếp theo",
        text58: "Hoàn thành",
        text59: "Xác minh của Google bị ràng buộc",
        text60: "Xác minh của Google đã được tách nhóm",
        text61: "Giấy chứng nhận chính đã được gửi",
        text62: "Đã gửi chứng chỉ nâng cao",
        text63: "Không có hình ảnh được tải lên",
        text64: "Gửi lại",
        text65: "Hãy nhập một nickname",
        text66: "Đã lưu biệt hiệu thành công",
        text67: "Chưa có hình ảnh nào được tải lên",
        text68: "Đặt lại mật khẩu hoàn tất",
        text69: "Email được sửa đổi thành công",
        text70: "Đã lưu hình đại diện thành công",
        text71: "Tải hình ảnh lên",
        text72: "hộp thư của bạn",
        text73: "Nhập email mới của bạn",
        text74: "Tên của bạn",
        text75: "Chứng nhận",
        text76: "Vui lòng xác minh ảnh ID đang giữ của bạn"
    },
    retrievePassword: {
        formTitle1: "đặt lại mật khẩu",
        formTitle2: "Vì sự an toàn cho tài sản của bạn, các giao dịch bị cấm trong vòng 24 giờ sau khi thay đổi mật khẩu.",
        formTitle3: "Lấy lại mật khẩu",
        formTitle4: "Xác nhận những thay đổi",
        formTitle5: "Vui lòng nhập mật khẩu cũ",
        formTitle6: "Vui lòng nhập mật khẩu mới"
    },
    home: {
        title1: "Nền tảng giao dịch tiền điện tử phổ biến nhất",
        title3: "tại sao lại là ",
        title4: " Đây là một nền tảng blockchain mã nguồn mở hiệu suất cao được thiết kế để hỗ trợ và vận hành cơ sở hạ tầng kỹ thuật số an toàn, tuân thủ và có thể dự đoán được.",
        field1: "Trao đổi thành lập",
        field2: "Dịch vụ tài chính tài sản kỹ thuật số",
        field3: "Hàng chục triệu người dùng",
        field4: "Thành lập nhiều quỹ bảo vệ nhà đầu tư",
        field5: "Giao dịch nhanh",
        field6: "CME Gruop có thể khớp lệnh với độ trễ cực thấp để mang lại trải nghiệm giao dịch tối ưu.",
        title5: "xu hướng thị trường",
        title6: "xem thêm",
        table1: "Tiền tệ",
        table2: "Giá mới nhất",
        table3: "Thăng trầm 24H",
        table4: "Khối lượng giao dịch 24H",
        table5: "Khối lượng giao dịch 24H",
        table6: "Báo giá",
        title7: "Về ",
        title8: " Chúng tôi cam kết cung cấp cho người dùng những công cụ giao dịch tiên tiến nhất, công nghệ tiên tiến và dịch vụ khách hàng chất lượng tuyệt vời.",
        title9: "Lĩnh vực tài sản kỹ thuật số đang thay đổi từng ngày, chỉ có tầm nhìn xa và tiến bộ nhanh chóng thì chúng ta mới có thể theo kịp tốc độ đổi mới công nghệ.",
        title901: " Cung cấp các dịch vụ giao dịch tài sản kỹ thuật số tiên tiến nhất cho người dùng và nhà cung cấp thanh khoản toàn cầu của chúng tôi.",
        title10: "Chúng tôi tin chắc rằng mọi người dùng đều xứng đáng có được trải nghiệm giao dịch tốt nhất.",
        title11: "Bảo mật bạn có thể tin tưởng",
        title12: "Các biện pháp bảo mật phức tạp và quỹ SAFU của chúng tôi bảo vệ tài sản kỹ thuật số của bạn khỏi mọi rủi ro. ",
        title13: "Tỷ giá giao dịch tốt nhất",
        title14: "Tỷ lệ giao dịch ưu đãi, quyền VIP cạnh tranh và tận hưởng chất lượng dịch vụ tốt nhất.",
        title15: "Hỗ trợ dịch vụ khách hàng 24*7 giờ",
        title16: "Chế độ hoạt động toàn thời gian, mọi thời tiết 24/7, chúng tôi sẽ trả lời các câu hỏi liên quan của bạn trong thời gian sớm nhất.",
        title17: "giao dịch 0 phí",
        title18: "Sử dụng nhiều phương thức thanh toán để giao dịch tiền kỹ thuật số với phí xử lý bằng 0, an toàn và nhanh chóng.",
        title19: "Giao dịch mọi lúc, mọi nơi",
        title20: "Tương thích hoàn hảo với nhiều thiết bị đầu cuối, đáp ứng nhu cầu giao dịch của nhiều tình huống khác nhau bất cứ lúc nào",
        title21: "Kiểm tra điều kiện thị trường theo thời gian thực bất cứ lúc nào",
        title22: "Dễ dàng mua và bán BTC, ETH, XRP và các tài sản kỹ thuật số khác",
        title23: "Nhận cảnh báo sớm về những thay đổi giá đối với loại tiền điện tử mà bạn quan tâm",
        title24: "Xem khoản vay giao ngay Bitcoin, khoản vay tương lai và giá quyền chọn",
        title25: "So sánh giá tiền điện tử trên các sàn giao dịch khác nhau",
        title26: "Đăng ký ngay",
        title27: "đô la",
        title28: "Khối lượng giao dịch 24 giờ",
        title29: "Nhà cung cấp thanh khoản tích hợp",
        title30: "người dùng",
        title31: "Hơn",
        title32: "Binance Loan bổ sung CTSI & KSM làm tài sản thế chấp và triển khai chương trình chiết khấu giảm lãi suất BUSD & USDT!",
        title33: "lợi thế của chúng tôi",
        title34: "an toàn và ổn định",
        title35: "Đội ngũ kỹ thuật hàng đầu, bảo vệ an ninh toàn diện, nghiên cứu và phát triển độc lập công cụ tổng hợp giao dịch tốc độ cao, vẫn có thể hoạt động ổn định và đáng tin cậy dưới các giao dịch đồng thời lớn.",
        title36: "Tuân thủ chuyên môn",
        title37: "Đội ngũ vận hành chuyên nghiệp, với nhiều năm kinh nghiệm trong lĩnh vực blockchain và tài chính, có giấy phép giao dịch tài sản kỹ thuật số tuân thủ và đảm bảo dự trữ 100%.",
        title38: "Người dùng là trên hết",
        title39: "Đối mặt với thị trường toàn cầu, hỗ trợ nhiều ngôn ngữ, hoạt động 24/7, hỗ trợ cộng đồng mạnh mẽ và dịch vụ khách hàng chuyên nghiệp.",
        title40: "hiệu suất cao",
        title41: "300.000 giao dịch mỗi giây và thời gian phản hồi đơn hàng dưới 1 mili giây.",
        title42: "về chúng tôi",
        title43: "Giao dịch mọi lúc, mọi nơi",
        title44: "Tương thích hoàn hảo với nhiều thiết bị đầu cuối, đáp ứng nhu cầu giao dịch của nhiều tình huống khác nhau bất cứ lúc nào",
        title45: "(Quét mã QR để tải về)",
        title46: "Tin tức tiền tệ kỹ thuật số",
        title47: "đối tác kinh doanh của chúng tôi",
        title48: "Danh sách thua cuộc",
        title49: "doanh thu 24h",
        title50: "24h tăng giảm"
    },
    quotation: {
        marketTrading: "Báo giá",
        turnover: "Âm lượng",
        optional: "Âm lượng",
        contractMarket: "thị trường hợp đồng",
        currencyPair: "cặp tiền tệ",
        latestPrice: "Giá đóng cửa",
        upsAndDowns: "Thay đổi báo giá",
        highest: "Cao nhất",
        lowest: "thấp nhất",
        transactionAmount: "Khối lượng giao dịch",
        operate: "vận hành",
        buy: "buôn bán",
        topTurnovers: "Danh sách giao dịch",
        topGainers: "Danh sách tăng giá",
        tradingPair: "cặp giao dịch",
        buyOrder: "Mua",
        sellOrder: "lệnh bán"
    },
    accountChange: {
        demo: "DEMO",
        realAccount: "tài khoản thực tế",
        demoAccount: "tài khoản demo",
        accountRole: "Vai trò tài khoản",
        pleaseSelect: "xin vui lòng chọn",
        AccountChangeType: "Loại thay đổi tài khoản",
        AccountType: "Kiểu tài khoản",
        currencyType: "Loại tiền tệ",
        allType: "Các loại",
        allAccount: "Tất cả các tài khoản",
        allCoin: "Tất cả các loại tiền tệ",
        coin: "Tiền tệ",
        amount: "Thay đổi nhiều",
        balance: "Số dư tài khoản",
        time: "thời gian",
        title: "Hồ sơ thay đổi tài khoản",
        filtrar: "lọc",
        check: "chuyển sang",
        showOnly: "Chỉ hiển thị số tiền nắm giữ",
        holdAssets: "Số dư tiền tệ",
        accountList: "Danh sách tài khoản"
    },
    contractTransaction: {
        chart: "đồ thị",
        basicEdition: "Phiên bản cơ bản",
        depthMap: "Bản đồ độ sâu",
        second: "Thứ hai",
        minute: "điểm",
        day: "bầu trời",
        week: "tuần",
        month: "mặt trăng",
        open: "mở",
        trade: "buôn bán",
        buyLong: "mua lâu",
        buyShort: "mua ngắn hạn",
        cannotBuy: "Loại tiền này không hỗ trợ giao dịch Giao hàng",
        cannotBuy1: "Loại tiền này không hỗ trợ giao dịch vĩnh viễn",
        tradingAssets: "tài sản giao dịch",
        amountAvailable: "Số lượng hiện có sẵn",
        timeStr: "thời gian",
        transactionPeriod: "thời gian giao dịch",
        quantity: "Số lượng",
        enterQuantity: "Vui lòng nhập số lượng",
        buyIn: "Đi lâu",
        latestTransaction: "giao dịch mới nhất",
        time: "thời gian",
        direction: "phương hướng",
        price: "giá ",
        sell: "ngắn",
        positionOrder: "Yêu cầu giao hàng",
        historicalOrders: "Lệnh lịch sử",
        product: "sản phẩm",
        realAccount: "(Tài khoản thực)",
        demoAccount: "(tài khoản demo)",
        openingTime: "thời gian phục vụ",
        openingPrice: "Giá mở cửa",
        curretPrice: "Giá hiện tại",
        liquidationPrice: "Giá đóng cửa",
        profitOrLoss: "Lãi và lỗ",
        endTime: "Thời gian đóng cửa",
        areYouSureToBuy: "Bạn có chắc chắn muốn mua không?",
        areYouSureToLongBuy: "Bạn có chắc chắn muốn đi lâu dài?",
        areYouSureToShortBuy: "Bạn có chắc chắn muốn mua bán khống?",
        successfullyBought: "Mua thành công",
        minBuy: "Mua vào tối thiểu",
        assets: "tài sản",
        withdraw: "rút",
        entrustedOrder: "Đặt hàng",
        total: "tổng cộng",
        continueToPlaceOrder: "Tiếp tục đặt hàng",
        orderConfirmation: "Xác nhận đơn hàng",
        buyRu: "mua",
        sellChu: "bán",
        areYouSureToSell: "Bạn có chắc chắn muốn bán không?",
        operation: "vận hành",
        buyCover: "vị trí che chắn",
        commitSuccess: "Giao dịch thành công",
        onlyEnterIntegers: "Chỉ có thể nhập số nguyên",
        trend: "xu hướng",
        lotteryTime: "Hạn chót đặt hàng",
        countdown: "Đếm ngược",
        period: "Số báo",
        cycle: "Khoảng thời gian",
        unlimited: "Vô hạn",
        put: "Put",
        call: "Call",
        pending: "Theo hoa hồng",
        unopened: "Chưa được vẽ",
        opened: "Rút thưởng",
        settlementPrice: "Giá thanh toán",
        rateOfReturn: "tỷ lệ lợi nhuận",
        currentPositions: "Vị trí hiện tại",
        positionQuantity: "số lượng vị thế",
        guarantee: "quỹ bảo đảm",
        text1: "Tăng",
        text2: "Giảm",
        text3: "Đang chờ mua",
        text4: "lượng đặt hàng",
        text5: "Số lượng giao dịch",
        text6: "Hủy bỏ",
        text7: "thời gian giao hàng",
        text8: "Lời nhắc hủy",
        text9: "Bạn có chắc chắn muốn hủy đơn hàng không?",
        text10: "số lượng trái phép",
        text11: "Nhắc nhở đặt hàng",
        text12: "Bạn có chắc chắn mình đang mua theo xu hướng tăng giá?",
        text13: "Bạn có chắc chắn về việc mua quyền chọn bán không?",
        text14: "Hủy thành công"
    },
    mining: {
        title1: "Nền tảng khai thác tài sản kỹ thuật số blockchain hàng đầu thế giới",
        title2: "Công khai và minh bạch · an toàn và ổn định",
        title3: "Vui lòng chọn loại tiền tệ",
        title4: "Năng suất khai thác hàng năm",
        title5: "bầu trời",
        title6: "Thời kỳ khai thác",
        title7: "Chi tiết sản phẩm",
        btn: "Khai thác ngay bây giờ",
        btn2: "Khép kín",
        tabs1: "trong tiến trình",
        tabs2: "được chuộc lại",
        table1: "tên sản phẩm",
        table2: "Thời kỳ khai thác",
        table3: "Thời gian khai thác",
        table4: "Số lượng khai thác",
        table5: "Hạn sử dụng",
        table6: "Tiền thu được đến hạn",
        table7: "Tình trạng đặt hàng",
        egularMining: "Khai thác thường xuyên",
        inProgress: "trong tiến trình",
        redeemed: "được chuộc lại",
        redemption: "sự cứu chuộc",
        regularMining: "Khai thác thường xuyên",
        earlyRedemption: "sự chuộc lỗi sớm",
        need: "cần",
        handlingFee: "phí xử lý",
        redemptionConfirmationMessage: "Vui lòng xác nhận có nên đổi trước không?",
        form1: "Năng suất khai thác hàng năm",
        form2: "Thời kỳ khai thác",
        form3: "Khai thác tiền tệ",
        form4: "Số tiền đăng ký (tối thiểu/tối đa)",
        form5: "Phí hoàn trả sớm: ",
        form6: "giá mua",
        form7: "Tiền khả dụng",
        form8: "giá mua",
        form9: "Tiền khả dụng",
        form10: "Vui lòng nhập số tiền mua",
        form11: "tất cả",
        form12: "Doanh thu được ước tính",
        form13: "Không đủ tiền sẵn có",
        form14: "Vui lòng nhập số tiền mua",
        form15: "Đơn hàng của tôi",
        form16: "thu nhập",
        form17: "chi tiết đặt hàng",
        form18: "Hướng dẫn đổi quà sớm:",
        form19: "Số tiền đăng ký (tối thiểu)",
        form20: "Số tiền đăng ký (tối đa)",
        form21: "Số lượng nắm giữ",
        form22: "Mua thời gian",
        form23: "thời gian đổi thưởng",
        form24: "qua",
        form25: "Thuê bao thành công",
        form26: "Hoàn vốn thành công",
        form27: "tài chính dễ dàng với lợi nhuận trên 25%, vượt trội so với thị trường và bạn cũng có thể nhận được phần thưởng sau khi mua",
        form28: "Mời bạn bè để nhận phần thưởng 50 USDT và phiếu hợp đồng cũng có thể trả lại 20% hoa hồng."
    },
    assets: {
        content1: "Tài sản tài khoản",
        content2: "Thu nhập ngày hôm nay",
        content3: "tài khoản hiện tại",
        content4: "Tài khoản thực tế là tài sản tài khoản thực tế của bạn, có thể được sử dụng để giao dịch và rút tiền.",
        content5: "Rút xu",
        content6: "Gửi tiền xu",
        content7: "trao đổi",
        content8: "truyền",
        content9: "vui lòng chọn",
        content10: "tài khoản demo",
        content11: "tài khoản thực tế",
        content12: "có được tài sản",
        content13: "Bạn có chắc chắn muốn chuyển sang tài khoản thực không?",
        content14: "Bạn có chắc chắn muốn chuyển sang tài khoản demo không?",
        content15: "đạt được thành công",
        table1: "Tiền tệ",
        table2: "Số lượng",
        table3: "đông cứng",
        table4: "Có sẵn",
        dialog1: "Thông tin chi tiết sản phẩm",
        dialog2: "1. Tài sản này chỉ được phép sử dụng ở chế độ tài khoản demo",
        dialog3: "2. Để tránh lạm dụng, chỉ được phép quy đổi một lần trong vòng một tháng.",
        dialog4: "3. Khi nhận tài sản, số lượng tài sản nhận được sẽ được cộng vào số tiền ban đầu và trạng thái vị thế không thay đổi.",
        dialog5: "4. Nếu bạn có bất kỳ câu hỏi nào khác, vui lòng liên hệ với bộ phận chăm sóc khách hàng",
        dialog6: "Khép kín",
        dialog7: "Nhận nội dung demo ngay bây giờ",
        dialog8: "chuyển giao",
        dialog9: "chuyển đi",
        dialog10: "công tắc",
        dialog11: "Định giá",
        dialog12: "Tài trợ demo",
        dialog13: "tài khoản của tôi",
        dialog14: "Tổng tài sản tài khoản",
        dialog15: "Tổng quan về tài sản",
        dialog16: "Định giá tổng tài sản",
        dialog17: "Định giá tài sản",
        dialog18: "Thu nhập hôm nay",
        dialog19: "quỹ đóng băng",
        dialog20: "Bạn có thể nhận được hạn ngạch tài sản mô phỏng mỗi tháng",
        dialog21: "Lấy",
        dialog22: "Bạn đã nhận thành công",
        dialog23: 'Thu nhập tích lũy 30 ngày',
        dialog24: "Sản phẩm tài chính",
        dialog25: "tài chính của tôi",
        dialog26: "tài chính lịch sử",
        dialog27: "kỷ lục lịch sử",
        dialog28: "Hồ sơ tài trợ"
    },
    validator: {
        dialog14: "người xác nhận",
        dialog15: "Trình xác thực ràng buộc",
        dialog16: "công tắc xác thực",
        dialog17: "Mã xác nhận",
        dialog18: "Mã QR xác thực",
        dialog19: "khóa xác thực",
        dialog20: "cứu",
        dialog21: "Vui lòng chọn người xác thực",
        dialog22: "sao chép chìa khóa",
        dialog23: "đã lưu thành công",
        dialog24: "vui lòng nhập mã xác nhận",
        dialog25: "Lời nhắc thân thiện: Trước khi xác nhận lưu, vui lòng mở APP tương ứng và quét mã QR bên dưới hoặc thêm khóa thủ công!",
        dialog26: "Việc kích hoạt trình xác thực sẽ mang lại sự bảo mật tối đa cho tài khoản của bạn.",
        dialog27: "Bạn sẽ cần nhập mã xác minh duy nhất được truy cập qua điện thoại di động của mình mỗi lần bạn đăng nhập hoặc rút tiền.",
        dialog28: "Thiết lập trình xác nhận",
        dialog29: "giúp đỡ",
        dialog30: "Bạn chưa ràng buộc mã xác thực. Nhận được sự bảo vệ mạnh mẽ hơn với trình xác thực.",
        dialog31: "Trình xác thực chưa được thiết lập, vui lòng thiết lập nó trước",
        dialog32: "Đi tới cài đặt"
    },
    recharge: {
        title1: "Bạn không chắc chắn về cách mua bằng tiền kỹ thuật số?",
        title2: "Chỉ cần ba bước đơn giản!",
        title3: "Chọn loại tiền kỹ thuật số bạn muốn mua từ menu thả xuống và nhập số lượng hoặc số tiền mua.",
        title4: "Chọn phương thức thanh toán ưa thích của bạn",
        title5: "Sau khi thanh toán được xác nhận, loại tiền kỹ thuật số bạn đã mua sẽ tự động được chuyển vào tài khoản của bạn.",
        title6: "Vui lòng chọn loại tiền bạn muốn nạp",
        title7: "Hồ sơ gửi tiền",
        table1: "Tiền tệ",
        table2: "Số lượng",
        table3: "Tên chuỗi",
        table4: "tình trạng",
        table5: "lý do",
        table6: "thời gian",
        table7: "Xem chứng từ",
        dialog1: "Vui lòng nhập mã xác minh bên dưới",
        dialog2: "mã thanh toán",
        dialog3: "sao chép",
        dialog4: "Địa chỉ",
        dialog5: "Số tiền gửi",
        dialog6: "Chụp màn hình giao dịch",
        dialog7: "Thông báo quan trọng",
        dialog8: "Vui lòng điền số tiền gửi",
        dialog9: "tải hình ảnh lên",
        dialog10: "Vui lòng nhập nội dung",
        dialog11: "Vui lòng tải lên ảnh chụp màn hình nạp tiền",
        dialog12: "Chưa được xem xét",
        dialog13: "vật bị loại bỏ",
        dialog14: "Đã gửi tiền",
        tip1: "gửi thành công",
        tip2: "Sao chép thành công",
        tip3: "Không sao chép được",
        tip4: "Vui lòng chọn loại tiền bạn muốn nạp",
        tip5: "1.Địa chỉ gửi tiền ở trên là địa chỉ thanh toán chính thức của nền tảng. Vui lòng tìm địa chỉ gửi tiền chính thức của nền tảng. Bạn sẽ chịu trách nhiệm về việc mất tiền do gửi nhầm;",
        tip6: "2.Hãy đảm bảo xác nhận tính bảo mật của máy tính và trình duyệt của bạn để tránh thông tin bị giả mạo hoặc rò rỉ;",
        tip7: "3.Sau khi bạn nạp tiền vào địa chỉ trên, toàn bộ nút mạng cần phải xác nhận trước khi có thể ghi có vào tài khoản;",
        tip8: "4.Vui lòng chọn hệ thống mã thông báo và loại tiền tệ ở trên và chuyển số tiền tương ứng để gửi tiền. Vui lòng không chuyển bất kỳ tài sản không liên quan nào khác, nếu không sẽ không được lấy lại.",
        tip9: "Lưu mã QR",
        tip10: "nạp tiền",
        tip11: "Thông báo quan trọng",
        tip12: "Liên kết ngoài trang web",
        tip13: "Nạp tiền bằng mã QR",
        tip14: "Bước tiếp theo",
        tip15: "Kỷ lục nạp tiền",
        tip16: "Chi tiết nạp tiền",
        tip17: "Địa chỉ gửi tiền",
        tip18: "Thanh toán nhanh",
        tip20: "Thiếu cân bằng",
        tip22: "Đã gửi, đang chờ xác nhận",
        tip23: "chờ thanh toán",
        tip24: "Số lượng",
        tip25: "Thanh toán đã được gửi, đang chờ xác nhận khối",
        tip26: "Thanh toán bị từ chối",
        tip27: "Thanh toán thất bại",
        tip28: "Thanh toán thành công",
        bankChannel: "Kênh thẻ ngân hàng",
        tip29: "Tìm kiếm lịch sử",
        tip30: "Danh sách tiền tệ",
        tip31: "Chọn mạng",
        tip32: "Khi bạn nạp loại tiền này, vui lòng chỉ nạp tiền qua các mạng được hỗ trợ bên dưới. Nếu bạn nạp tiền qua các mạng khác, tiền sẽ bị mất.",
        tip33: "Khi bạn rút loại tiền này, vui lòng chỉ rút qua các mạng được hỗ trợ bên dưới, nếu bạn rút qua các mạng khác, tiền sẽ bị mất.",
        tip34: "Nạp tiền nhanh",
        tip35: "Scan code để nạp tiền",
        tip36: "Nạp tiền thủ công",
        tip37: "Mạng nạp tiền",

        tip38: "Địa chỉ nạp tiền",
        tip39: "Số tiền nạp",
        tip40: "Tải lên biên lai giao dịch",
        tip41: "tải hình ảnh lên",
        tip42: "xác nhận gửi",
        tip43: "xác nhận thanh toán",
        tip44: "Lưu ý: Vui lòng xác nhận địa chỉ bạn cần nạp, chúng tôi không chịu trách nhiệm về việc mất tiền nếu bạn điền sai.",
        tip45: "nhắc lại",
        tip46: "Đã sao chép địa chỉ thành công!",
        tip47: "Sao chép địa chỉ không thành công!",
        tip48: "Số dư khả dụng không đủ",
        tip49: "Việc nạp tiền đã được gửi thành công và đang chờ xác nhận trên chuỗi.",
        tip50: "Nạp tiền thất bại",
        tip51: "Số tiền nạp sai",
        tip52: "Kết nối thất bại",
        tip53: "Ví chưa đăng nhập",
        tip54: "Kết nối bị từ chối",
        tip55: "Đã gửi thành công, đang chờ dịch vụ khách hàng xem xét và phê duyệt",
        tip56: "Vui lòng tải lên ảnh chụp màn hình biên lai giao dịch",
        tip57: "Gửi không thành công",
        tip58: "Kỷ lục nạp tiền",
        tip59: "Hồ sơ rút tiền",
        tip60: "Nạp tiền tệ",
        tip61: "Loại tiền rút",
        tip62: "Địa chỉ nạp tiền",
        tip63: "Hiển thị ảnh chụp màn hình",
        tip64: "Bấm vào để phóng to hình ảnh",
        tip65: "thời gian nạp tiền",
        tip66: "Lý do từ chối",
        tip67: "Số tiền rút",
        tip68: "Số tiền nhận được",
        tip69: "Mạng lưới rút tiền",
        tip70: "Địa chỉ rút tiền",
        tip71: "Thời gian rút tiền",
        tip72: "Khép kín",
        tip73: "Chụp màn hình giao dịch",
        tip74: "Đang chờ xem xét",
        tip75: "vật bị loại bỏ",
        tip76: "hoàn thành",
        tip77: "vật bị loại bỏ",
        tip78: "Đã kiểm toán",
        tip79: "Đã giải ngân",
        tip80: "trích xuất",
        tip81: "mạng lưới chuyển giao",
        tip82: "Địa chỉ/Hóa đơn",
        tip83: "Rút toàn bộ",
        tip84: "Vui lòng không rút tiền trực tiếp đến địa chỉ gây quỹ cộng đồng hoặc ICO, nếu không bạn sẽ không thể nhận được mã thông báo do quỹ cộng đồng hoặc ICO phát hành.",
        tip85: "Số lượng đến",
        tip86: "Xác nhận rút tiền",
        tip87: "Địa chỉ rút tiền sai",
        tip88: "Số tiền rút sai",
        tip89: "Số tiền rút tối thiểu là",
        tip90: "Số tiền rút tối đa là",
        tip91: "Yêu cầu rút tiền đã được gửi",
        tip92: "danh sách địa chỉ",
        tip93: "Bạn chưa thêm địa chỉ",
        tip94: "Thêm nó ngay bây giờ",
        tip95: "Thêm địa chỉ",
        tip96: "thêm địa chỉ mới",
        tip97: "xin vui lòng chọn",
        tip98: "Địa chỉ",
        tip99: "Vui lòng chọn mạng chuyển tiền",
        tip100: "Vui lòng nhập địa chỉ",
        tip101: "Đã thêm địa chỉ thành công",
        tip102: "trong giao dịch",
    },
    fundExchange: {
        title1: "Làm thế nào để trao đổi tiền?",
        title2: "Chọn loại tiền tệ thích hợp để chuyển đổi.",
        title3: "Chọn loại tiền phù hợp để chuyển đổi.",
        title4: "Nhập số tiền cần đổi và nhấp vào Gửi để hoàn tất việc đổi tiền.",
        title5: "từ",
        title6: "đến",
        title7: "Trao đổi số lượng",
        title8: "tỷ giá",
        title9: "Có sẵn",
        title10: "Vui lòng nhập số lượng quy đổi",
        title11: "Không có cặp giao dịch tương ứng để trao đổi.",
        title12: "Vui lòng nhập số lượng muốn đổi",
        title13: "Đổi thưởng thành công",
        title14: "Trao đổi hồ sơ",
        table1: "Sử dụng tiền tệ",
        table2: "Số lượng",
        table3: "tỷ giá",
        table4: "Trao đổi tiền tệ",
        table5: "Biên lai",
        table6: "thời gian",
        title15: "từ",
        title16: "Hiện tại không có tài sản nào, vui lòng chọn tài sản khác"
    },
    fundTransfer: {
        title1: "Làm thế nào để chuyển tiền?",
        title2: "Chọn tài khoản hiện tại cần chuyển.",
        title3: "Chọn tài khoản bạn muốn chuyển.",
        title4: "Nhập số tiền chuyển và nhấp vào Gửi để hoàn tất chuyển khoản.",
        title5: "Tiền tệ",
        title6: "số lượng chuyển nhượng",
        title7: "Vui lòng nhập số tiền cần chuyển",
        title8: "hồ sơ chuyển nhượng",
        title9: "Vui lòng chọn loại ví",
        title10: "Vui lòng chọn loại tiền tệ",
        title11: "Vui lòng chọn loại ví",
        title12: "Vui lòng chọn loại tiền tệ",
        title13: "Vui lòng nhập số tiền cần chuyển",
        title14: "gửi thành công",
        table1: "Chuyển tài khoản ra",
        table2: "chuyển tiền tệ",
        table3: "số lượng chuyển nhượng",
        table4: "chuyển tài khoản",
        table5: "thời gian",
        table6: "chuyển khoản",
        table7: "Chuyển quĩ",
        table8: "Chuyển tới",
        table9: "hồ sơ chuyển nhượng",
        table10: "Chi tiết chuyển khoản",
        table11: "Chuyển thành công",
        table12: "Chuyển không thành công",
        table13: "Không thể chuyển tài khoản hiện tại"
    },
    withdrawal: {
        title1: "Thông báo quan trọng",
        title2: "1.Để ngăn chặn chênh lệch giá, bạn có thể đăng ký rút tiền khi khối lượng giao dịch đạt đến hạn mức.",
        title3: "2.Đơn xin rút tiền sẽ được ghi có trong vòng 24 giờ sau khi gửi. Nếu không nhận được tiền rút sau thời gian rút tiền dự kiến, vui lòng liên hệ với bộ phận dịch vụ khách hàng trực tuyến.",
        title4: "3.Sau khi gửi đơn đăng ký rút tiền, tiền sẽ ở trạng thái đóng băng vì quá trình rút tiền đang diễn ra và tiền tạm thời bị hệ thống giữ, điều đó không có nghĩa là bạn đã mất tài sản hoặc có sự bất thường với tài sản.",
        title5: "Tiền tệ",
        title6: "địa chỉ ví",
        title7: "Số xu được rút",
        title8: "Kỷ lục rút tiền",
        title9: "Vui lòng chọn loại tiền tệ",
        title10: "Vui lòng chọn loại dây chuyền",
        title101: "",
        title11: "vui lòng nhập",
        title12: "địa chỉ ví",
        title13: "gửi thành công",
        title14: "đang được xem xét",
        title15: "Đánh giá thành công",
        title16: "Kiểm toán thất bại",
        title17: "Đã giải ngân",
        title18: "Vui lòng nhập hoặc dán địa chỉ ví của bạn",
        title19: "Vui lòng nhập số tiền rút",
        title20: "phí xử lý",
        title21: "Số tiền thực tế nhận được",
        table1: "Tiền tệ",
        table2: "Số xu được rút",
        table3: "Tên dây chuyền",
        table4: "địa chỉ ví",
        table5: "tiểu bang",
        table6: "lý do",
        table7: "thời gian nộp đơn",
        table8: "Rút xu",
        table81: "Rút xu",
        table9: "Địa chỉ rút tiền",
        table10: "Vui lòng chọn loại tiền bạn muốn rút",
        table11: "Hãy chắc chắn rằng bạn nhập",
        table12: 'Địa chỉ (thường bắt đầu bằng "0x")',
        table13: "Các loại tiền có thể rút (đã khấu trừ phí xử lý):",
        table14: "Chọn loại tiền tệ",
        table15: "Gặp phải vấn đề? Phản hồi ngay lập tức",
        table16: "THĂNG BẰNG",
        table17: "Chi tiết rút tiền",
        password: "mật khẩu",
        passwordTips: "Xin vui lòng nhập mật khẩu",
        passwordAg: "Xác nhận mật khẩu",
        passwordAgTips: "Vui lòng nhập lại mật khẩu",
        oldPassword: "Mật khẩu cũ",
        oldPasswordTips: "Hãy nhập mật khẩu cũ",
        newPassword: "mật khẩu mới",
        newPasswordTips: "Vui lòng nhập mật khẩu mới",
        passwordNotSame: "Mật khẩu không nhất quán",
        withdrawPasswordSet: "Đặt mật khẩu rút tiền",
        withdrawPasswordModify: "Thay đổi mật khẩu rút tiền",
        withdrawPassword: "Rút mật khẩu",
        withdrawPasswordTips: "Vui lòng nhập mật khẩu rút tiền",
        setWithdrawPasswordFirst: "Vui lòng đặt mật khẩu rút tiền trước",
        withdrawPasswordSetSuccess: "Thiết lập thành công",
        withdrawPasswordModifySuccess: "Đã sửa đổi thành công",
        submit: "nộp",
    },
    customer: {
        content1: "dịch vụ trực tuyến",
        content2: "dịch vụ khách hàng",
        content3: "Vui lòng điền thông tin liên lạc của bạn",
        content4: "Vui lòng nhập nội dung",
        content5: "gửi",
        content6: "Gửi không thành công, kết nối mạng không thành công",
        content7: "bức ảnh",
        content8: "tin nhắn trống rỗng",
        content9: "chia sẻ của tôi",
        content10: "chia sẻ đường link",
        content11: "lưu hình ảnh",
        content12: "Thu nhập của tôi",
        content13: "Mô tả cấp độ",
        content14: "Kích thước nhóm",
        content15: "thu nhập lời mời",
        content16: "Mô tả quy tắc",
        content17: "cấp độ thành viên",
        content18: "Số tiền nạp lại lớn hơn",
        content19: "Cấp độ đội",
        content20: "Cấp độ đội & thu nhập",
        content21: "Để đáp ứng điều kiện",
        content22: "Thu nhập phân phối",
        content23: "đội cấp",
        content24: "cấp độ, lời mời",
        content25: "thành viên hợp lệ",
        content26: "thu nhập ban đầu",
        content27: "Thu nhập cấp hai",
        content28: "Thu nhập cấp 3",
    },
    standardContract: {
        increaseMargin: "tăng ký quỹ",
        increaseAmount: "tăng số lượng",
        Pleaseenterthemargin: "Vui lòng nhập số tiền ký quỹ cần tăng",
        Pleaseenterthecorrect: "Vui lòng nhập đúng số tiền gửi",
        Increasemarginsuccessfully: "Tăng ký quỹ thành công",
        takeProfitPriceWrong: "Sai giá chốt lời",
        stoppriceerror: "Lỗi dừng giá",
        thetakeprofit1: "Giá chốt lời phải cao hơn giá thị trường",
        thetakeprofit2: "Giá dừng lỗ phải thấp hơn giá thị trường",
        thetakeprofit3: "Giá chốt lời phải thấp hơn giá thị trường",
        thetakeprofit4: "Giá dừng lỗ phải cao hơn giá thị trường",
        thetakeprofit5: "sai số lượng",
        estimatedprofitloss: "Ước tính lãi lỗ",
        positionAmount: "Số lượng vị trí",
        closeAmount: "Số lượng đóng",
        whenthelatest: "Khi đạt đến mức giá mới nhất",
        whenthelatest2: "Khi đó, lệnh chốt lời theo giá thị trường sẽ được kích hoạt để đóng vị thế hiện tại. Lợi nhuận và lỗ dự kiến",
        whenthelatest3: ", lệnh dừng lỗ thị trường sẽ được kích hoạt để đóng vị thế hiện tại. Lợi nhuận và lỗ dự kiến",
        contract: "hợp đồng",
        ordercanceledsuccessfully: "Đơn hàng đã được hủy thành công",
        successfullyordered: "đặt hàng thành công",
        positionopeningreminder: "Lời nhắc mở vị thế",
        wrongquantity: "sai số lượng",
        quantitycannot: "Số lượng không thể lớn hơn",
        modificationstoploss: "Sửa đổi dừng lãi và dừng lỗ thành công",
        positionclosedsuccessfully: "Vị thế đã đóng thành công",
        orderCancellation: "Hủy đơn hàng",
        orderCancellation2: "Bạn có chắc chắn muốn hủy đơn hàng không?",
        tobeexecuted: "Để được thực thi",
        expired: "hết hạn",
        failed: "thất bại",
        contractTransaction: "Vĩnh viễn",
        increase: "Tăng",
        openPosition: "Kho đầy đủ",
        isolatedPosition: "Vị trí biệt lập",
        limitOrder: "giá giới hạn",
        marketOrder: "Giá thị trường",
        pleaseSelectLeverage: "Vui lòng chọn đòn bẩy",
        pleaseEnterQuantity: "Vui lòng nhập số lượng",
        canOpenMore: "Vị trí tối đa có thể được mở",
        contractAmount: "Giá trị hợp đồng",
        openingFee: "phí xử lý",
        currentPosition: "Chức vụ",
        historicalPositions: "Giao dịch lịch sử",
        currentCommission: "Thứ tự hiện tại",
        historicalCommission: "ủy ban lịch sử",
        buyPrice: "giá mua",
        income: "lợi nhuận",
        quantity: "Số lượng",
        marginRate: "tỷ lệ ký quỹ",
        initialMargin: "Ký quỹ ban đầu",
        estimatedPriceParity: "Giá thanh lý bắt buộc",
        operate: "vận hành",
        finishQuantity: "Khối lượng giao dịch",
        averagePrice: "giá giao dịch trung bình",
        pleaseEnterLeverage: "Vui lòng nhập đòn bẩy",
        takeProfitAndStopLoss: "TP&SL",
        takeProfit: "ngừng lãi",
        stopLoss: "dừng lỗ",
        openLong: "Long",
        openShort: "Short",
        liquidation: "Đóng vị thế",
        marketLquidation: "Giá thị trường ổn định",
        cancel: "Hủy lệnh",
        enterBuyPrice: "Vui lòng nhập giá mua",
        areYouSureLquidation: "Bạn có chắc chắn đóng vị thế không?",
        confirm: "Chắc chắn",
        enterStopWinPrice: "Vui lòng nhập giá chốt lời",
        enterStopLossPrice: "Vui lòng nhập giá dừng lỗ",
        commissionTime: "Thời gian hoa hồng",
        commissionPrice: "Giá hoa hồng",
        commissionQuantity: "Tổng số tiền hoa hồng",
        volumeAlreadyTraded: "Số tiền đã hoàn thành",
        areYouSureToWinOrLoss: "Bạn có chắc chắn muốn dừng lãi và dừng lỗ không?",
        maxQuantity: "Vị trí tối đa",
        saleQuantity: "Vị thế đã đóng",
        salePrice: "Giá đóng cửa bình quân",
        saleTime: "时间",
        succeeded: "thành công rồi",
        cancelled: "Đã hủy",
        piece: "mở",
        triggerPrice: "kích hoạt giá",
        setWin: "Đặt chốt lời",
        setLoss: "Đặt điểm dừng lỗ",
        enterWinQuantity: "Vui lòng nhập số tiền lãi",
        enterLossQuantity: "Hãy nhập số tiền dừng lỗ",
        planCommission: "Ủy ban Kế hoạch",
        enterWinTriggerPrice: "Vui lòng nhập giá kích hoạt chốt lời",
        enterLossTriggerPrice: "Vui lòng nhập giá kích hoạt dừng lỗ",
        insufficientQuantity: "Không đủ số lượng",
        enterTriggerPrice: "Vui lòng nhập giá kích hoạt",
        tips1: "Giá đặt lệnh của bạn cao hơn giá hiện tại và sẽ được thực hiện trực tiếp theo giá thị trường hiện tại, bạn có chắc chắn muốn mua không?",
        tips2: "Giá đặt lệnh của bạn thấp hơn giá hiện tại và sẽ được thực hiện trực tiếp theo giá thị trường hiện tại, bạn có chắc chắn mua không?",
        type: "kiểu",
        increaseQuantity: "Tăng vị trí",
        reduceQuantity: "Giảm vị trí",
        limitCommission: "lệnh giới hạn",
        tips3: "Giá dừng lỗ phải thấp hơn giá thị trường",
        tips4: "Giá chốt lời phải cao hơn giá thị trường",
        tips5: "Giá dừng lỗ phải cao hơn giá thị trường",
        tips6: "Giá chốt lời phải thấp hơn giá thị trường",
        tips7: "Giá dừng lỗ phải thấp hơn giá thị trường và thấp hơn giá đặt lệnh",
        tips8: "Giá chốt lời phải lớn hơn giá đặt lệnh (có thể nhỏ hơn giá thị trường)",
        tips9: "Giá dừng lỗ phải lớn hơn giá thị trường và lớn hơn giá đặt lệnh",
        tips10: "Giá chốt lời phải nhỏ hơn giá đặt lệnh (có thể lớn hơn giá thị trường)",
        accountEquity: "Số dư tài khoản",
        unrealizedPL: "lãi hoặc lỗ chưa thực hiện",
        tips11: "Bạn có chắc là bạn đúng không",
        tips12: "Giá thị trường",
        tips13: "Đóng tất cả các vị thế",
        tips14: "Đừng nhắc lại",
        profitRate: "Tỷ suất lợi nhuận đóng cửa",
        openTime: "Thời gian mở cửa",
        unwindTime: "Thời gian đóng cửa",
        dealTime: "Thời gian giao dịch",
        amountClose: "Số lượng vị trí đóng",
        long: "nhiều",
        short: 'không',
        orderQuantity: "Số lượng đặt hàng",
        entrustedMargin: "ký quỹ ủy thác",
    },
    spotGoods: {
        spotGoods: "flash",
        sellPrice: "gia ban",
        orderRecord: "Bản ghi đổi quà flash",
        transactionTime: "Thời gian giao dịch",
        maxBuy: "có sẵn để mua",
        maxSell: "Có sẵn để bán",
        purchaseQuantity: "Số lượng mua",
        slippage: "Trượt giá",
        convertTips: "Chúng tôi sẽ chốt giao dịch ở mức giá thuận lợi nhất với độ trượt giá từ 0-5%.",
        changePrice: "Giá trao đổi hiện tại",
        consume: "tiêu thụ",
        get: "lấy",
        changeTips: "Bạn có chắc chắn muốn đổi quà không?",
        confirm: "Xác nhận đổi thưởng"
    },
    news: {
        title: "Trung tâm Tin tức",
        redMore: "đọc thêm",
    },
    credit: {
        credit: "điểm tín dụng",
        creditScore: "tổng điểm tín dụng",
        latestEntry: "kỷ lục mới nhất",
        liveUpdate: "Cập nhật trực tiếp",
        systemEvaluation: "Đánh giá hệ thống",
        time: "ngày",
        name: "tên",
        reason: "lý do",
        scoreStory: "Kỷ lục điểm tín dụng"
    },
    borrowing: {
        borrowing: "Kỷ lục điểm tín dụng",
        diversifiedMarket: "Thị trường đa dạng",
        totalSupply: "Tổng cung",
        totalBorrowings: "tổng số tiền vay",
        tips1: "Đây là tổng số tiền bạn có thể vay bằng tài sản thế chấp của mình, tối đa đến giới hạn vay.",
        tips2: "Lãi suất ổn định của bạn sẽ được giữ nguyên trong suốt thời gian vay. Được đề xuất cho nguồn cung cấp dài hạn và người dùng thích khả năng dự đoán.",
        totalSupplys: "Tổng cung",
        supplyAPR: "tỷ lệ cung cấp",
        totalBorrowings: "Tổng số tiền vay",
        borrowAPR: "lãi suất vay",
        check: "Kiểm tra",
        market: "chợ",
        availableLiquidity: "Thanh khoản sẵn có",
        currencyPrice: "giá tiền tệ hiện tại",
        supplyAssets: "cung cấp tài sản",
        tips3: "Lưu ý: Lãi suất bên dưới là lãi suất cố định, sau khi hết thời gian cung cấp, tiền gốc cộng với thu nhập sẽ được trả về tài khoản của bạn.",
        supplyCycle: "chu kỳ cung cấp",
        minimumSupply: "nguồn cung tối thiểu",
        supply: "cung cấp",
        borrowedAssets: "tài sản đi vay",
        tips4: "Điều quan trọng cần biết: Để vay tiền, bạn cần cung cấp mọi tài sản thế chấp hoặc bằng chứng,",
        tips5: "Vui lòng xem mô tả tài sản thế chấp.",
        repaymentCycle: "chu kỳ trả nợ",
        BorrowableAmount: "Số tiền vay có sẵn",
        borrow: "khoản vay",
        accountInformation: "thông tin tài khoản",
        walletBalance: "số dư ví",
        canSupply: "Có sẵn",
        AmountCanBeBorrowed: "Tổng số tiền cho vay hiện có",
        borrowedAmount: "Số tiền vay",
        remainingAmount: "số dư còn lại",
        getBorrowednAmount: "Nhận giới hạn cho vay",
        supplyOrder: "lệnh cung cấp",
        borrowedOrder: "lệnh vay",
        supplyAmount: "lượng cung cấp",
        expectedProfits: "Lợi nhuận kỳ vọng",
        supplyTime: "thời gian cung cấp",
        expirationTime: "Hạn sử dụng",
        borrowingAmount: "Số tiền vay",
        dueRepayment: "Số tiền trả nợ",
        borrowingTime: "Thời gian phát hành",
        repaymentTime: "Thời gian trả nợ",
        repay: "trả nợ",
        supplyCurrency: "cung cấp tiền tệ",
        buyNow: "Mua ngay",
        supplyRecords: "hồ sơ cung cấp",
        borrowRecords: "Hồ sơ vay vốn",
        completed: "hoàn thành",
        borrowNow: "Vay ngay bây giờ",
        borrowCurrency: "Vay tiền tệ",
        borrowMin: "nhỏ nhất",
        borrowMax: "tối đa",
        borrowRange: "Số tiền vay (tối thiểu/tối đa)",
        tips6: "Vui lòng hoàn trả đúng hạn, nếu quá hạn sẽ bị tính thêm số tiền (gốc + lãi) hàng ngày.",
        tips7: "Phí quá hạn.",
        pleaseEnterBorrowAmount: "Vui lòng nhập số tiền vay",
        interest: "Quan tâm",
        tips8: "Bạn có chắc chắn muốn trả nợ trước không?",
        borrowChannel: "Kênh vay",
        tips9: "Hãy bắt đầu bằng việc làm quen với bạn",
        name: "tên",
        lastName: "họ",
        birthDate: "ngày sinh",
        tips10: "Thêm một chút thông tin, địa chỉ của bạn là gì?",
        familyAddress: "Địa chỉ nhà (bao gồm số căn)",
        city: "Thành phố",
        country: "Quốc gia",
        postalCode: "mã bưu điện",
        pleaseEnterYourHomeAddress: "Vui lòng nhập địa chỉ nhà của bạn",
        tips11: "Hãy cho chúng tôi biết về tình hình tài chính của bạn",
        tips12: "Chúng tôi có thể cần xác nhận thông tin này sau, vì vậy vui lòng cố gắng chính xác nhất có thể",
        yourMonthlyIncome: "thu nhập hàng tháng của bạn",
        enterYourMonthlyIncome: "Vui lòng nhập thu nhập hàng tháng của bạn",
        extraIncomeOptional: "Thu nhập bổ sung (tùy chọn)",
        enterExtraIncome: "Vui lòng nhập thu nhập bổ sung của bạn",
        monthlyRentOrMortgagePayment: "Tiền thuê nhà hàng tháng hoặc thanh toán thế chấp",
        enterMonthlyRent: "Vui lòng nhập tiền thuê nhà hàng tháng hoặc khoản thanh toán thế chấp",
        tip13: "Hãy cho chúng tôi biết nếu bạn có thế chấp",
        tip14: "Nếu không chọn bảo lãnh thế chấp thì khả năng vượt qua được việc xem xét là thấp.",
        isThereMortgage: "Có thế chấp không?",
        selectIsThereMortgage: "Vui lòng chọn có thế chấp hay không",
        selectMortgageType: "Vui lòng chọn loại thế chấp",
        yes: "Đúng",
        no: "KHÔNG",
        uploadScreenshotOfTransaction: "Vui lòng tải lên ảnh chụp màn hình giao dịch",
        tip15: "Hãy giữ liên lạc",
        tip16: "Chúng tôi có thể liên hệ với bạn theo số này nếu chúng tôi cần thêm thông tin về đơn xin vay của bạn",
        tip17: "Tôi đã đọc và ký điện tử sự đồng ý của tôi",
        tip18: "Điều khoản sử dụng và sự đồng ý điện tử",
        tip19: ", bao gồm sự đồng ý của bạn để chúng tôi gửi cho bạn thông tin về",
        tip20: "Điều khoản sử dụng sản phẩm và dịch vụ",
        tip21: "Sau khi điền thông tin các bạn vui lòng chờ hệ thống đánh giá và xem xét.",
        phoneNumber: "số điện thoại",
        enterPhoneNumber: "Xin vui lòng điền số điện thoại của bạn",
        tip22: "Vui lòng chọn hộp để đồng ý với các điều khoản sử dụng và mẫu chấp thuận điện tử",
        inReview: "đang được xem xét",
        applicationAmount: "số tiền ứng dụng",
        reasonForRejection: "Lý do từ chối",
        reapply: "Đăng ký lại",
        applyStatus: "trạng thái ứng dụng",
        currentOrder: "trật tự hiện tại",
        interestRate: "lãi suất"
    },
    yuebao: {
        filed1: "đầu tư",
        filed2: "đưa ra",
        filed3: "Thu nhập hàng ngày",
        filed4: "tăng tốc đội",
        filed5: "Đã gửi tiền",
        filed6: "Thu nhập của ngày hôm qua",
        filed7: "Tổng doanh thu",
        filed8: "Tiền khả dụng",
        filed9: "Vui lòng nhập số tiền",
        filed10: "Khai thác thanh khoản",
        filed11: "Giới thiệu",
        field12: "Giá mua tối thiểu",
        field13: "Nhiều nhất bạn có thể mua",
        filed14: "Đăng ký",
        filed15: 'thường xuyên',
        filed16: 'lãi suất hàng năm',
    },
    tipsPopover: {
        title: "Cài đặt ứng dụng này",
        press: "dựa theo",
        and: "Và",
        scrren: "Thêm vào màn hình chính",
        hasInstall: "(Nếu đã cài đặt thì hãy đóng cửa sổ lại)",
    },
    follow: {
        title1: "Kế hoạch theo dõi",
        follow_text1: "Bắt đầu một đơn đặt hàng tiếp theo",
        follow_text2: "Lịch sử phát hành",
        follow_text3: "tiêu đề",
        follow_text4: 'cặp giao dịch',
        follow_text5: 'Thời hạn mua',
        follow_text6: 'thời gian phục vụ',
        follow_text7: 'thời gian phát hành',
        follow_text8: 'Số lượng người theo dõi',
        follow_text9: 'Số lượng đơn đặt hàng',
        follow_text10: 'vận hành',
        follow_text11: 'mời',
        follow_text12: 'Làm theo hướng đặt hàng',
        follow_text13: 'Đặt điều kiện lệnh',
        follow_text14: 'Điều kiện để thực hiện các lệnh sau',
        follow_text15: 'số tiền cố định',
        follow_text16: 'phần trăm',
        follow_text17: 'Người được mời',
        follow_text18: 'Id của bên kia',
        follow_text19: "Xác nhận thực hiện theo đơn đặt hàng",
        follow_text20: "Số tiền đặt hàng của bạn (tùy chọn)",
        follow_text21: "vui lòng nhập tiêu đề",
        follow_text22: "Vui lòng chọn một cặp giao dịch",
        follow_text23: "Vui lòng chọn hướng đặt hàng",
        follow_text24: "Vui lòng chọn thời gian mua hàng",
        follow_text25: "Vui lòng chọn thời gian đặt hàng",
        follow_text26: "Vui lòng chọn số lượng mua hàng",
        follow_text27: "Số lượng mua tối đa không được nhỏ hơn số lượng mua tối thiểu",
        follow_text28: "Mua vào tối thiểu",
        follow_text29: "Mua nhiều nhất",
        follow_text30: "Tạo lệnh sao chép thành công",
        follow_text31: "Danh sách cặp giao dịch",
        follow_text32: "Đã làm theo đơn đặt hàng",
        follow_text33: "Vui lòng nhập thông tin người được mời",
        follow_text34: "Được mời",
        follow_text35: "mời tôi",
        follow_text36: "Sao chép lịch sử",
        follow_text37: "Phạm vi số lượng đặt hàng"
    },
    followHistory: {
        title1: 'Lịch sử phát hành',
        title2: "Sao chép lịch sử",
        follow_h_text1: "Tổng số tiền của đơn đặt hàng",
        follow_h_text2: "Tổng số người theo đơn hàng",
        follow_h_text3: "Số lượng phát hành",
        follow_h_text4: "tỷ lệ thắng",
        follow_h_text5: "Số lần thực hiện mệnh lệnh",
        follow_h_text6: "Lợi nhuận từ việc theo dõi đơn hàng",
        follow_h_text7: "phương hướng",
        follow_h_text8: "thắng",
        follow_h_text9: "thâm hụt",
    },
    "asset.index.1579E8B41F8D5134": "tài sản của tôi",
    "asset.index.B3F6F05Dfbe431Cd": "mô phỏng",
    "asset.index.B9Aeab4Ee8F33746": "Định giá tài sản",
    "asset.index.583Df8Bd541Eb7E8": "xu hướng thu nhập",
    "asset.index.B1254815D133Cc26": "7 ngày",
    "asset.index.2F615F6C029F01B7": "30 ngày",
    "asset.index.29439Fb58E4Bbe22": "rút",
    "asset.index.831Ab31568A78285": "nạp tiền",
    "asset.index.39F7A0D48D12E8C9": "chuyển khoản",
    "asset.index.B99063C5689Beaec": "flash",
    "asset.index.E1Dc620F2F038B2A": "Nhận tài sản",
    "asset.index.972A0B4C2B4393D0": "tài khoản của tôi",
    "asset.index.9Dfb0Fb334620733": "Số dư khả dụng",
    "asset.index.Cdc5Fbd26790Cb47": "số tiền đông lạnh",
    "asset.index.3D8132D72Ae965Cd": "Định giá",
    "asset.receive.825503Cc430559Cb": "có được tài sản",
    "asset.receive.54154A0553A535B7": "Nội dung demo",
    "asset.receive.F2E57B00A9C5D129": "1.Tài sản này chỉ được phép sử dụng ở chế độ tài khoản demo",
    "asset.receive.Ea0D1D52989299Bd": "2.Để ngăn chặn sự lạm dụng, chỉ được phép quy đổi một lần trong vòng một tháng.",
    "asset.receive.090E0037Aa235D3D": "3.Việc nhận tài sản sẽ cộng thêm số lượng tài sản nhận được trên cơ sở ban đầu và sẽ không thay đổi trạng thái vị thế.",
    "asset.receive.7Fd4E1Abc805C0A6": "4.Nếu bạn có bất kỳ câu hỏi nào khác, vui lòng liên hệ với bộ phận chăm sóc khách hàng",
    "asset.receive.Df5B3A511D986575": "Nhận nội dung demo ngay bây giờ",
    "asset.records.1Fa02E8187A39592": "ba tháng qua",
    "asset.records.7B291C37333Fe34D": "từ gần đến xa",
    "asset.records.A476Cd1Ec868Fc5F": "ba tháng qua,",
    "asset.records.9D5755A842E55Bb7": "từ gần đến xa,",
    "asset.records.E3Fa05E607203Cdc": "nạp tiền",
    "asset.records.B4F206C6Bec08Dea": "rút",
    "asset.index.Df5B3A511D986576": "Bạn đã nhận thành công tài sản demo",
    "deposit.depositSpeedy.Efe81D0E0174239B": "phương thức thanh toán",
    "deposit.depositSpeedy.B9293Ee96E435F85": "Tiền tệ pháp định",
    "deposit.depositSpeedy.16658A855F07D171": "Số lượng",
    "deposit.depositSpeedy.204Ffab8A6E01870": "Chắc chắn",
    "deposit.depositSpeedy.22E5Dfb74Ead8Aa9": "Không thể trống",
    "deposit.depositSpeedy.B8E774D0E4E5E4C1": "Nạp tiền tối thiểu",
    "deposit.depositSpeedy.F169D816F6Dd83C5": "Nạp tối đa",
    "deposit.depositWallet.F64Ec60B1Db4A6D6": "Gửi USDT",
    "deposit.depositWallet.551640C76F4Ca4A1": "Nạp tiền",
    "deposit.depositWallet.B404C543712B71D5": "Mạng nạp tiền",
    "deposit.depositWallet.A484B0F5E94B3F4F": "Địa chỉ gửi tiền",
    "deposit.depositWallet.D3980D54Acf242Db": "Lưu mã QR",
    "deposit.depositWallet.A06E84801D6582Be": "Số tiền nạp",
    "deposit.depositWallet.Daaaeb1B7B22B126": "gợi ý",
    "deposit.depositWallet.D599E40E1Eeec1B3": "Nạp tiền thành công",
    "deposit.depositWallet.4F48Abbafce532Bb": "Đã gửi thành công, đang chờ dịch vụ khách hàng xem xét",
    "deposit.depositWallet.A88C481228811696": "Bạn đã từ chối chuyển mạng",
    "deposit.depositWallet.C6Ebf27895C21932": "Vui lòng mở plug-in ví TRON theo cách thủ công và đăng nhập",
    "deposit.index.B5491C8Dd09D8183": "Chọn loại tiền bạn muốn nạp",
    "deposit.index.2F784B2298D39987": "Nạp tiền nhanh",
    "deposit.index.0Daa03Dcdcabd837": "Liên kết ngoại vi",
    "withdraw.bottomSheet.628A63056Ef2D2Fe": "Xác nhận rút tiền",
    "withdraw.bottomSheet.A086Fa20082C849D": "Vui lòng đảm bảo thông tin bên dưới là chính xác",
    "withdraw.bottomSheet.Bf8B184D32971501": "Mạng rút tiền",
    "withdraw.bottomSheet.8758Fd50C87D6C9C": "Địa chỉ",
    "withdraw.bottomSheet.F9Db93B87E08763B": "Số lượng",
    "withdraw.bottomSheet.4Ad26475002878C0": "phí xử lý",
    "withdraw.index.A96454041169Af6D": "Chọn loại tiền tệ",
    "withdraw.index.2A563B7081Dd5B83": "Địa chỉ rút tiền",
    "withdraw.index.B189C2Ef713Cfff4": "Số lượng xu được rút",
    "withdraw.index.558F35140E84B562": "Có sẵn:",
    "withdraw.index.F4E759087E12Cb77": "Rút tất cả",
    "withdraw.index.5Cbd9A6B284D25Ae": "Mật khẩu quỹ",
    "withdraw.index.B89Cbe4Bfc33Bb41": "phí xử lý:",
    "withdraw.index.C886De8D8A27026E": "Thông báo quan trọng",
    "withdraw.index.Eac7C5A4095F5606": "1.Để ngăn chặn chênh lệch giá, bạn có thể đăng ký rút tiền khi khối lượng giao dịch đạt đến hạn mức.",
    "withdraw.index.C3Cc7Fff24504Be9": "2.Đơn xin rút tiền sẽ được ghi có trong vòng 24 giờ sau khi gửi. Nếu không nhận được tiền rút sau thời gian rút tiền dự kiến, vui lòng liên hệ với bộ phận dịch vụ khách hàng trực tuyến.",
    "withdraw.index.0B4Ad7Ccdc833B0D": "3.Sau khi gửi đơn đăng ký rút tiền, tiền sẽ ở trạng thái đóng băng vì quá trình rút tiền đang diễn ra và tiền tạm thời bị hệ thống giữ, điều đó không có nghĩa là bạn đã mất tài sản hoặc có sự bất thường với tài sản.",
    "withdraw.index.4639F3A6E07C00B3": "Bước tiếp theo",
    "withdraw.index.81Fba0D65Cc5C3Aa": "Vui lòng nhập hoặc dán địa chỉ ví của bạn",
    "withdraw.index.25768141C8Bdea91": "Vui lòng nhập số tiền rút",
    "withdraw.index.97A85734Cd1B7Bf0": "Vui lòng nhập mật khẩu quỹ",
    "withdraw.index.39C7D74B33241C78": "Số tiền rút không đúng",
    "withdraw.index.86A5D17908197525": "Xác nhận rút tiền, chờ xem xét để giải phóng tiền)",
    "withdraw.index.48Fb603C6638C7F6": "Vui lòng đặt mật khẩu quỹ",
    "withdraw.index.873Ade9A3Bacc2F7": "gợi ý",
    "bill.index.5Bbb2A6Ac68D6B2E": "tài khoản thực tế",
    "bill.index.75751E0Eb8261C40": "tài khoản demo",
    "bill.index.Cbc853B37Ecc2E97": "Loại thay đổi tài khoản",
    "bill.index.4A0Cacddeb2E38B1": "Kiểu tài khoản",
    "bill.index.F16217028E098B2F": "Tiền tệ",
    "bill.index.A620Dbe6F06B78Ba": "Các loại",
    "bill.index.2C3C148Bb94D9B3F": "Tất cả các tài khoản",
    "c2C.index.Ab45695E4Bf1E382": "Giao dịch C2C",
    "c2C.index.2702Dc911Dfea762": "tôi muốn mua",
    "c2C.index.D737D5B8367A53C4": "tôi muốn bán",
    "c2C.index.C0C540A3Ba9Abe01": "Âm lượng",
    "c2C.index.D8644E8D60C8Bd8A": "đơn giá",
    "c2C.index.Ebb8670B203D3604": "giới hạn",
    "c2C.index.F8C6245A2Ebf1D27": "Tất cả các thẻ ngân hàng",
    "c2C.index.F8C6245A2Ebf1D28": "thu tiền tệ",
    "c2C.index.F8C6245A2Ebf1D29": "Thẻ ngân hàng",
    "c2C.index.Cc3Aa8141Da9Ec9E": "Mua",
    "c2C.index.Cc3Aa8141Da9Ec9F": "bán",
    "c2C.index.67A39834582Ad99A": "Không có dữ liệu",
    "c2C.order.04C23810B47F77F7": "theo số lượng",
    "c2C.order.6905365902E0A73D": "theo số lượng",
    "c2C.order.3F0Ecebbd9D2F23B": "Có sẵn",
    "c2C.order.16853Bda54120Bf1": "tất cả",
    "c2C.order.Ad3335796F2C95F7": "số tiền một lần",
    "c2C.order.61092E27A6879318": "Để bảo vệ sự an toàn của tài sản, các bạn hãy nâng cao ý thức phòng ngừa nhé!",
    "c2C.order.0F51881F0Fb920B2": "Thông tin giao dịch",
    "c2C.order.Dd74Ceca85E5Bc65": "Thời hạn thanh toán",
    "c2C.order.De3341388A59073F": "30 phút",
    "c2C.order.D334B597A352167F": "Biệt hiệu của người bán",
    "c2C.order.D334B597A352167G": "Biệt hiệu của người mua",
    "c2C.order.9D5F89F99Ced890D": "phương tiện giao dịch",
    "c2C.order.8Adf755B14Bc6B7C": "Vui lòng đọc phần sau trước",
    "c2C.order.686B16F454A7338B": "Không đưa ra bất kỳ nhận xét nào khi thực hiện chuyển khoản bằng thẻ ngân hàng, nếu không tiền sẽ không được giải phóng và tài khoản sẽ bị khóa trực tiếp. Sau khi thanh toán, bạn cần cung cấp sơ đồ chi tiết giao dịch mới sau khi thanh toán (nếu sơ đồ P hoặc chi tiết giao dịch ẩn được báo cáo lên nền tảng để đóng băng tài khoản)",
    "c2C.order.9Bec8Fba867B739B": "Chọn phương thức thanh toán",
    "c2C.order.Cf2D892C419Fea36": "mua tối thiểu",
    "c2C.order.7290A6B7456A03E0": "mua lớn nhất",
    "c2C.orderConfirm.F7E99B8C203E2B07": "chi tiết đặt hàng",
    "c2C.orderConfirm.036D94Dc2D1Cf7D3": "Đơn hàng đã được tạo",
    "c2C.orderConfirm.0F5433503F3845Ce": "Vui lòng thanh toán cho người bán trong thời gian đếm ngược.",
    "c2C.orderConfirm.24C3Bcacdb85A451": " Chờ người mua thanh toán ",
    "c2C.orderConfirm.8E8140D7Ab607B24": "liên hệ với người bán",
    "c2C.orderConfirm.8E8140D7Ab607B25": "liên hệ với người mua",
    "c2C.orderConfirm.Ec4D8722Ec15C813": "Tiền kỹ thuật số của người bán hiện đã được gửi vào tài khoản ký quỹ, vui lòng thanh toán  ",
    "c2C.orderConfirm.69D1Fbbc7700C1Ab": "Tên thật của người mua:",
    "c2C.orderConfirm.E5Ed8Ec53Edcd5D3": "Hỗ trợ dịch vụ khách hàng 7x24 giờ ",
    "c2C.orderConfirm.Abf9F4B8C93D641E": "số thứ tự",
    "c2C.orderConfirm.726C51702F70C486": "thời gian sáng tạo",
    "c2C.orderConfirm.E097Bb9B1216Bfd8": "liên hệ với dịch vụ khách hàng",
    "c2C.orderConfirm.103408Fbbe30287F": "hủy đơn hàng",
    "c2C.orderConfirm.4E93Df1Fb521F4Ba": "Tôi đã xác nhận thanh toán",
    "c2C.orderConfirm.3B5418Dbea7Ebfe4": "Đã thanh toán",
    "c2C.orderConfirm.387Ca3625B260E4E": "Đơn hàng đã thanh toán của bạn",
    "c2C.orderConfirm.7C83D5F1Db1A08B7": "hủy giao dịch",
    "c2C.orderConfirm.76C86566Cc3751C0": "Lệnh hủy",
    "c2C.orderConfirm.205B349751D176B4": "thanh toán thành công",
    "c2C.paymentAdd.6F2A7Dcc66E6Acba": "phương thức thanh toán",
    "c2C.paymentAdd.C87E865Df6800304": "Cấu hình phương thức thanh toán",
    "c2C.paymentAdd.B59Ae0F1B1C235Df": "Loại phương thức thanh toán",
    "c2C.paymentAdd.06910Bddf002695F": "tên thật",
    "c2C.paymentAdd.3E33Ae3Efa7507Dd": "Tài khoản Paypal",
    "c2C.paymentAdd.3E33Ae3Efa7507De": "Số tài khoản ngân hàng/số thẻ",
    "c2C.paymentAdd.3E33Ae3Efa7507Df": "Tên tiền tệ",
    "c2C.paymentAdd.595Ede91Abf6F6E0": "Thanh toán mã QR",
    "c2C.paymentAdd.1Ecc8Be9Ba1C940A": "Bấm để tải lên",
    "c2C.paymentAdd.6Daae340F2E9718A": "tên ngân hàng",
    "c2C.paymentAdd.347751415C3Da489": "Tên chuỗi",
    "c2C.paymentAdd.15B3627Faddccb1D": "Nhận xét",
    "c2C.paymentAdd.86Da96Cbd274058F": "Lời nhắc ấm áp: Khi bạn bán tiền kỹ thuật số, phương thức thanh toán bạn chọn sẽ được hiển thị cho người mua. Vui lòng xác nhận rằng thông tin được điền chính xác.",
    "c2C.paymentAdd.4Ce9979Bfb6576D9": "cứu",
    "c2C.paymentAdd.90D2A3A5C07179C0": "vui lòng nhập tên thật của bạn",
    "c2C.paymentAdd.A3B0Cd3Ad9Ca3475": "Vui lòng nhập của bạn",
    "c2C.paymentAdd.Ec389E6190Eedb62": "Vui lòng nhập tên ngân hàng của bạn",
    "c2C.paymentAdd.9Be51Fb1E9025Fb2": "Vui lòng nhập tên chuỗi của bạn",
    "c2C.paymentAdd.94F034F0E01439E0": "Vui lòng nhập địa chỉ của bạn",
    "c2C.paymentAdd.B12E035663A42B32": "Tất cả các thẻ ngân hàng",
    "c2C.paymentAdd.C7B677F393A8F8Ac": "thu tiền tệ",
    "c2C.paymentAdd.Abafe11737Bab7Cd": "Vui lòng tải lên mã thanh toán",
    "c2C.paymentAdd.07C443212Ff04Bfb": "Thêm thành công",
    "c2C.paymentDetail.86Da96Cbd274058F": "Lời nhắc ấm áp: Khi bạn bán tiền kỹ thuật số, phương thức thanh toán bạn chọn sẽ được hiển thị cho người mua. Vui lòng xác nhận rằng thông tin được điền chính xác.",
    "c2C.paymentDetail.C4C613Da60226De4": "Đã sửa đổi thành công",
    "c2C.paymentMethod.7609F7C8337C7A17": "Phương thức thanh toán C2C",
    "c2C.records.9E36893D5736Abb4": "Danh sách đặt hàng",
    "c2C.records.4B53F9A5B13B3235": "待付款",
    "c2C.records.4B53F9A5B13B3236": "hủy giao dịch",
    "c2C.records.4B53F9A5B13B3237": "Đã thanh toán",
    "contract.index.9706Df877458F0Be": "Kho đầy đủ",
    "contract.index.623Fc7D3D40Aa479": "Ký quỹ ban đầu",
    "contract.index.F92Dce1169330387": "Giá thị trường ngang bằng",
    "contract.index.3598B97Dd28Dbf20": "Vui lòng nhập số lượng (cái)",
    "contract.index.6E1Fae821Dc0B797": "Bền vững",
    "contract.index.Eb07Eb0C37C14575": "xe đạp",
    "contract.index.Eb6492F19B1C1Fb6": "Chi tiết giao dịch lịch sử",
    "contract.index.873Ade9A3Bacc2F7": "gợi ý",
    "contract.index.Ff63B0F93D39F50B": "Bạn có chắc là bạn đúng không",
    "contract.index.Ff63B0F93D39F50D": "Đi lâu",
    "contract.index.Ff63B0F93D39F50E": "ngắn",
    "contract.index.Ff63B0F93D39F50F": "chỉ đạo",
    "contract.index.Ff63B0F93D39F50G": "Đóng tất cả các vị thế?",
    "customer.dialog.3D410C88E8A24Fe8": "Xác nhận biệt hiệu",
    "customer.dialog.4B3340F7872B411F": "Hủy bỏ",
    "customer.dialog.549B1456Bb5F98B3": "vui lòng nhập nickname của bạn",
    "customer.dialog.B773B3E45Ccfad00": "Biệt hiệu trống",
    "customer.index.7308C6B849D159E9": "Chi tiết hình ảnh",
    "delivery.callDialog.C74F562C3Fe91Fe3": "đặt hàng thành công",
    "delivery.callDialog.B8C98F42Caa15Ca5": "phương hướng",
    "delivery.callDialog.B79A581Dae543974": "mua lâu",
    "delivery.callDialog.B79A581Dae543975": "mua ngắn hạn",
    "delivery.callDialog.3B26F0F7E90773F3": "Giá mở cửa",
    "delivery.callDialog.65Fb252Bd94E1039": "Doanh thu được ước tính",
    "delivery.index.B483Ba4Fce633539": "xe đạp",
    "delivery.index.224Dd6Cb58D58249": "kỳ giao dịch",
    "delivery.index.28158F517E902788": "Mua vào tối thiểu",
    "delivery.index.A1232162B14Bec76": "Yêu cầu giao hàng",
    "delivery.index.445B1D3176D9C69D": "Lệnh lịch sử",
    "delivery.index.Ab6Cb86E28653C5C": "xe đạp",
    "delivery.index.20A7A6F14D3698C0": "Thời gian mở cửa",
    "delivery.index.Ec6821A49395Ddc2": "Thời gian đóng cửa",
    "delivery.index.254Fdc665C391743": "Giá đóng cửa",
    "delivery.index.2A0C5E68B44A472A": "Lợi nhuận và thua lỗ",
    "delivery.index.E05B33309F6D236A": "Vui lòng nhập số lượng",
    "delivery.index.B2899E073Cb355D9": "mua tối thiểu",
    "download.description.869467A93C7Dd4A1": "Cài đặt phần ghi chú",
    "download.description.C80C1Edd6Bee12Bc": "1.Cho phép tải xuống tệp mô tả",
    "download.description.2F5Bc180F92642B4": "2.Đã tải xuống tệp mô tả",
    "download.description.3C3B849F6Ccf712F": "3.Nhập [Cài đặt] trên điện thoại của bạn và tệp mô tả đã tải xuống sẽ được hiển thị bên dưới thông tin tài khoản.",
    "download.description.86Affd862985E045": "4.Click để tải file mô tả",
    "download.description.32832492Bfc1D95E": "5.Bấm để cài đặt",
    "download.index.245883C80F181C4A": "Tải xuống",
    "download.index.Ef3De8Cb2722937A": "Bắt đầu giao dịch mọi lúc, mọi nơi",
    "download.index.F4A87Eaeed691638": "Luôn cập nhật những tin tức mới nhất thông qua APP của chúng tôi",
    "download.index.Ad3Aba52D0D39D01": "Tải gói cài đặt",
    "download.safariGuide.61Bd9A8Fcc2Ea788": "Lời khuyên tử tế",
    "download.safariGuide.2Cc68Aaadf623477": "Vui lòng truy cập trong Safari",
    "download.safariGuide.C80Fff2A6Ff4E768": "Bạn đang truy cập một trang web phi tập trung, vui lòng chạy trong ứng dụng safari",
    "download.safariGuide.06Da016F3E2F79Bd": "sao chép đường dẫn",
    "finance.index.B034B367545D5236": "Dự án chất lượng cao toàn cầu",
    "finance.index.6Ebfac2C0A9228B0": "Hơn 1,6 triệu người dùng đang hoạt động, 122,4 tỷ USD",
    "finance.index.52Bdcaf19659363D": "Khai thác thanh khoản",
    "finance.index.D66924272B031Bf5": "Thu nhập hàng năm siêu cao",
    "finance.index.34789D88Afcefa64": "Xem các mục",
    "finance.index.E12676B95654D21E": "vay tín dụng",
    "finance.index.4C924Cfd89A11Ac7": "Khám phá thêm cơ hội giao dịch",
    "finance.index.C251D4B594Da2848": "Định lượng AI",
    "finance.index.4E53Cf2E5F173Dd3": "Cho thuê chỉ bằng một cú nhấp chuột, giao dịch thông minh AI",
    "finance.records.Cce168301447D1Ba": "trong tiến trình",
    "finance.records.4587B6Bd7Cfa4454": "được chuộc lại",
    "finance.records.18685418E3A50147": "Khai thác mỏ",
    "finance.records.Df9Fd438F97Cc908": "bầu trời",
    "finance.records.E23Cb03C5Ad359E5": "thuật ngữ",
    "finance.records.Bd87Dac6Cba7Eba8": "Số lượng mua",
    "finance.records.2D0Fff99265E8B1A": "Có lợi nhuận",
    "finance.records.2D0Fff99265E8B1B": "Lợi nhuận khi đáo hạn",
    "finance.records.024F5F0B851782De": "Thời gian khai thác:",
    "finance.records.3143A26D3F983896": "Thời gian quy đổi:",
    "finance.records.96574Aaf8Db85Ead": "sự chuộc lỗi sớm",
    "finance.records.531E1829203181Ac": "Bạn có chắc chắn muốn đổi sớm không?;",
    "finance.records.9205843B8923Dac3": "Bạn có chắc chắn muốn đổi sớm không?;",
    "loan.applyCallback.B9258Ec8746A74D8": "Kênh vay",
    "loan.applyForm.7B8Ed340400A6E2B": "tên",
    "loan.applyForm.549A44Bae725F4A2": "họ",
    "loan.applyForm.2A27E61375E7Fea8": "ngày sinh",
    "loan.applyForm.89925B019D537738": "địa chỉ email",
    "loan.applyForm.Afaeb32Cb4D62E28": "Địa chỉ nhà (bao gồm số căn)",
    "loan.applyForm.E05B5D049B64B040": "Thành phố",
    "loan.applyForm.B166E4E8Fe9513Fa": "Quốc gia",
    "loan.applyForm.83Aaede109E395F8": "mã bưu điện",
    "loan.applyForm.647Ed21332A4968B": "thu nhập hàng tháng của bạn",
    "loan.applyForm.Cf0A9B845D34D4B4": "Thu nhập bổ sung (tùy chọn)",
    "loan.applyForm.7048F618Acc6A54C": "Tiền thuê nhà hàng tháng hoặc thanh toán thế chấp",
    "loan.applyForm.15C1Bba18Ae18545": "Hãy cho chúng tôi biết nếu bạn có thế chấp",
    "loan.applyForm.3921E90625A84E36": "Nếu không chọn bảo lãnh thế chấp thì khả năng vượt qua được việc xem xét là thấp.",
    "loan.applyForm.262Eec4F8906B0F6": "Có thế chấp không?",
    "loan.applyForm.8634Eb21B6910F21": "Vui lòng chọn loại thế chấp",
    "loan.applyForm.A4897B3A331B5E61": "Chứng từ giao dịch",
    "loan.applyForm.6623384C683De457": "Hãy giữ liên lạc",
    "loan.applyForm.A8360Bdba86834Cb": "Chúng tôi có thể liên hệ với bạn theo số này nếu chúng tôi cần thêm thông tin về đơn xin vay của bạn",
    "loan.applyForm.C1C373F385A4C7B3": "số điện thoại",
    "loan.applyForm.1B9785Badb91191F": "Sau khi điền thông tin các bạn vui lòng chờ hệ thống đánh giá và xem xét.",
    "loan.applyForm.8755B9C761D4080C": "Tôi đã đọc và ký điện tử sự đồng ý của tôi",
    "loan.applyForm.3E987B787468148C": "Điều khoản sử dụng và sự đồng ý điện tử",
    "loan.applyForm.Bc54E76B61C50Fb9": "Vui lòng đọc và đồng ý với thỏa thuận",
    "loan.applyForm.89E158384F634Eb8": "Quay lại bước trước",
    "loan.applyForm.Efa5A4548E2F3510": "Địa chỉ nhà",
    "loan.applyForm.4Cadf1787541B35D": "Đúng,",
    "loan.applyForm.Fab408D2F4100447": "KHÔNG,",
    "loan.applyForm.3Bad7A307480Cc56": "Định dạng email sai;",
    "loan.applyForm.306322F49B36855D": "Đơn đăng ký đã được gửi, vui lòng chờ bộ phận dịch vụ khách hàng xem xét",
    "loan.applyForm.3C6E0E858C995Ff9": "Vui lòng tải lên chứng từ giao dịch",
    "loan.applyForm.2Bf443476B271376": "Có thể tải lên tối đa 4 ảnh",
    "loan.applyQuota.Ef1D8E23003B9A6F": "Kênh vay",
    "loan.applyQuota.Ef1D8E23003B9A6G": "Nhận giới hạn cho vay",
    "loan.applyQuota.9E0780F9352C7273": "số dư ví",
    "loan.applyQuota.Cbc1Eb9D639A6105": "Tổng số tiền cho vay hiện có",
    "loan.applyQuota.033A05462195B080": "Số tiền vay",
    "loan.applyQuota.9Aa89B036Ef4821D": "số dư còn lại",
    "loan.applyQuota.5656Fb52Ef17A557": "Nhận giới hạn cho vay",
    "loan.applyQuota.6Ff6702B4C917D40": "đang được xem xét",
    "loan.applyQuota.6Ff6702B4C917D41": "vật bị loại bỏ",
    "loan.applyQuota.6Ff6702B4C917D42": "đã thành công",
    "loan.applyQuota.0Bad0E857Ea4A952": "thời gian nộp đơn",
    "loan.applyQuota.3E7E56E2De2Cc546": "số tiền ứng dụng",
    "loan.applyQuota.8F1D170D9E54Af5B": "Tiến độ nộp đơn",
    "loan.applyQuota.6167Ca035B93Caf3": "lý do",
    "loan.applyQuota.410C7954F9A3981D": "Đăng ký lại",
    "loan.bottomSheet.61Cd62F861D8F5B2": "Vui lòng chọn loại tiền tệ",
    "loan.index.4C10307B22E885C3": "Khám phá thêm cơ hội giao dịch và tạo ra sự giàu có",
    "loan.index.Ae8D37Dea2B3717B": "lãi suất",
    "loan.index.1Bb54625682E85Db": "Giới thiệu quy tắc",
    "loan.index.5Bd31Fab681C6E9D": "xem thêm",
    "loan.index.111F95F404814327": "Đồng tiền cho vay",
    "loan.index.6Bc2723B3Bec106D": "khoản vay",
    "loan.index.3200106280E6E370": "lãi suất",
    "loan.index.049A6Ccb6C81A635": "Số tiền cho vay (tối thiểu/tối đa)",
    "loan.productDetails.5037Ad86Ab4B7E02": "khoản vay",
    "loan.productDetails.37181C74559Db182": "Đặt hàng ",
    "loan.productDetails.5802Aaf06A1E4755": "Thanh khoản",
    "loan.productDetails.89Aea9Cf9337C40F": "Số lượng có thể vay",
    "loan.productDetails.9E419Ae2D06F3Ad9": "Số lượng phải hoàn trả",
    "loan.productDetails.C65F329Ef14C4A3D": "Số tiền vay",
    "loan.productDetails.3A74667815Adc8Bf": "tối đa",
    "loan.productDetails.Ab851D8351573227": "bầu trời",
    "loan.productDetails.Ddf82D8A2C8F46A0": "chu kỳ trả nợ",
    "loan.productDetails.C105E5562005Aeb1": "bầu trời",
    "loan.productDetails.3F346Be2137F0756": "Số tiền cho vay (tối thiểu/tối đa)",
    "loan.productDetails.2707F78Df864E194": "Quan tâm",
    "loan.productDetails.B9Dc9B81E726Bdf1": "Vui lòng hoàn trả đúng hạn, nếu quá hạn sẽ bị tính thêm số tiền (gốc + lãi) hàng ngày.",
    "loan.productDetails.B9Dc9B81E726Bdf2": "% là phí quá hạn",
    "loan.productDetails.4D94Faa033Be7356": "tôi đã đồng ý",
    "loan.productDetails.62429A957A22780A": "Thỏa thuận dịch vụ cho vay tiền xu",
    "loan.productDetails.Bc54E76B61C50Fb9": "Vui lòng đọc và đồng ý với thỏa thuận",
    "loan.productDetails.Ec8Ba071C53A67Cb": "Vay ngay bây giờ",
    "loan.productDetails.59246A0Bf081D398": "ít nhất",
    "loan.productDetails.Ec3F1172B71Cd36C": "số tiền tối thiểu",
    "loan.productDetails.7Ccc4Cf1D81930B3": "số tiền lớn nhất",
    "loan.productDetails.071488598990Aa68": "Vui lòng đọc và đồng ý với thỏa thuận;",
    "loan.productDetails.579Dd216D3339119": "Khoản vay thành công;",
    "loan.records.4951B36Fd0D2A831": "Lịch sử cho vay và tín dụng",
    "loan.records.Bb0Aaa30514B7Cba": "kỷ lục hiện tại",
    "loan.records.0F8B0A7D80769051": "Lịch sử trả nợ",
    "loan.records.D4B42E43D88De6F1": "kỷ lục lịch sử",
    "loan.records.Cb1847D6281897E6": "Số tiền vay",
    "loan.records.C7Bfcacfc2Adea58": "Số tiền trả nợ",
    "loan.records.8B369164D5Fea340": "Thời gian vay:",
    "loan.records.Ab2402Ad3Ffd35E7": "Trả hết khoản vay sớm",
    "loan.records.B7A0Ca0C195D0Cd1": "Thời gian phát hành:",
    "loan.records.42014Bdcfd9C411B": "Thời gian trả nợ:",
    "loan.records.A0Acfaee54F06458": "hoàn thành",
    "loan.records.67A39834582Ad99A": "Không có dữ liệu",
    "loan.records.6A687Ff5B8F1598F": "Bạn có chắc chắn muốn trả nợ trước không?",
    "loan.records.7C20D7008D0Eae86": "Trả nợ thành công",
    "mining.bottomSheet.Ba91637D32Ccba04": "Khai thác thanh khoản",
    "mining.bottomSheet.7De3D636A165E008": "tỷ lệ lợi nhuận",
    "mining.bottomSheet.F3003345B189D683": "Thời kỳ khai thác",
    "mining.bottomSheet.3E4C0A8C533F5848": "loại tiền xu",
    "mining.bottomSheet.Adcba368993Ae65E": "Số tiền đăng ký (tối thiểu/tối đa)",
    "mining.bottomSheet.94743B24E4Ed4166": "Vô hạn",
    "mining.bottomSheet.286944Fedd211257": "Tiền khả dụng",
    "mining.bottomSheet.7B5766Dc65Abc0C3": "Doanh thu được ước tính:",
    "mining.bottomSheet.9Bbaf80Fdb6Fd148": "Tôi bây giờ",
    "mining.bottomSheet.Bb1493C567C114Dd": "Lời khuyên loại:",
    "mining.bottomSheet.D2315E87E34A3Bd3": "Phí quy đổi sớm",
    "mining.index.8B2Bba1422Eed6F0": "Tạo ra của cải hàng chục tỷ, thu nhập ròng 100%",
    "mining.index.42997E300086Ee89": "lợi nhuận cao nhất",
    "mining.index.510F74F42052Ecce": "Sản phẩm khai thác",
    "mining.index.B853F96626106912": "Đi khai thác",
    "mining.productList.37181C74559Db182": "Đặt hàng",
    "mining.productList.Aac5B810004C46C8": "thường xuyên",
    "mining.productList.D2Fb4F78C387D888": "Bạn đã khai thác thành công",
    "quantify.bottomSheet.9Ea4A318F690Bc95": "Xác nhận đơn hàng",
    "quantify.bottomSheet.6915303B698303B1": "người máy",
    "quantify.bottomSheet.68045296Dd0F8Ad6": "Số tiền thuê",
    "quantify.bottomSheet.Fbd931286Be387Aa": "xe đạp",
    "quantify.bottomSheet.9Ad84Db481807844": "vô thời hạn",
    "quantify.bottomSheet.994D7226Ca083Ea5": "Tỷ lệ hoàn vốn hàng ngày",
    "quantify.bottomSheet.0Bf82Ee4Bfe063B7": "ngày giá trị",
    "quantify.bottomSheet.88008603Ba7Bde64": "Mua thành công",
    "quantify.bottomSheet.Ff50B3091Bbd09Ab": "Nhận chia sẻ doanh thu của bạn bắt đầu từ hôm nay!",
    "quantify.bottomSheet.C778A674C4A054F9": "kiểm tra đơn hàng",
    "quantify.bottomSheet.685Dad322B7029B0": "Trả lại giao dịch",
    "quantify.buyForm.Acf8560A04334E95": "giá mua",
    "quantify.buyForm.Cf9Cfbc2C554944F": "Tổng quan",
    "quantify.buyForm.8788Df2B625E97B5": "người máy",
    "quantify.buyForm.50Ca63B45E881D50": "Thời gian trả cổ tức",
    "quantify.buyForm.Fcda03Adbc5Fca97": "Hằng ngày",
    "quantify.buyForm.Afb89504Fc399189": "Vui lòng nhập số lượng mua hàng",
    "quantify.buyForm.1435Afdfb4Ec0596": "Số lượng mua tối thiểu",
    "quantify.buyForm.1B97F1730Bacd839": "Số lượng mua tối đa",
    "quantify.index.32F56C9B7D0E250A": "Thời gian: vô thời hạn",
    "quantify.index.E34F028D00904630": "giá ",
    "quantify.index.E34F028D00904631": ",Lãi suất hàng ngày",
    "quantify.productDetail.6Bbe178Df539A5Ca": "Số tiền ký quỹ",
    "quantify.productDetail.53635B53Bca31Dfa": "Thu nhập ước tính hàng ngày",
    "quantify.productDetail.F9E46449A6269E09": "Thu nhập tích lũy",
    "quantify.productDetail.98Fc46B1B8C993B2": "Đặt hosting",
    "quantify.productDetail.93624Bb045830966": "Thuê ngay",
    "quantify.records.0C16A18C32B9Cf4A": "Hồ sơ đặt hàng",
    "quantify.records.B191F5614B0E2312": "Lịch sử mua hàng",
    "quantify.records.6A06033B86A73F7C": "hồ sơ đổi thưởng",
    "quantify.records.45E0F7E71611618E": "Thu nhập tích lũy",
    "quantify.records.45E0F7E71611618F": "Lợi nhuận khi đáo hạn",
    "quantify.records.Aae1201E38C4Baaa": "Đang chạy",
    "flash.index.C41Fd4F2Fdfa594B": "tiêu thụ",
    "flash.index.1Ac59Fa2218E3B00": "Có sẵn:",
    "flash.index.Bf7233A660821549": "lấy",
    "flash.index.548E775Dd67C7472": "Xác nhận trao đổi",
    "flash.index.066A2449Bd616F03": "Vui lòng nhập số lượng quy đổi",
    "flash.index.Fca26Fcf9C06563F": "Đổi flash thành công",
    "message.index.40031524233F4564": "Trung tâm tin nhắn",
    "message.index.8E5586210Fd44D69": "Nhắc nhở vị trí vĩnh viễn",
    "message.index.Bcd3F470E88C197D": "Chưa có thông báo",
    "message.index.23A557Fbce545879": "Thông báo thanh lý vĩnh viễn",
    "message.index.B5E19B36B5125550": "Nhắc nhở rút tiền",
    "message.index.716A981465138334": "Nhắc nhở nạp tiền",
    "message.index.884474Ebff2977Ba": "thông tin hệ thống",
    "message.index.08Cc7D24752Ec42C": "dịch vụ trực tuyến",
    "message.index.1D820A6A19Ab025D": "Dịch vụ thủ công trực tuyến 7 X 24 giờ",
    "more.index.29881E0B5Df58Bf7": "Nhiều tính năng hơn",
    "more.index.C77Ee35911D10D9D": "Chức năng chung",
    "more.index.3558F69B0260Be61": "Chức năng giao dịch",
    "more.index.7243Fe610Ed98Cec": "Tài khoản thực",
    "more.index.7243Fe610Ed98Ced": "tài khoản demo",
    "more.index.693C7B35Bdf3E3A3": "tài chính",
    "more.index.F855997Fac114554": "Trung tâm trợ giúp",
    "more.index.Ae9A1252278Da742": "giúp đỡ",
    "more.index.Ace012504Bc529E0": "thông tin",
    "more.index.05682Dbef5Eeb0A8": "dịch vụ khách hàng",
    "more.index.C7Af227A5D5C7Bb9": "Bạn đã chuyển sang tài khoản trực tiếp",
    "more.index.D818A65592694786": "Bạn đã chuyển sang tài khoản demo",
    "notice.details.B360807Dbe727E6E": "về chúng tôi",
    "notice.details.92F2552F695E70E3": "về chúng tôi",
    "notice.details.7Aaceaf69B67F3D1": "Giới thiệu quy tắc",
    "notice.index.6128438Be34Bcb9F": "Thông báo vĩnh viễn",
    "notice.index.71627F5Eb7128261": "Thông báo rút tiền",
    "notice.index.2Dce570Fda841705": "Thông báo nạp tiền",
    "notice.index.228A7C9C6448A9B1": "thông báo hệ thống",
    "setting.dialog.8B4F5B74660Deabe": "Cấu hình màu",
    "setting.dialog.Bfc7D25486Ebc1A9": "Màu xanh tăng và màu đỏ rơi",
    "setting.dialog.796Ea0E1A82C320B": "Tăng đỏ và giảm xanh",
    "setting.index.8Ddc5864E1739466": "Sở thích",
    "setting.index.F5205801D57D6727": "ngôn ngữ",
    "setting.index.2E2C12Dd0Cc1B63B": "Đồng tiền báo giá",
    "setting.index.322D8496Aec3D918": "số phiên bản",
    "setting.index.Aa7E6C204C751Cfa": "đăng xuất",
    "share.index.75Bc38Bbf7D2E766": "mời bạn bè",
    "share.index.45575Eb5D42213E6": "mã mời của tôi",
    "share.index.29F67F0644A78Bf3": "sao chép",
    "share.index.19Cc65Bb6Bb98Fe7": "Liên kết lời mời của tôi",
    "share.index.5372712F162Fb78B": "Số lượng cấp dưới trực tiếp",
    "share.index.C88D6B95E3Ab7D6F": "Tổng số cấp dưới",
    "share.index.Bb5Fa5471Aeecc62": "Chiết khấu hoa hồng cho cấp dưới trực tiếp",
    "share.index.426B6Bac9A5F3D4F": "tổng giảm giá",
    "share.index.197E4A428F0401C9": "Xem hồ sơ giảm giá",
    "share.records.88A6B2E2B74B13C4": "Hồ sơ hoa hồng",
    "share.records.B671A35Ffdc93B89": "Hạ giá",
    "transfer.index.B17617A869C03Ca5": "cái ví",
    "transfer.index.E4116Dcc6F6C7270": "Chuyển thành công",
    "transfer.index.48Fb603C6638C7F6": "Vui lòng đặt mật khẩu quỹ",
    "transfer.records.Eb0E39Eb5911D418": "lấy",
    "transfer.records.977554Be0Aa65052": "Thời gian đổi flash:",
    "transfer.records.9Aa33884Aa400B7C": "Thời gian chuyển giao:",
    "form1.forget.76636Db8Fae5B124": "hộp thư của bạn",
    "form1.forget.35354B9Cca1Bb0E6": "Mã xác minh email",
    "form1.forget.604A0B11E916F387": "mật khẩu",
    "form1.forget.9Cb1C9C51E7503D6": "Xác nhận mật khẩu",
    "form1.forget.3A4F7E04B008453C": "Số điện thoại của bạn",
    "form1.forget.Cf11Dce5B4713B5A": "Mã xác minh điện thoại di động",
    "form1.forget.3012Edba57D4F297": "Vui lòng nhập email",
    "form1.forget.F1Ee1D97E869E447": "vui lòng nhập mã xác nhận",
    "form1.forget.3A5C4C9Ae68Fa0F6": "Vui lòng nhập mật khẩu của bạn",
    "form1.forget.C6Cb174Da411D5F7": "Vui lòng nhập số điện thoại di động của bạn",
    "form1.forget.947B3B21Ee45E582": "gửi",
    "form1.forget.3E0580F1E8630Df6": "Hai mật khẩu không nhất quán",
    "form1.forget.C5A61836B8Cc74C6": "Chuyển hướng thay đổi mật khẩu thành công...",
    "form1.forget.B96D072Bc8962565": "Gửi lại",
    "form1.login.2B72C27Ff828Cfc4": "mật khẩu của bạn",
    "form1.login.D7C6B872Af9Cb17F": "nhớ mật khẩu của tôi",
    "form1.login.0Fff44C827A4F3B6": "Đăng nhập",
    "form1.login.1B7F2C1Ede3990Cc": "Không tài khoản? Đăng ký ngay",
    "form1.login.F657F7Be1Cc33Bd2": "quên mật khẩu",
    "form1.login.2074A1Eec4B088B0": "Đăng nhập ví",
    "form1.login.1B8938Fbcc3C9B56": "Đăng nhập thành công và chuyển hướng...",
    "form1.login.07162A7Bb9310555": "Vui lòng cài đặt ví ERC20",
    "form1.login.766969966Ae151Dc": "Hãy cài đặt ví TRC20",
    "form1.login.B88171E1Ad4C50Bf": "Bạn đã từ chối chuyển mạng",
    "form1.register.Aaef0710Cb4Db554": "Nhập lại mật khẩu",
    "form1.register.54Dfea0415C708Ff": "Mã mời",
    "form1.register.6839940Dac08C794": "đăng ký",
    "form1.register.9901A3B5550Aa58E": "Bạn co săn san để tạo một tai khoản? Đăng nhập",
    "form1.register.2548Ca53785252Ea": "Vui lòng nhập mã mời của bạn",
    "form1.register.Dd71Fd65900Dd03E": "Vui lòng đọc Thỏa thuận dịch vụ đăng ký và đồng ý,",
    "form1.register.7975613909490B77": "Đăng ký thành công và chuyển hướng...",
    "form1.walletDialog.105829Acfc0Bd6Aa": "Chọn ví",
    "userc.certification.2Af9511640153D2E": "Chứng nhận cơ sở",
    "userc.certification.177B191D8E0Fa84D": "không chứng nhận",
    "userc.certification.601218D4D572E98A": "đã xác minh",
    "userc.certification.6E37C51Ba9012Fc8": "Không vượt qua",
    "userc.certification.1B9B563E05C96474": "Chứng nhận nâng cao",
    "userc.certification.1418B83803Ab56B4": "Vui lòng hoàn thành chứng nhận chính trước",
    "userc.creditScore.0B149112601Acc4D": "điểm tín dụng",
    "userc.creditScore.88Ac7F84E106C5F6": "tổng điểm tín dụng",
    "userc.creditScore.3E1D6E0E7F1E6155": "Đánh giá hệ thống",
    "userc.index.62Eac58D04Bbca0F": "Trung tâm cá nhân",
    "userc.index.F4Cac53F93Cb2Aff": "giao dịch mô phỏng",
    "userc.index.42F409Fdf3559773": "Trung tâm bảo vệ",
    "userc.index.1B8E211009807Db8": "Xác thực",
    "userc.index.Bdfcf21Eb8F2Eb70": "Hồ sơ thay đổi tài khoản",
    "userc.index.6C18D67F6D19586B": "Giới thiệu nền tảng",
    "userc.index.807A814Ed58A93Bc": "xóa bộ nhớ đệm",
    "userc.index.E43Daad59759611C": "không chứng nhận",
    "userc.secureCenter.F5C70F0Db2F1Ba76": "Thay đổi mật khẩu đăng nhập",
    "userc.secureCenter.1F37Ba427Debf24A": "Ràng buộc tài khoản",
    "userc.secureCenter.F9A5Ffa750664004": "Liên kết Google Authenticator",
    "userb.certificationForm.7685Af7D30043Cd6": "Chứng nhận cơ sở",
    "userb.certificationForm.7685Af7D30043Cd7": "Chứng nhận nâng cao",
    "userb.certificationForm.70E37C35Abc3F462": "Quốc gia",
    "userb.certificationForm.F92C25A68E4D08C5": "Thành phố",
    "userb.certificationForm.0Ccc6387B38E0318": "Tên của bạn",
    "userb.certificationForm.94C67Bba370Fdd46": "số giấy phép",
    "userb.certificationForm.B2D4C7Fffe79258E": "Thư",
    "userb.certificationForm.57554268761Fb47A": "Tải lên mặt trước giấy tờ tùy thân của bạn",
    "userb.certificationForm.D79E90201F5319Bd": "Tải lên mặt sau giấy tờ tùy thân của bạn",
    "userb.certificationForm.9C6Bad08Af29E20E": "Tải lên hình ảnh chứng minh nhân dân của bạn",
    "userb.certificationForm.F96B62Dfa31Cde45": "Xác nhận gửi",
    "userb.certificationForm.2125D78Ea8C6D287": "Yêu cầu tải lên",
    "userb.certificationForm.0D64864D3076A824": "Xem ví dụ",
    "userb.certificationForm.35287Deb72281E87": "1.Phải có khả năng đọc thông tin CMND rõ ràng.",
    "userb.certificationForm.3007F2E01Efc7B57": "2.Nội dung của bức ảnh là đúng sự thật và hợp lệ và không được phép sửa đổi.",
    "userb.certificationForm.25A90660554319F9": "3.Số ID và tên phải hiển thị rõ ràng, hỗ trợ JPG / JPEG / PNG",
    "userb.certificationForm.5A9Aefbc03C778F7": "vui lòng nhập",
    "userb.certificationForm.3B7A1D8Ce27C937E": "Gửi thành công, vui lòng chờ xét duyệt",
    "userb.certificationForm.744Bd07D8Abd2513": "Vui lòng tải lên mặt trước giấy tờ tùy thân của bạn",
    "userb.certificationForm.6De1C319130F7Fef": "Vui lòng tải lên mặt sau giấy tờ tùy thân của bạn",
    "userb.certificationForm.79F8E8B172E30C8A": "Vui lòng tải lên ảnh chứng minh nhân dân của bạn",
    "userb.certificationForm.46412E16D28A4753": "Tải ảnh lên không thành công",
    "userb.changeDialog.676B0Adca1F8Eea4": "đổi mật khẩu",
    "userb.modifyInfoDialog.F41De3A1Fa69135A": "số điện thoại",
    "userb.modifyInfoDialog.3C3Aac9664A35Ef8": "công tắc xác thực",
    "userb.modifyInfoDialog.62149E566814154C": "Chọn trình xác thực",
    "userb.modifyInfoDialog.8E69468E2435Fc89": "Mở ứng dụng Xác thực và nhấp vào+",
    "userb.modifyInfoDialog.7625E5Bdcbfe9963": "Chọn 'Quét mã QR' hoặc 'Nhập phím'",
    "userb.modifyInfoDialog.93F094E2B4369472": "Quét mã QR hoặc nhập key",
    "userb.modifyInfoDialog.43E4F1532529Ee1E": "đóng mở",
    "userb.modifyInfoDialog.Bae6358Dfd95025A": "Mật khẩu quỹ cũ",
    "userb.modifyInfoDialog.E1133Cd4B1C2Abb7": "Mật khẩu quỹ mới",
    "userb.modifyInfoDialog.C1Ce0403A2523Ee0": "Xác nhận mật khẩu quỹ mới",
    "userb.modifyInfoDialog.8Dd453Adc2Cd78Fb": "Xác nhận mật khẩu quỹ",
    "userb.modifyInfoDialog.Bb0D9854C79325Bb": "Vui lòng nhập mật khẩu quỹ cũ của bạn",
    "userb.modifyInfoDialog.E66Cb29B00Bc54D1": "Vui lòng nhập mật khẩu quỹ mới của bạn",
    "userb.modifyInfoDialog.E8Ae2Ec5Df401255": "Vui lòng nhập mật khẩu quỹ của bạn",
    "userb.modifyInfoDialog.258A534498Fe984F": "Vui lòng xác nhận mật khẩu quỹ của bạn",
    "userb.modifyInfoDialog.768Ec43Faa193F08": "Ràng buộc tài khoản",
    "userb.modifyInfoDialog.D34746090C25E5Db": "Mật khẩu quỹ",
    "userb.modifyInfoDialog.E4F27D8916E5E536": "xác nhận",
    "userb.modifyInfoDialog.Bef7160Ce53773E0": "Trình xác thực đã đóng",
    "userb.modifyInfoDialog.Be8Bf47Ba6820De1": "Đã bật xác minh Google thành công",
    "userb.modifyInfoDialog.24Dba26593F43C74": "Đã sửa đổi mật khẩu quỹ thành công",
    "userb.modifyInfoDialog.5C369D42Dc7Dbe76": "Đặt mật khẩu quỹ thành công",
    "userb.modifyInfoDialog.667Bf8D0B6C36D4C": "Ràng buộc thành công",
    "userb.modifyInfoDialog.1C5E05Efee2B0B9F": "Lỗi mã xác minh",
    "userb.veBottomSheet.Eecc4683C725F6F2": "Ví dụ tải lên chứng chỉ",
    "userb.veBottomSheet.6F38A0D5858633Ee": "nền tối sạch",
    "userb.veBottomSheet.Af162Dbdfc4Fcae9": "Đủ ánh sáng",
    "userb.veBottomSheet.0F7Baf0Cee3C8141": "Khung phụ trợ căn chỉnh",
    "userb.veBottomSheet.Ac9E471Eead0Cf11": "Hình ảnh rõ ràng",
    "userb.veBottomSheet.50B741382E71F103": "Những lý do có thể dẫn đến thất bại",
    "userb.veBottomSheet.Aa56C68E8Fd4Cfb4": "Vỏ bảo vệ CMND chưa được tháo ra",
    "userb.veBottomSheet.5D2F429C75D4C7F2": "Nền bị ảnh hưởng bởi sự lộn xộn",
    "userb.veBottomSheet.90F42D3Cb7769Dbc": "Chứng minh nhân dân phản chiếu",
    "userb.veBottomSheet.1Ee78Cee782D81B4": "Ánh sáng quá tối/bị che khuất bởi bóng của điện thoại di động",
    "userb.veBottomSheet.1A75D2Fc2614592B": "cầm chứng minh thư",
    "home.home.C3525Eb110Db58Aa": "Hơn",
    "home.home.29761532Fc8Ebecc": "buôn bán",
    "home.home.F15Ea99Bfa6E1848": "Chứng nhận an toàn, mua với sự tự tin",
    "home.home.46E1Ab39Bae7182A": "Nhanh",
    "home.home.Fde2Eb7F6F3Dd800": "Giao dịch nhanh chóng và thao tác đơn giản",
    "home.home.Ea4756Bc1642E0F1": "tên",
    "home.home.B6B1127Ede1C97B4": "giá mới nhất",
    "home.home.3587Ad870Eb0Ab86": "Tăng giảm 24H",
    "home.home.Cb1A4E14Ec2Cd2B5": "Phát hiện",
    "home.home.0F1B1Ac0E96A40D1": "Chuỗi khối",
    "home.home.8Eaefb1107A0E210": "Ngoại hối",
    "home.home.Ad65263D034Ea051": "kim loại quý",
    "home.home.5Fc1474Dbf74Ac1A": "nông sản",
    "home.home.135318F4664F7773": "năng lượng",
    "kline.kLine.C96Ff250Cf6B8B84": "giá cao nhất 24h",
    "kline.kLine.6E8Be64F19D74565": "Khối lượng giao dịch 24h",
    "kline.kLine.08A4Bfbe26A0F262": "giá thấp nhất 24h",
    "kline.kLine.8701Deb075066331": "Khối lượng giao dịch 24 giờ (USDT)",
    "kline.kLine.Ea3C3052136C84Cd": "1 điểm",
    "kline.kLine.B49Cca240E013124": "5 điểm",
    "kline.kLine.F27A90Be97Ba3D6C": "15 điểm",
    "kline.kLine.Ff3E62395B694384": "30 điểm",
    "kline.kLine.C1993F9C6Db1A674": "1 giờ",
    "kline.kLine.Fd2Cbbae405E284C": "1 ngày",
    "kline.kLine.C9Cd71904395042C": "Tháng Một",
    "kline.kLine.7D8127Ec18C7B8C7": "Mua",
    "kline.kLine.E3D3Ffdb9B3E5636": "Số lượng (miếng)",
    "kline.kLine.6834Fec1838D8029": "Giá (USDT)",
    "kline.kLine.16C52Aae474Bc9Cd": "lệnh bán",
    "kline.kLine.B9Bea1A9A88D1419": "Mở lâu",
    "kline.kLine.D36Cfa96Ee2B343B": "Mở ngắn",
    "other.languageRateSelect.6897784800834C1B": "cài đặt ngôn ngữ",
    "other.languageRateSelect.6897784800834C1C": "Vui lòng chọn đơn vị tiền tệ định giá",
    "other.navigationBar.F6E62F9642018Cbb": "trang đầu",
    "other.navigationBar.0Ec227161A47B49D": "Báo giá",
    "other.navigationBar.29761532Fc8Ebecc": "buôn bán",
    "other.navigationBar.693C7B35Bdf3E3A3": "tài chính",
    "other.navigationBar.9734C54620Eb09Af": "tài sản",

    "home.index.25Aa4Fc745150030": "Vua tiền điện tử",
    "home.index.2D6Ecf8Faaa8B124": "Nhanh, tốt hơn, mạnh mẽ hơn",
    "home.index.1Db26267D1041223": "Dễ dàng giao dịch hơn 1.700 tiền điện tử",
    "home.index.F8E18C90C3E35618": "Đăng ký ngay",
    "home.index.29761532Fc8Ebecc": "buôn bán",
    "home.index.D8A95E4506487452": "Hợp đồng nóng",
    "home.index.5Bd31Fab681C6E9D": "Xem thêm",
    "home.index.010Ae07A09Caf221": "Giao dịch",
    "home.index.F4D875118E6D6Dbb": "Giá mới nhất",
    "home.index.3Dfdfb3D6599100E": "Thăng trầm 24H",
    "home.index.28Fb39Bd41E54242": "xu hướng",
    "home.index.B47A3911Bacd94C6": "Danh sách đạt được",
    "home.index.712910D574F43D95": "Thu nhập ổn định",
    "home.index.5507Fd830D7Bf51F": "Chiến lược hàng đầu, thư giãn",
    "home.index.7De3D636A165E008": "tỷ lệ lợi nhuận",
    "home.index.B853F96626106912": "Khai thác mỏ",
    "home.index.213B5C96Be7Cd1F8": "Chúa, lãi suất hàng năm",
    "home.index.213B5C96Be7Cd1F9": "Tối đa để mua",
    "home.index.Dc710Cffe6313Bb5": "Kiểm tra các chi tiết",
    "home.index.B92Ffc837Ecaa1Dd": "Tin tức",
    "home.index.6C18D67F6D19586B": "Giới thiệu nền tảng",
    "home.index.6342E3C33Cb48Cd5": "Tiền kỹ thuật số là một phương tiện giao dịch sử dụng nguyên tắc mật mã để đảm bảo bảo mật các giao dịch. Không giống như tiền tệ fiat như đồng đô la Mỹ, tiền kỹ thuật số không có hình thức vật lý. Các loại tiền kỹ thuật số nóng như Bitcoin (BTC), Ethereum (ETH) và Polkadot (DOT) sử dụng blockchain làm công nghệ cơ bản để hoạt động như một phân loại kỹ thuật số phi tập trung. Tất cả các giao dịch tiền kỹ thuật số được ghi lại trong blockchain, sau khi được xác nhận và xác minh, nó không thể thay đổi. Không giống như tiền tệ truyền thống theo danh mục bảo trì ngân hàng, các giao dịch tiền kỹ thuật số được thực hiện trên blockchain công cộng và bất kỳ ai cũng có thể truy cập nó.",
    "home.index.198Efc4254683Eef": "Ngoài ra, theo cơ chế đồng thuận, mọi người đều có thể xác minh các giao dịch tiền kỹ thuật số và thêm nó vào blockchain để đạt được sự phân cấp. Là các công ty hàng đầu toàn cầu như Samsung, Belide, Morgan Stanley và Alphabet đã vào văn phòng, mọi người tin rằng công nghệ blockchain sẽ thay đổi hoàn toàn hệ thống tài chính toàn cầu. Các loại tiền kỹ thuật số đã mở ra cánh cửa cho thế giới tài chính phi tập trung, cho phép bạn khám phá các khả năng vô hạn do công nghệ cắt giảm.",
    "home.index.E710339D9576292E": "Lợi thế của chúng tôi",
    "home.index.F9Ce8B2C63885B26": "an toàn và ổn định",
    "home.index.C4D0A2C9Ff0305Ce": "Nhóm kỹ thuật hàng đầu, bảo vệ bảo mật toàn diện, phát triển độc lập một công cụ kỹ thuật giao dịch tốc độ cao và các giao dịch lớn vẫn có thể chạy ổn định và đáng tin cậy.",
    "home.index.2E8708Ee3F11Cc1A": "Chuyên nghiệp và đáng tin cậy",
    "home.index.Bb12Cbcf89C56C5C": "Đội ngũ điều hành chuyên nghiệp, nhiều năm kinh nghiệm trong blockchain và ngành tài chính, có giấy phép tuân thủ giao dịch tài sản kỹ thuật số, đảm bảo tiền được đảm bảo 100 %.",
    "home.index.Aa4723Bfbf6808F4": "Phục vụ tận tâm",
    "home.index.1Bf0204B7D3Ee230": "Đối với thị trường toàn cầu, có nhiều hỗ trợ ngôn ngữ, hoạt động 7*24 giờ không bị gián đoạn, hỗ trợ cộng đồng mạnh mẽ, dịch vụ khách hàng chuyên nghiệp.",
    "home.index.2E7Dfd53D26F7224": "Hiệu suất cao",
    "home.index.1C65453103B5Fcc3": "300.000 giao dịch mỗi giây, thời gian phản hồi đơn đặt hàng dưới 1 mili giây.",
    "home.index.85Ac49Ece9Dcc80B": "Giao dịch mọi lúc, mọi nơi!",
    "home.index.2799E18842E4Ebe9": "Kho ngay lập tức, ứng dụng OKEX và hỗ trợ kép bên web!",
    "home.index.2Bf52Ce75E29Fc88": "Quét mã ngay bây giờ",
    "home.index.88D1422B33571066": "Android",
    "home.index.04Ee893775563F21": "IOS",
    "home.index.04Ee893775563F22": "dịch vụ sản phẩm",
    "home.index.04Ee893775563F23": "Quản lý tài chính",
    "home.index.04Ee893775563F24": "trở lại",
    "home.index.04Ee893775563F26": "IOS & ANDROID",
}
