import zhHans from 'vuetify/es5/locale/zh-Hans';
export default {
    ...zhHans,
    setting: {
        language: "Chinês simplificado",
        dialogDetermine: "Claro",
        dreivatives: "Entrega",
        markets: "Citações",
        assets: "ativos",
        liquidity: "Mineração de liquidez",
        helpCentre: "Centro de ajuda",
        announcementCenter: "Central de Anúncios",
        about: "sobre",
        bulletinCentre: "Central de Anúncios",
        serviceAgreement: "Acordo de serviço",
        support: "apoiar",
        onlineSupportCustomerService: "serviço on-line",
        suggestionsAndFeedback: "Sugestões e comentários",
        service: "Servir",
        download: "download",
        coinApplication: "Aplicativo de listagem de moedas",
        privacyStatement: "Declaração de privacidade",
        trade: "negociação de contrato",
        standardConteact: "Sustentável",
        signOut: "sair",
        assetWallets: "Carteira de ativos",
        financeRecords: "Registros de alteração de conta",
        identification: "Autenticação",
        personalCenter: "Centro pessoal",
        chooseLanguage: "Escolha um idioma",
        tips: "dica",
        dark: "modo escuro",
        light: "modo diurno",
        languageTitle: "linguagem",
        convertCurrency: "Converter moeda",
        cancel: "Cancelar",
        aboutUs: "sobre nós",
        standard: "Continuidade do padrão",
        share: "compartilhar",
        introduce: "Introdução à plataforma",
        set: "configurar",
        changePassword: "Alterar senha de login",
        denomination: "Moeda de cotação",
        color: "Configuração de cores",
        code: "número da versão",
        color1: "O vermelho sobe e o verde cai",
        color2: "O verde sobe e o vermelho cai",
        setLangue: "configuração de idioma",
        vip: "Benefícios VIP",
        vip2: "Nível VIP atual",
        safeMenu: "Centro de Segurança",
        conventional: "convencional",
        other: "outro",
        quickTips: "Adicione até 3 botões de função de atalho",
        quickTransaction: "Transação rápida",
        safeConvenient: "Seguro e conveniente",
        timeSharing: "compartilhamento de tempo",
        currentLevel: "nível atual",
        levelBenefits: "%{level} nível de renda",
        teamLevel: "Nível da equipe",
        levelLower: "%{level}subordinado",
        rules: "Descrição da regra",
        bindWithdrawalAddress: "Vincular endereço de retirada",
        tipsBindWithdrawalAddress: "Você ainda não vinculou seu endereço de retirada, vincule-o primeiro.",
        walletAddressFormatIncorrect: "O formato do endereço da carteira está incorreto",
        tradeRules1: "Preveja a ascensão e queda no período futuro. O preço no momento da ordem é considerado o preço inicial, e o preço no último segundo do período de previsão é considerado o preço de ajuste. Se o preço de ajuste for maior que o preço inicial, significa um aumento. Se o preço de ajuste for maior que o preço inicial, significa um aumento. o preço de ajuste for inferior ao preço inicial, significa uma diminuição.。",
        tradeRules2: "1.o que é um contrato",
        tradeRules3: "Esta plataforma oferece contratos perpétuos baseados em USDT. Os usuários podem avaliar o aumento ou a queda e optar por comprar contratos longos ou vendidos para obter lucros com o aumento/queda dos preços dos ativos digitais. Os contratos perpétuos não têm data de entrega e os usuários podem mantê-los o tempo todo.。",
        tradeRules4: "2.O que é margem",
        tradeRules5: "No mercado de contratos virtuais, os usuários precisam apenas pagar uma pequena quantia em uma determinada proporção de acordo com o preço do contrato como garantia financeira para a execução do contrato, e então podem participar da compra e venda do contrato. Esse tipo de fundo é o depósito de contrato virtual. \nOs contratos nesta plataforma adotam o modelo de margem separada de contas separadas. Cada moeda corresponde a uma conta contratual.Os activos e posições da conta de diferentes moedas contratuais são independentes uns dos outros, e as transferências e transacções entre diferentes contas contratuais não se afectam. Após a abertura da conta contratual de um usuário, os riscos e benefícios de todas as posições mantidas na conta contratual serão calculados em conjunto.。",
        tradeRules6: "3. Como calcular a margem",
        tradeRules7: "Margem de posição = valor nominal do contrato * número de contratos abertos / multiplicador de alavancagem\nmargem congelada = a soma das margens de todas as ordens colocadas com sucesso e ordens de abertura na ordem atual\nmargem disponível = saldo da conta - margem de posição - margem congelada",
        tradeRules8: "4.Como calcular a taxa de margem",
        tradeRules9: "A taxa de margem é uma medida do risco dos ativos do usuário. \nTaxa de margem = (Lucros e perdas não realizados + Margem de posição + Margem disponível + Margem de congelamento - Taxa de liquidação) / Margem de posição \nQuanto menor for a taxa de margem, maior será o risco da conta. Quando a taxa de margem for menor ou igual a 0%, será acionada a liquidação forçada.。",
        tradeRules10: "5.Qual é o preço de paridade forçada estimado?",
        tradeRules11: "O preço estimado de liquidação forçada, ou seja, o preço calculado pelo sistema que teoricamente desencadeia a liquidação forçada, é apenas para referência e pode divergir ligeiramente do valor real.。",
        tradeRules12: "6.O que é o patrimônio líquido total da conta e os lucros e perdas não realizados?",
        tradeRules13: "Patrimônio total da conta = saldo da conta + lucros e perdas não realizados\nLucros e perdas não realizados são os lucros e perdas da posição atualmente mantida pelo usuário no contrato de moeda. Lucros e perdas não realizados mudarão com as últimas alterações no preço da transação. \nLucros e perdas não realizados para posições longas = (1/preço médio da posição - 1/preço da última transação) * número de contratos para posições longas * valor nominal do contrato * preço da última transação \n lucros e perdas não realizados para posições curtas = (1/ preço da última transação - 1/ Preço médio da posição) * Número de contratos curtos * Valor nominal do contrato * Preço da última transação",
        text1: "Segurança da conta",
        text2: "modo dia e noite",
        text3: "Limpar cache",
        text4: "Tem certeza que deseja sair?？",
        text5: "Tem certeza de que deseja limpar o cache?？",
        text6: "multi Idiomas",
        text7: "Para proteger sua segurança, recomendamos ativar pelo menos uma autenticação de dois fatores"
    },
    login: {
        login: "Conecte-se",
        register: "registro",
        title1: "Negocie a qualquer hora, em qualquer lugar e veja as condições do mercado em tempo real",
        title2: "Negocie a qualquer hora, em qualquer lugar e veja as condições do mercado em tempo real",
        title3: "A plataforma de negociação de criptomoedas mais confiável",
        formTitle1: "Login da conta",
        formTitle2: "Bem vindo de volta! Faça login com seu e-mail, número de telefone",
        btn1: "celular",
        btn2: "Correio",
        btn3: "Conecte-se",
        btn4: "registro",
        btn5: "esqueça a senha？",
        field1: "Número de telefone",
        field2: "senha",
        field3: "Correio",
        placeholder1: "nação",
        placeholder2: "Por favor insira o número de telefone",
        placeholder3: "Por favor insira a senha",
        placeholder4: "por favor insira seu e-mail",
        content12: "Por favor insira o número de telefone",
        content13: "Por favor insira a senha",
        content14: "por favor insira seu e-mail",
        content16: "Serviços financeiros de ativos digitais",
        loginSuccess: "login bem-sucedido ",
        chooseArea: "Selecione o código de área",
        placeholder5: "Selecione o código do país",
        placeholder6: "lembre-se da minha senha",
        placeholder7: "Baixar aplicativo",
        placeholder8: "Entre em contato com o atendimento ao cliente",
        placeholder9: "Selecione o país ou região",
        placeholder10: "Código de área",
        placeholder11: "Login da carteira",
        placeholder12: "A operação falhou, mude para",
        placeholder13: "carteira"
    },
    aboutUs: {
        aboutCoinbeadCenterTitle: "sobre",
        aboutCoinbeadCenterDetails: "Possui a plataforma de negociação de ativos blockchain líder mundial e opera todo o ecossistema.。",
        footDetails1: "volume diário de negociação",
        footDetails2: "transações/segundo",
        footDetails3: "Centro de ajuda",
        coinbeadCenterTitle: "",
        coinbeadCenterDetails: "É um site internacional mundialmente famoso para ativos digitais, fornecendo principalmente moedas e derivados de ativos digitais como Bitcoin (BTC), Litecoin (LTC) e Ethereum (ETH) para usuários globais.",
        coinbeadCenterDetailsm: "Adotando tecnologias avançadas, como GSLB, servidores de rede, transações de rede, redes, mecanismos de negociação de memória de alta velocidade para várias máquinas, carteiras frias, carteiras quentes off-line inteligentes, etc., atendemos nossos clientes com vários terminais, como telefones celulares da Web e PCs. • Fornecer serviços de transações tarifárias digitais seguros, estáveis ​​e confiáveis. Ao mesmo tempo, melhoramos e atualizamos produtos e serviços com base nas sugestões e necessidades dos usuários para fornecer melhores serviços a cada cliente, e não há fim para a melhoria da experiência inovadora do usuário. ",
        infrastructureCenterTitle: "a infraestrutura",
        infrastructureCenterDetails: "Nossa missão é fornecer serviços de infraestrutura para todo o ecossistema blockchain",
        freeCenterTitle: "livre",
        freeCenterDetails: "Nossa visão é fazer com que o dinheiro flua livremente pelo mundo. Acreditamos que espalhar esta liberdade pode tornar o mundo um lugar melhor",
        futureCenterTitle: "Pioneirismo no futuro da tecnologia",
        futureCenterDetails: "Open up the future of technology",
    },
    applicationForListing: {
        title: "Aplicativo de listagem de moedas",
        projectName: "Nome do Projeto",
        tokenName: "Nome do token",
        projectDescription: "Descrição do Projeto",
        contractAddress: "Endereço do contrato",
        officialWebsite: "website oficial",
        ContactAndEmail: "Email de contato",
        ContactAndName: "Nome de contato",
        file: "Carregar anexo",
        pleaseEnter: "Por favor, insira",
        enterFile: "Por favor carregue os arquivos",
        upload1: "Atualmente limitado a 1 seleção de arquivo，",
        upload2: "Desta vez eu escolhi",
        upload3: "arquivos，",
        upload4: "Total selecionado",
        upload5: "arquivos",
    },
    public: {
        submit: "enviar",
        copySuccessfully: "Copiado com sucesso",
        copyFailed: "Falha na cópia",
        noDate: "Sem dados",
        submitSuccessfully: "submetido com sucesso",
        goBack: "voltar para a última página",
        cancel: "Cancelar",
        home: "primeira página",
        loading: "carregando...",
        confirm: "confirme",
        loadingFailed: "anomalia de rede",
        nomore: "não mais",
        tryAgain: "Atualize a página/saia do programa e carregue novamente!",
        hot: "Áreas populares",
    },
    inviteFriends: {
        inviteFriends: "Partilhe com amigos",
        freedomOfWealth: "Cooperação ganha-ganha",
        myInvitationCode: "meu código de convite",
        clickCopy: "Clique para copiar",
        myInvitationLink: "Link do meu convite",
        clicktoSaveTheQRCode: "Clique para salvar o código QR",
        saveQRCode: "Convidar código QR",
        numberOfSubordinates: "Número de subordinados",
        rebateIncome: "Renda de desconto",
        people: "pessoas",
        myPromotionRevenue: "Minha receita promocional",
        numberOfDirectSubordinates: "Número de subordinados diretos",
        totalNumberOfSubordinates: "Número total de subordinados",
        directSubordinateReturnsCommission: "Desconto de comissão para subordinados diretos",
        theTotalCommission: "desconto total",
        checkHistory: "Ver registros de descontos",
        history: "Registro de comissão",
        theLowerAccount: "Conta subordinada",
        type: "tipo：",
        serviceCharge: "taxa de manuseio：",
        percentageofCommissionReturned: "Taxa de desconto：",
        commissionrebateAmount: "Valor do desconto：",
        time: "tempo：",
        typeName1: "Recarga e comissão",
        typeName2: "Distribuição de três níveis da taxa de manuseio de pedidos MT",
        typeName3: "Distribuição lucrativa de três níveis MT",
        typeName4: "Recompensas de indicação",
        typeName30: "Recarga e comissão",
        typeName31: "Desconto periódico de lucro comercial",
        typeName32: "Desconto de lucro de mineração de liquidez",
        typeName45: "Desconto de taxa de transação",
        typeName46: "Desconto de comissão sobre taxa de manuseio próprio",
        typeName50: "Desconto na taxa de manuseio do contrato de entrega",
        typeName51: "Desconto perpétuo na taxa de tratamento de contrato",
        typeName52: "Desconto de comissão adicional para taxas de manuseio de contrato de entrega",
        typeName53: "Desconto de comissão adicional para taxas de tratamento de contrato perpétuo",
        typeName54: "Descontos adicionais em taxas de tratamento de contratos perpétuos",
        shareRules1: "Instruções de convite",
        shareRules2: "Entre na central de convites, copie o link ou código do convite e compartilhe com seus amigos. Amigos podem se tornar seus subordinados registrando-se com seu código de convite。",
        shareRules3: "Ganhe descontos",
        shareRules4: "Quando os subordinados realizam transações, você pode obter comissões correspondentes, sendo suportados até três níveis de subordinados. Por exemplo, se você convidar o amigo A, A convida B e B convida C, então A, B e C realizam contratos e outras transações na plataforma, e todos vocês podem receber as comissões correspondentes.。",
        shareRules5: "Nível da equipe",
        shareRules6: "Quanto mais subordinados de primeiro nível você promover, maior será o nível da equipe e maior será o desconto que você poderá desfrutar. Os níveis de equipe são divididos em LV1-LV6. As regras de atualização são mostradas na tabela abaixo, onde 'N' é o número de subordinados de primeiro nível que recarregaram e completaram a autenticação de nome real.。",
        shareRules7: "Quando os subordinados fazem apostas em contratos de entrega, você pode receber comissões correspondentes às suas apostas.。",
        upgradeConditions: "Exigir",
        recommendedNumberOfPeople: "Número recomendado de pessoas"
    },
    suggestionsAndFeedback: {
        suggestionsAndFeedback: "Sugestões e comentários",
        text: "Se você encontrar bugs ou tiver sugestões de produtos durante o uso, envie-nos um feedback.",
        email: "endereço de email",
        enterEmail: "Por favor insira o endereço de e-mail",
        enterCorrectEmail: "Por favor insira o endereço de e-mail correto",
        title: "título",
        enterTitle: "Por favor insira um título de pergunta",
        describeTheProblem: "Descreva o problema",
        enterDescribeTheProblem: "Por favor insira o problema que você descreve",
        submit: "enviar",
    },
    help: {
        enterSearch: "procurar",
        title: "Centro de ajuda",
        search: "Por favor insira sua pergunta",
        text1: "Entenda suas necessidades e continue a fornecer serviços mais convenientes"
    },
    announcement: {
        enterSearch: "procurar",
        title: "Central de Anúncios",
        search: "Por favor insira sua pergunta"
    },
    privacy: {
        title: "Declaração de privacidade",
        content1: "1. Quais informações pessoais dos usuários são coletadas？",
        content2: "As informações pessoais são coletadas para fornecer produtos e serviços e para melhorá-los continuamente。",
        content3: "A seguir estão os tipos de informações pessoais que coletamos：",
        content4: "Informações que você nos fornece: Recebemos e armazenamos todas as informações que você fornece em conexão com a Plataforma. Você pode optar por não fornecer determinadas informações, mas, como resultado, poderá não conseguir usar determinados “serviços desta plataforma”. Exemplos de informações que você nos fornece incluem：",
        content5: "Nome",
        content6: "endereço",
        content7: "País de cidadania",
        content8: "Foto de identificação",
        content9: "Número de celular/e-mail",
        content10: "Outras informações que nos ajudam a identificá-lo",
        content11: "Informações coletadas automaticamente: Coletamos e armazenamos automaticamente certos tipos de informações sobre seu uso dos Serviços da Plataforma. Como muitos sites, usamos cookies e outros identificadores exclusivos. Obtemos certos tipos de informações quando seu navegador ou dispositivo acessa a Plataforma. Exemplos de informações que coletamos e analisamos incluem：",
        content12: "O endereço do protocolo da Internet (IP) que conecta um computador pessoal à Internet；",
        content13: "Informações de login do dispositivo pessoal ou computador, endereço de e-mail, senha e localização；",
        content14: "Configurações de versão e fuso horário；",
        content15: "histórico de transações。",
        content16: "Informações de outras fontes: podemos coletar informações sobre você de outras fontes, como histórico de crédito de agências de crédito. Usaremos essas informações para prevenir e detectar fraudes。",
        content17: "2. As crianças podem usá-lo?",
        content18: "Esta plataforma não permite que nenhum indivíduo menor de 18 anos utilize os “Serviços””。",
        content19: "3. Para que são utilizados os cookies e outros identificadores?？",
        content20: "Utilizamos cookies e ferramentas semelhantes para otimizar a experiência do usuário, fornecer nossos serviços e entender como os clientes usam nossos serviços para que possamos fazer melhorias direcionadas. Usamos cookies para garantir que nosso sistema possa reconhecer seu navegador ou dispositivo e fornecer serviços a você。",
        content21: 'Utilizamos cookies operacionais e ferramentas semelhantes (coletivamente "Cookies") para fornecer serviços, como：',
        content22: "Identificá-lo quando você faz login e usa nossos serviços。",
        content23: "Fornece recursos e serviços personalizados。",
        content24: "Proteja-se contra atividades fraudulentas。",
        content25: "Melhore a segurança。",
        content26: "Acompanhe suas preferências (como moeda e idioma)。",
        content27: "Também utilizamos cookies para compreender como os utilizadores utilizam os nossos serviços, para que possamos fazer melhorias específicas.。",
        content28: "4. Minhas informações pessoais serão compartilhadas?？",
        content29: "As informações do usuário são uma parte importante do nosso negócio e não venderemos as informações pessoais dos usuários a terceiros. Esta plataforma só compartilhará informações pessoais dos usuários com suas subsidiárias ou afiliadas nas seguintes circunstâncias: Essas subsidiárias ou afiliadas são obrigadas a cumprir esta declaração de privacidade ou pelo menos seguir práticas que sejam tão protetoras quanto as medidas de proteção descritas nesta declaração de privacidade.。",
        content30: "Prestadores de serviços terceirizados: Contratamos outras empresas e indivíduos para desempenhar funções em nosso nome. Exemplos de tais funções incluem: análise de dados, prestação de assistência de marketing, processamento de pagamentos, transmissão de conteúdo e avaliação e gestão de risco de crédito. Esses prestadores de serviços terceirizados têm acesso às informações pessoais necessárias para desempenhar suas funções, mas não podem utilizá-las para quaisquer outros fins. Além disso, devem processar informações pessoais em conformidade com esta declaração de privacidade e com as leis de proteção de dados aplicáveis.。",
        content31: "Transferências Empresariais: À medida que o nosso negócio continua a crescer, poderemos vender ou comprar outros negócios ou serviços. Nessas transações, as informações do usuário geralmente estão entre os ativos comerciais transferidos, mas permanecem sujeitas às promessas de qualquer declaração de privacidade pré-existente (a menos, é claro, que o usuário concorde de outra forma). Além disso, se a nossa empresa ou substancialmente todos os seus ativos fossem adquiridos, as informações do usuário também seriam transferidas. Proteção de nossa empresa e de terceiros: Quando acreditamos que a divulgação de contas e outras informações pessoais é apropriada para cumprir a lei ou nossas obrigações regulatórias, fazer cumprir ou aplicar nossos Termos de Uso e outros acordos, ou os direitos, propriedade ou segurança de nossos usuários ou outros Quando, divulgaremos a conta e outras informações pessoais. Isto inclui a troca de informações com outras empresas e organizações para proteção contra fraudes e redução do risco de crédito.。",
        content32: "5. Transferências internacionais de dados pessoais",
        content33: 'Poderemos transferir os seus dados para fora do Espaço Económico Europeu ("EEE"). Implementaremos salvaguardas adequadas para garantir que tais transferências cumpram as regras de proteção de dados aplicáveis, a menos que a Comissão Europeia tenha confirmado que o país para o qual os dados são transferidos oferece um nível adequado de proteção。',
        content34: "6. A minha informação pessoal está segura?？",
        content35: "Projetamos nossos sistemas pensando na sua segurança e privacidade. Usamos protocolos e software de criptografia para nos esforçarmos para proteger a segurança das informações pessoais durante a transmissão.。",
        content36: "Sempre adotamos salvaguardas físicas, eletrônicas e processuais ao coletar, armazenar e divulgar informações pessoais. Nossos procedimentos de segurança significam que talvez precisemos verificar sua identidade antes de divulgar informações pessoais a você。",
        content37: "A coisa mais importante que você pode fazer é proteger as senhas de suas contas pessoais contra acesso não autorizado. Recomendamos definir uma senha exclusiva para sua conta, diferente de outras contas online. E certifique-se de sair depois de usar um computador compartilhado。",
        content38: "7. Como devo proteger as minhas informações pessoais?",
        content39: "Se você tiver alguma dúvida ou objeção sobre a forma como coletamos e processamos informações pessoais, entre em contato com a equipe de atendimento ao cliente",
        content40: "Depois de concordar que processemos suas informações pessoais para uma finalidade específica, você poderá retirar sua intenção a qualquer momento e deixaremos de processar seus dados para essa finalidade.。",
        content41: "Além disso, você tem o direito de solicitar o acesso, a retificação e a exclusão de dados pessoais e de solicitar a portabilidade dos dados, sujeito às leis aplicáveis. Você também pode se opor ao processamento dos seus dados pessoais ou solicitar que o processamento dos seus dados pessoais seja restringido em determinadas circunstâncias.",
        content42: "8. GDPR da UE e Lei de Proteção de Dados do Reino Unido – Base Jurídica",
        content43: "O GDPR da UE e a lei de proteção de dados do Reino Unido exigem que cumpramos a base legal ao usar informações pessoais. Nossa base depende dos propósitos específicos para os quais as informações pessoais são utilizadas. Essas bases incluem：",
        content44: "Execução de um contrato – para fornecer ou comunicar consigo sobre produtos ou serviços, incluindo a utilização das suas informações pessoais para aceitar e processar encomendas e processar pagamentos.。",
        content45: "Nossos interesses comerciais legítimos e interesses dos usuários - detectamos e prevenimos fraudes e abusos e protegemos a segurança de nossos usuários, de nós mesmos ou de terceiros。",
        content46: "Seu consentimento – Exigimos o seu consentimento para processar suas informações pessoais para os fins específicos que lhe foram comunicados. Depois de nos ter dado o seu consentimento para processar as suas informações pessoais para uma finalidade específica, poderá retirar o seu consentimento a qualquer momento e deixaremos de processar os seus dados para essa finalidade.。",
        content47: "Cumprir as obrigações legais - Utilizamos as suas informações pessoais conforme exigido por lei. Por exemplo, coletamos informações de contas bancárias para fins de verificação de identidade。",
        content48: "O conteúdo acima e outras bases legais dependem dos fins específicos para os quais utilizamos as informações pessoais.。",
        content49: "9. Condições de uso, avisos e alterações",
        content50: "Seu uso e qualquer disputa sobre privacidade estão sujeitos a esta Declaração e aos nossos Termos de Uso. Se você tiver alguma dúvida sobre a privacidade desta plataforma, entre em contato conosco com uma explicação detalhada e teremos prazer em resolver o problema para você. Você também tem o direito de entrar em contato com a autoridade local de proteção de dados。",
        content51: "Nosso negócio está em constante mudança, assim como nossa declaração de privacidade. Você deve verificar nosso site com frequência para ver as alterações mais recentes. Caso você não concorde com o conteúdo revisado, deverá interromper o acesso imediatamente. Após o lançamento da versão atualizada da Política de Privacidade, seu acesso contínuo significa que você concorda com o conteúdo atualizado e concorda em cumprir a Política de Privacidade atualizada. Salvo indicação em contrário, a atual Declaração de Privacidade aplica-se a todas as informações que mantemos sobre você e sua conta。",
    },
    serviceAgreement: {
        title: "Acordo de serviço",
        content1: 'Este site é uma plataforma específica para os usuários realizarem transações de ativos digitais e fornecerem serviços relacionados (doravante denominado "o serviço" ou "o serviço"). Para a conveniência de descrever este contrato, este site usa coletivamente “nós” ou outros pronomes de primeira pessoa neste contrato. Desde que a pessoa física ou outro sujeito que faça login no site seja um usuário deste site, para a conveniência de expressar este acordo, “você” ou outra segunda pessoa será utilizado abaixo. Para a conveniência da apresentação deste Contrato, nós e você somos coletivamente referidos como as "Partes" neste Contrato, e nós ou você somos individualmente referidos como uma "Parte".”。',
        content2: "dica importante：",
        content3: "Gostaríamos de lembrá-lo aqui：",
        content4: "1 Os ativos digitais em si não são emitidos por nenhuma instituição ou empresa financeira ou por este site",
        content5: "2 Os mercados de ativos digitais são novos, não confirmados e podem não crescer；",
        content6: "3 Os ativos digitais são usados ​​principalmente por especuladores, com relativamente pouco uso nos mercados de varejo e comerciais. As transações de ativos digitais são extremamente arriscadas. Eles são negociados continuamente ao longo do dia, sem limite de subida e descida, e os preços são facilmente afetados pelo mercado. decisores e políticas governamentais globais.；",
        content7: "4  A Empresa reserva-se o direito de suspender ou encerrar sua conta a qualquer momento se a Empresa determinar, a seu exclusivo critério, que você violou este Contrato, ou que os serviços fornecidos por este site ou o uso dos serviços fornecidos por este site são ilegal sob as leis de sua jurisdição., ou suspender ou encerrar seu uso dos serviços ou transações de ativos digitais fornecidos por este site. Qualquer pessoa de [China continental, Taiwan, Israel, Iraque, Bangladesh, Bolívia, Equador, Quirguistão, Sebastopol e Reino Unido (usuários de varejo)] está proibida de usar os serviços de transação contratual fornecidos por este site. A lista de países ou regiões mencionada acima mudará dependendo das políticas e dos tipos de produtos dos diferentes países ou regiões. Talvez não notifiquemos você especificamente nesse momento. Preste atenção às atualizações deste contrato em tempo hábil.。",
        content8: "A negociação de ativos digitais acarreta riscos extremamente elevados e não é adequada para a maioria das pessoas. Você entende e compreende que esta transação pode resultar em perda parcial ou total, portanto, você deve determinar o valor da transação com base no grau de perda que pode arcar. Você sabe e entende que os ativos digitais gerarão riscos derivativos, portanto, se tiver alguma dúvida, é recomendável procurar primeiro a assistência de consultores profissionais. Além dos riscos mencionados acima, também existem riscos que não podem ser previstos. Você deve considerar cuidadosamente e usar um julgamento claro para avaliar sua situação financeira e os riscos mencionados acima antes de tomar qualquer decisão de comprar ou vender ativos digitais e arcar com todas as perdas resultantes. Não assumimos qualquer responsabilidade por isso.。",
        content9: "Aviso para você：",
        content10: "1 Você entende que este site é usado apenas como um local para você obter informações sobre ativos digitais, encontrar partes em transações, negociar e realizar transações em ativos digitais. Este site não participa de nenhuma de suas transações, portanto, você deve usar seu próprio julgamento prudente determinar os ativos e/ou transações digitais relevantes ou a autenticidade, legalidade e validade das informações, e deverá arcar com as responsabilidades e perdas resultantes por conta própria。",
        content11: "2 Quaisquer opiniões, notícias, discussões, análises, preços, recomendações e outras informações contidas neste site são comentários gerais de mercado e não constituem conselhos de investimento. Não somos responsáveis ​​por quaisquer perdas decorrentes direta ou indiretamente da confiança nesta informação, incluindo, mas não limitado a, qualquer perda de lucros.。",
        content12: "3 Este site reserva-se o direito de tomar sua própria decisão, modificar ou alterar o conteúdo a qualquer momento. Tomamos medidas razoáveis ​​para garantir a exatidão das informações no site, mas não podemos garantir sua exatidão e não seremos responsáveis ​​por qualquer perda ou dano causado pelas informações contidas neste site ou pela falta de informações. Perdas diretas ou indiretas decorrentes de atrasos ou falhas na conexão com a Internet, transmissão ou recebimento de quaisquer avisos e informações。",
        content13: "4 Existem também riscos na utilização de sistemas de negociação baseados na Internet, incluindo, entre outros, falhas de software, hardware e de ligação à Internet. Como não temos controle sobre a confiabilidade e disponibilidade da Internet, não assumimos qualquer responsabilidade por distorções, atrasos e falhas de conexão.。",
        content14: "5 É proibido usar este site para se envolver em todas as atividades comerciais ilegais ou atividades ilegais, como lavagem de dinheiro, contrabando, suborno comercial, etc. Se forem encontradas quaisquer suspeitas de transações ilegais ou atividades ilegais, este site usará vários meios disponíveis, incluindo, mas não se limitando a congelar contas e notificar as partes relevantes, autoridades, etc., não assumimos todas as responsabilidades daí decorrentes e reservamo-nos o direito de reclamar responsabilidades de pessoas relevantes.。",
        content15: "6 É proibido usar este site para manipular maliciosamente o mercado, transações impróprias e outras atividades comerciais antiéticas. Se tais incidentes forem descobertos, este site avisará, restringirá transações e encerrará todos os comportamentos antiéticos, como manipulação maliciosa de preços e influência maliciosa sobre o sistema de negociação. Não assumimos qualquer responsabilidade decorrente de medidas de proteção preventiva, como suspensão de conta, e nos reservamos o direito de exigir responsabilidade de pessoas relevantes.。",
        content16: "1、Princípios gerais",
        content17: '1.1 O "Contrato do Usuário" (doravante denominado "este Contrato" ou "Estes Termos e Condições") consiste no texto principal, "Termos de Privacidade", "Conheça Seu Cliente e Política Antilavagem de Dinheiro" e várias outras políticas que têm foram publicados ou poderão ser publicados no futuro neste site. Regras de classe, declarações, descrições, etc.。',
        content18: "1.2 Você deve ler este contrato com atenção antes de utilizar os serviços prestados por este site. Se você não entender alguma coisa ou se for necessário, consulte um advogado profissional. Se você não concordar com este Contrato e/ou sua modificação a qualquer momento, pare imediatamente de usar os serviços fornecidos por este site ou não faça mais login neste site. Depois de fazer login neste site, usar qualquer serviço deste site ou qualquer outro comportamento semelhante, significa que você entendeu e concordou totalmente com o conteúdo deste Contrato, incluindo quaisquer modificações feitas por este site neste Contrato a qualquer momento.。",
        content19: '1.3 Você pode se tornar um membro deste site (doravante denominado "membro") preenchendo as informações relevantes de acordo com os requisitos deste site e registrando-se com sucesso após outros procedimentos relevantes. Ao clicar no botão "Concordo" durante o registro processo, você assina eletronicamente. Formulário de acordo com a empresa; ou quando você clica em qualquer botão marcado como "Concordo" ou significado semelhante durante o uso deste site, ou realmente usa os serviços fornecidos por este site de outras maneiras permitidas por este site , significa que você compreende, concorda e aceita totalmente todos os termos deste acordo. A ausência de sua assinatura manuscrita não afetará a força legal vinculativa deste acordo para você.。',
        content20: "1.4 Depósitos de tornar-se membro deste site, você recebe uma conta de membro e uma senha correspondente, que você é responsável por manter; você será legalmente responsável por todas as atividades e eventos realizados sob sua conta.。",
        content21: "1.5 Somente os membros que se tornarem membros deste site podem utilizar a plataforma de negociação de ativos digitais fornecida por este site para transações e usufruir de outros serviços estipulados por este site que estão disponíveis apenas para membros; os não membros só podem fazer login no site, navegar no site e outros serviços estipulados por este site.。",
        content22: "1.6 Ao registrar-se e utilizar quaisquer serviços e funções fornecidos por este site, será considerado que você leu, compreendeu e：",
        content23: "1.6.1 Aceite todos os termos e condições deste Contrato。",
        content24: "1.6.2 Você confirma que tem mais de 18 anos ou idade legal para celebrar contratos de acordo com as diferentes leis aplicáveis. Seu registro neste site, vendas ou compras, publicação de informações, etc. e aceitação dos serviços deste site devem estar em conformidade com suas necessidades. Ter jurisdição sobre as leis e regulamentos relevantes de um país ou região soberana e ter plena capacidade de aceitar estes termos, realizar transações e usar este site para transações de ativos digitais.。",
        content25: "1.6.3 Você garante que todos os ativos digitais envolvidos na transação que lhe pertencem são obtidos legalmente e possuem propriedade。",
        content26: "1.6.4 Você concorda que é o único responsável por suas próprias atividades comerciais ou não comerciais e por quaisquer ganhos ou perdas。",
        content27: "1.6.5 Você confirma que as informações fornecidas durante o registro são verdadeiras e precisas。",
        content28: "1.6.6 Você concorda em cumprir os requisitos de qualquer lei relevante para fins fiscais, incluindo a declaração de quaisquer lucros comerciais。",
        content29: "1.6.7 Você concorda em não se envolver ou participar de comportamentos ou atividades que prejudiquem os interesses deste site ou da empresa a qualquer momento, relacionados ou não aos serviços prestados por este site.。",
        content30: "1.6.8 Este acordo rege apenas os direitos e obrigações entre você e nós, e não envolve as relações jurídicas e disputas legais decorrentes de transações de ativos digitais entre usuários deste site e de outros sites e você.。",
        content31: "2、Alteração do Contrato",
        content32: "Reservamo-nos o direito de revisar este Contrato de tempos em tempos e anunciá-lo no site sem notificá-lo separadamente. Após a revisão, o tempo de revisão será marcado em [Hora da Última Atualização] exibido neste Contrato, e levará automaticamente efeito uma vez publicado no site. Você deve navegar e prestar atenção ao tempo de atualização e ao conteúdo atualizado deste Contrato de tempos em tempos. Se você não concordar com as alterações relevantes, você deve parar imediatamente de usar os serviços deste site; seu uso continuado dos serviços deste site site significa que você aceita e concorda em ficar vinculado ao acordo revisado.。",
        content33: "3. Registro",
        content34: "3.1 Qualificações de registro",
        content35: "Você confirma e promete que ao concluir o processo de registro ou realmente usar os serviços fornecidos por este site de outras formas permitidas por este site, você poderá assinar este contrato e usar os serviços deste site conforme estipulado pelas leis aplicáveis. pessoa física, pessoa jurídica ou outra organização. Depois de clicar no botão Concordo em registrar-se, significa que você ou seu agente autorizado concordou com o conteúdo do contrato e foi registrado e utilizou os serviços deste site por seu agente. Se você não tiver as qualificações acima mencionadas, você e seu agente autorizado arcarão com todas as consequências daí resultantes, e a empresa reserva-se o direito de cancelar ou congelar permanentemente sua conta e responsabilizar você e seu agente autorizado.。",
        content36: "3.2 Finalidade do registro",
        content37: "Você confirma e promete que seu registro neste site não tem como objetivo violar leis e regulamentos ou perturbar a ordem das transações de ativos digitais neste site.",
        content38: "3.3Processo de registro",
        content39: "3.3.1 Você concorda em fornecer um endereço de e-mail válido, número de celular e outras informações conforme exigido na página de registro do usuário deste site. Você pode usar o endereço de e-mail, número de celular fornecido ou confirmado ou outros métodos permitidos por este site como login método para entrar neste site. Se necessário, de acordo com as leis e regulamentos relevantes de diferentes jurisdições, você deverá fornecer seu nome real, documento de identidade e outras informações relevantes estipuladas em leis e regulamentos, cláusulas de privacidade e cláusulas contra lavagem de dinheiro, e atualizar constantemente as informações de registro em de maneira oportuna, detalhada e precisa. Todas as informações digitadas originalmente serão referenciadas como informações de registro. Você é responsável pela autenticidade, integridade e exatidão de tais informações e arca com quaisquer perdas diretas ou indiretas e consequências adversas daí decorrentes.。",
        content40: "3.3.2 Se as leis, regulamentos, regras, ordens e outros regulamentos do país soberano ou região onde você está localizado exigirem nomes reais para números de telefone celular, você concorda que o número de telefone celular registrado fornecido foi registrado com nome real. você não o fornecer de acordo com os regulamentos, quaisquer consequências causadas a você serão perdas diretas ou indiretas e as consequências adversas serão suportadas por você。",
        content41: "3.3.3 Se você fornecer as informações necessárias para o registro de forma legal, completa e eficaz e as tiver verificado, terá o direito de obter o número da conta e a senha deste site. Ao obter o número da conta e a senha deste site, considera-se que o seu registro for bem-sucedido e você poderá fazer login como membro neste site.。",
        content42: "3.3.4 Você concorda em receber e-mails e/ou mensagens curtas enviadas por este site relacionadas ao gerenciamento e operação deste site.。",
        content43: "4. Serviço",
        content44: "Este site fornece apenas serviços de plataforma de negociação on-line para suas atividades de negociação de ativos digitais (incluindo, entre outros, negociação de ativos digitais e outros serviços) através deste site.。",
        content45: "4.1 Conteúdo do serviço",
        content46: "4.1.1 Você tem o direito de navegar pelas condições de mercado em tempo real e pelas informações de transação de vários produtos de ativos digitais neste site, e tem o direito de enviar instruções de transação de ativos digitais e concluir transações de ativos digitais por meio deste site.。",
        content47: "4.1.2 Você tem o direito de visualizar as informações da sua conta de membro neste site e de aplicar as funções fornecidas por este site para operar。",
        content48: "4.1.3 Você tem o direito de participar das atividades do site organizadas por este site de acordo com as regras de atividade publicadas neste site.。",
        content49: "4.1.4 Outros serviços que este site promete fornecer a você。",
        content50: "4.2 Regras de serviço Você se compromete a cumprir as seguintes regras de serviço deste site：",
        content51: "4.2.1 Você deve cumprir as leis, regulamentos e requisitos de política, garantir a legalidade das fontes de todos os ativos digitais em sua conta e não se envolver em atividades ilegais ou outras atividades que prejudiquem os direitos e interesses deste site ou de terceiros neste site. ou usando seus serviços, incluindo, mas não se limitando a: Não se limitando a enviar ou receber qualquer informação ilegal, ilegal ou que viole os direitos de terceiros, enviar ou receber esquemas de pirâmide ou outras informações ou comentários prejudiciais, usar ou falsificar informações de cabeçalho de e-mail neste site sem autorização deste site, etc.。",
        content52: "4.2.2 Você deve cumprir as leis e regulamentos e usar e manter adequadamente seu número de conta, senha de login, senha de fundo, número de celular vinculado a você durante o registro e o código de verificação de celular recebido por seu celular. Você é totalmente responsável por quaisquer operações e consequências do uso de sua conta e senha de login, senha de fundos e código de verificação de telefone celular. Quando você descobrir que o número da conta, a senha de login, a senha do fundo ou o código de verificação deste site são usados ​​por terceiros sem a sua autorização, ou há outros problemas de segurança da conta, você deve notificar imediatamente e efetivamente este site e solicitar a suspensão do site. o serviço da conta deste site. Este site tem o direito de agir de acordo com sua solicitação dentro de um prazo razoável, mas não assume qualquer responsabilidade pelas consequências (incluindo, entre outras, quaisquer perdas que você sofra) que ocorreram antes de tomar medidas. Você não pode doar, emprestar, arrendar, transferir ou de outra forma alienar sua conta do site a terceiros sem o consentimento deste site.。",
        content53: "4.2.3 Você concorda em ser responsável por todas as atividades que ocorram sob sua conta e senha neste site (incluindo, entre outras, divulgação de informações, publicação de informações, cliques on-line para concordar ou envio de diversas regras e acordos, renovação on-line de acordos ou compra de serviços, etc.)。",
        content54: "4.2.4 Ao realizar transações de ativos digitais neste site, você não deve interferir maliciosamente no progresso normal das transações de ativos digitais ou perturbar a ordem das transações; você não deve interferir na operação normal deste site ou interferir no uso deste site por outros usuários. serviços por quaisquer meios técnicos ou outros meios; você não deve usar meios fictícios que difamem maliciosamente a boa vontade deste site através de fatos e outros meios。",
        content55: "4.2.5 Se você tiver uma disputa com outros usuários devido a transações on-line, você não poderá solicitar que este site forneça informações relevantes através de canais não judiciais ou administrativos.。",
        content56: "4.2.6 Você será o único a julgar e arcar com quaisquer impostos a pagar e todas as despesas de hardware, software, serviços e outras despesas incorridas durante o uso dos serviços fornecidos por este site.。",
        content57: "4.2.7 Você deve cumprir este contrato e outros termos de serviço e regras operacionais publicados e atualizados por este site de tempos em tempos, e tem o direito de encerrar o uso dos serviços fornecidos por este site a qualquer momento.。",
        content58: "4.3 regras do produto",
        content59: "4.3.1 Procure informações de transação",
        content60: "Ao navegar pelas informações de transação neste site, você deve ler atentamente todo o conteúdo contido nas informações de transação, incluindo, entre outros, preço, volume de comissão, taxa de manuseio, direção de compra ou venda, e somente depois de aceitar totalmente todo o conteúdo contido em as informações da transação Negocie com o clique de um botão。",
        content61: "4.3.2 Ver detalhes da transação",
        content62: "Você pode visualizar os registros de transações correspondentes através de sua conta。",
        content63: "5. Direitos e obrigações deste site",
        content64: "5.1 Se você não possui as qualificações de registro estipuladas neste contrato, este site tem o direito de recusar seu registro. Para aqueles que já se registraram, este site tem o direito de cancelar sua conta de membro. Este site reserva-se o direito de retê-lo ou seu agente autorizado responsável está certo. Ao mesmo tempo, este site reserva-se o direito de decidir se aceita o seu registo em quaisquer outras circunstâncias.。",
        content65: "5.2 Com base no julgamento do próprio site, se este site descobrir que você ou os usuários de sua conta associada não são adequados para investimentos de alto risco, temos o direito de suspender ou encerrar sua conta e o uso de todas as contas associadas.。",
        content66: "5.3 Quando este site descobre que o usuário da conta não é o registrante inicial da conta, ele tem o direito de suspender ou encerrar o uso da conta.。",
        content67: "5.4 Quando este site suspeita razoavelmente que as informações que você forneceu são erradas, falsas, inválidas ou incompletas através de testes técnicos, amostragem manual e outros métodos de teste, ele tem o direito de notificá-lo para corrigir ou atualizar as informações ou para suspender ou encerrar o fornecimento de este site presta serviços para você.。",
        content68: "5.5 Este site reserva-se o direito de corrigir qualquer informação exibida neste site quando for descoberto que há um erro óbvio.。",
        content69: "5.6 Este site reserva-se o direito de modificar, suspender ou encerrar os serviços deste site a qualquer momento. Este site não precisa notificá-lo com antecedência para exercer o direito de modificar ou encerrar serviços. Se este site encerrar um ou mais serviços deste site site, a rescisão será deste site no site. Em vigor na data de publicação do anúncio de rescisão。",
        content70: "5.7 Este site tomará os meios técnicos e medidas de gestão necessários para garantir o funcionamento normal deste site, fornecer um ambiente de negociação e serviços de negociação necessários e confiáveis ​​e manter a ordem das transações de ativos digitais.。",
        content71: "5.8 Se você não fizer login neste site usando sua conta de membro e senha por um ano consecutivo, este site terá o direito de cancelar sua conta. Após o cancelamento da conta, este site tem o direito de abrir o nome do membro correspondente a outros usuários para registro e uso.。",
        content72: "5.9 Este site garante a segurança dos seus ativos digitais, fortalecendo o investimento em tecnologia, melhorando as precauções de segurança e outras medidas, e irá notificá-lo antecipadamente quando surgirem riscos de segurança previsíveis na sua conta.。",
        content73: "5.10 Este site tem o direito de excluir a qualquer momento todos os tipos de informações de conteúdo deste site que não estejam em conformidade com as leis e regulamentos ou com os regulamentos deste site. Este site não precisa notificá-lo com antecedência para exercer tais direitos.。",
        content74: "5.11Este site tem o direito de solicitar que você forneça mais informações ou materiais de acordo com as leis, regulamentos, regras, ordens e outros requisitos regulamentares do seu país ou região soberana, e que tome medidas razoáveis ​​para cumprir os requisitos regulamentares locais. cooperar; este site tem o direito de suspender ou parar permanentemente de abrir alguns ou todos os serviços deste site para você de acordo com as leis, regulamentos, regras, ordens e outros requisitos de seu país ou região soberana.。",
        content75: "5.12 Este site reserva-se o direito de encerrar sua conta mediante aviso prévio de sete dias úteis, a seu exclusivo critério. Portanto, você tem sete dias úteis para cancelar pedidos e fechar posições. Se você não cancelou a ordem ou fechou a posição quando o período expirar, cancelaremos a ordem à força e fecharemos a posição, e devolveremos os ativos digitais restantes em sua conta para você.。",
        content76: "5.13 A fim de proteger os direitos e interesses dos comerciantes, este site reserva-se o direito de fazer ajustes nos produtos online em circunstâncias especiais (como falha do sistema, falha da rede, condições extremas de mercado, etc.), tais como: contratos de entrega antecipada e liquidação, tipos de contratos e contratos de entrega antecipada e liquidação. O ciclo e os preços de liquidação e entrega estão sujeitos a divulgação neste site.。",
        content77: "6. Compensação",
        content78: "6.1 Em nenhum caso nossa responsabilidade por danos diretos excederá o total das taxas de serviço que cobramos pelo uso deste site por um período de três (3) meses.。",
        content79: "6.2 Se você violar este Contrato ou outras leis e regulamentos, deverá nos compensar em pelo menos US$ 2 milhões e arcar com todos os custos incorridos (incluindo honorários advocatícios, etc.). Se não for suficiente para cobrir as perdas reais, você deverá compensar por isso.。",
        content80: "7. Direito de buscar medida cautelar",
        content81: "Nós e você reconhecemos que as soluções de direito consuetudinário para sua violação de contrato ou possível violação de contrato podem não ser suficientes para compensar todas as perdas que sofremos, portanto, temos o direito de buscar medida cautelar conforme permitido pela lei consuetudinária ou equidade no caso de sua quebra de contrato ou possível quebra de contrato. Todas as outras soluções。",
        content82: "8. Limitação de responsabilidade e isenção de responsabilidade",
        content83: "8.1 Você entende e concorda que, sob nenhuma circunstância, não seremos responsáveis ​​pelos seguintes assuntos：",
        content84: "8.1.1 perda de renda；",
        content85: "8.1.2 Lucros comerciais ou perdas contratuais；",
        content86: "8.1.3 Perdas causadas por interrupção de negócios；",
        content87: "8.1.4 Perda de poupanças monetárias esperadas；",
        content88: "8.1.5 Perdas causadas por problemas de informação；",
        content89: "8.1.6 Perda de oportunidade, boa vontade ou reputação；",
        content90: "8.1.7 Corrupção ou perda de dados；",
        content91: "8.1.8 Custo de aquisição de produtos ou serviços substitutos；",
        content92: "8.1.9 Qualquer perda ou dano indireto, especial ou incidental decorrente de ato ilícito (incluindo negligência), quebra de contrato ou qualquer outro motivo, seja ou não tal perda ou dano razoavelmente previsível por nós; quer tenhamos ou não sido informados da existência de tal antecipadamente Possibilidade de perda ou dano。",
        content93: "8.1.1 As cláusulas de 8.1.9 são independentes umas das outras。",
        content94: "8.2 Você entende e concorda que não seremos responsáveis ​​por quaisquer danos causados ​​a você por qualquer uma das seguintes circunstâncias:：",
        content95: "8.2.1 Suas transações específicas podem envolver violações graves de leis ou quebras de contrato。",
        content96: "8.2.2 Seu comportamento neste site é suspeito de ser ilegal ou imoral。",
        content97: "8.2.3 Custos e perdas incorridos na compra ou obtenção de quaisquer dados, informações ou realização de transações através dos serviços deste site ou outras ações substitutas。",
        content98: "8.2.4 Seu mal-entendido sobre os serviços deste site。",
        content99: "8.2.5 Quaisquer outras perdas relacionadas com os serviços prestados por este site que não sejam causadas por nós。",
        content100: "8.3 Somos responsáveis ​​pela manutenção de equipamentos de rede de informação, falha de conexão de rede de informação, falha de computador, comunicação ou outro sistema, falha de energia, condições climáticas, acidentes, greves, disputas trabalhistas, motins, revoltas, tumultos, produtividade ou materiais de produção insuficientes, incêndio, inundações , tempestades, explosões, guerras, motivos bancários ou outros motivos de parceiros, colapso do mercado de ativos digitais, ações governamentais, ordens de órgãos judiciais ou administrativos, outras ações que estão além do nosso controle ou que não temos capacidade de controlar, ou motivos de terceiros. não assuma qualquer responsabilidade pela incapacidade ou atraso no serviço, ou por suas perdas.。",
        content101: "8.4 Não podemos garantir que todas as informações, programas, textos, etc. contidos neste site sejam completamente seguros e não sofrerão interferência ou serão destruídos por quaisquer vírus, cavalos de Tróia e outros programas maliciosos. Portanto, se você fizer login e usar qualquer serviço no este site ou baixar e usar quaisquer programas baixados, informações, dados, etc. são todas suas decisões pessoais e você arca com os riscos e possíveis perdas por sua própria conta e risco.。",
        content102: "8.5 Não oferecemos quaisquer garantias ou compromissos sobre quaisquer informações, produtos e serviços de quaisquer sites de terceiros vinculados a este site ou qualquer outra forma de conteúdo que não nos pertença. Se você usar quaisquer serviços, informações e serviços fornecidos por terceiros sites de terceiros, produtos, etc. são todas decisões pessoais suas e você assume todas as responsabilidades daí decorrentes.。",
        content103: "8.6 Não oferecemos nenhuma garantia expressa ou implícita quanto ao uso dos serviços deste site, incluindo, entre outros, a adequação dos serviços fornecidos por este site, ausência de erros ou omissões, continuidade, precisão, confiabilidade e adequação para um determinado propósito. Ao mesmo tempo, não assumimos qualquer compromisso ou garantia quanto à validade, exatidão, correção, confiabilidade, qualidade, estabilidade, integridade e atualidade da tecnologia e informação envolvida nos serviços prestados por este site. A decisão de fazer login ou utilizar os serviços prestados por este site é sua e você assume seus próprios riscos e possíveis perdas. Não oferecemos nenhuma garantia expressa ou implícita em relação ao mercado, valor e preço dos ativos digitais. Você entende e entende que o mercado de ativos digitais é instável. Os preços e valores podem flutuar ou cair significativamente a qualquer momento. A negociação de ativos digitais é sua liberdade pessoal. Escolha e decida por sua própria conta e risco e possíveis perdas.。",
        content104: "8.7 Nossas garantias e compromissos estabelecidos neste Contrato são as únicas garantias e representações feitas por nós em relação a este Contrato e aos serviços prestados neste site e substituem quaisquer garantias e compromissos decorrentes de qualquer outra forma, seja escrita ou oral, expressa de ou implícito. Todas essas garantias e representações representam apenas nossos próprios compromissos e garantias e não garantem que terceiros cumprirão as garantias e compromissos deste Contrato.。",
        content105: "8.8 Não renunciamos a quaisquer direitos não mencionados neste Contrato para limitar, excluir ou compensar a nossa responsabilidade por danos em toda a extensão da lei aplicável.。",
        content106: "8.9 Após o registro, você concorda com quaisquer operações que realizamos de acordo com as regras estipuladas neste contrato, e quaisquer riscos incorridos serão suportados por você.。",
        content107: "9. Rescisão do Contrato",
        content108: "9.1 Este site tem o direito de rescindir todos os serviços deste site de acordo com as disposições deste contrato. Este contrato será rescindido na data de rescisão de todos os serviços deste site.。",
        content109: "9.2 Após a rescisão deste contrato, você não tem o direito de exigir que este site continue a fornecer quaisquer serviços ou executar quaisquer outras obrigações, incluindo, entre outras, exigir que este site retenha ou divulgue a você qualquer informação em sua conta original do site. para você ou um terceiro. O terceiro encaminha qualquer informação que não tenha lido ou enviado, etc.。",
        content110: "9.3 A rescisão deste acordo não afetará a exigência da parte observadora de arcar com outras responsabilidades contra a parte infratora.。",
        content111: "10. Direitos de propriedade intelectual",
        content112: "10.1 Todas as realizações intelectuais contidas neste site incluem, mas não estão limitadas a, logotipos de sites, bancos de dados, designs de sites, textos e gráficos, software, fotos, vídeos, músicas, sons e combinações dos mesmos, compilações de software, códigos-fonte relacionados e software (incluindo pequenos aplicativos ) Os direitos de propriedade intelectual de programas e scripts) pertencem a este site. Você não pode reproduzir, alterar, copiar, enviar ou usar qualquer um dos materiais ou conteúdos acima para fins comerciais。",
        content113: "10.2 Todos os direitos contidos no nome deste site (incluindo, mas não limitado a boa vontade e marcas registradas, logotipos) pertencem à empresa。",
        content114: "10.3 A aceitação deste acordo será considerada como sua iniciativa de transferir os direitos autorais de qualquer forma de informação que você publicar neste site, incluindo, mas não limitado a: direitos de reprodução, direitos de distribuição, direitos de aluguel, direitos de exibição, direitos de performance, direitos de exibição, direitos de transmissão, direitos de transmissão de informações na Internet, direitos de filmagem, direitos de adaptação, direitos de tradução, direitos de compilação e outros direitos transferíveis que devem ser usufruídos pelo titular dos direitos autorais são transferidos exclusivamente para este site gratuitamente. Este site tem o direito de registrar uma declaração independente ação judicial contra qualquer sujeito por infração e obter indenização integral. Este acordo é válido para qualquer conteúdo protegido pela lei de direitos autorais que você publicar neste site, seja o conteúdo formado antes da assinatura deste acordo ou após a assinatura deste acordo.。",
        content115: "10.4 Você não pode usar ou descartar ilegalmente os direitos de propriedade intelectual deste site ou de outros ao usar os serviços deste site. Você não pode publicar as informações publicadas neste site de qualquer forma ou autorizar outros sites (e meios de comunicação) a usá-las.。",
        content116: "11. Proteção de informações",
        content117: "Prevalecerão os termos dos “Termos de Privacidade” publicados separadamente neste site.。",
        content118: "12. Cálculo",
        content119: "Todos os resultados dos cálculos das transações foram verificados por nós e todos os métodos de cálculo foram publicados no site, mas não podemos garantir que o uso do site não será interrompido ou livre de erros。",
        content120: "13. Controle de Exportação",
        content121: "Você entende e reconhece que, de acordo com as leis relevantes, não está autorizado a exportar, reexportar, importar ou transferir quaisquer materiais (incluindo software) neste site, portanto você garante que não implementará ativamente, ajudará ou participará de qualquer um dos as exportações ou atividades relacionadas acima mencionadas que violam leis e regulamentos. Transferência ou outras violações de leis e regulamentos aplicáveis; se tal situação for descoberta, você deve reportá-la ativamente para nós em tempo hábil e nos ajudar a lidar com ela.。",
        content122: "14. Transferência",
        content123: "Os direitos e obrigações estipulados neste Contrato vincularão também os cessionários, herdeiros, executores e administradores das partes que se beneficiem de tais direitos e obrigações. Você não pode transferi-lo a terceiros sem o nosso consentimento, mas podemos transferir nossos direitos e obrigações neste Contrato a terceiros a qualquer momento e notificá-lo。",
        content124: "15. Divisibilidade",
        content125: "Se qualquer disposição deste Contrato for considerada inexequível, inválida ou ilegal por qualquer tribunal de jurisdição competente, isso não afetará a validade das demais disposições deste Contrato.。",
        content126: "16. Relacionamento sem agência",
        content127: "Nada neste Contrato será considerado como criação, implicação ou de outra forma nos constitui como seu agente, administrador ou outro representante, salvo disposição em contrário neste Contrato.。",
        content128: "17. Abstenção",
        content129: "A renúncia por nós ou por você da responsabilidade de qualquer parte por quebra de contrato ou outra responsabilidade estipulada neste Contrato não será considerada ou interpretada como uma renúncia a outra responsabilidade por quebra de contrato; a falha em exercer qualquer direito ou recurso não deverá, em qualquer ser interpretado como uma renúncia a tais direitos ou recursos.。",
        content130: "18. Título",
        content131: "Todos os títulos são para conveniência de expressão do acordo e não são usados ​​para expandir ou limitar o conteúdo ou escopo dos termos do acordo.。",
        content132: "19. Lei Aplicável",
        content133: "19.1 Antes de levantar uma disputa ou reclamação, você reconhece e concorda em primeiro entrar em contato com este site por e-mail para resolver a disputa informalmente. Tentaremos resolver sua disputa internamente o mais rápido possível; ambas as partes concordam em resolver a disputa por meio de negociações de boa fé (discussões serão confidenciais e protegidos pelas regras aplicáveis ​​e não serão usados ​​como prova em quaisquer processos judiciais)。",
        content134: '19.2 Você reconhece e concorda que, no caso de qualquer disputa, controvérsia, desacordo ou reclamação, incluindo a existência, validade, interpretação, desempenho, violação ou rescisão destes Termos ou qualquer disputa decorrente de ou em conexão com estes Termos ("Disputa" ), as partes acima mencionadas submeterão a disputa ao Tribunal Internacional de Arbitragem da Câmara de Comércio Internacional ("Tribunal Internacional de Arbitragem da ICC") de acordo com as "Regras de Arbitragem da ICC" então em vigor. O local da arbitragem será Belize e a lei aplicável da arbitragem será determinada de acordo com as Regras de Arbitragem da Câmara de Comércio Internacional. Salvo acordo em contrário entre as partes, apenas um árbitro será nomeado de acordo com as Regras de Arbitragem da CCI. O árbitro terá autoridade exclusiva para decidir sobre sua própria jurisdição, incluindo, sem limitação, qualquer objeção à existência, escopo ou validade da convenção de arbitragem ou à arbitrabilidade de qualquer reivindicação ou reconvenção. Qualquer arbitragem será conduzida em inglês. Independentemente de como a arbitragem for conduzida, o árbitro emitirá uma sentença por escrito que seja razoável e suficiente para explicar a sentença e as conclusões essenciais, se houver, nas quais a sentença se baseia. A sentença arbitral será final e vinculativa para as partes e poderá ser executada em qualquer tribunal de jurisdição competente。',
        content135: "20. Eficácia e interpretação do acordo",
        content136: "20.1 Este acordo entra em vigor quando você clica na página de registro deste site para concordar em se registrar, concluir o processo de registro e obter a conta e a senha deste site. Ele é vinculativo tanto para este site quanto para você.。",
        content137: "20.2 O direito de interpretação final deste acordo pertence a este site。",
        content138: "Conheça o seu cliente e a política anti-lavagem de dinheiro",
        content139: "1. Introdução",
        content140: '1.1 Prometemos cumprir cuidadosamente as leis e regulamentos relacionados a "Conheça seu cliente" e combate à lavagem de dinheiro e não violaremos intencionalmente a "Política Conheça seu cliente e combate à lavagem de dinheiro". No âmbito do nosso controle razoável, tomaremos as medidas e tecnologias necessárias para lhe fornecer serviços seguros e protegê-lo, tanto quanto possível, de perdas causadas pelo comportamento de lavagem de dinheiro de suspeitos de crimes.。',
        content141: "1.2 Nossa Política Conheça Seu Cliente e Combate à Lavagem de Dinheiro é um sistema de política internacional abrangente que inclui políticas Conheça Seu Cliente e Combate à Lavagem de Dinheiro para as diferentes jurisdições legais às quais você está afiliado。",
        content142: "2. Conheça o seu cliente e as políticas anti-lavagem de dinheiro são as seguintes：",
        content143: "2.1 Promulgar políticas de conhecimento do seu cliente e de combate à lavagem de dinheiro e atualizá-las periodicamente para atender aos padrões estabelecidos pelas leis e regulamentos aplicáveis；",
        content144: "2.2 Promulgar e atualizar alguns princípios e regras orientadoras para o funcionamento deste site, e os nossos colaboradores prestarão serviços de acordo com a orientação desses princípios e regras；",
        content145: "2.3 Desenhar e completar procedimentos para monitoramento e controle interno de transações, como verificação rigorosa de identidade e organização de uma equipe profissional responsável pelo trabalho de combate à lavagem de dinheiro；",
        content146: "2.4 Adotar uma abordagem de prevenção de riscos para realizar a devida diligência e supervisão contínua dos clientes；",
        content147: "2.5 Revise e verifique regularmente as transações que ocorreram；",
        content148: "2.6 Denunciar transações suspeitas às autoridades competentes；",
        content149: "2.7 Provas de identidade, endereço e registros de transações serão mantidas por pelo menos seis anos e poderão ser enviadas às autoridades reguladoras sem aviso prévio.。",
        content150: "3. Informações e verificação de identidade",
        content151: "3.1 Informações de identidade",
        content152: "3.1.1 De acordo com diferentes regulamentos em diferentes jurisdições e diferentes tipos de entidades, o conteúdo das suas informações que coletamos pode ser inconsistente. Em princípio, as informações a seguir serão coletadas de indivíduos registrados.：",
        content153: "Informações pessoais básicas: seu nome, endereço (e endereço permanente, se for diferente), data de nascimento e nacionalidade e outras informações disponíveis. A verificação da identidade deve basear-se em documentos emitidos por autoridades oficiais ou outras autoridades similares, tais como passaportes, bilhetes de identidade ou outros documentos de identificação exigidos e desencadeados por diferentes jurisdições. A morada que fornecer será verificada através de métodos adequados, como a verificação do título de transporte de passageiros ou da nota de taxa de juro ou a verificação do registo eleitoral, etc.。",
        content154: "Foto válida: antes de se registrar, você deve fornecer uma foto sua segurando seu documento de identidade contra o peito；",
        content155: "Dados de contacto: Número de telefone/telemóvel e/ou endereço de e-mail válido。",
        content156: "3.1.2 Se você for uma empresa ou outra entidade legal, coletaremos as seguintes informações para identificá-lo ou o beneficiário final de sua conta fiduciária。",
        content157: "Registro da empresa e certificado de registro; cópias do contrato social e memorando da empresa; materiais de certificação detalhados da estrutura patrimonial e descrição de propriedade da empresa, comprovando a resolução do conselho do diretor autorizado que determina a abertura e execução da conta neste site; empresa diretores que precisam ser fornecidos conforme necessário, documentos de identidade do acionista majoritário e da pessoa autorizada a assinar a conta neste site; o endereço comercial principal da empresa, se for diferente do endereço postal da empresa, fornecer o endereço postal. Se o endereço local da empresa for inconsistente com seu endereço comercial principal, ela será considerada um cliente de alto risco e precisará enviar documentos adicionais adicionais.。",
        content158: "Dependendo dos diferentes regulamentos em diferentes jurisdições e diferentes tipos de entidades, outras certificações e documentos emitidos por autoridades exigidas por nós e documentos que consideramos necessários。",
        content159: "3.1.3 Aceitamos apenas versões em inglês ou chinês de informações de identidade. Caso contrário, traduza suas informações de identidade para o inglês e autentique-as em cartório.。",
        content160: "3.2 Confirmar verificação",
        content161: "3.2.1 Conteúdo completo da página onde solicitamos documentos de identificação。",
        content162: "3.2.2 Pedimos uma foto sua segurando seu documento de identificação contra o peito。",
        content163: "3.2.3 As cópias dos documentos comprovativos geralmente devem ser comparadas com os comprovantes originais. Contudo, uma cópia é aceitável se um certificador adequado e confiável puder certificar que a cópia é uma reprodução precisa e completa do documento original. Esses certificadores incluem embaixadores, comissários judiciais, magistrados locais, etc.。",
        content164: "3.2.4 O requisito para identificar o beneficiário final e o controlo da conta consiste em determinar quais os indivíduos que, em última análise, possuem ou controlam o cliente direto e/ou estabelecer que as transações em curso estão a ser executadas em nome de terceiros. Se for uma empresa, deve ser verificada a identidade dos principais acionistas (como aqueles que detêm 10% ou mais dos direitos de voto). Geralmente, deter 25% das ações será considerado dentro do risco normal, e a identidade do acionista deve ser verificada; deter 10% das ações ou ter mais direitos de voto ou ações é considerado uma situação de alto risco, e a identidade do acionista deverá ser verificada.。",
        content165: "4. Monitore as transações",
        content166: "4.1 Definimos e ajustamos limites máximos diários de transações e saques de moeda de tempos em tempos com base na segurança e nas condições reais da transação.;",
        content167: "4.2 Se as transações ocorrerem com frequência em um usuário registrado ou se houver circunstâncias não razoáveis, nossa equipe de profissionais avaliará e decidirá se são suspeitas.;",
        content168: "4.3 Se determinarmos que uma transação é suspeita com base em nosso próprio julgamento, poderemos tomar medidas restritivas, como suspender a transação, rejeitar a transação ou até mesmo reverter a transação o mais rápido possível, se possível, e reportá-la à autoridade competente, mas não irá notificá-lo.;",
        content169: "4.4 Reservamo-nos o direito de rejeitar pedidos de registro de pessoas de jurisdições que não cumpram os padrões internacionais de combate à lavagem de dinheiro ou de pessoas que possam ser consideradas pessoas politicamente expostas. Reservamo-nos o direito de suspender ou encerrar transações que sejam suspeitas, a nosso exclusivo critério, em a qualquer momento, mas não o fazemos. Violar quaisquer deveres ou responsabilidades devidas a você。",
    },
    register: {
        formTitle1: "criar",
        formTitle2: "Cadastre-se usando seu e-mail ou número de celular",
        formTitle3: "Conta",
        field1: "Código de verificação",
        field2: "Obtivermos",
        field3: "确认密码Confirme sua senha",
        field4: "Código de Convite",
        field41: "（Opcional）",
        field5: "eu li e concordo",
        field6: "Termos de serviço",
        placeholder1: "insira o código de verificação",
        content7: "insira o código de verificação",
        content12: "Por favor insira o número de telefone",
        content13: "Por favor insira a senha",
        content14: "por favor insira seu e-mail",
        content18: "Por favor insira a senha novamente",
        content19: "Por favor, concorde com os termos de serviço",
        content20: "Insira o código de verificação correto",
        content23: "Ainda não recebi o código de verificação？",
        content24: "Por favor insira o código de verificação abaixo",
        registerSuccess: "Registro bem sucedido",
        content25: "As senhas são inconsistentes",
        content26: "Por favor insira o endereço de e-mail correto",
        content27: "Redefinição de senha bem-sucedida"
    },
    download: {
        content1: "Negocie a qualquer hora, em qualquer lugar",
        content2: "Perfeitamente compatível com vários terminais, atendendo às necessidades de transação de vários cenários a qualquer momento",
        content3: "Verifique as condições do mercado em tempo real a qualquer momento",
        content4: "Compre e venda facilmente BTC, ETH, XRP e outros ativos digitais",
        content5: "Receba avisos antecipados sobre alterações de preços das criptomoedas de seu interesse",
        content6: "Veja empréstimos à vista de Bitcoin, empréstimos futuros e preços de opções",
        content7: "Compare preços de criptomoedas em várias bolsas",
        title: "download",
        install: "Notas de instalação",
        one: "1、Permitir o download de arquivos de descrição",
        two: "2、Arquivo de descrição baixado",
        three: "3、Vá para [Configurações] no seu telefone e encontre [Geral]",
        four: "4、Perfis e gerenciador de dispositivos",
        five: "5、Clique no perfil",
    },
    identity: {
        noAuthentication: {
            title: "Certificação Júnior",
            pleaseSelectYourCountry: "por favor selecione seu país",
            name: "Nome",
            enterName: "Por favor, insira seu nome",
            city: "cidade",
            enterCity: "Por favor insira sua cidade",
            IDNumber: "número de identidade",
            enterIDNumber: "Por favor insira seu número de identificação",
        },
        noAdvancedAuthentication: {
            title: "Certificação avançada",
            infoCountry: "país/região",
            infoName: "Nome",
            infoNumber: "número de identidade",
            infoAddress: "endereço",
        },
        text: "não certificado",
        recertification: "re-certificação",
        certification: "Certificação",
        authenticationInProgress: "verificado",
        inAuthentication: "Certificação em andamento",
        uploadTheFrontOfTheDocument: "Carregue a frente do ID",
        uploadTheReverseSideOfTheDocument: "Carregar o verso do certificado",
        uploadAPhotoOfAHandHeldID: "Carregue uma foto do seu documento de identidade em mãos",
        pleaseUpload: "por favor ",
        pleaseCompleteOrdinaryCertificationFirst: "Precisa esperar pelo sucesso da revisão da certificação primária",
        refused: "rejeitado",
        reason: "razão",
        succeeded: "sucesso",
        uploading: "Enviando",
        newTitle: "Selecione o país onde seu documento de identidade foi emitido",
        pleaseOrdinaryCertificationFirst: "Por favor, realize a certificação primária primeiro",
        text1: "Modificar avatar",
        text2: "Apelido",
        text3: "conta",
        text4: "Modificação de apelido",
        text5: "novo apelido",
        text6: "Digite seu novo apelido",
        text7: "Senha Antiga",
        text8: "Digite sua senha antiga",
        text9: "Nova Senha",
        text10: "Digite sua nova senha",
        text11: "Confirme sua senha",

        text12: "Digite sua nova senha novamente",
        text13: "nova caixa de correio",
        text14: "Código de verificação",
        text15: "insira o código de verificação",
        text16: "seu número de celular",
        text17: "Novo número de celular",
        text18: "Por favor, digite seu número de telefone",
        text19: "Código de verificação do celular",
        text20: "Certificação Júnior",
        text21: "Verifique suas informações de identidade",
        text22: "País",
        text23: "Cidade",
        text24: "Digite sua cidade",
        text25: "Digite seu nome",
        text26: "Seu número de identificação",
        text27: "Digite seu número de identificação",
        text28: "Foto frontal do cartão de identificação",
        text29: "Clique para enviar seus documentos",
        text30: "reenviar",
        text31: "carregar",
        text32: "Segurando foto de identificação",
        text33: "Clique para fazer upload da foto do seu documento de identidade",
        text34: "Você vinculou o Google Authenticator",
        text35: "chave",
        text36: "Insira o código de verificação do Google",
        text37: "enviar",
        text38: "Não pode estar vazio",
        text39: "O formato do e-mail está errado",
        text40: "Duas senhas são inconsistentes",
        text41: "Copiado com sucesso",
        text42: "Falha na cópia",
        text43: "Modificação de avatar",
        text44: "alterar a senha",
        text45: "Vinculação de e-mail",
        text46: "Modificar e-mail",
        text47: "Vinculação de telefone celular",
        text48: "Modificar telefone celular",
        text49: "Verificação do Google",
        text50: "Vincular o autenticador do Google",
        text51: "Insira o código de verificação do Google",
        text52: "salvar",
        text53: "Confirmar envio",
        text54: "Rever",
        text55: "vinculativo",
        text56: "Desative a verificação do Google",
        text57: "Próxima Etapa",
        text58: "Terminar",
        text59: "A verificação do Google está vinculada",
        text60: "A verificação do Google foi desagrupada",
        text61: "A certificação primária foi enviada",
        text62: "Certificação avançada enviada",
        text63: "Nenhuma imagem enviada",
        text64: "Reenviar",
        text65: "Por favor insira um apelido",
        text66: "Apelido salvo com sucesso",
        text67: "Nenhuma imagem enviada ainda",
        text68: "Redefinição de senha concluída",
        text69: "E-mail modificado com sucesso",
        text70: "Avatar salvo com sucesso",
        text71: "Enviar Imagem",
        text72: "sua caixa de correio",
        text73: "Digite seu novo e-mail",
        text74: "Seu nome",
        text75: "Ir para autenticação",
        text76: "Por favor, verifique sua foto de identificação"
    },
    retrievePassword: {
        formTitle1: "redefinir senha",
        formTitle2: "Para a segurança dos seus bens, as transações são proibidas dentro de 24 horas após a alteração da senha.。",
        formTitle3: "Recuperar Senha",
        formTitle4: "Confirme as alterações",
        formTitle5: "Por favor insira a senha antiga",
        formTitle6: "Por favor insira uma nova senha"
    },
    home: {
        title1: "Plataforma de negociação de criptomoeda mais popular",
        title3: "por que é ",
        title4: " É uma plataforma blockchain de código aberto de alto desempenho projetada para suportar e operar infraestrutura digital segura, compatível e previsível。",
        field1: "Troca estabelecida",
        field2: "Serviços financeiros de ativos digitais",
        field3: "Dezenas de milhões de usuários",
        field4: "Estabelecer vários fundos de proteção ao investidor",
        field5: "Negociação rápida",
        field6: "O CME Gruop é capaz de combinar ordens com latência ultrabaixa para a melhor experiência de negociação。",
        title5: "tendência de mercado",
        title6: "ver mais",
        table1: "Moeda",
        table2: "Preço mais recente",
        table3: "Altos e baixos 24 horas",
        table4: "Volume de negociação 24 horas",
        table5: "Volume de transações 24H",
        table6: "Citações",
        title7: "sobre ",
        title8: " Comprometido em fornecer aos usuários as ferramentas de negociação mais avançadas, tecnologia inovadora e atendimento ao cliente de qualidade incomparável。",
        title9: "O campo dos ativos digitais está mudando a cada dia que passa. Somente sendo clarividentes e fazendo progressos rápidos poderemos acompanhar o ritmo da inovação tecnológica.。",
        title901: " Fornecer os mais avançados serviços de negociação de ativos digitais aos nossos usuários e provedores de liquidez globais。",
        title10: "Acreditamos firmemente que cada usuário merece a melhor experiência de negociação。",
        title11: "Segurança em que você pode confiar",
        title12: "Nossas sofisticadas medidas de segurança e fundos SAFU protegem seus ativos digitais de todos os riscos。 ",
        title13: "Melhores taxas de transação",
        title14: "Taxas de transação preferenciais, direitos VIP competitivos e desfrute dos serviços da melhor qualidade。",
        title15: "Suporte de atendimento ao cliente 24 horas por dia, 7 dias por semana",
        title16: "Modo de operação 24 horas por dia, 7 dias por semana, em qualquer clima, em tempo integral, responda às suas perguntas relevantes o mais rápido possível。",
        title17: "0 taxa de negociação",
        title18: "Use vários métodos de pagamento para negociar moedas digitais sem taxas, de forma segura e rápida。",
        title19: "Negocie a qualquer hora, em qualquer lugar",
        title20: "Perfeitamente compatível com vários terminais, atendendo às necessidades de transação de vários cenários a qualquer momento",
        title21: "Verifique as condições do mercado em tempo real a qualquer momento",
        title22: "Compre e venda facilmente BTC, ETH, XRP e outros ativos digitais",
        title23: "Receba avisos antecipados sobre alterações de preços das criptomoedas de seu interesse",
        title24: "Veja empréstimos à vista de Bitcoin, empréstimos futuros e preços de opções",
        title25: "Compare preços de criptomoedas em várias bolsas",
        title26: "Inscreva-se agora",
        title27: "Dólar",
        title28: "Volume de negociação em 24 horas",
        title29: "Provedor de liquidez integrado",
        title30: "do utilizador",
        title31: "Mais",
        title32: "Binance Loan adiciona CTSI e KSM como ativos colaterais e lança descontos para redução de taxas de juros BUSD e USDT！",
        title33: "Nossa vantagem",
        title34: "seguro e estável",
        title35: "Equipe técnica de ponta, proteção de segurança completa, pesquisa e desenvolvimento independentes de mecanismo de síntese de transações de alta velocidade, ainda pode operar de forma estável e confiável sob transações simultâneas massivas。",
        title36: "Conformidade Profissional",
        title37: "Equipe de operação profissional, muitos anos de experiência em blockchain e finanças, possuindo uma licença de negociação de ativos digitais compatível e 100% de garantia de reserva。",
        title38: "Usuário primeiro",
        title39: "Enfrentando o mercado global, oferecendo suporte a vários idiomas, funcionando 24 horas por dia, 7 dias por semana, forte suporte da comunidade e atendimento profissional ao cliente.",
        title40: "alta performance",
        title41: "300.000 transações por segundo com tempo de resposta de pedido inferior a 1 milissegundo。",
        title42: "sobre nós",
        title43: "Negocie a qualquer hora, em qualquer lugar",
        title44: "Perfeitamente compatível com vários terminais, atendendo às necessidades de transação de vários cenários a qualquer momento",
        title45: "(Escaneie o código QR para fazer o download)",
        title46: "Notícias sobre moeda digital",
        title47: "nosso parceiro de negócios",
        title48: "Lista de perdedores",
        title49: "Rotatividade 24h",
        title50: "Aumento e diminuição de 24h"
    },
    quotation: {
        marketTrading: "Citações",
        turnover: "Volume",
        optional: "Opcional",
        contractMarket: "mercado de contratos",
        currencyPair: "par de moedas",
        latestPrice: "Preço final",
        upsAndDowns: "Alteração de cotação",
        highest: "Altíssima",
        lowest: "mais baixo",
        transactionAmount: "Volume de transações",
        operate: "operar",
        buy: "troca",
        topTurnovers: "Lista de transações",
        topGainers: "Lista de ganhadores",
        tradingPair: "par de negociação",
        buyOrder: "Comprar",
        sellOrder: "pedido de venda"
    },
    accountChange: {
        demo: "simulação",
        realAccount: "conta real",
        demoAccount: "Conta de demonstração",
        accountRole: "Função da conta",
        pleaseSelect: "por favor escolha",
        AccountChangeType: "Tipo de alteração de conta",
        AccountType: "tipo de conta",
        currencyType: "Tipo de moeda",
        allType: "Todos os tipos",
        allAccount: "Todas as contas",
        allCoin: "Todas as moedas",
        coin: "Moeda",
        amount: "Alterar valor",
        balance: "Saldo da conta",
        time: "tempo",
        title: "Registros de alteração de conta",
        filtrar: "filtro",
        check: "troque para",
        showOnly: "Exibir apenas participações em moeda",
        holdAssets: "Saldo monetário",
        accountList: "Lista de contas"
    },
    contractTransaction: {
        chart: "gráfico",
        basicEdition: "Versão básica",
        depthMap: "Mapa de profundidade",
        second: "Segundo",
        minute: "apontar",
        day: "céu",
        week: "semana",
        month: "lua",
        open: "abrir",
        trade: "troca",
        buyLong: "compra longa",
        buyShort: "compra a descoberto",
        cannotBuy: "Esta moeda não suporta transações de contrato de entrega",
        cannotBuy1: "Esta moeda não suporta negociação perpétua",
        tradingAssets: "negociação de ativos",
        amountAvailable: "Quantidade Disponível",
        timeStr: "tempo",
        transactionPeriod: "período de negociação",
        quantity: "quantidade",
        enterQuantity: "Por favor insira a quantidade",
        buyIn: "Vá longo",
        latestTransaction: "última transação",
        time: "tempo",
        direction: "direção",
        price: "preço",
        sell: "curto",
        positionOrder: "Pedido de entrega",
        historicalOrders: "Ordens históricas",
        product: "produtos",
        realAccount: "(conta real)",
        demoAccount: "(Conta de demonstração)",
        openingTime: "hora do pedido",
        openingPrice: "Preço de abertura",
        curretPrice: "Preço atual",
        liquidationPrice: "Preço final",
        profitOrLoss: "Lucros e perdas",
        endTime: "Horário de encerramento",
        areYouSureToBuy: "Tem certeza de comprar?？",
        areYouSureToLongBuy: "Tem certeza de que deseja operar comprado?？",
        areYouSureToShortBuy: "Tem certeza de que deseja comprar a descoberto?？",
        successfullyBought: "Compre com sucesso",
        minBuy: "Buy-in mínimo",
        assets: "ativos",
        withdraw: "retirar",
        entrustedOrder: "Ordem",
        total: "total",
        continueToPlaceOrder: "Continuar a fazer um pedido",
        orderConfirmation: "Confirmação do pedido",
        buyRu: "Comprar",
        sellChu: "vender",
        areYouSureToSell: "Tem certeza de vender?？",
        operation: "operar",
        buyCover: "posição da tampa",
        commitSuccess: "Transação bem-sucedida",
        onlyEnterIntegers: "Somente números inteiros podem ser inseridos",
        trend: "Tendência",
        lotteryTime: "Prazo para encomenda",
        countdown: "Contagem regressiva",
        period: "Emitir",
        cycle: "Período de tempo",
        unlimited: "Ilimitado",
        put: "Put",
        call: "Call",
        pending: "Em comissão",
        unopened: "Ainda não desenhado",
        opened: "Prêmio sorteado",
        settlementPrice: "Preço de Liquidação",
        rateOfReturn: "taxa de retorno",
        currentPositions: "Posição atual",
        positionQuantity: "Quantidade de posição",
        guarantee: "margem",
        text1: "Call",
        text2: "Put",
        text3: "Esperando para comprar",
        text4: "Quantidade do pedido",
        text5: "O número de transações",
        text6: "Cancelar",
        text7: "prazo de entrega",
        text8: "Lembrete de cancelamento",
        text9: "Tem certeza de que deseja cancelar o pedido?",
        text10: "Quantidade ilegal",
        text11: "Lembrete de pedido",
        text12: "Tem certeza de que está comprando em alta?",
        text13: "Tem certeza de comprar a opção de venda?",
        text14: "Cancelamento bem-sucedido"
    },
    mining: {
        title1: "A principal plataforma de mineração de ativos digitais blockchain do mundo",
        title2: "Aberto e transparente · seguro e estável",
        title3: "Selecione a moeda",
        title4: "Rendimento Anualizado da Mineração",
        title5: "céu",
        title6: "Período de mineração",
        title7: "Detalhes do produto",
        btn: "Meu agora",
        btn2: "fecho",
        tabs1: "em andamento",
        tabs2: "resgatado",
        table1: "Nome do Produto",
        table2: "Período de mineração",
        table3: "Tempo de mineração",
        table4: "Quantidade de mineração",
        table5: "Data de validade",
        table6: "Prossegue até o vencimento",
        table7: "Status do pedido",
        egularMining: "Mineração regularmente",
        inProgress: "em andamento",
        redeemed: "resgatado",
        redemption: "redenção",
        regularMining: "Mineração regularmente",
        earlyRedemption: "resgate antecipado",
        need: "precisar",
        handlingFee: "taxa de manuseio",
        redemptionConfirmationMessage: "Por favor, confirme se deseja resgatar antecipadamente？",
        form1: "Rendimento Anualizado da Mineração",
        form2: "Período de mineração",
        form3: "Moeda de mineração",
        form4: "Valor da assinatura (mínimo/máximo)",
        form5: "Taxa de resgate antecipado: ",
        form6: "preço de compra",
        form7: "Fundos disponíveis",
        form8: "preço de compra",
        form9: "Fundos disponíveis",
        form10: "Por favor insira o valor da compra",
        form11: "todos",
        form12: "Receita estimada",
        form13: "Fundos insuficientes disponíveis",
        form14: "Por favor insira o valor da compra",
        form15: "Meu pedido",
        form16: "renda",
        form17: "detalhes do pedido",
        form18: "Instruções para resgate antecipado:",
        form19: "Valor da assinatura (mínimo)",
        form20: "Valor da assinatura (máximo)",
        form21: "Quantidade retida",
        form22: "Ganhando tempo",
        form23: "hora de resgate",
        form24: "sobre",
        form25: "Assinatura bem-sucedida",
        form26: "Resgate bem-sucedido",
        form27: "Fácil gestão financeira com retorno superior a 25%, superando o mercado, e você também pode receber recompensas após a compra",
        form28: "Convide amigos para receber uma recompensa de 50 USDT, e os vouchers de contrato também podem devolver 20% de comissão."
    },
    assets: {
        content1: "Ativos da conta",
        content2: "Os ganhos de hoje",
        content3: "conta corrente",
        content4: "A conta real são os ativos reais da sua conta, que podem ser usados ​​para transações e saques.",
        content5: "Retirar moedas",
        content6: "Depositar moedas",
        content7: "intercâmbio",
        content8: "transferir",
        content9: "por favor escolha",
        content10: "Conta de demonstração",
        content11: "conta real",
        content12: "adquirir ativos",
        content13: "Tem certeza de que deseja mudar para uma conta real?？",
        content14: "Tem certeza de que deseja mudar para uma conta demo?？",
        content15: "obter sucesso",
        table1: "Moeda",
        table2: "quantidade",
        table3: "congelar",
        table4: "Disponível",
        dialog1: "Detalhes do produto",
        dialog2: "1、Este ativo só pode ser usado no modo de conta demo",
        dialog3: "2、Para evitar abusos, apenas um resgate é permitido dentro de um mês.",
        dialog4: "3、O recebimento de ativos adicionará a quantidade de ativos recebidos na base original e não alterará o status da posição.",
        dialog5: "4、Se você tiver alguma outra dúvida, entre em contato com o atendimento ao cliente",
        dialog6: "fecho",
        dialog7: "Receba fundos simulados",
        dialog8: "transferência em",
        dialog9: "transferir",
        dialog10: "trocar",
        dialog11: "Avaliação",
        dialog12: "Recursos de demonstração",
        dialog13: "minha conta",
        dialog14: "Ativos totais da conta",
        dialog15: "Visão geral dos ativos",
        dialog16: "Avaliação total de ativos",
        dialog17: "Avaliação de ativos",
        dialog18: "Os ganhos de hoje",
        dialog19: "congelar fundos",
        dialog20: "Você pode receber cota de ativos simulados todos os meses",
        dialog21: "receber",
        dialog22: "Você recebeu com sucesso",
        dialog23: 'Renda acumulada de 30 dias',
        dialog24: "Produto financeiro",
        dialog25: "Minha gestão financeira",
        dialog26: "Financeira histórica",
        dialog27: "registro histórico",
        dialog28: "Registros de financiamento"
    },
    validator: {
        dialog14: "validador",
        dialog15: "Validador de vinculação",
        dialog16: "interruptor validador",
        dialog17: "Código de verificação",
        dialog18: "Código QR do validador",
        dialog19: "chave validadora",
        dialog20: "salvar",
        dialog21: "Selecione um validador",
        dialog22: "copiar chave",
        dialog23: "Salvo com sucesso",
        dialog24: "insira o código de verificação",
        dialog25: "Lembrete amigável: Antes de confirmar o salvamento, abra o APP correspondente e escaneie o código QR abaixo ou adicione a chave manualmente.！",
        dialog26: "Ative seu autenticador para fornecer segurança máxima para sua conta。",
        dialog27: "Você precisará inserir um código de verificação exclusivo acessado pelo seu celular sempre que fizer login ou sacar dinheiro.。",
        dialog28: "Configurar validador",
        dialog29: "ajuda",
        dialog30: "Você não vinculou o código de autenticação. Obtenha proteção mais forte com um autenticador。",
        dialog31: "O validador ainda não foi configurado, configure-o primeiro",
        dialog32: "Vá para as configurações"
    },
    recharge: {
        title1: "Não sei como usar moeda digital para comprar？",
        title2: "Apenas três etapas fáceis!",
        title3: "Selecione a moeda digital que deseja comprar no menu suspenso e insira a quantidade ou valor da compra。",
        title4: "Escolha o seu método de pagamento preferido",
        title5: "Após a confirmação do pagamento, a moeda digital que você comprou será automaticamente transferida para sua conta。",
        title6: "Selecione a moeda que deseja recarregar",
        title7: "Registro de depósito",
        table1: "Moeda",
        table2: "quantidade",
        table3: "Nome da cadeia",
        table4: "estado",
        table5: "razão",
        table6: "tempo",
        table7: "Ver comprovante",
        dialog1: "Por favor insira o código de verificação abaixo",
        dialog2: "código de pagamento",
        dialog3: "cópia de",
        dialog4: "endereço",
        dialog5: "Valor do depósito",
        dialog6: "Captura de tela da transação",
        dialog7: "notícia importante",
        dialog8: "Por favor preencha o valor do depósito",
        dialog9: "Enviar Imagem",
        dialog10: "Por favor insira o conteúdo",
        dialog11: "Faça upload de capturas de tela da recarga",
        dialog12: "Não revisado",
        dialog13: "rejeitado",
        dialog14: "Depositado",
        tip1: "submetido com sucesso",
        tip2: "Copiado com sucesso",
        tip3: "Falha na cópia",
        tip4: "Selecione a moeda que deseja recarregar",
        tip5: "1.O endereço de depósito acima é o endereço de pagamento oficial da plataforma. Procure o endereço de depósito oficial da plataforma. Qualquer perda de fundos causada por depósito incorreto será suportada por você.；",
        tip6: "2.Certifique-se de que seu computador e navegador estejam seguros para evitar que informações sejam adulteradas ou vazadas.；",
        tip7: "3.Depois de recarregar o endereço acima, todo o nó da rede precisa ser confirmado antes que a conta possa ser recebida.；",
        tip8: "4.Selecione o sistema de token e a moeda acima e transfira o valor correspondente para depósito. Não transfira nenhum outro ativo irrelevante, caso contrário, ele não será recuperado.。",
        tip9: "Salvar código QR",
        tip10: "completar",
        tip11: "notícia importante",
        tip12: "Links externos",
        tip13: "Recarregar código QR",
        tip14: "Próxima Etapa",
        tip15: "Registro de recarga",
        tip16: "Detalhes de recarga",
        tip17: "Endereço de depósito",
        tip18: "Pagamento rápido",
        tip20: "Saldo insuficiente",
        tip22: "Enviado, aguardando confirmação",
        tip23: "À espera de pagamento",
        tip24: "Quantia",
        tip25: "O pagamento foi enviado, aguardando confirmação do bloqueio",
        tip26: "Pagamento Recusado",
        tip27: "Falha no pagamento",
        tip28: "Pagamento bem sucedido",
        bankChannel: "Canal de cartão bancário",
        tip29: "Pesquisas históricas",
        tip30: "Lista de moedas",
        tip31: "Selecione a rede",
        tip32: "Ao recarregar esta moeda, recarregue apenas através das redes suportadas abaixo. Se você recarregar através de outras redes, os fundos serão perdidos.",
        tip33: "Ao sacar esta moeda, retire apenas através das redes suportadas abaixo. Se você retirar através de outras redes, os fundos serão perdidos.",
        tip34: "Recarga rápida",
        tip35: "Digitalize o código para recarregar",
        tip36: "Recarga manual",
        tip37: "Rede de recarga",

        tip38: "Endereço de recarga",
        tip39: "Valor de recarga",
        tip40: "Upload do comprovante de transação",
        tip41: "Enviar Imagem",
        tip42: "confirmar envio",
        tip43: "confirme o pagamento",
        tip44: "Nota: Por favor, confirme o endereço que você precisa para recarregar. Não nos responsabilizamos pela perda de dinheiro se você preencher incorretamente.",
        tip45: "lembrar",
        tip46: "Endereço copiado com sucesso!",
        tip47: "Falha na cópia do endereço!",
        tip48: "Saldo disponível insuficiente",
        tip49: "A recarga foi enviada com sucesso e aguarda confirmação na rede.",
        tip50: "Falha na recarga",
        tip51: "O valor da recarga está errado",
        tip52: "Falha na conexão",
        tip53: "Carteira não logada",
        tip54: "Ligação recusada",
        tip55: "Enviado com sucesso, aguardando análise e aprovação do atendimento ao cliente",
        tip56: "Faça upload de uma captura de tela do recibo da transação",
        tip57: "Falha no envio",
        tip58: "Registro de recarga",
        tip59: "Registro de retiradas",
        tip60: "Moeda de recarga",
        tip61: "Moeda de retirada",
        tip62: "Endereço de recarga",
        tip63: "Captura de tela mostra",
        tip64: "Clique para ampliar a imagem",
        tip65: "Tempo de recarga",
        tip66: "Razão para rejeição",
        tip67: "Quantidade retirada",
        tip68: "Montante recebido",
        tip69: "Rede de retirada de dinheiro",
        tip70: "Endereço de retirada",
        tip71: "Tempo de retirada",
        tip72: "fecho",
        tip73: "Captura de tela da transação",
        tip74: "Revisão pendente",
        tip75: "rejeitado",
        tip76: "concluído",
        tip77: "rejeitado",
        tip78: "Auditado",
        tip79: "Desembolsado",
        tip80: "extrair",
        tip81: "rede de transferência",
        tip82: "Endereço/Fatura",
        tip83: "Retirar tudo",
        tip84: "Por favor, não retire dinheiro diretamente para o endereço do crowdfunding ou da ICO, caso contrário você não poderá receber os tokens emitidos pelo crowdfunding ou pela ICO.",
        tip85: "Quantidade de chegada",
        tip86: "Confirmar retirada",
        tip87: "Endereço de retirada errado",
        tip88: "Valor de saque errado",
        tip89: "O valor a retirar é de pelo menos",
        tip90: "O valor máximo de retirada é",
        tip91: "A solicitação de retirada foi enviada",
        tip92: "lista de endereços",
        tip93: "Você ainda não adicionou um endereço",
        tip94: "Adicione agora",
        tip95: "Adicionar endereço",
        tip96: "adicionar novo endereço",
        tip97: "por favor escolha",
        tip98: "endereço",
        tip99: "Selecione uma rede de transferência",
        tip100: "Por favor insira o endereço",
        tip101: "Endereço adicionado com sucesso",
        tip102: "em transação",
    },
    fundExchange: {
        title1: "Como trocar fundos？",
        title2: "Selecione a moeda apropriada a ser convertida。",
        title3: "Selecione a moeda apropriada para converter。",
        title4: "Insira o valor a ser trocado e clique em Enviar para concluir a troca de fundos.。",
        title5: "de",
        title6: "chegar",
        title7: "Quantidade de troca",
        title8: "taxa de câmbio",
        title9: "Disponível",
        title10: "Insira a quantidade de resgate",
        title11: "Não há par de negociação correspondente para troca.",
        title12: "Insira a quantidade a ser resgatada",
        title13: "Resgate bem-sucedido",
        title14: "Registro de troca",
        table1: "Usar moeda",
        table2: "quantidade",
        table3: "taxa de câmbio",
        table4: "Moeda de câmbio",
        table5: "Recibo",
        table6: "tempo",
        title15: "de",
        title16: "No momento não há ativos disponíveis, selecione outro"
    },
    fundTransfer: {
        title1: "Como transferir fundos？",
        title2: "Selecione a conta corrente a ser transferida。",
        title3: "Selecione a conta que deseja transferir。",
        title4: "Insira o valor da transferência e clique em Enviar para concluir a transferência.。",
        title5: "Moeda",
        title6: "Quantidade de transferência",
        title7: "Insira o valor a ser transferido",
        title8: "registro de transferência",
        title9: "Selecione o tipo de carteira",
        title10: "Selecione a moeda",
        title11: "Selecione o tipo de carteira",
        title12: "Selecione a moeda",
        table1: "Transferir conta",
        table2: "moeda de transferência",
        table3: "Quantidade de transferência",
        table4: "conta de transferência",
        table5: "tempo",
        table6: "transferir",
        table7: "Transferência de fundos",
        table8: "Transferir para",
        table9: "registro de transferência",
        table10: "Detalhes da transferência",
        table11: "Transferência bem-sucedida",
        table12: "Falha na transferência",
        table13: "A conta corrente não pode ser transferida"
    },
    withdrawal: {
        title1: "notícia importante",
        title2: "1.Para evitar arbitragem, você pode solicitar a retirada de moeda quando o volume de transações atingir a cota.。",
        title3: "2.Após o envio do pedido de saque, o dinheiro chegará em até 24 horas. Caso o dinheiro não chegue após o horário previsto de saque, consulte o atendimento online.。",
        title4: "3.Após o envio do pedido de saque, os fundos são congelados porque o saque está em andamento e os fundos ficam temporariamente retidos pelo sistema, o que não significa que você perdeu o ativo ou que há uma anormalidade com o ativo.。",
        title5: "Moeda",
        title6: "endereço da carteira",
        title7: "Quantidade de moedas retiradas",
        title8: "Registro de retirada de moedas",
        title9: "Selecione a moeda",
        title10: "Selecione o tipo de corrente",
        title101: "",
        title11: "Por favor, insira",
        title12: "endereço da carteira",
        title13: "submetido com sucesso",
        title14: "sob revisão",
        title15: "Revisão bem-sucedida",
        title16: "Falha na auditoria",
        title17: "Desembolsado",
        title18: "Por favor, insira ou cole o endereço da sua carteira",
        title19: "Por favor insira o valor do saque",
        title20: "taxa de manuseio",
        title21: "Chegada real",
        table1: "Moeda",
        table2: "Quantidade de moedas retiradas",
        table3: "Nome da cadeia",
        table4: "endereço da carteira",
        table5: "estado",
        table6: "razão",
        table7: "tempo de aplicação",
        table8: "Retirar moedas",
        table81: "Retirar moedas",
        table9: "Endereço de retirada",
        table10: "Selecione a moeda que deseja sacar",
        table11: "Por favor, certifique-se de entrar",
        table12: 'Endereço (geralmente começa com "0x"）',
        table13: "As moedas podem ser retiradas (taxa de manuseio deduzida)）：",
        table14: "Escolha a moeda",
        table15: "Encontrou problemas? Retorno imediato",
        table16: "equilíbrio",
        table17: "Detalhes de retirada",
        password: "senha",
        passwordTips: "Por favor insira a senha",
        passwordAg: "Confirme sua senha",
        passwordAgTips: "Por favor insira a senha novamente",
        oldPassword: "Senha Antiga",
        oldPasswordTips: "Por favor insira a senha antiga",
        newPassword: "Nova Senha",
        newPasswordTips: "Por favor insira uma nova senha",
        passwordNotSame: "As senhas são inconsistentes",
        withdrawPasswordSet: "Definir senha de saque",
        withdrawPasswordModify: "Alterar senha de saque",
        withdrawPassword: "Retirar senha",
        withdrawPasswordTips: "Por favor insira a senha de saque",
        setWithdrawPasswordFirst: "Por favor, defina uma senha de saque primeiro",
        withdrawPasswordSetSuccess: "Configuração bem sucedida",
        withdrawPasswordModifySuccess: "Modificado com sucesso",
        submit: "enviar",
    },
    customer: {
        content1: "serviço on-line",
        content2: "atendimento ao Cliente",
        content3: "Por favor insira suas informações de contato",
        content4: "Por favor insira o conteúdo",
        content5: "enviar",
        content6: "Falha no envio, falha na conexão de rede",
        content7: "foto",
        content8: "a mensagem está vazia",
        content9: "minha parte",
        content10: "compartilhar link",
        content11: "Salvar foto",
        content12: "meus ganhos",
        content13: "Descrição do nível",
        content14: "Tamanho da equipe",
        content15: "Renda de convite",
        content16: "Descrição da regra",
        content17: "nível de associação",
        content18: "O valor da recarga é maior que",
        content19: "Nível da equipe",
        content20: "Nível e renda da equipe",
        content21: "Para atender às condições",
        content22: "Renda de distribuição",
        content23: "equipe de nível",
        content24: "nível, convite",
        content25: "membros válidos",
        content26: "renda primária",
        content27: "rendimento secundário",
        content28: "Renda nível 3",
    },
    standardContract: {
        increaseMargin: "Increase margin",
        increaseAmount: "Increase amount",
        Pleaseenterthemargin: "Please enter the margin amount to be increased",
        Pleaseenterthecorrect: "Please enter the correct deposit amount",
        Increasemarginsuccessfully: "Increase margin successfully",
        takeProfitPriceWrong: "Preço de lucro errado",
        stoppriceerror: "Erro de preço de parada",
        thetakeprofit1: "O preço take-profit deve ser superior ao preço de mercado",
        thetakeprofit2: "O preço do stop loss deve ser inferior ao preço de mercado",
        thetakeprofit3: "O preço take-profit deve ser inferior ao preço de mercado",
        thetakeprofit4: "O preço do stop loss deve ser superior ao preço de mercado",
        thetakeprofit5: "Quantidade errada",
        estimatedprofitloss: "Lucros e perdas estimados",
        positionAmount: "Número de posições",
        closeAmount: "Quantidade final",
        whenthelatest: "Quando o preço mais recente for atingido",
        whenthelatest2: ", a ordem de lucro do preço de mercado será acionada para fechar a posição atual. Lucro e perda esperados",
        whenthelatest3: ", a ordem de stop loss de mercado será acionada para fechar a posição atual. Lucro e perda esperados",
        contract: "contrato",
        ordercanceledsuccessfully: "Pedido cancelado com sucesso",
        successfullyordered: "encomendado com sucesso",
        positionopeningreminder: "Lembrete de abertura de posição",
        wrongquantity: "Quantidade errada",
        quantitycannot: "A quantidade não pode ser maior que",
        modificationstoploss: "Modificação de stop-profit e stop loss bem-sucedida",
        positionclosedsuccessfully: "Posição fechada com sucesso",
        orderCancellation: "Cancelamento de pedido",
        orderCancellation2: "Tem certeza de que deseja cancelar o pedido?",
        tobeexecuted: "Para ser executado",
        expired: "expirado",
        failed: "fracassado",
        contractTransaction: "Contrato perpétuo",
        increase: "Aumentar",
        openPosition: "Armazém completo",
        isolatedPosition: "Posição isolada",
        limitOrder: "limite de preço",
        marketOrder: "preço de mercado",
        pleaseSelectLeverage: "Selecione a alavancagem",
        pleaseEnterQuantity: "Por favor insira a quantidade",
        canOpenMore: "Posição máxima que pode ser aberta",
        contractAmount: "Valor do contrato",
        openingFee: "taxa de manuseio",
        currentPosition: "Posição",
        historicalPositions: "Transações históricas",
        currentCommission: "Ordem atual",
        historicalCommission: "comissão histórica",
        buyPrice: "preço de compra",
        income: "lucro",
        quantity: "quantidade",
        marginRate: "taxa de margem",
        initialMargin: "Margem inicial",
        estimatedPriceParity: "Preço de liquidação forçada",
        operate: "operar",
        finishQuantity: "Volume de transações",
        averagePrice: "preço médio de transação",
        pleaseEnterLeverage: "Por favor insira a alavancagem",
        takeProfitAndStopLoss: "Obtenha lucro e pare a perda",
        takeProfit: "Obter lucros",
        stopLoss: "parar a perda de",
        openLong: "Long",
        openShort: "Short",
        liquidation: "Fechar posição",
        marketLquidation: "O preço de mercado é estável",
        cancel: "Cancelar pedido",
        enterBuyPrice: "Por favor insira o preço de compra",
        areYouSureLquidation: "Tem certeza de fechar a posição??",
        confirm: "Claro",
        enterStopWinPrice: "Insira o preço de lucro",
        enterStopLossPrice: "Insira o preço do stop loss",
        commissionTime: "Tempo de comissão",
        commissionPrice: "Preço de comissão",
        commissionQuantity: "Valor total de comissões",
        volumeAlreadyTraded: "Valor concluído",
        areYouSureToWinOrLoss: "Tem certeza de que deseja interromper o lucro e a perda??",
        maxQuantity: "Posição máxima",
        saleQuantity: "Posição fechada",
        salePrice: "preço médio de fechamento",
        saleTime: "tempo",
        succeeded: "sucesso",
        cancelled: "Cancelado",
        piece: "abrir",
        triggerPrice: "preço de gatilho",
        setWin: "Definir lucro",
        setLoss: "Definir stop loss",
        enterWinQuantity: "Insira o valor do lucro",
        enterLossQuantity: "Insira o valor do stop loss",
        planCommission: "Comissão do plano",
        enterWinTriggerPrice: "Insira o preço de acionamento do lucro",
        enterLossTriggerPrice: "Por favor, insira o preço de gatilho do stop loss",
        insufficientQuantity: "Quantidade insuficiente",
        enterTriggerPrice: "Insira o preço de ativação",
        tips1: "O preço da sua ordem é superior ao preço atual e a transação será executada diretamente ao preço atual de mercado. Tem certeza de que deseja comprar?？",
        tips2: "O preço do seu pedido é inferior ao preço atual e a transação será executada diretamente ao preço de mercado atual. Tem certeza de que deseja comprar?？",
        type: "tipo",
        increaseQuantity: "Aumentar posição",
        reduceQuantity: "Reduzir posições",
        limitCommission: "ordem limite",
        tips3: "O preço do stop loss deve ser inferior ao preço de mercado",
        tips4: "O preço take-profit deve ser superior ao preço de mercado",
        tips5: "O preço do stop loss deve ser superior ao preço de mercado",
        tips6: "O preço take-profit deve ser inferior ao preço de mercado",
        tips7: "O preço do stop loss deve ser inferior ao preço de mercado e inferior ao preço do pedido",
        tips8: "O preço take-profit deve ser maior que o preço do pedido (pode ser menor que o preço de mercado)）",
        tips9: "O preço do stop loss deve ser maior que o preço de mercado e maior que o preço do pedido",
        tips10: "O preço take-profit deve ser menor que o preço do pedido (pode ser maior que o preço de mercado)）",
        accountEquity: "Saldo da conta",
        unrealizedPL: "lucro ou prejuízo não realizado",
        tips11: "Você tem certeza que está certo",
        tips12: "Preço de mercado",
        tips13: "Fechar todas as posições",
        tips14: "Não lembre novamente",
        profitRate: "Taxa de retorno de fechamento",
        openTime: "Tempo de abertura",
        unwindTime: "Horário de encerramento",
        dealTime: "Tempo de transação",
        amountClose: "Quantidade de posição fechada",
        long: "muitos",
        short: 'nulo',
        orderQuantity: "Quantidade do pedido",
        entrustedMargin: "margem confiada",
    },
    spotGoods: {
        spotGoods: "Troca de flash",
        sellPrice: "preço de venda",
        orderRecord: "Registro de resgate flash",
        transactionTime: "Tempo de transação",
        maxBuy: "disponível para compra",
        maxSell: "Disponível para venda",
        purchaseQuantity: "Quantidade de compra",
        slippage: "Deslizamento",
        convertTips: "Fecharemos a transação com o melhor preço, com slippage de 0-5%。",
        changePrice: "Preço de câmbio atual",
        consume: "consumir",
        get: "pegar",
        changeTips: "Tem certeza de que deseja resgatar?？",
        confirm: "Confirmar resgate"
    },
    news: {
        title: "Centro de notícias",
        redMore: "consulte Mais informação",
    },
    credit: {
        credit: "pontuação de crédito",
        creditScore: "pontuação total",
        latestEntry: "último recorde",
        liveUpdate: "Atualização ao vivo",
        systemEvaluation: "Avaliação do sistema",
        time: "data",
        name: "nome",
        reason: "razão",
        scoreStory: "Registro de pontuação de crédito"
    },
    borrowing: {
        borrowing: "empréstimo",
        diversifiedMarket: "Mercados diversificados",
        totalSupply: "Fornecimento total",
        totalBorrowings: "empréstimos totais",
        tips1: "Este é o valor total que você pode pedir emprestado contra sua garantia, até o limite de empréstimo。",
        tips2: "Sua taxa de juros estável permanecerá a mesma durante a vida do seu empréstimo. Recomendado para fornecimento de longo prazo e usuários que gostam de previsibilidade。",
        totalSupplys: "oferta total",
        supplyAPR: "taxa de abastecimento",
        totalBorrowings: "Empréstimo total",
        borrowAPR: "taxa de empréstimo",
        check: "Verificar",
        market: "mercado",
        availableLiquidity: "Liquidez disponível",
        currencyPrice: "preço da moeda atual",
        supplyAssets: "fornecer ativos",
        tips3: "Nota: A taxa de juros abaixo é fixa. Após o término do período de fornecimento, o principal mais a receita serão devolvidos à sua conta.。",
        supplyCycle: "ciclo de fornecimento",
        minimumSupply: "fornecimento mínimo",
        supply: "fornecer",
        borrowedAssets: "ativos emprestados",
        tips4: "Coisas a saber: Para pedir dinheiro emprestado, você precisará fornecer quaisquer bens colaterais ou provas，",
        tips5: "Consulte a descrição do ativo hipotecário。",
        repaymentCycle: "ciclo de reembolso",
        BorrowableAmount: "Valor do empréstimo disponível",
        borrow: "empréstimo",
        accountInformation: "Informação da conta",
        walletBalance: "saldo da carteira",
        canSupply: "Disponível",
        AmountCanBeBorrowed: "Valor total do empréstimo disponível",
        borrowedAmount: "Montante emprestado",
        remainingAmount: "saldo remanescente",
        getBorrowednAmount: "Obter limite de empréstimo",
        supplyOrder: "pedido de fornecimento",
        borrowedOrder: "ordem de empréstimo",
        supplyAmount: "quantidade de fornecimento",
        expectedProfits: "Retorno esperado",
        supplyTime: "Tempo de fornecimento",
        expirationTime: "Data de validade",
        borrowingAmount: "Montante do empréstimo",
        dueRepayment: "Valor do reembolso",
        borrowingTime: "Hora de lançamento",
        repaymentTime: "Prazo de reembolso",
        repay: "reembolso",
        supplyCurrency: "moeda de fornecimento",
        buyNow: "Comprar agora",
        supplyRecords: "registros de fornecimento",
        borrowRecords: "Registros de empréstimo",
        completed: "concluído",
        borrowNow: "Peça emprestado agora",
        borrowCurrency: "Empréstimo de moeda",
        borrowMin: "menor",
        borrowMax: "máximo",
        borrowRange: "Valor do empréstimo (mínimo/máximo)",
        tips6: "Por favor, pague a tempo. Se estiver vencido, será cobrado um valor diário adicional de (principal + juros).",
        tips7: "por taxas atrasadas。",
        pleaseEnterBorrowAmount: "Por favor insira o valor do empréstimo",
        interest: "Interesse",
        tips8: "Tem certeza de que deseja pagar antecipadamente?？",
        borrowChannel: "Canal de empréstimo",
        tips9: "Vamos começar conhecendo você",
        name: "nome",
        lastName: "sobrenome",
        birthDate: "data de nascimento",
        tips10: "Um pouco mais de informação, qual é o seu endereço?？",
        familyAddress: "Endereço residencial (incluindo número da unidade）",
        city: "Cidade",
        country: "nação",
        postalCode: "Código postal",
        pleaseEnterYourHomeAddress: "Por favor insira seu endereço residencial",
        tips11: "Conte-nos sobre sua situação financeira",
        tips12: "Poderemos precisar confirmar essas informações mais tarde, então tente ser o mais preciso possível",
        yourMonthlyIncome: "sua renda mensal",
        enterYourMonthlyIncome: "Por favor insira sua renda mensal",
        extraIncomeOptional: "Renda adicional (opcional)",
        enterExtraIncome: "Por favor insira sua renda adicional",
        monthlyRentOrMortgagePayment: "Aluguel mensal ou pagamento de hipoteca",
        enterMonthlyRent: "Insira seu aluguel mensal ou pagamento de hipoteca",
        tip13: "Diga-nos se você tem uma hipoteca",
        tip14: "Se nenhuma garantia hipotecária for selecionada, a probabilidade de aprovação na revisão é baixa.",
        isThereMortgage: "Existe uma hipoteca?",
        selectIsThereMortgage: "Selecione se há uma hipoteca",
        selectMortgageType: "Selecione o tipo de hipoteca",
        yes: "sim",
        no: "não",
        uploadScreenshotOfTransaction: "Faça upload das capturas de tela da transação",
        tip15: "Vamos manter contato",
        tip16: "Poderemos entrar em contato com você neste número se precisarmos de mais informações sobre seu pedido de empréstimo",
        tip17: "Li e assinei eletronicamente meu consentimento",
        tip18: "Termos de Uso e Consentimento Eletrônico",
        tip19: "，Isto inclui o seu consentimento para que lhe enviemos informações sobre nossos",
        tip20: "Termos de uso de produtos e serviços",
        tip21: "Após preencher as informações, aguarde a avaliação e revisão do sistema。",
        phoneNumber: "número de telefone",
        enterPhoneNumber: "Por favor, digite seu número de telefone",
        tip22: "Marque a caixa para concordar com os termos de uso e o formulário de consentimento eletrônico",
        inReview: "sob revisão",
        applicationAmount: "Valor da aplicação",
        reasonForRejection: "Razão para rejeição",
        reapply: "Reaplicar",
        applyStatus: "status do aplicativo",
        currentOrder: "ordem atual",
        interestRate: "taxa de juro"
    },
    yuebao: {
        filed1: "investir",
        filed2: "retirar",
        filed3: "Renda diária",
        filed4: "aceleração da equipe",
        filed5: "Depositado",
        filed6: "Lucro de ontem",
        filed7: "Rendimento total",
        filed8: "Fundos disponíveis",
        filed9: "Por favor insira o valor",
        filed10: "Mineração de liquidez",
        filed11: "Introdução",
        field12: "Preço mínimo de compra",
        field13: "O máximo que você pode comprar",
        filed14: "Inscrição",
        filed15: 'regular',
        filed16: 'taxa de juros anualizada',
    },
    tipsPopover: {
        title: "Instale este aplicativo",
        press: "de acordo com",
        and: "e",
        scrren: "Adicionar à tela inicial",
        hasInstall: "(Se instalado, feche a janela)",
    },
    follow: {
        title1: "Plano de acompanhamento",
        follow_text1: "Iniciar um pedido de acompanhamento",
        follow_text2: "Histórico de lançamento",
        follow_text3: "título",
        follow_text4: 'par de negociação',
        follow_text5: 'Duração da compra',
        follow_text6: 'hora do pedido',
        follow_text7: 'hora de lançamento',
        follow_text8: 'Número de seguidores',
        follow_text9: 'Valor do pedido',
        follow_text10: 'operar',
        follow_text11: 'convidar',
        follow_text12: 'Siga a direção do pedido',
        follow_text13: 'Definir condições de pedido',
        follow_text14: 'Condições para seguir ordens',
        follow_text15: 'quantia fixa',
        follow_text16: 'percentagem',
        follow_text17: 'Pessoas convidadas',
        follow_text18: 'O ID da outra parte',
        follow_text19: "Confirme para seguir o pedido",
        follow_text20: "O valor do seu pedido (opcional)）",
        follow_text21: "Por favor insira um título",
        follow_text22: "Selecione um par de negociação",
        follow_text23: "Por favor selecione a direção do pedido",
        follow_text24: "Selecione a duração da compra",
        follow_text25: "Selecione o horário do pedido",
        follow_text26: "Selecione a quantidade de compra",
        follow_text27: "A quantidade máxima de compra não pode ser inferior à quantidade mínima de compra",
        follow_text28: "Buy-in mínimo",
        follow_text29: "A maioria compra",
        follow_text30: "Criar pedido de cópia com sucesso",
        follow_text31: "Lista de pares de negociação",
        follow_text32: "Já segui a ordem",
        follow_text33: "Insira as informações do convidado",
        follow_text34: "Convidamos",
        follow_text35: "convidou-me",
        follow_text36: "Copiando histórico",
        follow_text37: "Faixa de valor do pedido"
    },
    followHistory: {
        title1: 'Histórico de lançamento',
        title2: "Copiando histórico",
        follow_h_text1: "O valor total do pedido",
        follow_h_text2: "Número total de pessoas seguindo ordens",
        follow_h_text3: "Número de lançamentos",
        follow_h_text4: "taxa de vitórias",
        follow_h_text5: "Número de vezes para seguir ordens",
        follow_h_text6: "Lucre com o seguimento de pedidos",
        follow_h_text7: "direção",
        follow_h_text8: "ganhar",
        follow_h_text9: "déficit",
    },
    "asset.index.1579E8B41F8D5134": "meus bens",
    "asset.index.B3F6F05Dfbe431Cd": "simulação",
    "asset.index.B9Aeab4Ee8F33746": "Avaliação de ativos",
    "asset.index.583Df8Bd541Eb7E8": "tendências de ganhos",
    "asset.index.B1254815D133Cc26": "7 dias",
    "asset.index.2F615F6C029F01B7": "30 dias",
    "asset.index.29439Fb58E4Bbe22": "retirar",
    "asset.index.831Ab31568A78285": "completar",
    "asset.index.39F7A0D48D12E8C9": "transferir",
    "asset.index.B99063C5689Beaec": "Troca de flash",
    "asset.index.E1Dc620F2F038B2A": "Receber ativos",
    "asset.index.972A0B4C2B4393D0": "minha conta",
    "asset.index.9Dfb0Fb334620733": "Saldo disponível",
    "asset.index.Cdc5Fbd26790Cb47": "quantidade congelada",
    "asset.index.3D8132D72Ae965Cd": "Avaliação",
    "asset.receive.825503Cc430559Cb": "adquirir ativos",
    "asset.receive.54154A0553A535B7": "Recursos de demonstração",
    "asset.receive.F2E57B00A9C5D129": "1.Este ativo só pode ser usado no modo de conta demo",
    "asset.receive.Ea0D1D52989299Bd": "2.Para evitar abusos, apenas um resgate é permitido dentro de um mês.",
    "asset.receive.090E0037Aa235D3D": "3.O recebimento de ativos adicionará a quantidade de ativos recebidos na base original e não alterará o status da posição.",
    "asset.receive.7Fd4E1Abc805C0A6": "4.Se você tiver alguma outra dúvida, entre em contato com o atendimento ao cliente",
    "asset.receive.Df5B3A511D986575": "Receba fundos simulados",
    "asset.records.1Fa02E8187A39592": "últimos três meses",
    "asset.records.7B291C37333Fe34D": "de perto para longe",
    "asset.records.A476Cd1Ec868Fc5F": "últimos três meses,",
    "asset.records.9D5755A842E55Bb7": "de perto para longe,",
    "asset.records.E3Fa05E607203Cdc": "completar",
    "asset.records.B4F206C6Bec08Dea": "retirar",
    "asset.index.Df5B3A511D986576": "Você recebeu com sucesso os ativos de demonstração",
    "deposit.depositSpeedy.Efe81D0E0174239B": "Forma de pagamento",
    "deposit.depositSpeedy.B9293Ee96E435F85": "Moeda fiduciária",
    "deposit.depositSpeedy.16658A855F07D171": "Quantia",
    "deposit.depositSpeedy.204Ffab8A6E01870": "Claro",
    "deposit.depositSpeedy.22E5Dfb74Ead8Aa9": "Não pode estar vazio",
    "deposit.depositSpeedy.B8E774D0E4E5E4C1": "Recarga mínima",
    "deposit.depositSpeedy.F169D816F6Dd83C5": "Recarga máxima",
    "deposit.depositWallet.F64Ec60B1Db4A6D6": "Depositar USDT",
    "deposit.depositWallet.551640C76F4Ca4A1": "Moeda de recarga",
    "deposit.depositWallet.B404C543712B71D5": "Rede de recarga",
    "deposit.depositWallet.A484B0F5E94B3F4F": "Endereço de depósito",
    "deposit.depositWallet.D3980D54Acf242Db": "Salvar código QR",
    "deposit.depositWallet.A06E84801D6582Be": "Valor de recarga",
    "deposit.depositWallet.Daaaeb1B7B22B126": "dica",
    "deposit.depositWallet.D599E40E1Eeec1B3": "Recarga bem-sucedida",
    "deposit.depositWallet.4F48Abbafce532Bb": "Enviado com sucesso, aguardando análise do atendimento ao cliente",
    "deposit.depositWallet.A88C481228811696": "Você se recusou a mudar de rede",
    "deposit.depositWallet.C6Ebf27895C21932": "Abra manualmente o plug-in da carteira TRON e faça login",
    "deposit.index.B5491C8Dd09D8183": "Selecione a moeda que deseja recarregar",
    "deposit.index.2F784B2298D39987": "Recarga rápida",
    "deposit.index.0Daa03Dcdcabd837": "Links externos",
    "withdraw.bottomSheet.628A63056Ef2D2Fe": "Confirmação de retirada",
    "withdraw.bottomSheet.A086Fa20082C849D": "Verifique se as informações abaixo estão corretas",
    "withdraw.bottomSheet.Bf8B184D32971501": "Rede de retirada",
    "withdraw.bottomSheet.8758Fd50C87D6C9C": "endereço",
    "withdraw.bottomSheet.F9Db93B87E08763B": "quantidade",
    "withdraw.bottomSheet.4Ad26475002878C0": "taxa de manuseio",
    "withdraw.index.A96454041169Af6D": "Escolha a moeda",
    "withdraw.index.2A563B7081Dd5B83": "Endereço de retirada",
    "withdraw.index.B189C2Ef713Cfff4": "Quantidade de moedas retiradas",
    "withdraw.index.558F35140E84B562": "Disponível：",
    "withdraw.index.F4E759087E12Cb77": "Retirar tudo",
    "withdraw.index.5Cbd9A6B284D25Ae": "Senha do fundo",
    "withdraw.index.B89Cbe4Bfc33Bb41": "taxa de manuseio:",
    "withdraw.index.C886De8D8A27026E": "notícia importante",
    "withdraw.index.Eac7C5A4095F5606": "1.Para evitar arbitragem, você pode solicitar a retirada de moeda quando o volume de transações atingir a cota.",
    "withdraw.index.C3Cc7Fff24504Be9": "2.O pedido de saque será creditado em até 24 horas após o envio. Caso o saque não seja recebido após o prazo estimado de saque, consulte o atendimento ao cliente online.",
    "withdraw.index.0B4Ad7Ccdc833B0D": "3.Após o envio do pedido de saque, os fundos ficam congelados porque o saque está em andamento e os fundos estão temporariamente retidos pelo sistema. Isso não significa que você perdeu o ativo ou que há uma anormalidade com o ativo.",
    "withdraw.index.4639F3A6E07C00B3": "Próxima Etapa",
    "withdraw.index.81Fba0D65Cc5C3Aa": "Por favor, insira ou cole o endereço da sua carteira",
    "withdraw.index.25768141C8Bdea91": "Por favor insira o valor do saque",
    "withdraw.index.97A85734Cd1B7Bf0": "Por favor insira a senha do fundo",
    "withdraw.index.39C7D74B33241C78": "O valor da retirada está errado",
    "withdraw.index.86A5D17908197525": "Confirmação de saque, aguardando análise para liberação de moedas)",
    "withdraw.index.48Fb603C6638C7F6": "Por favor, defina uma senha de fundo",
    "withdraw.index.873Ade9A3Bacc2F7": "dica",
    "bill.index.5Bbb2A6Ac68D6B2E": "conta real",
    "bill.index.75751E0Eb8261C40": "Conta de demonstração",
    "bill.index.Cbc853B37Ecc2E97": "Tipo de alteração de conta",
    "bill.index.4A0Cacddeb2E38B1": "tipo de conta",
    "bill.index.F16217028E098B2F": "Moeda",
    "bill.index.A620Dbe6F06B78Ba": "Todos os tipos",
    "bill.index.2C3C148Bb94D9B3F": "Todas as contas",
    "c2C.index.Ab45695E4Bf1E382": "Transação C2C",
    "c2C.index.2702Dc911Dfea762": "eu quero comprar",
    "c2C.index.D737D5B8367A53C4": "eu quero vender",
    "c2C.index.C0C540A3Ba9Abe01": "Volume",
    "c2C.index.D8644E8D60C8Bd8A": "preço unitário",
    "c2C.index.Ebb8670B203D3604": "limite",
    "c2C.index.F8C6245A2Ebf1D27": "Todos os cartões bancários",
    "c2C.index.F8C6245A2Ebf1D28": "cobrança de moeda",
    "c2C.index.F8C6245A2Ebf1D29": "cartão do banco",
    "c2C.index.Cc3Aa8141Da9Ec9E": "Comprar",
    "c2C.index.Cc3Aa8141Da9Ec9F": "vender",
    "c2C.index.67A39834582Ad99A": "Sem dados",
    "c2C.order.04C23810B47F77F7": "por valor",
    "c2C.order.6905365902E0A73D": "por quantidade",
    "c2C.order.3F0Ecebbd9D2F23B": "Disponível",
    "c2C.order.16853Bda54120Bf1": "todos",
    "c2C.order.Ad3335796F2C95F7": "montante fixo",
    "c2C.order.61092E27A6879318": "Para proteger a segurança dos ativos, aumente a sua consciência sobre a prevenção!",
    "c2C.order.0F51881F0Fb920B2": "Informações comerciais",
    "c2C.order.Dd74Ceca85E5Bc65": "Prazo de pagamento",
    "c2C.order.De3341388A59073F": "30 minutos",
    "c2C.order.D334B597A352167F": "Apelido do vendedor",
    "c2C.order.D334B597A352167G": "Apelido do comprador",
    "c2C.order.9D5F89F99Ced890D": "meio de transação",
    "c2C.order.8Adf755B14Bc6B7C": "Por favor leia o seguinte primeiro",
    "c2C.order.686B16F454A7338B": "Não faça comentários ao fazer transferências com cartão bancário, caso contrário o dinheiro não será liberado e a conta será bloqueada diretamente. Após o pagamento, você precisa fornecer um novo diagrama de detalhes da transação após o pagamento (se o diagrama P ou detalhes ocultos da transação forem relatados à plataforma para congelar a conta)",
    "c2C.order.9Bec8Fba867B739B": "Selecione o método de pagamento",
    "c2C.order.Cf2D892C419Fea36": "compra mínima",
    "c2C.order.7290A6B7456A03E0": "maior compra",
    "c2C.orderConfirm.F7E99B8C203E2B07": "detalhes do pedido",
    "c2C.orderConfirm.036D94Dc2D1Cf7D3": "O pedido foi gerado",
    "c2C.orderConfirm.0F5433503F3845Ce": "Por favor, pague ao vendedor dentro do tempo de contagem regressiva.",
    "c2C.orderConfirm.24C3Bcacdb85A451": " Aguardando o pagamento dos compradores ",
    "c2C.orderConfirm.8E8140D7Ab607B24": "Entre em contato com o vendedor",
    "c2C.orderConfirm.8E8140D7Ab607B25": "entre em contato com o comprador",
    "c2C.orderConfirm.Ec4D8722Ec15C813": "A moeda digital do vendedor agora está depositada na conta de garantia, sinta-se à vontade para pagar  ",
    "c2C.orderConfirm.69D1Fbbc7700C1Ab": "Nome verdadeiro do comprador：",
    "c2C.orderConfirm.E5Ed8Ec53Edcd5D3": "Suporte de atendimento ao cliente 7x24 horas ",
    "c2C.orderConfirm.Abf9F4B8C93D641E": "número do pedido",
    "c2C.orderConfirm.726C51702F70C486": "hora de criação",
    "c2C.orderConfirm.E097Bb9B1216Bfd8": "Entre em contato com o atendimento ao cliente",
    "c2C.orderConfirm.103408Fbbe30287F": "cancelar pedido",
    "c2C.orderConfirm.4E93Df1Fb521F4Ba": "Eu confirmei o pagamento",
    "c2C.orderConfirm.3B5418Dbea7Ebfe4": "Já pago",
    "c2C.orderConfirm.387Ca3625B260E4E": "Seu pedido pago",
    "c2C.orderConfirm.7C83D5F1Db1A08B7": "cancelar transações",
    "c2C.orderConfirm.76C86566Cc3751C0": "Pedido cancelado",
    "c2C.orderConfirm.205B349751D176B4": "pagamento bem sucedido",
    "c2C.paymentAdd.6F2A7Dcc66E6Acba": "Forma de pagamento",
    "c2C.paymentAdd.C87E865Df6800304": "Configuração da forma de pagamento",
    "c2C.paymentAdd.B59Ae0F1B1C235Df": "Tipo de forma de pagamento",
    "c2C.paymentAdd.06910Bddf002695F": "nome real",
    "c2C.paymentAdd.3E33Ae3Efa7507Dd": "Conta Paypal",
    "c2C.paymentAdd.3E33Ae3Efa7507De": "Número da conta bancária/número do cartão",
    "c2C.paymentAdd.3E33Ae3Efa7507Df": "Nome da moeda",
    "c2C.paymentAdd.595Ede91Abf6F6E0": "Pague o código QR",
    "c2C.paymentAdd.1Ecc8Be9Ba1C940A": "Clique para carregar",
    "c2C.paymentAdd.6Daae340F2E9718A": "nome do banco",
    "c2C.paymentAdd.347751415C3Da489": "Nome da cadeia",
    "c2C.paymentAdd.15B3627Faddccb1D": "Observação",
    "c2C.paymentAdd.86Da96Cbd274058F": "Lembrete caloroso: quando você vende moeda digital, o método de pagamento escolhido será exibido ao comprador. Por favor, confirme se as informações foram preenchidas com precisão.",
    "c2C.paymentAdd.4Ce9979Bfb6576D9": "salvar",
    "c2C.paymentAdd.90D2A3A5C07179C0": "por favor digite seu nome verdadeiro",
    "c2C.paymentAdd.A3B0Cd3Ad9Ca3475": "Por favor insira o seu",
    "c2C.paymentAdd.Ec389E6190Eedb62": "Por favor insira o nome do seu banco",
    "c2C.paymentAdd.9Be51Fb1E9025Fb2": "Por favor insira o nome da sua rede",
    "c2C.paymentAdd.94F034F0E01439E0": "Por favor insira seu endereço",
    "c2C.paymentAdd.B12E035663A42B32": "Todos os cartões bancários",
    "c2C.paymentAdd.C7B677F393A8F8Ac": "cobrança de moeda",
    "c2C.paymentAdd.Abafe11737Bab7Cd": "Por favor carregue o código de pagamento",
    "c2C.paymentAdd.07C443212Ff04Bfb": "Adicionado com sucesso",
    "c2C.paymentDetail.86Da96Cbd274058F": "Lembrete caloroso: quando você vende moeda digital, o método de pagamento escolhido será exibido ao comprador. Por favor, confirme se as informações foram preenchidas com precisão.",
    "c2C.paymentDetail.C4C613Da60226De4": "Modificado com sucesso",
    "c2C.paymentMethod.7609F7C8337C7A17": "Método de pagamento C2C",
    "c2C.records.9E36893D5736Abb4": "Lista de pedidos",
    "c2C.records.4B53F9A5B13B3235": "Pagamento Pendente",
    "c2C.records.4B53F9A5B13B3236": "cancelar transações",
    "c2C.records.4B53F9A5B13B3237": "Já pago",
    "contract.index.9706Df877458F0Be": "Armazém completo",
    "contract.index.623Fc7D3D40Aa479": "Margem inicial",
    "contract.index.F92Dce1169330387": "O preço de mercado é estável",
    "contract.index.3598B97Dd28Dbf20": "Por favor insira a quantidade (peças)",
    "contract.index.6E1Fae821Dc0B797": "Sustentável",
    "contract.index.Eb07Eb0C37C14575": "Entrega",
    "contract.index.Eb6492F19B1C1Fb6": "Detalhes históricos da transação",
    "contract.index.873Ade9A3Bacc2F7": "dica",
    "contract.index.Ff63B0F93D39F50B": "Você tem certeza que está certo",
    "contract.index.Ff63B0F93D39F50D": "Vá longo",
    "contract.index.Ff63B0F93D39F50E": "curto",
    "contract.index.Ff63B0F93D39F50F": "conduta",
    "contract.index.Ff63B0F93D39F50G": "Fechar todas as posições?",
    "customer.dialog.3D410C88E8A24Fe8": "Confirmar apelido",
    "customer.dialog.4B3340F7872B411F": "Cancelar",
    "customer.dialog.549B1456Bb5F98B3": "Por favor insira seu apelido",
    "customer.dialog.B773B3E45Ccfad00": "O apelido está vazio",
    "customer.index.7308C6B849D159E9": "Detalhes da imagem",
    "delivery.callDialog.C74F562C3Fe91Fe3": "encomendado com sucesso",
    "delivery.callDialog.B8C98F42Caa15Ca5": "direção",
    "delivery.callDialog.B79A581Dae543974": "compra longa",
    "delivery.callDialog.B79A581Dae543975": "compra a descoberto",
    "delivery.callDialog.3B26F0F7E90773F3": "Preço de abertura",
    "delivery.callDialog.65Fb252Bd94E1039": "Receita estimada",
    "delivery.index.B483Ba4Fce633539": "Entrega",
    "delivery.index.224Dd6Cb58D58249": "período de negociação",
    "delivery.index.28158F517E902788": "Buy-in mínimo",
    "delivery.index.A1232162B14Bec76": "Pedido de entrega",
    "delivery.index.445B1D3176D9C69D": "Ordens históricas",
    "delivery.index.Ab6Cb86E28653C5C": "Entrega",
    "delivery.index.20A7A6F14D3698C0": "Tempo de abertura",
    "delivery.index.Ec6821A49395Ddc2": "Horário de encerramento",
    "delivery.index.254Fdc665C391743": "Preço final",
    "delivery.index.2A0C5E68B44A472A": "Lucros e perdas",
    "delivery.index.E05B33309F6D236A": "Por favor insira a quantidade",
    "delivery.index.B2899E073Cb355D9": "Compra mínima",
    "download.description.869467A93C7Dd4A1": "Notas de instalação",
    "download.description.C80C1Edd6Bee12Bc": "1.Permitir o download de arquivos de descrição",
    "download.description.2F5Bc180F92642B4": "2.Arquivo de descrição baixado",
    "download.description.3C3B849F6Ccf712F": "3.Entre em [Configurações] no seu telefone e o arquivo de descrição baixado será exibido abaixo das informações da conta.",
    "download.description.86Affd862985E045": "4.Clique para baixar o arquivo de descrição",
    "download.description.32832492Bfc1D95E": "5.Clique para instalar",
    "download.index.245883C80F181C4A": "download",
    "download.index.Ef3De8Cb2722937A": "Comece a negociar a qualquer hora, em qualquer lugar",
    "download.index.F4A87Eaeed691638": "Fique por dentro das últimas novidades através do nosso APP",
    "download.index.Ad3Aba52D0D39D01": "Baixe o pacote de instalação",
    "download.safariGuide.61Bd9A8Fcc2Ea788": "Dicas gentis",
    "download.safariGuide.2Cc68Aaadf623477": "Visite no Safari",
    "download.safariGuide.C80Fff2A6Ff4E768": "Você está visitando um site descentralizado, execute no aplicativo Safari",
    "download.safariGuide.06Da016F3E2F79Bd": "link de cópia",
    "finance.index.B034B367545D5236": "Projetos globais de alta qualidade",
    "finance.index.6Ebfac2C0A9228B0": "Mais de 1,6 milhão de usuários ativos, US$ 122,4 bilhões",
    "finance.index.52Bdcaf19659363D": "Mineração de liquidez",
    "finance.index.D66924272B031Bf5": "Renda anualizada super alta",
    "finance.index.34789D88Afcefa64": "Ver itens",
    "finance.index.E12676B95654D21E": "empréstimo de crédito",
    "finance.index.4C924Cfd89A11Ac7": "Descubra mais oportunidades de negociação",
    "finance.index.C251D4B594Da2848": "Quantificação de IA",
    "finance.index.4E53Cf2E5F173Dd3": "Leasing com um clique, transação inteligente de IA",
    "finance.records.Cce168301447D1Ba": "em andamento",
    "finance.records.4587B6Bd7Cfa4454": "resgatado",
    "finance.records.18685418E3A50147": "Mineração",
    "finance.records.Df9Fd438F97Cc908": "céu",
    "finance.records.E23Cb03C5Ad359E5": "O termo",
    "finance.records.Bd87Dac6Cba7Eba8": "Quantidade de compra",
    "finance.records.2D0Fff99265E8B1A": "Rentável",
    "finance.records.2D0Fff99265E8B1B": "Lucro no vencimento",
    "finance.records.024F5F0B851782De": "Tempo de mineração：",
    "finance.records.3143A26D3F983896": "hora de resgate：",
    "finance.records.96574Aaf8Db85Ead": "resgate antecipado",
    "finance.records.531E1829203181Ac": "Tem certeza de que deseja resgatar antecipadamente;",
    "finance.records.9205843B8923Dac3": "Resgate bem-sucedido;",
    "loan.applyCallback.B9258Ec8746A74D8": "Canal de empréstimo",
    "loan.applyForm.7B8Ed340400A6E2B": "nome",
    "loan.applyForm.549A44Bae725F4A2": "sobrenome",
    "loan.applyForm.2A27E61375E7Fea8": "data de nascimento",
    "loan.applyForm.89925B019D537738": "endereço de email",
    "loan.applyForm.Afaeb32Cb4D62E28": "Endereço residencial (incluindo número da unidade)",
    "loan.applyForm.E05B5D049B64B040": "Cidade",
    "loan.applyForm.B166E4E8Fe9513Fa": "nação",
    "loan.applyForm.83Aaede109E395F8": "Código postal",
    "loan.applyForm.647Ed21332A4968B": "sua renda mensal",
    "loan.applyForm.Cf0A9B845D34D4B4": "Renda adicional (opcional)",
    "loan.applyForm.7048F618Acc6A54C": "Aluguel mensal ou pagamento de hipoteca",
    "loan.applyForm.15C1Bba18Ae18545": "Diga-nos se você tem uma hipoteca",
    "loan.applyForm.3921E90625A84E36": "Se nenhuma garantia hipotecária for selecionada, a probabilidade de aprovação na revisão é baixa.",
    "loan.applyForm.262Eec4F8906B0F6": "Existe uma hipoteca?",
    "loan.applyForm.8634Eb21B6910F21": "Selecione o tipo de hipoteca",
    "loan.applyForm.A4897B3A331B5E61": "Comprovante de transação",
    "loan.applyForm.6623384C683De457": "Vamos manter contato",
    "loan.applyForm.A8360Bdba86834Cb": "Poderemos entrar em contato com você neste número se precisarmos de mais informações sobre seu pedido de empréstimo",
    "loan.applyForm.C1C373F385A4C7B3": "número de telefone",
    "loan.applyForm.1B9785Badb91191F": "Após preencher as informações, aguarde a avaliação e revisão do sistema.",
    "loan.applyForm.8755B9C761D4080C": "Li e assinei eletronicamente meu consentimento",
    "loan.applyForm.3E987B787468148C": "Termos de Uso e Consentimento Eletrônico",
    "loan.applyForm.Bc54E76B61C50Fb9": "Por favor leia e concorde com o acordo",
    "loan.applyForm.89E158384F634Eb8": "Voltar ao passo anterior",
    "loan.applyForm.Efa5A4548E2F3510": "Endereço residencial",
    "loan.applyForm.4Cadf1787541B35D": "sim,",
    "loan.applyForm.Fab408D2F4100447": "não,",
    "loan.applyForm.3Bad7A307480Cc56": "O formato do e-mail está errado;",
    "loan.applyForm.306322F49B36855D": "A inscrição foi enviada, aguarde a análise do atendimento ao cliente",
    "loan.applyForm.3C6E0E858C995Ff9": "Faça upload do comprovante de transação",
    "loan.applyForm.2Bf443476B271376": "Até 4 fotos podem ser carregadas",
    "loan.applyQuota.Ef1D8E23003B9A6F": "Canal de empréstimo",
    "loan.applyQuota.Ef1D8E23003B9A6G": "Obter limite de empréstimo",
    "loan.applyQuota.9E0780F9352C7273": "saldo da carteira",
    "loan.applyQuota.Cbc1Eb9D639A6105": "Valor total do empréstimo disponível",
    "loan.applyQuota.033A05462195B080": "Montante emprestado",
    "loan.applyQuota.9Aa89B036Ef4821D": "saldo remanescente",
    "loan.applyQuota.5656Fb52Ef17A557": "Obter limite de empréstimo",
    "loan.applyQuota.6Ff6702B4C917D40": "sob revisão",
    "loan.applyQuota.6Ff6702B4C917D41": "rejeitado",
    "loan.applyQuota.6Ff6702B4C917D42": "sucesso",
    "loan.applyQuota.0Bad0E857Ea4A952": "tempo de aplicação",
    "loan.applyQuota.3E7E56E2De2Cc546": "Valor da aplicação",
    "loan.applyQuota.8F1D170D9E54Af5B": "Progresso da aplicação",
    "loan.applyQuota.6167Ca035B93Caf3": "razão",
    "loan.applyQuota.410C7954F9A3981D": "Reaplicar",
    "loan.bottomSheet.61Cd62F861D8F5B2": "Selecione a moeda",
    "loan.index.4C10307B22E885C3": "Descubra mais oportunidades de negociação e crie riqueza",
    "loan.index.Ae8D37Dea2B3717B": "taxa de juro",
    "loan.index.1Bb54625682E85Db": "Introdução à regra",
    "loan.index.5Bd31Fab681C6E9D": "ver mais",
    "loan.index.111F95F404814327": "Moeda do empréstimo",
    "loan.index.6Bc2723B3Bec106D": "empréstimo",
    "loan.index.3200106280E6E370": "taxa de juro",
    "loan.index.049A6Ccb6C81A635": "Valor do empréstimo (mín./máx.)",
    "loan.productDetails.5037Ad86Ab4B7E02": "empréstimo",
    "loan.productDetails.37181C74559Db182": "Ordem ",
    "loan.productDetails.5802Aaf06A1E4755": "Liquidez",
    "loan.productDetails.89Aea9Cf9337C40F": "Quantidade disponível para empréstimo",
    "loan.productDetails.9E419Ae2D06F3Ad9": "Quantidade a ser reembolsada",
    "loan.productDetails.C65F329Ef14C4A3D": "Quantidade de moedas emprestadas",
    "loan.productDetails.3A74667815Adc8Bf": "máximo",
    "loan.productDetails.Ab851D8351573227": "céu",
    "loan.productDetails.Ddf82D8A2C8F46A0": "ciclo de reembolso",
    "loan.productDetails.C105E5562005Aeb1": "céu",
    "loan.productDetails.3F346Be2137F0756": "Valor do empréstimo (mín./máx.)",
    "loan.productDetails.2707F78Df864E194": "Interesse",
    "loan.productDetails.B9Dc9B81E726Bdf1": "Por favor, pague a tempo. Se estiver vencido, será cobrado um valor diário adicional de (principal + juros).",
    "loan.productDetails.B9Dc9B81E726Bdf2": "% é taxa vencida",
    "loan.productDetails.4D94Faa033Be7356": "eu concordei",
    "loan.productDetails.62429A957A22780A": "Contrato de serviço de empréstimo de moedas",
    "loan.productDetails.Bc54E76B61C50Fb9": "Por favor leia e concorde com o acordo",
    "loan.productDetails.Ec8Ba071C53A67Cb": "Peça emprestado agora",
    "loan.productDetails.59246A0Bf081D398": "ao menos",
    "loan.productDetails.Ec3F1172B71Cd36C": "quantidade mínima",
    "loan.productDetails.7Ccc4Cf1D81930B3": "maior quantidade",
    "loan.productDetails.071488598990Aa68": "Por favor leia e concorde com o acordo;",
    "loan.productDetails.579Dd216D3339119": "Empréstimo bem sucedido;",
    "loan.records.4951B36Fd0D2A831": "Histórico de empréstimos e crédito",
    "loan.records.Bb0Aaa30514B7Cba": "Registro atual",
    "loan.records.0F8B0A7D80769051": "Histórico de reembolso",
    "loan.records.D4B42E43D88De6F1": "registro histórico",
    "loan.records.Cb1847D6281897E6": "Montante do empréstimo",
    "loan.records.C7Bfcacfc2Adea58": "Valor do reembolso",
    "loan.records.8B369164D5Fea340": "Tempo de empréstimo：",
    "loan.records.Ab2402Ad3Ffd35E7": "Pague o empréstimo antecipadamente",
    "loan.records.B7A0Ca0C195D0Cd1": "Hora de lançamento：",
    "loan.records.42014Bdcfd9C411B": "Prazo de reembolso：",
    "loan.records.A0Acfaee54F06458": "concluído",
    "loan.records.67A39834582Ad99A": "Sem dados",
    "loan.records.6A687Ff5B8F1598F": "Tem certeza de que deseja pagar antecipadamente?",
    "loan.records.7C20D7008D0Eae86": "Reembolso bem-sucedido",
    "mining.bottomSheet.Ba91637D32Ccba04": "Mineração de liquidez",
    "mining.bottomSheet.7De3D636A165E008": "taxa de retorno",
    "mining.bottomSheet.F3003345B189D683": "Período de mineração",
    "mining.bottomSheet.3E4C0A8C533F5848": "tipo de moeda",
    "mining.bottomSheet.Adcba368993Ae65E": "Valor da assinatura (mínimo/máximo)",
    "mining.bottomSheet.94743B24E4Ed4166": "Ilimitado",
    "mining.bottomSheet.286944Fedd211257": "Fundos disponíveis",
    "mining.bottomSheet.7B5766Dc65Abc0C3": "Receita estimada:",
    "mining.bottomSheet.9Bbaf80Fdb6Fd148": "Meu agora",
    "mining.bottomSheet.Bb1493C567C114Dd": "Dicas gentis：",
    "mining.bottomSheet.D2315E87E34A3Bd3": "Taxa de resgate antecipado",
    "mining.index.8B2Bba1422Eed6F0": "Crie dezenas de bilhões de riqueza, 100% de lucro líquido",
    "mining.index.42997E300086Ee89": "maior retorno",
    "mining.index.510F74F42052Ecce": "Produtos de mineração",
    "mining.index.B853F96626106912": "Vá minerar",
    "mining.productList.37181C74559Db182": "Ordem",
    "mining.productList.Aac5B810004C46C8": "regular",
    "mining.productList.D2Fb4F78C387D888": "Você extraiu com sucesso",
    "quantify.bottomSheet.9Ea4A318F690Bc95": "Confirmar pedido",
    "quantify.bottomSheet.6915303B698303B1": "robô",
    "quantify.bottomSheet.68045296Dd0F8Ad6": "Valor do aluguel",
    "quantify.bottomSheet.Fbd931286Be387Aa": "ciclo",
    "quantify.bottomSheet.9Ad84Db481807844": "indefinidamente",
    "quantify.bottomSheet.994D7226Ca083Ea5": "Taxa diária de retorno",
    "quantify.bottomSheet.0Bf82Ee4Bfe063B7": "data de validade",
    "quantify.bottomSheet.88008603Ba7Bde64": "Compra bem-sucedida",
    "quantify.bottomSheet.Ff50B3091Bbd09Ab": "Obtenha participação na receita a partir de agora！",
    "quantify.bottomSheet.C778A674C4A054F9": "verificar pedido",
    "quantify.bottomSheet.685Dad322B7029B0": "Transação de devolução",
    "quantify.buyForm.Acf8560A04334E95": "preço de compra",
    "quantify.buyForm.Cf9Cfbc2C554944F": "Visão geral",
    "quantify.buyForm.8788Df2B625E97B5": "robô",
    "quantify.buyForm.50Ca63B45E881D50": "Prazo de pagamento de dividendos",
    "quantify.buyForm.Fcda03Adbc5Fca97": "diariamente",
    "quantify.buyForm.Afb89504Fc399189": "Por favor insira a quantidade de compra",
    "quantify.buyForm.1435Afdfb4Ec0596": "Quantidade mínima de compra",
    "quantify.buyForm.1B97F1730Bacd839": "Quantidade máxima de compra",
    "quantify.index.32F56C9B7D0E250A": "Período: indefinido",
    "quantify.index.E34F028D00904630": "preço",
    "quantify.index.E34F028D00904631": ", taxa diária de retorno",
    "quantify.productDetail.6Bbe178Df539A5Ca": "Valor do depósito",
    "quantify.productDetail.53635B53Bca31Dfa": "Renda diária estimada",
    "quantify.productDetail.F9E46449A6269E09": "Renda acumulada",
    "quantify.productDetail.98Fc46B1B8C993B2": "Solicitar hospedagem",
    "quantify.productDetail.93624Bb045830966": "Alugue agora",
    "quantify.records.0C16A18C32B9Cf4A": "Registro de pedido",
    "quantify.records.B191F5614B0E2312": "Histórico de compras",
    "quantify.records.6A06033B86A73F7C": "registro de resgate",
    "quantify.records.45E0F7E71611618E": "Renda acumulada",
    "quantify.records.45E0F7E71611618F": "Lucro no vencimento",
    "quantify.records.Aae1201E38C4Baaa": "Correndo",
    "flash.index.C41Fd4F2Fdfa594B": "consumir",
    "flash.index.1Ac59Fa2218E3B00": "Disponível:",
    "flash.index.Bf7233A660821549": "pegar",
    "flash.index.548E775Dd67C7472": "Confirmar troca",
    "flash.index.066A2449Bd616F03": "Insira a quantidade de resgate",
    "flash.index.Fca26Fcf9C06563F": "Resgate de Flash bem-sucedido",
    "message.index.40031524233F4564": "Centro de mensagens",
    "message.index.8E5586210Fd44D69": "Lembrete de posição perpétua",
    "message.index.Bcd3F470E88C197D": "Nenhuma notificação ainda",
    "message.index.23A557Fbce545879": "Aviso de Liquidação Permanente",
    "message.index.B5E19B36B5125550": "Lembrete de retirada",
    "message.index.716A981465138334": "Lembrete de recarga",
    "message.index.884474Ebff2977Ba": "informação do sistema",
    "message.index.08Cc7D24752Ec42C": "serviço on-line",
    "message.index.1D820A6A19Ab025D": "Serviço manual on-line 7 x 24 horas",
    "more.index.29881E0B5Df58Bf7": "Mais recursos",
    "more.index.C77Ee35911D10D9D": "Funções Comuns",
    "more.index.3558F69B0260Be61": "Função de negociação",
    "more.index.7243Fe610Ed98Cec": "conta real",
    "more.index.7243Fe610Ed98Ced": "Conta de demonstração",
    "more.index.693C7B35Bdf3E3A3": "finança",
    "more.index.F855997Fac114554": "Centro de ajuda",
    "more.index.Ae9A1252278Da742": "ajuda",
    "more.index.Ace012504Bc529E0": "Informação",
    "more.index.05682Dbef5Eeb0A8": "atendimento ao Cliente",
    "more.index.C7Af227A5D5C7Bb9": "Você mudou para uma conta real",
    "more.index.D818A65592694786": "Você mudou para uma conta demo",
    "notice.details.B360807Dbe727E6E": "sobre nós",
    "notice.details.92F2552F695E70E3": "sobre nós",
    "notice.details.7Aaceaf69B67F3D1": "Introdução à regra",
    "notice.index.6128438Be34Bcb9F": "Aviso perpétuo",
    "notice.index.71627F5Eb7128261": "Notificação de retirada",
    "notice.index.2Dce570Fda841705": "Notificação de recarga",
    "notice.index.228A7C9C6448A9B1": "notificação do sistema",
    "setting.dialog.8B4F5B74660Deabe": "Configuração de cores",
    "setting.dialog.Bfc7D25486Ebc1A9": "O verde sobe e o vermelho cai",
    "setting.dialog.796Ea0E1A82C320B": "O vermelho sobe e o verde cai",
    "setting.index.8Ddc5864E1739466": "Preferências",
    "setting.index.F5205801D57D6727": "linguagem",
    "setting.index.2E2C12Dd0Cc1B63B": "Moeda de cotação",
    "setting.index.322D8496Aec3D918": "número da versão",
    "setting.index.Aa7E6C204C751Cfa": "sair",
    "share.index.75Bc38Bbf7D2E766": "convide amigos",
    "share.index.45575Eb5D42213E6": "meu código de convite",
    "share.index.29F67F0644A78Bf3": "cópia de",
    "share.index.19Cc65Bb6Bb98Fe7": "Link do meu convite",
    "share.index.5372712F162Fb78B": "Número de subordinados diretos",
    "share.index.C88D6B95E3Ab7D6F": "Número total de subordinados",
    "share.index.Bb5Fa5471Aeecc62": "Desconto de comissão para subordinados diretos",
    "share.index.426B6Bac9A5F3D4F": "desconto total",
    "share.index.197E4A428F0401C9": "Ver registros de descontos",
    "share.records.88A6B2E2B74B13C4": "Registro de comissão",
    "share.records.B671A35Ffdc93B89": "Desconto",
    "transfer.index.B17617A869C03Ca5": "carteira",
    "transfer.index.E4116Dcc6F6C7270": "Transferência bem-sucedida",
    "transfer.index.48Fb603C6638C7F6": "Por favor, defina uma senha de fundo",
    "transfer.records.Eb0E39Eb5911D418": "pegar",
    "transfer.records.977554Be0Aa65052": "Tempo de resgate do Flash：",
    "transfer.records.9Aa33884Aa400B7C": "tempo de transferência：",
    "form1.forget.76636Db8Fae5B124": "sua caixa de correio",
    "form1.forget.35354B9Cca1Bb0E6": "Código de verificação de e-mail",
    "form1.forget.604A0B11E916F387": "senha",
    "form1.forget.9Cb1C9C51E7503D6": "Confirme sua senha",
    "form1.forget.3A4F7E04B008453C": "Seu número de telefone",
    "form1.forget.Cf11Dce5B4713B5A": "Código de verificação do celular",
    "form1.forget.3012Edba57D4F297": "Por favor introduza o seu e-mail",
    "form1.forget.F1Ee1D97E869E447": "insira o código de verificação",
    "form1.forget.3A5C4C9Ae68Fa0F6": "Por favor, insira sua senha",
    "form1.forget.C6Cb174Da411D5F7": "Por favor insira seu celular",
    "form1.forget.947B3B21Ee45E582": "enviar",
    "form1.forget.3E0580F1E8630Df6": "Duas senhas são inconsistentes",
    "form1.forget.C5A61836B8Cc74C6": "Alteração de senha redirecionada com sucesso...",
    "form1.forget.B96D072Bc8962565": "Reenviar",
    "form1.login.2B72C27Ff828Cfc4": "sua senha",
    "form1.login.D7C6B872Af9Cb17F": "lembre-se da minha senha",
    "form1.login.0Fff44C827A4F3B6": "Conecte-se",
    "form1.login.1B7F2C1Ede3990Cc": "Sem conta? Cadastre-se agora",
    "form1.login.F657F7Be1Cc33Bd2": "esqueça a senha",
    "form1.login.2074A1Eec4B088B0": "Login da carteira",
    "form1.login.1B8938Fbcc3C9B56": "Login bem-sucedido e redirecionamento...",
    "form1.login.07162A7Bb9310555": "Instale a carteira ERC20",
    "form1.login.766969966Ae151Dc": "Instale a carteira TRC20",
    "form1.login.B88171E1Ad4C50Bf": "Você se recusou a mudar de rede",
    "form1.register.Aaef0710Cb4Db554": "Coloque a senha novamente",
    "form1.register.54Dfea0415C708Ff": "Código de Convite",
    "form1.register.6839940Dac08C794": "registro",
    "form1.register.9901A3B5550Aa58E": "já tem uma conta? Conecte-se",
    "form1.register.2548Ca53785252Ea": "Por favor insira seu código de convite",
    "form1.register.Dd71Fd65900Dd03E": "Por favor, leia o Contrato de Serviço de Registro e concorde,",
    "form1.register.7975613909490B77": "Registro bem-sucedido e redirecionamento...",
    "form1.walletDialog.105829Acfc0Bd6Aa": "Escolha carteira",
    "userc.certification.2Af9511640153D2E": "Certificação Júnior",
    "userc.certification.177B191D8E0Fa84D": "não certificado",
    "userc.certification.601218D4D572E98A": "verificado",
    "userc.certification.6E37C51Ba9012Fc8": "Não passou",
    "userc.certification.1B9B563E05C96474": "Certificação avançada",
    "userc.certification.1418B83803Ab56B4": "Por favor, complete a certificação primária primeiro",
    "userc.creditScore.0B149112601Acc4D": "pontuação de crédito",
    "userc.creditScore.88Ac7F84E106C5F6": "pontuação de crédito total",
    "userc.creditScore.3E1D6E0E7F1E6155": "Avaliação do sistema",
    "userc.index.62Eac58D04Bbca0F": "Centro Pessoal",
    "userc.index.F4Cac53F93Cb2Aff": "negociação simulada",
    "userc.index.42F409Fdf3559773": "Centro de Segurança",
    "userc.index.1B8E211009807Db8": "Autenticação",
    "userc.index.Bdfcf21Eb8F2Eb70": "Registros de alteração de conta",
    "userc.index.6C18D67F6D19586B": "Introdução à plataforma",
    "userc.index.807A814Ed58A93Bc": "Limpar cache",
    "userc.index.E43Daad59759611C": "não certificado",
    "userc.secureCenter.F5C70F0Db2F1Ba76": "Alterar senha de login",
    "userc.secureCenter.1F37Ba427Debf24A": "Vinculação de conta",
    "userc.secureCenter.F9A5Ffa750664004": "Vincular o autenticador do Google",
    "userb.certificationForm.7685Af7D30043Cd6": "Certificação Júnior",
    "userb.certificationForm.7685Af7D30043Cd7": "Certificação avançada",
    "userb.certificationForm.70E37C35Abc3F462": "País",
    "userb.certificationForm.F92C25A68E4D08C5": "Cidade",
    "userb.certificationForm.0Ccc6387B38E0318": "Seu nome",
    "userb.certificationForm.94C67Bba370Fdd46": "número de licença",
    "userb.certificationForm.B2D4C7Fffe79258E": "número de licença",
    "userb.certificationForm.57554268761Fb47A": "Faça upload da frente do seu documento de identidade",
    "userb.certificationForm.D79E90201F5319Bd": "Faça upload do verso do seu documento de identidade",
    "userb.certificationForm.9C6Bad08Af29E20E": "Carregue uma foto do seu cartão de identificação",
    "userb.certificationForm.F96B62Dfa31Cde45": "Confirmar envio",
    "userb.certificationForm.2125D78Ea8C6D287": "Requisitos de upload",
    "userb.certificationForm.0D64864D3076A824": "Ver exemplo",
    "userb.certificationForm.35287Deb72281E87": "1.Deve ser capaz de ler as informações do cartão de identificação com clareza.",
    "userb.certificationForm.3007F2E01Efc7B57": "2.O conteúdo da foto é verdadeiro e válido e nenhuma modificação é permitida.",
    "userb.certificationForm.25A90660554319F9": "3.O número de identificação e o nome devem estar claramente visíveis, suportar JPG/JPEG/PNG",
    "userb.certificationForm.5A9Aefbc03C778F7": "Por favor, insira",
    "userb.certificationForm.3B7A1D8Ce27C937E": "Envio bem-sucedido. Aguarde análise",
    "userb.certificationForm.744Bd07D8Abd2513": "Por favor, carregue a frente do seu documento de identidade",
    "userb.certificationForm.6De1C319130F7Fef": "Por favor, carregue o verso do seu documento de identidade",
    "userb.certificationForm.79F8E8B172E30C8A": "Por favor, carregue uma foto do seu cartão de identificação",
    "userb.certificationForm.46412E16D28A4753": "Falha no upload da foto",
    "userb.changeDialog.676B0Adca1F8Eea4": "alterar a senha",
    "userb.modifyInfoDialog.F41De3A1Fa69135A": "número de telefone",
    "userb.modifyInfoDialog.3C3Aac9664A35Ef8": "interruptor validador",
    "userb.modifyInfoDialog.62149E566814154C": "Selecione validador",
    "userb.modifyInfoDialog.8E69468E2435Fc89": "Abra o aplicativo Autenticação e toque em +",
    "userb.modifyInfoDialog.7625E5Bdcbfe9963": "Selecione 'Digitalizar código QR' ou 'Tecla Enter'",
    "userb.modifyInfoDialog.93F094E2B4369472": "Digitalize o código QR ou digite a chave",
    "userb.modifyInfoDialog.43E4F1532529Ee1E": "fechado aberto",
    "userb.modifyInfoDialog.Bae6358Dfd95025A": "Senha antiga do fundo",
    "userb.modifyInfoDialog.E1133Cd4B1C2Abb7": "Nova senha do fundo",
    "userb.modifyInfoDialog.C1Ce0403A2523Ee0": "Confirme a nova senha do fundo",
    "userb.modifyInfoDialog.8Dd453Adc2Cd78Fb": "Confirme a senha do fundo",
    "userb.modifyInfoDialog.Bb0D9854C79325Bb": "Por favor, insira sua senha antiga do fundo",
    "userb.modifyInfoDialog.E66Cb29B00Bc54D1": "Por favor, insira sua nova senha do fundo",
    "userb.modifyInfoDialog.E8Ae2Ec5Df401255": "Por favor, insira a senha do seu fundo",
    "userb.modifyInfoDialog.258A534498Fe984F": "Por favor, confirme a senha do seu fundo",
    "userb.modifyInfoDialog.768Ec43Faa193F08": "Vinculação de conta",
    "userb.modifyInfoDialog.D34746090C25E5Db": "Senha do fundo",
    "userb.modifyInfoDialog.E4F27D8916E5E536": "confirme",
    "userb.modifyInfoDialog.Bef7160Ce53773E0": "O validador está fechado",
    "userb.modifyInfoDialog.Be8Bf47Ba6820De1": "Verificação do Google ativada com sucesso",
    "userb.modifyInfoDialog.24Dba26593F43C74": "Senha do fundo modificada com sucesso",
    "userb.modifyInfoDialog.5C369D42Dc7Dbe76": "Defina a senha do fundo com sucesso",
    "userb.modifyInfoDialog.667Bf8D0B6C36D4C": "Vinculação bem-sucedida",
    "userb.modifyInfoDialog.1C5E05Efee2B0B9F": "Erro no código de verificação",
    "userb.veBottomSheet.Eecc4683C725F6F2": "Exemplo de upload de certificado",
    "userb.veBottomSheet.6F38A0D5858633Ee": "fundo escuro e limpo",
    "userb.veBottomSheet.Af162Dbdfc4Fcae9": "Iluminação suficiente",
    "userb.veBottomSheet.0F7Baf0Cee3C8141": "Estrutura auxiliar de alinhamento",
    "userb.veBottomSheet.Ac9E471Eead0Cf11": "As fotos são claras",
    "userb.veBottomSheet.50B741382E71F103": "Possíveis razões para o fracasso",
    "userb.veBottomSheet.Aa56C68E8Fd4Cfb4": "A capa protetora do cartão de identificação não foi removida",
    "userb.veBottomSheet.5D2F429C75D4C7F2": "O fundo é afetado pela desordem",
    "userb.veBottomSheet.90F42D3Cb7769Dbc": "O cartão de identificação é reflexivo",
    "userb.veBottomSheet.1Ee78Cee782D81B4": "A luz está muito escura/obstruída pela sombra do celular",
    "userb.veBottomSheet.1A75D2Fc2614592B": "segurando cartão de identificação",
    "home.home.C3525Eb110Db58Aa": "Mais",
    "home.home.29761532Fc8Ebecc": "troca",
    "home.home.F15Ea99Bfa6E1848": "Certificação de segurança, compre com confiança",
    "home.home.46E1Ab39Bae7182A": "Rápido",
    "home.home.Fde2Eb7F6F3Dd800": "Transações rápidas e operação simples",
    "home.home.Ea4756Bc1642E0F1": "nome",
    "home.home.B6B1127Ede1C97B4": "preço mais recente",
    "home.home.3587Ad870Eb0Ab86": "Altos baixos 24H",
    "home.home.Cb1A4E14Ec2Cd2B5": "Descobrir",
    "home.home.0F1B1Ac0E96A40D1": "Blockchain",
    "home.home.8Eaefb1107A0E210": "Forex",
    "home.home.Ad65263D034Ea051": "Metal",
    "home.home.5Fc1474Dbf74Ac1A": "Agro",
    "home.home.135318F4664F7773": "energia",
    "kline.kLine.C96Ff250Cf6B8B84": "Preço mais alto em 24h",
    "kline.kLine.6E8Be64F19D74565": "Volume de negociação em 24 horas",
    "kline.kLine.08A4Bfbe26A0F262": "24h preço mais baixo",
    "kline.kLine.8701Deb075066331": "Volume de negociação em 24 horas (USDT)",
    "kline.kLine.Ea3C3052136C84Cd": "1 ponto",
    "kline.kLine.B49Cca240E013124": "5 pontos",
    "kline.kLine.F27A90Be97Ba3D6C": "15 marcas",
    "kline.kLine.Ff3E62395B694384": "30 pontos",
    "kline.kLine.C1993F9C6Db1A674": "1 hora",
    "kline.kLine.Fd2Cbbae405E284C": "1 dia",
    "kline.kLine.C9Cd71904395042C": "Janeiro",
    "kline.kLine.7D8127Ec18C7B8C7": "Comprar",
    "kline.kLine.E3D3Ffdb9B3E5636": "Quantidade (peças)",
    "kline.kLine.6834Fec1838D8029": "Preço (USDT)",
    "kline.kLine.16C52Aae474Bc9Cd": "pedido de venda",
    "kline.kLine.B9Bea1A9A88D1419": "compre longo",
    "kline.kLine.D36Cfa96Ee2B343B": "Abrir curto",
    "other.languageRateSelect.6897784800834C1B": "configuração de idioma",
    "other.languageRateSelect.6897784800834C1C": "Selecione a moeda do preço",
    "other.navigationBar.F6E62F9642018Cbb": "primeira página",
    "other.navigationBar.0Ec227161A47B49D": "Citações",
    "other.navigationBar.29761532Fc8Ebecc": "troca",
    "other.navigationBar.693C7B35Bdf3E3A3": "finança",
    "other.navigationBar.9734C54620Eb09Af": "ativos",

    "home.index.25Aa4Fc745150030": "The King of Cryptocurrency",
    "home.index.2D6Ecf8Faaa8B124": "Faster, better, stronger",
    "home.index.1Db26267D1041223": "Easily trade over 1700 cryptocurrencies",
    "home.index.F8E18C90C3E35618": "Register now",
    "home.index.29761532Fc8Ebecc": "Transaction",
    "home.index.D8A95E4506487452": "Popular Contracts",
    "home.index.5Bd31Fab681C6E9D": "View more",
    "home.index.010Ae07A09Caf221": "Trading pair",
    "home.index.F4D875118E6D6Dbb": "Latest transaction price",
    "home.index.3Dfdfb3D6599100E": "24-hour increase or decrease",
    "home.index.28Fb39Bd41E54242": "Trend Chart",
    "home.index.B47A3911Bacd94C6": "Growth List",
    "home.index.712910D574F43D95": "Stable income",
    "home.index.5507Fd830D7Bf51F": "Top strategy, easy to copy",
    "home.index.7De3D636A165E008": "yield",
    "home.index.B853F96626106912": "Go mining",
    "home.index.213B5C96Be7Cd1F8": "Days, annualized interest rate",
    "home.index.213B5C96Be7Cd1F9": "Maximum purchase",
    "home.index.Dc710Cffe6313Bb5": "View details",
    "home.index.B92Ffc837Ecaa1Dd": "News",
    "home.index.6C18D67F6D19586B": "Platform introduction",
    "home.index.6342E3C33Cb48Cd5": "Digital currency is a medium of exchange that uses cryptographic principles to ensure transaction security. Unlike fiat currencies such as the U.S. dollar, digital currencies do not have a physical form. Bitcoin (BTC), Ethereum (ETH) and Polkadot Popular digital currencies such as (DOT) use blockchain as the underlying technology and act as a decentralized digital ledger. All digital currency transactions are recorded in the blockchain and cannot be changed once confirmed and verified. Unlike traditional currencies, which are maintained by banks Unlike centralized ledgers, digital currency transactions occur on a public blockchain and can be accessed by anyone.",
    "home.index.198Efc4254683Eef": "In addition, according to the consensus mechanism, everyone can verify digital currency transactions and add them to the blockchain, thereby achieving decentralization. With Samsung, BlackRock, Morgan Stanley Leading global companies such as Alphabet and Alphabet have invested in it, and people believe that blockchain technology will completely change the global financial system. Digital currency opens the door to the world of decentralized finance for you, allowing you to explore the infinite possibilities brought by cutting-edge technology .",
    "home.index.E710339D9576292E": "Our advantages",
    "home.index.F9Ce8B2C63885B26": "Safe and stable",
    "home.index.C4D0A2C9Ff0305Ce": "Top technical team, comprehensive security protection, independent research and development of high-speed transaction synthesis engine, which can still operate stably and reliably under massive concurrent transactions.",
    "home.index.2E8708Ee3F11Cc1A": "Professional and reliable",
    "home.index.Bb12Cbcf89C56C5C": "Professional operation team, many years of experience in blockchain and financial industry, holding a compliant digital asset trading license, and 100% reserve guarantee.",
    "home.index.Aa4723Bfbf6808F4": "Service with heart",
    "home.index.1Bf0204B7D3Ee230": "Facing the global market, supporting multiple languages, running 24/7, strong community support, and professional customer service.",
    "home.index.2E7Dfd53D26F7224": "High performance",
    "home.index.1C65453103B5Fcc3": "300,000 transactions per second, order response time is less than 1 millisecond.",
    "home.index.85Ac49Ece9Dcc80B": "Trade anytime, anywhere!",
    "home.index.2799E18842E4Ebe9": "Instant position opening, supported by OKEX App and web page!",
    "home.index.2Bf52Ce75E29Fc88": "Scan the code to download now",
    "home.index.88D1422B33571066": "Android",
    "home.index.04Ee893775563F21": "Apple",
    "home.index.04Ee893775563F22": "Products and Services",
    "home.index.04Ee893775563F23": "Financial Management",
    "home.index.04Ee893775563F26": "IOS & ANDROID",
    "homepage.text1": "Compre Bitcoin, Ethereum e",
	"homepage.text1_2": "mais de 1000 criptomoedas",
	"homepage.text2": "Sua jornada em criptomoedas",
	"homepage.text4": "Gestão financeira de alta taxa de juros",
	"homepage.text5": "Gestão financeira estável, alto retorno anual",
	"homepage.text6": "Recompensas de partilha",
	"homepage.text7": "Transações rápidas, operação simples",
	"homepage.text8": "Ver mais",
	"homepage.text9": "Troca instantânea",
	"homepage.text10": "Certificação de segurança, compre com confiança",
	"homepage.text11": "Equipe técnica de ponta, segurança abrangente",
	"homepage.text12": "Proteção, independência",
	"homepage.text13": "Profissional e confiável",
	"homepage.text14": "Equipe de operações profissional com anos de experiência em blockchain e finanças",
	"homepage.text15": "Serviço dedicado",
	"homepage.text16": "Orientado para o mercado global, suportando vários idiomas, operação 24/7",
	"homepage.text17": "Alto desempenho",
	"homepage.text18": "Processamento de 300.000 transações por segundo, tempo de resposta do pedido inferior a 1 milissegundo",
}
